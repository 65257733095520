
import { store, type AppDispatch } from '../../store/store'
import { getChat } from '../../store/actionsCreators/chatActions'
import _ from 'lodash'
import { getChatList } from '../../store/actionsCreators/chatListActions'
import { addViewing, setViewings } from '../../store/slices/viewingsSlice'
import { reduceViewing, reduceViewingAfterCreate } from '../../functions/agent/socket/reduceViewing'
import { USER_ID_KEY } from '../../urls'
import { reduceViewingAfterUpdateKey } from '../../functions/agent/socket/reduceViewingAfterUpdateKey'
import { fetchSocketUser, fetchUser, fetchUsers } from '../../store/actionsCreators/userActions'
import { setIsUserVerified } from '../../store/slices/modalSlice'
import { setStatuses } from '../../store/slices/agentSlice'
import getAgentStatuses from '../../functions/agent/documents/checkDocumentsForAproved'
import { fetchDocuments } from '../../store/actionsCreators/documentActions'
import { swalSuccess } from '../../functions/swalOptions'
import { getCorrectName } from '../../functions/agent/agentModuleComponent/getCorrectName'
import { documentAddUpdate } from '../../store/slices/documentSlice'
import { fetchOffers, processSocketOffer } from '../../store/actionsCreators/offerActions'
import { fetchSingleUnit, fetchUnits } from '../../store/actionsCreators/unitsActions'
import customLog from '../../functions/log'
import { setCurrentUserUnits, type User } from '../../store/slices/userSlice'
import { addAdvertise, setAdvertises } from '../../store/slices/advertisesSlice'
import { reduceAdvertice } from '../../functions/agent/socket/reduceAdvertice'
import { fetchViewings } from '../../store/actionsCreators/viewingsActions'
import { fetchAdvertises } from '../../store/actionsCreators/advertiseActions/fetchAdvertiseAction'
import { setHash } from '../../store/slices/hashSlice'

export const landlordSocket = async (webSocket: WebSocket, dispatch: AppDispatch): Promise<void> => {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const messageObject = {
        action: 'get.state',
        deviceToken: store.getState().user.currentUser.DeviceToken,
        userID
    }
    webSocket.onopen = () => {
        console.log('Connected => get.state sended')
        webSocket.send(JSON.stringify(messageObject))
    }

    webSocket.onmessage = (event: MessageEvent) => {
        try {
            const message = event.data
            if (typeof message === 'string' && (message.startsWith('{') || message.startsWith('['))) {
                const data = JSON.parse(message)
                if (data?.action === 'open.app') {
                    if (data?.deviceToken === store.getState().user.currentUser.DeviceToken) {
                        webSocket.send(JSON.stringify(messageObject))
                    }
                }

                if (data.action === 'get.state') {
                    const { state, stateDev } = data
                    const currentState = store.getState().hash

                    if (process.env.REACT_APP_IS_DEV) {
                        if (stateDev?.user?.token === store.getState().user.currentUser.DeviceToken) {
                            if (stateDev?.users !== currentState.users) {
                                if ((stateDev?.user?.me !== stateDev.users)) {
                                    void dispatch(fetchUsers(true))
                                } else {
                                    void dispatch(fetchUser(true))
                                }
                            }

                            if (Boolean(stateDev.user.chatRooms) && stateDev.user.chatRooms !== currentState.chatRooms) {
                                void dispatch(getChatList())
                            }
                            if (stateDev.units !== currentState.units) {
                                void dispatch(fetchUnits(true))
                            }
                            if (stateDev.viewings !== currentState.viewings) {
                                void dispatch(fetchViewings(true))
                            }
                            if (stateDev.offers !== currentState.offers) {
                                void dispatch(fetchOffers(true, true))
                            }
                            if (stateDev.unitAdvertises !== currentState.unitAdvertises) {
                                void dispatch(fetchAdvertises(true))
                            }
                            if (stateDev.documents !== currentState.documents) {
                                void dispatch(fetchDocuments(true))
                            }
                            if (stateDev.unitKeys !== currentState.unitKeys) {
                                void dispatch(fetchUnits(true))
                            }

                            dispatch(setHash({ action: 'users', timestamp: stateDev.users }))
                            dispatch(setHash({ action: 'units', timestamp: stateDev.units }))
                            dispatch(setHash({ action: 'viewings', timestamp: stateDev.viewings }))
                            dispatch(setHash({ action: 'offers', timestamp: stateDev.offers }))
                            dispatch(setHash({ action: 'unitAdvertises', timestamp: stateDev.unitAdvertises }))
                            dispatch(setHash({ action: 'unitKeys', timestamp: stateDev.unitKeys }))
                            dispatch(setHash({ action: 'documents', timestamp: stateDev.documents }))
                            dispatch(setHash({ action: 'chatRooms', timestamp: stateDev.user.chatRooms }))
                        }
                    } else {
                        if (data.deviceToken === store.getState().user.currentUser.DeviceToken) {
                            if (state.users !== currentState.users) {
                                if ((state.user.me !== state.users)) {
                                    void dispatch(fetchUsers(true))
                                } else {
                                    void dispatch(fetchUser(true))
                                }
                            } if (
                                Boolean(state?.user?.chatRooms) && state.user.chatRooms !== currentState.chatRooms) {
                                void dispatch(getChatList())
                            }
                            if (state.units !== currentState.units) {
                                void dispatch(fetchUnits(true))
                            }
                            if (state.viewings !== currentState.viewings) {
                                void dispatch(fetchViewings(true))
                            }
                            if (state.offers !== currentState.offers) {
                                void dispatch(fetchOffers(true, true))
                            }
                            if (state.unitAdvertises !== currentState.unitAdvertises) {
                                void dispatch(fetchAdvertises(true))
                            }
                            if (state.documents !== currentState.documents) {
                                void dispatch(fetchDocuments(true))
                            }
                            if (state.unitKeys !== currentState.unitKeys) {
                                void dispatch(fetchUnits(true))
                            }

                            dispatch(setHash({ action: 'users', timestamp: state.users }))
                            dispatch(setHash({ action: 'units', timestamp: state.units }))
                            dispatch(setHash({ action: 'viewings', timestamp: state.viewings }))
                            dispatch(setHash({ action: 'offers', timestamp: state.offers }))
                            dispatch(setHash({ action: 'unitAdvertises', timestamp: state.unitAdvertises }))
                            dispatch(setHash({ action: 'unitKeys', timestamp: state.unitKeys }))
                            dispatch(setHash({ action: 'documents', timestamp: state.documents }))
                            dispatch(setHash({ action: 'chatRooms', timestamp: state.user.chatRooms }))
                        }
                    }
                }

                if ('action' in data && 'isDev' in data) {
                    const [collection, action] = data.action.split('.')
                    const entry = data?.entry

                    if (
                        (data?.isDev && process.env.REACT_APP_IS_DEV === 'true') ||
                    (!data?.isDev) && (!process.env.REACT_APP_IS_DEV)
                    ) {
                        if (collection === 'chat-room') {
                            if (action === 'update' && entry.id === store.getState().chat?.chat?.id) {
                                void dispatch(getChat(entry.id, 1, undefined, true))
                            }
                            if (action === 'update' && _.some(store.getState().chatList.chatList, { id: entry.id })) {
                                void dispatch(getChatList())
                            }
                            if (action === 'create') {
                                void dispatch(getChatList())
                            }
                        }
                        if (collection === 'unit-key') {
                            if (action === 'create') {
                                if (entry.Holder.id === Number(userID)) {
                                    const isThere = store.getState().viewings.viewings.filter((viewEl) => viewEl.id === entry?.Viewings?.[0]?.id) // ?.find((view) => view.attributes.User.data?.id === Number(userID))
                                    if (isThere != null && isThere.length > 0) {
                                        dispatch(setViewings(reduceViewing(entry?.Viewings?.[0], isThere[0])))
                                    }
                                }
                            }
                            if (action === 'update') {
                                // console.log(entry, 'entry in update unit key')
                                if (entry.Holder.id === Number(userID)) {
                                    const agentViewingsIDS = store.getState().viewings.viewings.filter((el) => el?.attributes?.User?.data?.id === Number(userID))?.map((el) => el.id)

                                    const isThere = store.getState().viewings.viewings.filter(
                                        view => view.id === entry?.Viewings?.filter((el: any) => agentViewingsIDS.includes(el.id))[0].id
                                    )
                                    //     const isThere = store.getState().viewings.viewings.filter((viewEl) => viewEl.id === entry?.Viewings?.[0]?.id) // ?.find((view) => view.attributes.User.data?.id === Number(userID))
                                    if (isThere != null && isThere.length > 0) {
                                        dispatch(setViewings(reduceViewingAfterUpdateKey(entry, isThere[0])))
                                    }
                                }
                            }
                        }

                        if (collection === 'user') {
                            if (action === 'create') {
                                dispatch(fetchSocketUser(entry?.id ?? -1))
                            }
                            if (action === 'update') {
                                if (entry.id === Number(userID)) {
                                    //      console.log(entry, '============> entry <=================')
                                    dispatch(fetchUser(true))
                                    const actualUser = store.getState().user?.currentUser
                                    const updateUser = { ...actualUser, ...entry } as User
                                    // dispatch(userUpdate(updateUser))
                                    if (updateUser.emailVerified) {
                                        setIsUserVerified(true)
                                    } else {
                                        setIsUserVerified(false)
                                    }
                                    const docs = updateUser?.Documents
                                    dispatch(setStatuses(getAgentStatuses(docs)))
                                } else {
                                    dispatch(fetchSocketUser(entry?.id ?? -1, true))
                                    if (entry.id === store.getState().chat?.chat?.User?.id) {
                                        if (store.getState().chat?.chat?.id != null) {
                                            void dispatch(getChat(Number(store.getState().chat.chat.id), 1, undefined, true))
                                        }
                                    }
                                }
                            }
                        }
                        if (collection === 'document') {
                            // console.log(action, entry)
                            if (action === 'create') {
                                const keys = ['Offer', 'Unit', 'Advertise']
                                for (const key of keys) {
                                    if (key in entry && entry[key] != null) {
                                        // TODO add check that entity belongs to user and copy to AgentModule
                                        void dispatch(fetchDocuments(true))
                                        break
                                    }
                                }
                            }
                            if (action === 'update') {
                                //        console.log(entry, '============> entry <=================')
                                //  console.log(userID, 'userID<========== =========>entry.User.id', entry.User.id)
                                if (entry?.User?.id === Number(userID)) {
                                    //    const isMy = store.getState().documents.documents.filter((docEl) => docEl.id === entry.id)
                                    //    if (isMy !== null && isMy.length > 0) {
                                    void swalSuccess(`Your ${getCorrectName(entry?.Type[0].__component)} is ${entry?.Statuses[0]}`).then(async (result: any) => {
                                        if (result.isConfirmed) {
                                            dispatch(documentAddUpdate(entry)) // , isMy[0]
                                            const userDocs = store.getState().documents.documents.filter((doc) => doc.attributes.User.data?.id === Number(userID))
                                            dispatch(setStatuses(getAgentStatuses(userDocs)))
                                        }
                                    })
                                }
                            }
                        }
                        if (collection === 'viewing') {
                            if (action === 'create') {
                                if (entry?.User.id !== Number(userID)) {
                                    dispatch(addViewing(reduceViewingAfterCreate(entry)))
                                }
                            }
                            if (action === 'update') {
                                const isThere = store.getState().viewings.viewings.filter((viewEl) => {
                                    return viewEl.id === entry.id
                                })
                                dispatch(setViewings(reduceViewing(entry, isThere[0])))
                            }
                        }
                        if (collection === 'offer') {
                            console.log(entry)
                            if (entry.Type != null && entry.Unit != null && entry.User != null) {
                                const { isSuccessful } = dispatch(processSocketOffer(entry))
                                if (!isSuccessful) {
                                    dispatch(fetchOffers(true, true))
                                }
                            } else {
                                dispatch(fetchOffers(true, true))
                            }
                        }

                        if (collection === 'unit') {
                            if (action === 'update') {
                                /* if (_.some(store.getState().units.catalog, { id: entry.id })) {
                            dispatch(fetchSingleUnit(entry.id))
                                .then((result: any) => {
                                    const unitsCopy = _.cloneDeep(store.getState().units.catalog)
                                    const index = _.findIndex(unitsCopy, { id: entry.id })
                                    unitsCopy[index] = { id: entry.id, attributes: result }
                                    dispatch(unitsSetCatalog(unitsCopy))
                                })
                                .catch((err: any) => {
                                    customLog(err)
                                })
                        } */
                                if (_.some((store.getState().user.currentUser as any).Units, { id: entry.id })) {
                                    /* dispatch(fetchSingleUnit(entry.id))
                                .then((result: any) => {
                                    const unitsCopy = _.cloneDeep((store.getState().user.currentUser as any).Units)
                                    const index = _.findIndex(unitsCopy, { id: entry.id })
                                    unitsCopy[index] = { id: entry.id, ...result }
                                    dispatch(setCurrentUserUnits(unitsCopy))
                                })
                                .catch((err: any) => {
                                    customLog(err)
                                }) */
                                    dispatch(fetchUser(true))
                                }
                                if (_.some(store.getState().chat.searchedInvites, (invite) => invite?.Unit?.attributes?.id === entry.id)) {
                                    const invitesCopy = _.cloneDeep(store.getState().chat.searchedInvites)
                                    const index = _.findIndex(invitesCopy, (invite) => invite?.Unit?.attributes?.id === entry.id)
                                    dispatch(fetchSingleUnit(entry.id))
                                        .then((result: any) => {
                                            if (invitesCopy?.[index]?.Unit?.attributes != null) {
                                                (invitesCopy[index].Unit as any).attributes = result
                                            }
                                        })
                                        .catch((err: any) => {
                                            customLog(err)
                                        })
                                }
                            } else if (action === 'create') {
                                if (entry.User?.id === store.getState().user.currentUser?.id) {
                                    const unitsCopy = _.cloneDeep((store.getState().user.currentUser as any).Units)
                                    unitsCopy.push(entry)
                                    dispatch(setCurrentUserUnits(unitsCopy))
                                }
                            } else if (action === 'delete') {
                                console.log(entry)
                                if (_.some((store.getState().user.currentUser as any).Units, { id: entry.id })) {
                                    dispatch(setCurrentUserUnits((store.getState().user.currentUser as any).Units.filter((unit: any) => unit.id !== entry.id)))
                                }
                            }
                        }
                        if (collection === 'unit-advertise') {
                            if (action === 'update') {
                                const advertise = _.find(store.getState().advertises.advertises, advertise => advertise.id === entry.id)
                                if (advertise != null && (advertise?.attributes?.User.data?.id === Number(userID) || userID === process.env.REACT_APP_BSO_USER_ID)) {
                                    dispatch(setAdvertises(reduceAdvertice(entry, advertise)))
                                }
                            }
                            if (action === 'create') {
                                const advertise = { id: entry.id, attributes: { ...entry } }
                                const unit = _.find(store.getState().units.units, (unit) => unit.id === entry.Unit.id)
                                const user = _.find(store.getState().user.allUsers, (user) => user.id === entry.User.id)
                                advertise.attributes.Unit = { id: unit?.id, data: unit }
                                advertise.attributes.User = { id: user?.id, data: user }
                                dispatch(addAdvertise(advertise))
                            }
                        }
                    }
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

}
