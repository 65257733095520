import React, { useEffect, useRef, useState } from 'react'
import ChatHeader from './ChatHeader'
import ChatInput from './ChatInput'
import {
    getChat, getMessageDraft,
    getMessages,
    removeAllPendingMessages,
    removeUnreadMessages, setMessageDraft
} from '../../../../store/actionsCreators/chatActions'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { useParams } from 'react-router'
import { type RootState } from '../../../../store/store'
import _ from 'lodash'
import { setChat, setCurrentMessage, setMessages } from '../../../../store/slices/chatSlice'
import MessagesSwitch from './messages/MessagesSwitch'
import { nanoid } from 'nanoid'
import ChatUtils from '../../../../functions/chats/ChatUtils'
import useInfinteScroll from '../../../../hooks/UseInfinteScroll'
import { setPage } from '../../../../store/slices/paginationSlice'
import ListLoader from '../../../../app/custom/loaders/ListLoader'

import { type IChatMessage } from '../../../../types'
import messageDatesAdapter from '../../../../functions/adapters/chat/messageDatesAdapter'
import { useSearchParams } from 'react-router-dom'
import { blockUser } from '../../../../store/actionsCreators/userActions'
import { Spin } from 'antd'

import { placeholders } from '../../../../urls'
import AdminChatActions from "./AdminChatActions";

const PaginationLoader = (): JSX.Element => {
    const paginationLoading = useAppSelector((state: RootState) => state.pagination.paginationLoading)
    return (
        paginationLoading
            ? <div className={'w-100 d-flex justify-content-start position-absolute'} style={{ top: '5rem' }}>
                <Spin size={'default'} />
            </div>
            : <></>
    )
}

const NewChatV2 = (): JSX.Element => {
    const bottomRef = useRef<HTMLDivElement>(null)
    const scrollRef = useRef<HTMLDivElement>(null)
    const scrollPageRef = useRef<HTMLDivElement>(null)
    const dispatch = useAppDispatch()
    const { id: chatID = '0' } = useParams()
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const chat = useAppSelector((state: RootState) => state.chat.chat)
    const pendingMessages = useAppSelector((state: RootState) => state.chat.pendingMessages)
    const Messages = useAppSelector((state: RootState) => state.chat.Messages)
    const page = useAppSelector((state: RootState) => state.pagination.page)
    const pageCount = useAppSelector((state: RootState) => state.pagination.pageCount)
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const [searchParams, setSearchParams] = useSearchParams()
    const messageID = searchParams.get('message')
    const [loading, setLoading] = useState(true)
    const [blockLoading, setBlockLoading] = useState(false)



    useEffect(() => {
        //  console.log('COMPONENT MOUNT', [messageID, JSON.stringify((currentUser as any).BlackList), chatID])
        setLoading(true)
        dispatch(setPage(1))
        dispatch(setMessages([]))
        dispatch(setCurrentMessage(getMessageDraft(+chatID)))
        dispatch(getChat(+chatID, 1, messageID != null ? +messageID : undefined, undefined, () => {
            dispatch(setMessages([]))
            if (messageID == null) {
                dispatch(removeUnreadMessages(+chatID))
            }
        }))
            .finally(() => {
                setLoading(false);
                setTimeout(() => {

                    // if (Messages.length > 0) {
                    // console.log('SCROLL BOTTOM REF', bottomRef)
                    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
                    // }
                }, 100);
            });
        return () => {
            if (messageID == null) {
                dispatch(removeUnreadMessages(+chatID))
                dispatch(removeAllPendingMessages())
                dispatch(setCurrentMessage(''))
            }
            dispatch(setMessages([]))
            dispatch(setChat({}))
        }
    }, [messageID, JSON.stringify((currentUser as any).BlackList), chatID])

    useInfinteScroll({
        scrollRef,
        page,
        pageCount,
        setPage,
        action: () => getMessages(+chatID, page),
        direction: 'top',
        triggerByScrollTop: true
    })

    const messages = messageDatesAdapter(Messages, 'asc')
    const isOffer = chat.Type === 'Offer'
    const isChatCompleted = isOffer && chat.Status === 'Completed'
    return (
        <div className={'w-screen fs-4 d-flex flex-column'}
            id={'chat-wrapper'}
            style={{
                background: 'url(/assets/media/chat-background.png) no-repeat center',
                backgroundSize: 'cover',
                height: '100vh',
                overflow: 'auto'
            }}>
            <ChatHeader chat={chat} loading={loading} isOffer={isOffer} scrollRef={scrollPageRef} />
            {
                [...pendingMessages, ...messages].length === 0 && !loading
                    ?
                    <div className={'d-flex position-relative align-items-end '} style={{ height: '100%', bottom: '-13%', left: '50%', transform: 'translateX(-50%)' }}>
                        <img
                            className="w-75 object-fit-cover m-auto rounded-0 rounded-bottom opacity-100"
                            src={placeholders.Messages}
                            alt={'liable_picture'}
                        />
                        <span className="fs-2x fw-bold text-white text-uppercase position-absolute text-center" style={{ bottom: '17%', left: '50%', transform: 'translateX(-50%)', textShadow: '1px 1px 10px #332d2dd9' }}>
                            No messages yet
                        </span>
                    </div>
                    : <></>
            }
            <div className={'overflow-auto h-100 position-relative'}
                ref={scrollRef}
            >
                <div className={'d-flex flex-column-reverse gap-4 p-4 min-h-100'}
                    ref={scrollPageRef}
                // style={
                //     !ChatUtils.isBSOBroadcast(chat) ? { height: userDevice === 'IPhone' ? 'calc(100vh - 12rem)' : 'calc(100vh - 10rem)' } : { height: 'calc(100vh - 5rem)' }
                // }
                // onScroll={handleScroll}
                >
                    {loading
                        ? <ListLoader />
                        : <>
                            <div ref={bottomRef} id={'bottom-ref-element'} />
                            {
                                messageID != null
                                    ? <>
                                        <div className={'d-flex w-100 justify-content-center position-absolute'}>
                                            <div onClick={() => {
                                                dispatch(setMessages([]))
                                                setSearchParams({ from: 'single-message' })
                                            }} className={'btn btn-bso btn-bso-sm'}
                                            >
                                                See other messages
                                            </div>
                                        </div>
                                        <div style={{ height: '15rem' }}></div>
                                    </>
                                    : <></>
                            }
                            {
                                _.map(pendingMessages, (message) => (
                                    <MessagesSwitch sentBy={'me'} message={message} key={nanoid()} isPending={true} />
                                ))
                            }
                            {
                                _.map(messages, (message) => (
                                    (message as { date: string }).date !== undefined
                                        ? <div className={'d-flex justify-content-center'}>
                                            <div className={'badge bg-white color-black padding-1 fs-6'}>{(message as { date: string }).date}</div>
                                        </div>
                                        : <MessagesSwitch message={message as IChatMessage} key={nanoid()} />
                                ))
                            }
                            <PaginationLoader />
                        </>
                    }
                </div>
            </div>
            {isChatCompleted
                ? <div className={'w-100 d-flex justify-content-between align-items-center p-4 mb-12'}>
                    <div className={'card text-dark fw-bold text-uppercase lh-sm p-4 my-2 text-center'}>You have completed negotiations on this offer</div>
                </div>

                : chat.BlockedBy != null
                    ? <div className={'w-100 d-flex justify-content-between align-items-center p-4'}>
                        {
                            chat.BlockedBy === currentUser.id
                                ? <>
                                    <div className={'text-danger'}>Chat is blocked by You</div>
                                    <div className={'btn btn-sm btn-danger'}
                                        onClick={() => {
                                            if (!blockLoading) {
                                                setBlockLoading(true)
                                                void dispatch(blockUser(chat?.User?.id ?? -1, true))
                                                    .finally(() => {
                                                        setBlockLoading(false)
                                                    })
                                            }
                                        }}
                                    >
                                        {
                                            !blockLoading
                                                ? <>Unblock {chat?.User?.Name}</>
                                                : <span className="indicator-progress d-block">
                                                    Please wait...
                                                    <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                                </span>
                                        }
                                    </div>
                                </>
                                : <div className={'badge badge-danger'}>Chat is blocked by {chat?.User?.Name}</div>
                        }
                    </div>
                    : <ChatInput scrollRef={scrollPageRef} />
            }
        </div >
    )
}

export default NewChatV2
