import React from 'react'
import TabButton from '../../../shared/elements/TabButton'

const ServiceTabs = (): JSX.Element => {
    return (
        <>
            <TabButton
                value={'services'}
                filter={'page'}
                label={'SERVICES'}
                additionalParam={'source=bso-catalog'}
                isDefault
            />
            <TabButton
                value={'subscriptions'}
                filter={'page'}
                label={'SUBSCRIPTIONS'}
                additionalParam={'source=bso-catalog'}
            />
        </>
    )
}

export default ServiceTabs
