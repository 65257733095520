import { getCurrentDubaiDate } from "../../../functions/agent/date/getCurrentDateStrFormat"
import { toUTCWithOffset } from "../../../functions/agent/date/toUTCWithOffset"
import { getErrorss } from "../../../functions/errors/GetErrors"
import { getQueryUnits } from "../../../functions/shared/api/queries/getQueryUnits"
import { filterVacantDateForAdvertise, getNextAvailableAdvert } from "../../../functions/shared/date/filterVacantDateForAdvertise"
import { sendNotification } from "../../../functions/shared/notifications/from-agent/sendNotification"
import { swalError, swalSuccess } from "../../../functions/swalOptions"
import { ACCESS_KEY, urlAPI } from "../../../urls"
import { dataFetchingFinish, dataFetchingStart } from "../../slices/modalSlice"
import { type AppDispatch, type RootState } from "../../store"



interface WaitingItem {
    adId: number;
    userID: number;
    hideDate: string;
    startDate: string;
    status: 'Reserve' | 'Reserved';
}
export const updateWaitingList = (fields: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    console.log(fields,"fields < ==")
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? '';
    try {
        dispatch(dataFetchingStart());   
        const advertiseReserveDates = filterVacantDateForAdvertise(fields?.matchingAdvertsFromOtherUsers)
        console.log(advertiseReserveDates,"advertiseReserveDates")

        const currentWaitingList = fields.awaitingList ?? [];
        console.log(currentWaitingList,"currentWaitingList")
        const newAdvert = getNextAvailableAdvert(advertiseReserveDates, currentWaitingList);
        console.log(newAdvert,"newAdvert")
        let hideDate = null;
        let startDate = null;
 
        if (newAdvert == null || ( newAdvert != null && newAdvert?.EndDate === 'TBA')) {
            //   await sendNotification(toUTCWithOffset(getCurrentDubaiDate(),1), Number(fields?.userID), "id-prodict", 'admin-viewing-advertise-listing', `Attention admin, user reserved this unit for advertisement.`)
        } else if (newAdvert?.EndDate !== null || newAdvert?.EndDate !== "TBA") {
            const vacantDate = new Date(newAdvert?.EndDate);
            vacantDate.setDate(vacantDate.getDate() + 1); 
            startDate = vacantDate.toISOString().split('T')[0]
            const advertiseEndNotify = toUTCWithOffset(new Date(newAdvert?.EndDate as string), 0);
            const advertiseEndNotifyBeforeDay = new Date(advertiseEndNotify.getTime() - 1 * 24 * 60 * 60 * 1000);
            await sendNotification(toUTCWithOffset(getCurrentDubaiDate(), 1), Number(fields?.userID), Number(fields.unitID), 'user-waiting-list-end-24', 'Check is it advertisement that will be created in 24 hours.')
            await sendNotification(advertiseEndNotify, Number(fields?.userID), undefined, 'user-waiting-list-end', 'We remind you that you have a reserved advertisement, if you do not have time to install the advertisement within 24 hours, we will be forced to give it to other agents.')
            await sendNotification(advertiseEndNotifyBeforeDay, Number(fields?.userID), undefined, 'user-waiting-list-end-heads-up', 'We remind you that you have a reserved advertisement that will be available in 24 hours.')
            const hideDateTimestamp = new Date(newAdvert?.EndDate as string).getTime() + (24 * 60 * 60 * 1000); 
            hideDate = new Date(hideDateTimestamp).toISOString();
        }


        const newWaitingListItem = {
            adId: newAdvert?.id,  
            userID: Number(fields?.userID),
            hideDate,
            startDate,
            status: 'Reserve',
        };

        const bodyObject = {
            method: 'PUT',
            collection: 'units',
            id: Number(fields.unitID),
            body: {
                WaitingList: [...currentWaitingList, newWaitingListItem]
            },
            query: getQueryUnits()
        };
        console.log(bodyObject,"bodyObject << =")

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        });

        const responseJSON = await response?.json();
        console.log(responseJSON,"responseJSON")
        if (responseJSON.success) {
            if (responseJSON.response.error != null) {
                dispatch(dataFetchingFinish());
                return await swalError(getErrorss(responseJSON));
            } else {
                dispatch(dataFetchingFinish());
                return await swalSuccess("You have successfully reserved an advertisement");
            }
        } else {
            dispatch(dataFetchingFinish());
            return await swalError(getErrorss(responseJSON));
        }
    } catch (error) {
        dispatch(dataFetchingFinish());
        return await swalError(getErrorss({}));
    }
};
