import React from "react";
import { locationSelectionImage } from "../../../urls";
import Markdown from "react-markdown";

interface IProps {
    countAreasExtend: undefined | string;
    unitArea: null | string;
}

export default function DescriptionAreasExtend({
    countAreasExtend,
    unitArea,
}: IProps): JSX.Element {
    return (
        <div className={"px-2 text-center  mb-4"}>
            <div className="mb-4 ">
                <div className={"rounded rounded-sm bg-gray-300 p-4"}>
                    <h2>Choose your preferred locations</h2>
                    <img
                        className=" w-100 h-250px object-fit-cover rounded-0 rounded-bottom rounded-top opacity-100"
                        src={locationSelectionImage}
                        alt={"loc_liable"}
                    />

                    <p className={"text-gray-800 fs-3"}>
                        <div className="fw-semi fw-bold  lh-1 mt-2">
                            <Markdown>
                                **Market** and explore listings strategically in
                                these key areas.
                            </Markdown>
                        </div>
                    </p>

                    {unitArea ? (
                        <div className="fw-semi fs-5 lh-1 mt-2">
                            <Markdown>
                                {`• Select **${unitArea.trim()}** and hit the "Save Changes" button below.`}
                            </Markdown>
                        </div>
                    ) : (
                        <div className="fw-semi fs-5  lh-1 mt-2">
                            <Markdown>
                                {`• Click the dropdown menu below to select your top ${Number(countAreasExtend) > 1
                                    ? "preferred districts."
                                    : "preferred district."
                                    }`}
                            </Markdown>
                            <Markdown>
                                {`• Remember, your current subscription allows you to choose up to ${Number(countAreasExtend) > 1
                                    ? "locations."
                                    : " 1 location."
                                    }`}
                            </Markdown>
                            <Markdown>
                                • Each selected location allows you to make
                                advertisement requests for units located in this
                                area.
                            </Markdown>
                            <Markdown>
                                • Ready to make a move? Hit the "Save Changes"
                                button below.
                            </Markdown>
                            <p className={"text-gray-800"}>
                                <div className="fw-semi fw-bold  lh-1 mt-2">
                                    <Markdown>
                                        {`Want to expand your reach? Check out our add-on section
                                to expand your preference beyond ${Number(countAreasExtend) > 1
                                                ? "districts on period up to 30 days."
                                                : " 1 district on period up to 30 days. "
                                            }`}
                                    </Markdown>
                                </div>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
