import { type AppDispatch, type RootState } from '../../../../../store'
import { setScannedData } from '../../../../../slices/modalSlice'

export const addOfferCompanyDocument = (unitID: string | undefined, objectID: string | undefined) => (data: Record<string, unknown>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().modal.documents.length === 0 && objectID === '-') {
        return {
            isSuccessful: false,
            textNotification: 'Document is not valid'
        }
    }
    dispatch(setScannedData({
        ...getState().modal.scannedData,
        formData: data
    }))

    return {
        isSuccessful: true,
        textNavigate: `/agent/modal/agent_set_offer_modal_client_info_company/${unitID ?? '-'}/${objectID ?? '-'}`,
        textNotification: ''
    }
}
