import React, { type FormEvent } from 'react'
import Icon from '../../icon/Icon'
import _ from 'lodash'
import { store } from '../../../store/store'

export interface CustomFileInputProps {
    row: any
    register: any
    errors: any
    getValues: any
}

export default function CustomSingleFileInput({ row, register, errors, getValues }: CustomFileInputProps): React.ReactElement {
    return <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
        <label className={
            (row.required === true)
                ? 'required form-label mb-2 fw-bold text-dark text-uppercase'
                : 'form-label fw-bold mb-2 text-dark text-uppercase'
        }> {row.content?.[0]}</label>

        <div className={'d-flex align-items-center gap-4'}>
            <input
                {...register(row.id, row.required === true ? { required: `Field "${String(row.content?.[0])}" is required` } : {})}
                className="form-control form-control-solid form-control-lg"
                name={row.id}
                onInputCapture={row.onInput != null
                    ? (e: FormEvent<HTMLInputElement>) => {
                        row.onInput(e, getValues)
                    }
                    : () => { }}
                accept={row.id === 'avatar' ? 'image/png, image/gif, image/jpeg' : null}
                type={"file"}
                // placeholder={row.placeholder ?? 'Choose File'}
                multiple={row.multiple != null}
            />
            {
                row.uploaded === true
                    ? <Icon name={'check_circle'} className={'text-muted fs-5x'} />
                    : <></>
            }
            {
                row.uploadedBase64 != null
                    ? <Icon name={'download_for_offline_24dp'} className={'text-muted fs-5x'} onClick={() => {
                        window.open(row.uploadedBase64)
                    }} />
                    : <></>
            }
        </div>
        {_.get(errors, row.id) != null && (
            <div className="text-danger">{_.get(errors, row.id).message}</div>
        )}

    </div>
}
