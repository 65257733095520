import React, { type ReactElement } from 'react'
import ViewModalContactInfo from './ViewModalContactInfo'
import { useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import {
    checkIsHaveEmiratesAndExpires,
    checkIsHavePassportAndExpires
} from '../../../../functions/agent/documents/checkIsHaveDocumentsAndExpires'
import { type Document } from '../../../../store/slices/documentSlice'
import { nanoid } from 'nanoid'

interface OfferClientInfoForChildrenProps {
    offerID: number
}
export default function OfferClientInfoForChildren({ offerID }: OfferClientInfoForChildrenProps): ReactElement {
    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const documents = useAppSelector((state: RootState) => state.documents.documents)
    const offer = offers.find(offer => offer.id === offerID)

    const offerDocumentsIDs = offer?.attributes?.Documents?.data?.map(doc => doc.id) ?? []
    const offerDocuments = offerDocumentsIDs.map(docID => documents.find(doc => doc.id === docID)).filter(Boolean) as Document[] ?? []

    const emiratesIdDoc = checkIsHaveEmiratesAndExpires(offerDocuments).file
    const passportDoc = checkIsHavePassportAndExpires(offerDocuments).file

    return offer != null
        ? <ViewModalContactInfo
            title={'Client'}
            info={[
                offer?.attributes?.Type?.[0]?.FirstName != null || offer?.attributes?.Type?.[0]?.LastName != null
                    ? <>{offer?.attributes?.Type?.[0]?.FirstName} {offer?.attributes?.Type?.[0]?.LastName}</>
                    : <></>,
                offer?.attributes?.Type?.[0]?.BusinessOwnerName != null || offer?.attributes?.Type?.[0]?.BusinessName != null
                    ? <>{offer?.attributes?.Type?.[0]?.BusinessOwnerName} ({offer?.attributes?.Type?.[0]?.BusinessName})</>
                    : <></>,
                offer?.attributes?.Type?.[0]?.Phone != null
                    ? <a href={`tel:${offer?.attributes?.Type?.[0]?.Phone}`} key={nanoid()}>
                        {offer?.attributes?.Type?.[0]?.Phone}
                    </a>
                    : <></>,
                offer?.attributes?.Type?.[0]?.Email != null
                    ? <a href={`mailto:${offer?.attributes?.Type?.[0]?.Email}`} key={nanoid()}>
                        {offer?.attributes?.Type?.[0]?.Email}
                    </a>
                    : <></>,
                emiratesIdDoc?.attributes?.Type[0]?.Number != null
                    ? <>Emirates ID: {emiratesIdDoc?.attributes?.Type[0]?.Number}</>
                    : <></>,
                passportDoc?.attributes?.Type[0]?.Number != null
                    ? <>Passport: {passportDoc?.attributes?.Type[0]?.Number}</>
                    : <></>,
                offer?.attributes?.Type[0]?.Employer != null
                    ? <>Employer: {offer?.attributes?.Type[0]?.Employer}</>
                    : <></>
            ]}
            documents={[
                {
                    docType: 'Emirates ID',
                    document: emiratesIdDoc,
                    ignoreStatus: true
                },
                {
                    docType: 'Passport',
                    document: passportDoc,
                    ignoreStatus: true
                }
            ]}
        />
        : <></>
}
