import { type IFile } from "../../../../types"
import { uploadStrapiFile } from "../../fileActions"

export const uploadSingleFile = async (file: File, refID?: string, collection?: string, entityID?: string): Promise< string | null> => {
    let uploadedFile: IFile | null
    if (refID == null && collection == null && entityID == null) {
        uploadedFile = await uploadStrapiFile(file, 'users')
    } else {
        if (collection != null && entityID != null) {
            uploadedFile = await uploadStrapiFile(file, collection, entityID)
        } else if (refID != null) {
            uploadedFile = await uploadStrapiFile(file, collection, entityID, refID)
        } else {
            uploadedFile = null
        }
    }
    if (uploadedFile != null) {
        return uploadedFile.data
    } else {
        return null
    }
}