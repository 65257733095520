import React, {useState, type ReactElement, useCallback} from "react";
import {Tooltip} from "antd";
import Icon from "../../../app/icon/Icon";
import copyToClipboard from "../../../functions/utils/copyToClipboard";
interface CopyTextProps {
    text: string
}

export default function CopyText ({text}: CopyTextProps): ReactElement {
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout>()
    const copy = useCallback(() => {
        const {isSuccessful} = copyToClipboard(text)
        if (isSuccessful) {
            clearTimeout(timeoutID)
            setTooltipOpen(true)
            setTimeoutID(
                setTimeout(() => {
                    setTooltipOpen(false)
                }, 2000)
            )
        }
    }, [text])
    return <>
        <Tooltip title={'Copied'} open={tooltipOpen} placement={'topLeft'}>
            <span onClick={copy} className={'text-nowrap'}>
                <Icon name={'content_copy'} className={''}/> {text}
            </span>
        </Tooltip>
    </>
}