import MyCarousel from '../../../../app/MyCarousel'
import _ from 'lodash'
import UnitImageCard from './UnitImageCard'
import React from 'react'
import { nanoid } from 'nanoid'

export interface UnitSliderProps {
    unit: any // Replace to IUnit
    inMessage?: boolean
}
const UnitSlider = ({ unit, inMessage = false }: UnitSliderProps): JSX.Element => {
    return (unit.attributes.Images !== undefined
        ? (
            <div key={nanoid()}>
                <MyCarousel showTwoColumns={false}>
                    {
                        _.map(unit.attributes.Images || [], (image: string) => (
                            <UnitImageCard image={image} inMessage={inMessage} key={nanoid()}/>
                        ))
                    }
                </MyCarousel>
            </div>
        )
        : (
            <div className="card-body p-0 d-flex justify-content-center align-items-center h-200px">
                <span className="fw-bolder fs-6 p-1 py-3 ">
                              Not loaded picture
                </span>
            </div>
        )
    )
}

export default UnitSlider
