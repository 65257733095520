export function renderViewingStatus (statuses: string[]): string {
    if (statuses?.includes('Door open')) {
        return 'Door open'
    } else if (statuses?.includes('Digital lock')) {
        return 'Digital lock'
    } else if (statuses?.includes('Occupied')) {
        return 'Occupied'
    } else if (statuses?.includes('Keys in BSO office')) {
        return 'Keys in BSO office'
    } else if (statuses?.includes('Building Management')) {
        return 'Building Management'
    } else if (statuses?.includes('Special Request')) {
        return 'Special Request'
    } else {
        return 'Unit is deleted'
    }
}
