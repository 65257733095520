export default function copyToClipboard(text: string): { isSuccessful: boolean } {
    const textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    let isSuccessful = false
    try {
        document.execCommand('copy')
        isSuccessful = true
        //     console.log('Text copied to clipboard successfully!')
    } catch (err) {
        console.error('Failed to copy text to clipboard:', err)
    }

    document.body.removeChild(textArea)
    return {
        isSuccessful
    }
}