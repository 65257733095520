import { type AppDispatch, type RootState } from '../../../../../store'
import { addOffer } from '../../../../offerActions'
import { getOfferType } from '../../../../../../functions/agent/offers/getOfferType'
import _ from 'lodash'
type AddOfferIndividualOfferDetailsData = Record<string, any>
export const addOfferIndividualOfferDetails = (unitID: string | undefined, objectID: string | undefined) => (data: AddOfferIndividualOfferDetailsData) => async (dispatch: AppDispatch, getState: () => RootState) => {
    // const unit = getState().units.units?.find((unitItem) => unitItem.id === Number(unitID))
    // if (unit != null) {
    // const offerModalType = getOfferType(unit)
    const savedData = _.cloneDeep(getState().modal.scannedData.formData)
    const offerData = {
        ...data,
        ...savedData,
        Type: {
            ...data?.Type,
            ...savedData?.Type
        }
    }
    return await dispatch(addOffer(offerData, 'residential', Number(unitID), objectID !== '-' ? Number(objectID) : undefined))
    /* } else {
        return {
            isSuccessful: false
        }
    } */
}
