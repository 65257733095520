import { ACCESS_KEY } from '../../../urls'

interface SendEmailProps {
    email: string
    templateId: string
    dynamicTemplateData?: object
    attachments?: Array<{
        content: string
        filename: string
        type: string
        disposition: 'attachment'
    }>
}

export default async function sendMail (data: SendEmailProps): Promise<any> {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const response = await fetch('https://bso.ae/api/site/sendMail', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`
        }
    })

    return response
}
