import React from 'react'

const SubscriptionCard = (): JSX.Element => {
    const f = false
    return (
        <div className="card shadow-sm mb-2 position-relative me-n0 mx-4 d-flex flex-row overflow-hidden">
            <div className={'w-90px'}
                style={{
                    background: 'url(https://drive.google.com/uc?id=1arke0MLlUGDaD9b3GfdJsos3wRx39OFW&export=download) no-repeat center',
                    backgroundSize: 'cover'
                }}
            />
            <div className={'d-flex flex-row justify-content-between h-100 p-4'} style={{ width: 'calc(100% - 90px)' }}>
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex align-items-center text-gray-800">
                        <i className="fs-1 ki-duotone ki-safe-home me-2">
                            <i className="path1"></i>
                            <i className="path2"></i>
                        </i>
                        <div className={'fs-7 fw-bold text-truncate'}>
                            SOL Star Building
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-gray-800">
                        <i className="fs-1 ki-duotone ki-map me-2">
                            <i className="path1"></i>
                            <i className="path2"></i>
                            <i className="path3"></i>
                        </i>
                        <div className={'fs-7 fw-bold text-truncate text-gray-600'}>
                            DIP
                        </div>
                    </div>
                    <div className={'d-flex gap-2'}>
                        <i className="fs-1 ki-duotone ki-price-tag">
                            <i className="path1"></i>
                            <i className="path2"></i>
                            <i className="path3"></i>
                        </i>
                        <span className={'btn btn-sm btn-light py-0 px-2'}>1 Bed</span>
                        <span className={'btn btn-sm btn-light py-0 px-2'}>1 Room</span>
                    </div>
                    <div className="d-flex align-items-center text-gray-800">
                        <i className="fs-1 ki-duotone ki-badge me-2">
                            <i className="path1"></i>
                            <i className="path2"></i>
                            <i className="path3"></i>
                            <i className="path4"></i>
                            <i className="path5"></i>
                        </i>
                        <div className={'fs-7 fw-bold text-truncate text-gray-600'}>
                            { f
                                ? 'Not rented'
                                : `${2} tenants`
                            }
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex align-items-center text-gray-800">
                        <i className="fs-1 ki-duotone ki-calendar-tick me-2">
                            <i className="path1"></i>
                            <i className="path2"></i>
                            <i className="path3"></i>
                            <i className="path4"></i>
                            <i className="path5"></i>
                            <i className="path6"></i>
                        </i>
                        <div className={' badge badge-light-success'}>02.02.23 13:00</div>
                    </div>
                    <div className="d-flex align-items-center text-gray-800">
                        <i className="fs-1 ki-duotone ki-calendar-remove me-2">
                            <i className="path1"></i>
                            <i className="path2"></i>
                            <i className="path3"></i>
                            <i className="path4"></i>
                            <i className="path5"></i>
                            <i className="path6"></i>
                        </i>
                        <div className={' badge badge-light-danger'}>02.02.24 13:00</div>
                    </div>
                    <div className="d-flex align-items-center text-gray-800">
                        <i className="fs-1 ki-duotone ki-award me-2">
                            <i className="path1"></i>
                            <i className="path2"></i>
                            <i className="path3"></i>
                        </i>
                        <div className={'badge badge-light-warning'}>Gold</div>
                    </div>
                    <div className="d-flex align-items-center text-gray-800">
                        <i className="fs-1 ki-duotone ki-bill me-2">
                            <i className="path1"></i>
                            <i className="path2"></i>
                            <i className="path3"></i>
                            <i className="path4"></i>
                            <i className="path5"></i>
                            <i className="path6"></i>
                        </i>
                        <div className={'fs-7 fw-bold text-gray-600'}>10000 AED</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionCard
