import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'

import { useParams } from 'react-router-dom'
import Loader from '../../../app/custom/loaders/Loader'
import AgentFooter from '../AgentFooter'
import { useLocation } from 'react-router'
import _ from 'lodash'
import AgentHeader from '../AgentHeader'
import UseUserType from '../../../hooks/UseUserType'
import UnitSh from '../../shared/newUnits/UnitSh'
export default function OtherAgentCatalogUnits(): JSX.Element {
    const { userId } = useParams()
    const units = useAppSelector((state: RootState) => state.units).units
    const currentUser = useAppSelector((state: RootState) => state.user).currentUser
    const { userType, userTypeLowercase } = UseUserType()
    const [filteredUnits, setFilteredUnits] = useState<any>([])

    useEffect(() => {
        if (units != null && Array.isArray(units)) {
            const filtered = units.filter((el) => el.attributes.User.data?.id === Number(userId))
            setFilteredUnits(filtered)
        }
    }, [units, currentUser])

    return (
        <>
            <div className="page d-flex flex-column flex-row-fluid">
                <AgentHeader
                    active="Agent catalog"
                />
                <div className="content d-flex flex-column flex-fill p-0 m-4 mb-0">

                    {filteredUnits && filteredUnits.length > 0
                        ? (
                            filteredUnits.map((unit: any) => {
                                return (


                                    <UnitSh
                                        key={unit.id}
                                        hide={true}
                                        unit={userType === 'Agent' ? unit : { id: unit.id, attributes: unit }}
                                        fromEntry={'catalogAgent'}
                                    />
                                )
                            })
                        )
                        : (
                            <div className="card shadow-sm my-1">
                                <div className="card-body p-0 m-5 text-center">
                                    <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 ">
                                        No units for you
                                    </span>
                                </div>
                            </div>
                        )
                    }
                    <div className="d-none"></div>
                </div>

                <div className="m-0" style={{ height: '115px' }}></div>

            </div>
            <AgentFooter />
        </>
    )
}
