import React, { type ReactElement } from 'react'
import { useAppSelector } from '../../../hooks/redux'

export default function UnitsNotFoundMessageSh (): ReactElement {
    const searchString = useAppSelector((state) => state.units.searchString)
    const searchStringEmpty = searchString.trim().length === 0
    return (
        <div className="card shadow-sm my-1">
            <div className="card-body p-0 m-5 text-center">
                <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 ">
                    {
                        searchStringEmpty
                            ? 'Nothing was found by your filtering preset'
                            : 'Nothing was found by your search request'
                    }
                </span>
            </div>
        </div>
    )
}
