import React from 'react'
import { getSubsDetails } from '../../../../functions/agent/subscriptions/getSubsDetails'

interface IProps {
    selectedSubscription: string
    selectedPeriod: string
    setSelectedSubscription: any
    activeSub: string
}

export default function GetDescriptions ({
    activeSub,
    selectedPeriod,
    selectedSubscription,
    setSelectedSubscription
}: IProps): JSX.Element {
    // console.log(selectedSubscription, activeSub)

    return (
        <>
            <div className="pt-1 px-2 d-flex flex-column justify-content-between mt-2">
                <div className="d-flex align-items-center ">
                    <span className="fw-semibold fs-5 text-white flex-grow-1">
            Unit Broadcast
                    </span>
                    <i className="ki-duotone ki-check-circle fs-3x text-white">
                        <span className="path1"></span>
                        <span className="path2 "></span>
                    </i>
                </div>
                <div className="separator separator-dashed my-2"></div>
                <div className="d-flex align-items-center ">
                    <span className="fw-semibold fs-5 text-white flex-grow-1">
            Online Chat
                    </span>

                    <i className="ki-duotone ki-check-circle fs-3x text-white">
                        <span className="path1"></span>
                        <span className="path2 "></span>
                    </i>
                </div>
                <div className="separator separator-dashed my-2"></div>
                <div className="d-flex align-items-center">
                    <span className="fw-semibold fs-5 text-white flex-grow-1">
            Scheduler
                    </span>
                    <i className="ki-duotone ki-check-circle fs-3x text-white">
                        <span className="path1"></span>
                        <span className="path2 "></span>
                    </i>
                </div>
                <div className="separator separator-dashed my-2"></div>

                <div className="d-flex align-items-center">
                    <span className="fw-semibold fs-5 text-white flex-grow-1">
            Active Areas{' '}
                    </span>

                    {selectedSubscription === 'premium'
                        ? (
                            <span className="badge badge-lg badge-light-primary">
                                {getSubsDetails(selectedSubscription).areas}
                            </span>
                        )
                        : (
                            <span className={`badge ${selectedSubscription === 'Premium' ? '' : 'badge-circle'}   badge-outline badge-light-primary badge-lg fs-6`}>
                                {getSubsDetails(selectedSubscription).areas}
                            </span>
                        )}
                </div>
                <div className="separator separator-dashed my-2"></div>
                <div className="d-flex align-items-center mb-2">
                    <span className="fw-semibold fs-5 text-white flex-grow-1">
            Advertise Coins{' '}
                    </span>

                    <span className="badge badge-circle badge-outline badge-light-primary badge-lg fs-6 ">
                        {(getSubsDetails(selectedSubscription) as any).coins}
                    </span>
                </div>

                {/* <div className="separator separator-dashed my-2"></div>
                <div className="d-flex align-items-center ">
                    <span className="fw-semibold fs-5 text-gray-800 flex-grow-1">
            Limitations on viewings{' '}
                    </span>
                    <i className="ki-duotone ki-cross-circle  fs-2x text-white">
                        <span className="path1 text-gray-800"></span>
                        <span className="path2"></span>
                    </i>
                </div>
                <div className="separator separator-dashed my-2"></div>
                <div className="d-flex align-items-center ">
                    <span className="fw-semibold fs-5 text-gray-800 flex-grow-1">
            Limitations on areas{' '}
                    </span>
                    <i className="ki-duotone ki-cross-circle fs-2x text-white">
                        <span className="path1 text-gray-800"></span>
                        <span className="path2"></span>
                    </i>
                </div> */}
            </div>
        </>
    )
}
