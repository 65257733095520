import React from 'react'
import AgentHeader from '../../agentModule/AgentHeader'
import { Collapse } from 'antd'
import { Link } from 'react-router-dom'
import useUserType from '../../../hooks/UseUserType'
import faqConfig from './faqConfig.json'
import { nanoid } from 'nanoid'

export default function FAQ(): React.ReactElement {
    const { userTypeLowercase } = useUserType()
    //  console.log(faqConfig)
    return (
        <div className="page d-flex flex-column flex-row-fluid fs-5">
            <AgentHeader
                active="F.A.Q."
            />
            <div className="content d-flex flex-column flex-fill p-0 m-4">
                {
                    faqConfig.children.map((category) => (
                        <div className="card shadow-sm mb-2" key={nanoid()}>
                            <Collapse>
                                <Collapse.Panel
                                    style={{ fontFamily: 'Poppins' }}
                                    showArrow={false}
                                    header={
                                        <span className="card-title fs-3 fw-bold fw-bold p-0 m-0 ">
                                            {
                                                (category.name[0].toUpperCase() + category.name.substring(1)).replaceAll('_', ' ')
                                            }
                                        </span>
                                    }
                                    key="5"
                                >
                                    {
                                        category.children.map((article, index) => (
                                            <>
                                                <Link to={`/${userTypeLowercase}/modal/faq_modal/-/${category.name}-${article.name.replace('.md', '')}`} key={nanoid()}>
                                                    <div>
                                                        {
                                                            (article.name[0].toUpperCase() + article.name.substring(1)).replaceAll('_', ' ').replaceAll('~', '/').replace('.md', '')
                                                        }?
                                                    </div>
                                                </Link>
                                                {
                                                    index !== category.children.length - 1
                                                        ? <div className="separator separator-dashed my-4"></div>
                                                        : <></>
                                                }
                                            </>
                                        ))
                                    }
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                    ))
                }
            </div>
            <div className="m-0" style={{ height: '170px' }}></div>
        </div>
    )
}
