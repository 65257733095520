import { timeStringInScheduler } from '../../functions/agent/date/timeStringInScheduler'

const swalTexts = {
    error: {
        title: 'Error',
        message: "Sorry, we can't proceed with your request right now. Kindly wait for a moment and <strong> try again </strong> shortly.",
        confirmButtonText: 'Ok'
    },

    success: {
        title: 'Success',
        message: 'It\'s ok',
        confirmButtonText: 'Ok'
    },

    successViewingsActions: {
        createViewing: {
            defaultMessage: 'Wait for the viewing confirmation',
            specialRequest:   "<strong>Attention!</strong> <br/>To arrange a viewing of this unit please <strong>call us</strong> for instructions on <strong>+971 52 647 7228</strong>",
            digitalLook: "Your viewing request has been successfully submitted.<br/> Please note that the unit you selected <strong>requires Digital Lock Password</strong> . Shortly after confirmation, we will send you a <strong>Digital Lock Password</strong> that you can use <strong>to enter</strong> the apartment.<br/>To get further instruction please call to this phone number: <strong>+971 52 647 7228</strong>" ,
            keysInBSO: "Your viewing request has been successfully submitted.<br/>Please note that the unit you selected <strong>requires physical keys</strong> . We will <strong>confirm</strong>  or <strong>schedule another time</strong>  for viewing, time <strong>pickup</strong> and return of the keys.<br/>To get further instruction please call to this phone number: <strong>+971 52 647 7228</strong>",
            buildingManagement: "Your viewing request has been successfully submitted.<br/>Please note that you have selected a vacant unit which is currently <strong>under building management</strong>. We will <strong>confirm</strong>  or <strong>schedule another time</strong> for viewing.",
            occupied: "Your viewing request has been successfully submitted.<br/>Please note that you have selected <strong>an occupied unit</strong>. We will <strong>confirm</strong> or <strong>schedule another time</strong>  for viewing. <br/>To arrange a viewing of this unit please <strong>call us</strong> for instructions on <strong>+971 52 647 7228</strong>",
            title: 'Viewing has been created'
        },
        updateViewing: {
            defaultMessage: 'Viewing time has been changed'
        },
        scanQrCodeForPickUpKeysViewing: {
            defaultMessage: 'You have successfully completed key collection process',
            errorMessage: 'QR-code not recognized, please try again and follow scanning instructions in screen below'
        }
    },
    successDocumentsActions: {

    },
    agentNotices: {
        expiryDocument: 'Your document is expiring, please update it to continue to use the full range of functionality of the application!'
    },
    // reschedule viewings popup
    viewingsWindow: {
        title: (propertyHeadline: string) => `Notification of changes to review by <strong>${propertyHeadline}</strong>`,
        dateMessage: (dateTimeViewing: string, propertyHeadline: string) => `The new time for your viewing in the unit: <strong>${propertyHeadline}</strong> changed to <strong>${timeStringInScheduler(dateTimeViewing)}</strong>. Do you agree with that time?`, // `The time of your viewings has changed, you were invited to <strong>${timeStringInScheduler(dateTimeViewing)}</strong>`,
        cancelButtonText: 'No, cancel this viewing',
        confirmButtonText: 'Yes, I agree',
        denyButtonText: 'Choose other time',
        DateText: (dateTimeViewing: string, propertyHeadline: string) =>
            `Unfortunately, you didn\'t react in time to the viewing rescheduled request for the viewing in the unit: <strong>${propertyHeadline}</strong>  on
          <strong>${timeStringInScheduler(dateTimeViewing)}</strong>, please choose another time for the viewing or cancel it.`,
        StatusText: 'Unfortunately you did not react in time to overwrite the view, the unit is no longer available',
        defaultTextIsManagerHasNotBeenAble: 'Our manager was not able to respond within the timeline.\n Kindly reschedule your viewing. '

    },

    // popup if not loaded documents
    needLoadDocs: {
        title: 'Documents missing!',
        message: 'Please upload your Emirates ID or Passport to unlock functionalities and features',
        confirmButtonText: 'Upload now',
        cancelButtonText: 'Got it, maybe later'
    },
    // popup if  documents in process verification
    inProgress: {
        title: 'Attention',
        message: 'Please wait for confirmation of your documents to gain access to this application\'s functionality',
        messageForRera: 'Please wait for confirmation of your RERA Card to gain access to this application\'s functionality',
        confirmButtonText: 'OK, got it'
    },

    //  click to google maps in unit show modal
    justAlertAndRedirect: {
        message: 'No link has been added to this property',
        messageAfterUpgradeSubscription: (plan: string) => `You have successfully set the <strong>${plan}</strong> plan`,
        title: 'We\'re sorry',
        icon: 'warning',
        confirmButtonText: 'Ok, got it',
        confirmButtonAfterChooseStandardPlan: 'Ok, go to choose areas'
    },

    // deal  pop up if rera not loaded, and we click on the ad creation
    needRera: {
        title: 'Attention',
        confirmButtonText: "Share unit's details" , //  'Take unit\'s portfolio',
        cancelButtonText: 'Ok, got it',
        denyButtonText: 'Upload RERA now',
        // messageLicenseLoaded:`You should <strong>upload</strong> your <strong>RERA Card to get access</strong> to this app\'s functionality.
        // However, you can simply download <strong>photos</strong> of the property`,
        messageLicenseLoaded:  `In this case, you will <strong>only</strong> share <strong> portfolio of the unit</strong> without official <strong> advertisement </strong>`,
        messageLicenseReject: 'Your RERA Card is <strong>rejected</strong> by BSO, please <strong>upload valid</strong> document to gain access to this application\'s functionality',
        messageLicenseExpire: 'Your RERA Card is <strong>expired</strong>, please <strong>upload valid</strong> document to gain access to this application\'s functionality'
    },

    // alert if you want to leave
    sureLogout: {
        title: 'Are you sure?',
        message: 'If you leave now, you lose your unsaved progress',
        confirmButtonText: 'Cancel',
        denyButtonText: 'Confirm'
    },
    sureDelete: {
        title: 'Are you sure?',
        message: 'By confirming, you agree to delete the entire account, all data, transactions, documents will be deleted.',
        confirmButtonText: 'No, cancel.',
        denyButtonText: 'Yes, confirm account deletion.'
    },

    attentionLeave: {
        title: 'Attention',
        confirmButtonText: 'Confirm', // yes  // yellow
        denyButtonText: 'Cancel', // no // secondary
        messageModalFooterClose: 'Are you sure you want to leave? Progress can be lost',
        messageCustomModalForView: 'Are you sure that you want to cancel viewing?',
        messageForIsSureForCustomModal: 'You will be taken to one step back after confirmation', // 'Are you sure about this, the data will be reset',
        messageForAgentButtonActions: 'You haven\'t purchased the area of this unit for advertisement. Do you want to purchase this area and make an advertisement in a couple of clicks?',
        messageForClearRowLinkAdvertise: 'Are you sure to delete this Ad Trakheesi ID?',
        // alert if you want deleted links on advertise
        messageOnRemoveAdvertise: 'Are you sure you want to submit a request for ad removal verification?',
        messageForAddStartAdvertisement: 'Specify the start date of the advertisement'
    },
    rejectedDoc: {
        title: 'Attention',
        confirmButtonText: 'OK, Upload another',
        denyButtonText: 'Another time',
        documentMessageRej: (document: string) => `Your ${document} did rejected, please update documents, to gain access to this application's functionality `,
        documentMessageExp: (document: string) => `Your ${document}  has expired, please update documents, to gain access to this application's functionality `
    },
    verifyEmail: {
        title: 'Verify your email address',
        message: 'Please check your inbox and <strong>verify</strong> your email address to proceed',
        confirmButtonText: 'Check verification status',
        denyButtonText: 'Change your email'
    },

    /*
warning popup
    */

    mainDeal: {
        title: 'Attention',
        // used in the subscription selection and renewal component
        textSubscriptionListRenew: (terminate: any) => terminate ? 'Are you sure you want to terminate your active package with the current functionality and switch to a limited basic subscription?' : 'Are you sure about choosing this subscription package?',
        titleSubscriptionList: 'Attention',
        confirmButtonSubscriptionList: (isFree: boolean) => isFree ? "Yes, let's set my areas" : "Yes, let's pay and set my areas",
        denyButtonSubscriptionList: 'No, I want to choose another package',

        // settings =>  agent profile  => active areas => update button
        textAgentProfileSettings: `You can purchase additional areas for expanding the list of units available for advertising campaigns in BSO Catalog and also make you feel more confident in case of any hot offer.
         Want to do it now?`,
        titleAgentProfileSettings: 'Want to buy an extra district?',
        // custom validator hook  comp

        // here condition
        // deal for load Rera  after choosing a subscription and and just pop up after registration
        textCustomValidatorHook: (id: string) => id === 'agent_settings_for_subscriptions' ? 'Congratulations on selecting your preferred areas! To unlock the full functionality of the app and attain a Licensed BSO broker status, please upload your valid RERA card. Ready to upload now?' : 'Do you want to load the RERA license for full app usage?',
        titleCustomValidatorHook: 'Do you have a RERA card?',
        confirmButtonCustomValidatorHook: 'Yes, upload RERA card now',
        denyButtonCustomValidatorHook: 'No, I may do it later',

        defaultconfirmButtonText: 'Yes',
        defaultdenyButtonText: 'Maybe later' // 'Not now'
    },

    feedbackPopup: {
        // first lable
        title: (unitAddress: string, date: string) => `Viewing review
        Please complete this short quiz about the following viewing.
Viewing Unit: <strong>${unitAddress}</strong>
Viewing Time:  <strong>${timeStringInScheduler(date)}</strong>
`,
        htmlMessage: 'How was the viewing result?',
        confirmButtonText: 'Viewing completed',
        denyButtonText: 'Viewing not completed',
        questions: {
            // then lable if you clicked on  'Viewing take place',
            viewingTakePlace: {
                // Viewing take place lable
                title: (unitAddress: string, date: string) => `Viewing review
                Please complete this short quiz about the following viewing.
        Viewing Unit: <strong>${unitAddress}</strong>
        Viewing Time:  <strong>${timeStringInScheduler(date)}</strong>
        `,
                html: 'How was the overall viewing experience?',
                confirmButtonText: 'Everything went well',
                denyButtonText: 'There were some issues',
                viewingTakePlaceWasOkay: {
                    // then lable if you clicked on  'Viewing was okay',
                    title: (unitAddress: string, date: string) => `Viewing review
        Please complete this short quiz about the following viewing.
Viewing Unit: <strong>${unitAddress}</strong>
Viewing Time:  <strong>${timeStringInScheduler(date)}</strong>
`,
                    html: 'Are your client ready to make an offer for this unit?',
                    confirmButtonText: 'Yes, create a new offer',
                    denyButtonText: 'No',
                    feedbackViewingTakePlaceWasOkayMakeOffer: 'Viewing completed - Everything went well - Potential client is considering other options because of: Potential client interested to submit offer',

                    textNotifyViewingTakePlaceWasOkayMakeOffer: (userName: string, unitAddress: string) => `User: ${userName} complete his viewing: Unit: ${unitAddress} Check his review in app!`,
                    viewingTakePlaceWasOkaySubmitTheAnswer: {
                        title: (unitAddress: string, date: string) => `Viewing review
        Please complete this short quiz about the following viewing.
Viewing Unit: <strong>${unitAddress}</strong>
Viewing Time:  <strong>${timeStringInScheduler(date)}</strong>
`,
                        inputLabel: 'Why doesn\'t your client want to make an offer for this unit?',
                        inputOptions: {
                            'Potential client is considering other options because of:': {
                                'Maintenance issues': 'Maintenance issues',
                                'Unit is small': 'Unit is small',
                                'Unit has not have good view': 'Unit has not have good view',
                                'Unit not clean': 'Unit not clean',
                                'Other reason': 'Other reason'
                            }
                        },
                        // if agent choosed "Other reason" popup  ,
                        viewingTakePlaceWasOkaySubmitTheAnswerOtherReason: {
                            title: (unitAddress: string, date: string) => `Viewing review
        Please complete this short quiz about the following viewing.
Viewing Unit: <strong>${unitAddress}</strong>
Viewing Time:  <strong>${timeStringInScheduler(date)}</strong>
`,
                            inputLabel: 'Feel free to write down your own answer',
                            confirmButtonText: 'Submit the answer'
                        },
                        feedbackViewingTakePlaceWasOkaySubmitTheAnswerReason: (reason: string) => `Viewing completed - Everything went well - Potential client is considering other options because of: ${reason}`
                    }
                },
                // then lable if you clicked on  'Viewing was not okay',
                viewingTakePlaceWasNotOkay: {
                    title: (unitAddress: string, date: string) => `Viewing review
        Please complete this short quiz about the following viewing.
Viewing Unit: <strong>${unitAddress}</strong>
Viewing Time:  <strong>${timeStringInScheduler(date)}</strong>
`,
                    inputLabel: 'Why doesn\'t your client want to make an offer for this unit?',
                    inputOptions: {
                        'Maintenance issues': 'Maintenance issues',
                        'Unit is small': 'Unit is small',
                        'Unit has not have good view': 'Unit has not have good view',
                        'Unit not clean': 'Unit not clean',
                        'Other reason': 'Other reason'
                    },

                    feedbackViewingTakePlaceWasNotOkaySubmitTheAnswerReason: (reason: string) => `Viewing completed - There were some issues because of: ${String(reason)}`,
                    viewingTakePlaceWasNotOkaySubmitTheAnswerOtherReason: {
                        title: (unitAddress: string, date: string) => `Viewing review
        Please complete this short quiz about the following viewing.
Viewing Unit: <strong>${unitAddress}</strong>
Viewing Time:  <strong>${timeStringInScheduler(date)}</strong>
`,
                        inputLabel: 'Input the reason like freely answer in textarea',
                        confirmButtonText: 'Submit the answer'
                    }
                }
            },

            // then lable if you clicked on  'Viewing did not take place',
            viewingDidNotTakePlace: {
                title: (unitAddress: string, date: string) => `Viewing review
        Please complete this short quiz about the following viewing.
Viewing Unit: <strong>${unitAddress}</strong>
Viewing Time:  <strong>${timeStringInScheduler(date)}</strong>
`,
                inputLabel: 'Why is this viewing not completed?',
                inputOptions: {
                    'Time management issues': 'Time management issues',
                    'Property access issues': 'Property access issues'
                },
                confirmButtonText: 'Submit the answer',
                inputPlaceholder: 'Select a reason',
                feedbackViewingViewingDidNotTakePlace: (reason: string) => `Viewing is not completed because of: ${String(reason)}`
            }
        }
    },
    chatSearchInfo: {
        title: 'Chat Search Help Info',
        html: `Hi! In this app section, you can search for new <b>brokers</b> to chat with, old <b>messages</b> to relate to and check who works in the same <b>areas</b> as you. <br/> <br/> 
                <ul style="text-align:start">
                    <li>For new <b>brokers</b>: You can search by user's name</li>
                    <li>For <b>messages</b>: You can search by message text</li>
                    <li>For <b>areas</b>: You can search by area name</li>
                </ul><br/>
                Good luck with your search!`
    },
    negotiateOfferSwal: {
        title: {
            byLandlord: 'Your offer was negotiated by landlord',
            byBSO: 'Your offer was negotiated by BSO'
        },
        cancelButtonText: 'Decide later',
        confirmButtonText: 'Yes I agree',
        inputLabel: 'Your price',
        denyButtonText: 'Negotiate offer',

        negotiateCanceled: {
            message: 'You have successfully canceled your offer'
        },
        negotiateConfirmed: {
            message: 'You have successfully agreed on your offer'
        },
        negotiateNegotiated: {
            message: 'Offer has been successfully sent to negotiation'
        }
    },
    confirmedOfferSwal: {
        title: 'Your offer was confirmed by BSO',
        message: (price: number) => 'Our team prepared all the documents for your offer, please follow the instructions:<br>' +
            '1. Inform your tenant about signing the booking form that we already sent to his email<br>' +
            `2. Inform your tenant that after signing the booking form he should send to BSO the booking deposit in the amount of <strong>${price.toFixed(2)} AED</strong><br>` +
            'It needs to be paid to the following bank:<br>' +
            'Bank Name: First Abu Dhabi Bank<br>' +
            'Account Name: BSO Real Estate Management<br>' +
            'Account No.: <strong>1611323950980015</strong><br>' +
            'IBAN No.:<strong> AE270351611323950980015</strong><br>' +
            'Swift Code: <strong>NBADAEAAXXX</strong><br>' +
            '3. Upload the check transfer slip received from your client at the offer page in your scheduler<br>' +
            '<br>' +
            'Now please send us your client\'s contact info so that we can send him the booking form for signing, don’t worry this action is 100% safe for your client\'s personal data.<br>',
        confirmedSuccess: {
            message: 'Your client’s info has been successfully submitted to BSO'
        },
        confirmButtonText: 'Submit',
        cancelButtonText: 'Submit later'
    },
    signingFFormOfferSwal: {
        title: 'We are signing the F form for offer',
        confirmButtonText: 'OK'
    },
    slipApprovedOfferSwal: {
        title: 'Deposit slip is approved for offer',
        confirmButtonText: 'OK'
    },
    otherDocumentsOfferSwal: {
        title: 'We are signing other documents for offer',
        confirmButtonText: 'OK'
    },
    obtainingNOCOfferSwal: {
        title: 'We are obtaining NOC for offer',
        confirmButtonText: 'OK'
    },
    downpaymentOfferSwal: {
        title: 'We are processing downpayment for offer',
        confirmButtonText: 'OK'
    },
    transferProcessOfferSwal: {
        title: 'We are processing transfer for',
        message: 'We are processing transfer for offer',
        confirmButtonText: 'OK'
    },
    offerExpiredSwal: {
        message: (headline: string) => `Sorry, you missed the deadline to complete this offer on <strong>${headline}</strong> <br/>Wait until our team make decision regarding this offer.`,
        confirmButtonText: 'Ok, got it'
    },
    waitingPaySlipOfferSwal: {
        title: {
            firstTime: 'Has your client completed the deposit transfer for the following offer?',
            wasRejected: 'The transfer for the following offer deposit was rejected by BSO'
        },
        message: (price: number, wasRejected: boolean) => 'Please make sure that all the following instructions are finished:<br>' +
            '1. Inform your tenant about signing the booking form that we already sent to his email<br>' +
            `2. Inform your tenant that after signing the booking form he should send to BSO the booking deposit in the amount of ${price.toFixed(2)} AED<br>` +
            'It needs to be paid to the following bank:<br>' +
            'Bank Name: First Abu Dhabi Bank<br>' +
            'Account Name: BSO Real Estate Management<br>' +
            'Account No.: 1611323950980015<br>' +
            'IBAN No.: AE270351611323950980015<br>' +
            'Swift Code: NBADAEAAXXX<br>' +
            '3. Upload the check transfer slip received from your client at the offer page in your scheduler<br>' +
            '<br>' +
            (wasRejected
                ? 'Now please upload a correct check transfer slip for an offer deposit in the form below.<br>'
                : 'Now please upload a check transfer slip for an offer deposit in the form below.<br>'),
        confirmButtonText: 'Upload transfer slip',
        cancelButtonText: 'Upload later',
        inputLabel: 'Upload check/transfer slip',
        slipCorrect: {
            message: 'Your client’s deposit transfer slip has been uploaded successfully'
        },
        slipIncorrentValidationMessage: 'Enter a valid document'
    },
    offerCancelSwal: {
        title: 'Attention',
        message: {
            ratingAffected: 'This action can affected your rating',
            ratingNotAffected: 'Are you sure you want to cancel this offer?'
        },
        confirmButtonText: 'Yes',
        denyButtonText: 'Not now'
    },
    offerCompletedSwal: {
        title: 'After your client get the keys and send your roualty to you please open offer in app and set status to Completed'
    },
    scanDocumentsForOfferSwal: {
        title: 'Scan the client\'s emirates ID or Passport',
        message: (isEdit?: boolean) => [
            'The Scanned image will be used to autofill the offer form. ',
            (isEdit === true ? '' : '<br>If you would like to fill it manually feel free to skip this step.'),
            'Emirates ID',
            'Passport'
        ],
        cancelButtonText: 'Skip',
        validationMessage: 'Enter a valid document'
    },
    addOfferResultSwal: {
        error: {
            message: (emiratesID: string) => `Client's Emirates ID number - ${emiratesID} - is not valid. Check the fields Client Nationality, Client Emirates ID, Client Birth Day`
        }
    },
    offerApprovedSwal: {
        title: 'Offer is approved'
    }

    /*

*/

    // until not using swals texts
    // dataAfterUpdateDocs: {
    //     title: (text: string) => `Data from documents ${text}`,
    //     message: 'Check your data, do you want to keep it?',
    //     confirmButtonText: 'Save',
    //     denyButtonText: 'Update'
    // },
}

export default swalTexts
