import { type IAdvertise } from '../../../store/slices/advertisesSlice'

export interface StateLink {
    Title?: string
    Link?: string
    Status: string
    Trakheesi_id: string
}

export const reduceLinks = (currentAdvertise: IAdvertise) => {
    let stateLinks: StateLink[] = []

    if (currentAdvertise.attributes.hasOwnProperty('Links') && currentAdvertise.attributes.Links !== null) {
        if (Array.isArray(currentAdvertise.attributes.Links)) {
            stateLinks = currentAdvertise.attributes.Links as StateLink[]
        } else if (typeof currentAdvertise.attributes.Links === 'object') {
            stateLinks = [currentAdvertise.attributes.Links] as StateLink[]
        }
    }

    // console.log(stateLinks, 'stateLinks')
    const mappedLinks: StateLink[] = stateLinks.map((el) => {
        const returnObject: StateLink = {
            Title: '',
            // Link: '',
            Trakheesi_id: '',
            Status: ''
        }
        if (el !== null) {
            if (el.hasOwnProperty('Status') && el.Status !== '') {
                returnObject.Status = el.Status
            }
            if (el.hasOwnProperty('Title') && el.Title !== '') {
                returnObject.Title = el.Title
            }
            // if (el.hasOwnProperty('Link') && el.Link !== '') {
            //     returnObject.Link = el.Link
            // }
            if (el.hasOwnProperty('Trakheesi_id') && el.Trakheesi_id !== '') {
                returnObject.Trakheesi_id = el.Trakheesi_id
            }
        }

        return returnObject
    })

    return mappedLinks
}
