import React, { type ReactNode } from 'react'
import VoicePlayer from './index'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'

interface VoicePlayerLayoutProps {
    children: ReactNode
}

const VoicePlayerLayout = ({ children }: VoicePlayerLayoutProps): JSX.Element => {
    const { isShown } = useAppSelector((state: RootState) => state.voice)
    return (
        <>
            {isShown
                ? <>
                    <VoicePlayer/>
                    <div style={{ height: '31px', width: '100%', background: '#FFFFFF' }}/></>
                : <></>
            }
            { children }
        </>
    )
}

export default VoicePlayerLayout
