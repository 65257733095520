import { createSlice } from '@reduxjs/toolkit'

interface VoiceState {
    activeId: string | null
    isShown: boolean
    isPlaying: boolean
    audioSrc: string | null
    duration: number
    currentTime: number
    messageDate: string | null
    messageAuthor: string | null
    timeJump: number | null

}

const initialState: VoiceState = {
    activeId: null,
    isShown: false,
    isPlaying: false,
    audioSrc: null,
    duration: 0,
    currentTime: 0,
    messageDate: null,
    messageAuthor: null,
    timeJump: null
}

export const voiceSlice = createSlice({
    name: 'voice',
    initialState,
    reducers: {
        setActiveId (state, action) {
            state.activeId = action.payload
        },
        setIsShown (state, action) {
            state.isShown = action.payload
        },
        setIsPlaying (state, action) {
            state.isPlaying = action.payload
        },
        setAudioSrc (state, action) {
            state.audioSrc = action.payload
        },
        setDuration (state, action) {
            state.duration = action.payload
        },
        setCurrentTime (state, action) {
            state.currentTime = action.payload
        },
        setMessageDate (state, action) {
            state.messageDate = action.payload
        },
        setMessageAuthor (state, action) {
            state.messageAuthor = action.payload
        },
        setTimeJump (state, action) {
            state.timeJump = action.payload
        }
    }
})

export const {
    setActiveId,
    setIsShown,
    setIsPlaying,
    setAudioSrc,
    setDuration,
    setCurrentTime,
    setMessageDate,
    setMessageAuthor,
    setTimeJump
} = voiceSlice.actions

export default voiceSlice.reducer
