import { AgentModals } from '../../agent/AgentModals'
import _ from 'lodash'
import { store } from '../../../store/store'

const magicFillCheckLinks = (advertiseID: number, modalID: string): { modal: Record<string, unknown> } => {
    const modal = _.cloneDeep(
        _.find(AgentModals(),
            (modal: any) => modal.id === modalID
        )
    )
    const advertise = _.find(
        store.getState().advertises.advertises,
        (advertise: any) => advertise.id === advertiseID
    )
    if (advertise?.attributes.Links != null) {
        const linkRows = _.map(advertise?.attributes.Links, (link: any, index) => (
            {
                id: `Links[${index}].Status`,
                content: [link.Trakheesi_id, link.Title, link.Trakheesi_id],
                default: link.Status === 'Approved',
                element: 'check-link'
            }
        ))
        modal.form = [
            ...linkRows,
            ...modal.form
        ]
    }
    return {
        modal
    }
}

export default magicFillCheckLinks
