import React, { useState } from 'react'
import { Modal } from 'antd'

import PrivacyPolicy from './PrivacyPolicy'
import TermsAndConditions from './TermsAndConditions'
import BSOAbout from './BSOAbout'

export default function RulesModals ({ row }: any): JSX.Element {
    const [modalOpen, setModalOpen] = useState(false)

    return (
        <>

            { row === 'About BSO Club'
                ? <div onClick={() => { setModalOpen(true) }} > {row} </div>
                : <span onClick={() => {
                    setModalOpen(true)
                }} className="d-inline-block position-relative ms-1 text-decoration-none">
                    <div
                        className="d-inline-block  fw-bold"
                    >
                        {row}
                    </div>
                    <span className="d-inline-block position-absolute h-2px bottom-0 end-0 start-0 bg-bso translate rounded"></span>
                </span> }

            <Modal
                centered
                open={modalOpen}
                // maskStyle={{ height: '100vh', left: 0 }}
                style={{ height: '100vh', left: 0 }}
                width="100vw"
                zIndex={1501}
                closable={false}
                footer={null}
            >
                {row === 'Privacy Policy' ? <PrivacyPolicy onCancel={() => { setModalOpen(false) }}/> : row === 'Terms & Conditions' ? <TermsAndConditions onCancel={() => { setModalOpen(false) }}/> : <BSOAbout onCancel={() => { setModalOpen(false) }}/>}
            </Modal>

        </>
    )
}
