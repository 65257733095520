import React from 'react'
import { type ISettings } from '../../../../store/slices/userSlice'
import Icon from '../../../../app/icon/Icon'
interface IProps {
    settings: ISettings[]
}
function Wallet ({ settings }: IProps): JSX.Element {
    return (
        <div className="fw-bold px-2 text-white pt-2">
            <div className="d-flex justify-content-between align-items-center ">
                <div className={'fs-2hx text-center mt-2'}>Wallet</div>
                <span className="mt-2">
                    <Icon name={'wallet'} filled className={'text-white fs-4x'}/>
                </span>
            </div>
            <div className="d-flex justify-content-between my-1 align-items-center">
                <span className="fs-5x">    {settings?.[0]?.BSOCredits ?? '0'}</span>
                <span className="fs-1 lh-1 text-end ">BSO Credits</span>
            </div>
            {/* <div className="text-end"> */}
            {/*    <div className=" btn btn-sm w-75 px-0 fs-5 fw-bold bg-white">Refer a client</div> */}
            {/* </div> */}
        </div>
    )
}

export default Wallet
