import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import styles from './videoPlayer.module.css'
import ChatUtils from '../../../functions/chats/ChatUtils'
import { setFullscreenVideo } from '../../../store/slices/appSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { Modal } from 'antd'
import Icon from '../../../app/icon/Icon'
import FullScreenPlayer from './FullScreenPlayer'

export interface VideoPlayerProps {
    video: string
    name: string
    time?: Date
    sentBy?: 'me' | 'other'
    smallSize?: boolean
    isFullScreen?: boolean
    closeParentFullScreen?: () => void
}

const VideoPlayer = ({ smallSize = false, video, name, time, sentBy, isFullScreen = false, closeParentFullScreen = () => {} }: VideoPlayerProps): JSX.Element => {
    const [playing, setPlaying] = useState(false)
    const [progress, setProgress] = useState(0)
    const [seekeing, setSeekeing] = useState(false)
    const [controlsOpen, setControlsOpen] = useState(false)
    const playerRef = useRef<ReactPlayer>(null)
    const progressRef = useRef<HTMLDivElement>(null)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const dispatch = useAppDispatch()
    const { fullscreenVideo } = useAppSelector((state: RootState) => state.app)
    const small = !fullscreenVideo && smallSize
    const setTime = (e: any): void => {
        //  console.log('setTime')
        setSeekeing(false)
        if (progressRef?.current?.offsetLeft !== undefined) {
            const offset = e.pageX - progressRef.current.getBoundingClientRect().x
            const width = progressRef.current.offsetWidth
            if (playerRef?.current != null) {
                playerRef.current.seekTo((offset / width) * playerRef.current.getDuration())
            }
        }
    }

    const [fullScreen, setFullScreen] = useState(false)
    return (
        <div className={'w-100 h-100 position-relative bg-black ' + styles.Player} ref={wrapperRef}
            // style={{
            //    height: '100%'
            // }}
            onClick={(e) => {
                //     console.log((e.target as HTMLElement).closest('.close-controls'))
                if ((e.target as HTMLElement).closest('.close-controls') == null) {
                    setControlsOpen(true)
                }
            }}
        >
            <div className={'d-flex justify-content-end w-100 position-absolute top-0 z-index-3 ' + styles.Player__close + ' ' + (controlsOpen ? styles['Player__close-open'] : '')}>
                {/* <i className={`bi bi-x ${small ? 'fs-2x' : 'fs-4x'} text-white close-controls`} */}

                {/* ></i> */}
                <Icon name={'close'}
                    className={`${small ? 'fs-2x' : 'fs-4x'} text-white close-controls`}
                    onClick={() => {
                        setControlsOpen(false)
                    }}
                />
            </div>
            { !playing
                ? <div className={'position-absolute d-flex align-items-center justify-content-center'}
                    onClick={() => {
                        setPlaying(true)
                    }}
                    style={{
                        paddingTop: '18px',
                        bottom: '50px',
                        left: 0,
                        right: 0,
                        top: '32px',
                        zIndex: 1000
                    }}>
                    {/* <i className={`bi bi-play-circle ${small ? 'fs-4x' : 'fs-7x'} text-white`}/> */}
                    <Icon name={'play_circle'} className={`${small ? 'fs-4x' : 'fs-7x'} text-white`}/>
                </div>
                : <></>
            }
            <ReactPlayer
                // light={<></>}
                // playIcon={<i className="bi bi-play-circle fs-5x text-white"></i>}
                playsinline={true}
                className='react-player'
                controls={false}
                url={video}
                width='100%'
                height='100%'
                playing={playing}
                ref={playerRef}
                onProgress={(data) => {
                    if (!seekeing) {
                        setProgress(data.played)
                    }
                }}
            />
            <div className={'d-flex flex-row gap-2 w-100 position-absolute bottom-0 p-2 ' + styles.Player__controls + ' ' + (controlsOpen ? styles['Player__controls-open'] : '') }>
                <div className={'d-flex align-items-center'}>
                    {
                        // playing
                        //     ? <i className={`bi bi-pause-circle ${small ? 'fs-2x' : 'fs-5x'} text-white`}

                        //     />
                        //     : <i className={`bi bi-play-circle ${small ? 'fs-2x' : 'fs-5x'} text-white`}

                        //     />
                        playing
                            ? <Icon name={'pause_circle'}
                                className={`${small ? 'fs-2x' : 'fs-5x'} text-white`}
                                onClick={() => {
                                    setPlaying(false)
                                }}
                            />
                            : <Icon name={'play_circle'}
                                className={`${small ? 'fs-2x' : 'fs-5x'} text-white`}
                                onClick={() => {
                                    setPlaying(true)
                                }}
                            />
                    }
                </div>
                <div className={'d-flex flex-column'}
                    style={{
                        width: '100%'
                    }}
                    ref={progressRef}
                    onMouseDown={() => {
                        setSeekeing(true)
                    }}
                    onMouseUp={setTime}
                >
                    <div className={`text-white text-truncate ${small ? 'fs-9' : ''}`}
                        style={{
                            width: '100%',
                            maxWidth: small ? '50px' : '170px'
                        }}
                    >{name}</div>
                    <div className={'rounded h-5px border border-white'}
                        style={{
                            width: '100%'
                        }}
                    >
                        <div className={'bg-white h-100'}
                            style={{
                                width: `${progress * 100}%`
                            }}
                        />
                    </div>
                    <div className={`text-white ${small ? 'fs-9' : 'fs-8'}`}
                        style={{
                            width: '100%'
                        }}
                    >{
                            ChatUtils.formatDuration(
                                playerRef?.current != null
                                    ? playerRef?.current?.getDuration() * progress
                                    : 0
                            )
                        } | {
                            ChatUtils.formatDuration(
                                playerRef?.current != null
                                    ? playerRef?.current?.getDuration()
                                    : 0
                            )
                        }</div>
                </div>
                <div className={'h-100 pe-2 p-2'}>
                    {/* <i className={`bi bi-arrows-angle-${isFullScreen ? 'contract' : 'expand'} text-white  ${small ? 'fs-2' : 'fs-3x'}`} */}

                    {/* /> */}
                    <Icon name={isFullScreen ? 'fullscreen_exit_24dp' : 'fullscreen_24dp'}
                        className={`text-white  ${small ? 'fs-2' : 'fs-3x'}`}
                        onClick={() => {
                            // if (wrapperRef.current != null) {
                            //     if (!fullscreenVideo) {
                            //         dispatch(setFullscreenVideo(true))
                            //         try {
                            //             void wrapperRef.current.requestFullscreen()
                            //         } catch (err) {
                            //             console.log(err)
                            //         }
                            //     } else {
                            //         dispatch(setFullscreenVideo(false))
                            //         try {
                            //             void document.exitFullscreen()
                            //                 .catch((err) => {
                            //                     console.log(err)
                            //                 })
                            //         } catch (err) {
                            //             console.log(err)
                            //         }
                            //     }
                            // }
                            setPlaying(false)
                            if (isFullScreen) {
                                closeParentFullScreen()
                                dispatch(setFullscreenVideo(false))
                            } else {
                                setFullScreen(true)
                                dispatch(setFullscreenVideo(true))
                            }
                        }}
                    />
                </div>
            </div>
            { time != null
                ? <div className={'position-absolute end-0 rounded p-1 px-2 me-1 mb-1 fs-8 text-gray-600'}
                    style={{
                        background: sentBy === 'me' ? '#fff200' : '#FFFFFF',
                        bottom: controlsOpen ? '4.25rem' : 0,
                        transition: 'all 0.2',
                        display: fullscreenVideo ? 'none' : 'block'
                    }}
                >
                    {ChatUtils.formatTime(time)}
                </div>
                : <></>
            }
            <FullScreenPlayer
                open={fullScreen}
                onClose={() => {
                    setFullScreen(false)
                }}
                video={video}
                name={name}
                time={time}
                sentBy={sentBy}
            />
        </div>
    )
}

export default VideoPlayer
