import { getErrorss } from '../../../functions/errors/GetErrors'
import { urlAPI, ACCESS_KEY, USER_ID_KEY } from '../../../urls'
import { viewingsSlice } from '../../slices/viewingsSlice'
import { type AppDispatch, type RootState } from '../../store'
import { viewingsQuery } from '../viewingsActions'

export const createFeedbackViewingAction = (bodyClient: any) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        try {
            const response = await fetch(urlAPI, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                },
                body: JSON.stringify({
                    method: 'PUT',
                    collection: 'viewings',
                    query: viewingsQuery,
                    //  query: 'populate[User][fields][0]=id&populate[Unit][fields][0]=id&populate[KeyBooking]=*',
                    id: Number(bodyClient.viewID),
                    body: {
                        Feedback: bodyClient.feedbackAgent,
                        Statuses: ['Waiting for complain'],
                        Notify: 'BSO'
                    }
                })
            })
            const responseJSON = await response.json()
            if (
                responseJSON.response.data === null &&
        responseJSON.response.hasOwnProperty('error')
            ) {
                dispatch(
                    viewingsSlice.actions.viewingsFetchingError(getErrorss(responseJSON))
                )
                return responseJSON
            }

            dispatch(viewingsSlice.actions.setViewings(responseJSON.response.data))

            return { ...responseJSON, textNotification: 'Viewing has been' }
        } catch (e) {
            console.log(e)
            // dispatch(viewingsSlice.actions.viewingsFetchingError(e as Error))
        }
    }
