export interface IGetServices {
    id: string
    name: string
    product: string
    oneTime: boolean
    amount: number
    type: string
    check: string
    plans?: {
        id: number
        oneTime: boolean
        name: string
        info: string
        price?: string
        dayString?: string
        show?:boolean
    }[]
    dop?: {
        id: number
        name: string
        price: string
    }[]
}
export function getServices (serviceIn: string): IGetServices | undefined {
    const services = [
        {id: 'property_management', name: 'Male' , product: '5', oneTime: false, amount:5 , type: 'Subscription Service', check:'unit',
           },
        {id: 'finding_tenant', name: 'Finding a tenant', product: '4', oneTime: true, amount:5, type: 'BSO Service', check:'unit',
            plans: [{
                id:1,
                oneTime: true,
                name: 'One Day',
                info: '',
                price: '100',
                dayString: '1 day'
            },
            {
                id:2,
                oneTime: true,
                name: '3-Days',
                info: '',
                price: '300',
                dayString: '3 days'
            },
            {
                id:3,
                oneTime: false,
                name: 'Weekly',
                info: 'search without stopping',
                price: '550',
                dayString: '7 days'
            },
            {
                id:4,
                oneTime: false,
                name: 'BSO Premium',
                info: '',
                price: '750',
                dayString: '7 days'
            },],
            dop:[
                {
                    id: 1,
                    name: 'We will determine the cost for you',
                    price: '20'
                }
            ]},
        {id: 'finding_buyer', name: 'Finding a buyer', product: '4', oneTime: true, amount:5, type: 'BSO Service', check:'unit',
            plans: [{
                    id:1,
                    oneTime: true,
                    name: 'One Day',
                    info: '',
                    price: '100',
                    dayString: '1 day'
                },
                {
                    id:2,
                    oneTime: true,
                    name: '3-Days',
                    info: '',
                    price: '300',
                    dayString: '3 days'
                },
                {
                    id:3,
                    oneTime: false,
                    name: 'Weekly',
                    info: 'search without stopping',
                    price: '550',
                    dayString: '7 days'
                },
                {
                    id:4,
                    oneTime: false,
                    name: 'BSO Premium',
                    info: '',
                    price: '750',
                    dayString: '7 days'
                },],
            dop:[
                {
                    id: 1,
                    name: 'We will determine the cost for you',
                    price: '20'
                }
            ]},
        {id: 'tenant_screening', name: 'Tenant screening', product: '4', oneTime: true, amount:5, type: 'BSO Service', check:'unit',

        },
        {id: 'power_attorney', name: 'Power of Attorney', product: '4', oneTime: true, amount:5, type: 'BSO Service', check:'unit',
                plans: [
                {
                    id:1,
                    oneTime: true,
                    name: 'Decision Maker',
                    info: 'info about product',
                    price: '700'
                },
                {
                    id:2,
                    oneTime: true,
                    name: 'Signatory',
                    info: 'info about product',
                    price: '400'
                },
                {
                    id:3,
                    oneTime: true,
                    name: 'Beneficiary',
                    info: 'info about product',
                    price: '600'
                },
                {
                    id:4,
                    oneTime: true,
                    name: 'Other',
                    info: '',
                    show: false
                },],
        },
        {id: 'rental_dispute', name: 'Rental dispute', product: '4', oneTime: true, amount:5, type: 'BSO Service', check:'unit',

        },
        {id: 'legal_notice', name: 'Legal Notice', product: '4', oneTime: true, amount:5, type: 'BSO Service', check:'unit',
            plans: [
                {
                    id:1,
                    oneTime: true,
                    name: 'Eviction of renters',
                    info: 'info about product'
                },
                {
                    id:2,
                    oneTime: true,
                    name: 'Ownership disputes',
                    info: 'info about product'
                },
                {
                    id:3,
                    oneTime: true,
                    name: 'Mortgage',
                    info: 'info about product'
                },],
        },
        {id: 'property_maintenance', name: 'Property maintenance', product: '4', oneTime: true, amount:5, type: 'BSO Service', check:'unit',
            plans: [
                {
                    id:1,
                    oneTime: true,
                    name: 'AC',
                    info: 'info about product'
                },
                {
                    id:2,
                    oneTime: true,
                    name: 'Repair',
                    info: 'info about product'
                },
                {
                    id:3,
                    oneTime: true,
                    name: 'Moving In',
                    info: 'info about product'
                },
                {
                    id:4,
                    oneTime: true,
                    name: 'Moving Out',
                    info: 'info about product'
                },],
        },
    ]
    const serviceOut = services.find(el => el.id === serviceIn)
    return serviceOut
}