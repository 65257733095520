import React from 'react'
import UnitSlider from './UnitSlider'
import UnitCardParams from './UnitCardParams'
import UnitButtons from '../buttons/UnitButtons'

import { useNavigate } from 'react-router'

import useUserType from '../../../../hooks/UseUserType'
import UnitOfferSaleSh from '../../newUnits/UnitOfferSaleSh'

export interface UnitCardProps {
    unit: any // change to IUnit
    inMessage?: boolean
    inProfile?: boolean
    sentBy: string
}
const UnitCard = ({ unit, inMessage, inProfile, sentBy }: UnitCardProps): JSX.Element => {
    const navigate = useNavigate()
    const { userType } = useUserType()
    return (
        <div className={`card pb-4 overflow-hidden ${inProfile === true ? 'fs-7 border border-gray-200' : 'shadow-sm'}`}
            onClick={(e: any) => {
                if ((e.target as HTMLElement).closest('.unit-buttons') == null) {
                    navigate(`/${userType.toLowerCase()}/modalView/unit_modal/${String(unit.attributes.id)}/not/${sentBy === 'me' ? 'agent_s_unit' : 'other_agent_s_unit'}`)
                }
            }}
        >
            <div className={'card-body position-relative p-0'}>
                <UnitOfferSaleSh hide={true} unit={unit} />
                <UnitSlider unit={unit} inMessage={inMessage} />
            </div>
            {inProfile !== true
                ? <>
                    <UnitCardParams unit={unit} />
                    <div className={'unit-buttons'} >
                        <UnitButtons unit={unit} sentBy={sentBy} />
                    </div>
                </>
                : <></>
            }
            {inMessage === true
                ? <div className={'mb-6'} />
                : <></>
            }
        </div>
    )
}

export default UnitCard
