// import type { IFormFields } from '../../../components/landlord/local/Form'
// import {docs, text, expDate, textDecimal, textLen} from './fields'
// import { getFeatures } from '../userStatistics/getFeatures'
// import {ILink} from '../../../components/landlord/local/AppLink'

import { store } from '../../../store/store';
import { getFeatures } from './userStatistics/getFeatures'
// import {getTime} from "../userStatistics/getTime";

type IFormFields = any
type ILink = any

const docs = (el: any) => el; const text = (el: any) => el; const expDate = (el: any) => el; const textDecimal = (el: any) => el; const textLen = (el: any) => el.length
const getTime = () => { }
export interface IForm {
    id: string
    content?: string[]
    selectors?: [string]
    selectorsP?: string
    tbody?: [{ id: string, name: string, type: string, show?: boolean, selectors?: string[] }]
    link?: ILink
    links?: [[ILink]]
    element: string
    visual?: number
}
export interface IFooter {
    id: string
    content?: string[]
    element: string
}
export interface IModalPage {
    id: string
    header?: string
    background?: string
    form: IForm[]
    footer: IFooter[]
    fields: IFormFields
}


const allAreas = store.getState().app.config?.all.Area
    .filter((area: string) => area !== 'All areas')
    .sort((a: string, b: string) => a.localeCompare(b));


const LandlordModals = [
    {
        id: 'status_landlord',
        header: 'Proceed As',
        form: [
            { id: 'docs1', content: ['', 'Choose your Visa Status'], link: { id: 'docs_resident_page', title: 'Citizen or resident', icon: 'bi-house', description: ['UAE Government-issued identify', 'card (Emirates ID) holders'] }, element: 'link' },
            { id: 'docs2', content: ['', 'Choose your Visa Status'], link: { id: 'docs_visitor_page', title: 'Visitor', icon: 'bi-airplane', description: ['Non UAE Government-issued', 'identify card or passport holders'] }, element: 'link' }
        ],
        footer: [
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            visa_type: text('Visa Status')
        }
    },
    {
        id: 'docs',
        header: 'Edit owner',
        form: [
            { id: 'name', content: ['Name', 'Chooses Name Owner'], element: 'input' },
            { id: 'company_type', content: ['Company Type', 'Choose your company Type'], selectors: ['Sole est', 'LLC/Company'], element: 'select' },
            { id: 'label1', content: ['Emirates ID'], element: 'label-primary' },
            { id: 'id_front_img', element: 'img-preview' },
            { id: 'id_front', content: ['Emirates ID Front'], element: 'input-file' },
            { id: 'id_back_img', element: 'img-preview' },
            { id: 'id_back', content: ['Emirates ID Back'], element: 'input-file' },
            { id: 'id_expiry', content: ['', ''], element: 'input-hidden' },
            { id: 'label2', content: ['or Passport'], element: 'label-primary' },
            { id: 'passport_img', element: 'img-preview' },
            { id: 'passport', content: ['Passport'], element: 'input-file' },
            { id: 'passport_expiry', content: ['', ''], element: 'input-hidden' },
            { id: 'pow_attorney_img', element: 'img-preview' },
            { id: 'pow_attorney', content: ['Power of attorney'], element: 'input-file' },
            { id: 'designation_title', content: ['Designation Title', 'Chooses Designation Title'], element: 'input' },
            { id: 'birth_cert_number', content: ['Birth Certificate Number', 'Chooses Birth Certificate Number'], element: 'input' },
            { id: 'pow_attorney_number', content: ['Power Of Attorney Number', 'Chooses Power Of Attorney Number'], element: 'input' },
            { id: 'trade_license', content: ['Trade License'], element: 'input-file' },
            { id: 'shares_cert', content: ['Shares Certificate'], element: 'input-file' },
            { id: 'incumbency_cert', content: ['Incumbency Certificate'], element: 'input-file' },
            { id: 'cert_standing', content: ['Certificate Of Good Standing'], element: 'input-file' },
            { id: 'memo_assoc', content: ['Memorandum Of Association'], element: 'input-file-multiple' },
            { id: 'id_row', content: ['', ''], element: 'input-hidden' },
            { id: 'type_table', content: ['', ''], element: 'input-hidden' },
            { id: 'name_id', content: ['', ''], element: 'input-hidden' },
            { id: 'name_passport', content: ['', ''], element: 'input-hidden' },
            { id: 'id_number', content: ['', ''], element: 'input-hidden' },
            { id: 'passport_number', content: ['', ''], element: 'input-hidden' },
            { id: 'dob', content: ['', ''], element: 'input-hidden' },
            { id: 'country', content: ['', ''], element: 'input-hidden' },
            { id: 'gender', content: ['', ''], element: 'input-hidden' }
        ],
        footer: [
            { id: 'submit', content: ['Save owners'], element: 'button-primary' },
            { id: 'property_page', content: ['Back'], element: 'button-secondary' }
        ],
        fields: {
            name: textLen('Name Owner')
        }
    },
    {
        id: 'docs_resident',
        header: 'Upload your ID to get Verified Status',
        form: [
            { id: 'id_front', content: ['Emirates ID Front'], element: 'input-file' },
            { id: 'id_back', content: ['Emirates ID Back'], element: 'input-file' },
            { id: 'id_expiry', content: ['Emirates ID Expiry Date', 'Select date'], element: 'input-date' }
        ],
        footer: [
            { id: 'submit', content: ['Save changes'], element: 'button-primary' },
            { id: 'status_landlord_page', content: ['Back'], element: 'button-back' },
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            id_front: docs('Emirates ID Front'),
            id_back: docs('Emirates ID Back'),
            id_expiry: expDate('The expiry date')
        }
    },
    {
        id: 'docs_visitor',
        header: 'Upload your Passport to get Verified Status',
        form: [
            { id: 'visa', content: ['Visa'], element: 'input-file' },
            { id: 'visa_expiry', content: ['Passport Expiry Date', 'Select date'], element: 'input-date' }
        ],
        footer: [
            { id: 'submit', content: ['Save changes'], element: 'button-primary' },
            { id: 'status_landlord_page', content: ['Back'], element: 'button-back' },
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            visa: docs('Passport'),
            visa_expiry: expDate('The expiry date')
        }
    },
    {
        id: 'ownership',
        header: 'Owner Info',
        form: [
            {
                id: 'ownership_type2',
                content: ['Ownership Type', 'Choose your Ownership Type'],
                selectors: [
                    'Property owner (Individual)',
                    'Property owner (Company)',
                    'Representative'],
                element: 'select'
            },
            { id: 'title_deed_img', element: 'img-preview' },
            { id: 'title_deed', content: ['Title Deed/OQOOD File'], element: 'input-file' },
            { id: 'title_deed_type', content: ['Title Deed/OQOOD Type', 'Select type'], selectors: ['Unit', 'Land', 'Villa'], element: 'select' },
            { id: 'title_deed_year', content: ['Title Deed/OQOOD Year', 'Input title deed year'], element: 'input' },
            { id: 'title_deed_number', content: ['Title Deed/OQOOD Number', 'Input title Deed Number'], element: 'input' }

        ],
        footer: [
            { id: 'submit', content: ['Next'], element: 'button-primary' },
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            ownership_type: text('Ownership Type'),
            title_deed_type: text('Title Deed Type'),
            title_deed_number: text('Title Deed Number'),
            title_deed_year: text('Title Deed Year')
        }
    },
    {
        id: 'property',
        header: 'Property Info',
        form: [
            {
                id: 'area',
                content: ['Area', 'area'],
                element: 'input-block'
            },
            {
                id: 'building',
                content: ['Building', 'building'],
                element: 'input-block'
            },
            {
                id: 'type',
                content: ['Property type', 'Select type'],
                selectors: ['Residential', 'Commercial'],
                element: 'select'
            },
            {
                id: 'ownership_type',
                content: ['Type of representation', 'Choose your type of representation'],
                selectors: [
                    'Legal Representative (power of attorney)',
                    'Legal Representative Parent (birth certificate)',
                    'None'],
                element: 'select'
            },
            {
                id: 'owners',
                content: ['Documents of all owners', 'add documents owner'],
                tbody: [
                    { id: 'name', name: 'Owner name', type: 'input', show: true },
                    { id: 'settings', name: '', type: 'settings', show: true },
                    { id: 'delete', name: '', type: 'delete', show: true },
                    { id: 'id_row', name: '', type: 'input', show: false }
                ],
                element: 'table-input'
            },
            {
                id: 'other',
                content: ['Your documents', ''],
                tbody: [
                    { id: 'name', name: 'Name', type: 'input', show: true },
                    { id: 'settings', name: '', type: 'settings', show: true },
                    { id: 'id_row', name: '', type: 'input', show: false }

                ],
                element: 'table-input'
            }
        ],
        footer: [
            { id: 'submit', content: ['Next'], element: 'button-primary' },
            { id: 'ownership_page', content: ['Back'], element: 'button-back' },
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            type: text('Property type'),
            building: text('Building')
        }
    },
    {
        id: 'unit',
        header: 'Unit Info',
        form: [
            { id: 'bedrooms', content: ['Bedrooms', 'Count of bedrooms in unit'], element: 'input' },
            { id: 'bathrooms', content: ['Bathrooms', 'Count of bathrooms in unit'], element: 'input' },
            { id: 'number', content: ['Unit number', 'Unit number'], element: 'input' },
            {
                id: 'size_area',
                content: ['Size area', 'Sq.Ft.', 'Input size area'],
                element: 'input-group'
            },
            /* {
                id: 'fit',
                content: ['Build fitting', 'Unit build fitting'],
                selectors: ['Furnished', 'Semi Furnished', 'Not furnished', 'Fitted', 'Shell & Core'],
                element: 'select'
            }, */
            { id: 'parking_slot_count', content: ['Parking slots count', 'Unit parking slots count'], element: 'input' },
            {
                id: 'features_list',
                content: ['Features', 'Unit features'],
                selectors: getFeatures(),
                element: 'select-multiple'
            }
        ],
        footer: [
            { id: 'submit', content: ['Save'], element: 'button-primary' },
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            bedrooms: text('Bedrooms'),
            bathrooms: text('Bathrooms'),
            number: text('Unit Number'),
            size_area: textDecimal('Size Area'),
            parking_slot: text('Unit parking slot number'),
            features_list: text('Features List')
        }
    },
    {
        id: 'filters',
        header: 'Search filters',
        form: [
            { id: 'areas', content: ['Areas', 'Choose areas'], selectors: allAreas, multiple: true, element: 'select' },
            { id: 'property_type', content: ['Property Type', 'Choose property type'], selectors: ['Residential', 'Commercial', 'Retail'], element: 'select' },
            { id: 'price', content: ['Property price AED'], element: 'range', min: 1000, max: 100000 },
            /* { id: 'price_min', content: ['Property Price Min', 'Input minimum property price'], element: 'input' },
            { id: 'price_max', content: ['Property Price Max', 'Input maximum property price'], element: 'input' }, */
            { id: 'bedrooms', content: ['Bedrooms'], element: 'range', min: 1, max: 10 },
            /* { id: 'beds_min', content: ['Bedrooms Min', 'Input minimum property price'], element: 'input' },
            { id: 'beds_max', content: ['Bedrooms Max', 'Input maximum property price'], element: 'input' }, */
            { id: 'baths', content: ['Baths'], element: 'range', min: 1, max: 10 },
            /* { id: 'baths_min', content: ['Bathrooms Min', 'Input minimum property price'], element: 'input' },
            { id: 'baths_max', content: ['Bathrooms Max', 'Input maximum property price'], element: 'input' }, */
            { id: 'feature', content: ['Search by features', 'Choose features'], selectors: getFeatures(), multiple: true, element: 'select' }
        ],
        footer: [
            { id: 'clear', content: ['Clear'], element: 'button-next' },
            { id: 'submit', content: ['Search'], element: 'button-primary' },
            { id: 'bso_catalog_page', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            services1: text('Service Status')
        }
    },
    {
        id: 'unit_details',
        header: 'Unit Details',
        form: [
            { id: 'marketing_headline', element: 'label-primary' },
            { id: 'unit_images_slider', element: 'slider-images' },
            { id: 'location', content: ['Location'], element: 'label-secondary' },
            { id: 'type', content: ['Type'], element: 'label-secondary' },
            { id: 'size_area', content: ['Size Area'], element: 'label-secondary' },
            { id: 'number', content: ['Unit number'], element: 'label-secondary' },
            { id: 'parking_slots', content: ['Parking Slots Count'], element: 'label-secondary' },
            { id: 'bedrooms', content: ['Bedrooms'], element: 'label-secondary' },
            { id: 'bathrooms', content: ['Bathrooms'], element: 'label-secondary' },
            { id: 'features_list', content: ['Property Features'], element: 'fix-list' }
        ],
        footer: [
            { id: 'edit_unit_page', content: ['Edit'], element: 'button-next' },
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            unit_info1: text('Service Status')
        }
    },
    {
        id: 'viewing_details',
        header: 'Approval Details',
        form: [
            { id: 'user_info', content: ['User Info'], element: 'label-primary' },
            { id: 'name', content: ['Name'], element: 'label-secondary' },
            { id: 'email', content: ['Email'], element: 'label-secondary' },
            { id: 'phone', content: ['Phone'], element: 'label-secondary' },
            { id: 'dob', content: ['Date of birth'], element: 'label-secondary' },
            { id: 'country', content: ['Country of birth'], element: 'label-secondary' },
            { id: 'gender', content: ['Gender'], element: 'label-secondary' },
            { id: 'viewing_info', content: ['Viewing'], element: 'label-primary' },
            { id: 'status', content: ['Viewing status'], element: 'label-secondary' },
            { id: 'date', content: ['Viewing date'], element: 'label-secondary' },
            { id: 'unit_info', content: ['Unit Info'], element: 'label-primary' },
            { id: 'location', content: ['Location'], element: 'label-secondary' },
            { id: 'property', content: ['Building name'], element: 'label-secondary' },
            { id: 'number', content: ['Unit number'], element: 'label-secondary' }
        ],
        footer: [
            { id: 'viewing_reject_swal', content: ['Reject'], element: 'button-next' },
            { id: 'viewing_details_approval', content: ['Approval'], element: 'button-next' },
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            unit_info1: text('Service Status')
        }
    },
    {
        id: 'change_viewing',
        header: 'Change Viewing',
        form: [
            { id: 'unit_images_slider', element: 'slider-images' },
            { id: 'viewing_date', content: ['Date of viewing', 'Select date'], element: 'input-date' },
            { id: 'viewing_time', content: ['Time of viewing '], element: 'set-time', tbody: getTime() }
        ],
        footer: [
            { id: 'submit', content: ['Set date'], element: 'button-primary' },
            { id: 'viewing_details_page', content: ['Back'], element: 'button-back' }
        ],
        fields: {
            viewing_date: expDate('The viewing date')
        }
    },
    {
        id: 'offer_details',
        header: 'Offer Details',
        form: [
            { id: 'offer_headline', element: 'label-primary' },
            { id: 'status', content: ['Status'], element: 'label-secondary' },
            { id: 'unit_info', content: ['Unit info'], element: 'label-secondary' },
            { id: 'user_info', content: ['User Info'], element: 'label-secondary' }
        ],
        footer: [
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            unit_info1: text('Service Status')
        }
    },
    {
        id: 'service_details',
        header: 'Service Details',
        form: [
            { id: 'order_info', content: ['Order Info'], element: 'label-primary' },
            { id: 'type', content: ['Type Order'], element: 'label-secondary' },
            { id: 'complete_date', content: ['Complete date'], element: 'label-secondary' },
            { id: 'status', content: ['Status Order'], element: 'label-secondary' },
            { id: 'payment_info', content: ['Payment Info'], element: 'label-primary' },
            { id: 'pay_date', content: ['Pay date'], element: 'label-secondary' },
            { id: 'amount', content: ['Amount'], element: 'label-secondary' },
            { id: 'unit_info', content: ['Unit Info'], element: 'label-primary' },
            { id: 'location', content: ['Location'], element: 'label-secondary' },
            { id: 'property', content: ['Building name'], element: 'label-secondary' },
            { id: 'number', content: ['Unit number'], element: 'label-secondary' }
        ],
        footer: [
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            unit_info1: text('Service Status')
        }
    },
    {
        id: 'subscription_details',
        header: 'Subscription Details',
        form: [
            { id: 'order_info', content: ['Order Info'], element: 'label-primary' },
            { id: 'type', content: ['Type Order'], element: 'label-secondary' },
            { id: 'status', content: ['Status Order'], element: 'label-secondary' },
            { id: 'expiry_date', content: ['Expiry date'], element: 'label-secondary' },
            { id: 'complete_date', content: ['Complete date'], element: 'label-secondary' },
            { id: 'amount', content: ['Amount'], element: 'label-secondary' },
            { id: 'unit_info', content: ['Unit Info'], element: 'label-primary' },
            { id: 'location', content: ['Location'], element: 'label-secondary' },
            { id: 'property', content: ['Building name'], element: 'label-secondary' },
            { id: 'number', content: ['Unit number'], element: 'label-secondary' }
        ],
        footer: [
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            unit_info1: text('Service Status')
        }
    },
    {
        id: 'payment_details',
        header: 'Payment Details',
        form: [
            { id: 'type', content: ['Type'], element: 'label-secondary' },
            { id: 'status', content: ['Status'], element: 'label-secondary' },
            { id: 'pay_date', content: ['Pay date'], element: 'label-secondary' },
            { id: 'amount', content: ['Amount'], element: 'label-secondary' }
        ],
        footer: [
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            unit_info1: text('Service Status')
        }
    },
    {
        id: 'property_management',
        header: 'PROPERTY MANAGEMENT',
        background: '/shutterstock/main_services_1.jpg',
        form: [
            { id: 'background', element: 'img-background' },
            { id: 'headline', content: ['Online BSO service subscription combines security, effectiveness, and a user-friendly approach to address all of your property management needs.'], element: 'label-text' },
            { id: 'units', content: ['Unit'], element: 'select', selectorsP: 'units' }

        ],
        footer: [
            { id: 'submit', content: ['Pay now'], element: 'button-primary' },
            { id: 'services_page', content: ['Close'], element: 'button-back' }
        ],
        fields: {
            unit_info1: text('Service Status')
        }
    },
    {
        id: 'finding_tenant',
        header: 'FINDING A TENANT',
        background: '/shutterstock/main_services_2.jpg',
        form: [
            { id: 'background', element: 'img-background' },
            { id: 'headline1', content: ['Instead of one, ', 'over a thousand', ' brokers will be working towards a single goal to find your next perfect tenant.'], element: 'label-text-1' },
            { id: 'headline2', content: ['2 STEPS TO START'], element: 'label-text-2' },
            {
                id: 'step1',
                content: [
                    'Choose a unit to search for a tenant', (<i className="ki-outline ki-setting-4 fs-1 me-2"></i>)
                ],
                element: 'label-text-4'
            },
            { id: 'units', content: [''], element: 'select', selectorsP: 'units' },
            { id: 'separator', content: [''], element: 'separator' },
            { id: 'step2', content: ['Select the plan and additional service'], element: 'label-text-5' },
            { id: 'plan', content: [''], element: 'set-package' },
            { id: 'separator2', content: [''], element: 'separator' },
            { id: 'dop', content: [''], element: 'set-package' },
            { element: 'toc' }

        ],
        footer: [
            { id: 'submit', content: ['pay_now_id'], element: 'button-primary' },
            { id: 'services_page', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            toc: text('Terms & Conditions is required'),
            units: text('Unit is required'),
            ADS_type: text('Advertisement Type is required'),
            days: text('Days is required')
        }
    },
    {
        id: 'finding_buyer',
        header: 'FINDING A BUYER',
        background: '/shutterstock/main_services_13.jpg',
        form: [
            { id: 'background', element: 'img-background' },
            { id: 'headline1', content: ['Instead of one, ', 'over a thousand', ' brokers will be working towards a single goal to find your buyer.'], element: 'label-text-1' },
            { id: 'headline2', content: ['2 STEPS TO START'], element: 'label-text-2' },
            { id: 'step1', content: ['Choose a unit to search for a buyer', (<i className="ki-outline ki-setting-4 fs-1 me-2"></i>)], element: 'label-text-4' },
            { id: 'units', content: ['', 'Choose a unit'], element: 'select', selectorsP: 'units' },
            { id: 'separator', content: [''], element: 'separator' },
            { id: 'step2', content: ['Select the plan and additional service'], element: 'label-text-5' },
            { id: 'plan', content: [''], element: 'set-package' },
            { id: 'separator2', content: [''], element: 'separator' },
            { id: 'dop', content: [''], element: 'set-package' },
            { element: 'toc' }

        ],
        footer: [
            { id: 'submit', content: ['pay_now_id'], element: 'button-primary' },
            { id: 'services_page', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            toc: text('Terms & Conditions is required'),
            units: text('Unit is required'),
            ADS_type: text('Advertise Type is required'),
            days: text('Days is required')
        }
    },
    {
        id: 'tenant_screening',
        header: 'TENANT SCREENING',
        background: '/shutterstock/main_services_7.jpg',
        form: [
            { id: 'background', element: 'img-background' },
            { id: 'headline1', content: ['Our mission is to find a financially sound tenant and secure you from every potential risk.', '', ''], element: 'label-text-1' },
            { id: 'headline2', content: ['2 CLICK TO START'], element: 'label-text-2' },
            { id: 'step1', content: ['Choose a unit to tenant screening', (<i className="ki-outline ki-setting-4 fs-1 me-2"></i>)], element: 'label-text-4' },
            { id: 'units', content: ['Unit'], element: 'select', selectorsP: 'units' },
            { element: 'toc' }

        ],
        footer: [
            { id: 'submit', content: ['Get Screening Service'], element: 'button-primary' },
            { id: 'services_page', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            toc: text('Terms & Conditions is required')
        }
    },
    {
        id: 'rental_dispute',
        header: 'RENTAL DISPUTE',
        background: '/shutterstock/main_services_4.jpg',
        form: [
            { id: 'background', element: 'img-background' },
            { id: 'headline1', content: ['Thanks to our expert legal team, 47% of our legal inquiries in rental disputes reach settlement stages without the need to register a rental case.'], element: 'label-text-1' },
            { id: 'headline2', content: ['3 STEPS TO START'], element: 'label-text-2' },
            { id: 'step1', content: ['Choose a unit to rental dispute', (<i className="ki-outline ki-setting-4 fs-1 me-2"></i>)], element: 'label-text-4' },
            { id: 'units', content: ['', 'Choose a unit'], element: 'select', selectorsP: 'units' },
            { id: 'separator1', content: [''], element: 'separator' },
            { id: 'step2', content: ['Choose against', (<i className="ki-outline ki-setting-4 fs-1 me-2"></i>)], element: 'label-text-4' },
            { id: 'against', content: ['', 'Choose against', 'primary'], element: 'select', selectors: ['Landlord', 'Tenant', 'Facility'] },
            { id: 'separator2', content: [''], element: 'separator' },
            { id: 'step3', content: ['Describe the problem of the dispute', 'ki-pencil'], element: 'label-text-3' },
            { id: 'textarea', content: ['', 'Problems'], element: 'textarea' },
            { element: 'toc' }

        ],
        footer: [
            { id: 'submit', content: ['Get RDC Service'], element: 'button-primary' },
            { id: 'services_page', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            toc: text('Terms & Conditions is required')
        }
    },
    {
        id: 'legal_notice',
        header: 'LEGAL NOTICE',
        background: '/shutterstock/main_services_9.jpg',
        form: [
            { id: 'background', element: 'img-background' },
            { id: 'headline1', content: ['The purpose of a legal notice is to inform the other party about a grievance and ask for a remedy.'], element: 'label-text-1' },
            { id: 'headline2', content: ['3 STEPS TO START'], element: 'label-text-2' },
            { id: 'step1', content: ['Choose a unit to legal notice', (<i className="ki-outline ki-setting-4 fs-1 me-2"></i>)], element: 'label-text-4' },
            { id: 'units', content: ['', 'Choose a unit to legal notice'], element: 'select', selectorsP: 'units' },
            { id: 'separator1', content: [''], element: 'separator' },
            { id: 'step2', content: ['Select case and describe reason'], element: 'label-text-5' },
            { id: 'plan', content: [''], element: 'set-package' },
            { id: 'separator2', content: [''], element: 'separator' },
            { id: 'step3', content: ['Describe the reason for applying legal notice', 'ki-pencil'], element: 'label-text-3' },
            { id: 'textarea', content: ['', 'Reason'], element: 'textarea' },
            { element: 'toc' }

        ],
        footer: [
            { id: 'submit', content: ['Get Legal Notice Service'], element: 'button-primary' },
            { id: 'services_page', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            toc: text('Terms & Conditions is required')
        }
    },
    {
        id: 'power_attorney',
        header: 'POWER OF ATTORNEY',
        background: '/shutterstock/main_services_14.jpg',
        form: [
            { id: 'background', element: 'img-background' },
            { id: 'headline1', content: ['Place your order online by selecting your desired POA package.', '', ''], element: 'label-text-1' },
            { id: 'headline2', content: ['2 STEPS TO START'], element: 'label-text-2' },
            { id: 'step0', content: ['Choose document type', 'ki-arrow-right-left'], element: 'label-text-3' },
            { id: 'switch', element: 'switch' },
            { id: 'separator', content: [''], element: 'separator' },
            { id: 'step1', content: ['Choose a unit to power of attorney', (<i className="ki-outline ki-setting-4 fs-1 me-2"></i>)], element: 'label-text-4', visual: 1 },
            { id: 'units', content: ['', 'Choose a unit'], element: 'select', selectorsP: 'units', visual: 1 },
            { id: 'separator1', content: [''], element: 'separator', visual: 1 },
            { id: 'step2', content: ['Select POA package'], element: 'label-text-5', visual: 1 },
            { id: 'plan', content: [''], element: 'set-package', visual: 1 },
            { id: 'step3', content: ['Describe the reason for applying power of attorney', 'ki-pencil'], element: 'label-text-3', visual: 2 },
            { id: 'textarea', content: ['', 'Reason'], element: 'textarea', visual: 2 },
            { element: 'toc' }

        ],
        footer: [
            { id: 'submit', content: ['pay_now_id'], element: 'button-primary' },
            { id: 'services_page', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            toc: text('Terms & Conditions is required')
        }
    },
    {
        id: 'property_maintenance',
        header: 'PROPERTY MAINTENANCE',
        background: '/shutterstock/main_services_8.jpg',
        form: [
            { id: 'background', element: 'img-background' },
            { id: 'headline1', content: ['We\'ll take care of everything, whether a quick repair or a full-scale renovation.', '', ''], element: 'label-text-1' },
            { id: 'headline2', content: ['3 STEPS TO START'], element: 'label-text-2' },
            { id: 'step1', content: ['Choose a unit to property maintenance', (<i className="ki-outline ki-setting-4 fs-1 me-2"></i>)], element: 'label-text-4' },
            { id: 'units', content: ['Unit'], element: 'select', selectorsP: 'units' },
            { id: 'separator1', content: [''], element: 'separator' },
            { id: 'step2', content: ['Select case and start date'], element: 'label-text-5' },
            { id: 'plan', content: [''], element: 'set-package' },
            { id: 'start', content: ['Work Start Date', 'Select date'], element: 'input-date' },
            { id: 'separator3', content: [''], element: 'separator' },
            { id: 'step5', content: ['Add required tasks'], element: 'label-text-5' },
            { id: 'tasks', content: [''], element: 'table' },
            { element: 'toc' }

        ],
        footer: [
            { id: 'submit', content: ['Get Maintenance Service'], element: 'button-primary' },
            { id: 'services_page', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            start: expDate('Start date'),
            toc: text('Terms & Conditions is required')
        }
    },
    {
        id: 'work_task',
        header: 'Work Task',
        form: [
            { id: 'name', content: ['Name', 'Input Work Name'], element: 'input' },
            {
                id: 'area',
                content: ['Area', 'Sq.Ft.', 'Input Work Area'],
                element: 'input-group'
            },
            { id: 'images_dz', content: ['Images'], element: 'drop-zone' }

        ],
        footer: [
            { id: 'submit', content: ['Save Task'], element: 'button-primary' },
            { id: 'property_maintenance_page', content: ['Back'], element: 'button-secondary' }
        ],
        fields: {
            name: text('Work Name is required'),
            area: text('Work Area is required')
        }
    },
    {
        id: 'get_services',
        header: 'Select service',
        form: [
            {
                id: 'services',
                content: ['', ''],
                links: [
                    {
                        id: 'finding_tenant_page',
                        title: 'FINDING A TENANT',
                        description: ['Due diligence of BSO', 'reduces the risk of dealing with high-risk profile tenants'],
                        img: '/services/get_services_2.png',
                        type: 'post'
                    },
                    {
                        id: 'finding_buyer_page',
                        title: 'FINDING A BUYER',
                        description: ['Due diligence of BSO', 'reduces the risk of dealing with high-risk profile tenants'],
                        img: '/services/get_services_13.png',
                        type: 'post'
                    },
                    {
                        id: 'power_attorney_page',
                        title: 'POWER OF ATTORNEY',
                        description: ['Due diligence of BSO', 'reduces the risk of dealing with high-risk profile tenants'],
                        img: '/services/get_services_14.png',
                        type: 'post'
                    },
                    {
                        id: 'tenant_screening_page',
                        title: 'TENANT SCREENING',
                        description: ['Due diligence of BSO', 'reduces the risk of dealing with high-risk profile tenants'],
                        img: '/services/get_services_7.png',
                        type: 'post'
                    }
                ],
                element: 'link-multiple'
            }
        ],
        footer: [
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            services1: text('Service Status')
        }
    },
    {
        id: 'unit_notify',
        header: 'Notification',
        form: [
            { id: 'notification', content: ['', ''], element: 'notification' }
        ],
        footer: [
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            services1: text('Service Status')
        }
    },
    {
        id: 'marketing',
        header: 'Marketing Info',
        form: [
            {
                id: 'headline',
                content: ['Marketing headline', 'This title will be seen by app users'],
                element: 'input'
            },
            {
                id: 'fit',
                content: ['Build fitting', 'Unit build fitting'],
                selectors: ['Furnished', 'Semi Furnished', 'Not furnished', 'Fitted', 'Shell & Core'],
                element: 'select'
            },
            {
                id: 'checks',
                content: ['', ''],
                tbody: [
                    { id: 'check', name: 'Count check', type: 'select', selectors: ['1', '2', '4', '6'], show: true },
                    { id: 'payment', name: 'Total Amount', type: 'input-group', show: true },
                    { id: 'delete', name: '', type: 'delete', show: true },
                    { id: 'id_row', name: '', type: 'input', show: false }
                ],
                element: 'table-input'
            },
            { id: 'images_dz', content: ['Images'], element: 'drop-zone' }

        ],
        footer: [
            { id: 'submit', content: ['Save'], element: 'button-primary' },
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            bathrooms: text('Bathrooms'),
            ownership_type: text('Ownership Type')
        }
    },
    {
        id: 'tenant',
        header: 'Tenant Info',
        form: [
            { id: 'name', content: ['Name', 'Chooses Name Tenant'], element: 'input' },
            { id: 'label1', content: ['Emirates ID'], element: 'label-primary' },
            { id: 'id_front', content: ['Emirates ID Front'], element: 'input-file' },
            { id: 'id_back', content: ['Emirates ID Back'], element: 'input-file' },
            { id: 'id_expiry', content: ['', ''], element: 'input-hidden' },
            { id: 'label2', content: ['or Passport'], element: 'label-primary' },
            { id: 'passport', content: ['Passport'], element: 'input-file' },
            { id: 'passport_expiry', content: ['', ''], element: 'input-hidden' }
        ],
        footer: [
            { id: 'submit', content: ['Save'], element: 'button-primary' },
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            name: textLen('Name Tenant')
        }
    },
    {
        id: 'vendors',
        header: 'Tender Info',
        form: [
            { id: 'label1', content: ['Tender status: In-process'], element: 'label-primary' },
            { id: 'step2', content: ['List Tasks'], element: 'label-text-5' },
            { id: 'tasks2', content: [''], element: 'table' },
            { id: 'separator1', content: [''], element: 'separator' },
            { id: 'step5', content: ['Select vendor'], element: 'label-text-5' },
            { id: 'vendors', content: [''], element: 'table' }
        ],
        footer: [
            { id: 'submit', content: ['Choose Vendor'], element: 'button-primary' },
            { id: 'vendors_reject_info', content: ['Reject'], element: 'button-next' },
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            name: textLen('Name Tenant')
        }
    },
    {
        id: 'works',
        header: 'Works Info',
        form: [
            { id: 'vendor_company', content: ['Vendor Company'], element: 'label-secondary' },
            { id: 'vendor_name', content: ['Vendor Name'], element: 'label-secondary' },
            { id: 'phone', content: ['Phone'], element: 'label-secondary' },
            { id: 'email', content: ['Email'], element: 'label-secondary' },
            { id: 'start_date', content: ['Start Work Date'], element: 'label-secondary' },
            { id: 'total_price', content: ['Total Price'], element: 'label-secondary' },
            { id: 'separator1', content: [''], element: 'separator' },
            { id: 'step5', content: ['Work progress'], element: 'label-text-5' },
            { id: 'works', content: [''], element: 'table' }
        ],
        footer: [
            { id: 'close', content: ['Close'], element: 'button-secondary' }
        ],
        fields: {
            name: textLen('Name Tenant')
        }
    }
] as IModalPage[]

export { LandlordModals }
