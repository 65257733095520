
import { timeStringInScheduler } from '../../../functions/agent/date/timeStringInScheduler'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../urls'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'
import { setViewings, viewingsFetchingError } from '../../slices/viewingsSlice'
import { type AppDispatch, type RootState } from '../../store'

export const createPeriodBisyKeys = (bodyClient: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    dispatch(dataFetchingStart())
    const updViewing = getState().viewings.viewings.filter((view) => view.id === Number(bodyClient.viewID))[0]
    //  console.log(updViewing, 'updViewing ascmajskcmkasmc ????ASC')
    const sendObject = {
        method: 'POST',
        collection: 'unit-keys',
        query: 'populate[User][fields][0]=id&populate[Unit][fields][0]=id&populate[Viewings][fields][0]=id',
        body: {
            PickUpDate: bodyClient['pick-up-date'].toISOString(),
            DropOutDate: bodyClient['pick-out-date'].toISOString(),
            Holder: Number(userID),
            Viewings: Number(bodyClient.viewID),
            Unit: Number(bodyClient.unitID),
            Status: 'In-process'
        }
    }
    try {
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(viewingsFetchingError(getErrorss(dataJSON)))
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                const DropOutDate = dataJSON.response.data?.attributes?.DropOutDate
                const PickUpDate = dataJSON.response.data?.attributes?.PickUpDate
                const textNot = `You should pick up your keys on &nbsp;<strong>${timeStringInScheduler(PickUpDate).split(',')[0]} - ${timeStringInScheduler(PickUpDate).split(',')[1]}</strong> &nbsp; But return them by &nbsp;<strong>${timeStringInScheduler(DropOutDate).split(',')[0]} - ${timeStringInScheduler(DropOutDate).split(',')[1]}</strong>`
                //  console.log(dataJSON?.response?.data, 'vdataJSON?.response?.data COOOL')

                const newObject = JSON.parse(JSON.stringify(updViewing))
                newObject.attributes.KeyBooking.data = dataJSON?.response?.data

                //  console.log(newObject, 'newObject<____________<newObjectnewObjectnewObject')
                dispatch(setViewings(newObject))// responseJSON.response.data
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: `/agent/modalView/viewing_modal/${bodyClient.unitID}/${bodyClient.viewID}/not`, isSuccessful: true, textNotification: textNot, title: 'Viewing has been created' }
            }
        } else {
            dispatch(viewingsFetchingError(getErrorss(dataJSON)))
            dispatch(dataFetchingFinish())
            return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (e) {
        dispatch(dataFetchingFinish())
        dispatch(viewingsFetchingError(e as Error))
    }
}
