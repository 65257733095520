import React, { useState } from 'react'
import { Upload } from 'antd'
import { dataFetchingFinish, dataFetchingStart, setDocuments } from '../../../store/slices/modalSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'

import { rerenderModal, setTitleDeedLoaded } from '../../../store/slices/setUnitSlice'
import { applyTitleDeed } from '../../../store/actionsCreators/setUnitActions'
import { Controller } from 'react-hook-form'
import _ from 'lodash'
import { scanDocuments } from '../../../store/actionsCreators/documentsActions/scaning/scanDocuments'

interface CustomDocumentInputProps {
    row: any
    control: any
    errors: any
}

/**
 * On 'input-document'
  */
export default function CustomDocumentInput({ row, control, errors }: CustomDocumentInputProps): React.ReactElement {
    const dispatch = useAppDispatch()
    const documents = useAppSelector((state) => state.modal.documents)
    const [isUploading, setIsUploading] = useState(false)
    const [uploadedFile, setUploadedFile] = useState<File | null>()
    const buttonLabels = row.content[2] ?? ['Scan', 'Scanning...']
    return (
        <Controller
            name={row.id}
            control={control}
            // defaultValue={data.defaultValue}
            rules={row.required === true ? { required: `${String(row.content?.[0])} is required` } : {}}
            render={({ field }) => {
                return <><label className={
                    (row.required === true)
                        ? 'required form-label mb-2 fw-bold text-dark text-uppercase'
                        : 'form-label fw-bold mb-2 text-dark text-uppercase'
                }> {row.content?.[0]}
                </label>
                    <div className={'custom-document-input'}>
                        <Upload
                            listType="picture-card"
                            fileList={documents
                                ?.slice(0, 1)
                                ?.map((document, index) => ({
                                    uid: String(index),
                                    name: row.content[1] ?? 'Title deed',
                                    status: isUploading ? 'uploading' : 'done'
                                }))
                            }
                            onRemove={(file) => {
                                setUploadedFile(null)
                                dispatch(setDocuments([]))
                                field.onChange(null)
                            }}
                            customRequest={(options) => {
                                setUploadedFile(options.file as File)
                                dispatch(setDocuments([-1]))
                            }}
                            {...field}
                        >
                            {documents?.length === 0 && !isUploading
                                ? <div className={'btn btn-bso'}>Upload</div>
                                : null
                            }
                        </Upload>
                        {uploadedFile != null
                            ? <div className={'d-flex justify-content-center align-items-center rounded'}
                                style={{ border: '1px solid #d9d9d9' }}
                            >
                                <div className="btn btn-bso"
                                    onClick={() => {
                                        if (!isUploading) {
                                            if (row.onUpload == null) {
                                                dispatch(dataFetchingStart())
                                                setIsUploading(true)
                                                void dispatch(scanDocuments([uploadedFile], 'Title Deed'))
                                                    .then((response: any) => {
                                                        // dispatch(setDocuments([]))
                                                        dispatch(applyTitleDeed(response))
                                                        //         console.log(response)
                                                        dispatch(setTitleDeedLoaded(true))
                                                        dispatch(dataFetchingFinish())
                                                        dispatch(rerenderModal())
                                                        setIsUploading(false)
                                                        setUploadedFile(null)
                                                    })
                                                    .catch((err: any) => {
                                                        console.error(err)
                                                        // dispatch(setDocuments([]))
                                                        dispatch(dataFetchingFinish())
                                                        setIsUploading(false)
                                                        setUploadedFile(null)
                                                    })
                                            } else {
                                                setIsUploading(true)
                                                row.onUpload(uploadedFile)
                                                    .finally(() => {
                                                        setIsUploading(false)
                                                        setUploadedFile(null)
                                                    })
                                            }
                                        }
                                    }}
                                >
                                    {
                                        isUploading ? buttonLabels[1] : buttonLabels[0]
                                    }
                                </div>
                            </div>
                            : <></>
                        }
                    </div>
                    {_.get(errors, row.id) != null && (
                        <div className="text-danger">{_.get(errors, row.id).message}</div>
                    )}
                </>
            }}
        />
    )
}
