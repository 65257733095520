
import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { getQueryAdvertises } from '../../../functions/shared/api/queries/getQueryAdvertises'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'
import { ACCESS_KEY, urlAPI } from '../../../urls'
import { setAdvertises } from '../../slices/advertisesSlice'
import { dataFetchingFinish, dataFetchingStart, modalSlice } from '../../slices/modalSlice'
import { type User } from '../../slices/userSlice'
import { type AppDispatch, type RootState } from '../../store'

export const createLinksAdvertiseAction = (formValues: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    try {
        dispatch(dataFetchingStart())
        const sendObject = {
            method: 'PUT',
            collection: 'unit-advertises',
            id: Number(formValues.objectID),
            query: getQueryAdvertises(), // 'populate[User][fields][0]=id&populate[Unit][fields][0]=id',
            body: {
                Links: formValues.form.links,
                Statuses: ['Links added'],
                Notify: 'BSO'
            }
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()
        // console.log(dataJSON, 'dataJSON addAgentSettings')
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                const currentUnit = getState().units?.units?.filter((el: any) => el.id === Number(formValues.unitID))[0]
                const currentUser = getState().user.currentUser
                const nameUser = getNameUser(currentUser as User)

                const unitAdress = getUnitAddress(currentUnit)
                const inMomentViewingForAdmin = toUTCWithOffset(getCurrentDubaiDate(), 1)

                dispatch(dataFetchingFinish())
                dispatch(setAdvertises(dataJSON.response.data))
                //  admin-advertise-ads-removed
                await sendNotification(inMomentViewingForAdmin, Number(currentUser.id), dataJSON.response.data.id, 'admin-advertise-links-added', `Attention admin, ${nameUser} created link an advertise for ${unitAdress}.`)
                return { ...dataJSON, textNavigate: `/agent/modalView/advertise_modal/${dataJSON?.response?.data?.attributes?.Unit?.data?.id}/${dataJSON?.response?.data?.id}/not`, isSuccessful: true, textNotification: 'You have successfully updated the advertising Trakheesi IDs' }
            }
        } else {
            dispatch(dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
        dispatch(dataFetchingFinish())
        return { textNavigate: '', isSuccessful: false, textNotification: 'Something went wrong, try again.' }
        //  dispatch(userSlice.actions.userFetchingError(error))
    }
}
