import React from 'react'
// import type { RootState } from '../../../redux/store'
// import {useSelector} from 'react-redux'

import AppLink from '../../../app/AppLink'
import _ from 'lodash'
import { nanoid } from 'nanoid'
// import {initJS} from "../../../functions/landlord/init";

const LandlordServices = (): JSX.Element => {
    /* const currentUser = useSelector((state: RootState) => state.general.currentUser)
    const page = useSelector((state: RootState) => state.local.page)
    const optionS2B = {
        minimumResultsForSearch: 5,
        nullVal: true
    } */
    const linksCol = [
        [
            {
                id: 'finding_tenant',
                title: 'Finding a tenant',
                description: ['Due diligence of BSO', 'reduces the risk of dealing with high-risk profile tenants'],
                img: '/services/get_services_2.png',
                class: 'col-8',
                type: 'post'
            },
            {
                id: 'finding_buyer',
                title: 'Finding a buyer',
                description: [],
                img: '/services/get_services_13.png',
                class: 'col-4',
                type: 'post'
            }
        ],
        [
            {
                id: 'power_attorney',
                title: 'Power of attorney',
                description: [],
                img: '/services/get_services_14.png',
                class: 'col-4',
                type: 'post'
            },
            {
                id: 'legal_notice',
                title: 'Legal notice',
                description: [],
                img: '/services/get_services_5.png',
                class: 'col-4',
                type: 'post'
            },
            {
                id: 'property_maintenance',
                title: 'Property maintenance',
                description: [],
                img: '/services/get_services_8.png',
                class: 'col-4',
                type: 'post'
            }
        ],
        [
            {
                id: 'tenant_screening',
                title: 'Tenant screening',
                description: [],
                img: '/services/get_services_7.png',
                class: 'col-4',
                type: 'post'
            },
            {
                id: 'rental_dispute',
                title: 'Rental dispute',
                description: ['Due diligence of BSO', 'reduces the risk of dealing with high-risk profile tenants'],
                img: '/services/get_services_4.png',
                class: 'col-8',
                type: 'post'
            }
        ]
    ]
    return (
        <div data-id="services_page" className="page d-flex flex-column flex-row-fluid fs-5">

            <div className={'container py-4 px-4'}>
                {
                    _.map(linksCol, (row) => (
                        <div className={'row mb-4'} key={nanoid()}>
                            {
                                _.map(row, (item) => (
                                    <div className={`${item.class} px-2`} key={nanoid()}>
                                        <AppLink link={item}/>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
            <div className={'h-75px'}/>
        </div>
    )
}

export default LandlordServices
