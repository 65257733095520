import { modalSlice } from '../../slices/modalSlice'
import { updateOfferByMagic } from './magicOfferActions'
import { setUnitSold } from '../unitsActions'

import { type RootState } from '../../store'
import { createDocument } from '../documentsActions/createDocument'

interface MagicCheckBookingFormProps {
    SignedBookingForm: FileList
}

export const magicCheckBookingForm = (unitID: string | undefined, objectID: string | undefined) => (data: MagicCheckBookingFormProps) => async (dispatch: any, getState: () => RootState) => {
    dispatch(modalSlice.actions.dataFetchingStart())

    const { isSuccessful } = await dispatch(createDocument(data.SignedBookingForm, {
        RefID: `REF_DOC<->booking_form<->${new Date().getTime()}`,
        Category: 'Booking Form',
        Offer: Number(objectID),
        Unit: Number(unitID),
        Statuses: ['In-process']
    }, 'offers', objectID))
    if (isSuccessful === true) {
        const offer = getState().offers.offers.find(offerItem => offerItem.id === Number(objectID))
        if (['offer.sale', 'offer.sale-company'].includes(String(offer?.attributes?.Type?.[0]?.__component))) {
            await dispatch(updateOfferByMagic(Number(objectID), { Statuses: ['Completed'] }, 'Booking form is successfully signed', 'admin-offer-completed', 'Offer is completed'))
            void dispatch(setUnitSold(Number(unitID)))
        } else {
            await dispatch(updateOfferByMagic(Number(objectID), { Statuses: ['Waiting deposit pay slip'] }, 'Booking form is successfully signed', 'user-offer-deposit', 'ATTENTION upload a deposit pay slip for offer'))
        }
        // void swalSuccess('Booking form is successfully signed')
    }
    dispatch(modalSlice.actions.dataFetchingFinish())
    return {
        isSuccessful,
        textNavigate: -1
    }
}
