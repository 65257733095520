import React, { useEffect, useState } from 'react'
import { useAudioRecorder } from 'react-audio-voice-recorder'
import moment from 'moment/moment'
import { nanoid } from 'nanoid'
import { transcodeVoiceMessage, uploadStrapiFile } from '../../../../store/actionsCreators/fileActions'
import { setCurrentFile, setCurrentMessage } from '../../../../store/slices/chatSlice'
import { removePendingMessage, sendMessage, setPendingMessage } from '../../../../store/actionsCreators/chatActions'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { useParams } from 'react-router'
import getBlobDuration from 'get-blob-duration'
import { type RootState } from '../../../../store/store'
import Swal from 'sweetalert2'
import { swalError } from '../../../../functions/swalOptions'
import Icon from '../../../../app/icon/Icon'

const VoiceRecorder = (): JSX.Element => {
    const {
        startRecording,
        stopRecording,
        recordingBlob,
        isRecording,
        recordingTime
    } = useAudioRecorder({
        autoGainControl: true,
        noiseSuppression: true,
        echoCancellation: true,
        sampleRate: 44100,
        sampleSize: 2
    })

    const [cancel, setCancel] = useState<boolean>(false)

    const dispatch = useAppDispatch()
    const { id: chatID = '0' } = useParams()
    const { pendingMessages } = useAppSelector((state: RootState) => state.chat)
    const microphoneAccessProvided = useAppSelector((state: RootState) => state.app.microphoneAccessProvided)
    useEffect(() => {
        const upload = async (): Promise<void> => {
            if (recordingBlob !== undefined && !cancel) {
                const id = pendingMessages.length
                // dispatch(setPendingMessage('type:voice', id))
                const duration = await getBlobDuration(recordingBlob)
                // dispatch(removePendingMessage(id))
                dispatch(setPendingMessage('type:voice', id, 'Voice message', URL.createObjectURL(recordingBlob), 0, '', duration))
                const mp3Blob = await transcodeVoiceMessage(recordingBlob)
                if (mp3Blob != null) {
                    const file = new File([mp3Blob], `voice-${nanoid(10)}.mp3`, {
                        type: 'audio/mpeg'
                    })
                    const uploadedFile = await uploadStrapiFile(file, 'chat-rooms', chatID)
                    if (uploadedFile != null) {
                        dispatch(setCurrentMessage('type:voice'))
                        dispatch(setCurrentFile({ ...uploadedFile, duration }))
                        dispatch(sendMessage(+chatID)).finally(() => {
                            dispatch(removePendingMessage(id))
                        })
                    } else {
                        dispatch(removePendingMessage(id))
                    }
                } else {
                    dispatch(removePendingMessage(id))
                }
            }
        }
        void upload()
    }, [recordingBlob])

    return (
        <>
            <Icon
                name={'mic'}
                className={'text-muted fs-3x'}
                onClick={() => {
                    startRecording()
                }}
            />
            {
                isRecording
                    ? <div className={'position-fixed w-100 d-flex justify-content-between align-items-center p-4 pb-8 gap-4 bg-white'}
                        style={{ height: '59px', bottom: 0, left: 0, zIndex: '101' }}
                    >
                        <div style={{ minWidth: '50px' }} className={'text-gray-600 d-flex align-items-center'}>
                            <Icon
                                name={'mic'}
                                className={'text-muted fs-3x'}
                            />
                            {/* <Timer startTime={startTime}/> */}
                            <>{moment(recordingTime * 1000).format('m:ss')}</>
                        </div>
                        <div className={'btn btn-sm btn-light-danger'}
                            onClick={() => {
                                setCancel(true)
                                setTimeout(() => {
                                    stopRecording()
                                    setTimeout(() => {
                                        setCancel(false)
                                    }, 100)
                                }, 100)
                            }}
                        >
                            CANCEL
                        </div>

                        <div className={'rounded-circle d-flex justify-content-center align-items-center pulse'}
                            style={{ background: '#fff200', height: '40px', width: '40px' }}
                            onClick={() => {
                                stopRecording()
                            }}
                        >
                            <Icon
                                name={'send'}
                                className={'text-muted fs-3x'}
                            />
                        </div>
                    </div>
                    : <></>
            }
        </>
    )
}

export default VoiceRecorder
