import { type IOrder } from "../../../store/slices/userSlice"
import { store } from "../../../store/store"

export const getCurrentSub = () => {
    const currentUser = store.getState().user.currentUser
    const serviceAgentSubscription = (currentUser.Orders as IOrder[])?.filter(
        (order: IOrder) => order?.Statuses?.includes('Paid') && order?.Service?.some((service) => service.__component === 'services.broker-subscription')
    )

    serviceAgentSubscription?.sort((a, b) => new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime())

    const latestServiceAgentSubscription = serviceAgentSubscription?.[0]
    const beforeLatestServiceAgentSubscription = serviceAgentSubscription?.[1]

    const packageTitle = latestServiceAgentSubscription?.Service?.[0]?.Package
    const activeSub = packageTitle?.includes('Premium') ? 'Premium' : packageTitle?.includes('Standard') ? 'Standard' : packageTitle?.includes('Free') ? 'Free' : '' // 'free'
    const activeSubPeriod = latestServiceAgentSubscription?.Name?.includes('(Yearly)') ? 'annual' : latestServiceAgentSubscription?.Name?.includes('(Weekly)') ? 'weekly' : 'month'

    return { activeSub, activeSubPeriod, latestServiceAgentSubscription, beforeLatestServiceAgentSubscription }
}
