import React from 'react'

export default function PrivacyPolicyContent (): JSX.Element {
    return (
        <div className="row g-0 ">
            <div className="row g-0 text-end fs-8">
                <p className="mb-0">
BSO REAL ESTATE MANAGEMENT</p>
                <p className="mb-0">BSO Mobile Application</p>
                <p className="mb-0">BSO CLUB</p>
            </div>
            <div className="p-5 col-lg-12 col-md-12 col-sm-12 col-12 blog text-justify">
                <h1 className="text-center mb-0">PRIVACY POLICY</h1>
                <p className="text-center fs-8"> Latest updates published on <span className="text-danger fw-bold">01.04.2024</span></p>
                <h4 id="1-introduction">1. INTRODUCTION</h4>
                <p>BSO Real Estate Management (“BSO”, or “we”, or “us”, or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application (“BSO Mobile App”), including any other media form, media channel, website, or BSO Mobile App related or connected thereto. Please read this Privacy Policy carefully. If you do not agree with the Terms of this Privacy Policy, please do not access BSO Mobile App.
                </p>
                <p>
                We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the date of this Privacy Policy. Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on BSO Mobile App, and you waive the right to receive specific notice of each such change or modification. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of BSO Mobile App after the date such revised Privacy Policy is posted.</p>
                <h4 id="2-collection-of-information">2. COLLECTION OF INFORMATION</h4>
                <p>We may, at any time, collect different information about you in a variety of ways. The information we may collect on BSO Mobile App includes:</p>
                <p> <span className="fw-bold">2.1 Personal Data:</span>{' '}
                personally identifiable information, such as your name, address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you may be required to give to us when you register on BSO Mobile App, or when you choose to participate in various services and/or activities related BSO Mobile App;</p>
                <p><span className="fw-bold">2.2 Derivative Data:</span>{' '}
                information our servers automatically collect when you access BSO Mobile App such as your IP address, your browser type, your operating system, your access times. This information may also include your device name and type, your operating system, your phone number, your country and other interactions with the application and other users via server log files, as well as any other information you choose to provide;</p>
                <p><span className="fw-bold">2.3 Financial Data: </span>{' '}
                financial information, such as data related to your payment method (e.g. valid credit and/or debit card number, card brand, expiration date) that we may collect when you purchase any subscription from our list of services from BSO Mobile App. All financial information is stored by our payment gateway service providers (“Payment Provider”): Emirates Telecommunication Group Company P. J. S. C. (the “Etisalat Group”) and/or Network International Payment Solutions (the “Network International”);</p>
                <p><span className="fw-bold">2.4 BSO Mobile App Information: </span>{' '}</p>
                <p>2.4.1 Geo-Location Information: we may request access or permission to and track location-based information from your mobile device, either continuously or while you are using BSO Mobile App, to provide location-based services. Any change to our access or permissions may be done through a device’s settings;</p>
                <p>2.4.2 Mobile Device Access: we may request access or permission to certain features from your mobile device, including your mobile device’s Bluetooth, calendar, camera, contacts, microphone, reminders, sensors, SMS messages, social media accounts, storage and other features. Any change our access or permissions may be done through a device’s settings;</p>
                <p>2.4.3 Mobile Device Data: mobile device information such as your mobile device ID, model and manufacturer, operating system, version information and IP address.</p>
                <p><span className="fw-bold">2.5 Third Party Data: </span>{' '}
                information from third parties, such as personal information or network friends, if you connect your account to the third party and grant BSO Mobile App permission to access this information.</p>
                <h4 id="3-why-we-process-your-information">3. WHY WE PROCESS YOUR INFORMATION</h4>
                <p>We generally process your information when we need to do so to fulfill a contractual obligation, for example, to process your subscription payments to use the platform, or where we or someone we work with needs to use your personal information for a reason related to their business. Other reasons include and are not limited to:</p>
                <p>3.1 Preventing risk and fraud;</p>
                <p>3.2 Answering questions or providing other types of support;</p>
                <p>3.3 Providing and improving our products and services;</p>
                <p>3.4 Providing reporting and analytics;</p>
                <p>3.5 Testing out features or additional services;</p>
                <p>3.6 Assisting with marketing, advertising, or other communications.</p>
                <h4 id="4-disclosure-of-information">4. DISCLOSURE OF INFORMATION</h4>
                <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
                <p><span className="fw-bold">4.1 By Law Or To Protect Rights: </span>{' '}
                if we believe the release of information about you is necessary to respond to legal matters, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction;</p>
                <p><span className="fw-bold">4.2 Third-Party Service Providers: </span>{' '}
we may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance;we may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance;</p>
                <p><span className="fw-bold">4.3 Marketing Communications: </span>{' '}
                with your consent, and/or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law;</p>
                <p><span className="fw-bold">4.4 Interactions With Other Users: </span>{' '}
                other users on BSO Mobile App can see your name, profile photo, rating and descriptions of your activity, including sending invitations to other users, chatting with other users, and other services or activities;</p>
                <p><span className="fw-bold">4.5 Third-Party: </span>{' '}</p>
                <p>4.5.1 We may use third-party advertising companies to serve ads when you visit BSO Mobile App. These companies may use information about your visits to BSO Mobile App and other websites that are contained in web cookies and/or other similar technologies in order to provide advertisements about goods and services of interest to you;</p>
                <p>4.5.2 We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.</p>
                <p><span className="fw-bold">4.6 Affiliates:</span>{' '}
                we may share your information with our affiliates, in which case we will require those affiliates to honor the Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us;</p>
                <p><span className="fw-bold">4.7 Business Partners:</span>{' '}
                we may share your information with our business partners to offer you certain products, services or promotions.</p>
                <h4 id="5-tracking-technologies">5. TRACKING TECHNOLOGIES</h4>
                <p><span className="fw-bold">5.1 Cookies:</span>{' '}
                we may use cookies and/or other similar tracking technologies (i.e. web beacons, browser analysis tools, pixels, server logs, and mobile identifiers) to help customize BSO Mobile App and improve your experience. When you access BSO Mobile App, tracking technology does not collect your personal information. Most browsers are set to accept cookies and/or other similar technologies by default. You can remove or reject cookies and/or other similar technologies but be aware that such action could affect the availability and functionality of BSO Mobile App;</p>
                <p><span className="fw-bold">5.2 Internet-Based Advertising:</span>{' '}
                additionally, we may use third-party software to serve ads on BSO Mobile App, implement email marketing campaigns, and manage other interactive marketing initiatives. This third-party software may use cookies and/or other similar technologies to help manage and optimize your online experience with us;</p>
                <p><span className="fw-bold">5.3 Third Party:</span>{' '}</p>
                <p>5.3.1: We may also partner with selected third-party vendors to allow tracking technologies and remarketing services on to BSO Mobile App through the use of first party cookies and/or other similar technologies and third-party cookies and/or other similar technologies, to, among other things, analyze and track users’ use of BSO Mobile App, determine the popularity of certain content and better understand online activity. By accessing BSO Mobile App, you consent to the collection and use of your information by these third-party vendors;</p>
                <p>5.3.2: BSO Mobile App may contain links to third-party websites and applications of interest, including advertisements and external services, which are not affiliated with us. Once you have used these links to leave BSO Mobile App, the Privacy Policy does not cover any information you provide to these third parties, and we cannot guarantee the safety and privacy of your information.</p>
                <h4 id="6-data-retention">6. DATA RETENTION</h4>
                <p>When you place an order through BSO Mobile App, we will maintain your order information for our records unless and until you ask us to delete this information.</p>
                <h4 id="7-minors">7. MINORS</h4>
                <p>BSO Mobile App is not intended for individuals under the age of eighteen (18) years. If you become aware of any data we have collected from children under age of eighteen (18), please contact us using the contact information provided below.</p>
                <h4 id="8-security-of-your-information">8. SECURITY OF YOUR INFORMATION</h4>
                <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.</p>
                <h4 id="9-your-rights">9. YOUR RIGHTS</h4>
                <p>If you are a European resident, you have the right to access the personal information we hold about you and to ask that your personal information is corrected, updated, or deleted. If you would like to exercise this right, please contact us.</p>
                <h4 id="10-emails-and-communications">10. EMAILS AND COMMUNICATIONS</h4>
                <p>If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:</p>
                <p>10.1 Noting your preferences at the time you register your account with BSO Mobile App;</p>
                <p>10.2 Logging into your account settings and updating your preferences;</p>
                <p>10.3 Contacting us using the contact information provided below.</p>
                <h4 id="11-contact-us">11. CONTACT US</h4>
                <p>If you have questions or comments about the Privacy Policy, please contact us at email <a href="mailto:helpcenter@bso.ae">helpcenter@bso.ae</a> and/or phone +971 4 454 7337.</p>

            </div>

        </div>
    )
}
