import React, { useRef } from 'react'
import {
    type IOrder,
    type ISettings
} from '../../../../store/slices/userSlice'
import { mounthes } from '../../../../functions/agent/date/timeString'
import Icon from '../../../../app/icon/Icon'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'

interface IProps {
    orders: IOrder[]
}

export default function HistoryCardsList ({ orders }: IProps): JSX.Element {
    // const ServiceAgentSubscription = (currentUser.Orders as IOrder[])?.filter(
    //     (order: IOrder) => order.Service.some((service) => service.__component === 'services.broker-subscription')
    // )
    // ServiceAgentSubscription?.sort((a, b) => new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime())

    // const latestServiceAgentSubscription = ServiceAgentSubscription?.[0]
    // console.log(latestServiceAgentSubscription, 'latestServiceAgentSubscription')
    // const packageTitle = latestServiceAgentSubscription?.Service?.[0]?.Package
    // console.log(packageTitle, 'packageTitle')
    // const activeSub = packageTitle?.includes('Premium') ? 'Premium' : packageTitle?.includes('Standard') ? 'Standard' : packageTitle?.includes('Free') ? 'Free' : '' // 'free'
    // const activeSubPeriod = latestServiceAgentSubscription?.Name?.includes('(Yearly)') ? 'annual' : latestServiceAgentSubscription?.Name?.includes('(Weekly)') ? 'weekly' : 'month'
    const IconSuccess = <svg xmlns="http://www.w3.org/2000/svg" className={'fs-7x'}/* xmlns:xlink="http://www.w3.org/1999/xlink" */ viewBox="0,0,256,256" width="1em" height="1em"><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}><g transform="scale(3.2,3.2)"><path d="M40,77.5c-20.7,0 -37.5,-16.8 -37.5,-37.5c0,-20.7 16.8,-37.5 37.5,-37.5c20.7,0 37.5,16.8 37.5,37.5c0,20.7 -16.8,37.5 -37.5,37.5z" fill="#bae0bd"></path><path d="M40,3c20.4,0 37,16.6 37,37c0,20.4 -16.6,37 -37,37c-20.4,0 -37,-16.6 -37,-37c0,-20.4 16.6,-37 37,-37M40,2c-21,0 -38,17 -38,38c0,21 17,38 38,38c21,0 38,-17 38,-38c0,-21 -17,-38 -38,-38z" fillOpacity="0" fill="#5e9c76"></path><path d="M34,56l-13.8,-13.8l4.3,-4.2l9.5,9.6l24.2,-24.2l4.3,4.2z" fill="#ffffff"></path></g></g></svg>
    const IconCancel = <svg xmlns="http://www.w3.org/2000/svg" className={'fs-7x'} viewBox="0,0,256,256" width="1em" height="1em"><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}><g transform="scale(3.2,3.2)"><path d="M40,77.5c-20.678,0 -37.5,-16.822 -37.5,-37.5c0,-20.678 16.822,-37.5 37.5,-37.5c20.678,0 37.5,16.822 37.5,37.5c0,20.678 -16.822,37.5 -37.5,37.5z" fill="#f78f8f"></path><path d="M40,3c20.402,0 37,16.598 37,37c0,20.402 -16.598,37 -37,37c-20.402,0 -37,-16.598 -37,-37c0,-20.402 16.598,-37 37,-37M40,2c-20.987,0 -38,17.013 -38,38c0,20.987 17.013,38 38,38c20.987,0 38,-17.013 38,-38c0,-20.987 -17.013,-38 -38,-38z" fillOpacity="0" fill="#c74343"></path><path transform="translate(39.99901,96.56854) rotate(-134.999)" d="M37,20h6v40h-6z" fill="#ffffff"></path><path transform="translate(-16.56854,40.00099) rotate(-45.001)" d="M37,20h6v40h-6z" fill="#ffffff"></path></g></g></svg>
    return (
        <div className={'d-flex flex-column-reverse gap-4'}>
            {orders &&
                orders.map((ord) => {
                    const orderName = ord?.Name ?? ''
                    let name = ord?.Name
                    let desc = ''
                    if (orderName.split('(').length > 0) {
                        name = orderName.split('(')[0]
                        desc = orderName.split('(')[1]
                            ? orderName
                                .split('(')[1]
                                ?.slice(
                                    0,
                                    orderName.split('(')[1]?.length -
                                    1
                                )
                            : 'Basic bundle'
                    }

                    if (name?.includes('_') && (name?.includes('Free') || name?.includes('Standard') || name?.includes('Premium'))) {
                        name = name.split('_')?.[2] ?? name
                        desc = 'Subscription'
                    }

                    const createdAtDate = ord?.createdAt != null
                        ? dayjs(ord?.createdAt)
                        : null

                    const status = ord?.Statuses?.[0] ?? 'In-process'
                    const expDate = ord?.Service?.[0]?.ExpiryDate != null
                        ? dayjs(ord?.Service?.[0]?.ExpiryDate)
                        : null
                    return (
                        <div className={'card shadow shadow-sm'} key={nanoid()}>
                            <div className={'card-body d-flex flex-column gap-4 px-5 py-4'}>
                                <div className={'d-flex gap-4 align-items-center'}>
                                    <div>
                                        { status !== 'Canceled'
                                            ? IconSuccess
                                            : IconCancel
                                        }
                                    </div>
                                    <div className={'d-flex flex-column gap-1'}>
                                        <div>
                                            {name} {desc}
                                        </div>
                                        <div className={''}>
                                            {createdAtDate != null && (
                                                <label>
                                                    {
                                                        createdAtDate.format('MMM DD YYYY')
                                                    }
                                                    {
                                                        expDate != null && desc === 'Subscription'
                                                            ? ` - ${expDate.format('MMM DD YYYY')}`
                                                            : ''
                                                    }
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={'px-1'}>
                                    <div className={'d-flex align-items-center justify-content-between border-bottom border-color-muted border-width-2 pb-2'}>
                                        <span className={'text-muted'}>
                                            Price
                                        </span>
                                        <span>
                                            {ord?.Amount} AED
                                        </span>
                                    </div>
                                    <div className={'d-flex align-items-center justify-content-between mt-2'}>
                                        <span className={'text-muted'}>
                                            Status
                                        </span>
                                        <span>
                                            {status}
                                        </span>
                                    </div>
                                </div>
                                <div className={'d-flex gap-4'}>
                                    <div className={ord?.InvoiceURL !== null ? 'btn btn-bso w-100' : 'btn btn-bso w-100 opacity-50'}
                                        onClick={
                                            ord?.InvoiceURL !== null
                                                ? () =>
                                                    window.open(
                                                        ord?.InvoiceURL as string,
                                                        '_self'
                                                    )
                                                : undefined
                                        }
                                    >
                                        Get invoice
                                    </div>
                                    <div className={ord?.ReceiptURL !== null ? 'btn btn-bso w-100' : 'btn btn-bso w-100 opacity-50' }
                                        onClick={ ord?.ReceiptURL !== null
                                            ? () =>
                                                window.open(
                                                    ord?.ReceiptURL as string,
                                                    '_self'
                                                )
                                            : undefined}
                                    >
                                        Get receipt
                                    </div>
                                </div>
                            </div>
                        </div>
                        // <tr key={ord?.id}>
                        //     <td>
                        //         <label className="min-w-100px mw-125px  lh-1">
                        //             {/* {ord?.Service?.[0]?.Package ?? ord?.Name} */}
                        //             {name}
                        //         </label>
                        //         <div className="fw-normal text-gray-600">
                        //             {desc}
                        //         </div>
                        //     </td>
                        //
                        //     {createdAtDate != null && (
                        //         <>
                        //             <td className="text-start">
                        //                 <label className="min-w-40px">
                        //                     {mounthes[
                        //                         createdAtDate.getMonth()
                        //                     ].slice(0, 3)}{' '}
                        //                     {createdAtDate
                        //                         .getDate()
                        //                         .toString()
                        //                         .padStart(2, '0')}
                        //                 </label>
                        //                 <div className="fw-normal text-gray-600">
                        //                     {' '}
                        //                     {createdAtDate
                        //                         .getFullYear()
                        //                         .toString()}
                        //                 </div>
                        //             </td>
                        //         </>
                        //     )}
                        //     <td className="text-start">
                        //         <label>{ord?.Amount}</label>
                        //         <div className="fw-normal text-gray-600">
                        //             AED
                        //         </div>
                        //     </td>
                        //     <td className="text-end">
                        //         <div className="d-flex flex-column">
                        //             {/* window.open(createOrder?.url, '_self') */}
                        //             <span
                        //                 onClick={
                        //                     ord?.InvoiceURL !== null
                        //                         ? () =>
                        //                             window.open(
                        //                                 ord?.InvoiceURL as string,
                        //                                 '_self'
                        //                             )
                        //                         : undefined
                        //                 }
                        //                 className={`${
                        //                     ord?.InvoiceURL !== null
                        //                         ? ' btn-color-primary btn-active-color-primary'
                        //                         : 'btn-color-muted'
                        //                 } btn btn-icon btn-sm me-3 d-flex align-items-center`}
                        //             >
                        //                 <span>Invoice{' '}</span>
                        //                 <Icon
                        //                     name={'print'}
                        //                     className={
                        //                         'fs-3 text-muted ms-1 d-flex justify-content-center align-items-center'
                        //                     }
                        //                     useWrapper
                        //                 />{' '}
                        //             </span>
                        //
                        //             <span
                        //                 onClick={
                        //                     ord?.ReceiptURL !== null
                        //                         ? () =>
                        //                             window.open(
                        //                                 ord?.ReceiptURL as string,
                        //                                 '_self'
                        //                             )
                        //                         : undefined
                        //                 }
                        //                 className={`${
                        //                     ord?.ReceiptURL !== null
                        //                         ? 'btn-color-primary  btn-active-color-primary'
                        //                         : 'btn-color-muted'
                        //                 } btn btn-icon  btn-sm me-3 d-flex align-items-center`}
                        //             >
                        //                 <span>Receipt{' '}</span>
                        //                 <Icon
                        //                     name={'mail'}
                        //                     className={
                        //                         'fs-3 text-muted ms-1 d-flex justify-content-center align-items-center'
                        //                     }
                        //                     useWrapper
                        //                 />{' '}
                        //             </span>
                        //         </div>
                        //     </td>
                        // </tr>
                    )
                })}
        </div>
    )
}
