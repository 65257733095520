import { createSlice } from '@reduxjs/toolkit'
import { type IUnitKey } from './unitsSlice'
import { USER_ID_KEY } from '../../urls'

interface IUserVar {
    data: {
        id: number
        attributes: {}
    } | null
}

interface IKeyVar {
    data: IUnitKey | null
}

interface IUnitVar {
    data: {
        id: number
        attributes: {
            User: IUserVar | null
        }
    } | null
}

export interface IViewing {
    id: number
    attributes: {
        createdAt: string
        updatedAt: string
        Complaint: string | null
        Comments: string[] | null
        Notify: string | null
        Feedback: string | null
        Tasks: any[]
        Name: string
        Datetime: string
        Statuses: string[] | null
        User: IUserVar
        Unit: IUnitVar
        KeyBooking: IKeyVar
        DigitalLockPass: null | string
        RejectReason: null | string
        NotifyViewedDate: string | null
        AgentToAgentID: number | null
    }
}

interface ViewingsState {
    viewings: IViewing[]
    viewingsLoading: boolean
    error: string
    viewingsListOffsetPosition: number
    areThereMoreThanFiveViewings: boolean
}

const initialState: ViewingsState = {
    viewings: [],
    viewingsLoading: false,
    error: '',
    viewingsListOffsetPosition: 0,
    areThereMoreThanFiveViewings: false
}

const userID = window.localStorage.getItem(USER_ID_KEY)

export const viewingsSlice = createSlice({
    name: 'viewings',
    initialState,
    reducers: {
        viewingsFetching (state) {
            state.viewingsLoading = true
        },
        viewingsFetchingSuccess (state, action) {
            state.error = ''
            state.viewings = action.payload
            state.viewingsLoading = false
            state.areThereMoreThanFiveViewings = action.payload.filter((viewing: IViewing | null) => viewing?.attributes?.User?.data?.id === Number(userID))?.length > 5
        },

        addViewing: (state, action) => {
            state.error = ''

            const findDublicate = state.viewings.find((view) => view.id === action.payload.id)
            if (findDublicate === undefined) {
                const actualViewings = [...state.viewings, action.payload]
                state.viewings = actualViewings
                state.areThereMoreThanFiveViewings = actualViewings.filter((viewing: IViewing | null) => viewing?.attributes?.User?.data?.id === Number(userID))?.length > 5
            }

            state.viewingsLoading = false
        },
        setViewings: (state, action) => {
            state.error = ''
            const copyViewings = [...state.viewings]
            const updatedViewings = copyViewings.map((viewing: any) => {
                if (viewing.id === action.payload.id) {
                    return {
                        ...action.payload
                    }
                } else {
                    return viewing
                }
            })
            // console.log(updatedViewings, ' <<<<<<< updatedViewings <<<<<<< ??')
            state.viewings = updatedViewings
            state.areThereMoreThanFiveViewings = updatedViewings.filter((viewing: IViewing | null) => viewing?.attributes?.User?.data?.id === Number(userID))?.length > 5
            state.viewingsLoading = false
        },

        viewingsFetchingError (state, action) {
            state.viewingsLoading = false
            state.error = action.payload
        },
        setViewingsListOffsetPosition (state, action) {
            state.viewingsListOffsetPosition = action.payload
        }
    }
})

export const { addViewing, setViewings, setViewingsListOffsetPosition, viewingsFetchingError } = viewingsSlice.actions

export default viewingsSlice.reducer
