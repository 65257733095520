import React, { useEffect } from "react";

import UnitBodySh from "./UnitBodySh";
import UnitOfferSaleSh from "./UnitOfferSaleSh";
import { type Unit } from "../../../store/slices/unitsSlice";
import { type ISettings } from "../../../store/slices/userSlice";
import UnitArchiveButtonSh from "./UnitArchiveButtonSh";
import useUserType from "../../../hooks/UseUserType";

interface IProps {
    hide: boolean;
    unit: Unit;
    fromEntry: string | undefined;
    settings?: ISettings[];
}

export default function UnitSh({
    hide,
    unit,
    fromEntry,
    settings,
}: IProps): JSX.Element {
    const { userType } = useUserType();
    return (
        <div className="card shadow-sm mb-4 position-relative" key={`unit-${unit.id}`}>
            {userType === "Landlord" && (
                <UnitArchiveButtonSh unit={unit} />
            )}
            <UnitOfferSaleSh hide={hide} unit={unit} />
            <UnitBodySh unit={unit} fromEntry={fromEntry} settings={settings} />
        </div>
    );
}
