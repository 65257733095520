import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    statusesApproved: {
        emiratesApproved: false,
        passportApproved: false,
        licenseApproved: false
    },
    documentsLoaded: {
        emiratesLoaded: false,
        passportLoaded: false,
        licenseLoaded: false
    },
    documentsInProgress: {
        emiratesInProgress: false,
        passportInProgress: false,
        licenseInProgress: false
    },
    documentsRejected: {
        passportReject: false,
        emiratesReject: false,
        licenseReject: false
    },
    documentsExpires: {
        emiratesExpire: false,
        passportExpire: false,
        licenseExpire: false
    },
    tutorialViewed: false

}

export const agentSlice = createSlice({
    name: 'agentStates',
    initialState,
    reducers: {
        setStatuses: (state, action) => {
            state.statusesApproved.emiratesApproved = action.payload.emiratesApproved
            state.statusesApproved.licenseApproved = action.payload.licenseApproved
            state.statusesApproved.passportApproved = action.payload.passportApproved
            //
            state.documentsLoaded.emiratesLoaded = action.payload.emiratesLoaded
            state.documentsLoaded.licenseLoaded = action.payload.licenseLoaded
            state.documentsLoaded.passportLoaded = action.payload.passportLoaded
            //
            state.documentsInProgress.emiratesInProgress = action.payload.emiratesInProgress
            state.documentsInProgress.licenseInProgress = action.payload.licenseInProgress
            state.documentsInProgress.passportInProgress = action.payload.passportInProgress
            //
            state.documentsRejected.passportReject = action.payload.passportReject
            state.documentsRejected.emiratesReject = action.payload.emiratesReject
            state.documentsRejected.licenseReject = action.payload.licenseReject
            //
            state.documentsExpires.emiratesExpire = action.payload.emiratesExpire
            state.documentsExpires.passportExpire = action.payload.passportExpire
            state.documentsExpires.licenseExpire = action.payload.licenseExpire
        },
        setTutorialViewed: (state) => {
            state.tutorialViewed = true
        }
    }
})
export const { setStatuses, setTutorialViewed } = agentSlice.actions
export default agentSlice.reducer
