import { swalNeedLoadDocs } from '../../swalOptions'

import { type NavigateFunction } from 'react-router-dom'

export const onBlockLoadDocuments = (userTypeLowercase: string, callback: NavigateFunction): void => {
    void swalNeedLoadDocs().then(async (result: any) => {
        if (result?.isConfirmed) {
            callback(`/${userTypeLowercase}/modal/update_emirates_front_together/INagentMODULE/to_choose_subscription`)
        } if (result.isDismissed) {
            //   console.log('cancel')
        }
    })
}
