import React from 'react'

import { Collapse, type CollapseProps } from 'antd'
import { type IOrder } from '../../../../store/slices/userSlice'

import OrdersHistoryButton from './OrdersHistoryButton'

import HistoryList from './HistoryList'
import { nanoid } from 'nanoid'
import CurrentSub from './CurrentSub'

interface IProps {
    orders: IOrder[]
    latestServiceAgentSubscription: IOrder | null
}

export default function OrdersSubscriptions ({ latestServiceAgentSubscription, orders }: IProps): JSX.Element {
    // console.log(orders, 'orders')
    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: <span className="card-title fs-3 fw-bold fw-bold p-0 m-0">
              Orders & Subscriptions
            </span>,
            children: <div className="card-body p-0 m-0">
                <div className="d-flex flex-wrap align-items-center mb-2">
                    <div>
                        <div className="fs-6 fw-bold mb-1 text-uppercase">
                            My Orders
                        </div>
                    </div>

                    <div className="ms-auto">
                        <OrdersHistoryButton title={'Open History'}>
                            <HistoryList orders={orders}/>
                        </OrdersHistoryButton>

                    </div>
                </div>

                <div className="separator separator-dashed my-6"></div>
                {/* <label className="nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6 mb-6 active" data-bs-toggle="tab" aria-selected="true" role="tab"> */}

                <CurrentSub latestServiceAgentSubscription={latestServiceAgentSubscription}/>
            </div>

        }]
    return (
        <div key={nanoid()} className="card shadow-sm mb-2">
            <Collapse defaultActiveKey={'1'} items={items} />

        </div>
    )
}
