import React from 'react'
import styles from './style.module.css'
function CustomInputCode (): JSX.Element {
    return (
        // {styles.otpInputFields}
        <div className={'w-100 d-flex justify-content-between px-10'}>
            <input type="number" className="w-40px h-40px rounded-4"/>
            <input type="number" className="w-40px h-40px rounded-4"/>
            <input type="number" className="w-40px h-40px rounded-4"/>
            <span className={'fs-4x fw-bolder'}> - </span>
            <input type="number" className="w-40px h-40px rounded-4"/>
            <input type="number" className="w-40px h-40px rounded-4"/>
            <input type="number" className="w-40px h-40px rounded-4"/>
        </div>

    )
}

export default CustomInputCode
