import swalTexts from '../../../app/texts/swalTexts'
import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'

import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../urls'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'
import { setFilterNavigate } from '../../slices/scheduleSlice'
import { setShowPopupViewingReschedule } from '../../slices/swalsSlice'
import { type User } from '../../slices/userSlice'
import { setViewings, viewingsFetchingError } from '../../slices/viewingsSlice'
import { type AppDispatch, type RootState } from '../../store'
import { viewingsQuery } from '../viewingsActions'
import { createNotifyViewing } from './createNotifyViewing'

export const updateViewing = (bodyClient: any) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const currentDubaiDate = getCurrentDubaiDate()
    const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)


    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const units: any = getState().units.units
            const unit = units?.filter((el: any) => el.id === Number(bodyClient.unitID))
            const unitStatuses = unit[0].attributes?.Statuses ?? []

            const currentUnit = unit?.[0]
            const currentUser = getState().user.currentUser
            const nameUser = getNameUser(currentUser as User)
            const unitAddress = getUnitAddress(currentUnit)

            const createDigitalLock = unitStatuses?.includes('Digital lock')
            const createKeysInBSO = unitStatuses?.includes('Keys in BSO office')
            const createBuildingManagement = unitStatuses?.includes('Building Management')
            const createOccupied = unitStatuses?.includes('Occupied')
            const isDoorOpen = unitStatuses?.includes('Door open')
            const isDoorCloseAndOwnsOtherAgent = unitStatuses?.includes('Door close')
            const isSpecialRequest = unitStatuses?.includes('Special Request')
            const dateViewing = new Date(`${bodyClient.time_viewing}+04:00`)
            dispatch(dataFetchingStart())

            const bodyObject = {
                method: 'PUT',
                collection: 'viewings',
                query: viewingsQuery,
                id: Number(bodyClient.viewID),
                body: {
                    Datetime: dateViewing,
                    Statuses: [isDoorOpen ? 'Approved' : 'Rescheduled by agent'],
                    Notify: isDoorCloseAndOwnsOtherAgent ? 'Other Agent' : 'Both'
                }
            }
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                },
                body: JSON.stringify(bodyObject)
            })
            const dataJSON = await response.json()

            if (dataJSON.success) {
                if (dataJSON.response.error != null) {
                    dispatch(viewingsFetchingError(getErrorss(dataJSON)))
                    dispatch(dataFetchingFinish())
                    return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
                } else {
                    if (createOccupied) {
                        const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                        await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-in-process', `Attention admin, ${nameUser} has selected ${unitAddress} which is in Occupied , please respond.`)
                    }
                    
     
                    if (createKeysInBSO) {
                        const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                        await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-key', `Attention admin, ${nameUser} has selected ${unitAddress} which contains an Keys in BSO , please respond.`)
                    } if (createDigitalLock) {
                        const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                        await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-digital-lock', `Attention admin, ${nameUser} has selected ${unitAddress} which contains an digital lock , please respond.`
                        )
                    }
                    if (createBuildingManagement) {
                        const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                        await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-building-management', `Attention admin, ${nameUser} has selected ${unitAddress} which contains an Building Management , please respond.`
                        )
                    }

                    if(isSpecialRequest){
                        const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                        await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-building-management', `Attention admin, ${nameUser} has selected ${unitAddress} which contains an Special Request , please respond.`
                        )
                    }


                    await createNotifyViewing(dataJSON.response.data, unit, isDoorCloseAndOwnsOtherAgent) // true
                    await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON.response.data.id, 'admin-viewing-newtime', `Attention admin, ${nameUser} has rescheduled ${unitAddress}`)
                    dispatch(setShowPopupViewingReschedule(false))
                    dispatch(setViewings(
                        dataJSON.response.data
                    ))
                    dispatch(dataFetchingFinish())
                    const areThereMoreThanFiveViewings = getState().viewings.areThereMoreThanFiveViewings

                    if (!areThereMoreThanFiveViewings && !isDoorOpen) {
                        if (!areThereMoreThanFiveViewings) {
                            dispatch(setFilterNavigate('viewings'))
                        }
                    }

                    return {
                        ...dataJSON,
                        textNavigate: !areThereMoreThanFiveViewings && !isDoorOpen
                            ? '/agent/schedule'
                            : -1,

                        isSuccessful: true,
                        textNotification: swalTexts.successViewingsActions.updateViewing.defaultMessage
                    }
                }
            } else {
                dispatch(dataFetchingFinish())
                dispatch(viewingsFetchingError(getErrorss(dataJSON)))
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } catch (e) {
            dispatch(dataFetchingFinish())
            console.log(e)
        }
    }
}
