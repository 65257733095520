import React, { useEffect, useState } from 'react'
import ChatListHeader from './ChatListHeader'
import ChatCard from './ChatCard'
import { getChatList } from '../../../../store/actionsCreators/chatListActions'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import _ from 'lodash'
import useWebSocket from '../../../../hooks/useWebSocket'
import ChatUtils from '../../../../functions/chats/ChatUtils'
import ListLoader from '../../../../app/custom/loaders/ListLoader'
import { Link, useSearchParams } from 'react-router-dom'
import useUserType from '../../../../hooks/UseUserType'
import { nanoid } from 'nanoid'
import { type IChatListItem } from '../../../../types'


export const isPinnedChat = (chat: IChatListItem): boolean => {
    const isDev = process.env.REACT_APP_IS_DEV === 'true'
    if (isDev) {
        return (ChatUtils.isBSOSupport(chat) || ChatUtils.isBSOBroadcast(chat))
    }
    return ChatUtils.isBSOSupport(chat)
}


const ChatList = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const [searchParams] = useSearchParams()
    const favorites = searchParams.get('filter') === 'favorites'
    const blocked = searchParams.get('filter') === 'blocked'
    const { currentUser } = useAppSelector((state: RootState) => state.user)
    const { userType } = useUserType()
    const userDevice = useAppSelector((state) => state.app.userDevice)

    const { chatList } = useAppSelector((state: RootState) => state.chatList)


    return (
        <div className={'bg-gray-200 bg-white'} style={{ height: 'calc(100vh - 12rem)' }} >
            <ChatListHeader />
            {loading
                ? <ListLoader height={'calc(100vh - 17rem)'} />
                : <div className={'d-flex flex-column'}>
                    {!favorites && !blocked
                        ? chatList
                            .filter(isPinnedChat)
                            .map((chat, index) => (
                                <ChatCard
                                    key={nanoid()}
                                    blocked={_.some((currentUser as any).BlackList, (user) => user.id === chat.userID)}
                                    favorite={_.some((currentUser as any).FavoriteList, (user) => user.id === chat.userID)}
                                    chat={chat}
                                    isAdmin={true}
                                />
                            ))
                        : <></>
                    }

                    <div className={'d-flex flex-column overflow-auto pb-12'}>
                        {
                            _.map(
                                favorites || blocked
                                    ? _.filter(chatList, chat =>
                                        _.some(
                                            favorites ? (currentUser as any).FavoriteList : (currentUser as any).BlackList,
                                            favItem => favItem.id === chat.userID
                                        ) && chat?.userID != null
                                    )
                                    : _.filter(chatList.filter(chat => !ChatUtils.isMessageOffer(chat)), chat => !isPinnedChat(chat) && (chat?.userID != null && chat?.userID !== Number(process.env.REACT_APP_BSO_USER_ID) && (chat?.LastMessage?.updatedAt !== '' || _.includes([/* Number(process.env.REACT_APP_BSO_USER_ID) */, Number(process.env.REACT_APP_BSO_SUPPORT_USER_ID)], chat.userID)))),
                                (chat, index) => (
                                    <ChatCard
                                        key={nanoid()}
                                        blocked={_.some((currentUser as any).BlackList, (user) => user.id === chat.userID)}
                                        favorite={_.some((currentUser as any).FavoriteList, (user) => user.id === chat.userID)}
                                        chat={chat}
                                    />
                                ))
                        }
                    </div>

                    <div className="fixed-bottom mw-450px mx-auto px-4 py-2 bg-white" style={{ bottom: userDevice === 'IPhone' ? '9.65rem' : '8.2rem', left: '0px', zIndex: 3 }}>
                        <Link to={`/${userType.toLowerCase()}/search?type=profiles`}>
                            <div className="btn btn-bso fs-6 p-1 py-4 w-100">
                                Start a new chat
                            </div >
                        </Link>
                    </div>
                </div>
            }
        </div>
    )
}

export default ChatList
