import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../../hooks/redux'
import { generateBgAndBorderCol } from '../../../../functions/agent/schedule/generateBgAndBorderCol'
import { nanoid } from 'nanoid'
import UseUserType, { type UserType } from '../../../../hooks/UseUserType'
import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../../urls'
import Icon from '../../../../app/icon/Icon'
import { type IOffer } from '../../../../store/slices/offersSlice'
import formatPrice from '../../../../functions/shared/format/formatPrice'
import { getBelongsUnitForOffer } from '../../../../functions/shared/schedule/getBelongsUnit'
import renderOfferStatus from "../../../../functions/agent/offers/renderOfferStatus";

interface IProps {
    currentOffer: IOffer

}
export default function CardOffer({ currentOffer }: IProps): JSX.Element {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const { userType, userTypeLowercase } = UseUserType()
    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    let notifyApp: UserType | 'BSO' | 'Both' | 'Other Agent' = userType
    if (userID === REACT_APP_BSO_USER_ID) {
        notifyApp = 'BSO'
    }

    let task = getBelongsUnitForOffer(currentOffer)

    const createdOfferAgent = useAppSelector(
        (state) => state.user.allUsers
    )?.filter(
        (user) => user.id === Number(currentOffer?.attributes?.User?.data?.id)
    )[0]

    const [statusNotify, setStatusNotify] = useState(
        currentOffer.attributes?.Notify
    )

    let unitNumber, buildName, location

    if (currentOffer) {
        if (currentOffer?.attributes?.Name) {
            [unitNumber, buildName, location] = currentOffer?.attributes?.Name?.split(currentOffer?.attributes?.Name.includes(' <-> ') ? ' <-> ' : '-')
        } else {
            [unitNumber, buildName, location] = [
                'Not number',
                'Not title',
                'Not location'
            ]
        }
    }



    const [showBadge, setShowBadge] = useState(false)

    useEffect(() => {
        if (notifyApp === 'Agent') {
            if (task.type === 'MY UNIT' && currentOffer?.attributes.User?.data?.id !== Number(userID) && statusNotify === 'Other Agent') {
                setShowBadge(true)
            } else if (task.type === 'BSO UNIT' && currentOffer?.attributes.User?.data?.id === Number(userID) && (statusNotify === 'Agent' || statusNotify === "Both")) {
                setShowBadge(true)
            } else if (task.type === 'OTHER AGENT UNIT' && currentOffer?.attributes.User?.data?.id === Number(userID) && statusNotify === 'Agent') {
                setShowBadge(true)
            }
        } else if (notifyApp === 'BSO' && (statusNotify === "BSO" || statusNotify === "Both")) {
            if (task.type === 'BSO UNIT' && currentOffer?.attributes.User?.data?.id !== Number(userID)) {
                setShowBadge(true)
            }
        }
        task = getBelongsUnitForOffer(currentOffer)
        setStatusNotify(currentOffer?.attributes?.Notify)
    }, [currentOffer])

    function renderOfferComment(status: string): string | null {
        if (userType === 'Agent') {
            switch (status) {
                // case 'Completed':
                //     return 'Completed status on offer' // 'Wait updates from your client'
                case 'Approved':
                    return 'Wait for landlord response'
                case 'Slip approved':
                    return 'Please, wait for your response'

                default:
                    return null
            }
        } else {
            return null
        }
    }

    const typeOffer = currentOffer?.attributes?.Type?.[0]?.__component?.split('.')[1]

    return (
        <div
            className={`card shadow-sm my-2 p-1 me-1 position-relative border border-1 border-${generateBgAndBorderCol(currentOffer).borderColor} bg-light-${generateBgAndBorderCol(currentOffer).borderColor}`}
        >
            <div className={'d-flex flex-row row g-0 align-items-start justify-content-between'}>
                {
                    showBadge
                        ? (
                            <span className="position-absolute top-1 start-100 translate-middle badge badge-circle badge-danger">

                                !
                            </span>
                        )
                        : null}

                <div className="d-flex flex-column col-8 p-2 ">
                    {isDev
                        ? notifyApp !== 'BSO' && <span className={`position-absolute badge  badge-light-${generateBgAndBorderCol(currentOffer).textColor} border border-${generateBgAndBorderCol(currentOffer).borderColor} text-${generateBgAndBorderCol(currentOffer).textColor} p-1`} style={{ top: '-7px', left: '-5px' }}>
                            {task.type}
                        </span>
                        : null}

                    <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                        <Icon
                            name={'door_open'}
                            styles={{ minWidth: '12.9px' }}
                        />
                        <span className={'text-dots-app'}>
                            {unitNumber}, {buildName}
                        </span>
                    </span>
                    <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                        <Icon
                            name={'location_on'}
                            styles={{ minWidth: '12.9px' }}
                        />
                        <span className={'text-dots-app'}>
                            {location}{' '}
                        </span>
                    </span>
                    {userType === 'Agent'
                        ? (currentOffer?.attributes?.Type[0]?.__component === 'offer.commercial' || currentOffer?.attributes?.Type[0]?.__component === 'offer.sale-commercial'
                            ? <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                <Icon
                                    name={'person'}
                                    styles={{ minWidth: '12.9px' }}
                                />
                                <div className="">
                                    <span className={'text-dots-app'}>
                                        {currentOffer?.attributes?.Type?.[0]?.BusinessName ?? 'No name'}
                                    </span>
                                    <div className="fs-8 text-gray-700">Client Name</div>
                                </div>
                            </span>

                            : <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                <Icon
                                    name={'person'}
                                    styles={{ minWidth: '12.9px' }}
                                />

                                <div className="">
                                    <span className={'text-dots-app'}>
                                        {currentOffer?.attributes?.Type?.[0]?.FirstName ?? 'No'}{' '}
                                        {currentOffer?.attributes?.Type?.[0]?.LastName ?? 'name'}
                                    </span>
                                    <div className="fs-8 text-gray-700 my-n2">Client Name</div>
                                </div>
                            </span>

                        )
                        : <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                            <Icon
                                name={'person'}
                                styles={{ minWidth: '12.9px' }}
                            />

                            <span className={'text-dots-app'}>
                                {`${String(createdOfferAgent?.ContactInfo?.FirstName ?? 'Account')} ${String(createdOfferAgent?.ContactInfo?.FamilyName ?? 'deleted')}`}
                            </span>
                        </span>
                    }
                    {
                        renderOfferComment(currentOffer?.attributes?.Statuses[0]) != null
                            ? <span className="fs-6 text-gray-800 fw-bold d-flex align-items-center gap-2 lh-1 mt-1">
                                <Icon
                                    name={'info'}
                                />
                                <span className={'text-dots-app1'}>
                                    {renderOfferComment(currentOffer?.attributes?.Statuses[0])}
                                </span>
                            </span>
                            : <></>
                    }
                </div>

                <div className="d-flex flex-column col-4 p-1 text-end">
                    <div className="fs-6 d-flex align-items-center justify-content-end">

                        <div className="d-flex flex-column">
                            {currentOffer?.attributes?.Statuses?.map((el: string) => {
                                return (
                                    <div className="fs-1x text-dark lh-1 fw-bold" key={nanoid()}>
                                        <span
                                            className={`me-1 text-end badge h-10px min-w-10px w-10px badge-circle badge-outline badge-${generateBgAndBorderCol(currentOffer).borderColor} bg-light-${generateBgAndBorderCol(currentOffer).borderColor}`}
                                        >
                                            {' '}
                                        </span>
                                        {
                                            el === 'Waiting for complain'
                                                ? 'Completed'
                                                : el === 'Canceled by other agent'
                                                    ? task.type === 'MY UNIT' ? 'Canceled by yourself' : 'Canceled by agent'
                                                    : el === "Negotiated by other agent"
                                                        ? task.type === 'MY UNIT' ? 'Negotiated by yourself' : 'Negotiated by other agent'
                                                        : el === "Negotiated by agent"
                                                            ? task.type === 'MY UNIT'
                                                                ? 'Negotiated by other agent'
                                                                : Number(userID) === Number(process.env.REACT_APP_BSO_USER_ID)
                                                                    ? el
                                                                    : 'Negotiated by yourself'
                                                            : renderOfferStatus(el, currentOffer?.id ?? -1)
                                        }


                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="text-dark pe-2 pt-1 d-flex align-items-center justify-content-end gap-1">

                        <div>
                            <Icon
                                name={'monetization_on'}
                            />
                            {formatPrice(currentOffer?.attributes?.Type?.[0]?.Offer)}

                            <span className="fs-8 text-gray-600">AED</span>

                        </div>
                    </div>

                    <Link
                        to={
                            notifyApp === 'BSO'
                                ? `/${userTypeLowercase}/modalView/magic_offer_modal/${String(currentOffer?.attributes?.Unit?.data?.id) ?? 'not'}/${String(currentOffer?.id) ?? 'not'}/catalog?type=${String(currentOffer?.attributes?.Type?.[0]?.__component ?? 'not')}`
                                : currentOffer?.attributes?.Unit?.data?.id && currentOffer?.id ? `/${userTypeLowercase}/modalView/offer_modal/${String(currentOffer?.attributes?.Unit?.data?.id)}/${String(currentOffer?.id)}/${task.redirect}?type=${typeOffer ?? 'not'}` : "#"
                        }
                        className="text-decoration-none"
                    >
                        <div
                            className={`fs-7 pt-1 badge border border-${generateBgAndBorderCol(currentOffer).borderColor}`}
                        >
                            GET INFO
                            <Icon
                                name={'expand_more'}
                                className={'text-dark fs-2 ms-1'}
                                useWrapper
                            />
                        </div>
                    </Link>

                </div>
            </div>
        </div >
    )
}
