// import type { IOffers, IViewings } from '../../types'
import { getCurrentDubaiDate } from '../date/getCurrentDateStrFormat'
import { getLastDayOfMonth } from './getLastDayOfMounth'

type returnObj = Record<string, number>
const mounth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const now = new Date(getCurrentDubaiDate())
const currentMounth = now.getMonth()
const currentDate = now.getDate()
const currentYear = now.getFullYear()

// arr: IViewings[] | IOffers[]
export const sortDaysForChart = (arr: any[] | any[], group: string): any[] => {
    const resultObj = sortByCurrentDate(currentDate)
    const resultArr = []

    arr.forEach((el) => {
        const date = new Date(Date.parse(el?.attributes?.createdAt.toString()))
        const createdDate = date.getDate()
        const createdMonth = date.getMonth()

        if (isDifference(now, date)) {
            for (const date in resultObj) {
                const detailedDate = date.split(' ')
                if (
                    detailedDate[0] === String(createdDate) &&
                    detailedDate[1] === mounth[createdMonth]
                ) {
                    resultObj[`${detailedDate[0]} ${detailedDate[1]}`] =
                        resultObj[`${detailedDate[0]} ${detailedDate[1]}`] + 1
                }
            }
        }
    })

    for (const date in resultObj) {
        resultArr.push({
            category: group,
            date,
            value: resultObj[date] || 0,
            subtype: 'me'
        })
    }

    return resultArr
}

export function sortByCurrentDate (date: number): returnObj {
    const segmentOfLastMonth = []
    const segmentOfCurrentMounth = []
    const countPreviousDays = getLastDayOfMonth(currentYear, currentMounth - 1)
    const countCurrentDays = getLastDayOfMonth(currentYear, currentMounth)
    const dateStart = countPreviousDays - (countCurrentDays - currentDate)

    for (let i = dateStart + 1; i <= countPreviousDays; i++) {
        if (currentMounth - 1 !== -1) {
            segmentOfLastMonth.push(`${i} ${mounth[currentMounth - 1]}`)
        } else {
            segmentOfLastMonth.push(`${i} ${mounth[11]}`)
        }
    }
    for (let i = 1; i <= currentDate; i++) {
        segmentOfCurrentMounth.push(`${i} ${mounth[currentMounth]}`)
    }
    const resultArrayDates = segmentOfLastMonth.concat(segmentOfCurrentMounth)
    const returnedObjDates: returnObj = {}
    for (const date of resultArrayDates) {
        returnedObjDates[date] = 0
    }
    // console.log(returnedObjDates, 'returnedObjDates')
    return returnedObjDates
}

function isDifference (currentDate: Date, createdDate: Date): boolean {
    const daysDifference = Math.ceil(Math.abs(createdDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24))
    if (daysDifference <= 31) {
        return true
    }
    return false
}
