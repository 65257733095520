import React from 'react'
import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../urls'

interface IProps {
    onClickCategory: any
    filter: any
}
export default function UnitsNavigateSh({
    onClickCategory,
    filter
}: IProps): JSX.Element {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    return (
        <div className="row w-100 g-0">

            <span
                onClick={() => onClickCategory('rent')}
                className="col d-inline-block position-relative text-center cursor-pointer"
            >
                <div className="d-inline-block link-dark fw-bold my-4">BSO RENTALS</div>
                <span className={`d-inline-block position-absolute h-4px bottom-0 end-0 start-0  translate rounded-top ${filter.unitsCategory === 'rent' ? 'bg-dark' : 'bg-light'
                    }`}
                ></span>
            </span>
            <span
                onClick={() => onClickCategory('sell')}
                className="col d-inline-block position-relative text-center cursor-pointer"
            >
                <div className="d-inline-block link-dark fw-bold my-4">BSO SALES</div>
                <span
                    className={`d-inline-block position-absolute h-4px bottom-0 end-0 start-0  translate rounded-top ${filter.unitsCategory === 'sell' ? 'bg-dark' : 'bg-light'
                        }`}
                ></span>
            </span>
            {
                Number(userID) !== Number(REACT_APP_BSO_USER_ID) && process.env.REACT_APP_IS_DEV === 'true' && <span
                    onClick={() => onClickCategory('agents')}
                    className="col d-inline-block position-relative text-center cursor-pointer"
                >
                    <div className="d-inline-block link-dark fw-bold my-4"> AGENT UNITS</div>
                    <span
                        className={`d-inline-block position-absolute h-4px bottom-0 end-0 start-0  translate rounded-top ${filter.unitsCategory === 'agents' ? 'bg-dark' : 'bg-light'
                            }`}
                    ></span>
                </span>
            }

        </div>
    )
}
