import { Link } from 'react-router-dom'
import React, { type ReactElement } from 'react'

interface MenuItemProps {
    route: string
    label: string
    description: string
    icon: ReactElement
    onClick?: any
    bgColor?: string

}
const MenuItem = ({ route, label, icon, description, bgColor, onClick }: MenuItemProps): JSX.Element => {
    return (

        <Link
            onClick={onClick}
            to={route}
            className="text-decoration-none"
        >
            {/* <div className="card shadow-lg mb-4 h-80px "> */}
            <div className="card-body d-flex justify-content-start align-items-center p-2 position-relative">
                <div className='align-items-center'>
                    <div className={`symbol symbol-50px bg-${bgColor} p-2`}>
                        <span className="p-0">
                            {
                                icon
                            }
                        </span>
                    </div>

                    {/* <span className="bi bi-gift fs-4x text-info pe-2"></span> */}
                </div>

                <div className="ps-2">
                    <span className="fs-3 text-dark fw-semi-bold d-block text-hover-primary">
                        {label}</span>
                    <div className="fs-6 fw-semi-bold text-gray-500 lh-1"> {description}</div>
                </div >

                {/* <span className='position-absolute top-0 start-100 ms-n5'>
                    <i className="ki-duotone ki-information-5 fs-3x text-warning me-5"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
                </span> */}
            </div>
            {/* </div> */}
        </Link>

    )
}

export default MenuItem
