import { type AppDispatch, type RootState } from '../../../../../store'
import { setScannedData } from '../../../../../slices/modalSlice'
import _ from 'lodash'

export type AddOfferIndividualClientInfoData = Record<string, any>

export const addOfferCompanyClientInfo = (unitID: string | undefined, objectID: string | undefined) => (data: AddOfferIndividualClientInfoData) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const savedData = _.cloneDeep(getState().modal.scannedData.formData)
    dispatch(setScannedData({
        ...getState().modal.scannedData,
        formData: {
            ...savedData,
            ...data,
            Type: {
                ...savedData.Type,
                ...data.Type
            }
        }
    }))
    return {
        isSuccessful: true,
        textNavigate: `/agent/modal/agent_set_offer_modal_offer_details_company/${unitID ?? '-'}/${objectID ?? '-'}`,
        textNotification: ''
    }
}
