import React from 'react'
import ChatUtils from '../../../../../functions/chats/ChatUtils'
import Icon from '../../../../../app/icon/Icon'

interface FileMessageProps {
    sentBy: 'me' | 'other'
    time: Date
    fileName: string
    fileSize: number
    fileUrl: string
    senderName?: string
    isPending?: boolean
}
const FileMessage = ({ sentBy, time, fileName, fileSize, fileUrl, senderName, isPending = false }: FileMessageProps): JSX.Element => {
    return (<div className={`w-100 d-flex justify-content-${sentBy === 'me' ? 'end' : 'start'}`}>
        <div className={'card px-2 py-2 shadow-sm'}
            style={sentBy === 'me' ? { width: '70vw', background: '#fff200' } : { width: '70vw' }}
        >
            { sentBy === 'other'
                ? <div className={'text-gray-600 mb-1'}>{senderName}</div>
                : <></>
            }
            <div className={'d-flex flex-row gap-2'}>
                {
                    !isPending
                        ? <div className={'d-flex align-items-center'}>
                            <Icon name={'description'} className={'text-muted fs-5x'} onClick={() => {
                                window.open(fileUrl)
                            }}/>
                            {/* <i className="ki-duotone ki-file-down fs-5x" */}
                            {/*    onClick={() => { */}
                            {/*        window.open(fileUrl) */}
                            {/*    }} */}
                            {/* > */}
                            {/*    <i className="path1"></i> */}
                            {/*    <i className="path2"></i> */}
                            {/* </i> */}
                        </div>
                        : <div className={'d-flex align-items-center'}>
                            <div className="text-gray-500 spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>

                }
                <div className={'d-flex flex-column'}
                    style={{ width: 'calc(100% - 7rem)' }}
                >
                    <div className={'text-truncate'}>{ fileName }</div>
                    <div className={'text-gray-500 fs-8'}>
                        {Math.floor(fileSize / 1024)} KB
                    </div>
                </div>
                <div className={'h-100 d-flex align-items-end text-gray-500 fs-6'}>
                    <div>{ChatUtils.formatTime(time)}</div>
                </div>
            </div>
        </div>
    </div>)
}

export default FileMessage
