
import React, { useState } from 'react'
import { Space, Switch } from 'antd'
import _ from 'lodash'
import GroupInput from '../inputs/GroupInput'
import DefaultInput from '../inputs/DefaultInput'

export default function AdditionalPropertyFields({
    setValue,
    errors,
    register,
    control
}: any): JSX.Element {
    const [switchChecked, setChecked] = useState(false)

    const rowGoogleMapsLink = { id: 'GoogleMapsLink', content: ['Google Maps Link', 'Input goo.gl/maps link'], element: 'input', required: false, maxLength: 700 }
    const rowAreaSqFt = { id: 'AreaSqFt', content: ['Property area', 'Sq.Ft.', 'Input property area'], element: 'input-group' }
    const rowMAKANI = { id: 'MAKANI', content: ['MAKANI', 'Input MAKANI'], element: 'input', required: false }
    const rowDeveloper = { id: 'Developer', content: ['Property Developer', 'Input Property Developer'], element: 'input', required: false }
    const rowPlotNumber = { id: 'PlotNumber', content: ['Plot Number', 'Input Plot Number'], element: 'input', required: false }

    return (
        <>

            <div className="fv-row  mt-8 p-0 w-100">
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase"> Do you want add additional unit's information ?</div>
                    </div>
                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Switch unCheckedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>NO</span>} defaultChecked={switchChecked}
                                checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                onChange={(ch) => { setChecked(ch) }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-4"></div>

                {
                    switchChecked
                        ? <>
                            <div className={'text-start'}>
                                <DefaultInput row={rowGoogleMapsLink} register={register} errors={errors} />
                                <GroupInput row={rowAreaSqFt} register={register} errors={errors} />
                                <DefaultInput row={rowMAKANI} register={register} errors={errors} />
                                <DefaultInput row={rowDeveloper} register={register} errors={errors} />
                                <DefaultInput row={rowPlotNumber} register={register} errors={errors} />

                            </div>   </>
                        : null
                }

            </div >

        </>

    )
}
