import { type Unit } from '../../../store/slices/unitsSlice'
import { removeDataAttributes } from '../../adapters/universal/removeDataAttributes'
import _ from 'lodash'

export const getOfferType = (unit: Unit): 'residential' | 'commercial' | 'sale' => {
    const unitCopy = removeDataAttributes(
        _.cloneDeep(
            unit
        )
    )
    let offerModalType: 'residential' | 'commercial' | 'sale'
    if (unitCopy.Marketing?.Use === 'For rent') {
        offerModalType = unitCopy?.Type === 'Residential' ? 'residential' : 'commercial'
    } else {
        offerModalType = 'sale'
    }
    return offerModalType
}
