import { Space, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import MyDataPicker from '../../MyDataPicker'
import dayjs from 'dayjs'
export default function CustomDynamicStatuses({
    setValue,
    row,
    register,
    errors,
    control,
    clearErrors
}: any): JSX.Element {
    const [switchChecked, setSwitchChecked] = useState(!!(row?.isEdit && row?.defaultValue))

    const handleSwitchChange = (checked: boolean) => {
        setSwitchChecked(checked)
    }

    const rowOccupied = { id: 'occupied_until_edit', content: ['Occupied until', 'Select date'], element: 'input-date', required: true, defaultValue: row.defaultValue }
    const dateFormat = 'YYYY-MM-DD'

    useEffect(() => {
        if (row?.defaultValue && switchChecked) {
            const defaultValueDate = new Date(row?.defaultValue)
            if (!isNaN(defaultValueDate.getTime())) {
                setValue(rowOccupied.id, dayjs(row.defaultValue, dateFormat))
            }
        } else {
            setValue(rowOccupied.id, null)
        }
    }, [row?.defaultValue, switchChecked])

    return (
        <div>
            <div className="d-flex flex-wrap align-items-center">
                <div >
                    <div className="fs-6 fw-bold mb-1 text-uppercase">Unit is occupied ?</div>
                </div>

                <div className="ms-auto">
                    <Space direction="vertical">
                        <Switch unCheckedChildren="NO" defaultChecked={switchChecked}
                            checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                            onChange={handleSwitchChange}
                        />
                    </Space>
                </div>
            </div>
            <div className="separator separator-dashed my-4"></div>
            {
                row?.isEdit

                    ? switchChecked
                        ? <>
                            <div className={'d-flex flex-start'}>
                                <label className={'text-start required form-label  fw-bold text-dark text-uppercase'
                                }>
                                    Occupied until

                                </label></div>

                            <MyDataPicker control={control} register={register} row={rowOccupied} setValue={setValue} isBlockPast={true} />
                            {errors?.[rowOccupied.id] && (
                                <div className="text-danger text-start">{errors?.[rowOccupied.id].message}</div>
                            )}
                        </>
                        : (
                            null
                        )
                    : switchChecked
                        ? <>
                            <div className={'d-flex flex-start'}>
                                <label className={'text-start required form-label  fw-bold text-dark text-uppercase'
                                }>
                                    Occupied until

                                </label></div>

                            <MyDataPicker control={control} register={register} row={rowOccupied} setValue={setValue} isBlockPast={true} />
                            {errors?.[rowOccupied.id] && (
                                <div className="text-danger text-start">{errors?.[rowOccupied.id].message}</div>
                            )}
                        </>
                        : (
                            null
                        )

            }
        </div>
    )
}
