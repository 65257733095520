
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { setMyDraftUnitsOpen } from '../../../store/slices/unitsSlice'

import { useNavigate } from 'react-router-dom'
import UseUserType from '../../../hooks/UseUserType'
import { Upload } from 'antd'
import Icon from '../../../app/icon/Icon'
import { setTemporaryTitleDeed } from '../../../store/slices/documentSlice'
import { scanDocument } from '../../../store/actionsCreators/documentsActions/scaning/documentScan'
import { dataFetchingFinish } from '../../../store/slices/modalSlice'
import { createDraftUnit } from '../../../store/actionsCreators/unitActions/createDraftUnit'
import { swalError, swalSuccess } from '../../../functions/swalOptions'
import { getErrorss, getMessageForError } from '../../../functions/errors/GetErrors'

export default function PathHowCreateUnit(): JSX.Element {

    const temporaryTitleDeed = useAppSelector(state => state.documents.temporaryTitleDeed)
    const [loadingLocal, setLoadingLocal] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { userTypeLowercase } = UseUserType()

    useEffect(() => {
        setIsValid(temporaryTitleDeed?.document?.valid)
    }, [temporaryTitleDeed])


    const scanHandler = async (selectedFile: any): Promise<void> => {
        if (!selectedFile) return;
        setLoadingLocal(true);
        try {
            const scanDoc = await dispatch(scanDocument({ agent_create_unit_title_deed: [selectedFile] } as any));
            if (scanDoc && scanDoc.document) {
                dispatch(setTemporaryTitleDeed(scanDoc));
                const jsonCreatedUnit = await dispatch(createDraftUnit({ agent_create_unit_title_deed: selectedFile }))

                setIsValid(scanDoc.document?.valid)
                if (jsonCreatedUnit.isSuccessful) {
                    await swalSuccess(jsonCreatedUnit.textNotification, () => {
                        dispatch(setMyDraftUnitsOpen(true))
                        navigate(jsonCreatedUnit.textNavigate)
                        dispatch(setTemporaryTitleDeed(null))
                    })
                } else {
                    await swalError(jsonCreatedUnit.textNotification)
                }
            } else {
                await swalError(getErrorss(getMessageForError("The document was not recognized properly" + "<br/><strong>Try again</strong>")))
            }
            setLoadingLocal(false);
        } catch (error) {
            console.error('Error while scanning title deed:', error);
            setLoadingLocal(false);
        } finally {
            dispatch(dataFetchingFinish());
        }
    };


    return (
        <>
            <div className="d-flex justify-content-evenly gap-4 w-100 scrollbar-width overflow-x-auto px-4 mb-4">
                <div className={'col'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <Upload
                        className="customSizedUpload"
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        customRequest={async ({ file, onSuccess, onError }) => {
                            setLoadingLocal(true);
                            try {
                                await scanHandler(file);
                            } catch (error) {
                                console.error('Error while scanning title deed:', error);
                            } finally {
                                setLoadingLocal(false);
                            }
                        }}
                    //    onChange={handleChange}
                    >
                        <button style={{ border: 0, background: 'none' }} type="button">
                            {loadingLocal ? (
                                <i className="fs-5hx text-dark las la-spinner la-spin"></i>
                            ) : (
                                <i className="fs-5hx text-dark las la-plus-circle"></i>
                            )}
                            <div className="d-flex align-items-center justify-content-center w-100 px-auto py-n1 overlay">
                                <span className={`fs-6 text-dark text-uppercase my-2 pb-2 lh-1 text-center`}>
                                    By Scan Title Deed
                                </span>
                            </div>
                        </button>
                    </Upload>
                </div>

                <div className={'col'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "calc(100% - 1px)" }} onClick={() => { navigate(`/${userTypeLowercase}/modal/agent_create_unit_property/-/-`) }}>
                    <div className={`bg-gray-100 border-gray-300 border border-1 d-flex flex-column align-items-center  overflow-hidden rounded-3 py-5`} style={{ width: "100%", }}>
                        <i className="fs-5hx text-dark las la-marker"></i>
                        <div className={'d-flex align-items-center justify-content-center w-100 px-auto py-n1 overlay'}>
                            <span className={`fs-6 text-dark text-uppercase my-2 pb-2 lh-1 text-center`}>
                                By Manually
                            </span>
                        </div>
                    </div>
                </div>
            </div >

            <div className="separator separator-dashed my-4"></div>
            {
                temporaryTitleDeed
                    ?
                    <div className={'w-100 d-flex align-items-center justify-content-between'}>
                        <label className={'form-label fw-bold mb-2 text-dark text-uppercase required'}>Title Deed</label>
                        <div className={'form-label fw-bold text-dark text-uppercase d-flex align-items-center'
                        }>
                            {

                                isValid
                                    ? <>
                                        <Icon
                                            name={'check_circle'}
                                            className={'text-success fs-1'}
                                        />
                                        <span className='pt-1 ms-1'>Verified</span></>
                                    : <>
                                        <Icon
                                            name={'cancel'}
                                            className={'text-danger fs-1'}
                                        />
                                        <span className='pt-1 ms-1'>Not Verified</span></>
                            }
                        </div>
                    </div>
                    : null}
            {
                !temporaryTitleDeed

                    ? <p className={'text-gray-800 fs-7 text-uppercase lh-sm '}>
                        Boost your listing’s exposure and enhance your chances of closing a deal by verifying
                        your property. Simply scan your title deed by taking a picture or uploading a picture of
                        the document.
                    </p> :
                    isValid ?
                        null :
                        <p className={'text-gray-800 fs-7 text-uppercase lh-sm '}>
                            During scanning your "Title Deed"  was not verified successfully, <strong>try again </strong>or <strong>use quick unit creation in manual</strong>
                        </p>
            }
        </>

    )
}
