import {store} from "../../../store/store";

export default function checkIfUnitHasOngoingTransactions (unitID: number): boolean {
    const unit = store.getState().units.units.find(unitItem => unitItem.id === unitID)
    if (unit == null) {
        return false
    }
    const viewings = unit?.attributes?.Viewings?.data ?? []
    const advertises = unit?.attributes?.Advertises?.data ?? []
    const offers = unit?.attributes?.Offers?.data ?? []

    const onGoingViewingsCount = viewings?.filter((viewing: any) => (
        !['Canceled', 'Completed', 'Rejected'].includes(viewing?.attributes?.Statuses?.[0])
    ))?.length
    if (onGoingViewingsCount > 0) {
        return true
    }

    const onGoingAdvertisesCount = advertises?.filter((advertise: any) => (
        !['Canceled', 'Completed'].includes(advertise?.attributes?.Statuses?.[0])
    ))?.length
    if (onGoingAdvertisesCount > 0) {
        return true
    }

    const onGoingOffersCount = offers?.filter((offer: any) => (
        !['Canceled', 'Completed', 'Rejected', 'Resubmit required'].includes(offer?.attributes?.Statuses?.[0])
    ))?.length
    return onGoingOffersCount > 0;
}