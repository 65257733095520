import React, {type ReactElement, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {type RootState} from "../../../../store/store";
import {
    handleSupportTicket
} from "../../../../store/actionsCreators/chatActions";
import Icon from "../../../../app/icon/Icon";

export default function AdminChatActions ({ scrollRef }: { scrollRef: React.RefObject<HTMLDivElement> }): ReactElement {
    const currentUser = useAppSelector((state) => state.user.currentUser)
    const chat = useAppSelector((state: RootState) => state.chat.chat)
    const dispatch = useAppDispatch()
    const scrollToBottom = (): void => {
        if (scrollRef.current != null) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    const supportTickets = chat.SupportTickets
    const issueOpen = supportTickets?.[supportTickets?.length - 1]?.TicketOpen === true
    const [actionsLoading, setActionsLoading] = useState(false)

    return currentUser?.id === Number(process.env.REACT_APP_BSO_USER_ID) && issueOpen
        ? <div className={'p-4 w-100 d-flex justify-content-between align-items-center'}>
            <div className={'d-flex align-items-center gap-2 fw-bold'}>
                <Icon name={'support_agent'} className={'fs-3x'}/>
                <div className={'fs-4'}>
                    Issue type: {
                        supportTickets?.[supportTickets?.length - 1]?.TicketType
                    }
                </div>
            </div>
            <div className={'btn btn-bso btn-sm'}
                onClick={() => {
                    if (!actionsLoading) {
                        setActionsLoading(true)
                        dispatch(handleSupportTicket({
                            isClose: true,
                            chatID: chat.id ?? -1
                        })).finally(() => {
                            setActionsLoading(false)
                            scrollToBottom()
                        })
                    }
                }}
            >
                {
                    actionsLoading
                        ? <div className="spinner-border spinner-border-sm"/>
                        : <>
                            Close issue
                        </>
                }
            </div>
        </div>
        : <></>
}