
import QueryString from 'qs'
import { REACT_APP_BSO_USER_ID, USER_CATEGORY_KEY, USER_ID_KEY } from '../../../../urls'

export const getQueryAllUsers = () => {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const userCATEGORY = window.localStorage.getItem(USER_CATEGORY_KEY)
    //  console.log(userID, 'userID')

    // let usersQueryObject = {
    //     filters: {
    //         id: {
    //             $not: userID
    //         }
    //     },
    //     populate: {}
    // };

    let usersQueryObject = {}

    if (userCATEGORY === 'Agent') {
        usersQueryObject = {
            populate: {
                Type: '*',
                FavoriteList: '*',
                BlackList: '*',
                ContactInfo: '*',
                BankDetails: '*',
                Settings: '*',
                // {
                //     populate: {
                //         Notifies: '*'
                //     }
                // },
                Documents: {
                    populate: {
                        Type: '*'
                    }
                },

                Viewings: // '*',
                {
                    populate: {
                        Unit: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        }
                    }
                },
                Offers: {
                    populate: {
                        Type: '*',
                        Unit: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        }
                    }
                },
                Units: {
                    populate: {
                        Offers: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        },
                        Viewings: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        },
                        Documents: {
                            fields: ['id', 'Type'],
                            populate: {
                                Type: '*'
                            }
                        },
                        Property: '*'
                    }
                },

                // Orders: {
                //     populate: {
                //         Service: '*',
                //         Unit: {
                //             fields: ['id']
                //         }
                //     }
                // },

                Chats: {
                    populate: {
                        Users:
                        {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                            // было в строке
                            // populate: {
                            //     Type: '*',
                            //     ContactInfo: '*',
                            //     Settings: '*'
                            // }
                        },
                        Messages: {
                            populate: {
                                Sender: {
                                    fields: ['id']
                                },
                                Unit:
                                 {
                                     fields: ['id']
                                     // было в строке
                                     // populate: {
                                     //     Marketing: '*',
                                     //     Property: '*'
                                     // }
                                 }
                            }
                        }
                    }
                }
            }
        }
    } else if (userCATEGORY === 'Landlord' && Number(userID) !== Number(REACT_APP_BSO_USER_ID)) {
        usersQueryObject = {
            populate: {
                Type: '*',
                BlackList: '*',
                ContactInfo: '*',
                Documents: {
                    populate: {
                        Type: '*'
                    }
                },
                Units: {
                    populate: {
                        Offers: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        },
                        Viewings: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        },
                        Documents: {
                            fields: ['id', 'Type'],
                            populate: {
                                Type: '*'
                            }
                        },
                        Property: '*'
                    }
                },
                Chats: {
                    populate: {
                        Users:
                            {
                                fields: ['id'],
                                populate: {
                                    User: {
                                        fields: ['id']
                                    }
                                }
                            },
                        Messages: {
                            populate: {
                                Sender: {
                                    fields: ['id']
                                },
                                Unit:
                                    {
                                        fields: ['id']
                                    }
                            }
                        }
                    }
                }
            }
        }
    } else if (Number(userID) === Number(REACT_APP_BSO_USER_ID)) {
        usersQueryObject = {
            populate: {
                Type: '*',
                FavoriteList: '*',
                BlackList: '*',
                ContactInfo: '*',
                BankDetails: '*',
                Settings: '*',
                // {
                //     populate: {
                //         Notifies: '*'
                //     }
                // },
                Documents: {
                    populate: {
                        Type: '*'
                    }
                },

                Viewings: // '*',
                {
                    populate: {
                        Unit: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        }
                    }
                },
                Offers: {
                    populate: {
                        Type: '*',
                        Unit: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        }
                    }
                },
                Units: {
                    populate: {
                        Offers: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        },
                        Viewings: {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        },
                        Documents: {
                            fields: ['id', 'Type'],
                            populate: {
                                Type: '*'
                            }
                        },
                        Property: '*'
                    }
                },

                Orders: {
                    populate: {
                        Service: '*',
                        Unit: {
                            fields: ['id']
                        }
                    }
                },

                Chats: {
                    populate: {
                        Users:
                        {
                            fields: ['id'],
                            populate: {
                                User: {
                                    fields: ['id']
                                }
                            }
                        },
                        Messages: {
                            populate: {
                                Sender: {
                                    fields: ['id']
                                },
                                Unit:
                                 {
                                     fields: ['id']
                                 }
                            }
                        }
                    }
                }
            }
        }
    }

    const usersQuery = QueryString.stringify(usersQueryObject, { encode: false })
    //  console.log(usersQuery, 'usersQuery')
    return usersQuery
}
