import React from 'react'
import ServiceTabs from './tabs'
import _ from 'lodash'
import SubscriptionCard from './subscription'
import ServiceCard from './service'
import { useSearchParams } from 'react-router-dom'

const MyServicesSubscriptions = (): JSX.Element => {
    const [searchParams] = useSearchParams()
    const page = searchParams.get('page') ?? 'services'
    return (
        <>
            <div className="header fixed-top bg-white h-auto fw-bold d-flex shadow-sm mw-450px mx-auto">
                <ServiceTabs/>
            </div>
            <div style={{ height: '4rem' }}/>
            <div className={'d-flex flex-column gap-0'}>
                {
                    page === 'services'
                        ? _.map([1, 2, 3], () => (
                            <ServiceCard/>
                        ))
                        : _.map([1, 2, 3], () => (
                            <SubscriptionCard/>
                        ))
                }
            </div>
        </>
    )
}

export default MyServicesSubscriptions
