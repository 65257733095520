
import { getErrorss } from '../../functions/errors/GetErrors'
import { ACCESS_KEY, urlAPI } from '../../urls'
import { viewingsSlice } from '../slices/viewingsSlice'
import { type AppDispatch } from '../store'

import QueryString from 'qs'

const viewingsQueryObject = {
    populate: {
        User: {
            fields: ['id', 'DeviceToken']
        },
        Unit: {
            fields: ['id'],
            populate: {
                User: {
                    fields: ['id']
                }
            }
        },
        KeyBooking: {
            populate: {
                Holder: {
                    fields: ['id']
                }
            }
        }
    }
}
export const viewingsQuery = QueryString.stringify(viewingsQueryObject, { encode: false })
export const fetchViewings = (noLading?: boolean) => async (dispatch: AppDispatch) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)

    if (noLading !== true) {
        dispatch(viewingsSlice.actions.viewingsFetching())
    }
    try {
        const bodyObject = {
            method: 'GET',
            collection: 'viewings',
            query: viewingsQuery
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })
        const dataJSON = await response.json()
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(viewingsSlice.actions.viewingsFetchingSuccess(dataJSON.response.data))
                return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(viewingsSlice.actions.viewingsFetchingError(JSON.stringify(error)))
        return { textNavigate: '', isSuccessful: false, textNotification: '' }
    }
}
