import _ from 'lodash'

const nanToNull = (number: any): number | null => {
    let formattedNumber = number
    if (_.isString(number)) {
        formattedNumber = (formattedNumber as string).replaceAll(',', '').trim()
    }
    return (!_.isNaN(Number(formattedNumber)) && formattedNumber !== '')
        ? Number(formattedNumber)
        : null
}
export default nanToNull
