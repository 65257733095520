import _ from 'lodash'
import { type IViewing } from '../../../store/slices/viewingsSlice'

export const reduceViewing = (entry: Record<any, any>, isMy: IViewing) => {
    const actualKeyBooking = _.isEmpty(entry?.KeyBooking) || entry?.KeyBooking === null
        ? { data: null }
        : {
            data: {
                id: entry?.KeyBooking?.id,
                attributes: {
                    DropOutDate: entry?.KeyBooking?.DropOutDate,
                    PickUpDate: entry?.KeyBooking?.PickUpDate,
                    Status: entry?.KeyBooking?.Status,
                    createdAt: entry?.KeyBooking?.createdAt,
                    updatedAt: entry?.KeyBooking?.updatedAt
                }
            }
        }

    const reducedViewing = {
        id: entry.id,
        attributes: {
            createdAt: entry.createdAt,
            updatedAt: entry.updatedAt,
            Complaint: entry.Complaint,
            Comments: entry.Comments,
            Notify: entry.Notify,
            Feedback: entry.Feedback,
            RejectReason: entry.RejectReason,
            // order: entry.order,
            Tasks: entry.Tasks,
            Name: entry.Name,
            Datetime: entry.Datetime,
            Statuses: entry.Statuses,
            DigitalLockPass: entry.DigitalLockPass,
            NotifyViewedDate: entry.NotifyViewedDate,
            User: {
                data: {
                    id: isMy?.attributes?.User?.data?.id ?? entry?.User?.id,
                    attributes: {}
                }
            },
            Unit: {
                data: {
                    id: isMy?.attributes?.Unit?.data?.id ?? entry?.Unit?.id,
                    attributes: {}
                }
            },
            KeyBooking: actualKeyBooking
        }
    }
    // console.log(reducedViewing, 'reducedViewing')
    return reducedViewing
}

export const reduceViewingAfterCreate = (entry: Record<any, any>) => {
    const actualKeyBooking = _.isEmpty(entry?.KeyBooking) || entry?.KeyBooking === null
        ? { data: null }
        : {
            data: {
                id: entry?.KeyBooking?.id,
                attributes: {
                    DropOutDate: entry?.KeyBooking?.DropOutDate,
                    PickUpDate: entry?.KeyBooking?.PickUpDate,
                    Status: entry?.KeyBooking?.Status,
                    createdAt: entry?.KeyBooking?.createdAt,
                    updatedAt: entry?.KeyBooking?.updatedAt
                }
            }
        }
    const reducedViewing = {
        id: entry.id,
        attributes: {
            createdAt: entry.createdAt,
            updatedAt: entry.updatedAt,
            Complaint: entry.Complaint,
            Comments: entry.Comments,
            Notify: entry.Notify,
            Feedback: entry.Feedback,
            Tasks: entry.Tasks,
            Name: entry.Name,
            Datetime: entry.Datetime,
            Statuses: entry.Statuses,
            DigitalLockPass: entry.DigitalLockPass,
            NotifyViewedDate: entry.NotifyViewedDate,

            User: entry?.User?.id
                ? {
                    data: {
                        id: entry?.User?.id,
                        attributes: {}
                    }
                }
                : null,
            Unit: entry?.Unit?.id
                ? {
                    data: {
                        id: entry?.Unit?.id,
                        attributes: {}
                    }
                }
                : null,
            KeyBooking: actualKeyBooking
        }
    }

    // console.log(reducedViewing, 'reducedViewing')
    return reducedViewing
}
