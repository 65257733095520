import React from 'react'
import { scanningInstructions } from '../../../../urls'

export default function DescriptionScanQR(): JSX.Element {
    return (
        <div className={'px-2 mt-2   mb-8'}>
            <h1 className={'text-center my-2'}>QR-code scanning instruction</h1>
            {/* <p className={'text-gray-400 fs-3'}>
                                    Market and explore listings strategically in these key areas.
    </p> */}

            <img
                className="w-100 h-275px object-fit-contain mb-2 rounded-0 rounded-bottom "
                src={scanningInstructions.QR_Code}
                alt="cover_scan_qr"
            />

            <div className={'text-gray-400 fs-3 ps-4 mt-2'} >
                {/* • Select    <span className="fs-3 fw-bold text-dark">{unitArea}</span> and hit the "Save Changes" button below */}

                1. Choose "Use camera" option in file input.

            </div>
            <div className={'text-gray-400 fs-3 ps-4 '} >

                2. Take photo of QR-code.         </div>
            <div className={'text-gray-400 fs-3 ps-4 mb-8'} >
                3. Make sure that QR-code at photo centered, has visible angles, not rotated, clearly visible without light reflections.   </div>

        </div>
    )
}
