import React, { useRef } from 'react'
import AgentHeader from '../AgentHeader'
import HistoryList from '../settings/ordersSubscriptions/HistoryList'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { type IOrder, type User } from '../../../store/slices/userSlice'

import { setOrderListScrollPosition } from '../../../store/slices/modalSlice'
import UseSaveScroll from '../../../hooks/useSaveScroll'
import HistoryCardsList from '../settings/ordersSubscriptions/HistoryCardsList'

export default function OrdersSection () {
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector(
        (state: RootState) => state.user.currentUser as User
    )
    const orders = currentUser.Orders as IOrder[]
    const ServiceAgentSubscription = orders
        ? orders.filter((order: IOrder) =>
            order.Service?.some(
                (service) => service.__component === 'services.broker-subscription'
            )
        )
        : []
    ServiceAgentSubscription?.sort((a, b) => {
        const aCreatedAt =
      a.createdAt != null ? new Date(a.createdAt) : new Date(0)
        const bCreatedAt =
      b.createdAt != null ? new Date(b.createdAt) : new Date(0)
        return bCreatedAt.getTime() - aCreatedAt.getTime()
    })

    const scrollRef = useRef<HTMLDivElement>(null)
    const orderListScrollPosition = useAppSelector((state: RootState) => state.modal.orderListScrollPosition)

    UseSaveScroll({
        scrollRef,
        position: orderListScrollPosition,
        setPosition: (position: number) => dispatch(setOrderListScrollPosition(position))
    })

    return (
        <>
            <div className="page d-flex flex-column flex-row-fluid fs-5">
                <AgentHeader active="Purchases" />

                <div className="content d-flex flex-column flex-fill p-0 m-4">
                    <div className="w-100 mw-450px mx-auto">
                        {/* <div className="card shadow-sm mb-4"> */}
                        {/*    <div className="card-body p-2"> */}
                        {/*        <HistoryList orders={orders}/> */}
                        {/*    </div> */}
                        {/* </div> */}
                        <HistoryCardsList orders={orders}/>
                    </div>
                    <div className="m-0" style={{ height: '160px' }} />
                </div>
            </div>
        </>
    )
}
