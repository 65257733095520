import React from 'react'
import { type IChatMessage } from '../../../../../types'
import VoiceMessage from './VoiceMessage'
import ImageMessage from './ImageMessage'
import FileMessage from './FileMessage'
import UnitMessage from './UnitMessage'
import TextMessage from './TextMessage'

import { USER_ID_KEY } from '../../../../../urls'
import VideoMessage from './VideoMessage'
import ClientMessage from './ClientMessage'
import IssueClosedMark from "./IssueClosedMark";
import IssueOpenMark from "./IssueOpenMark";

interface MessagesSwitchProps {
    message: IChatMessage
    sentBy?: 'me' | 'other' | undefined
    isPending?: boolean
}

const MessagesSwitch = ({ message, sentBy, isPending = false }: MessagesSwitchProps): JSX.Element => {
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    const sender = sentBy ?? (message.Sender === +userID ? 'me' : 'other')
    // const { allUsers } = useAppSelector((state: RootState) => state.user)
    // const user = _.find(allUsers, (userItem) => userItem.id === message.Sender)
    //  console.log(message, 'message <<<+++++++++++++++ +++++++++++++++++++++++++++ ===============')
    const senderName = (message.User?.FirstName ?? '') + ' ' + (message.User?.FamilyName ?? '')
    return (
        message.Text === 'type:voice' || message.Text === 'type:audio'
            ? <VoiceMessage
                id={`${message.id}`}
                sentBy={sender}
                time={new Date(message.updatedAt)}
                name={message.Attachments?.name}
                audio={message.Attachments?.data ?? ''}
                duration={message.Attachments?.duration ?? 0}
                senderName={senderName}
                type={message.Text}
                isPending={isPending}
            />
            /* : message.Text === 'type:video'
                ? <VideoMessage
                    video={message.Attachments?.data ?? ''}
                    sentBy={sender}
                    time={new Date(message.updatedAt)}
                    name={message.Attachments?.name}
                    senderName={senderName}
                /> */
            : message.Text === 'type:file' || message.Text === 'type:video'
                ? /image\/*/.test(message?.Attachments?.type ?? '')
                    ? <ImageMessage
                        sentBy={sender}
                        time={new Date(message.updatedAt)}
                        image={message.Attachments?.data ?? ''}
                        senderName={senderName}
                    />
                    : <FileMessage
                        sentBy={sender}
                        time={new Date(message.updatedAt)}
                        fileUrl={message.Attachments?.data ?? ''}
                        fileName={message.Attachments?.name ?? ''}
                        fileSize={message.Attachments?.size ?? 0}
                        senderName={senderName}
                        isPending={isPending}
                    />
                : message.Text === 'type:unit' || message.Text === 'type:client'
                    ? message.Text === 'type:client'
                        ? <ClientMessage
                            unit={message.Unit}
                            sentBy={sender}
                            time={new Date(message.updatedAt)}
                            senderName={(message.User?.FirstName ?? '') + ' ' + (message.User?.FamilyName ?? '')}
                            user={message.User}
                            message={message}
                        />
                        : <UnitMessage
                            unit={message.Unit}
                            sentBy={sender}
                            time={new Date(message.updatedAt)}
                            senderName={(message.User?.FirstName ?? '') + ' ' + (message.User?.FamilyName ?? '')}
                            user={message.User}
                        />
                    : message.Text === 'type:close-issue'
                        ? <IssueClosedMark/>
                        : message.Text?.includes('type:open-issue')
                            ? <IssueOpenMark text={message.Text }/>
                            : <TextMessage
                                senderName={senderName}
                                sentBy={sender}
                                time={new Date(message.updatedAt)}
                                text={message.Text}
                                user={message.User}
                                chatWith={message.ChatWith ?? ''}
                            />
    )
}

export default MessagesSwitch
