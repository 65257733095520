import { type NavigateFunction } from 'react-router-dom'
import { type Unit } from '../../slices/unitsSlice'
import { type AppDispatch, store } from '../../store'
import { type IViewing } from '../../../types'
import dayjs from 'dayjs'
import Swal, { type SweetAlertResult } from 'sweetalert2'
import { swalOptions } from '../../../functions/swalOptions'
import { USER_ID_KEY } from '../../../urls'
import { userUpdateClickCount } from '../userActions/userUpdateClickCount'

export default function openAddViewingModal (unit: Unit, navigate: NavigateFunction , dispatch : AppDispatch ): void {
    const lsUserId = window.localStorage.getItem(USER_ID_KEY)
    const currentUser = store.getState().user.currentUser
    const userID = currentUser.id ?? lsUserId

    function filterViewings (viewing: IViewing): boolean {
        return (
            viewing.attributes.Unit?.data?.id === unit.id &&
            viewing.attributes.User?.data?.id === currentUser.id &&
            dayjs(viewing.attributes.Datetime).isAfter(dayjs()) &&
            viewing.attributes.Notify === 'Agent'
        )
    }

    function sortViewings (viewingA: IViewing, viewingB: IViewing): number {
        return dayjs(viewingA.attributes.Datetime).isAfter(viewingB.attributes.Datetime, 'minutes') ? 1 : -1
    }

    const viewings = store.getState().viewings.viewings
        .filter(filterViewings)
        .sort(sortViewings)

    if (viewings?.length > 0) {
        void Swal.fire({
            ...swalOptions('info'),
            title: 'New viewing',
            html: 'Dou you want to create a new viewing for this unit or to react on a previously created?',
            confirmButtonText: 'React on existing',
            denyButtonText: 'Create new',
            showDenyButton: true,
            showConfirmButton: true
        }).then(({ isConfirmed, isDenied }: SweetAlertResult<any>) => {
            if (isConfirmed) {
                navigate(`/agent/modalView/viewing_modal/${unit.id}/${viewings[0].id}/calendar`)
            } else if (isDenied) {
                dispatch(userUpdateClickCount('viewing'));
                navigate(`/agent/modal/agent_set_viewing_modal/${unit.id}/${userID}`)
            }
        })
    } else {
        dispatch(userUpdateClickCount('viewing'));
        navigate(`/agent/modal/agent_set_viewing_modal/${unit.id}/${userID}`)
    }
}
