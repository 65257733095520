import { getErrorss } from "../../../functions/errors/GetErrors"
import { getQueryUnits } from "../../../functions/shared/api/queries/getQueryUnits"
import { swalError, swalSuccess } from "../../../functions/swalOptions"
import { ACCESS_KEY, urlAPI } from "../../../urls"
import { dataFetchingFinish, dataFetchingStart } from "../../slices/modalSlice"
import { type AppDispatch, type RootState } from "../../store"
import { getChatList } from "../chatListActions"
import { fetchUnits, singleUnitFetchingSuccess } from "../unitsActions"
import { fetchUser } from "../userActions"

export const archiveAgentUnit = (unitID: number ) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    try {
        dispatch(dataFetchingStart())
        const unit = getState().units.units.find(unitItem => unitItem.id === unitID)
        const Statuses = (unit?.attributes?.Statuses ?? []).filter(status => status !== 'Archived')
        const unitIsAvailable = unit?.attributes?.Statuses?.includes('Archived') !== true
        if (unitIsAvailable) {
            Statuses.push('Archived')
        }

        const bodyObject = {
            method: 'PUT',
            collection: 'units',
            id: unitID,
            body: {
                Statuses
            },
            query: getQueryUnits()
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json() as unknown as any


        if (responseJSON.success) {
            if (responseJSON.response.error != null) {
                dispatch(dataFetchingFinish())
                return await swalError(getErrorss(responseJSON)) 
            } else {
                // if (!unitIsAvailable && !currentUnit[0].attributes.Statuses.includes('Drafted') && !currentUnit[0].attributes.Statuses.includes('Sold')) {
                //     void sendUnitNotification(responseJSON.response.data as Unit, 'user-unit-new')
                // } 
                const userBroadcastMessages = getState().chat.userBroadcastMessages
                if(userBroadcastMessages.length > 0){
                    const currentBroadcast = userBroadcastMessages.find((_broadcast) => _broadcast?.Unit?.attributes?.id === Number(unitID) )
                    if(currentBroadcast != null){
                        const responseDeleteMessage = await fetch(`${urlAPI}`, {
                            method: 'POST',
                            body: JSON.stringify({
                                method: 'DELETE',
                                collection: 'messages',
                                id : currentBroadcast.id
                            }),
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${jwt}`
                            }
                        })
                        await responseDeleteMessage.json()
                    }
                    await dispatch(getChatList())
                }




                const { isSuccessful } = dispatch(singleUnitFetchingSuccess(responseJSON))
                if (!isSuccessful) {
                    await dispatch(fetchUnits(true))
                    await dispatch(fetchUser(true))
                }
                dispatch(dataFetchingFinish())
                return await swalSuccess(unitIsAvailable ? 'Unit has archived successfully' : "Unit has revised successfully")
            }
        } else {
            dispatch(dataFetchingFinish())
            await swalError(getErrorss(responseJSON))
           
        }
    } catch (error) {
        dispatch(dataFetchingFinish())
        await swalError(getErrorss("error"))
    }

}