import useSearchProvider from '../hooks/useSearchProvider'

interface SearchProviderProps {
    children: JSX.Element
}
const SearchProvider = ({ children }: SearchProviderProps): JSX.Element => {
    useSearchProvider()
    return (
        children
    )
}

export default SearchProvider
