import QueryString from 'qs'
import { REACT_APP_BSO_USER_ID, USER_CATEGORY_KEY, USER_ID_KEY } from '../../../../urls'

export const getQueryDocuments = (): string => {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const userCATEGORY = window.localStorage.getItem(USER_CATEGORY_KEY)

    let documentsQueryObject = {}

    if (userCATEGORY === 'Agent') {
        documentsQueryObject = {
            filters: {
                $or: [
                    {
                        User: {
                            id: {
                                $eq: userID
                            }
                        }
                    },
                    {
                        User: {
                            id: {
                                $notNull: true
                            }
                        }
                    }
                ],
                RefID: {
                    $contains: 'REF_DOC'
                }
            },
            populate: {
                Type: "*",
                User: {
                    fields: ['id']
                },
                Unit: {
                    fields: ['id']
                },
                Offer: {
                    fields: ['id']
                },
                Advertise: {
                    fields: ['id']
                }
            }
        }
    } else if (userCATEGORY === 'Landlord' && Number(userID) !== Number(REACT_APP_BSO_USER_ID)) {
        documentsQueryObject = {
            filters: {
                $or: [
                    {
                        User: {
                            id: {
                                $eq: userID
                            }
                        }
                    },
                    {
                        User: {
                            id: {
                                $notNull: false
                            }
                        }
                    }
                ]

            },
            populate: {
                Type: {
                    fields: ['id', '__component', 'Number', 'Activity', 'Roles', 'RegistryDate']
                },
                User: {
                    fields: ['id']
                },
                Unit: {
                    fields: ['id']
                },
                Offer: {
                    fields: ['id']
                },
                Advertise: {
                    fields: ['id']
                }
            }
        }
    } else if (Number(userID) === Number(REACT_APP_BSO_USER_ID)) {
        documentsQueryObject = {
            // filters: {
            //     $or: [
            //         {
            //             User: {
            //                 id: {
            //                     $eq: userID
            //                 }
            //             }
            //         },
            //         {
            //             User: {
            //                 id: {
            //                     $notNull: false
            //                 }
            //             }
            //         }
            //     ]
            //
            // },
            populate: {
                Type: "*",
                // Type: {
                //     fields: ['id', '__component', 'Number', 'Activity', 'Roles', 'RegistryDate', 'Type']
                // },
                User: {
                    fields: ['id']
                },
                Unit: {
                    fields: ['id']
                },
                Offer: {
                    fields: ['id']
                },
                Advertise: {
                    fields: ['id']
                }
            }
        }
    }

    const docsQuery = QueryString.stringify(documentsQueryObject, { encodeValuesOnly: true })
    return docsQuery
}
