import React, { type ChangeEvent, useState } from 'react'
import { Drawer } from 'antd'
import UnitPreview from '../../units/preview/UnitPreview'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { setCurrentFile, setCurrentMessage, setCurrentUnit } from '../../../../store/slices/chatSlice'
import { removePendingMessage, sendMessage, setPendingMessage } from '../../../../store/actionsCreators/chatActions'
import { useParams } from 'react-router'
import { transcodeImage, uploadStrapiFile } from '../../../../store/actionsCreators/fileActions'
import { type RootState } from '../../../../store/store'
import _ from 'lodash'
import { MESSAGE_UNPUBLISH_TIME } from '../../../../constants'
import getBlobDuration from 'get-blob-duration'
import Icon from '../../../../app/icon/Icon'

const FileAttacher = (): JSX.Element => {
    const [open, setOpen] = useState(false)
    const [tab, setTab] = useState<'file' | 'unit'>('file')
    const dispatch = useAppDispatch()
    const { id: chatID = '0' } = useParams()

    const { currentUser } = useAppSelector((state: RootState) => state.user)
    const { pendingMessages } = useAppSelector((state: RootState) => state.chat)
    let units: any[] = []
    if (currentUser instanceof Object && 'Units' in (currentUser as any)) {
        units = (currentUser as any).Units
    }

    const sendFile = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
        const id = pendingMessages.length
        let files: FileList | File[] = e.target?.files ?? new FileList()
        if (files[0] == null) {
            return
        }
        dispatch(setPendingMessage('type:file', id))
        const file = await uploadStrapiFile(files[0], 'chat-rooms', chatID)
        let duration: number | undefined
        switch (files?.[0]?.type) {
            case 'audio/mpeg':
                duration = await getBlobDuration(files[0])
                dispatch(setCurrentMessage('type:audio'))
                break
            case 'video/mp4':
            case 'video/mpeg':
                dispatch(setCurrentMessage('type:video'))
                break
            case 'image/heic':
            case 'image/heif':
                files = [await transcodeImage(files[0]) as File]
                dispatch(setCurrentMessage('type:file'))
                break
            default:
                dispatch(setCurrentMessage('type:file'))
                break
        }
        if (file != null) {
            file.name = files?.[0]?.name
            dispatch(setCurrentFile({ ...file, duration }))
            dispatch(sendMessage(+chatID)).finally(() => {
                dispatch(removePendingMessage(id))
            })
            dispatch(setCurrentMessage(''))
        } else {
            dispatch(setCurrentMessage(''))
            dispatch(removePendingMessage(id))
        }
    }

    const isDev = process.env.REACT_APP_IS_DEV === 'true'
    return (
        <>
            <Icon
                name={'attach_file'}
                className={'text-muted fs-3x overflow-y-auto'}
                onClick={() => {
                    setOpen(true)
                }}
            />
            <Drawer
                placement={'bottom'}
                open={open}
                zIndex={1500}
                width={'100vw'}
                height={'50vh'}
                className={'bg-gray-200'}
                onClose={() => {
                    setOpen(false)
                }}
                styles={{
                    body: {
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }}
                closable={false}
            >
                {/* unit send */}
                {isDev
                    ? <div className={'d-flex'}>
                        <span
                            onClick={() => {
                                setTab('file')
                            }}
                            className="col d-inline-block position-relative text-center cursor-pointer"
                        >
                            <div className="d-inline-block link-dark fw-bold my-4">
                                FILE
                            </div>
                            <span
                                className={`d-inline-block position-absolute h-4px bottom-0 end-0 start-0 translate rounded-top ${tab === 'file' ? 'bg-bso' : 'bg-light'}`}
                            />
                        </span>
                        <span
                            onClick={() => {
                                setTab('unit')
                            }}
                            className="col d-inline-block position-relative text-center cursor-pointer"
                        >
                            <div className="d-inline-block link-dark fw-bold my-4">
                                UNIT
                            </div>
                            <span
                                className={`d-inline-block position-absolute h-4px bottom-0 end-0 start-0 translate rounded-top ${tab === 'unit' ? 'bg-bso' : 'bg-light'}`}
                            />
                        </span>
                    </div>
                    : null}
                {
                    tab === 'file'
                        ? <div className={'d-flex flex-column pt-4'}>
                            <div className={'position-relative'}>
                                <input type="file"
                                    onChange={(e) => {
                                        setOpen(false)
                                        void sendFile(e)
                                    }}
                                    className={'opacity-0 position-absolute w-100 h-100 z-index-1501'}
                                />
                                <div className={'d-flex gap-4 align-items-center'}>
                                    <Icon
                                        name={'upload_file'}
                                        className={'text-muted fs-3x'}
                                    />
                                    <div className={'fw-bold text-gray-600'}>Upload local file</div>
                                </div>
                            </div>
                        </div>
                        : <div className={'d-flex flex-wrap gap-4 overflow-auto pt-4'}
                            style={{ maxHeight: 'calc(100% - 4rem)' }}
                        >
                            {
                                _.map(units, (unit) => (
                                    <div style={{ width: 'calc(50% - 0.5rem)' }}
                                        onClick={() => {
                                            const id = pendingMessages.length
                                            dispatch(setPendingMessage('type:unit', id))
                                            setOpen(false)
                                            dispatch(setCurrentMessage('type:unit'))
                                            dispatch(setCurrentUnit(unit?.id))
                                            dispatch(sendMessage(+chatID)).finally(() => {
                                                dispatch(removePendingMessage(id))
                                            })
                                            // for what do we create draft unit in mesages history?
                                            // dispatch(sendMessage(+chatID, MESSAGE_UNPUBLISH_TIME)).finally(() => {
                                            //     dispatch(removePendingMessage(id))
                                            // })
                                        }}
                                    >
                                        <UnitPreview unit={unit} />
                                    </div>
                                ))
                            }
                        </div>
                }
            </Drawer>
        </>
    )
}

export default FileAttacher
