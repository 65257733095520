import { type IFile } from '../../types'
import { ACCESS_KEY, USER_ID_KEY } from '../../urls'
import { nanoid } from 'nanoid'
import _ from 'lodash'

export const uploadStrapiFile = async (file: File, collection = 'users', entityID: string | null = null, refID: string | null = null): Promise<IFile | null> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    const formData = new FormData()
    formData.append('file', file)
    formData.append('collection', collection)
    if (refID != null) {
        formData.append('refID', refID)
    } else {
        formData.append('id', `${entityID ?? userID}`)
    }

    try {
        const response = await fetch('https://bso.ae/api/app/uploadStrapiFile', {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()
        console.log(dataJSON, 'dataJSON uploadStrapiFile')
        return {
            data: dataJSON?.response?.link,
            name: nanoid() + file.name,
            type: file.type,
            size: file.size
        }
    } catch (error) {
        console.log(error, 'error')
        return null
    }
}

export const uploadMultipleFiles = async (files: FileList | null, collection: string, entityID: string | null = null, refID: string | null = null): Promise<Array<IFile | null>> => {
    const promises = _.map(files, async (file) => await uploadStrapiFile(file, collection, entityID, refID))
    return await Promise.all(promises)
}

const downloadBlobAsFile = (blob: Blob, type: any): void => {
    // Create a link element
    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(blob)
    downloadLink.download = 'test-file.mp3' // Replace with the desired file name
    downloadLink.style.display = 'none' // Hide the link

    // Append the link to the document body
    document.body.appendChild(downloadLink)

    // Trigger the download by simulating a click event on the link
    downloadLink.click()

    // Clean up by removing the link from the document body
    document.body.removeChild(downloadLink)
}

export const transcodeVoiceMessage = async (recordingBlob: Blob): Promise<Blob | null> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const file = new File([recordingBlob], `voice-${nanoid(10)}.webm`, {
        type: 'audio/webm'
    })

    const formData = new FormData()
    formData.append('file', file)
    try {
        const response = await fetch('https://bso.ae/api/app/convertVoiceMessage', {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        })
        return await response.blob()
    } catch (error) {
        console.error(error, 'error')
        return null
    }
    // const uploadedFile = await uploadStrapiFile(file, 'chat-rooms')
}

export const transcodeImage = async (file: File): Promise<File | null> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const formData = new FormData()
    formData.append('file', file)
    try {
        const response = await fetch('https://bso.ae/api/app/convertImage', {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        })
        const blob = await response.blob()
        return new File([blob], String(_.slice(file.name, 0, _.lastIndexOf(file.name, '.'))) + '.jpeg', {
            type: 'image/jpeg'
        })
    } catch (error) {
        console.error(error, 'error')
        return null
    }
}
