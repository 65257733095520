import React, { useState } from 'react'
import { type Unit } from '../../../store/slices/unitsSlice'
import CustomModalForViewRenderDescription from './CustomModalForViewRenderDescription'
import Icon from '../../icon/Icon'
import Markdown from 'react-markdown'

interface IProps {
    currentUnit: Unit
}

export default function ChildUnit({ currentUnit }: IProps) {
    const [expanded, setExpanded] = useState(false)

    const marketing = currentUnit?.attributes?.Marketing ?? null

    const handleClick = () => {
        setExpanded(!expanded)
    }

    return (
        <>
            <div className="d-flex align-items-start mb-0 px-3">
                <span className="text-gray-900 fs-3x me-0 mt-1 lh-sm">
                    {marketing?.Headline}
                </span>
            </div>
            <div className="d-flex align-items-end justify-content-end mb-2 px-3">
                <span className="text-gray-900 fs-2 me-2 mb-1">AED</span>
                <span className="text-gray-900 fs-3x me-0">{marketing?.Price?.toLocaleString('en-US')}</span>
            </div>
            {currentUnit?.attributes?.ImportantNotice != null && currentUnit?.attributes?.ImportantNotice?.length > 0
                ?
                // <div className="card shadow-sm d-flex flex-row align-items-center gap-2 mt-2 mb-4 g-0 p-2 position-relative border border-1 border-info bg-light-info fs-4">
                //     <Icon name={'info'} className={'fs-2 text-info'}/>
                //     {
                //         currentUnit?.attributes?.ImportantNotice
                //     }
                // </div>
                <div className={'rounded rounded-sm bg-gray-300 p-4 mt-4 '}>
                    <label className={'form-label fw-bold fs-5 text-dark m-0 d-flex align-items-center text-uppercase'}>
                        <Icon name={'info'} className={'fs-2x text-info me-1'} />
                        Important Notice
                    </label>
                    <div className='fw-semi fs-5  lh-1 mt-2'> <Markdown>
                        {currentUnit?.attributes?.ImportantNotice}
                    </Markdown></div>
                </div>
                : <></>
            }
        </>
    )
}
