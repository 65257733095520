import { useEffect } from 'react'
import { useAppSelector } from './redux'
import { type RootState } from '../store/store'

interface UseFireSearchProps {
    callback: (...args: any[]) => any
    delay: number
    controlValue: any
}

const UseFireSearch = ({ callback, delay, controlValue }: UseFireSearchProps): void => {
    const pageCount = useAppSelector((state: RootState) => state.pagination.pageCount)
    useEffect(() => {
        //  console.log('FIRESEARCH LANDED')
        const timeout = setTimeout(() => {
            callback()
            //      console.log(pageCount)
        }, delay)
        return () => { clearTimeout(timeout) }
    }, [controlValue, pageCount])
}

export default UseFireSearch
