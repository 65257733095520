import { type IChat, type IChatListItem } from '../../types'
import { MESSAGE_UNPUBLISH_TIME } from '../../constants'
import { store } from '../../store/store'

const ChatUtils = {

    formatTime: (time: Date): string => {
        const hours = time.getHours()
        const minutes = time.getMinutes()
        return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`
    },
    formatDuration: (seconds: number): string => {
        const m = Math.floor(seconds / 60)
        const s = Math.floor(seconds % 60)
        /* if (m === 0 && s === 0) {
            return '-:--'
        } */
        if (s < 10) {
            return `${Math.max(m, 0)}:0${Math.max(s, 0)}`
        }
        return `${Math.max(m, 0)}:${Math.max(s, 0)}`
    },
    hasLastMessageDisappeared: (chat: IChatListItem): boolean => {
        return chat?.id === +(process.env.REACT_APP_BSO_BROADCAST_ID ?? '4') &&
            chat.LastMessage?.updatedAt !== '' &&
                chat.LastMessage?.updatedAt != null &&
                    new Date().getTime() - new Date(chat.LastMessage.updatedAt).getTime() > MESSAGE_UNPUBLISH_TIME
    },
    isBSOBroadcast: (chat: IChat | IChatListItem): boolean => {
        return chat?.id === +(process.env.REACT_APP_BSO_BROADCAST_ID ?? '4') /* && store.getState().user.currentUser.id !== +(process.env.REACT_APP_BSO_USER_ID ?? '57') */
    },
    isBSOSupport: (chat: IChat | IChatListItem): boolean => {
        if (ChatUtils.isBSOBroadcast(chat)) {
            return false
        }
        if ((chat as IChatListItem)?.userID != null) {
            return (chat as IChatListItem).userID === +(process.env.REACT_APP_BSO_USER_ID ?? '57')
        } else {
            return (chat as IChat)?.User?.id === +(process.env.REACT_APP_BSO_USER_ID ?? '57')
        }
    },
    isMessageOffer: (chat: IChat | IChatListItem): boolean => {
        // console.log(chat, chat.Type === 'Offer', "chat.Type === 'Offer'")
        return chat.Type === 'Offer'
    }

}

export default ChatUtils
