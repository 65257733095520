import React from 'react'
import Icon from '../../../app/icon/Icon'
import { type IconName } from '../../../app/icon/iconsConfig'

export interface UnitBodyOptionsBadgeShProps {
    icon: IconName
    text?: string
    renderCondition?: boolean
}
export default function UnitBodyOptionsBadgeSh ({ icon, text, renderCondition = true }: UnitBodyOptionsBadgeShProps): JSX.Element {
    return (
        text != null && renderCondition
            ? <div className="btn btn-sm btn-light py-1 px-2 me-2 mb-2 d-flex align-items-center gap-1">
                <Icon
                    name={icon}
                    className={'text-muted'}
                    iconToFontRatio={1.2}
                />
                <span data-id="units_list_template_bathrooms" style={{ lineHeight: '100%' }}>
                    {text}
                </span>
            </div>
            : <></>
    )
}
