import CustomFormLable from '../customModalUtils/CustomFormLable'
import CustomSelect from './CustomSelect'

import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { setDrawerOpen } from '../../../store/slices/modalSlice'
import Icon from '../../icon/Icon'
import CustomModalDrawer from '../customModalUtils/CustomModalDrawer'

interface CustomSelectProps {
    row: any
    control: any
    register: any
    errors: any
    buttonView?: boolean
    unitID?: string
    objectID?: string
}

const CustomSelectAdd = ({ row, control, register, errors, buttonView = false, unitID, objectID }: CustomSelectProps): JSX.Element => {
    // const [drawerOpen, setDrawerOpen] = useState(false)
    const drawerOpen = useAppSelector((state: RootState) => state.modal.drawerOpen)
    const [filledRow, setFilledRow] = useState(row)
    const dispatch = useAppDispatch()
    const dynamicDropDown = useAppSelector((state: RootState) => state.modal.dynamicDropDown)
    useEffect(() => {
        setFilledRow({ ...row, selectors: dynamicDropDown })
    }, [row, dynamicDropDown])
    return (
        <div className={'container mb-2 px-0'}>
            {buttonView ? <></> : <CustomFormLable row={filledRow} />}
            <div className="row align-items-center fv-row m-0 p-0 w-100 fv-plugins-icon-container">
                {buttonView
                    ? <div className={'btn btn-bso col'}
                        onClick={() => {
                            dispatch(setDrawerOpen(row.modal))
                        }}
                    >
                        {
                            row.content[0]
                        }
                    </div>
                    : <>
                        <div className={`${row.editModal != null ? 'col-9' : 'col-10'} p-0`}>
                            <CustomSelect control={control} register={register} data={filledRow} />
                        </div>
                        <div className="col p-0 d-flex justify-content-end">
                            <i className="ki-duotone ki-plus-square fs-4x"
                                onClick={() => {
                                    dispatch(setDrawerOpen(row.modal))
                                }}
                            >
                                <i className="path1"></i>
                                <i className="path2"></i>
                                <i className="path3"></i>
                            </i>
                        </div>
                        {row.editModal != null
                            ? <div className="col p-0 d-flex justify-content-end">
                                <i className="ki-duotone ki-pencil fs-4x"
                                    onClick={() => {
                                        dispatch(setDrawerOpen(row.editModal))
                                    }}
                                >
                                    <i className="path1"></i>
                                    <i className="path2"></i>
                                    <i className="path3"></i>
                                </i>
                            </div>
                            : <></>
                        }
                        {/* <Icon name={'close'} className={'fs-5x text-muted'} onClick={() => { */}
                        {/*    dispatch(setDrawerOpen(row.id)) */}
                        {/* }}/> */}
                    </>
                }
                <CustomModalDrawer
                    open={(drawerOpen === row.modal || drawerOpen === row.editModal) && drawerOpen != null}
                    closeDrawer={() => {
                        dispatch(setDrawerOpen(undefined))
                    }}
                    modalID={drawerOpen ?? ''}
                    unitID={unitID}
                    objectID={objectID}
                />
            </div>
            {errors?.[row.id] && (
                <div className="text-danger">{errors[row.id].message}</div>
            )}
        </div>
    )
}

export default CustomSelectAdd
