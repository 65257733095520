import moment from 'moment'
import React from 'react'
import useVoiceMessage from '../../../../hooks/UseVoiceMessage'
import Icon from '../../../../app/icon/Icon'

interface SharedVoiceProps {
    time: Date
    sentBy: string
    id: string
    audioSrc: string
}

const SharedVoice = ({ time, sentBy, id, audioSrc }: SharedVoiceProps): JSX.Element => {
    const { play, pause, isPlaying } = useVoiceMessage({
        date: time,
        author: sentBy,
        audioSrc,
        id
    })
    return (
        <div className={'d-flex flex-row gap-2 w-100'}>
            {
                isPlaying
                    ? <Icon
                        key={'pause'}
                        name={'pause_circle'}
                        className={'text-muted fs-5x'}
                        onClick={pause}
                    />
                    : <Icon
                        key={'play'}
                        name={'play_circle'}
                        className={'text-muted fs-5x'}
                        onClick={play}
                    />
            }

            <div className={'d-flex flex-column justify-content-between w-100'}>
                <div>
                    {
                        moment(time).format('MMM DD - HH:MM')
                    }
                </div>
                <div className={'d-flex flex-row gap-2 text-gray-500 fs-8'}>
                    <div>
                        {sentBy}
                    </div>
                </div>
                <div className="separator"/>
            </div>
        </div>
    )
}

export default SharedVoice
