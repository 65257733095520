import { type IUser } from '../../../types'

export const compareOffers = (a: IUser, b: IUser): number => {
    let dataA, dataB
    let compretion = 0

    if (a.Offers.length !== 0) {
        dataA = a.Offers.length
    } else {
        dataA = compretion
    }
    if (b.Offers.length !== 0) {
        dataB = b.Offers.length
    } else {
        dataB = compretion
    }

    if (dataA > dataB) compretion = -1
    if (dataA < dataB) compretion = 1
    return compretion
}
export const compareEarnings = (a: any, b: any): number => {
    let dataA, dataB
    let compretion = 0
    if (a.Settings && a.Settings.length > 0 && a.Settings[0]?.Business) {
        dataA = Number(a.Settings[0]?.Business)
    } else {
        dataA = compretion
    }
    if (b.Settings && b.Settings.length > 0 && b.Settings[0]?.Business) {
        dataB = Number(b.Settings[0]?.Business)
    } else {
        dataB = compretion
    }

    if (dataA > dataB) compretion = -1
    if (dataA < dataB) compretion = 1
    return compretion
}

export const compareRating = (a: any, b: any): number => {
    let dataA, dataB
    let compretion = 0

    if (a.Settings && a.Settings.length > 0 && a.Settings[0]?.Performance) {
        dataA = a.Settings[0]?.Performance
    } else {
        dataA = compretion
    }
    if (b.Settings && b.Settings.length > 0 && b.Settings[0]?.Performance) {
        dataB = b.Settings[0]?.Performance
    } else {
        dataB = compretion
    }

    if (dataA > dataB) compretion = -1
    if (dataA < dataB) compretion = 1
    return compretion
}

// export const compareRating = (a: any, b: any): number => {
//     let dataA, dataB
//     let compretion = 0
//     console.log({ a, b })
//     if (a.Agent.Performance !== null || a.Agent.Performance.length !== 0) {
//         const index = a.Agent.Performance.length - 1
//         dataA = a.Agent.Performance[index].TotalScore
//     } else {
//         dataA = compretion
//     }
//     if (b.Agent.Performance !== null || b.Agent.Performance.length !== 0) {
//         const index = b.Agent.Performance.length - 1
//         dataB = b.Agent.Performance[index].TotalScore
//     } else {
//         dataB = compretion
//     }
