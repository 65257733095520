import QueryString from 'qs'
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../urls'
import { type AppDispatch, type RootState } from '../../store'
import { messagePopulate } from '../chatActions'
import { setUserBroadcastMessages } from '../../slices/chatSlice'
import getMessageAdapter from '../../../functions/adapters/chat/getMessageAdapter'

export const getBroadcasts = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const lsUserId = window.localStorage.getItem(USER_ID_KEY)
        const currentUser = getState().user.currentUser
        const userID = currentUser.id ?? lsUserId

        const query: any = {
            // sort: {
            //     id: 'desc'
            // },
            populate: messagePopulate,
            filters: {
                Sender: {
                    id: userID
                },
                Text: 'type:unit',
                ChatRoom: {
                    Type: 'Broadcast'
                }
            }

        }

        const bodyObject = {
            method: 'GET',
            collection: 'messages',
            query: QueryString.stringify(query, {
                encodeValuesOnly: true
            })
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                'BSO-Hash': '02ns30nsBSO!',
                Authorization: `Bearer ${jwt}`
            }
        })

        const dataJSON = await response.json()
     
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            } else {
                const fetchedMessages = getMessageAdapter(dataJSON)

                dispatch(setUserBroadcastMessages(fetchedMessages))
                return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.error(error)
    }
}
