import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../hooks/redux'

interface ActionButtonProps {
    active: boolean
    fullFooter: boolean
}
const ActionButton = ({ active, fullFooter }: ActionButtonProps): JSX.Element => {
    const userDevice = useAppSelector((state) => state.app.userDevice)
    return (
        active
            ? <div className={`fixed-bottom mw-450px mx-auto px-4 mt-2 mb-1 pt-4 ${fullFooter ? 'bg-white shadow-sm' : ''}`}
                style={{ bottom: '0', left: '0px', zIndex: 100 }}
            >
                <Link to={'/landlord/services'} className="w-100">
                    <div className="btn btn-bso btn-primary w-100">Order a service</div>
                </Link>
                <div style={{ height: userDevice === 'IPhone' ? '10rem' : '8.65rem' }}></div>
            </div>
            : <></>
    )
}

export default ActionButton
