import React from 'react'
import { type RootState } from '../../../store/store'
import { useAppSelector } from '../../../hooks/redux'
import { type User } from '../../../store/slices/userSlice'

export default function PaymentMy () {
    const { currentUser } = useAppSelector(
        (state: RootState) => state.user
    )

    return (
        <>
            <div className="card shadow-sm mb-5 mb-xl-10">

                {/* <div className="card-header card-header-stretch pb-0"> */}

                {/* <div className="card-title">
                    <h3 className="m-0">Payment Methods</h3>
                </div> */}

                {/* <div className="card-toolbar m-0">

                    <ul className="nav nav-stretch nav-line-tabs border-transparent" role="tablist">

                        <li className="nav-item" role="presentation">
                            <a id="kt_billing_creditcard_tab" className="nav-link fs-5 fw-bold me-5 active" data-bs-toggle="tab" role="tab" href="#kt_billing_creditcard" aria-selected="true">
                        Credit / Debit Card
                            </a>
                        </li>

                        <li className="nav-item" role="presentation">
                            <a id="kt_billing_paypal_tab" className="nav-link fs-5 fw-bold" data-bs-toggle="tab" role="tab" href="#kt_billing_paypal" aria-selected="false">
                        Paypal
                            </a>
                        </li>
                    </ul>
                </div> */}

                {/* </div> */}

                <div id="kt_billing_payment_tab_content" className="card-body tab-content">

                    <div id="kt_billing_creditcard" className="tab-pane fade active show">

                        <h3 className="mb-5">My Cards</h3>

                        <div className="row gx-9 gy-6">

                            <div className="col-xl-6" data-kt-billing-element="card">

                                <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">

                                    <div className="d-flex flex-column py-2">

                                        <div className="d-flex align-items-center fs-4 fw-bold mb-5">
                                            {(currentUser as User)?.ContactInfo?.FirstName} {(currentUser as User)?.ContactInfo?.FamilyName}
                                            <span className="badge badge-light-success fs-7 ms-2">Primary</span>
                                        </div>

                                        <div className="d-flex align-items-center">

                                            <img src="/assets/media/placeholders/visa.svg" alt="" className="me-4"/>

                                            <div>
                                                <div className="fs-4 fw-bold">Visa **** 1679</div>
                                                <div className="fs-6 fw-semibold text-gray-400">Card expires at 09/24</div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="d-flex align-items-center py-2">
                                        <button className="btn btn-sm  border border-dark text-dark  me-3" data-kt-billing-action="card-delete">

                                            <span className="indicator-label">
    Delete</span>

                                            <span className="indicator-progress">
    Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>

                                        </button>
                                        <button className="btn btn-sm  border border-dark text-dark btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Edit</button>
                                    </div>

                                </div>

                            </div>

                            <div className="col-xl-6" data-kt-billing-element="card">

                                <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">

                                    <div className="d-flex flex-column py-2">

                                        <div className="d-flex align-items-center fs-4 fw-bold mb-5">
                                            {(currentUser as User)?.ContactInfo?.FirstName} {(currentUser as User)?.ContactInfo?.FamilyName}
                                        </div>

                                        <div className="d-flex align-items-center">

                                            <img src="/assets/media/placeholders/mastercard.svg" alt="" className="me-4"/>

                                            <div>
                                                <div className="fs-4 fw-bold">Mastercard **** 1290</div>
                                                <div className="fs-6 fw-semibold text-gray-400">Card expires at 03/23</div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="d-flex align-items-center py-2">
                                        <button className="btn btn-sm  border border-dark text-dark btn-active-light-primary me-3" data-kt-billing-action="card-delete">

                                            <span className="indicator-label">
    Delete</span>

                                            <span className="indicator-progress">
    Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>

                                        </button>
                                        <button className="btn btn-sm  border border-dark text-dark btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Edit</button>
                                    </div>

                                </div>

                            </div>

                            {/* <div className="col-xl-6">

                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6">

                                <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">

                                    <div className="mb-3 mb-md-0 fw-semibold">
                                        <h4 className="text-gray-900 fw-bold">Important Note!</h4>

                                        <div className="fs-6 text-gray-700 pe-7">Please carefully read <a href="#" className="fw-bold me-1">Product Terms</a> adding  your new payment card  </div>
                                    </div>

                                    <a href="#" className="btn btn-primary px-6 align-self-center text-nowrap" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">
                Add Card            </a>

                                </div>

                            </div>

                        </div> */}

                        </div>

                    </div>

                    {/* <div id="kt_billing_paypal" className="tab-pane fade" role="tabpanel" aria-labelledby="kt_billing_paypal_tab" data-select2-id="select2-data-kt_billing_paypal">

                    <h3 className="mb-5">My Paypal</h3>

                    <div className="text-gray-600 fs-6 fw-semibold mb-5">To use PayPal as your payment method, you will need to make pre-payments each month before your bill is due.</div>

                    <form className="form" data-select2-id="select2-data-361-our6">

                        <div className="mb-7 mw-350px">
                            <select name="timezone" data-control="select2" data-placeholder="Select an option" data-hide-search="true" className="form-select form-select-solid form-select-lg fw-semibold fs-6 text-gray-700 select2-hidden-accessible" data-select2-id="select2-data-10-3hvf" aria-hidden="true" data-kt-initialized="1">
                                <option data-select2-id="select2-data-12-iqdf">Select an option</option>
                                <option value="25" data-select2-id="select2-data-363-avwj">US $25.00</option>
                                <option value="50" data-select2-id="select2-data-364-l57p">US $50.00</option>
                                <option value="100" data-select2-id="select2-data-365-a2tv">US $100.00</option>
                                <option value="125" data-select2-id="select2-data-366-wx43">US $125.00</option>
                                <option value="150" data-select2-id="select2-data-367-ebdz">US $150.00</option>
                            </select><span className="select2 select2-container select2-container--bootstrap5 select2-container--below" dir="ltr" data-select2-id="select2-data-11-sqma" style={{ width: '100%' }}><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid form-select-lg fw-semibold fs-6 text-gray-700" role="combobox" aria-haspopup="true" aria-expanded="false" aria-disabled="false" aria-labelledby="select2-timezone-21-container" aria-controls="select2-timezone-21-container"><span className="select2-selection__rendered" id="select2-timezone-21-container" role="textbox" aria-readonly="true" title="US $150.00">US $150.00</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
                        </div>

                        <button type="submit" className="btn btn-primary">Pay with Paypal</button>
                    </form>

                </div> */}

                </div>

            </div>
            <div className="h-90px"></div>
        </>
    )
}
