import { getErrorss } from '../../../functions/errors/GetErrors'
import { removeNotifyByDeviceToken } from '../../../functions/shared/notify-helpers/removeNotify'
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../urls'
import { setViewings, viewingsFetchingError } from '../../slices/viewingsSlice'
import { type AppDispatch, type RootState } from '../../store'
import { viewingsQuery } from '../viewingsActions'

export const viewViewing = (bodyClient: any) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
        try {
            const response = await fetch(`${urlAPI}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    body: JSON.stringify({
                        method: 'PUT',
                        collection: 'viewings',
                        id: Number(bodyClient.viewID),
                        query: viewingsQuery,
                        body: {
                            Notify: bodyClient.notify
                        }
                    })
                })
            const dataJSON = await response.json()
            if (dataJSON.success) {
                if (dataJSON.response.error != null) {
                    dispatch(viewingsFetchingError(getErrorss(dataJSON)))

                    return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
                } else {
                    await removeNotifyByDeviceToken({
                        userID: getState().user.currentUser.id as number,
                        deviceToken: getState().user.currentUser.DeviceToken as string
                    })
                    dispatch(setViewings(
                        dataJSON.response.data
                    ))

                    return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
                }
            } else {
                dispatch(viewingsFetchingError(getErrorss(dataJSON)))
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            }
        } catch (e) {
            console.log(e)
        }
    }
}
