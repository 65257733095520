import React from 'react'
import { ava_blank, bso_logo_512 } from '../../../urls'

interface AvatarProps {
    image?: string
    size: string
    isAdmin?: boolean
}

const Avatar = ({ image, size, isAdmin }: AvatarProps): JSX.Element => {

    return (
        <div style={{ position: 'relative', width: size, height: size }}>
            <div
                className="rounded-circle overflow-hidden"
                style={{
                    background: `url(${isAdmin ? bso_logo_512 : ava_blank}) center center / cover no-repeat`,
                    width: size,
                    height: size,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                }}
            />

            <div
                className="rounded-circle overflow-hidden"
                style={{
                    background: `url(${isAdmin ? bso_logo_512 : image ?? ava_blank}) center center / cover no-repeat`,
                    width: size,
                    height: size,
                    position: 'relative',
                    zIndex: 2,
                }}
            />
        </div>
    );
};



export default Avatar
