import React, { useState } from 'react'
import MyUnitsScrollSaver from './MyUnitsScrollSaver'
import AgentHeader from '../../agentModule/AgentHeader'
import LandlordHeader from '../../landlordModule/LandlordHeader'

import useUserType from '../../../hooks/UseUserType'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import UnitsHeaderSh from './UnitsHeaderSh'
import UnitsNavigateSh from './UnitsNavigateSh'
import MainUnitFilters from './filters/MainUnitFilters'
import UnitSh from './UnitSh'
import { nanoid } from 'nanoid'
import UnitsNotFoundMessageSh from './UnitsNotFoundMessageSh'
import { Spin } from 'antd'
import useUnitsCatalog from '../../../hooks/useUnitsCatalog'
import { setUnitsCatalogScrollPosition, setUnitsCategory } from '../../../store/slices/unitsSlice'
import { type UnitsCategory } from './UnitsSh'
import Loader from '../../../app/custom/loaders/Loader'
import { Link } from 'react-router-dom'
const MyUnitsWithFiltersSh = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { filters: storedFilters, searchString, unitsLoading, units, myArchivedUnitsOpen } = useAppSelector((state: RootState) => state.units)
    const { userType, userTypeLowercase } = useUserType()
    const filteredCatalog = useUnitsCatalog(true)
    const unitsCategory = useAppSelector((state: RootState) => state.units.unitsCategory)
    const userDevice = useAppSelector((state: RootState) => state.app.userDevice)
    const paginationLoading = useAppSelector((state: RootState) => state.units.catalogLoading)
    const [filterUse, setFilterUse] = useState('rent')

    const onClickCategory = (val: UnitsCategory): any => {
        dispatch(setUnitsCatalogScrollPosition(0))
        dispatch(setUnitsCategory(val))
    }

    const onClickUse = (val: string): any => {
        setFilterUse(val)
    }

    if (unitsLoading) return <Loader />
    return <>
        <MyUnitsScrollSaver />
        <div className="page d-flex flex-column flex-row-fluid">
            {/* {
                userType === 'Agent'
                    ? <AgentHeader
                        active="My units"
                    />
                    : <LandlordHeader
                        active="My units"
                    />
            } */}

            <div className="header w-100 bg-white h-auto fw-bold d-flex flex-column shadow-sm mw-450px mx-auto position-fixed"
                style={{
                    // marginTop: isVoicePlayerShown ? '31px' : 0,
                    paddingTop: userDevice === 'IPhone' ? '2.5rem' : 0, zIndex: 100 // userDevice === 'IPhone' ? 'calc(2.5rem + 6rem)' : '6rem',

                }}
            >
                <div className="d-flex flex-column p-4 py-0 w-100 position-relative">
                    <UnitsHeaderSh />
                </div>
                <UnitsNavigateSh filter={{ unitsCategory, filterUse }} onClickCategory={onClickCategory} />
                {/* <UnitFilters/> */}
                <MainUnitFilters />
            </div>
            <div style={{ height: '12.5rem' }} />

            <div className="content d-flex flex-column flex-fill pt-4 pb-4 mx-4 overflow-auto"
                style={{
                    height: '100%'//! hideHeader ? 'calc(100vh - 21.25rem)' : 'calc(100vh - 10rem)' // ????  'calc(100vh - 17rem)'
                }}
            // ref={scrollRef}
            >

                {filteredCatalog?.length > 0
                    ? (
                        <>
                            {
                                filteredCatalog.map((unit: any) => {
                                    return (
                                        <UnitSh
                                            key={`${String(unit.id)}-${nanoid()}`}
                                            hide={true}
                                            unit={unit}
                                            fromEntry={'myUnitsAgent'}
                                        // settings={settings}
                                        />
                                    )
                                })
                            }
                        </>
                    )
                    : (!paginationLoading
                        ? <UnitsNotFoundMessageSh />
                        : <></>
                    )
                }
                {
                    paginationLoading
                        ? <div className={'w-100 d-flex justify-content-center p-4'}>
                            <Spin size={'large'} />
                        </div>
                        : <></>
                }
                <div className="d-none pb-14"></div>
            </div>

            <div className="m-0" style={{ height: '170px' }}></div>
            <div className="fixed-bottom mw-450px mx-auto px-4 py-2 bg-white" style={{ bottom: userDevice === 'IPhone' ? '9.65rem' : '8rem', left: '0px', zIndex: 50 }}>

                <Link to={`/${userTypeLowercase}/set_unit_modal/set_unit_modal/-/-`}>
                    <div className=" btn btn-bso my-1 fs-6 p-1 py-4 w-100">
                        Add unit
                    </div >
                </Link>

            </div>
        </div>

    </>
}

export default MyUnitsWithFiltersSh
