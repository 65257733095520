import React, { useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import _ from 'lodash'
import MessagesSwitch from '../../newChat/chat/messages/MessagesSwitch'
import { nanoid } from 'nanoid'
import {
    chatSetInvitePage,
    setSearchInvitesScrollPosition
} from '../../../../store/slices/chatSlice'
import messageDatesAdapter from '../../../../functions/adapters/chat/messageDatesAdapter'
import { type IChatMessage } from '../../../../types'
import useUserType from '../../../../hooks/UseUserType'
import { useNavigate } from 'react-router'
import useInfinteScroll from '../../../../hooks/UseInfinteScroll'
import { searchInvites } from '../../../../store/actionsCreators/chatActions'
import { Spin } from 'antd'
import useSaveScroll from '../../../../hooks/useSaveScroll'
import customLog from '../../../../functions/log'

const SearchInvites = (): JSX.Element => {
    const { searchedInvites, searchInvitesScrollPosition } = useAppSelector((state: RootState) => state.chat)
    const { paginationLoading } = useAppSelector((state: RootState) => state.pagination)
    const { invitePage: page, invitePageCount: pageCount } = useAppSelector((state: RootState) => state.chat)
    const { userType } = useUserType()
    const dispatch = useAppDispatch()
    const messages = messageDatesAdapter(searchedInvites, 'desc')
    const navigate = useNavigate()
    const scrollRef = useRef<HTMLDivElement>(null)
    useInfinteScroll({
        scrollRef,
        page,
        pageCount,
        setPage: chatSetInvitePage,
        action: () => searchInvites(page),
        direction: 'bottom'
    })
    useSaveScroll({
        scrollRef,
        position: searchInvitesScrollPosition,
        setPosition: (position: number) => dispatch(setSearchInvitesScrollPosition(position))
    })

    customLog(messages)

    return (
        <div className={'d-flex flex-column gap-4 p-4 overflow-auto'}
            style={{ height: '100%' }}
            ref={scrollRef}
        >
            {
                messages.length > 0
                    ? _.map(
                        _.map(messages, message => ({ ...message, Sender: -1 })),
                        (message) =>
                            (
                                (message as { date: string }).date !== undefined
                                    ? <div className={'d-flex justify-content-center'} key={nanoid()}>
                                        <div className={'badge bg-white color-black padding-1'}>{(message as { date: string }).date}</div>
                                    </div>
                                    : <>
                                        <div
                                            onClick={() => {
                                                navigate(`/${userType.toLowerCase()}/chats/${String((message as IChatMessage)?.ChatRoom ?? '-')}?message=${(message as IChatMessage).id}`)
                                            }}
                                        >
                                            <MessagesSwitch message={message as IChatMessage} key={nanoid()}/>
                                        </div>
                                    </>
                            ))
                    : !paginationLoading
                        ? <div className="card shadow-sm my-1">
                            <div className="card-body p-0 m-5 text-center">
                                <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 ">
                                No messages for you
                                </span>
                            </div>
                        </div>
                        : <></>
            }
            {
                paginationLoading
                    ? <div className={'w-100 d-flex justify-content-center p-4'}>
                        <Spin size={'large'}/>
                    </div>
                    : <></>
            }
        </div>
    )
}

export default SearchInvites
