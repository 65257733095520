import CustomFormLable from '../customModalUtils/CustomFormLable'
import React, { type ReactElement, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { Controller } from 'react-hook-form'
import _ from 'lodash'
import Icon from '../../icon/Icon'
import CustomSelect from '../selects/CustomSelect'
import { addUploadedDocument, setTitleDeedLoaded, setValue as setStoreValue } from '../../../store/slices/setUnitSlice'
import { regExps } from '../../../constants'
import { store } from '../../../store/store'
import { setScannedData } from '../../../store/slices/modalSlice'

interface CustomEmiratesIDInputProps {
    row: any
    control: any
    errors: any
    setValue: any
    type?: string
    register: any
}
interface CustomEmiratesIDSubmitButtonProps {
    documentType: string
    setScanned: (value: 'Minka kadj' | '') => void
    row: any
}

interface CustomTitleDeedInputManualToggleProps {
    setScanned: (value: 'Minka kadj' | '') => void
    row: any
}

export const documentStatuses: {
    true: 'Minka kadj'
    false: ''
} = {
    true: 'Minka kadj',
    false: ''
}

function CustomEmiratesIDSubmitButton ({ documentType, setScanned, row }: CustomEmiratesIDSubmitButtonProps): React.ReactElement {
    const loading = useAppSelector((state) => state.modal.loading)
    return (
        !loading
            ? <div className={'row gap-4 px-4'}>
                <div
                    className={'btn btn-bso col'}
                    onClick={() => {
                        const documents: Record<string, File | undefined> = {}
                        const inputs: HTMLInputElement[] = []
                        switch (documentType) {
                            case 'Emirates ID':
                                inputs[0] = document.getElementById('emirates-id-front') as HTMLInputElement
                                inputs[1] = document.getElementById('emirates-id-back') as HTMLInputElement
                                documents['emirates-id-front'] = inputs?.[0]?.files?.[0]
                                documents['emirates-id-back'] = inputs?.[1]?.files?.[0]
                                break
                            case 'Passport':
                                inputs[0] = document.getElementById('passport') as HTMLInputElement
                                documents.passport = inputs?.[0]?.files?.[0]
                                break
                            case 'Title Deed':
                                inputs[0] = document.getElementById('title-deed') as HTMLInputElement
                                if (inputs[0]?.files?.[0] == null) {
                                    setScanned(documentStatuses.false)
                                    return
                                }
                                documents['title-deed'] = inputs[0]?.files?.[0]
                                break
                            default:
                                break
                        }
                        if (row.onScan != null) {
                            row.onScan(documentType)(documents)
                                .then((isSuccessful: boolean) => {
                                    setScanned(isSuccessful ? documentStatuses.true : documentStatuses.false)
                                })
                        }
                    }}
                >
                Scan
                </div>
            </div>
            : <div className={'btn btn-bso disabled'}>
                <span className="indicator-progress d-block">
                    Please wait...
                    <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                </span>
            </div>
    )
}

export function CustomEmiratesIdTitleDeedFields ({ row, control, register, errors, setValue, type }: CustomEmiratesIDInputProps): React.ReactElement {
    const url = useAppSelector((state) => state.modal?.scannedData?.failedToScan?.url)
    const dispatch = useAppDispatch()
    return (
        type === 'Title Deed' && url != null
            ? <>
                <div className="mt-2 mb-2">
                    <CustomFormLable row={{ id: 'Scanned.DocumentNumber', required: true, content: ['Document number'] }} />
                    <input
                        {...register('Scanned.DocumentNumber', {
                            required: 'Field "Document number" is required',
                            maxLength: row.maxLength != null ? row.maxLength : 60,
                            validate: (value: string) => new RegExp(regExps.isNumber).test(value)
                        })}
                        className="form-control form-control-solid form-control-lg"
                        placeholder={'Document number'}
                        onInput={(e: any) => {
                            dispatch(setStoreValue({ id: 'Scanned.DocumentNumber', value: e.target.value }))
                        }}
                        type="text"
                        autoComplete="off"
                    />
                    {_.get(errors, 'Scanned.DocumentNumber') != null &&
                        (_.get(errors, 'Scanned.DocumentNumber').type === 'validate'
                            ? <div className="text-danger">{'Document number must be a number'} </div>
                            : <div className="text-danger">{_.get(errors, 'Scanned.DocumentNumber').message}</div>
                        )
                    }
                </div>
                <div className="mb-2">
                    <CustomFormLable row={{ id: 'Scanned.DocumentYear', required: true, content: ['Document issue year'] }} />
                    <input
                        {...register('Scanned.DocumentYear', {
                            required: 'Field "Document issue year" is required',
                            maxLength: row.maxLength != null ? row.maxLength : 60,
                            validate: (value: string) => new RegExp(regExps.isYear).test(value)
                        })}
                        className="form-control form-control-solid form-control-lg"
                        placeholder={'Year'}
                        onInput={(e: any) => {
                            dispatch(setStoreValue({ id: 'Scanned.DocumentYear', value: e.target.value }))
                        }}
                        type="text"
                        autoComplete="off"
                    />
                    {_.get(errors, 'Scanned.DocumentYear') != null &&
                        (_.get(errors, 'Scanned.DocumentYear').type === 'validate'
                            ? <div className="text-danger">{'Document issue year must be a valid'} </div>
                            : <div className="text-danger">{_.get(errors, 'Scanned.DocumentYear').message}</div>
                        )
                    }
                </div>
                <div className="mb-2">
                    <CustomFormLable row={{ id: 'Scanned.DocumentType', required: true, content: ['Document type'] }} />
                    <CustomSelect
                        control={control}
                        register={register}
                        data={{
                            id: 'Scanned.DocumentType',
                            required: true,
                            content: ['Document type', 'Select document type'],
                            selectors: ['Unit', 'Villa', 'Land'],
                            onChange: (e: any) => {
                                dispatch(setStoreValue({ value: e, id: 'Scanned.DocumentType' }))
                            }
                        }}
                        setValue={setValue}
                    />
                    {_.get(errors, 'Scanned.DocumentType') != null && (
                        <div className="text-danger">{_.get(errors, 'Scanned.DocumentType').message}</div>
                    )}
                </div>
            </>
            : <></>
    )
}

function CustomTitleDeedInputManualToggle ({ setScanned, row }: CustomTitleDeedInputManualToggleProps): ReactElement {
    const dispatch = useAppDispatch()
    const url = useAppSelector((state) => state.modal?.scannedData?.failedToScan?.url)
    return (
        <div className={'row mt-2 px-4'}>
            <div className="btn btn-light"
                onClick={() => {
                    if (url == null) {
                        dispatch(setScannedData({ ...store.getState().modal.scannedData, failedToScan: { url: '' } }))
                        dispatch(setStoreValue({ value: documentStatuses.true, id: row.id ?? '' }))
                        setScanned(documentStatuses.true)
                    } else {
                        dispatch(setScannedData({ ...store.getState().modal.scannedData, failedToScan: { url: undefined } }))
                        dispatch(setStoreValue({ value: documentStatuses.false, id: row.id ?? '' }))
                        setScanned(documentStatuses.false)
                    }
                }}
            >
                {
                    url == null
                        ? 'Add info manually'
                        : 'Close manual fields'
                }
            </div>
        </div>
    )
}

export default function CustomEmiratesIDInput ({ row, control, register, errors, setValue, type }: CustomEmiratesIDInputProps): React.ReactElement {
    let documentType: 'Emirates ID' | 'Passport' | string = useAppSelector(state => state.modal.scannedData.documentType ?? 'Emirates ID')
    const [uploadedBase64, setUploadedBase64] = useState<string>()
    if (type != null) {
        documentType = type
    }
    /**
     * In this component 'Minka kadj' is used as true and '' as a false for the field validation
     */
    function setScanned (value: 'Minka kadj' | ''): void {
        setValue(row.id, value)
    }
    useEffect(() => {
        //   console.log('Drop scanned')
        if (row.default == null) {
            setScanned(documentStatuses.false)
        }
        if (row.value === documentStatuses.true) {
            setScanned(documentStatuses.true)
        }
    }, [documentType])
    return (
        <div className="fv-row mb-2 m-0 p-0 w-100 fv-plugins-icon-container">
            <CustomFormLable row={row} />
            <div className={'card bg-white p-4 my-4'} style={{ border: '1px solid #d9d9d9' }}>
                {documentType === 'Emirates ID'
                    ? <div className={'d-flex flex-column gap-4 mb-4'}>
                        <div>
                            <label className={'form-label fw-bold mb-2 text-dark text-uppercase'}>Front side</label>
                            <input
                                id={'emirates-id-front'}
                                className="form-control form-control-solid form-control-lg"
                                /* onInputCapture={
                                    row.onInput?.['emirates-id-front'] != null
                                        ? row.onInput['emirates-id-front']
                                        : null
                                } */
                                accept={row.id === 'avatar' ? 'image/png, image/gif, image/jpeg' : undefined}
                                type="file"
                                placeholder="Choose File"
                                multiple={row.multiple != null}
                            />
                        </div>
                        <div>
                            <label className={'form-label fw-bold mb-2 text-dark text-uppercase'}>Back side</label>
                            <input
                                id={'emirates-id-back'}
                                className="form-control form-control-solid form-control-lg"
                                /* onInputCapture={
                                    row.onInput?.['emirates-id-back'] != null
                                        ? row.onInput['emirates-id-back']
                                        : null
                                } */
                                accept={row.id === 'avatar' ? 'image/png, image/gif, image/jpeg' : undefined}
                                type="file"
                                placeholder="Choose File"
                                multiple={row.multiple != null}
                            />
                        </div>
                    </div>
                    : <></>
                }
                {documentType === 'Passport'
                    ? <div className={'d-flex flex-column gap-4 mb-4'}>
                        <div>
                            <label className={'form-label fw-bold mb-2 text-dark text-uppercase'}>Passport</label>
                            <input
                                id={'passport'}
                                className="form-control form-control-solid form-control-lg"
                                /* onInputCapture={
                                    row.onInput?.passport != null
                                        ? row.onInput.passport
                                        : null
                                } */
                                accept={row.id === 'avatar' ? 'image/png, image/gif, image/jpeg' : undefined}
                                type="file"
                                placeholder="Choose File"
                                multiple={row.multiple != null}
                            />
                        </div>
                    </div>
                    : <></>
                }
                {documentType === 'Title Deed'
                    ? <div className={'d-flex flex-column gap-4 mb-4'}>
                        <div>
                            <div className={'w-100 d-flex align-items-center justify-content-between'}>
                                <label className={'form-label fw-bold mb-2 text-dark text-uppercase'}>Title Deed</label>
                                {
                                    row.value === documentStatuses.true
                                        ? <Icon name={'check_circle'} className={'text-muted fs-1'} />
                                        : <></>
                                }
                            </div>
                            <div className={'d-flex gap-4 align-items-center'}>
                                <input
                                    id={'title-deed'}
                                    className="form-control form-control-solid form-control-lg"
                                    type="file"
                                    placeholder="Choose File"
                                    onInput={(e) => {
                                        const file = e.currentTarget?.files?.[0]
                                        if (file != null) {
                                            const reader = new FileReader()
                                            reader.onload = () => {
                                                // setUploadedBase64(String(reader.result))
                                                // dispatch(addUploadedDocument({ field: row.id, base64: String(reader.result) }))
                                            }
                                            reader.readAsDataURL(file)
                                        }
                                    }}
                                />
                                {uploadedBase64 != null || store.getState().setUnit.uploadedDocuments[row.id] != null
                                    ? <Icon name={'download_for_offline_24dp'} className={'text-muted fs-5x'} onClick={() => {
                                        /* const win = */
                                        window.open(store.getState().setUnit.uploadedDocuments[row.id])
                                        // win?.document?.write(`<iframe src="${uploadedBase64 ?? store.getState().setUnit.uploadedDocuments[row.id]}" width="100%" height="100%"></iframe>`)
                                    }} />
                                    : <></>
                                }
                            </div>
                        </div>
                    </div>
                    : <></>

                }
                <CustomEmiratesIDSubmitButton
                    row={row}
                    documentType={documentType}
                    setScanned={setScanned}
                />
                <CustomEmiratesIdTitleDeedFields {...{ row, control, register, errors, setValue, type }} />
                {
                    documentType === 'Title Deed'
                        ? <CustomTitleDeedInputManualToggle setScanned={setScanned} row={row}/>
                        : <></>
                }
            </div>
            <div>
                <Controller
                    name={row.id}
                    control={control}
                    rules={row.required != null
                        ? {
                            required: `${row.content?.[0]} is required`
                        }
                        : {}
                    }
                    render={({ field }) => (
                        <input type={'text'} {...field} style={{ position: 'absolute', height: '0px', width: '0px', left: '-500px' }} />
                    )}
                />
            </div>
            {
                _.get(errors, row.id) != null && (<div className="text-danger">{_.get(errors, row.id).message}</div>)
            }
        </div>
    )
}
