import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../../urls'

export const checkDocument = async (formValues: Record<string, any>, fileKeys: string[]): Promise<any> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    let docType = fileKeys[0]
    if (docType === 'id_back' || docType === 'id_front') {
        docType = 'emirates_id'
    }
    try {
        const sendObject = {
            method: 'GET',
            collection: 'documents',
            query: `filters[RefID][$contains]=REF_DOC<->${docType}<->${formValues.number}&filters[ForRegister][$eq]=true&filters[User][id][$notNull]=true&populate[User][fields][0]=id`
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })      

        const responseJSON = await response.json()
       
        if (responseJSON.success) {
            if (responseJSON.response.data.length > 0) {
                const filteredResults = responseJSON.response?.data?.filter((doc: any) => {
                    return doc?.attributes?.User?.data?.id !== Number(userID)
                })
                if (filteredResults.length > 0) {
                    return true
                } else {
                    return false
                }
            } else if (responseJSON.response.data === null || responseJSON.response.data.length === 0) {
                return false
            }
            return false
        }
        return false
    } catch (error) {
        console.log(error, 'error')
    }
}








