import _ from "lodash"
import { getQueryDocuments } from "../../../../functions/shared/api/queries/getQueryDocuments"
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from "../../../../urls"
import { setDocuments, setScannedData } from "../../../slices/modalSlice"
import { fetchDocuments } from "../../documentActions"
import { uploadSingleFile } from "../upload/uploadSingleFile"
import { type AppDispatch, type RootState } from "../../../store"

/**
 * @param documents
 * @param type is Category of document in DB
 * @param omitUser
 * @param refID
 * @param collection
 */
export const scanDocuments = (documents: FileList | File[] | null, type: string, omitUser: boolean = false, refID?: string, collection?: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    const promises = _.map(documents, async (document, i) => {
        const formData = new FormData()
        formData.append('file', document)
        formData.append('type', type)
        const response = await fetch('https://bso.ae/api/service/scanDocument', {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        let url: string | null
        if (responseJSON.success === true && (responseJSON?.response?.document?.number != null || type !== 'Title Deed')) {
            url = await uploadSingleFile(document, refID, collection)
            const sendBody = {
                method: 'POST',
                collection: 'documents',
                query: getQueryDocuments(),
                body: {
                    RefID: `REF_DOC_${type.toLowerCase().replace(' ', '_')}_${new Date().getTime()}_${i}`,
                    Link: url,
                    Category: type,
                    Type: [
                        {
                            __component: type === 'Title Deed' ? 'documents.title-deed' : type === 'Emirates ID' ? 'documents.emirates-id' : type === 'Passport' ? 'documents.passport' : '',
                            Number: String(responseJSON?.response?.document?.number),
                            Name: responseJSON?.response?.document?.name,
                            Type: ['Unit', 'Land', 'Villa'].includes(responseJSON?.response?.document?.type) ? responseJSON?.response?.document?.type : 'Unit',
                            Gender: type === 'Title Deed' ? undefined : responseJSON?.response?.document?.type,
                            // Nationality: responseJSON?.response?.document?.nationality,
                            // DateOfBirth: responseJSON?.response?.document?.dob,
                        }
                    ],
                    Statuses: ['In-process'],
                    User: !omitUser ? userID : undefined
                }
            }
            const documentResponse = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(sendBody),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const documentResponseJSON = await documentResponse.json()
            if (documentResponseJSON.success === true) {
                // const documents = getState().modal.documents
                dispatch(setDocuments([documentResponseJSON.response.data.id]))
                void dispatch(fetchDocuments(true))
            }
        } else {
            url = await uploadSingleFile(document, refID, collection)
            dispatch(setScannedData({ ...getState().modal.scannedData, failedToScan: { url } }))
        }
        return {
            ...responseJSON,
            url
        }
    })
    return await Promise.all(promises)
}
