import React, { useState } from 'react'

import { Controller } from 'react-hook-form'
import _ from 'lodash'
import customLog from '../../../functions/log'

interface CustomInlineSelectButtonProps {
    label: string
    value: string | number
    name: string
    activeID: string | number | undefined
    setActiveID: (value: string | number | undefined) => void
    defaultChecked?: boolean
    register: any
    data: any
    control: any
}

const CustomInlineSelectButton = ({ label, value, name, setActiveID, activeID, defaultChecked = false, register, data, control }: CustomInlineSelectButtonProps): JSX.Element => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    const [originallyChecked] = useState(activeID)
    const checked = activeID === value || (originallyChecked === activeID && defaultChecked)
    return <>
        <Controller
            name={data.id}
            control={control}
            // defaultValue={data.defaultValue}
            defaultValue={data.default || data.default === 'undefined' ? data.default : undefined}
            rules={data.required ? { required: `${data.content?.[0]} is required` } : {}}
            render={({ field }) => (
                <>
                    <div className={`btn btn-sm btn-outline btn-outline-default ${checked ? 'btn-bso' : ''}`}
                        onClick={() => {
                            if (inputRef.current != null) {
                                inputRef.current.checked = !(inputRef?.current?.checked)
                                setActiveID(activeID === value ? undefined : value)
                                field.onChange(activeID === value ? undefined : value)
                                // field.onChange()
                            }
                        }}
                    >
                        {label}
                    </div>
                    <input {...field} ref={inputRef} type="radio" value={value} name={name} className={'display-none'}/>
                </>
            )}
        />

    </>
}

const CustomInlineSelect = ({ register, control, data }: any): JSX.Element => {
    customLog('USE FORM', { register, control, data })
    const [activeId, setActiveId] = useState<string | number | undefined>(data.default)
    return <div className={'d-flex gap-2 w-100 overflow-x-auto mb-2'}>
        {
            _.map(data.selectors, (selector: { label: string, value: string | number }) => (
                <CustomInlineSelectButton
                    label={selector.label}
                    value={selector.value}
                    name={data.id}
                    activeID={activeId}
                    setActiveID={setActiveId}
                    defaultChecked={data.default === selector}
                    data={data}
                    control={control}
                    register={register}
                />
            ))
        }
    </div>
}

export default CustomInlineSelect
