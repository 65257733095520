import { getQueryCurrentUser } from '../../../functions/shared/api/queries/getQueryCurrentUser';
import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../../urls';
import { userSlice, type User } from '../../slices/userSlice';
import { type AppDispatch, type RootState } from '../../store';


export const userUpdateClickCount = (actionType: 'viewing' | 'offer' | 'advertise' | "viewing-agent"  | 'offer-agent') => 
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const jwt = window.localStorage.getItem(ACCESS_KEY);
        const userID = window.localStorage.getItem(USER_ID_KEY);
        const currentUser = getState().user.currentUser as User;

        try {
            let updateField;
            switch (actionType) {
                case 'viewing':
                    updateField = { ViewingClicks: (currentUser.Stats?.ViewingClicks || 0) + 1 };
                    break;
                case 'offer':
                    updateField = { OfferClicks: (currentUser.Stats?.OfferClicks || 0) + 1 };
                    break;
                case 'advertise':
                    updateField = { AdvertiseClicks: (currentUser.Stats?.AdvertiseClicks || 0) + 1 };
                    break;
                case 'viewing-agent':
                    updateField = { AgentToAgentViewingClicks: (currentUser.Stats?.AgentToAgentViewingClicks || 0) + 1 };
                    break;
                case 'offer-agent':
                    updateField = { AgentToAgentOfferClicks: (currentUser.Stats?.AgentToAgentOfferClicks || 0) + 1 };
                    break;
                default:
                    break; 
            }


            const sendObject = {
                method: 'PUT',
                collection: 'users',
                query: getQueryCurrentUser(),
                id: Number(userID),
                body: {
                    Stats: {
                        ...currentUser.Stats,
                        ...updateField,
                    },
                },
            };

            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(sendObject),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`,
                },
            });

            const dataJSON = await response.json();

            if (dataJSON.success) {
                if (!dataJSON.response.error) {
                    dispatch(userSlice.actions.userUpdate(dataJSON.response.data));
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
