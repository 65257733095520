import React from 'react'
import AgentHeader from '../AgentHeader'
import MenuItem from './MenuItem'
import Icon from '../../../app/icon/Icon'

function OrdersMenuSection (): JSX.Element {
    return (
        <>
            <div
                className="page d-flex flex-column flex-row-fluid fs-5"
                id='header_orders_section'
            >

                <AgentHeader
                    active="Orders"

                />

                <div className="content d-flex flex-column flex-fill p-0 m-4">

                    <div className="w-100 mw-450px mx-auto">
                        <div className="card shadow-sm mb-4 ">
                            <div className="card-body p-4">
                                <MenuItem
                                    description={'Purchase history'}
                                    label={'Purchases'}
                                    route={'/agent/settings/orders'}
                                    bgColor={'primary'}
                                    icon={
                                        <Icon
                                            name={'list'}
                                            className={'fs-3x mx-auto text-white'}
                                        />
                                    }
                                />
                                <MenuItem
                                    description={'Buy more credits, unlock more features'}
                                    label={'My Add-ons'}
                                    route={'/agent/settings/addOns'}
                                    bgColor={'danger'}
                                    icon={
                                        <Icon
                                            name={'database'}
                                            className={'fs-3x mx-auto text-white'}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrdersMenuSection
