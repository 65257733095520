import { type UseFormGetValues } from 'react-hook-form'
import { Upload, Image, type UploadFile } from 'antd'
import type { RcFile } from 'antd/es/upload'

import React, { useMemo, useState, useEffect } from 'react'

import { type IFile } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState, store } from '../../../store/store'
import { setImages } from '../../../store/slices/modalSlice'
import { nanoid } from 'nanoid'
import _ from 'lodash'
import Icon from '../../icon/Icon'
import CustomFormLable from '../customModalUtils/CustomFormLable'

interface CustomMultipleImagesInputProps {
    row: any
    register: any
    control: any
    errors: any
    getValues: UseFormGetValues<any>
}
const CustomMultipleImagesInput = ({ row, register, control, errors, getValues }: CustomMultipleImagesInputProps): JSX.Element => {
    const getBase64 = async (file: RcFile): Promise<string> => {
        return await new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => { resolve(reader.result as string) }
            reader.onerror = (error) => { reject(error) }
        })
    }

    const images: Array<IFile | null> = useAppSelector((state: RootState) => state.modal.images)

    const fileList: Array<UploadFile<any>> = useMemo(() => {
        return images?.map((image, index) => ({
            uid: String(index),
            name: nanoid(),
            status: 'done',
            url: image?.data
        }))
    }, [images])

    const [activeUid, setActiveUid] = useState<string | null>(null)

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (row?.defaultValue && row?.defaultValue.length > 0) {
            dispatch(setImages(_.map(row?.defaultValue, (image) => ({ data: image }))))
        }
    }, [row.defaultValue, dispatch])

    return (
        <>
            <CustomFormLable row={row} />
            <div className={'custom-multiple-images-input'}>
                <Upload
                    multiple
                    listType="picture-card"
                    itemRender={(el, file, fileList) => (
                        <div className="ant-upload-list-item overflow-hidden d-flex align-items-center justify-content-center"
                            onClick={() => {
                                setActiveUid(file.uid)
                            }}
                        >
                            <img src={file.url} width="100px" alt="" />
                        </div>
                    )}
                    onPreview={(file) => {
                        setActiveUid(file.uid)
                    }}
                    fileList={fileList}
                    // onRemove={(file) => {
                    //     const imagesCopy = _.cloneDeep(images)
                    //     imagesCopy.splice(Number(file.uid), 1)
                    //     dispatch(setImages(imagesCopy))
                    // }}
                    customRequest={(options) => {
                        void (async () => {
                            const base64img = await getBase64(options?.file as RcFile)
                            const prevImages = store.getState().modal.images
                            dispatch(setImages([...prevImages, { data: base64img }]))
                        })()
                    }}
                >
                    <div className={'btn btn-bso'}>Upload</div>
                </Upload>
            </div>
            {
                activeUid != null
                    ? <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                            visible: true,
                            onVisibleChange: (visible) => { setActiveUid(null) },
                            toolbarRender: () => (
                                <div className={'row w-100'}>
                                    <div className="col-3 d-flex flex-column align-items-center"
                                        onClick={() => {
                                            if (Number(activeUid) === images.length - 1) {
                                                setActiveUid('0')
                                            } else {
                                                setActiveUid(String(Number(activeUid) + 1))
                                            }
                                        }}
                                    >
                                        <Icon name={'arrow_circle_left'} className={'fs-3x'} />
                                        <div>Previous</div>
                                    </div>
                                    <div className="col-3 d-flex flex-column align-items-center"
                                        onClick={() => {
                                            const imagesCopy = _.cloneDeep(images)
                                            const [image] = imagesCopy.splice(Number(activeUid), 1)
                                            dispatch(setImages([image, ...imagesCopy]))
                                            setActiveUid('0')
                                        }}
                                    >
                                        <Icon name={'bookmark'} filled={activeUid === '0'} className={'fs-3x'} />
                                        <div>{
                                            activeUid === '0'
                                                ? 'First image'
                                                : 'Make first'
                                        }</div>
                                    </div>
                                    <div className="col-3 d-flex flex-column align-items-center"
                                        onClick={() => {
                                            const imagesCopy = _.cloneDeep(images)
                                            imagesCopy.splice(Number(activeUid), 1)
                                            dispatch(setImages(imagesCopy))
                                            if (imagesCopy?.length <= Number(activeUid)) {
                                                setActiveUid(null)
                                            }
                                        }}
                                    >
                                        <Icon name={'cancel'} className={'fs-3x'} />
                                        <div>Delete</div>
                                    </div>
                                    <div className="col-3 d-flex flex-column align-items-center"
                                        onClick={() => {
                                            if (Number(activeUid) === images.length - 1) {
                                                setActiveUid('0')
                                            } else {
                                                setActiveUid(String(Number(activeUid) + 1))
                                            }
                                        }}
                                    >
                                        <Icon name={'arrow_circle_right'} className={'fs-3x'} />
                                        <div>Next</div>
                                    </div>
                                </div>
                            )
                        }}
                        src={
                            String(
                                fileList.find((file) => file.uid === activeUid)?.url
                            )
                        }
                    />
                    : <></>
            }
        </>
    )
}

export default CustomMultipleImagesInput
