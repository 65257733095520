import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../../urls'
import { type AppDispatch, type RootState } from '../../store'
interface NotificationArguments {
    notificationTime: Date
    RuleID: number
    Rule: string
    User: number
    Text: string
    publishedAt: string | null
}

export const customCreateNotify = ({
    notificationTime,
    RuleID,
    Rule,
    User,
    Text,
    publishedAt
}: NotificationArguments) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    try {
        const requestForNotification = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify({
                method: 'POST',
                collection: 'user-notifies',
                body: {
                    mode: 'publish',
                    Type: 'Mobile',
                    RuleID,
                    Rule,
                    User,
                    Text
                }
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await requestForNotification.json()
    } catch (error) {
        console.error('Error:', error)
    }
}
