import React from 'react'
import { marketingCreditsImage } from '../../../../urls'
export default function AddOnServicesBricksInfo (): React.ReactElement {
    return (

        <>
            <img
                className=" w-100 h-275px object-fit-cover mb-2 rounded-0 rounded-bottom rounded-top opacity-100 bg-primary"
                src={marketingCreditsImage}
                alt={'mark_liable'}
            />
            <div className="d-flex flex-column border border-1  border-dashed border-gray-300 text-center  pb-8 mb-8 card-rounded my-4">
                <span className="fs-2 fw-bold counted text-center px-4 pt-4">Introducing Bricks: Your Key to Premium Marketing Access </span>

                <div className="separator separator-dotted border-success my-2 mx-4"></div>
                <span className="fs-2 fw-bold counted text-center px-4 py-2"> What are Bricks?</span>

                <span className="fw-semi-bold text-gray-600 fs-6 pb-1 text-justify px-4"> Think of Bricks as your digital currency within our app, designed specifically to unlock premium
                    marketing features. Each Brick is valued at 3 AED and can be easily purchased to unlock
                    exclusive marketing privileges.</span>

            </div>

            <div className="d-flex flex-column border border-1  border-dashed border-gray-300 text-center pt-5 pb-7 mb-8 card-rounded my-4">
                <div className="fs-2 fw-bold counted text-gray-800 text-start px-4 mb-2 text-center">
                    How it Works:
                </div>

                <span className="fs-2 fw-bold counted text-gray-800"> Expanded Marketing Reach:

                </span>
                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                    With Bricks, you can boost your marketing efforts by showcasing
                    properties on external platforms. This helps you reach a wider audience and attract potential
                    clients more effectively.
                </span>
                <span className="fs-2 fw-bold counted text-gray-800">     Unlock Document Access:

                </span>
                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                    Use Bricks to request property documents and gain limited
                    exclusive access to market properties on third-party portals, all with our authorization.

                </span>
                <span className="fs-2 fw-bold counted text-gray-800">  Strategic Property Selection:

                </span>
                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                    Combine Bricks with our Area Expansion Add-On to access a
                    broader range of properties. This strategic approach lets you secure exclusive marketing rights
                    for your preferred listings.

                </span>
                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                    Bricks give you the flexibility and power to enhance your marketing strategies, attract more
                    clients, and shine in the competitive real estate market. Unlock premium marketing access with
                    Bricks today!

                </span>
            </div>

        </>

    )
}
