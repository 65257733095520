import { type IAdvertise } from '../../../store/slices/advertisesSlice'

export const reduceAdvertice = (entry: Record<any, any>, isMy: IAdvertise) => {
    const reduceAdvertice = {
        id: entry.id,
        attributes: {
            createdAt: entry.createdAt,
            updatedAt: entry.updatedAt,
            Statuses: entry.Statuses,
            DigitalLockPass: entry.DigitalLockPass,
            StartDate: entry.StartDate,
            EndDate: entry.EndDate,
            CreditsAmount: entry.CreditsAmount,
            Status: entry.Status,
            Links: entry.Links,
            Name: entry.Name,
            RejectReason: entry.RejectReason,
            Notify: entry.Notify,
            Duration: entry.Duration,
            User: {
                data: {
                    id: isMy?.attributes?.User?.data?.id ?? entry?.User?.id,
                    attributes: {}
                }
            },
            Unit: {
                data: {
                    id: isMy?.attributes?.Unit?.data?.id ?? entry?.Unit?.id,
                    attributes: {}
                }
            }

        }
    }
    // console.log(reduceAdvertice, 'reduceAdvertice')
    return reduceAdvertice
}
