import { type NavigateFunction } from 'react-router-dom'
import {  USER_NAME_KEY, USER_GENDER_KEY, USER_DOB_KEY, USER_NATIONALITY_KEY, USER_DOC_EXP_KEY, USER_DOC_NUM_KEY, USER_DOC_TYP_KEY } from '../../urls'
import { swalAttentionLeave } from '../swalOptions'
import configSwals from '../../app/texts/swalTexts'
export const isShure = (navigate: NavigateFunction, str?: string): void => {
    return void swalAttentionLeave(configSwals.attentionLeave.messageForIsSureForCustomModal).then(async (result: any) => {
        if (result.isConfirmed) {
            window.localStorage.removeItem(USER_NAME_KEY)
            window.localStorage.removeItem(USER_GENDER_KEY)
            window.localStorage.removeItem(USER_DOB_KEY)
            window.localStorage.removeItem(USER_NATIONALITY_KEY)
            window.localStorage.removeItem(USER_DOC_EXP_KEY)
            window.localStorage.removeItem(USER_DOC_NUM_KEY)
            window.localStorage.removeItem(USER_DOC_TYP_KEY)

            ;(str != null) && str !== '' ? navigate(str) : navigate(-1)
        }
    }).catch((err) => { console.log(err) })
}
