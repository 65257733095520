// import { useAppSelector } from '../../../hooks/redux'
// import { type RootState } from '../../../store/store'
import _ from 'lodash'
import { cancelOffer, setOfferStatus } from '../../../store/actionsCreators/offerActions'
import { useParams } from 'react-router'
import { type AppDispatch } from '../../../store/store'
// import { useSearchParams } from 'react-router-dom'

const fillOfferModal = (modal: any, offerID: number, searchParams: URLSearchParams, dispatch: AppDispatch, navigate: any, currentOffer: any): any => {
    // const [searchParams] = useSearchParams()
    // const { currentUser } = useAppSelector((state: RootState) => state.user)
    const { objectID } = useParams()
    const offer = currentOffer// _.find(currentUser.Offers ?? [], (offerItem: any) => offerItem.id === offerID) ?? {}

    /* if (offer.Notify === 'Agent') {
        void dispatch(removeOfferNotify(offerID))
    } */

    let modalType = searchParams.get('type') ?? 'residential'
    if (modalType.includes('offer.')) {
        modalType = modalType.replace('offer.', '')
    }
    //  console.log(modalType, modal)

    modal[0].form = _.find(
        modal[0].form,
        (variant: any) => variant.id === modalType
    )?.subrow ?? []

    modal[0].footer = modal[0].footer.filter((row: any) => {
        if (row.id === 'cancel' && ['Canceled', 'Completed'].some((status) => currentOffer?.Statuses?.includes(status) === true)) {
            return false
        }
        if (row.id === 'revise' && currentOffer?.Statuses?.includes('Canceled') !== true) {
            return false
        }
        return true
    })

    for (const row of modal[0].footer) {
        switch (row.id) {
            /* case 'link':
                row.onClick = () => {
                    navigate(`/agent/modal/agent_set_offer_modal_${searchParams.get('type') ?? ''}/-/${objectID ?? -1}`)
                }
                break */
            case 'cancel':
                row.onClick = () => {
                    dispatch(cancelOffer(offerID))
                        .finally(() => {
                            // navigate(-1)
                        })
                }
                break
            case 'revise':
                row.onClick = () => {
                    void dispatch(setOfferStatus(offerID, 'In-process'))
                }
                break
        }
    }

    if (!_.includes(offer.Statuses, 'In-process')) {
        _.remove(modal[0].footer, (row: Record<string, any>) => row.id === 'link')
    }

    /* _.forEach(modal[0].form, (row: Record<string, any>) => {
        let path: string[]
        switch (row.id) {
            case 'Unit.Images':
                row.content = offer?.Unit?.Images
                break
            default:
                if (row.content !== undefined) {
                    try {
                        path = _.split(row.id, '.')
                        row.content[1] = _.reduce(
                            path,
                            (object, key) => _.isArray(object[key]) ? object?.[key][0] : object?.[key],
                            offer
                        )
                        if (_.isBoolean(row.content[1])) {
                            row.content[1] = row.content[1] ? 'Yes' : 'No'
                        }
                        if (row.content[0] === undefined) {
                            row.content[0] = row.content[1]
                        }
                    } catch (error) {
                        console.error(error)
                    }
                    break
                }
        }
    }) */
    return modal
}
export default fillOfferModal
