import React, { type ReactElement } from 'react'
import { nanoid } from 'nanoid'

interface ViewModalContactInfoControlsProps {
    controls: Array<{
        text: string
        action: () => void
        loading?: boolean
    }>
}

export default function ViewModalContactInfoControls ({ controls }: ViewModalContactInfoControlsProps): ReactElement {
    return <>
        <div className="d-flex flex-row fw-normal text-dark mb-2 gap-2">
            {
                controls.map((control) => (
                    control.loading !== true
                        ? <div className="btn btn-bso col"
                            key={nanoid()}
                            onClick={control.action}
                        >
                            {
                                control.text
                            }
                        </div>
                        : <div className={'btn btn-bso col'} key={nanoid()}>
                            <span className="indicator-progress  d-block">
                                <span className={'spinner-border spinner-border-sm align-middle'}></span>
                            </span>
                        </div>
                ))
            }
        </div>
        <div className="fv-row my-2  p-2 w-100">
            <div className="separator border border-bso border-3 px-2 my-2"/>
        </div>
    </>
}
