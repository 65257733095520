import { type JSX } from '@babel/types'
import React from 'react'

export default function Canceled ({ fontsize = '' }: { fontsize?: string | undefined }): JSX.Element {
    return (
        <div
            className={`badge badge-light-danger border border-danger ${fontsize}`}
        >
  Canceled
        </div>
    )
}
