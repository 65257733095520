import React, { useEffect, useState } from 'react'
import moment, { type Moment } from 'moment'

interface TimerProps {
    startTime?: Moment | null
    endTime?: Moment | null
    mode: 'duration' | 'count-back'
}

const Timer = ({ startTime, endTime, mode }: TimerProps): JSX.Element => {
    const getTimeString = (): string => {
        let timeMs: number = 0
        if (mode === 'duration') {
            timeMs = moment().diff(startTime ?? moment())
        } else {
            timeMs = (endTime ?? moment()).diff(moment())
        }
        const duration = moment.duration(timeMs)
        let timeString = moment(timeMs).format('hh:mm:ss')
        if (duration.months() > 0) {
            timeString = `${duration.months()}m ${duration.days()}d ` + timeString
        } else if (duration.days() > 0) {
            timeString = `${duration.days()}d ` + timeString
        }
        return timeString
    }

    const [time, setTime] = useState<string>(getTimeString())
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(getTimeString())
        }, 1000)
        return () => { clearInterval(interval) }
    }, [startTime])
    return <>{ time }</>
}

export default Timer
