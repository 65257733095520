import { type IAdvertise } from '../../../../store/slices/advertisesSlice'
import { type Unit } from '../../../../store/slices/unitsSlice'
import AdvertiseTimelineRent from './AdvertiseTimelineRent'
import AdvertiseTimelineSale from './AdvertiseTimelineSale'

interface IProps {
    advertise: IAdvertise
    unit: Unit
}



export default function AdvertiseTimeline({ advertise, unit }: IProps): JSX.Element {
    const typeUnit = unit.attributes?.Marketing?.Use

    return (
        <>
            {typeUnit === 'For rent'
                ? <AdvertiseTimelineRent advertise={advertise} unit={unit} />
                : typeUnit === 'For sale'
                    ? <AdvertiseTimelineSale advertise={advertise} unit={unit} />
                    : <div className="card shadow-sm my-4">
                        <div className="card-body p-0 m-5 text-center">
                            <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 ">Our admin&apos;s messed up, restart the app.</span>
                        </div>
                    </div>}
        </>
    )
}
