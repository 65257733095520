import React, { useEffect, useState } from 'react'
import Markdown from 'react-markdown'

interface CustomMarkdownRendererProps {
    row: any
}

export default function CustomMarkdownRenderer ({ row }: CustomMarkdownRendererProps): React.ReactElement {
    const [text, setText] = useState<string | null>(null)
    const [error, setError] = useState<string | null>(null)
    useEffect(() => {
        // const filepath = '/'.concat(rawFilePath.replace('-', '/'), '.md')
        void fetch(row.filePath)
            .then(async r => await r.text())
            .then(t => { setText(t) })
            .catch(e => { setError(e) })
    }, [])
    return (
        <Markdown>
            {
                text
            }
        </Markdown>
    )
}
