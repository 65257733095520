import { type AppDispatch } from '../store'
import _ from 'lodash'
import { ACCESS_KEY, urlAPI } from '../../urls'
import { setConfig, setConfigDBState, setMicrophoneAccessProvided, setUserDevice } from '../slices/appSlice'
import requestMicrophoneAccess from '../../functions/shared/audio/requestMicrophoneAccess'

export const fetchAppConfig = () => async (dispatch: AppDispatch) => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const bodyObject = {
            method: 'GET',
            collection: 'config'
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response.json()
        if (responseJSON.success === true) {
            dispatch(setConfig(responseJSON.response?.data?.attributes?.Selectors))
            dispatch(setConfigDBState(responseJSON.response?.data?.attributes?.DBState))
            return {
                isSuccessful: true
            }
        } else {
            return {
                isSuccessful: false
            }
        }
    } catch (err) {
        console.error(err)
        return {
            isSuccessful: false
        }
    }
}

export const getMicrophoneAccess = () => async (dispatch: AppDispatch): Promise<{ isSuccessful: boolean }> => {
    const savedAccessProvided = window.localStorage.getItem('BSO_MICROPHONE_ACCESS_PROVIDED')
    console.log(savedAccessProvided, 'savedAccessProvided')
    if (savedAccessProvided === 'true' || savedAccessProvided === 'false') {
        dispatch(setMicrophoneAccessProvided(savedAccessProvided === 'true'))
        return {
            isSuccessful: true
        }
    } else {
        const accessProvided = await requestMicrophoneAccess()
        window.localStorage.setItem('BSO_MICROPHONE_ACCESS_PROVIDED', accessProvided.toString())
        dispatch(setMicrophoneAccessProvided(accessProvided))
        return {
            isSuccessful: true
        }
    }
}

export const getUserDevice = () => async (dispatch: AppDispatch): Promise<{ isSuccessful: boolean }> => {
    const userAgent = navigator.userAgent

    const isIPhone = _.includes(userAgent.toLowerCase(), 'iphone')
    const isAndroid = _.includes(userAgent.toLowerCase(), 'android')

    if (isIPhone) {
        dispatch(setUserDevice('IPhone'))
    } if (isAndroid) {
        dispatch(setUserDevice('Android'))
    }
    return {
        isSuccessful: true
    }
}
