
import React from 'react'

import { Drawer } from 'antd'

import { type RootState } from '../../../../store/store'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { ordersHistoryButtonClosing, ordersHistoryButtonOpening } from '../../../../store/slices/modalSlice'
import { nanoid } from 'nanoid'

export default function OrdersHistoryButton ({ children, title }: any): JSX.Element {
    const { buttonOrdersHistoryOpen } = useAppSelector((state: RootState) => state.modal)

    const dispatch = useAppDispatch()
    const showDrawer = (): void => {
        // setOpen(true)
        dispatch(ordersHistoryButtonOpening())
    }

    const onClose = (): void => {
        // setOpen(false)
        dispatch(ordersHistoryButtonClosing())
    }
    return (
        <>

            <div key={nanoid()} className="btn btn-sm fs-6 border border-dark text-dark"
                onClick={showDrawer}
            >
                {title}
            </div >

            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'auto'}
                styles={{ body: { maxHeight: '80vh', marginBottom: '3px', paddingTop: '3px', marginTop: '6px', paddingLeft: '2px', paddingRight: '2px' } }}
                placement={'bottom'}
                closable={false}
                onClose={onClose}
                open={buttonOrdersHistoryOpen}
                // key={'bottom'}
                key={title}
                maskClosable={true}
            >
                <div key={nanoid()}
                    className="content d-flex flex-column flex-fill p-0"
                >
                    <div className="content d-flex flex-column flex-fill p-0">

                        <div className="card p-0 m-0">

                            <div className="w-100 mw-450px  mx-auto">
                                {children}
                            </div>

                        </div>

                    </div>
                </div>
            </Drawer>
        </>
    )
}
