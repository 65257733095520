import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { mainSwalDeal, mainSwalDoAndSkip, swalError, swalSuccess } from '../../functions/swalOptions'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'

import { authSlice } from '../../store/slices/authSlice'
import { type IResponseObjectJSON } from '../../types/userTypes/TAuth'
import { store, type RootState } from '../../store/store'
import { dataFetchingFinish, modalSlice, setDrawerOpen } from '../../store/slices/modalSlice'
import swalsConfig from '../texts/swalTexts'
import { BLOCK_OPEN_AD_AFTER_EXTEND_AREA_WITH_UNIT_ID, BLOCK_OPEN_AD_BEFORE_EXTEND_AREA, USER_ID_BACK_ID_LOAD } from '../../urls'
import { type IOrder } from '../../store/slices/userSlice'
interface IProps {
    id: string
    callback: any
    handleReset?: any
    children?: any
    initialState?: Record<string, any> | null
    fromPage?: string
    unitID?: any
    objectID?: any
    setGetValues?: any
    modal?: any
    jsonFields?: any
    modals?: any
}
const modalIdsIncludesParams = ['reschedule_viewing_modal_from_agent', 'agent_to_agent_make_offer', 'agent_to_agent_make_offer_last', 'agent_to_agent_negotiate_offer',
    'agent_set_broadcast', 'agent_set_viewing_modal', 'agent_update_viewing_modal', 'set_time_for_take_keys_modal', 'agent_advertise_create_duration', 'scan_qrcode', 'agent_update_viewing_modal_close_btn', 'agent_advertise_create_start_date', 'agent_advertise_send_form', 'agent_create_unit_property', 'agent_create_unit_options', 'agent_create_unit_marketing', 'agent_edit_unit', "save_after_draft_agent_unit", // 'agent_advertise_extend_duration',
    "profile_modal"
]
export default function CustomValidatorHook({ id, callback, fromPage, children, unitID, objectID, initialState, handleReset, setGetValues, modal: storedModal, modals }: IProps): JSX.Element {
    const formRef = useRef(null)
    const reraLoaded = useAppSelector(
        (state: RootState) => state.agentStates.documentsLoaded.licenseLoaded
    )
    const maxAttemptsScan = useAppSelector(
        (state: RootState) => state.documents.maxAttemptsScan
    )

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [formKey, setFormKey] = useState(0)
    const newProperty = useAppSelector((state) => state.property.newPropertyFields)

    const modal = storedModal != null ? storedModal : modals?.filter((modal: any) => modal.id === id)
    const filteredElements = modal?.[0]?.form?.filter((item: any) => {
        return (Boolean(item?.element?.includes('input'))) || item?.element?.includes('select')
    })

    const inputElementsState = filteredElements?.reduce((acc: any, curr: any) => {
        acc[curr.id] = undefined
        return acc
    }, {})

    const { getValues, register, setValue, trigger, handleSubmit, control, formState: { errors, isSubmitted, isSubmitting, isSubmitSuccessful }, watch, reset, clearErrors, setError } = useForm({
        mode: 'onChange',
        defaultValues: inputElementsState
    })
    const password = watch('password');





    useEffect(() => {
        reset(initialState != null ? initialState : inputElementsState) // Сбросить значения формы при изменении id
        setFormKey((prev) => prev + 1) // Обновить ключ формы
    }, [id, initialState, newProperty])





    let passwordConfirm: any
    if (id === 'register2' || id === 'reset_password_final_email' || id === 'reset_password_final' || id === 'my_profile_reset_password_final_phone' || id === 'my_profile_reset_password_final_email') {
        passwordConfirm = watch('password-confirm')
        if (password !== passwordConfirm) {
            errors['password-confirm'] = { type: 'manual', message: 'Passwords do not match' }
        }
    }

    function shouldShowMainSwalDeal(id: string, reraLoaded: boolean): boolean {
        return (
            (id === 'update_emirates_back_together' || id === 'update_passport_together' || id === 'agent_settings' || id === 'agent_settings_for_subscriptions') &&
            !reraLoaded
        )
    }

    function showMainSwalDeal(): void {
        void mainSwalDeal(swalsConfig.mainDeal.textCustomValidatorHook(id), swalsConfig.mainDeal.titleCustomValidatorHook, swalsConfig.mainDeal.confirmButtonCustomValidatorHook, swalsConfig.mainDeal.denyButtonCustomValidatorHook).then(async (result: any) => {
            if (result.isConfirmed) {
                navigate('/agent/modal/update_rera_deal/not/not')
            } else if (result.isDenied) {
                navigate('/agent/units/nav')
            }
        })
    }

    const onSubmit = async (data: any): Promise<void> => {



        if (['register2', 'reset_password_final_email', 'reset_password_final', 'my_profile_reset_password_final_phone', 'my_profile_reset_password_final_email'].includes(id)) {
            if (password !== passwordConfirm) {
                setError('password-confirm', {
                    type: 'manual',
                    message: 'Passwords do not match',
                });
                return
            } else {
                clearErrors('password-confirm');
            }
        }


        if (callback !== null) {
            if (modalIdsIncludesParams.includes(id)) {
                data.unitID = unitID
                data.viewID = objectID
                data.objectID = objectID
            }

            if (id === 'register_scan_emirates' || id === 'register_scan_emirates_front' || id === 'register_scan_passport' || id === 'update_emirates_front_together' || id === 'update_emirates_back_together' || id === 'update_passport_together' || id === 'update_passport' || id === 'update_emirates_front' || id === 'update_rera') {
                data.type_document_page = id
            }

            if (id === 'update_emirates_front') {
                data.redirect = '/agent/modal/update_emirates_back/not/not'
                window.localStorage.setItem(USER_ID_BACK_ID_LOAD, 'true')
            }
            if (id === 'update_emirates_front_together') {
                data.redirect = '/agent/modal/update_emirates_back/INagentMODULE/to_choose_subscription'
            }
            if (id === 'agent_settings_for_subscriptions') {
                data.chooseSubscription = objectID
                data.modalID = id
            }
            if (id === 'update_rera') {
                data.redirect = '/agent/profile/me'
            }
            if (id === 'update_passport' || id === 'update_emirates_back') {
                data.redirect = '/agent/settings'
            }
            if (id === 'update_emirates_back_together' || id === 'update_passport_together' || id === 'update_passport' || id === 'update_emirates_back' || id === 'update_rera' || id === 'update_rera_deal' || id === "update_emirates_front") {
                data.isLoadedDocumentFromSettings = true
                window.localStorage.setItem(USER_ID_BACK_ID_LOAD, 'false')
                if (objectID === 'to_choose_subscription') {
                    const ServiceAgentSubscription = (store.getState().user.currentUser.Orders as IOrder[])?.filter(
                        (order: IOrder) => order.Service.some((service) => service.__component === 'services.broker-subscription')
                    )
                    if (ServiceAgentSubscription && ServiceAgentSubscription.length > 0) {
                        data.redirect = '/agent/units/nav'
                    } else {
                        data.redirect = '/agent/settings/subscriptions/choose'
                    }
                }
                if (id === 'update_rera_deal') {
                    data.redirect = '/agent/units/nav'
                }
            } if (id === 'agent_settings_for_areas_extension') {
                if (unitID !== 'addons') {
                    if (window.localStorage.getItem(BLOCK_OPEN_AD_AFTER_EXTEND_AREA_WITH_UNIT_ID)) {
                        data.redirect = '/agent/units/nav'
                        window.localStorage.setItem(BLOCK_OPEN_AD_BEFORE_EXTEND_AREA, "true")
                    } else {
                        data.redirect = `/agent/modal/agent_advertise_create_duration/${unitID}/${objectID}`
                    }

                } else {
                    data.redirect = '/agent/profile/me'
                }
            }

            const json: IResponseObjectJSON = await dispatch(callback(data))

            if ((json as any)?.ignoreSubmit === true) {
                return
            }


            if ((json as any)?.maxAttemptsWarning === true && maxAttemptsScan > 2) {


                await mainSwalDeal(json.textNotification, "Attention!", 'Request Manual Review', 'Try again').then(async (result: any) => {
                    if (result.isConfirmed) {
                        dispatch(authSlice.actions.authFetching(false))
                        dispatch(dataFetchingFinish())
                        navigate(json.textNavigate)
                    }
                    dispatch(authSlice.actions.authFetching(false))
                    dispatch(dataFetchingFinish())
                }); return;

            }





            if (json?.isSuccessful) {
                dispatch(authSlice.actions.authFetching(false))
                dispatch(dataFetchingFinish())

                if (json.textNotification !== '') {
                    if (json.swalSpecialRequest) {
                        await mainSwalDoAndSkip(json.title as string, json.textNotification, 'Call to BSO', 'Try it later').then(async (result: any) => {
                            if (result.isConfirmed) {
                                window.location.href = "tel:+971526477228";
                                navigate(json.textNavigate)
                            } else {
                                navigate(json.textNavigate)
                            }
                        }); return;

                    }

                    void swalSuccess(json.textNotification, () => {
                        dispatch(setDrawerOpen(undefined))
                        navigate(json.textNavigate)
                        if (shouldShowMainSwalDeal(id, reraLoaded) && objectID !== 'to_choose_subscription') {
                            showMainSwalDeal()
                        }
                    }, json?.title, json?.icon)
                } else {
                    navigate(json.textNavigate)
                    const currentForm = formRef.current as HTMLFormElement | null

                    if (id === 'login' || id === 'register2') {
                        if (currentForm != null) {
                            const emailInput = currentForm.querySelector('input[name="email"]')
                            if (emailInput != null) {
                                emailInput.setAttribute('email', 'username')
                                currentForm.submit()
                            }
                        }
                    }
                    if (shouldShowMainSwalDeal(id, reraLoaded) && objectID !== 'to_choose_subscription') {
                        showMainSwalDeal()
                    }
                }
            } else {
                void swalError(json?.textNotification, () => {
                    dispatch(authSlice.actions.authFetching(false))
                    navigate(json?.textNavigate)
                })
            }
        }
    }

    return (
        <form ref={formRef} key={formKey} name={id} id={id} className="form" encType='multipart/form-data' autoComplete="on" onSubmit={handleSubmit(onSubmit)} onReset={handleReset != null ? handleReset : () => { }}>
            {React.Children.map(children, (child) =>
                React.cloneElement(child, { register, control, filteredElements, handleSubmit, onSubmit, isSubmitted, errors, password, passwordConfirm, clearErrors, setValue, trigger, getValues, setError })
            )}
        </form>
    )
}
