/* import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'

import { DatePicker } from 'antd'

import { Controller, useForm } from 'react-hook-form'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { getCurrentDubaiDate, getCurrentDubaiDateString } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { unitReservations } from '../../../functions/agent/date/isReservation'

export default function PickUpKeysTimeSelect ({ id, modal, unitID, row, control, viewingID, register, errors, clearErrors, setValue, trigger }: any): JSX.Element {
    const dateChooseViewing = useAppSelector((state: RootState) => state.modal.dateChooseViewing) as Date
    const currentUnit = useAppSelector(
        (state: RootState) => state.units.units
    )?.filter((el: any) => el.id === Number(unitID))[0]
    const currentTime = getCurrentDubaiDate()
    const choosingTime = new Date(dateChooseViewing)
    // console.log(currentTime, '<=======currentTime choosingTime=======> ', choosingTime)
    // console.log(unitReservations(currentUnit, dateChooseViewing))

    const minutsCorrectStart = 1
    const minutsCorrectEnd = 31

    let hoursCorrectStart = dayjs(dateChooseViewing).format('YYYY-MM-DD').toString() === getCurrentDubaiDateString().toString() ? dayjs(currentTime).hour() < dayjs(dateChooseViewing).hour() ? dayjs(currentTime).hour() + 1 : dayjs(dateChooseViewing).hour() : 9

    if (dayjs(dateChooseViewing).format('YYYY-MM-DD').toString() === getCurrentDubaiDateString().toString()) {
        // today
    } else {
        // not today
    }

    if (row.id === 'pick-out-date' || row.id === 'DropOutDate') {
        hoursCorrectStart = dayjs(dateChooseViewing).format('YYYY-MM-DD').toString() === getCurrentDubaiDateString().toString() ? dayjs(dateChooseViewing).hour() + 1 : 9
        //   minutsCorrect = ''
    }

    const [disableTime, setDisableTime] = useState({ mins: 31, start: hoursCorrectStart, end: 17 }) // dayjs(dateChooseViewing).hour() - 1
    const [selectedDate, setSelectedDate] = useState(dayjs(dateChooseViewing))

    // const [tomorrow, setTomorrow] = useState(false)
    const rangeMinuts = (start: number, end: number) => {
        const result = []
        for (let i = 10; i < 55; i += 10) {
            result.push(i)
        }
        return result
    }

    //  console.log(rangeMinuts())

    const range = (start: number, end: number) => {
        const result = []
        for (let i = start; i < end; i++) {
            result.push(i)
        }
        return result
    }

    // eslint-disable-next-line arrow-body-style
    const disabledDate = (current: any) => {
        const choosingDateViewingDAYJS = dayjs(dateChooseViewing)
        const secondDateAfterChoosing = choosingDateViewingDAYJS.add(1, 'day')
        const currentDate = dayjs()
        //  console.log(currentDate, 'currentDate')
        // console.log(choosingDateViewingDAYJS, 'choosingDateViewingDAYJS.day()')

        if (choosingDateViewingDAYJS.hour() >= 18 && current.isSame(choosingDateViewingDAYJS, 'day')) {
            return true // Если сегодня больше 18:00, то разрешить выбирать только завтра
        }

        if (current.day() === 0 || current.day() === 6) {
            return true
        }

        if (choosingDateViewingDAYJS.day() === 6 || choosingDateViewingDAYJS.day() === 0 || choosingDateViewingDAYJS.day() === 5) {
            if (choosingDateViewingDAYJS.day() === 0) {
                if (current.isBefore(choosingDateViewingDAYJS.subtract(1, 'day').startOf('day')) || current.isAfter(secondDateAfterChoosing.add(1, 'day').endOf('day'))) {
                    return true // Запретить выбор дней до сегодняшнего + 2 и после завтрашнего
                }
            } if (choosingDateViewingDAYJS.day() === 6) {
                if (current.isBefore(choosingDateViewingDAYJS.subtract(1, 'day').startOf('day')) || current.isAfter(secondDateAfterChoosing.add(1, 'day').endOf('day'))) {
                    return true // Запретить выбор дней до сегодняшнего + 1 и после послезавтрашнего
                }
            } if (choosingDateViewingDAYJS.day() === 5) {
                if (current.isBefore(choosingDateViewingDAYJS.startOf('day')) || current.isAfter(secondDateAfterChoosing.add(2, 'day').endOf('day'))) {
                    return true // Запретить выбор дней кроме пятницы и понедельника
                }
            }
        } else {
            if (current.isBefore(choosingDateViewingDAYJS.startOf('day')) || current.isAfter(secondDateAfterChoosing.endOf('day'))) {
                return true // Запретить выбор дней до сегодняшнего и после завтрашнего
            }
        }
        return false
    }

    return (

        <Controller
            name={row.id}
            control={control}
            defaultValue={row.defaultValue ? dayjs(row.defaultValue) : null} // Установка defaultValue в поле контроллера
            rules={row.required ? { required: `${row.content?.[0]} is required` } : {}}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (

                <DatePicker

                    disabledDate={disabledDate}
                    popupClassName="full-screen-time-picker"
                    placeholder={row.content && row.content[1]}
                    size="large"
                    className="d-block"
                    inputReadOnly
                    name={name}
                    ref={ref}
                    format={'YYYY/MM/DD HH:mm'}
                    onChange={(date, dateString) => {
                        onChange(date) // Обновление значения поля в контролируемом компоненте DatePicker
                    }}

                    onSelect={(date) => {
                        const selected = dayjs(date)
                        //    console.log(selected)
                        const dateChooseView = dayjs(dateChooseViewing)
                        const tomorrowDate = dateChooseView.add(1, 'day')
                        if (selected.isSame(dateChooseView, 'day')) {
                            // Если выбрана текущая дата, установить disableTime равным текущему часу
                            setDisableTime({ mins: 31, start: hoursCorrectStart, end: 17 })
                        } else if (selected.isSame(tomorrowDate, 'day')) {
                            //     // Если выбрана завтрашняя дата, установить disableTime равным 10
                            setDisableTime({ mins: 1, start: 9, end: 9 })
                        } else {
                            //  setDisableTime(0) // В остальных случаях сбросить disableTime
                            setDisableTime({ mins: 1, start: 9, end: 9 })
                        }
                    }}
                    showTime={{
                        hideDisabledOptions: true,
                        disabledHours: () => range(0, 24).filter(hour => hour < disableTime.start || hour > disableTime.end),
                        disabledMinutes: () => range(disableTime.mins, 60),
                        format: 'HH:mm'
                    }}
                    showNow={false}

                />

            )}
        />

    )
} */
import React, { useEffect, useMemo, useState } from 'react'

import dayjs from 'dayjs'

import { DatePicker } from 'antd'

import { Controller, useForm } from 'react-hook-form'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { getCurrentDubaiDate, getCurrentDubaiDateString } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { unitReservations } from '../../../functions/agent/date/isReservation'
import { type IViewing } from '../../../types'

export default function PickUpKeysTimeSelect ({ id, modal, unitID, objectID, row, control, viewingID, register, errors, clearErrors, setValue, trigger }: any): JSX.Element {
    const dateChooseViewing = useAppSelector((state: RootState) => state.modal.dateChooseViewing) as Date
    const viewingDateTime = dayjs(dateChooseViewing)
    const currentTime = getCurrentDubaiDate()
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(viewingDateTime)
    const isPickUp = row.id === 'PickUpDate'

    const units = useAppSelector((state) => state.units.units)
    const allViewings = useAppSelector((state) => state.viewings.viewings)

    const getKeySpans = (): { keysAmount: number, keyDateSpans: dayjs.Dayjs[][] } => {
        const unit = units.find(unitItem => unitItem.id === Number(unitID))
        const keysAmount = unit?.attributes.KeysAmount ?? 1 // TODO fill keys amounts in DB for existing units
        const viewings = allViewings
            .filter((viewing) => unit?.attributes?.Viewings?.data
                .some((viewingItem: IViewing) => viewingItem.id === viewing.id)
            )
        const keyDateSpans: dayjs.Dayjs[][] = viewings
            .map(viewing => {
                const key = viewing?.attributes?.KeyBooking?.data?.attributes
                return [dayjs(key?.PickUpDate), dayjs(key?.DropOutDate)]
            })
            .filter(el => el.every(Boolean))
        return {
            keysAmount,
            keyDateSpans
        }
    }

    const { keysAmount, keyDateSpans } = useMemo(getKeySpans, [units, allViewings])

    const getFreeKeyHours = (timeRange: number[], rawDate: dayjs.Dayjs): number[] => {
        const date = dayjs(rawDate.set('hour', 0).set('minute', 0))
        const sameDayKeys = keyDateSpans.filter(([pickUp, dropOut]) =>
            (date.isSame(pickUp, 'day') || date.isAfter(pickUp)) &&
            (date.isSame(dropOut, 'day') || date.isBefore(dropOut, 'day'))
        )

        return timeRange.filter((hour) => {
            const keysTaken = sameDayKeys.reduce(
                (accumulator, key) => {
                    if (key[0].hour() - 1 < hour && key[1].hour() > hour - 1) {
                        return accumulator + 1
                    }
                    return accumulator
                },
                0
            )
            return keysTaken < keysAmount
        })
    }

    const range = (start: number, end: number): number[] => {
        const result = []
        for (let i = start; i < end; i++) {
            result.push(i)
        }
        return result
    }

    const getEnabledHours = (date: dayjs.Dayjs): number[] => {
        if (isPickUp) {
            if (date.isSame(viewingDateTime, 'day')) {
                return getFreeKeyHours(range(
                    date.isSame(dayjs(), 'day') ? Math.max(dayjs().hour(), 9) : 9,
                    Math.min(
                        viewingDateTime.minute() >= 30 ? viewingDateTime.hour() + 1 : viewingDateTime.hour()
                        , 19
                    )
                ),
                date
                )
            } else {
                return [18]
            }
        } else {
            if (date.isSame(viewingDateTime, 'day')) {
                return range(
                    date.isSame(dayjs(), 'day') ? Math.max(viewingDateTime.hour(), 9, dayjs().hour()) : Math.max(viewingDateTime.hour(), 9),
                    19
                )
            } else {
                return [9]
            }
        }
    }

    const getDisabledHours = (): number[] => {
        const enabledHours = getEnabledHours(selectedDate)
        return range(0, 24).filter(hour => !enabledHours.includes(hour))
    }

    const getEnabledMinutes = (): number[] => {
        if (isPickUp) {
            if (selectedDate.isSame(viewingDateTime, 'day') && selectedDate.hour() !== 18) {
                return range(0, 60).filter(minute => minute % 30 === 0)
            } else {
                return [0]
            }
        } else {
            if (selectedDate.isSame(viewingDateTime, 'day') && selectedDate.hour() !== 18) {
                return range(0, 60).filter(minute => minute % 30 === 0)
            } else {
                return [0]
            }
        }
    }

    const getDisabledMinutes = (): number[] => {
        const enabledMinutes = getEnabledMinutes()
        return range(0, 60).filter(minute => !enabledMinutes.includes(minute))
    }

    const checkWorkingHours = (): 'In' | 'Before' | 'After' => {
        const workingHours = [9, 18]
        const viewingHour = viewingDateTime.hour()
        if (viewingHour < workingHours[0]) {
            return 'Before'
        } else if (workingHours[1] <= viewingHour) {
            return 'After'
        } else {
            return 'In'
        }
    }

    const workingHours = useMemo(checkWorkingHours, [viewingDateTime])

    return (

        <Controller
            name={row.id}
            control={control}
            // defaultValue={dayjs(dateChooseViewing)}
            rules={row.required === true ? { required: `${String(row.content?.[0])} is required` } : {}}
            render={({ field: { onChange, onBlur, value, name, ref } }) => {
                return (
                    <DatePicker
                        disabledDate={(rawDate: dayjs.Dayjs) => {
                            const date = dayjs(rawDate.set('hour', 0).set('minute', 0))
                            if (getEnabledHours(date).length === 0) { // Disable day if there are no keys left for any free time
                                return true
                            }
                            if ([0, 6].includes(date.day()) || date.isBefore(currentTime, 'day')) { // Disable Saturdays, Sundays and past dates
                                return true
                            }
                            if (isPickUp) {
                                const hasKeyInMorning = getEnabledHours(viewingDateTime).includes(9)
                                const conditions = [
                                    date.isSame(viewingDateTime, 'day') && workingHours !== 'Before', // The day of viewing
                                    date.diff(viewingDateTime, 'day') === -1 && hasKeyInMorning, // The day before viewing
                                    viewingDateTime.day() === 1 && date.diff(viewingDateTime, 'day') === -3 && hasKeyInMorning, // Friday if viewing is on monday
                                    viewingDateTime.day() === 0 && date.diff(viewingDateTime, 'day') === -2 && hasKeyInMorning, // Friday if viewing is on sunday
                                    viewingDateTime.day() === 5 && date.diff(viewingDateTime, 'day') === -1 && hasKeyInMorning // Friday if viewing is on saturday
                                ]
                                return !conditions.some(Boolean)
                            } else {
                                const conditions = [
                                    date.isSame(viewingDateTime, 'day') && workingHours !== 'After', // The day of viewing
                                    date.diff(viewingDateTime, 'day') === 0 && date.isAfter(viewingDateTime, 'day'), // The next day
                                    viewingDateTime.day() === 5 && date.diff(viewingDateTime, 'day') === 2, // Monday if viewing is on friday
                                    viewingDateTime.day() === 6 && date.diff(viewingDateTime, 'day') === 1, // Monday if viewing is on saturday
                                    viewingDateTime.day() === 0 && date.diff(viewingDateTime, 'day') === 0 && date.isAfter(viewingDateTime) // Monday if viewing is on sunday
                                ]
                                return !conditions.some(Boolean)
                            }
                        }}
                        popupClassName="full-screen-time-picker"
                        placeholder={row.content?.[1]}
                        size="large"
                        className="pick-up-keys-picker d-block"
                        inputReadOnly
                        name={name}
                        ref={ref}
                        format={'YYYY/MM/DD HH:mm'}

                        onChange ={(rawDate: any) => {
                            const date = dayjs(rawDate.set('hour', 0).set('minute', 0))
                            //      console.log(date.diff(viewingDateTime, 'day'))
                            setSelectedDate(rawDate)
                        }}
                        showTime={{
                            hideDisabledOptions: true,
                            disabledHours: getDisabledHours,
                            disabledMinutes: getDisabledMinutes,
                            format: 'HH:mm'
                        }}
                        showNow={false}
                    />

                )
            }}
        />

    )
}
