import React from 'react'
import { Link } from 'react-router-dom'
import { type AuthState } from '../../../../store/slices/authSlice'
import { useAppSelector } from '../../../../hooks/redux'
import { type IChatListItem } from '../../../../types'
import moment from 'moment'
import _ from 'lodash'
import { type RootState } from '../../../../store/store'
import ChatUtils from '../../../../functions/chats/ChatUtils'
import Avatar from '../../elements/Avatar'
import Icon from '../../../../app/icon/Icon'
import { bso_logo_512 } from '../../../../urls'

interface ChartCardProps {

    chat: IChatListItem
    blocked: boolean
    favorite: boolean
    isAdmin?: boolean
}

const ChatCard = ({ chat, blocked = false, favorite = false, isAdmin = false }: ChartCardProps): JSX.Element => {

    const { userCATEGORY }: AuthState = useAppSelector((state) => state.auth)
    const { currentUser } = useAppSelector((state: RootState) => state.user)

    // const unread = _.reduce(Object.keys(chat.Unread ?? {}), (sum, key) => {
    //     if (+key !== currentUser.id) {
    //         return sum + (chat?.Unread?.[key] ?? 0)
    //     } else {
    //         return sum
    //     }
    // }, 0)
    const unread = chat?.Unread?.[String(currentUser.id)] ?? 0
    return (
        <>
            <Link to={`/${userCATEGORY?.toLowerCase() ?? ''}/chats/${chat.id}`}

            >

                <div className={'w-100 d-flex flex-row gap-4 p-4 bg-white'}>

                    <Avatar image={chat?.Photo} size={'40px'} isAdmin={isAdmin} />

                    <div style={{ maxWidth: 'calc(100% - 30px - 2rem)' }}
                        className={'d-flex flex-column justify-content-between w-100'}>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <div className={'text-dark'}>
                                {
                                    ChatUtils.isBSOBroadcast(chat)
                                        ? 'BSO Broadcast'
                                        : ChatUtils.isBSOSupport(chat)
                                            ? 'BSO Support'
                                            : chat.Name
                                }
                                {
                                    (unread > 0) &&
                                    (
                                        <div className={'badge badge-light-dark ms-2'}
                                            style={{ background: '#FFF200' }}
                                        >
                                            {unread}
                                        </div>
                                    )
                                }
                            </div>
                            {
                                favorite
                                    ? <Icon name={'bookmark'} filled={true} className={'fs-1 text-muted'} />
                                    : <></>
                            }
                        </div>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            {
                                blocked
                                    ? <div className={'text-danger'}>Blocked</div>
                                    : !ChatUtils.hasLastMessageDisappeared(chat)
                                        ? <div className={'text-truncate text-gray-600 me-2'}>
                                            {chat.LastMessage?.Text != null && chat.LastMessage?.Text?.length > 0
                                                ? (
                                                    chat.LastMessage?.Text === 'type:voice'
                                                        ? 'Voice message'
                                                        : chat.LastMessage?.Text === 'type:file'
                                                            ? 'File'
                                                            : chat.LastMessage?.Text === 'type:unit'
                                                                ? 'Unit'
                                                                : chat.LastMessage?.Text === 'type:audio'
                                                                    ? 'Audio'
                                                                    : chat.LastMessage?.Text === 'type:video'
                                                                        ? 'Video'
                                                                        : chat.LastMessage?.Text === 'type:close-issue'
                                                                            ? 'Issue closed'
                                                                            : chat?.LastMessage?.Text?.includes('type:open-issue')
                                                                                ? 'Issue open'
                                                                                : chat.LastMessage?.Text
                                                )
                                                : <div className={'text-gray-600'}>No messages yet</div>

                                            }
                                        </div>
                                        : <div className={'text-gray-600'}>No messages yet</div>
                                // <div className={'text-gray-600'}>No messages yet</div>
                            }
                            {
                                !ChatUtils.hasLastMessageDisappeared(chat) && chat.LastMessage?.updatedAt !== '' && _.isDate(new Date(chat.LastMessage?.updatedAt ?? ''))
                                    ? <div className={'text-gray-600 text-nowrap'}>
                                        {
                                            /* moment(chat.LastMessage?.updatedAt).format(' HH:mm') */
                                            moment(chat.LastMessage?.updatedAt).fromNow()
                                        }
                                    </div>
                                    : <></>
                            }
                        </div>
                    </div>
                </div>
                {(<div className={`separator border-gray-300 ${'ms-20'}`} />)}
            </Link>
        </>
    )
}

export default ChatCard
