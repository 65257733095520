
import React, { useEffect } from 'react'

import { DatePicker } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { isEighteen } from '../../../functions/agent/date/isEighteen'
import dayjs from 'dayjs'
interface MyDataPickerProps {
    register: any
    row: any
    control: any
    clearErrors: any
}
export default function DataPickerForAuth({
    // register,
    row,
    control
    //  clearErrors
}: MyDataPickerProps): JSX.Element {
    //  setError,
    const { clearErrors: clearFormErrors, formState } = useForm()

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            clearFormErrors() // Очистка ошибок при успешной отправке формы
        }
    }, [formState.isSubmitSuccessful])

    const validateDatePicker = (value: string) => {
        if (!value) {
            return `${row.content?.[0]} is required`
        }
        return isEighteen(value) || 'You must be 18 years or older'
    }

    return (
        <Controller
            name={row.id}
            control={control}
            defaultValue={row.defaultValue ? dayjs(row.defaultValue) : null} // Установка defaultValue в поле контроллера
            rules={{
                validate: validateDatePicker
            }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <DatePicker
                    onFocus={(e) => {
                        e.target.blur()
                    }}
                    popupClassName="full-screen"
                    placeholder={row.content && row.content[1]}
                    size="large"
                    className="d-block"
                    name={name}
                    ref={ref}
                    onChange={(date, dateString) => {
                        onChange(date) // Обновление значения поля в контролируемом компоненте DatePicker
                    }}

                    onBlur={onBlur}
                    value={value ? dayjs(value) : null} // Установка значения датапикера из контроллера
                />
            )}
        />
    )
}

// export default function MyDataPicker ({
//     register,
//     row,
//     control
// }: MyDataPickerProps): JSX.Element {
//     return (
//         <Controller
//             name={row.id}
//             control={control}
//             rules={{
//                 required: `${row.content?.[0]} is required`,
//                 validate: {
//                     eighteenOrOlder: (value) => isEighteen(value) || 'You must be 18 years or older'
//                 }
//             }}
//             render={({ field }) => (
//                 <DatePicker
//                     placeholder={row.content && row.content[1]}
//                     className="form-select form-select-solid form-select-sm"
//                     {...field}
//                     name={row.id}
//                 />
//             )}
//         />
//     )
// }
