import { getQueryDocuments } from '../../../functions/shared/api/queries/getQueryDocuments'
import { ACCESS_KEY, urlAPI } from '../../../urls'

export const archiveDocument = async (id: number): Promise<any> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

    const sendObject = {
        method: 'PUT',
        collection: 'documents',
        id: Number(id),
        query: getQueryDocuments(),
        body: {
            Statuses: ['Archived']
        }

    }

    const response = await fetch(`${urlAPI}`, {
        method: 'POST',
        body: JSON.stringify(sendObject),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`
        }
    })
    const json = await response.json()

    return json
}
