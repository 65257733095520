import React from 'react'
import { nanoid } from 'nanoid'
import ProfileCard from './profile-card'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { store, type RootState } from '../../../../store/store'
import _ from 'lodash'
import { type User } from '../../../../store/slices/userSlice'
import { toggleOpenKey } from '../../../../store/slices/areasSlice'

interface AreaCardProps {
    area: string
    users: User[]
}

const AreaCard = ({ area, users }: AreaCardProps): JSX.Element => {
    const dispatch = useAppDispatch()
    const openAreas = useAppSelector((state: RootState) => state.areas.openKeys)
    const open = _.includes(openAreas, area)
    return (
        <div className={''}>
            <div className="card shadow-sm my-1 position-relative">
                <div className="card-body p-0 m-5 text-center"
                    onClick={() => {
                        dispatch(toggleOpenKey(area))
                        // setOpen((prev) => !prev)
                    }}
                >
                    <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3">
                        {area}
                    </span>
                </div>
                <div className={'badge badge-sm position-absolute top-0 end-0 bg-bso m-2'}>
                    {users?.length ?? 0}
                </div>
            </div>
            <div className={`mb-1 mt-2 px-4 d-flex flex-column gap-2 ${!open ? 'invisible position-absolute' : ''} `}>
                {
                    users?.length > 0
                        ? _.map(users,
                            (user) => (
                                <ProfileCard
                                    user={user }
                                    key={nanoid()}
                                />
                            )
                        )
                        : <div className="card shadow-sm my-1">
                            <div className="card-body p-0 m-5 text-center"><span
                                className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 text-gray-600">No agents working in this area</span></div>
                        </div>

                }
            </div>

        </div>
    )
}

export default AreaCard
