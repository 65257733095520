import React from 'react'
import { useNavigate } from 'react-router-dom'
import TermsContent from './TermsContent'
import CloseIconBasic from '../elements/CloseIconBasic'

export default function TermsAndConditions ({ onCancel }: any): JSX.Element {
    const navigate = useNavigate()
    return (
        <div className="container position-relative p-lg-20 p-md-15 p-sm-10 p-5 ">
            <CloseIconBasic onCancel={onCancel} />
            <TermsContent/>

        </div>
    )
}
