export const isInLastMonth = (date: string): boolean => {
    const dateView = new Date(date)
    const today = new Date()
    const oneMonthAgo = new Date(today)
    oneMonthAgo.setMonth(today.getMonth() - 1)

    return (
        dateView >= oneMonthAgo &&
        dateView < today
    )
}
