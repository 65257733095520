import { nanoid } from 'nanoid'
import React, { useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { type Unit } from '../../../../store/slices/unitsSlice'
import Icon from '../../../../app/icon/Icon'
import { useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'

export default function SharedUnitList({ units }: any): JSX.Element {
    const currentUser = useAppSelector((state: RootState) => state.user).currentUser

    const scrollContainerRef = useRef<HTMLDivElement>(null)

    const saveScrollPosition = () => {
        if (scrollContainerRef.current != null) {
            localStorage.setItem('scrollPositionUnitsInAgentProfile', scrollContainerRef.current.scrollLeft.toString())
        }
    }

    const restoreScrollPosition = () => {
        const savedPosition = localStorage.getItem('scrollPositionUnitsInAgentProfile')
        if ((scrollContainerRef.current != null) && savedPosition) {
            scrollContainerRef.current.scrollLeft = parseInt(savedPosition, 10)
        }
    }

    const handleScroll = () => {
        saveScrollPosition()
    }

    useEffect(() => {
        restoreScrollPosition()
        if (scrollContainerRef.current != null) {
            scrollContainerRef.current.addEventListener('scroll', handleScroll)
        }

        return () => {
            saveScrollPosition()

            if (scrollContainerRef.current != null) {
                scrollContainerRef.current.removeEventListener('scroll', handleScroll)
            }
        }
    }, [])
    const defaultImage = '/assets/media/istockphoto.jpg'
    return (

        units.length > 0
            ? <div className={'row g-0'}>

                {<div
                    ref={scrollContainerRef}
                    style={{ overflowX: 'auto' }}
                    className=" slides-manual d-flex flex-row gap-4 w-100 scrollbar-width overflow-auto pe-4 mb-4">
                    {units && units.length > 0
                        ? units?.map((_unit: any) => {
                            //         console.log(_unit, '_unit')
                            const isItMine = _unit?.attributes?.User?.data?.id === currentUser.id

                            return (<>
                                <div key={nanoid()} className='position-relative'
                                >
                                    <Link
                                        to={`/agent/modalView/unit_modal/${_unit?.attributes?.id}/not/${isItMine ? 'agent_s_unit' : 'other_agent_s_unit'}`}
                                        className="text-decoration-none"
                                    >
                                        <div className={'w-100 h-100 position-absolute z-index-2 rounded rounded-2 '} style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', top: 0 }}>
                                            <span className="ps-4 fw-semi-bold me-2 fs-2x lh-1 text-white position-absolute" style={{ bottom: '70px', left: '5px' }}>
                                                {_unit?.attributes?.Marketing?.Headline}
                                            </span>
                                            <div className={'text-white  ps-4'} >
                                                <span className="d-block position-absolute fs-1 fw-semi-bold ms-4" style={{ bottom: '27px', left: '5px' }} >
                                                    AED {_unit?.attributes?.Marketing?.Price}
                                                </span>
                                                <span className="text-end position-absolute d-block pe-4 fs-6 fw-semi-bold " style={{ bottom: '14px', right: '4px' }} >
                                                    <Icon name={'location_on'} filled className={'fs-6 text-white'} />
                                                    {_unit?.attributes?.Property?.data?.attributes?.Area ?? _unit?.attributes?.Property?.Area ?? ''}
                                                </span>
                                            </div>
                                        </div>
                                        {_unit?.attributes?.Images && ((_unit?.attributes?.Images?.[0]).length > 0)
                                            ? <img
                                                className='w-250px h-150px object-fit-cover rounded rounded-2  opacity-100'
                                                src={_unit?.attributes?.Images?.[0]}
                                                alt={'img_unit'}
                                            />
                                            : <img
                                                className='w-250px h-150px object-fit-cover rounded rounded-2  opacity-100'
                                                src={defaultImage}
                                                alt={'img_unit'}
                                            />

                                        }

                                    </Link>
                                </div>
                            </>)
                        })
                        : 'Not units'
                    }
                    {/* {units.length > 0 && units.length < 8 && (
                        <div onClick={() => { navigate('/agent/catalog_agent/'); localStorage.setItem('scrollPositionHorizontal', '0') }}
                            className="w-100 h-150px px-4 py-auto opacity-100 d-flex justify-content-center align-items-center border border-1 border-gray-600 rounded fs-4 fw-semi-bold lh-1 bg-light-secondary">
                            <div >SHOW MORE <i className="bi bi-arrow-return-right fs-2x d-block text-center text-dark"></i></div>
                        </div>
                    )} */}
                </div>
                }

            </div>
            : <div className={'py-1'}>
                <div className="card shadow-sm bg-secondary">
                    <div className="card-body p-0 m-5 text-center">
                        <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 ">
                            No units
                        </span>
                    </div>
                </div>
            </div>
    )
}
