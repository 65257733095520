import React, { type ReactElement } from 'react'
import { areaExpansionImage } from '../../../../urls'
export default function AddOnServicesAreaInfo (): ReactElement {
    return (
        <>
            <img
                className=" w-100 h-275px object-fit-contain mb-2 rounded-0 rounded-bottom rounded-top opacity-100 bg-success"
                src={areaExpansionImage}
                alt={'mark_liable'}
            />
            <div className="d-flex flex-column border border-1  border-dashed border-gray-300 text-center pt-5 pb-7 mb-8 card-rounded my-4">
                <span className="fs-2 fw-bold counted text-center px-1"> Unlock New Markets with Area Expansion Add-On</span>

                <div className="separator separator-dotted border-success my-2 mx-4"></div>

                <span className="fw-semi-bold text-gray-600 fs-6 pb-1 text-justify px-4"> Utilize our in-app currency, Bricks, to unlock the Area Expansion Add-On and maximize your
                    real estate potential.</span>

            </div>

            <div className="d-flex flex-column border border-1  border-dashed border-gray-300 text-center pt-5 pb-7 mb-8 card-rounded my-4">
                <div className="fs-2 fw-bold counted text-gray-800 text-start px-4 mb-2 text-center">
                    Benefits of Area Expansion Add-On:
                </div>
                <span className="fs-2 fw-bold counted text-gray-800"> Unlock Untapped Markets:

                </span>
                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                    Dive into new districts, unveiling hidden opportunities and
                    expanding your reach to a wider client base.

                </span>

                <span className="fs-2 fw-bold counted text-gray-800"> Priority Property Access:

                </span>
                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                    Gain exclusive rights to market properties in your chosen areas.
                    This gives you a head start in showcasing listings and attracting potential clients effectively.

                </span>
                <span className="fs-2 fw-bold counted text-gray-800">        Personalised Recommendations:

                </span>
                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                    Get tailored suggestions based on expanded areas,
                    ensuring you find properties that perfectly match your preferences and meet your client's needs.
                </span>
                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                    Ready to unlock new markets and elevate your real estate game? Use Bricks to access the
                    Area Expansion Add-On
                </span>
            </div>

        </>
    )
}
// area expansion
