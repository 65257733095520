import React, { type ReactElement } from 'react'
import useSaveScroll from '../../../hooks/useSaveScroll'
import { setMyUnitsScrollPosition } from '../../../store/slices/unitsSlice'
import { type RootState } from '../../../store/store'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
// interface UnitsSaverScrollProps {
//    scrollRef: React.RefObject<HTMLDivElement>
// }
export default function MyUnitsScrollSaver (/* { scrollRef }: UnitsSaverScrollProps */): ReactElement {
    const myUnitsScrollPosition = useAppSelector((state: RootState) => state.units.myUnitsScrollPosition)
    const dispatch = useAppDispatch()
    useSaveScroll({
        // scrollRef,
        position: myUnitsScrollPosition,
        setPosition: (position: number) => dispatch(setMyUnitsScrollPosition(position)),
        getPosition: (state: RootState) => state.units.myUnitsScrollPosition
    })
    return <></>
}
