import React from 'react'
import { type IOrder, type ISettings } from '../../../../store/slices/userSlice'
import { mounthes } from '../../../../functions/agent/date/timeString'
interface IProps {
    orders: IOrder[]
}
export default function HistoryList ({ orders }: IProps): JSX.Element {
    return (
        <div className="mb-0">
            <h5 className="mb-4">Orders:</h5>

            <div className="table-responsive px-2">
                <table className="table align-middle table-row-dashed fs-6 gy-4 mb-0 gx-2">
                    <thead>
                        <tr className="border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                            <th className="min-w-100px max-w-100px">Name</th>
                            <th className="min-w-100px max-w-40px">Date</th>
                            <th className="min-w-100px max-w-70px">Amount</th>
                            <th className="text-end min-w-30px max-w-30px">Actions</th>
                        </tr>
                    </thead>

                    <tbody className="fw-semibold text-gray-800">
                        {orders && orders.map((ord) => {
                            // Check if ord?.createdAt is not null before creating the Date object
                            const createdAtDate = ord?.createdAt ? new Date(ord?.createdAt) : null

                            return (
                                <tr key={ord?.id}>
                                    <td>
                                        <label className="min-w-90px">{ord?.Service?.[0].Package}</label>
                                        <div className="fw-normal text-gray-600">
                        Basic yearly bundle
                                        </div>
                                    </td>
                                    <td>
                                        {/* Check if createdAtDate is not null before accessing its properties */}
                                        {(createdAtDate != null) && (
                                            <>
                                                <label className="min-w-40px">
                                                    {mounthes[createdAtDate.getMonth()].slice(0, 3)}{' '}
                                                    {createdAtDate.getDate().toString().padStart(2, '0')}
                                                </label>
                                                <div className="fw-normal text-gray-600">
                                                    {createdAtDate.getFullYear().toString()}
                                                </div>
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        <label >{ord?.Amount}</label>
                                        <div className="fw-normal text-gray-600">
                        AED
                                        </div>
                                    </td>

                                    <td className="text-end">
                                        <div className="d-flex flex-column">
                                            <a href="#" className="btn btn-icon btn-color-muted btn-bg-white btn-active-color-primary btn-sm me-3">
                            Invoice  <i className="ki-duotone ki-printer fs-3">
                                                    <span className="path1"></span><span className="path2"></span>
                                                    <span className="path3"></span><span className="path4"></span>
                                                    <span className="path5"></span>
                                                </i>
                                            </a>
                                            <a href="#" className="btn btn-icon btn-color-muted btn-bg-white btn-active-color-primary btn-sm me-3">
                                            Receipt  <i className="ki-duotone ki-sms fs-3">
                                                    <span className="path1"></span><span className="path2"></span>
                                                </i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
