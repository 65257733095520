import { ACCESS_KEY, URL_API_SEND_NOTIFY } from '../../../urls'

export async function customSendNotify (customBody: Record<string, unknown>): Promise<any> {
    const jwt = window.localStorage.getItem(ACCESS_KEY) as string
    try {
        const requestForNotification = await fetch(`${URL_API_SEND_NOTIFY}`, {
            method: 'POST',
            body: JSON.stringify(customBody),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const requestForNotificationJSON = await requestForNotification.json()

        return requestForNotificationJSON
    } catch (error) {
        console.log(error)
    }
}
