import React from 'react'

import { Drawer } from 'antd'

import { type RootState } from '../../../../store/store'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { mainButtonClosing, mainButtonOpening } from '../../../../store/slices/modalSlice'
import { nanoid } from 'nanoid'

export default function AgentSmalBtn ({ children, title }: any): JSX.Element {
    const { mainButtonOpen } = useAppSelector((state: RootState) => state.modal)

    const dispatch = useAppDispatch()
    const showDrawer = (): void => {
        // setOpen(true)
        dispatch(mainButtonOpening())
    }

    const onClose = (): void => {
        // setOpen(false)
        dispatch(mainButtonClosing())
    }
    return (
        <>

            <div key={nanoid()} className="btn btn-sm fs-6 btn-light-secondary border border-dark text-dark me-2"
                onClick={showDrawer}
            >
                {title}
            </div >

            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'auto'}
                styles={{
                    body: {
                        maxHeight: '50vh',
                        marginBottom: '3px',
                        paddingTop: '3px',
                        marginTop: '6px',
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }
                }}
                placement={'bottom'}
                closable={false}
                onClose={onClose}
                open={mainButtonOpen}
                // key={'bottom'}
                key={title}
                maskClosable={true}
            >
                <div
                    className="content d-flex flex-column flex-fill p-0 m-1 px-2"
                >
                    <div key={nanoid()} className="w-100 mw-450px  mx-auto">
                        {children}
                    </div>
                </div>
            </Drawer>
        </>
    )
}
