import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'antd'
import SlideFirst from '../../tutorials/SlideFirst'
import { nanoid } from 'nanoid'
import { useNavigate } from 'react-router-dom'
import { type CarouselRef } from 'antd/lib/carousel'

interface IProps {
    title: string
    imgPath: string
    contentArray: any
}

function SlideManual ({ imgPath, title, contentArray }: IProps): JSX.Element {
    const [modalOpen, setModalOpen] = useState(false)

    const [carouselCurrent, setCarouselCurrent] = useState(0)
    const carouselRefManuals = useRef<CarouselRef>(null)
    const [showClose, setShowClose] = useState(false)

    useEffect(() => {
        if (modalOpen) {
            carouselRefManuals.current?.goTo(0)
        }
    }, [modalOpen])

    const handleSlideChange = (index: number): void => {
        setCarouselCurrent(index)
        if (index === contentArray.length - 1) {
            setShowClose(true)
        }
    }
    // console.log(carouselCurrent, 'carouselCurrent')
    useEffect(() => {
        if (!showClose) {
            const interval = setInterval(() => {
                if (contentArray.length - 1 !== carouselCurrent) {
                    carouselRefManuals.current?.next()
                }
            }, 8000)
            return () => { clearInterval(interval) }
        }
    }, [showClose])

    const handleLeftClick = (): void => {
        if (carouselCurrent !== 0) {
            carouselRefManuals.current?.prev()
        }
    }

    const handleNextClick = (): void => {
        if (contentArray.length - 1 !== carouselCurrent) {
            carouselRefManuals.current?.next()
        }
    }

    const handleClose = (): void => {
        setModalOpen(false)
    }

    return (
        <>
            <div key={nanoid()} className={'w-100px h-100px  '}
                onClick={() => {
                    setModalOpen(true)
                }}
            >
                <div style={{ borderRadius: '0.6rem', verticalAlign: 'bottom' }}
                    className="w-100px h-100px d-flex  position-relative overflow-hidden border border-3 border-gray-500">
                    {/* <div className="z-index-1 position-absolute rounded" style={{ border: '2px solid #fff200', height: 'calc(100% - 4px)', width: 'calc(100% - 4px)', margin: '2px' }} /> */}

                    {/* <div className="p-4 z-index-1"> */}
                    {/*    <p className="fs-6 text-dark fw-bold lh-1 text-shadow">{el.title}</p> */}
                    {/* </div> */}
                    <img style={{ borderRadius: '0.6rem' }} className="position-absolute z-index-0 h-100 " src={imgPath} alt=""
                    />

                    <div className={'d-flex align-items-end w-100  h-100  position-absolute z-index-2 overlay '}
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', verticalAlign: 'bottom' }}>
                        <div className="px-2 py-0 z-index-1  ">
                            {/* style={{ background: 'linear-gradient(to top, rgba(0, 0, 0, 0.6),transparente )' }} */}
                            <p className="fs-6  text-white fw-semi-bold  lh-1  ">{title}</p>
                        </div>
                    </div>
                </div>

            </div>
            <Modal
                centered
                open={modalOpen}
                // maskStyle={{ height: '100vh', left: 0 }}
                style={{ height: '100vh', left: 0 }}
                width="100vw"
                zIndex={1501}
                closable={false}
                // onCancel={() => { setModalOpen(false) }}
                footer={null}

            >

                <div className="position-absolute container" style={{ height: '100vh', top: 0, left: 0, right: 0, bottom: 'auto', zIndex: 9998 }}>
                    <div className="row" style={{ height: 'inherit' }}>
                        <div className="col-6  opacity-0" onClick={handleLeftClick}>1</div>
                        <div className="col-6  opacity-0" onClick={handleNextClick}>2</div>
                    </div>
                </div>
                {/* {showClose && ( */}
                <span
                    className="position-absolute"
                    style={{
                        top: '40px',
                        right: '20px',
                        zIndex: 9999

                    }}
                    onClick={() => { handleClose() }}
                >
                    {/* style={{ mixBlendMode: 'difference' }} */}
                    <i className="fs-6x ki-duotone ki-cross-square " >
                        <span className="path1 text-dark" style={{ filter: 'brightness(0)' }}></span>
                        <span className="path2 text-white" style={{ filter: 'brightness(1)' }}></span>
                    </i>
                    {/* <i className="fs-5x  bi bi-x-lg"></i> */}
                </span>
                {/* )} */}

                <SlideFirst onChange={handleSlideChange} ref={carouselRefManuals}>
                    {contentArray.map((el: any) => {
                        return (
                            <div key={`${nanoid()}`} className="text-start ">
                                <div className="position-relative  w-100vw h-100vh border border-gray-500 overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center d-flex flex-column justify-content-center align-items-center"
                                    style={{ backgroundImage: `url(${el.img})` }}>
                                    <div className="w-100" style={{ height: '100vh' }}></div>
                                </div>
                            </div>
                        )
                    }
                    )}
                </SlideFirst>
            </Modal></>

    )
}

export default SlideManual
