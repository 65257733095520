import React from 'react'

import CardOffer from './CardOffer'

import { days } from '../../../../constants'

export default function OfferListItem(props: any): JSX.Element {

    return (
        <div className="flex-grow-1">
            {props.offers && props?.offers?.length > 0 ? (
                Array.from(new Set(props?.offers.map((offer: any) => (new Date(!('attributes' in offer) ? offer?.createdAt : offer?.attributes?.createdAt) as any).toDateString())))
                    .sort((a: any, b: any) => (new Date(a) as any) - (new Date(b) as any))
                    .map((dateString: any, index: number) => {
                        //      console.log(dateString, 'dateString')
                        const date = new Date(dateString)
                        const day = date.getDate().toString().padStart(2, '0')
                        const month = date.toLocaleString('default', { month: 'short' })
                        const dayWeek = date.getDay()

                        const haveDatesOffers = props.offers.filter((of: any) => (new Date(of?.attributes?.createdAt) as any).toDateString() === dateString)

                        return (
                            <div
                                key={`${index}-${props?.status}`}
                                className="card shadow-sm mb-2 position-relative me-n0 "
                            >

                                <div className="d-flex justify-content-between">
                                    <div className="w-50px d-flex flex-column align-items-center justify-content-start py-4 ps-2 col-2">
                                        <span className="fw-normal fs-5x lh-1 my-1">{`${day}`}</span>
                                        <span className="text-gray-700 fs-4">{days[dayWeek]}</span>
                                    </div>
                                    <div className='col-10'>
                                        {haveDatesOffers && haveDatesOffers.length > 0
                                            ? haveDatesOffers.map((off: any) => (
                                                <CardOffer key={`offer-${off.id}`} currentOffer={off} />
                                            ))
                                            : <div className={'d-flex align-items-end justify-content-end p-4'}>
                                                <span className="fs-2x text-gray-800 fw-semi-bold text-uppercase">No offers for today </span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
            )
                : <div className="card shadow-sm mb-2 position-relative" /> // Add an empty div when there are no viewings
            }
        </div>

    )
}
