import React from 'react'
import Lottie from 'react-lottie'
import animationData from '../../../animations/loading_screen_animation_new/350x200.json'

export default function BackLoader(): JSX.Element {
    return (
        <div className="fixed-bottom w-100" style={{ zIndex: '-1', bottom: 0 }}>
            <div className="d-flex flex-column flex-row-fluid flex-column-fluid flex-center pb-5 mb-15">
                <Lottie
                    options={{
                        animationData,
                        loop: true,
                        autoplay: true
                    }}
                    height={'auto'}
                    width={'100%'}
                />
            </div>
        </div>
    )
}
