import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { nanoid } from 'nanoid'


import MyCarousel from '../../../app/MyCarousel'
import { AgentModals } from '../../../functions/agent/AgentModals'
import { type Unit } from '../../../store/slices/unitsSlice'
import GeoForMyCustomModalForView from '../../../app/custom/customModalForView/unitsActions/GeoForMyCustomModalForView'
import FormListForMyCustomForView from '../../../app/custom/customModalForView/FormListForMyCustomForView'

export default function UnitDescriptonModal({
    unitID
}: any): JSX.Element {
    const viewings = useAppSelector(
        (state: RootState) => state.viewings.viewings
    )
    const units = useAppSelector(
        (state: RootState) => state.units.units
    )

    const [currentUnit, setCurrentUnit] = useState(units?.filter((el: any) => el.id === Number(unitID))[0])

    useEffect(() => {
        const foundCurrentUnit = units?.find((el: any) => el.id === Number(unitID)) as Unit
        setCurrentUnit(foundCurrentUnit)
    }, [viewings, unitID, units])

    return (
        < div key={nanoid()}>

            <div className="row g-0 d-flex flex-row">

                {(
                    <div className="card d-flex mh-100 ">
                        <div className="fv-row my-2 m-0 p-0 w-100">
                            <div className="separator border border-bso border-3 mx-n7 p-0 my-4"></div>
                        </div>
                        <GeoForMyCustomModalForView googleMapsLink={null} />
                        <div className="fv-row my-2 m-0 p-0 w-100">
                            <div className="separator border border-bso border-3 mx-n7 p-0 my-4"></div>
                        </div>
                        <FormListForMyCustomForView
                            form={
                                AgentModals().find((el: any) => el.id === 'viewing_modal').form
                            }
                            response={currentUnit}
                        />
                        <div className="fv-row my-2 m-0 p-0 w-100" />
                        <MyCarousel dots={true} autoScrol={true} showTwoColumns={false}>
                            {currentUnit?.attributes.Images
                                ? (
                                    currentUnit?.attributes.Images?.map((el: string) => (
                                        <div key={`${el}`} className="text-center">
                                            <div
                                                className="w-100 h-250px border border-gray-500 overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center position-relative"
                                                style={{ backgroundImage: `url(${el})` }}
                                            >
                                                <div className="h-200px w-100"></div>
                                            </div>
                                        </div>
                                    ))
                                )
                                : (
                                    <div>Not images</div>
                                )}
                        </MyCarousel>
                        <div style={{ height: '70px' }}></div>
                    </div>
                )
                }
            </div>
        </div>
    )
}
