
import React from 'react'

interface IProps {
    row: any
    description?: string

}

export default function CustomFormLable({ row, description }: IProps): JSX.Element {
    description = description || row.description ? description || row.description : ''
    return (
        <div className='lh-1'>
            <label className={
                (row.required)
                    ? 'required form-label  fw-bold text-dark text-uppercase'
                    : 'form-label fw-bold  text-dark text-uppercase'
            }>
                {row.content?.[0]}

            </label>
            {description && <div className="d-block mb-2 text-gray-500">{description}</div>}

        </div>
    )
}
