
import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY, urlAPI } from '../../../urls'
import { setAdvertises } from '../../slices/advertisesSlice'
import { modalSlice } from '../../slices/modalSlice'
import { type AppDispatch, type RootState } from '../../store'
import _ from 'lodash'

import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'
import { getQueryAdvertises } from '../../../functions/shared/api/queries/getQueryAdvertises'

export const setAdvertiseLinksStatus = (data: Record<string, unknown>, id: number, modalID: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)

    const currentDubaiDate = getCurrentDubaiDate()
    const inMomentOfferForAdmin = toUTCWithOffset(currentDubaiDate, 1)

    const advertise = _.find(getState().advertises.advertises, (advertise: any) => advertise.id === id)
    const isAllApproved = _.every(data?.Links as Array<{ Status: boolean }>, (link: { Status: boolean }) => link.Status)

    const Statuses = isAllApproved
        ? modalID === 'check_advertisement_links_modal' ? ['Links approved'] : ['Completed']
        : modalID === 'check_advertisement_links_modal' ? ['Links rejected'] : ['Ads removing rejected']
    const Links = _.map(data?.Links as Array<{ Status: boolean }>, (link: { Status: boolean }, index) => ({
        Link: (advertise?.attributes?.Links?.[index] as any)?.Link,
        Status: link.Status ? 'Approved' : 'Rejected',
        Title: (advertise?.attributes?.Links?.[index] as any)?.Title,
        Trakheesi_id: (advertise?.attributes?.Links?.[index] as any)?.Trakheesi_id
    }))
    const RejectReason = !isAllApproved ? data?.RejectReason : null

    try {
        dispatch(modalSlice.actions.dataFetchingStart())
        const sendObject = {
            method: 'PUT',
            collection: 'unit-advertises',
            id,
            query: getQueryAdvertises() , // 'populate[User][fields][0]=id&populate[Unit][fields][0]=id',
            body: {
                ...advertise?.attributes,
                Links,
                Statuses,
                RejectReason,
                Notify: 'Agent'
            }
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()
        // console.log(dataJSON, 'dataJSON addAgentSettings')
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                let notifyRule: string, notifyMessage: string
                if (modalID === 'check_advertisement_links_modal') {
                    if (isAllApproved) {
                        notifyRule = 'user-advertise-approved'
                        notifyMessage = 'The notify links are successfully approved'
                    } else {
                        notifyRule = 'user-advertise-rejected'
                        notifyMessage = 'The notify links are rejected'
                    }
                } else {
                    if (isAllApproved) {
                        notifyRule = 'user-advertise-approved'
                        notifyMessage = 'The notify links removing is successfully approved'
                    } else {
                        notifyRule = 'user-advertise-rejected'
                        notifyMessage = 'The notify links removing is rejected'
                    }
                }
                await sendNotification(inMomentOfferForAdmin, Number(advertise?.attributes?.User?.data?.id), id, notifyRule, notifyMessage)
                dispatch(modalSlice.actions.dataFetchingFinish())
                dispatch(setAdvertises(
                    dataJSON.response.data
                ))
                return { ...dataJSON, textNavigate: -1, isSuccessful: true, textNotification: 'You have successfully reviewed the Trakheesi ID(s)' }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
        dispatch(modalSlice.actions.dataFetchingFinish())
        return { textNavigate: '', isSuccessful: false, textNotification: 'Something went wrong, try again.' }
        //  dispatch(userSlice.actions.userFetchingError(error))
    }
}
