import React, { useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import { Collapse } from 'antd'
import AgentSmalBtn from '../dashboard/createActionsButton/AgentSmalBtn'
import ActionLoadEmiratesID from '../dashboard/createActionsButton/ActionLoadEmiratesID'
import ActionLoadPassport from '../dashboard/createActionsButton/ActionLoadPassport'
import ActionLoadRera from '../dashboard/createActionsButton/ActionLoadRera'
import { mainSwalDeal } from '../../../functions/swalOptions'
import { nanoid } from 'nanoid'
// import ChooseAreasList from '../addOnsServices/areas/ChooseAreasList'
// import HideButtonListAddOns from '../addOnsServices/HideButtonListAddOns'
import { useDispatch } from 'react-redux'
import { setShowPopupAddOnsAreas } from '../../../store/slices/modalSlice'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import swalsConfig from '../../../app/texts/swalTexts'
const { Panel } = Collapse

interface IProps {
    userID: number
    activeSub: string
}

export default function AgentProfileSettings ({ userID, activeSub }: IProps): JSX.Element {
    const [openAlert, setOpenAlert] = useState(false)
    const [addOnsActive, setAddOnsActive] = useState(false)
    const showPopupAddOnsAreas = useAppSelector((state: RootState) => state.modal.showPopupAddOnsAreas)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    if (openAlert) {
        void mainSwalDeal(swalsConfig.mainDeal.textAgentProfileSettings, swalsConfig.mainDeal.titleAgentProfileSettings).then(async (result: any) => {
            if (result.isConfirmed) {
                setOpenAlert(false)
                // navigate('/agent/settings/addOns')
                dispatch(setShowPopupAddOnsAreas(true))
            } if (result.isDenied) {
                setOpenAlert(false)
            }
        }).catch(console.log)
    }

    return (
        <div className="card shadow-sm mb-2">
            <Collapse defaultActiveKey={'profile_settings'} >
                <Panel
                    style={{ fontFamily: 'Poppins' }}
                    showArrow={false}
                    header={<span className="card-title fs-3 fw-bold fw-bold p-0 m-0">Profile</span>}
                    key="profile_settings"
                >    <div className="card-body p-0 m-0">
                        <div className="d-flex flex-wrap align-items-center">
                            <div >
                                <div className="fs-6 fw-bold mb-1 text-uppercase">Avatar</div>
                            </div>
                            <div className="ms-auto">
                                <Link

                                    to={`/landlord/modal/agent_avatar/${userID}/${userID}`}
                                    className="text-decoration-none "
                                >
                                    <div
                                        className="btn btn-sm fs-6 btn-light-secondary  border border-dark text-dark me-2">Update
                                    </div>
                                </Link>

                            </div>
                        </div>
                        <div className="separator separator-dashed my-6"></div>
                        <div className="d-flex flex-wrap align-items-center">
                            <div >
                                <div className="fs-6 fw-bold mb-1 text-uppercase">My Documents</div>
                            </div>

                            <div className="ms-auto">
                                {/* <Link to={`/agent/modal/phone_verify_contacts_my/${userID}/${userID}`} className="text-decoration-none"> */}

                                <AgentSmalBtn title={'Update'}>
                                    <ActionLoadEmiratesID/>
                                    <ActionLoadPassport/>
                                    <ActionLoadRera/>
                                </AgentSmalBtn>

                            </div>

                        </div>

                        <div className="separator separator-dashed my-6"></div>

                        <div className="d-flex flex-wrap align-items-center">
                            <div >
                                <div className="fs-6 fw-bold mb-1 text-uppercase">Active areas</div>
                            </div>
                            <div className="ms-auto">

                                <Link
                                    to={`${addOnsActive ? `/landlord/modal/agent_settings/${userID}/${activeSub}` : '#'}`}
                                    onClick={ addOnsActive ? () => {} : () => { setOpenAlert(true) }}

                                    className="text-decoration-none "
                                >
                                    <div
                                        className="btn btn-sm fs-6 btn-light-secondary  border border-dark text-dark me-2">Update
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="separator separator-dashed my-6"></div>
                        <div className="d-flex flex-wrap align-items-center mb-2">

                            <div >
                                <div className="fs-6 fw-bold mb-1 text-uppercase">Black list</div>
                                {/* <div className="fw-semibold text-gray-600">************</div> */}
                            </div>

                            <div className="ms-auto">
                                <div onClick={() => {
                                    //  navigate('/agent/modal/my_profile_reset_password_way_email/not/not')
                                }
                                } className="btn btn-sm fs-6 btn-light-secondary border border-dark text-dark me-2">Update</div>
                            </div>

                        </div>
                    </div></Panel>
            </Collapse>

            {/* showPopupAddOnsAreas
                ? <HideButtonListAddOns title='ChooseAreasListHide' >
                    <ChooseAreasList key={`${nanoid()}`} />
                </HideButtonListAddOns>
                : null */}

        </div>
    )
}
