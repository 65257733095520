import QueryString from 'qs'
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../../urls'
import { dataFetchingFinish, dataFetchingStart, modalSlice } from '../../../slices/modalSlice'
import { type Unit } from '../../../slices/unitsSlice'
import { type AppDispatch, type RootState } from '../../../store'
import { offersSlice } from '../../../slices/offersSlice'
import { getErrorss } from '../../../../functions/errors/GetErrors'
import { setFilterNavigate } from '../../../slices/scheduleSlice'
import { getNameUser } from '../../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../../functions/agent/agentModuleComponent/getUnitAddres'
import { type User } from '../../../slices/userSlice'

import { getCurrentDubaiDate } from '../../../../functions/agent/date/getCurrentDateStrFormat'
import { sendNotification } from '../../../../functions/shared/notifications/from-agent/sendNotification'
import { toUTCWithOffset } from '../../../../functions/agent/date/toUTCWithOffset'

export const makeOfferAgentToAgentLastPart = (bodyClient: any) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const oferID = bodyClient.objectID
   
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
    
            dispatch(dataFetchingStart())

            const units: any = getState().units.units
            const currentUnit = units?.filter((el: any) => el.id === Number(bodyClient.unitID))?.[0]
            const property = currentUnit?.attributes?.Property.data

            const offer = getState().offers?.offers?.find((offer) => offer.id === Number(oferID))
            const isSale = currentUnit?.attributes.Marketing?.Use === 'For sale'
            // const name = property.attributes.Name ?? ''
            // const location = property.attributes.Area ?? ''
            // const unitNumber = currentUnit.attributes?.Number ?? ''
            // const ownerUserId = (currentUnit).attributes?.User.data?.id
            const unitStatuses = currentUnit.attributes?.Statuses ?? []

            //     Payments Duration Additional Notes Offer Contract Start Date
            // Business Name Business Owner Name   Business Activity Establishment Date
            const offerType = {
                ...offer?.attributes?.Type?.[0],
                Offer: bodyClient.Type.Offer,
                ContractStartDate: bodyClient.Type.ContractStartDate,
                Payments: bodyClient.Type.Payments,
                ...(isSale ? {} : { Duration: bodyClient.Type.Duration }),
                AdditionalNotes: bodyClient.Type.AdditionalNotes
            }

            const bodyObject = {
                method: 'PUT',
                collection: 'offers',
                id: Number(oferID),
                query: QueryString.stringify({
                    populate: ['Type', 'Documents', 'User', 'Unit']
                }, {
                    encodeValuesOnly: true
                }),

                body: {
                    Notify: unitStatuses?.includes('Door close') ? 'Other Agent' : 'Both',
                    Type: [offerType]

                }
            }
            console.log(bodyObject, 'bodyObject')
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'BSO-Hash': '02ns30nsBSO!',
                    Authorization: `Bearer ${jwt}`
                },
                body: JSON.stringify(bodyObject)
            })
            const dataJSON = await response.json()

            if (dataJSON.success) {
                if (dataJSON.response.error != null) {
                    dispatch(offersSlice.actions.offersFetchingError(getErrorss(dataJSON)))
                    dispatch(dataFetchingFinish())
                    return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
                } else {
                    const nameUser = getNameUser(getState().user.currentUser as User)
                    const unitAdress = getUnitAddress(currentUnit)
                    await sendNotification(toUTCWithOffset(getCurrentDubaiDate(), 1), Number(userID), dataJSON?.response?.data?.id, 'admin-offer-in-process', `Attention kindy, ${nameUser} did offer on ${unitAdress} , please respond.`)

                    dispatch(setFilterNavigate('offers'))
                    dispatch(dataFetchingFinish())
                    return {
                        textNavigate: '/agent/schedule',
                        // `/agent/modalView/viewing_modal/${bodyClient.unitID}/${dataJSON?.response?.data?.id}/create`,
                        isSuccessful: true,
                        textNotification: 'Wait change text',
                        title: 'Offer has been created'
                    }
                }
            } else {
                dispatch(offersSlice.actions.offersFetchingError(getErrorss(dataJSON)))
                dispatch(dataFetchingFinish())
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } catch (e) {
            dispatch(dataFetchingFinish())
            console.log(e)
        }
    }
}
