interface fillFieldInFormTask {
    id: string
    tagName: any
    value: string | null
}
export default async function fillFieldInForm ({ id, tagName, value }: fillFieldInFormTask): Promise<{ isSuccessful: boolean }> {
    return await new Promise((resolve) => {
        try {
            const formField = document.getElementById(id)
            if (formField != null) {
                const formInput = formField.getElementsByTagName(tagName)
                if (formInput?.[0] != null) {
                    (formInput[0]).focus()
                    setTimeout(() => {
                        formInput[0].value = value
                        // console.log(value)
                        resolve({
                            isSuccessful: true
                        })
                    }, 100)
                } else {
                    throw new Error(`Can't find element with tag ${String(tagName)} inside ${id}`)
                }
            } else {
                throw new Error(`Can't find element with id ${id}`)
            }
        } catch (err) {
            console.error('function - fillFieldInForm: ', err)
            resolve({
                isSuccessful: false
            })
        }
    })
}
