export const isToday = (date: string): boolean => {
    const dateView = new Date(date)
    const today = new Date()
    return (
        dateView.getDate() === today.getDate() &&
        dateView.getMonth() === today.getMonth() &&
        dateView.getFullYear() === today.getFullYear()
    )
}

