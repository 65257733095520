import { getQueryDocuments } from "../../../functions/shared/api/queries/getQueryDocuments"
import { ACCESS_KEY, urlAPI } from "../../../urls"
import { setDocuments } from "../../slices/modalSlice"
import { type AppDispatch, type RootState } from "../../store"
import { fetchDocuments } from "../documentActions"
import { uploadSingleFile } from "./upload/uploadSingleFile"

export const scanEmiratesID = (fileFront: File | null, fileBack: File | null) => async (dispatch: AppDispatch, getState: () => RootState): Promise<Record<string, any> | null> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const scanOneSide = async (file: File, type: 'Emirates ID' | 'Emirates ID Front'): Promise<{ document: Record<string, any>, url: string } | null> => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('type', type)
        const response = await fetch('https://bso.ae/api/service/scanDocument', {
            method: 'POST',
            body: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        if (responseJSON.response.error == null) {
            const url = await uploadSingleFile(file, `offer-${new Date().getTime()}-emirates-id-${type === 'Emirates ID Front' ? 'front' : 'back'}`, 'offers')
            if (url != null) {
                return {
                    document: responseJSON?.response?.document,
                    url
                }
            }
        }
        return null
    }
    if (fileFront != null && fileBack != null) {
        const frontResult = await scanOneSide(fileFront, 'Emirates ID Front')
        const backResult = await scanOneSide(fileBack, 'Emirates ID')
        if (frontResult != null && backResult != null) {
            const isOneDocument = Object.keys(backResult).every(key => !(key in frontResult) || (backResult.document[key] === frontResult.document[key]))
            if (isOneDocument) {
                const sendBody = {
                    method: 'POST',
                    collection: 'documents',
                    query: getQueryDocuments(),
                    body: {
                        RefID: `REF_DOC<->emirates_id<->${String(backResult.document.number)}<->${new Date().getTime()}`,
                        LinkFront: frontResult.url,
                        LinkBack: backResult.url,
                        Category: 'Emirates ID',
                        Type: [
                            {
                                __component: 'documents.emirates-id',
                                Number: backResult.document.number,
                                // DateOfBirth: responseJSON?.response?.document?.dob,
                                Gender: backResult.document.gender === 'M' ? 'Male' : 'Female',
                                // Nationality: responseJSON?.response?.document?.nationality,
                                Name: backResult.document.name
                            }
                        ],
                        Statuses: ['In-process']
                    }
                }
                const documentResponse = await fetch(`${urlAPI}`, {
                    method: 'POST',
                    body: JSON.stringify(sendBody),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                })
                const documentResponseJSON = await documentResponse.json()
                if (documentResponseJSON.success === true) {
                    // const documents = getState().modal.documents
                    dispatch(setDocuments([documentResponseJSON.response.data.id]))
                    void dispatch(fetchDocuments(true))
                    return {
                        ...frontResult.document,
                        ...backResult.document
                    }
                }
            }
        }
    }
    return null
}