import { type Document } from '../../../store/slices/documentSlice'
import { type ISettings, type User } from '../../../store/slices/userSlice'

export const generateProgressProfile = (user: User, documents: Document[]): number => {
    let progress = 25
    let emiratesIdOrPassportFound = false
    let reraCardFound = false
    // console.log(user, 'user')
    documents && documents.forEach((doc: Document) => {
        if (!emiratesIdOrPassportFound && Array.isArray(doc.attributes?.Type) && doc.attributes.Type.length > 0) {
            const firstType = doc.attributes.Type[0]
            if (firstType && (firstType.__component === 'documents.emirates-id' || firstType.__component === 'documents.passport')) {
                progress += 25
                emiratesIdOrPassportFound = true
            }
        }

        if (!reraCardFound && Array.isArray(doc.attributes?.Type) && doc.attributes.Type.length > 0) {
            const firstType = doc.attributes.Type[0]
            if (firstType && firstType.__component === 'documents.rera-card') {
                progress += 25
                reraCardFound = true
            }
        }
    })

    if (user.Settings?.at(0)?.Areas != null) {
        if ((user.Settings.at(0)?.Areas as ISettings[]).length > 0) {
            progress += 15
        }
    }
    if (Array.isArray(user?.Type) && user?.Type.length > 0) {
        if (user?.Type.at(0)?.Photo != null) {
            progress += 10
        }
    }

    return progress
}
