import React, { useState } from 'react'
import { useAppDispatch } from '../../../hooks/redux'
import { logout } from '../../../store/slices/authSlice'

import { Collapse, type CollapseProps } from 'antd'
import { useNavigate } from 'react-router-dom'
import { deleteUserAction } from '../../../store/actionsCreators/userActions/deleteUserAction'
import { swalError, swalSuccess, swalSureDelete, swalSureLogout } from '../../../functions/swalOptions'
import { clearDeviceToken } from '../../../store/actionsCreators/authActions'
import { removeNotifyByDeviceToken } from '../../../functions/shared/notify-helpers/removeNotify'
import { store } from '../../../store/store'

export default function AgentProfileSecurityOld(): JSX.Element {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [indicatorText, setIndicatorText] = useState('')
    const logoutHandler = (): any => {
        void swalSureLogout().then(async (result: any) => {
            if (result.isDenied) {
                //   const clearDevice = await dispatch(clearDeviceToken())
                //    if (clearDevice.success && clearDevice.response) {
                dispatch(logout())
                void removeNotifyByDeviceToken({
                    userID: store.getState().user.currentUser.id as number,
                    deviceToken: store.getState().user.currentUser.DeviceToken as string,
                    badges: 0
                })
                location.reload()
                navigate('/auth/login')
            }
            // }
        })
    }

    const handleDeleteAccount = (): void => {
        void swalSureDelete().then(async (result: any) => {
            if (result.isDenied) {
                const isDeleted = await dispatch(deleteUserAction())
                if (isDeleted.isSuccessful) {
                    void await swalSuccess(isDeleted.textNotification, () => {
                        //  location.reload()
                        navigate(isDeleted.textNavigate)
                    })
                } else {
                    void await swalError(isDeleted.textNotification, () => {
                        navigate(isDeleted.textNavigate)
                    })
                }
            }
        })
    }

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: <span className="card-title fs-3 fw-bold fw-bold p-0 m-0 ">Profile Security</span>,
            children: <div className="card-body p-0 m-0 ">
                {/* <div className="row g-0 d-flex flex-row"> */}
                {/*    <div className="col g-0 d-flex flex-column"> */}
                {/*        <span>Last session:</span> */}
                {/*    </div> */}
                {/*    <div className="col g-0 d-flex flex-column"> */}
                {/*        <span className="text-dots-app"></span> */}
                {/*    </div> */}
                {/* </div> */}
                <div className="row g-0 d-flex flex-row">
                    <div className="col g-0 d-flex flex-column">
                        <div className="btn btn-bso me-2 mt-2" onClick={logoutHandler}>
                            Logout
                            {loading
                                ? (
                                    <span className="indicator-progress">
                                        {indicatorText}
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )
                                : null}
                        </div>
                    </div>
                    <div className="col g-0 d-flex flex-column">
                        <div
                            className="btn btn-light ms-2 mt-2"
                            onClick={handleDeleteAccount}
                        // disabled={loading}
                        >
                            <span className="indicator-label">Delete account</span>
                            {loading
                                ? (
                                    <span className="indicator-progress">
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        }]

    return (
        <>
            <div data-id="user_UI_block" className="card shadow-sm mb-0 ">
                <Collapse items={items} />

            </div>

        </>
    )
}
