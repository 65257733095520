import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import _ from 'lodash'
import {
    checkIsHaveEmiratesAndExpires,
    checkIsHavePassportAndExpires, checkIsHaveReraAndExpires
} from '../../../../functions/agent/documents/checkIsHaveDocumentsAndExpires'
// import { docStatus } from '../../../../components/agentModule/settings/AgentIdAndLicence'
// import NotAdded from '../../../../functions/agent/statuses/NotAdded'
import dayjs from 'dayjs'
import { type RootState } from '../../../../store/store'
import ViewModalContactInfo from './ViewModalContactInfo'
import { nanoid } from 'nanoid'
import { openChatByUserID } from '../../../../store/actionsCreators/chatActions'
import { useNavigate } from 'react-router'
import useUserType from '../../../../hooks/UseUserType'
import { Link } from 'react-router-dom'
import ProfileCard from "../../../../components/shared/search/search-results/profile-card";

export interface AgentInfoForChildrenProps {
    userID: number
}
const AgentInfoForChildren = ({ userID }: AgentInfoForChildrenProps): JSX.Element => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [contactLoading, setContactLoading] = useState(false)
    const { userTypeLowercase } = useUserType()

    const allUsers = useAppSelector((state: RootState) => state.user.allUsers)
    const agentUser = _.find(allUsers, (user) => user.id === userID)
    let agentDocs: any = []
    if (Array.isArray(agentUser?.Documents)) {
        agentDocs = agentUser?.Documents
    }
    const emiratesIdDoc = checkIsHaveEmiratesAndExpires(agentDocs.map((doc: any) => ({ attributes: doc }))).file
    const passportDoc = checkIsHavePassportAndExpires(agentDocs.map((doc: any) => ({ attributes: doc }))).file
    const reraCardDoc = checkIsHaveReraAndExpires(agentDocs.map((doc: any) => ({ attributes: doc }))).file

    const verifiedDocs = []
    if (emiratesIdDoc != null) {
        verifiedDocs.push(emiratesIdDoc)
    } if (passportDoc != null) {
        verifiedDocs.push(passportDoc)
    }
    const verifiedDoc = verifiedDocs.find((el) => el?.attributes?.Statuses?.includes('Approved')) ?? verifiedDocs.find((el) => el?.attributes?.Statuses?.includes('Rejected')) ?? verifiedDocs.find((el) => el?.attributes?.Statuses?.includes('In-process')) ?? verifiedDocs.find((el) => el?.attributes?.Statuses?.includes('Archived'))

    return (
        agentUser != null
            ?
            // <ViewModalContactInfo
            //     title={'Agent'}
            //     info={[
            //         <Link to={`/${userTypeLowercase}/profile/${agentUser.id}/-`} key={nanoid()}>
            //             {agentUser?.ContactInfo?.FirstName} {agentUser?.ContactInfo?.FamilyName}
            //         </Link>,
            //         <a href={agentUser?.phone != null ? `tel:${agentUser.phone}` : ''} key={nanoid()}>
            //             {agentUser?.phone}
            //         </a>,
            //         <a href={agentUser?.email != null ? `mailto:${agentUser.email}` : ''} key={nanoid()}>
            //             {agentUser?.email}
            //         </a>,
            //         <>{agentUser?.ContactInfo?.DateOfBirth} ({dayjs().diff(agentUser?.ContactInfo?.DateOfBirth, 'year')} years old)</>,
            //         <>{agentUser?.ContactInfo?.Gender}</>
            //     ]}
            //     documents={[
            //         {
            //             docType: 'Emirates ID or Passport',
            //             document: verifiedDoc
            //         },
            //         {
            //             docType: 'RERA License',
            //             document: reraCardDoc
            //         }
            //     ]}
            // />
            <div>
                <ProfileCard user={agentUser} showUserType={true}/>
            </div>
            : <></>
    )
}

export default AgentInfoForChildren
