import { ACCESS_KEY, USER_ID_KEY } from '../../../../urls'
import { modalSlice } from '../../../slices/modalSlice'

import { type AppDispatch, type RootState } from '../../../store'

export const addCardAction = (formValues: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    //  console.log(formValues, 'formValues')
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const currentUser = getState().user.currentUser

    try {
        console.log('hey')
    } catch (error) {
        console.log(error)
        dispatch(modalSlice.actions.dataFetchingFinish())
    }
}
