import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState, store } from '../../../store/store'
// import {initJS} from "../../../functions/landlord/init";
import { setTask, setTasks, /* setService, */ setPlan, setDop } from '../../../store/slices/serviceSlice'
// import {setPage} from "../../../redux/reducers/localReducer";

export default function SetButton ({ type, data }: { type: string, data: any }): JSX.Element {
    const dispatch = useDispatch()
    const plan = useSelector((state: RootState) => state.service.plan)
    const dop = useSelector((state: RootState) => state.service.dop)
    const vendor = useSelector((state: RootState) => state.service.vendor)
    const dopIn = dop.find(el => el.id === data.id)
    useEffect(() => {
        // initJS()
    }, [])
    const editWorkTask = (): void => {
        store.dispatch(setTask(data))
        /* $(`[data-id="work_task_name"]`).val(data.name)
        $(`[data-id="work_task_area"]`).val(data.area) */
        // store.dispatch(setPage('work_task_page'))
    }
    const delWorkTask = (): void => {
        const tasksIn = [...store.getState().service.tasks]
        const tasks = tasksIn.filter(el => el.id !== data.id)
        store.dispatch(setTasks(tasks))
    }
    if (type === 'task' || type === 'tasks2') {
        return (<div className="card shadow-sm mb-4 position-relative border rounded border-primary bg-light-primary">
            <div className="card-body p-0  position-relative">
                <div className="d-flex flex-row row g-0 p-4 m-0 align-items-start justify-content-between"
                    data-post-button="set_type" data-id={data.name}>
                    <div className="d-flex flex-column col-8">
                        <span className="text-gray-900 fs-4 fw-bolder me-0 mt-0 mt-1">{data.name}</span>
                        <span className="text-gray-500 fs-8 fw-bolder me-0 mt-0">{data.area} Sq.Ft.</span>
                    </div>
                    {type === 'task'
                        ? <div onClick={editWorkTask} className="d-flex flex-column col-1">
                            {/* <span className="bi bi-gear fs-2x m-0 p-0 mt-2 text-dark"/> */}
                        </div>
                        : null}
                    {type === 'task'
                        ? <div onClick={delWorkTask} className="d-flex flex-column col-1">
                            {/* <span className="bi bi-trash fs-2x m-0 p-0 mt-2 text-dark"/> */}
                        </div>
                        : null}
                </div>
            </div>
        </div>)
    } else if (type === 'works') {
        return (<div className={
            data.status === 'In progress'
                ? 'card shadow-sm mb-4 position-relative border rounded border-warning bg-light-warning'
                : data.status === 'Done'
                    ? 'card shadow-sm mb-4 position-relative border rounded border-success bg-light-success'
                    : 'card shadow-sm mb-4 position-relative border rounded border-primary bg-light-primary'}>
            <div className="card-body p-0  position-relative">
                <div className="d-flex flex-row row g-0 p-4 m-0 align-items-start justify-content-between"
                    data-post-button="set_type" data-id={data.name}>
                    <div className="d-flex flex-column col-6">
                        <span className="text-gray-900 fs-4 fw-bolder me-0 mt-0 mt-1">{data.name}</span>
                        <span className="text-gray-500 fs-8 fw-bolder me-0 mt-0">{data.area} Sq.Ft.</span>
                    </div>
                    <div className="d-flex flex-column col-5">
                        <span className="text-gray-800 fs-7 fw-bolder me-0 mt-0 mt-1">Price: AED{data.price}</span>
                        <span className="text-gray-800 fs-7 fw-bolder me-0 mt-0 mt-1">Status: {data.status}</span>
                    </div>
                </div>
            </div>
        </div>)
    } else if (type === 'vendor') {
        return (<div className={vendor.id === data.id ? 'card shadow-sm mb-4 position-relative border rounded border-primary bg-light-primary' : 'card shadow-sm mb-4 position-relative border rounded border-dark bg-light-dark'}>
            <div className="card-body p-0  position-relative">
                <div className="text-center p-5">
                    <div className="card-rounded  h-200px w-100 overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center" style={{ backgroundImage: `url(${data.img})` }}>
                        <div className="h-200px w-100" ></div>
                    </div>
                </div>
                <div className="d-flex flex-row row g-0 p-4 m-0 align-items-start justify-content-between"
                    data-post-button="set_vendor" data-id={data.id}>
                    <div className="d-flex flex-column col-7">
                        <span className="text-gray-900 fs-4 fw-bolder me-0 mt-0 mt-1">{data.company}</span>
                        <span className="fw-bold fs-5 mb-2 text-dots-app text-dark">{data.name}</span>
                        <div className="btn btn-sm text-start p-0 px-2 mb-2 disable button-text">
                            {/* <span className="bi bi-envelope-fill pe-2"></span> */}
                            <span>{data.email}</span></div>
                        <div className="btn btn-sm text-start p-0 px-2 mb-2 disable button-text">
                            {/* <span className="bi bi-telephone-fill pe-2"></span> */}
                            <span>{data.phone}</span></div>
                    </div>
                    <div className="d-flex flex-column col-5">
                        <span className="fw-bold fs-5 mb-2 text-dots-app text-dark">Total price:</span>
                        <span className="fw-bold fs-5 mb-2 text-dots-app text-dark">AED{data?.price}</span>
                    </div>
                </div>
            </div>
        </div>)
    } else if (type === 'plan') {
        return (<div className={plan.id === data.id ? 'card shadow-sm mb-4 position-relative border rounded border-primary bg-light-primary' : 'card shadow-sm mb-4 position-relative border rounded border-dark bg-light-dark'}>
            <div className="card-body p-0  position-relative">
                <div className="d-flex flex-row row g-0 p-4 m-0 align-items-start justify-content-between"
                    onClick={() => { dispatch(setPlan(data)) }}
                >
                    <div className="d-flex flex-column col-1">
                        {/* <span className={plan.id === data.id ? 'bi bi-check-circle-fill fs-2x m-0 p-0 mt-2 text-primary' : 'bi bi-circle fs-2x m-0 p-0 mt-2'}/> */}
                    </div>
                    <div className={data?.price !== undefined ? 'd-flex flex-column col-7' : 'd-flex flex-column col-10'}>
                        <span className="text-gray-900 fs-4 fw-bolder me-0 mt-0 mt-1">{`${data.name} ${data?.dayString !== undefined ? data.oneTime ? 'Plan' : 'Subscription' : ''}`}</span>
                        <span className="text-gray-500 fs-8 fw-bolder me-0 mt-0">{data.info}</span>
                    </div>
                    {data?.price !== undefined
                        ? <div className="d-flex flex-column col-3">
                            <div className="d-flex flex-row">
                                <div className="d-inline-block min-w-2px position-relative bg-dark mx-3"
                                    style={{ left: 0, top: 0, height: 'auto' }}>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className={data?.dayString !== undefined ? 'text-end mt-2' : 'text-end my-4'}>AED{data?.price}</div>
                                    {data?.dayString !== undefined
                                        ? <div className="text-gray-500 fs-8 text-end fw-bolder me-0 mt-0">
                                        for {data?.dayString}</div>
                                        : null}
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </div>)
    } else if (type === 'dop') {
        return (<div className={dopIn !== undefined ? 'card shadow-sm mb-4 position-relative border rounded border-primary bg-light-primary' : 'card shadow-sm mb-4 position-relative border rounded border-dark bg-light-dark'}>
            <div className="card-body p-0  position-relative">
                <div className="d-flex flex-row row g-0 p-4 m-0 align-items-start justify-content-between"
                    onClick={() => {
                        if (dopIn !== null) {
                            dispatch(setDop([]))
                        } else {
                            dispatch(setDop([data]))
                        }
                    }}
                >
                    <div className="d-flex flex-column col-1">
                        {/* <span className={dopIn !== undefined ? 'bi bi-check-square-fill fs-2x m-0 p-0 mt-3 text-primary' : 'bi bi-square fs-2x m-0 p-0 mt-3'}/> */}
                    </div>
                    <div className="d-flex flex-column col-7">
                        <span className="text-gray-900 fs-4 fw-bolder me-0 mt-0 mt-1">{data.name}</span>
                    </div>

                    <div className="d-flex flex-column col-3">
                        <div className="d-flex flex-row">
                            <div className="d-inline-block min-w-2px position-relative bg-dark mx-3"
                                style={{ left: 0, top: 0, height: 'auto' }}>
                            </div>
                            <div className="d-flex flex-column">
                                <div className="text-end mt-4">AED{data?.price}</div>
                                <div className="text-end mt-4"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>)
    } else {
        return (<></>)
    }
}
