import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from './redux'
import { type RootState } from '../store/store'

interface UseInfiniteScrollProps {
    scrollRef?: any
    page: number
    pageCount: number
    setPage: any
    action: any
    direction: 'top' | 'bottom'
    block?: boolean
    triggerByScrollTop?: boolean
}

const UseInfiniteScroll = ({ scrollRef, page, pageCount, setPage, action, direction, block, triggerByScrollTop }: UseInfiniteScrollProps): void => {
    const [reached, setReached] = useState(false)
    const dispatch = useAppDispatch()
    const { paginationLoading } = useAppSelector((state: RootState) => state.pagination)
    useEffect(() => {
        const handleScroll = (e: any): void => {
            // console.log(e.target.scrollHeight + e.target.scrollTop - e.target.clientHeight)
            let scrolled: boolean = false
            // console.log(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight)
            if (direction === 'top') {
                // console.log(e.target.scrollHeight, e.target.scrollTop, e.target.clientHeight)
                if (triggerByScrollTop === true) {
                    scrolled = e.target.scrollTop < 10
                } else {
                    scrolled = e.target.scrollHeight + e.target.scrollTop - e.target.clientHeight < 10
                }
            } else {
                scrolled = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10
            }
            // console.log({
            //     scrolled,
            //     reached,
            //     paginationLoading,
            //     block
            // })
            if (scrolled && !reached && !paginationLoading && block !== true) {
                if (!reached && (page < pageCount || pageCount === -1)) {
                    //     console.log('PAGINATION LOADING', { paginationLoading })
                    dispatch(action())
                    dispatch(setPage(page + 1))
                    setReached(true)
                }
            } else {
                setReached(false)
            }
        }
        //     console.log('REF', scrollRef)
        if (scrollRef?.current != null) {
            scrollRef.current.addEventListener('scroll', handleScroll)
        } else {
            window.addEventListener('scroll', handleScroll)
        }

        return () => {
            if (scrollRef?.current != null) {
                scrollRef.current.removeEventListener('scroll', handleScroll)
            } else {
                window.removeEventListener('scroll', handleScroll)
            }
            // dispatch(setPage(1))
            // dispatch(setPageCount(1))
        }
    }, [page, setPage, scrollRef, pageCount, action, direction, paginationLoading, block])
}

export default UseInfiniteScroll
