const subscriptionTexts = {
    free_plane: {
        title: 'Free',
        description: 'Ideal to explore the app and its features'
    },
    standard_plane: {
        title: 'Standard',
        description: 'Perfect for users seeking an afforable way to boost their growth'
    },
    premium_plane: {
        title: 'Premium',
        description: 'Enjoy the full suite of benefits and features'
    },
    field_brodcoast: ' Broker Units Broadcasting',
    field_chat: 'Online Chat with brokers and BSO Support',
    field_schedule: 'Create viewings, offers and advertisements',
    field_areas: 'Active advertising areas',
    field_broadcoast_coins: 'Broadcast credits',
    field_marketing_coins: 'Bricks: e-currency for all in-app purchases'
}
export default subscriptionTexts
