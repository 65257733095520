import React from 'react'
const ServiceCard = (): JSX.Element => {
    return (
        <div className="card rounded shadow-sm mb-2 position-relative me-n0 d-flex flex-row overflow-hidden mx-2">
            <div className={'w-90px'}
                style={{
                    background: 'url(/assets/media/placeholders/user.jpg) no-repeat center',
                    backgroundSize: 'cover'
                }}
            />
            <div className={'d-flex flex-row justify-content-between h-100 p-4'} style={{ width: 'calc(100% - 90px)' }}>
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex align-items-center text-gray-800">
                        <i className="fs-1 ki-duotone ki-wrench me-2">
                            <i className="path1"></i>
                            <i className="path2"></i>
                        </i>
                        <div className={'fs-7 fw-bold text-truncate'}>
                            AC maintenance
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-gray-800">
                        <i className="fs-1 ki-duotone ki-safe-home me-2">
                            <i className="path1"></i>
                            <i className="path2"></i>
                        </i>
                        <div className={'fs-7 fw-bold text-truncate text-gray-600'}>
                            SOL Star Building
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-gray-800">
                        <i className="fs-1 ki-duotone ki-calendar me-2">
                            <i className="path1"></i>
                            <i className="path2"></i>
                            <i className="path3"></i>
                            <i className="path4"></i>
                            <i className="path5"></i>
                            <i className="path6"></i>
                        </i>
                        <div className={' badge badge-light'}>02.02.23 13:00</div>
                    </div>
                    <div className="d-flex align-items-center text-gray-800">
                        <i className="fs-1 ki-duotone ki-notification-status me-2">
                            <i className="path1"></i>
                            <i className="path2"></i>
                            <i className="path3"></i>
                            <i className="path4"></i>
                        </i>
                        <div className={'fs-7 fw-bold text-truncate text-gray-600'}>
                            <div className={' badge badge-light-success'}>Approved</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard
