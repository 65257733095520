import React, { useMemo } from 'react'
import { removeDataAttributes } from '../../../../functions/adapters/universal/removeDataAttributes'
import _ from 'lodash'
import Icon from '../../../../app/icon/Icon'

interface UnitPreviewProps {
    unit?: any
}

export default function UnitPreview ({ unit }: UnitPreviewProps): JSX.Element {
    const normalizedUnit = useMemo(() => removeDataAttributes(_.cloneDeep(unit)), [unit])
    return (
        <>
            <div
                className={'d-flex flex-column min-w-150px w-150px  text-start position-relative border border-gray-500 rounded'}
            >
                <div

                    className="rounded-0 rounded-top w-100 h-175px overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center cover-fit mb-1"
                    style={{
                        backgroundImage: `url(${String(normalizedUnit?.Images?.[0] ?? 'https://drive.google.com/uc?id=1arke0MLlUGDaD9b3GfdJsos3wRx39OFW&export=download')})`
                    }}
                >
                    <div className="h-100px w-100"></div>
                    <div className="d-flex flex-wrap fw-bold fs-6 mb-1 m-0 p-2 rounded-0 border-top-0 ">

                    </div>

                </div>
                <div className={'p-2'}>
                    <div className="btn btn-sm btn-light text-start p-0 px-2 me-2 mb-1 disable button-text">
                        <Icon name={'home'} className={'text-muted pe-1'} useWrapper/>
                        <span data-id="dashboard_user_viewings_slider_template_location">
                            { normalizedUnit?.Property?.Name ?? 'Building Name' }
                        </span>
                    </div>
                    <div className="btn btn-sm btn-light text-start p-0 px-2 me-2 mb-1 disable button-text">
                        <Icon name={'location_on'} className={'text-muted'} useWrapper/>
                        <span data-id="dashboard_user_viewings_slider_template_datetime">
                            { normalizedUnit?.Property?.Area ?? 'Building Location' }
                        </span>
                    </div>

                    <div className="btn btn-sm btn-light text-start p-0 px-2 me-2 mb-0 disable button-text">
                        <Icon name={'door_open'} className={'text-muted pe-2'} useWrapper/>
                        <span >
                            { normalizedUnit?.Number ?? 'Unit Number' }
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}
