import React, { useEffect, useState } from 'react'
import { List } from 'antd'

import Icon from '../../../../app/icon/Icon'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { getReferrals } from '../../../../store/actionsCreators/userActions'
import Swal from 'sweetalert2'
import _ from 'lodash'
import Referral from '../../referrals/Referral'

export default function ReferralsList(): JSX.Element {
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)

    const referralLink = `${window.location.protocol}//${window.location.host}/auth/register1?referral-id=${String(currentUser.id)}`

    const referrals = currentUser.Referrals // useAppSelector((state: RootState) => state.user.currentUser.Referrals)

    //  console.log(currentUser, 'currentUser')
    // useEffect(() => {
    //     void dispatch(getReferrals())
    // }, [])

    const bgLable = '/assets/media/schedule/navigation/untitled_design.png'

    return (

        <>
            <div className="card shadow-sm bg-light-warning card-flush my-4 mx-2 overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center shadow-sm"
                style={{ backgroundImage: `url(${bgLable})`, minHeight: '180px', maxHeight: '220px' }}>
                <>

                    <div className="card-title d-flex flex-column  p-4 py-2">
                        <div className="d-flex align-items-center mt-2">
                            <Icon name={'wallet'} filled className={'text-dark fs-2x'} />
                            <span className="fs-1hx fw-bold text-dark ms-2 lh-1 ls-n2"> 2,000</span>
                            <span className="badge badge-light-success ">
                                <i className="ki-duotone ki-arrow-up fs-8 text-success ms-n1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                                +1000 BC
                            </span>
                        </div>
                        <span className="text-gray-700  fw-semibold  fs-7">Your BSO Credits<span className="fs-7 ms-1 badge badge-circle badge-dark border border-dark opacity-50" >!</span> </span>
                    </div>

                    <div className="card-title d-flex flex-column p-4 py-2">
                        <div className="d-flex align-items-center">
                            <Icon name={'person'} className={'text-dark fs-2x'} />
                            <span className="fs-1hx fw-bold text-dark ms-2 lh-1 ls-n2"> 0</span>
                            <span className="badge badge-light-success ">
                                {/* <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i> */}
                                {/* 0 */}
                            </span>
                        </div>
                        <span className="text-gray-700  fw-semibold fs-7">Active Friends <span className="fs-7 ms-1 badge badge-circle badge-dark border border-dark opacity-50" >!</span> </span>
                    </div>

                    <div className="card-title d-flex flex-column p-4 py-2">
                        <div className="d-flex align-items-center">
                            <Icon name={'person'} className={'text-dark fs-2x'} />
                            <span className="fs-1hx fw-bold text-dark ms-2 lh-1 ls-n2"> 1</span>
                            <span className="badge badge-light-success">
                                <i className="ki-duotone ki-arrow-up text-success ms-n1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                                +1
                            </span>
                        </div>
                        <span className="text-gray-700  fw-semibold  fs-7">Referred Friends <span className="fs-7 ms-1 badge badge-circle badge-dark border border-dark opacity-50" >!</span> </span>
                    </div>

                </>
            </div>

            <div className="page d-flex flex-column flex-row-fluid my-4 ">
                <h2 className='text-center'>Share your link</h2>
                <div className="text-center px-6 fw-semibold fs-6 text-gray-600 lh-sm">
                    Invite your friends to join BSOClub and start earning BSO Credits!
                </div>
                <div className="card shadow-sm m-2">
                    <div className="card-body p-2 py-1 row align-items-center">
                        <div className="col-9 text-truncate text-gray-500 pe-2  fs-4">
                            {referralLink}
                        </div>
                        <div className="col-3 d-flex justify-content-end">
                            <button className="btn btn-bso text-nowrap w-100"
                                onClick={() => {
                                    void navigator?.clipboard?.writeText(referralLink)
                                        .then(() => {
                                            void Swal.fire('Referral link is successfully copied', `<strong>${referralLink}</strong>`, 'success')
                                        })
                                }}
                            >
                                Copy
                            </button>
                        </div>
                    </div>
                </div>

                {referrals && (referrals.length > 0) &&
                    <div className="">
                        <div className="page d-flex flex-column flex-row-fluid my-2 ">
                            <h2 className='text-center'>Recent Refferals</h2>
                        </div>

                        <div className="d-flex flex-row gap-4 w-100 scrollbar-width overflow-x-auto px-4 mb-4 mt-2 ">
                            {
                                _.map(referrals, (referral) =>
                                    <Referral user={referral} />
                                )
                            } </div>

                    </div>

                }
            </div>
        </>

    )
}
