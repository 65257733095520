import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { mainButtonClosing } from '../../../store/slices/modalSlice'
import AgentProfileSecurity from './AgentProfileSecurity'
import AgentBilling from './AgentBilling'
import AgentProfileSettings from './AgentProfileSettings'
import AgentNotifies from './AgentNotifies'
import AgentHeader from '../../agentModule/AgentHeader'
import OrdersSubscriptions from './ordersSubscriptions/OrdersSubscriptions'
import { type User, type IOrder, type ISettings } from '../../../store/slices/userSlice'
import { useParams } from 'react-router'
import LandlordHeader from '../LandlordHeader'
interface IProps {
    dinamicRoutes: boolean
}

export default function LandlordSettings({ dinamicRoutes }: IProps): JSX.Element {
    const { mainButtonOpen, buttonOrdersHistoryOpen, buttonSubscriptionsOpen } = useAppSelector((state: RootState) => state.modal)
    const { email, subscription } = useParams()
    if (dinamicRoutes) {

    }
    const currentUser = useAppSelector(
        (state: RootState) => state.user.currentUser as User
    )
    const userID = currentUser.id

    const { loading } = useAppSelector(
        (state: RootState) => state.modal
    )
    const dispatch = useAppDispatch()
    const settings = currentUser?.Settings as ISettings[]

    const notifies = settings?.[0]?.Notifies ?? null
    const orders = currentUser.Orders as IOrder[]
    const ServiceAgentSubscription = orders
        ? orders.filter((order: IOrder) =>
            order.Service?.some(
                (service) => service.__component === 'services.broker-subscription'
            )
        )
        : []
    ServiceAgentSubscription?.sort((a, b) => {
        const aCreatedAt = a.createdAt != null ? new Date(a.createdAt) : new Date(0)
        const bCreatedAt = b.createdAt != null ? new Date(b.createdAt) : new Date(0)
        return bCreatedAt.getTime() - aCreatedAt.getTime()
    })
    const latestServiceAgentSubscription = ServiceAgentSubscription?.[0]
    const activeSub = latestServiceAgentSubscription?.Service?.[0]?.Package ?? '' // 'free'

    useEffect(() => {
        dispatch(mainButtonClosing())
    }, [])

    return (
        <>
            <div
                className="page d-flex flex-column flex-row-fluid fs-5"
            >
                <LandlordHeader active="Settings" />
                <div
                    className="content d-flex flex-column flex-fill p-0 m-4"
                >
                    <div className="w-100 mw-450px mx-auto">
                        <AgentProfileSecurity />
                        <AgentProfileSettings userID={userID} activeSub={activeSub} />
                        <OrdersSubscriptions orders={orders} latestServiceAgentSubscription={latestServiceAgentSubscription} />
                        <AgentBilling />
                        { /* <CreditsBSO currentUser={currentUser} /> */}
                        <AgentNotifies notifies={notifies} />

                        {/* <AgentIdAndLicence
                        /> */}
                        {/* <AgentGeneralInfo
                        />
                        <AgentIdAndLicence
                        />

                        {/* <AgentProfileSecurityOld /> */}
                    </div>
                </div>
                <div className="m-0" style={{ height: `${mainButtonOpen ? '0' : '95px'}` }} />
            </div>
        </>
    )
}
