import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { useNavigate } from 'react-router-dom'
import { clientsSetSearchString } from '../../../store/slices/userSlice'

const ClientsHeader = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { clientsSearchString } = useAppSelector((state: RootState) => state.user)
    const userDevice = useAppSelector((state: RootState) => state.app.userDevice)
    const navigate = useNavigate()
    return (
        <>
            <div className={`row w-100 g-0 px-4 bg-white pb-4 top-0 ${userDevice === 'IPhone' ? 'pt-10' : ''}`}>
                <div className="d-flex flex-stack">
                    {/* <i className="ki-duotone ki-left-square fs-3x mt-5 me-4"
                        onClick={() => {
                            navigate('/agent/menu')
                        }}
                    >
                        <i className="path1"></i>
                        <i className="path2"></i>
                    </i> */}
                    <div className="position-relative w-100">
                        <input
                            type="text"
                            className="form-control form-control-solid form-control-sm mt-4"
                            placeholder="My clients"
                            autoComplete="false"
                            value={ clientsSearchString }
                            onChange={ (e) => {
                                dispatch(clientsSetSearchString(e.target.value))
                            }}
                        />
                        <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n6 mt-2"
                            onClick={() => {
                                dispatch(clientsSetSearchString(''))
                            }}
                        >
                            {/* <i className="bi bi-x fs-1"></i> */}
                        </span>
                    </div>
                    { /* <i className="bi bi-search fs-4 m-0 p-0"></i> */}
                    { /* <i className="ki-duotone ki-information-2 fs-3x mt-4 ms-4 p-0"
                        onClick={() => {

                        }}
                    >
                        <i className="path1"></i>
                        <i className="path2"></i>
                        <i className="path3"></i>
                    </i> */}
                </div>
            </div>
        </>
    )
}

export default ClientsHeader
