import { createSlice } from '@reduxjs/toolkit'

export interface Config {
    all: All
    users: Users
    'user-notifies': UserNotifies
    units: Units
    'unit-keys': UnitKeys
    viewings: Viewings
    offers: Offers
    portfolios: Portfolios
    properties: Properties
    landlords: Landlords
    leases: Leases
    tenants: Tenants
    documents: Documents
    tasks: Tasks
    orders: Orders
    comments: Comments
    'unit-advertises': UnitAdvertises
}

export interface All {
    Country: string[]
    Gender: string[]
    Currency: string[]
    Area: string[]
    Bank: string[]
}

export interface Users {
    Category: string[]
    Settings: Settings
}

export interface Settings {
    Emirate: string[]
    Memdership: string[]
    Type: string[]
}

export interface UserNotifies {
    Rule: string[]
    Type: string[]
}

export interface Units {
    Type: string[]
    Fit: string[]
    View: string[]
    Features: string[]
    Amenities: string[]
    Appliances: string[]
    Utilities: Utilities
    Marketing: Marketing
    ManagedBy: string[]
    Statuses: string[]
}

export interface Utilities {
    ACType: string[]
}

export interface Marketing {
    Status: string[]
    Use: string[]
}

export interface UnitKeys {
    Status: string[]
}

export interface Viewings {
    Notify: string[]
    Statuses: string[]
}

export interface Offers {
    Type: Type
    Notify: string[]
    Statuses: string[]
}

export interface Type {
    Residential: Residential
    Sale: Sale
}

export interface Residential {
    Occupants: string[]
    PetType: string[]
}

export interface Sale {
    Type: string[]
}

export interface Portfolios {
    Statuses: string[]
}

export interface Properties {
    Type: string[]
    Statuses: string[]
    Utilities: Utilities2
}

export interface Utilities2 {
    GasSupplierName: string[]
    CoolingProviderName: string[]
}

export interface Landlords {
    PowerOfAttorney: PowerOfAttorney
    Statuses: string[]
}

export interface PowerOfAttorney {
    Activity: string[]
    Status: string[]
    Roles: string[]
}

export interface Leases {
    Payments: Payments
    Statuses: string[]
}

export interface Payments {
    PaymentMethod: string[]
    BeneficiaryTitle: string[]
}

export interface Tenants {
    Statuses: string[]
}

export interface Documents {
    Type: Type2
    Statuses: string[]
}

export interface Type2 {
    PowerOfAttorney: PowerOfAttorney2
    TitleDeed: TitleDeed
}

export interface PowerOfAttorney2 {
    Activity: string[]
    Roles: string[]
}

export interface TitleDeed {
    Type: string[]
    Property: Property
}

export interface Property {
    Type: string[]
}

export interface Tasks {
    Type: string[]
    Checkpoints: Checkpoints
    Works: Works
    Statuses: string[]
}

export interface Checkpoints {
    Type: string[]
}

export interface Works {
    Status: string[]
}

export interface Orders {
    Statuses: string[]
    Service: Service
}

export interface Service {
    FindingBuyer: FindingBuyer
    FindingTenant: FindingTenant
    LegalNotice: LegalNotice
    Maintenance: Maintenance
    PowerOfAttorney: PowerOfAttorney3
    RentalDisputeCenter: RentalDisputeCenter
    BrokerSubscription: BrokerSubscription
}

export interface FindingBuyer {
    Package: string[]
    AdditionalServices: string[]
}

export interface FindingTenant {
    Package: string[]
    AdditionalServices: string[]
}

export interface LegalNotice {
    Type: string[]
}

export interface Maintenance {
    Type: string[]
}

export interface PowerOfAttorney3 {
    Roles: string[]
}

export interface RentalDisputeCenter {
    Against: string[]
}

export interface BrokerSubscription {
    Package: string[]
}

export interface Comments {
    Statuses: string[]
}

export interface UnitAdvertises {
    Status: string[]
}

interface AppState {
    fullscreenVideo: boolean
    config?: Config
    microphoneAccessProvided: boolean
    userDevice: 'IPhone' | 'Android' | ''
    dataLoaded: boolean
    configDBState: null
}

const initialState: AppState = {
    fullscreenVideo: false,
    config: undefined,
    microphoneAccessProvided: false,
    userDevice: '',
    dataLoaded: false,
    configDBState: null
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setFullscreenVideo (state, action) {
            state.fullscreenVideo = action.payload
        },
        setConfig (state, action) {
            state.config = action.payload
        },
        setMicrophoneAccessProvided (state, action) {
            state.microphoneAccessProvided = action.payload
        },
        setUserDevice (state, action) {
            state.userDevice = action.payload
        },

        setDataLoaded (state, action) {
            state.dataLoaded = action.payload
        },
        setConfigDBState (state, action) {
            state.configDBState = action.payload
        }

    }
})

export const {
    setFullscreenVideo,
    setConfig,
    setMicrophoneAccessProvided,
    setUserDevice,
    setDataLoaded,
    setConfigDBState
} = appSlice.actions
export default appSlice.reducer
