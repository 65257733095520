import _ from 'lodash'
import { AgentModals } from '../../agent/AgentModals'

export default function fillFaqModal (modalID: string, rawFilePath: string): { modal: Record<string, unknown> } {
    const modal = _.cloneDeep(
        AgentModals().find((modal: any) => modal.id === modalID)
    )
    const rawFilePathSplitRaw = rawFilePath.split('-')
    const rawFilePathSplit = [rawFilePathSplitRaw[0], rawFilePathSplitRaw.slice(1, rawFilePathSplitRaw.length).join('-')]
    const title = rawFilePathSplit[1].replaceAll('_', ' ').replaceAll('~', '/')
    modal.header = title[0].toUpperCase() + title.substring(1) + '?'

    const markdownRow = modal.form.find((row: any) => row.id === 'markdown')
    markdownRow.filePath = '/assets/texts/faq/'.concat(rawFilePath.replace('-', '/'), '.md')
    return {
        modal
    }
}
