import Fuse from 'fuse.js'

const searchFuse = (array: any[], query: string, threshold: number, keys?: Array<Fuse.FuseOptionKey<any>>, includeScore: boolean = true): any[] => {
    const fuse = new Fuse(array, {
        keys,
        threshold,
        includeScore
    })
    return fuse.search(query)
}

export default searchFuse
