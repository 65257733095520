import React, { type FormEvent, useState } from 'react'
import { Controller, type UseFormGetValues } from 'react-hook-form'
import UnitSlider from '../../../components/shared/units/cards/UnitSlider'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import _ from 'lodash'
import { type IFile } from '../../../types'

interface CustomFileInputProps {
    row: any
    register: any
    control: any
    errors: any
    getValues: UseFormGetValues<any>
}

const CustomFileInput = ({ row, register, control, errors, getValues }: CustomFileInputProps): JSX.Element => {
    const [changed, setChanged] = useState(false)
    const { images }: { images: Array<IFile | null> } = useAppSelector((state: RootState) => state.modal)
    return (
        <>
            <Controller
                control={control}
                name={row.id}
                rules={row.required === true ? { required: 'Recipe picture is required' } : {}}
                render={({ field: { value, onChange, ...field } }) => (
                    <div className="fv-row mb-2 m-0 p-0 w-100 fv-plugins-icon-container">
                        <label className={
                            (row.required)
                                ? 'required form-label mb-2 fw-bold text-dark text-uppercase'
                                : 'form-label fw-bold mb-2 text-dark text-uppercase'
                        }> {row.content?.[0]}</label>
                        {(row.default !== undefined && !changed) || images?.length > 0
                            ? <UnitSlider unit={{ attributes: { Images: images?.length > 0 ? _.map(images, image => image?.data) : row.default } }} />
                            : <></>
                        }
                        <input
                            /* {...register(row.id, { required: `${row.content?.[0]} is required` })} */
                            className="form-control form-control-solid form-control-lg"
                            onChange={(event) => {
                                setChanged(true)
                                onChange(event.target.files)
                            }}
                            onInputCapture={row.onInput != null
                                ? (e: FormEvent<HTMLInputElement>) => {
                                    row.onInput(e, getValues)
                                }
                                : () => { }}
                            name={row.id}
                            type="file"
                            multiple={true}
                            placeholder="Choose Files" />
                        {errors[row.id] && (
                            <div className="text-danger">{errors[row.id].message}</div>
                        )}

                    </div>
                )}
            />
        </>
    )
}

export default CustomFileInput
