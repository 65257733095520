import React from 'react'
import { Link } from 'react-router-dom'
export interface ILink {
    id: string
    title: string
    icon?: string
    description: string[]
    col?: number
    img?: string
    class?: string
    type?: string
}
export default function AppLink ({ link }: { link: ILink }) {
    const colStr = link.col !== undefined ? link.col.toString() : ''
    if (link.type === 'page' && link.img !== undefined) {
        return (
            <Link to={`/landlord/modal/${link.id}/-/-`} className="col-lg-3 col-md-6 col-sm-6 text-center">
                <div
                    className="anime-card rounded-2 text-center m-0 h-100px overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center shadow-sm"
                    style={{ backgroundImage: `url(/assets/media${link.img})` }}>
                    <div className="d-flex flex-wrap flex-fill g-0 p-4 m-0 h-80px">
                        <div className="d-flex flex-column col-7">
                            <div className="fw-bold fs-5 text-dots-app text-dark text-start">{link.title}</div>
                            { link.description.length > 0
                                ? <div className="d-flex flex-wrap align-items-start">
                                    <div className="text-start me-1 p-0 mb-0 disable"
                                        style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: '#7E8299' }}>
                                        <span>{link.description[0]}</span>
                                    </div>
                                    <div className="text-start me-1 p-0 mb-0 disable"
                                        style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: '#7E8299' }}>
                                        <span>{link.description[1]}</span>
                                    </div>
                                </div>
                                : <></>
                            }
                        </div>
                        <div className="d-flex flex-column col-2">
                            <div className="d-flex flex-row h-30px"></div>
                            <div className="d-flex flex-row px-2">
                                {/* <span className={`bi ${link.icon} fs-2x m-0 p-0`}/> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        )
    } else if (link.type === 'post' && link.img !== undefined) {
        return (
            <Link to={`/landlord/modal/${link.id}`} data-post-button={link.id} className="col-lg-3 col-md-6 col-sm-6 text-center" style={{ height: 'fit-content' }}>
                <div
                    className="anime-card rounded text-center m-0 h-100px overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-end bgi-position-y-center shadow-sm"
                    // style={{ backgroundImage : `url(/assets/sharedMedia${link.img})`}}
                >
                    <div className="d-flex flex-wrap flex-fill g-0 p-4 m-0 h-80px">
                        <div className="d-flex flex-column w-100">
                            <div className="fw-bold fs-7 text-dark text-start">{link.title}</div>
                            { link.description.length > 0
                                ? <div className="d-flex flex-wrap align-items-start fs-8">
                                    <div className="text-start me-1 p-0 mb-0 disable"
                                        style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: '#7E8299' }}>
                                        <span>{link.description[0]}</span>
                                    </div>
                                    <div className="text-start me-1 p-0 mb-0 disable"
                                        style={{ /* whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', */ color: '#7E8299' }}>
                                        <span>{link.description[1]}</span>
                                    </div>
                                </div> : <></>
                            }
                        </div>
                        {/* <div className="d-flex flex-column col-2">
                            <div className="d-flex flex-row h-30px"></div>
                            <div className="d-flex flex-row px-2">
                                <span className={`bi ${link.icon} fs-2x m-0 p-0`}/>
                            </div>
                        </div> */}
                    </div>
                </div>
            </Link>
        )
    } else {
        return (
            <Link to={`/landlord/modal/${link.id}`} className="btn btn-sm btn-white gradient-card border border-secondary border-1 p-1 m-0 rounded-4 shadow-sm" style={{ width: '100%' }}>
                <div className="d-flex flex-row flex-fill g-0 p-4 m-0 h-100px">
                    <div className="d-flex flex-column col-11">
                        <div className="fw-bold fs-5 text-dots-app text-dark text-start">{link.title}</div>
                        { link.description.length > 0
                            ? <div className="d-flex flex-wrap align-items-start">
                                <div className="text-start me-1 p-0 mb-0 disable"
                                    style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: '#7E8299' }}>
                                    <span>{link.description[0]}</span>
                                </div>
                                <div className="text-start me-1 p-0 mb-0 disable"
                                    style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: '#7E8299' }}>
                                    <span>{link.description[1]}</span>
                                </div>
                            </div>
                            : <></>
                        }
                    </div>
                    <div className="d-flex flex-column col-1">
                        <div className="d-flex flex-row h-50px"></div>
                        <div className="d-flex flex-row ">
                            {/* <span className={`bi ${link.icon} fs-2x m-0 p-0`}/> */}
                        </div>
                    </div>
                </div>
            </Link>
        )
    }
}
