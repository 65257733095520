import _ from 'lodash'
import { type Unit } from '../../../store/slices/unitsSlice'
import moment from 'moment'
import dayjs from 'dayjs'
import customLog from '../../log'
import { uploadStrapiFile } from '../../../store/actionsCreators/fileActions'
import { nanoid } from 'nanoid'
import { setOfferStatus } from '../../../store/actionsCreators/offerActions'
import { swalOptions, swalSuccess } from '../../swalOptions'
import swalConfig from '../../../app/texts/swalTexts'
import { type FormEvent } from 'react'

import { modalSlice, setScannedData } from '../../../store/slices/modalSlice'
import { getName, registerLocale } from 'i18n-iso-countries'
import fillFieldInForm from '../../shared/modals/fillFieldInForm'
import { store } from '../../../store/store'
import en from 'i18n-iso-countries/langs/en.json'
import getValueInForm from '../../shared/modals/getValueInForm'
import Swal from 'sweetalert2'
import { removeDataAttributes } from '../../adapters/universal/removeDataAttributes'
import { getCountries } from '../../getDataForSelects/dataFunctions'
import { scanDocuments } from '../../../store/actionsCreators/documentsActions/scaning/scanDocuments'
import { createDocument } from '../../../store/actionsCreators/documentsActions/createDocument'
import { scanEmiratesID } from '../../../store/actionsCreators/documentsActions/scanEmiratesID'

registerLocale(en)

const fillAddOfferModal = (modal: any, unitID: number, units: Unit[], currentUser: any, objectID: string, initialState: any, dispatch = (callback: any = () => {}) => callback()): { modal: any, initialState: Record<string, any> } => {
    let currentUnit: any = {}
    if (unitID !== -1) {
        currentUnit = units?.filter((el: any) => el.id === Number(unitID))?.[0]
    }
    const state: Record<string, any> = {}
    if (objectID === '-') {
        for (const row of modal[0].form) {
            switch (row.id) {
                case 'Type.FirstName':
                    state[row.id] = store.getState().modal.scannedData['Scanned.FirstName']
                    break
                case 'Type.LastName':
                    state[row.id] = store.getState().modal.scannedData['Scanned.LastName']
                    break
                case 'Type.Country':
                    state[row.id] = getName(store.getState().modal.scannedData['Scanned.Country'], 'en', { select: 'alias' })
                    break
                case 'marketing_headline':
                    row.content = [currentUnit?.attributes?.Marketing?.Headline ?? '']
                    break
                case 'images_slider':
                    row.content = currentUnit?.attributes?.Images ?? []
                    break
                case 'client_birthday':
                    state[row.id] = initialState?.client_birthday != null
                        ? dayjs((new Date(
                            _(initialState?.client_birthday)?.split('')?.chunk(2)?.map((item: any) => item.join(''))?.join('/') ?? ''
                        )))
                        : undefined
                    break
                case 'Type.Type':
                    row.onChange = (value: string) => {
                        try {
                            const preApprovedAmount: HTMLElement | null = document.getElementById('Type.PreApprovedAmount')
                            const bankPreApproval: HTMLElement | null = document.getElementById('Type.BankPreApproval')
                            if (preApprovedAmount != null && bankPreApproval != null) {
                                const amountInputContainer = preApprovedAmount?.children?.[0]
                                const amountInputWrapper = amountInputContainer?.children?.[1]
                                const amountInput = amountInputWrapper?.children[0] as HTMLInputElement

                                if (value === 'Mortgage Buyer') {
                                    amountInput.value = ''
                                    amountInput.focus()
                                    dispatch(setScannedData(
                                        {
                                            ...store.getState().modal.scannedData,
                                            BankPreapproval: 'Yes'
                                        }
                                    ))
                                    preApprovedAmount.style.visibility = 'visible'
                                    preApprovedAmount.style.position = 'static'
                                    bankPreApproval.style.visibility = 'visible'
                                    bankPreApproval.style.position = 'static'
                                } else {
                                    amountInput.value = '00'
                                    amountInput.focus()
                                    dispatch(setScannedData(
                                        {
                                            ...store.getState().modal.scannedData,
                                            BankPreapproval: 'No'
                                        }
                                    ))
                                    preApprovedAmount.style.visibility = 'hidden'
                                    preApprovedAmount.style.position = 'absolute'
                                    bankPreApproval.style.visibility = 'hidden'
                                    bankPreApproval.style.position = 'absolute'
                                }
                            }
                        } catch (err) {
                            console.error(err)
                        }
                    }
                    break
                case 'Type.BankPreApproval':
                    row.trigger = 'scannedData.BankPreapproval'
                    row.onChange = (value: string) => {
                        try {
                            const preApprovedAmount: HTMLElement | null = document.getElementById('Type.PreApprovedAmount')
                            if (preApprovedAmount != null) {
                                if (value === 'Yes') {
                                    const inputContainer = preApprovedAmount?.children?.[0]
                                    // customLog(inputContainer)
                                    const inputWrapper = inputContainer?.children?.[1]
                                    // customLog(inputWrapper)
                                    const input = inputWrapper?.children[0] as HTMLInputElement
                                    // customLog(input)
                                    input.value = ''
                                    preApprovedAmount.style.visibility = 'visible'
                                    preApprovedAmount.style.position = 'static'
                                } else {
                                    // preApprovedAmount
                                    const inputContainer = preApprovedAmount?.children?.[0]
                                    const inputWrapper = inputContainer?.children?.[1]
                                    // customLog(inputWrapper)
                                    const input = inputWrapper?.children?.[0] as HTMLInputElement
                                    // customLog(input)
                                    input.value = '00'
                                    input.focus()
                                    preApprovedAmount.style.visibility = 'hidden'
                                    preApprovedAmount.style.position = 'absolute'
                                }
                            }
                        } catch (err) {
                            console.error(err)
                        }
                    }
                    state[row.id] = 'Yes'
                    break
                case 'Type.Pets':
                    row.onChange = (value: string) => {
                        try {
                            const petType: HTMLElement | null = document.getElementById('Type.PetType')
                            if (petType != null) {
                                if (value === 'Yes') {
                                    /* const inputContainer = preApprovedAmount?.children?.[0]
                                    customLog(inputContainer)
                                    const inputWrapper = inputContainer?.children?.[1]
                                    customLog(inputWrapper)
                                    const input = inputWrapper?.children[0] as HTMLInputElement
                                    customLog(input)
                                    input.value = '' */
                                    petType.style.visibility = 'visible'
                                    petType.style.position = 'static'
                                } else {
                                    dispatch(setScannedData(
                                        {
                                            ...store.getState().modal.scannedData,
                                            PetType: 'Other'
                                        }
                                    ))
                                    petType.style.visibility = 'hidden'
                                    petType.style.position = 'absolute'
                                }
                            }
                        } catch (err) {
                            console.error(err)
                        }
                    }
                    state[row.id] = 'Yes'
                    break
                case 'Scanned.DocumentType':
                    row.onChange = (value: string) => {
                        dispatch(setScannedData({
                            ...store.getState().modal.scannedData,
                            documentType: value
                        }))
                    }
                    break
                case 'Scanned.ClientDocument':
                    row.onScan = (documentType: string) => async (data: any): Promise<boolean> => {
                        // console.log(data)
                        // const fileList = [uploadedFile]
                        dispatch(modalSlice.actions.dataFetchingStart())
                        let document: Record<string, any> | null
                        if (documentType === 'Emirates ID') {
                            document = await dispatch(scanEmiratesID(data['emirates-id-front'], data['emirates-id-back']))
                        } else {
                            const response = await dispatch(scanDocuments([data.passport], 'Passport', true, `offer-${new Date().getTime()}-passport`, 'offers'))
                            document = response[0]?.response?.document
                        }
                        // if (fileList != null && fileList?.length > 0) {
                        /// const response = await dispatch(scanDocuments(fileList, documentType, true))

                        if (document?.valid === true) {
                            let nationality: string | undefined = getName(document.nationality, 'en', { select: 'alias' })
                            if (nationality === 'UAE') {
                                nationality = 'United Arab Emirates'
                            }
                            if (nationality && !getCountries().includes(nationality)) {
                                nationality = undefined
                            }
                            dispatch(setScannedData(
                                {
                                    ...store.getState().modal.scannedData,
                                    'Scanned.FirstName': _.split(document.name, ' ')[1],
                                    'Scanned.LastName': _.split(document.name, ' ')[0],
                                    'Scanned.EmiratesID': document.number,
                                    Country: nationality,
                                    DOB: dayjs((new Date(
                                        _(document?.dob)?.split('')?.chunk(2)?.map((item: any) => item.join(''))?.join('/') ?? ''
                                    )))
                                }
                            ))
                            const tasks = [
                                {
                                    id: 'Type.FirstName',
                                    value: _.split(document.name, ' ')[1],
                                    tagName: 'input'
                                },
                                {
                                    id: 'Type.LastName',
                                    value: _.split(document.name, ' ')[0],
                                    tagName: 'input'
                                },
                                {
                                    id: 'Type.EmiratesID',
                                    value: document.number,
                                    tagName: 'input'
                                }
                            ]
                            for (const task of tasks) {
                                await fillFieldInForm(task)
                            }
                        } else {
                            await Swal.fire({
                                ...swalOptions('error'),
                                title: 'Scanned document is not valid'
                            })
                        }
                        // } else {
                        //   console.log('no file list')
                        // }
                        dispatch(modalSlice.actions.dataFetchingFinish())
                        return document?.valid === true
                    }
                    break
                case 'Scanned.TradeLicence':
                    row.onInput = (e: FormEvent<HTMLInputElement>, formValues: Record<string, any>) => {
                        const fileList = (e.target as HTMLInputElement).files
                        dispatch(modalSlice.actions.dataFetchingStart())
                        void (async () => {
                            if (fileList != null && fileList?.length > 0) {
                                const response = await dispatch(createDocument(fileList, {
                                    RefID: `REF_DOC_trade_licence_${new Date().getTime()}_${0}`,
                                    Type: [
                                        {
                                            __component: 'documents.trade-license'
                                        }
                                    ],
                                    Category: 'Trade License',
                                    Statuses: ['In-process'],
                                    User: store.getState().user.currentUser.id
                                }))

                                if (response?.response?.data?.id != null) {
                                    dispatch(setScannedData(
                                        {
                                            ...store.getState().modal.scannedData,
                                            'Scanned.TradeLicenceID': response.response.data.id
                                        }))
                                } else {
                                    await Swal.fire({
                                        ...swalOptions('error'),
                                        title: 'Error occurred while uploading. try again'
                                    })
                                    await fillFieldInForm({
                                        id: 'Scanned.ClientDocument',
                                        value: null,
                                        tagName: 'input'
                                    })
                                }
                            }
                            dispatch(modalSlice.actions.dataFetchingFinish())
                        })()
                    }
                    break
                case 'Scanned.DepositPaySlip':
                    row.onInput = (e: FormEvent<HTMLInputElement>, formValues: Record<string, any>) => {
                        const fileList = (e.target as HTMLInputElement).files
                        //  console.log(fileList)
                        dispatch(modalSlice.actions.dataFetchingStart())
                        void (async () => {
                            if (fileList != null && fileList?.length > 0) {
                                const uploadedFile = await uploadStrapiFile(fileList[0], undefined, undefined, `deposit-pay-slip-unknown-${String(nanoid())}`)
                                if (uploadedFile != null) {
                                    const slipLink = uploadedFile.data
                                    dispatch(setScannedData(
                                        {
                                            ...store.getState().modal.scannedData,
                                            'Scanned.DepositSlipLink': slipLink
                                        }
                                    ))
                                }
                            }
                            dispatch(modalSlice.actions.dataFetchingFinish())
                        })()
                    }
                    break
                default:
                    break
            }
        }
    } else {
        modal[0].header = modal[0].header.replace('Add', 'Resubmit')
        // modal[0].footer[0].content = ['Save']
        const rawOffer = _.find(store.getState().offers.offers, (offerItem) => offerItem.id === +(objectID ?? '-1'))
        const offer = removeDataAttributes(_.cloneDeep(rawOffer ?? {}))
        for (const row of modal[0].form) {
            let path: string[]
            let stateValue: any
            switch (row.id) {
                case 'marketing_headline':
                    row.content = [offer?.Unit?.Marketing?.Headline ?? '']
                    break
                case 'images_slider':
                    row.content = offer?.Unit?.Images ?? []
                    break
                case 'Type[0].ContractStartDate':
                case 'Type.ContractStartDate':
                    //  console.log(offer.Type[0].ContractStartDate)
                    state[row.id] = dayjs(
                        _.join(
                            _.split(offer.Type[0].ContractStartDate ?? '', '-'),
                            '/'
                        )
                    )
                    break
                case 'client_birthday':
                    /* state[row.id] = dayjs((new Date(
                        _(initialState?.client_birthday)?.split('')?.chunk(2)?.map((item: any) => item.join(''))?.join('/') ?? ''
                    ))) */
                    row.remove = true
                    break
                /* case 'Type.BankPreApproval':
                    row.onChange = (value: string) => {
                        const preApprovedAmount: HTMLElement | null = document.getElementById('Type.PreApprovedAmount')
                        if (preApprovedAmount != null) {
                            if (value === 'Yes') {
                                const inputWrapper = preApprovedAmount?.children?.[preApprovedAmount?.children?.length - 1]
                                const input = inputWrapper?.children[0] as HTMLInputElement
                                input.value = ''
                                preApprovedAmount.style.display = 'block'
                            } else {
                                const inputWrapper = preApprovedAmount?.children?.[preApprovedAmount?.children?.length - 1]
                                const input = inputWrapper?.children[0] as HTMLInputElement
                                input.value = '0'
                                preApprovedAmount.style.display = 'none'
                            }
                        }
                    }
                    break */
                case 'Type.Type':
                    row.onChange = (value: string) => {
                        try {
                            const preApprovedAmount: HTMLElement | null = document.getElementById('Type.PreApprovedAmount')
                            const bankPreApproval: HTMLElement | null = document.getElementById('Type.BankPreApproval')
                            if (preApprovedAmount != null && bankPreApproval != null) {
                                const amountInputContainer = preApprovedAmount?.children?.[0]
                                const amountInputWrapper = amountInputContainer?.children?.[1]
                                const amountInput = amountInputWrapper?.children[0] as HTMLInputElement

                                if (value === 'Mortgage Buyer') {
                                    amountInput.value = ''
                                    amountInput.focus()
                                    dispatch(setScannedData(
                                        {
                                            ...store.getState().modal.scannedData,
                                            BankPreapproval: 'Yes'
                                        }
                                    ))
                                    preApprovedAmount.style.visibility = 'visible'
                                    preApprovedAmount.style.position = 'static'
                                    bankPreApproval.style.visibility = 'visible'
                                    bankPreApproval.style.position = 'static'
                                } else {
                                    amountInput.value = '00'
                                    amountInput.focus()
                                    dispatch(setScannedData(
                                        {
                                            ...store.getState().modal.scannedData,
                                            BankPreapproval: 'No'
                                        }
                                    ))
                                    preApprovedAmount.style.visibility = 'hidden'
                                    preApprovedAmount.style.position = 'absolute'
                                    bankPreApproval.style.visibility = 'hidden'
                                    bankPreApproval.style.position = 'absolute'
                                }
                            }
                        } catch (err) {
                            console.error(err)
                        }
                    }
                    state[row.id] = offer.Type[0].Type
                    break
                case 'Type.BankPreApproval':
                    row.trigger = 'scannedData.BankPreapproval'
                    row.onChange = (value: string) => {
                        try {
                            const preApprovedAmount: HTMLElement | null = document.getElementById('Type.PreApprovedAmount')
                            if (preApprovedAmount != null) {
                                if (value === 'Yes') {
                                    const inputContainer = preApprovedAmount?.children?.[0]
                                    // customLog(inputContainer)
                                    const inputWrapper = inputContainer?.children?.[1]
                                    // customLog(inputWrapper)
                                    const input = inputWrapper?.children[0] as HTMLInputElement
                                    // customLog(input)
                                    input.value = ''
                                    preApprovedAmount.style.visibility = 'visible'
                                    preApprovedAmount.style.position = 'static'
                                } else {
                                    // preApprovedAmount
                                    const inputContainer = preApprovedAmount?.children?.[0]
                                    const inputWrapper = inputContainer?.children?.[1]
                                    // customLog(inputWrapper)
                                    const input = inputWrapper?.children?.[0] as HTMLInputElement
                                    // customLog(input)
                                    input.value = '00'
                                    input.focus()
                                    preApprovedAmount.style.visibility = 'hidden'
                                    preApprovedAmount.style.position = 'absolute'
                                }
                            }
                        } catch (err) {
                            console.error(err)
                        }
                    }
                    if (offer.Type[0].Type !== 'Mortgage Buyer') {
                        row.defaultHidden = true
                    } else {
                        row.defaultHidden = false
                    }
                    state[row.id] = offer.Type[0].BankPreApproval === true ? 'Yes' : 'No'
                    break
                case 'Type.PreApprovedAmount':
                    if (offer.Type[0].BankPreApproval !== true) {
                        row.defaultHidden = true
                        state[row.id] = '00'
                    } else {
                        row.defaultHidden = false
                        state[row.id] = offer.Type[0].PreApprovedAmount
                    }
                    break
                case 'Type.Pets':
                    row.onChange = (value: string) => {
                        try {
                            const petType: HTMLElement | null = document.getElementById('Type.PetType')
                            if (petType != null) {
                                if (value === 'Yes') {
                                    /* const inputContainer = preApprovedAmount?.children?.[0]
                                    customLog(inputContainer)
                                    const inputWrapper = inputContainer?.children?.[1]
                                    customLog(inputWrapper)
                                    const input = inputWrapper?.children[0] as HTMLInputElement
                                    customLog(input)
                                    input.value = '' */
                                    petType.style.visibility = 'visible'
                                    petType.style.position = 'static'
                                } else {
                                    dispatch(setScannedData(
                                        {
                                            ...store.getState().modal.scannedData,
                                            PetType: 'Other'
                                        }
                                    ))
                                    petType.style.visibility = 'hidden'
                                    petType.style.position = 'absolute'
                                }
                            }
                        } catch (err) {
                            console.error(err)
                        }
                    }
                    state[row.id] = offer.Type[0].Pets === true ? 'Yes' : 'No'
                    break
                case 'Scanned.DocumentType':
                    row.onChange = (value: string) => {
                        dispatch(setScannedData({
                            ...store.getState().modal.scannedData,
                            documentType: value
                        }))
                    }
                    break
                case 'Scanned.ClientDocument':
                    row.onScan = (documentType: string) => async (data: any): Promise<boolean> => {
                        // console.log(data)
                        // const fileList = [uploadedFile]
                        dispatch(modalSlice.actions.dataFetchingStart())
                        let document: Record<string, any> | null
                        if (documentType === 'Emirates ID') {
                            document = await dispatch(scanEmiratesID(data['emirates-id-front'], data['emirates-id-back']))
                        } else {
                            const response = await dispatch(scanDocuments([data.passport], 'Passport', true, `offer-${new Date().getTime()}-passport`, 'offers'))
                            document = response[0]?.response?.document
                        }
                        // if (fileList != null && fileList?.length > 0) {
                        /// const response = await dispatch(scanDocuments(fileList, documentType, true))
                        if (document?.valid === true) {
                            const nameSplit: string[] = document.name.split(' ')
                            const LastName = nameSplit.shift()
                            const FirstName = nameSplit.join(' ')
                            dispatch(setScannedData(
                                {
                                    ...store.getState().modal.scannedData,
                                    'Scanned.FirstName': FirstName, // _.split(document.name, ' ')[1],
                                    'Scanned.LastName': LastName, // _.split(document.name, ' ')[0],
                                    'Scanned.EmiratesID': document.number,
                                    Country: getName(document.nationality, 'en', { select: 'alias' }),
                                    DOB: dayjs((new Date(
                                        _(document?.dob)?.split('')?.chunk(2)?.map((item: any) => item.join(''))?.join('/') ?? ''
                                    )))
                                }
                            ))
                            const tasks = [
                                {
                                    id: 'Type.FirstName',
                                    value: _.split(document.name, ' ')[1],
                                    tagName: 'input'
                                },
                                {
                                    id: 'Type.LastName',
                                    value: _.split(document.name, ' ')[0],
                                    tagName: 'input'
                                },
                                {
                                    id: 'Type.EmiratesID',
                                    value: document.number,
                                    tagName: 'input'
                                }
                            ]
                            for (const task of tasks) {
                                await fillFieldInForm(task)
                            }
                        } else {
                            await Swal.fire({
                                ...swalOptions('error'),
                                title: 'Scanned document is not valid'
                            })
                        }
                        // } else {
                        //   console.log('no file list')
                        // }
                        dispatch(modalSlice.actions.dataFetchingFinish())
                        return document?.valid === true
                    }
                    state[row.id] = offer?.Documents?.some((documentItem: any) => {
                        // console.log(documentItem, store.getState().documents.documents)
                        const storedDocument = removeDataAttributes(
                            _.cloneDeep(
                                store.getState().documents.documents.find(doc => doc.id === documentItem.id) ?? {}
                            )
                        )
                        // console.log(storedDocument)
                        return (storedDocument?.Type?.[0]?.__component === 'documents.emirates-id' || storedDocument?.Type?.[0]?.__component === 'documents.passport')
                    }) === true
                        ? 'Minka kadj'
                        : ''
                    row.default = state[row.id]
                    //  console.log('DOCUMENT STATE ', state[row.id])
                    break
                case 'Scanned.TradeLicence':
                    row.onInput = (e: FormEvent<HTMLInputElement>, formValues: Record<string, any>) => {
                        const fileList = (e.target as HTMLInputElement).files
                        dispatch(modalSlice.actions.dataFetchingStart())
                        void (async () => {
                            if (fileList != null && fileList?.length > 0) {
                                const response = await dispatch(createDocument(fileList, {
                                    RefID: `REF_DOC_trade_licence_${new Date().getTime()}_${0}`,
                                    Category: 'Trade License',
                                    Type: [
                                        {
                                            __component: 'documents.trade-license'
                                        }
                                    ],
                                    Statuses: ['In-process'],
                                    User: store.getState().user.currentUser.id
                                }))
                                //    console.log(response)
                                if (response?.response?.data?.id != null) {
                                    dispatch(setScannedData(
                                        {
                                            ...store.getState().modal.scannedData,
                                            'Scanned.TradeLicenceID': response.response.data.id
                                        }))
                                } else {
                                    await Swal.fire({
                                        ...swalOptions('error'),
                                        title: 'Error occurred while uploading. try again'
                                    })
                                    await fillFieldInForm({
                                        id: 'Scanned.ClientDocument',
                                        value: null,
                                        tagName: 'input'
                                    })
                                }
                            }
                            dispatch(modalSlice.actions.dataFetchingFinish())
                        })()
                    }
                    row.required = false
                    break
                case 'Scanned.DepositPaySlip':
                    row.onInput = (e: FormEvent<HTMLInputElement>, formValues: Record<string, any>) => {
                        const fileList = (e.target as HTMLInputElement).files
                        //      console.log(fileList)
                        dispatch(modalSlice.actions.dataFetchingStart())
                        void (async () => {
                            if (fileList != null && fileList?.length > 0) {
                                const uploadedFile = await uploadStrapiFile(fileList[0], undefined, undefined, `deposit-pay-slip-unknown-${String(nanoid())}`)
                                if (uploadedFile != null) {
                                    const slipLink = uploadedFile.data
                                    dispatch(setScannedData(
                                        {
                                            ...store.getState().modal.scannedData,
                                            'Scanned.DepositSlipLink': slipLink
                                        }
                                    ))
                                }
                            }
                            dispatch(modalSlice.actions.dataFetchingFinish())
                        })()
                    }
                    break
                default:
                    switch (row.id) {
                        case 'Type.Country':
                            if (modal?.[0]?.id?.includes('_sale') === true || modal?.[0]?.id?.includes('_company') === true) {
                                row.remove = true
                            }
                            break
                        case 'Type.FirstName':
                        case 'Type.LastName':
                            if (modal?.[0]?.id?.includes('_company') === true) {
                                row.remove = true
                            }
                            break
                        default:
                            break
                    }
                    let objectRowId: string = row.id
                    if (objectRowId.includes('Type.')) {
                        objectRowId = objectRowId.replace('Type.', 'Type[0].')
                    }
                    stateValue = _.get(offer, objectRowId)
                    if (_.isBoolean(stateValue)) {
                        stateValue = stateValue ? 'Yes' : 'No'
                    }
                    state[row.id] = stateValue
                    break
            }
        }

        modal[0].form = modal[0].form?.filter((row: any) => row.remove !== true)
        if (modal[0].id === 'negotiate_offer_modal' && offer?.Type[0]?.__component?.includes('sale')) {
            modal[0].form = modal[0].form?.filter((row: any) => ['Type[0].Offer', 'Type[0].AdditionalNotes'].includes(row.id))
        }
    }

    return {
        modal,
        initialState: { ...state, ...initialState, client_birthday: state.client_birthday }
    }
}

export default fillAddOfferModal
