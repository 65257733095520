import _ from 'lodash'
import { AgentModals } from '../../agent/AgentModals'
import { store } from '../../../store/store'

export const saleConfig: Record<string, Record<string, string>> = {
    'Waiting for updates': {
        title: 'Upload MOU',
        nextStatus: 'Under MOU',
        uploadLabel: 'Upload MOU',
        documentCategory: 'MOU'
    },
    'Under MOU': {
        title: 'Upload MOU',
        nextStatus: 'Mortgage process',
        uploadLabel: 'Upload MOU',
        documentCategory: 'MOU'
    },
    'Mortgage process': {
        title: 'Upload mortgage document',
        nextStatus: 'Obtaining NOC',
        uploadLabel: 'Upload mortgage document',
        documentCategory: 'Mortgage document'
    },
    'Obtaining NOC': {
        title: 'Upload NOC',
        nextStatus: 'Transfer process',
        uploadLabel: 'Upload NOC',
        documentCategory: 'NOC'
    },
    'Transfer process': {
        title: 'Upload transfer document',
        nextStatus: 'Completed',
        uploadLabel: 'Upload transfer document',
        documentCategory: 'Deposit Cheque'
    }
}
export const rentConfig: Record<string, Record<string, string>> = {
    'Slip approved': {
        title: 'Upload tenancy contract',
        nextStatus: 'Completed',
        uploadLabel: 'Upload tenancy contract',
        documentCategory: 'Tenancy Contract'
    }
}

export default function fillAdminOfferDocumentModal (offerID: number, modalID: string): { modal: any } {
    const modal = _.cloneDeep(AgentModals().find((modal: any) => modal.id === modalID))

    const offer = store.getState().offers.offers.find((offer) => offer.id === offerID)
    if (offer != null) {
        const offerStatus = offer.attributes?.Statuses?.[0]
        const offerType = offer.attributes?.Type?.[0]?.__component
        if (offerType === 'offer.sale' || offerType === 'offer.sale-commercial') {
            modal.header = saleConfig[offerStatus]?.title
            const row = modal.form.find((row: any) => row.id === 'document')
            row.content[0] = saleConfig[offerStatus]?.uploadLabel
        } else {
            modal.header = rentConfig[offerStatus]?.title
            const row = modal.form.find((row: any) => row.id === 'document')
            row.content[0] = rentConfig[offerStatus]?.uploadLabel
        }
    }
    return {
        modal
    }
}
