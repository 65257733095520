import { type User } from '../../../store/slices/userSlice'
import { store } from '../../../store/store'

interface ICustomNames {
    FirstName: string
    FamilyName: string
}

export const checkDocForProbabilityAgentName = (checkData: string, customNames?: ICustomNames): number => {
    let usFirstName
    let usFamilyName

    if (customNames == null) {
        const currentUser = store.getState().user.currentUser as User
        usFirstName = currentUser?.ContactInfo?.FirstName
        usFamilyName = currentUser?.ContactInfo?.FamilyName
    } else {
        usFirstName = customNames.FirstName
        usFamilyName = customNames.FamilyName
    }

    const lastNameWords = usFirstName ? usFirstName.toUpperCase().split(' ') : []
    const firstNameWords = usFamilyName ? usFamilyName.toUpperCase().split(' ') : []

    const allWords = lastNameWords.concat(firstNameWords)
    const checkDataWords = checkData.toUpperCase().split(' ')

    const matchingWords = allWords.filter((word: string) => checkDataWords.includes(word))
    const matchCount = matchingWords.length
    const totalWords = allWords.length

    let probability: number
    if (totalWords <= 2) {
        probability = matchCount === totalWords ? 100 : 0
    } else {
        probability = (matchCount / totalWords) * 100
    }

    return probability
}
