import _ from 'lodash'
import customLog from '../../../log'

/* export const removeDataAttributes = (entryObject: Record<string, any>): Record<string, any> => {
    let object = _.cloneDeep(entryObject)
    const keys = Object.keys(object)
    for (const key of keys) {
        if (key === 'data' || key === 'attributes') {
            object = _.omit(
                { ...object, ...removeDataAttributes(object[key]) },
                [key]
            )
        } else {
            object[key] = removeDataAttributes(object[key])
        }
    }
    return object
} */

/* export const removeDataAttributes = (object: Record<string, any>): Record<string, any> => {
    const keys = Object.keys(object)
    for (const key of keys) {
        if (key === 'data' || key === 'attributes') {
            const branch = object[key]
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete object[key]
            _.assign(object, removeDataAttributes(branch))
        } else if (_.isArray(object[key])) {
            object[key] = object[key].map(removeDataAttributes)
        } else {
            object[key] = removeDataAttributes(object[key])
        }
    }
    return object
} */

export const removeDataAttributes = (object: Record<string, any>): Record<string, any> => {
    if (_.isArray(object)) {
        object = object.map(removeDataAttributes)
    } else {
        const keys = _.keys(object)
        let pureObject: unknown
        for (const key of keys) {
            switch (key) {
                case 'data':
                    pureObject = removeDataAttributes(object.data)
                    if (_.isArray(pureObject)) {
                        object = pureObject
                    } else {
                        _.assign(object, pureObject)
                    }
                    delete object.data
                    break
                case 'attributes':
                    _.assign(object, removeDataAttributes(object.attributes))
                    delete object.attributes
                    break
                default:
                    if (_.isArray(object[key])) {
                        object[key] = object[key].map(removeDataAttributes)
                    } else if (_.isObject(object[key])) {
                        object[key] = removeDataAttributes(object[key])
                    }
                    break
            }
        }
    }
    return object
}

/**
 * {
 *     data: {
 *         attributes: {
 *              value: 1
 *          }
 *          id: 1
 *     }
 * }
 *
 *
 */
