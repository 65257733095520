import { type IViewing } from '../../../store/slices/viewingsSlice'

export const getPeriod = (startDate: any, viewings: any[]): any => {
    const startYear = new Date(startDate).getFullYear()
    const startMonth = new Date(startDate).getMonth()

    let maxViewingDate = viewings.length > 0
        ? new Date(Math.max(...viewings.map((viewing: IViewing) => {
            const date = viewing.attributes.Datetime === null ? viewing.attributes.createdAt : viewing.attributes.Datetime
            return new Date(date).getTime()
        })))
        : new Date()

    if (maxViewingDate < new Date()) {
        maxViewingDate = new Date()
    }

    const endYear = maxViewingDate.getFullYear()
    const endMonth = maxViewingDate.getMonth()

    const periods = []
    for (let year = startYear; year <= endYear; year++) {
        const startMonthOfYear = year === startYear ? startMonth : 0
        const endMonthOfYear = year === endYear ? endMonth : 11

        for (let month = startMonthOfYear; month <= endMonthOfYear; month++) {
            const monthViewings = viewings.filter((viewing: any) => {
                const viewingDate = new Date(viewing.attributes.Datetime === null ? viewing.attributes.createdAt : viewing.attributes.Datetime)
                return (
                    viewingDate.getFullYear() === year &&
                    viewingDate.getMonth() === month
                )
            })
            periods.push({
                year,
                month,
                viewings: monthViewings,
                viewingsLength: monthViewings.length
            })
        }
    }
    return periods
}
