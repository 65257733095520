import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Approved from '../../../functions/agent/statuses/Approved'
import InProcess from '../../../functions/agent/statuses/InProcess'
import Rejected from '../../../functions/agent/statuses/Rejected'
import { Collapse } from 'antd'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { type User } from '../../../store/slices/userSlice'
import NotAdded from '../../../functions/agent/statuses/NotAdded'
import InProcessScheduler from '../../../functions/agent/statuses/InProcessScheduler'
import AgentMainButton from '../../shared/elements/buttons/AgentMainButton'
import ActionLoadEmiratesID from '../../shared/elements/buttons/ActionLoadEmiratesID'
import ActionLoadPassport from '../../shared/elements/buttons/ActionLoadPassport'
import ActionLoadRera from '../../shared/elements/buttons/ActionLoadRera'

export function docStatus (arrayStatuses: string[]): JSX.Element {
    // console.log(arrayStatuses, 'arrayStatuses')
    if (arrayStatuses.includes('Approved')) return <Approved />
    else if (arrayStatuses.includes('In-process')) return <InProcessScheduler />
    else if (arrayStatuses.includes('Rejected')) return <Rejected />
    else return <div></div>
}

export default function AgentIdAndLicence (): JSX.Element {
    const currentUser = useAppSelector(
        (state: RootState) => state.user.currentUser
    )
    const documents = useAppSelector(
        (state: RootState) => state.documents.documents
    )
    // console.log(documents, 'documents')
    // console.log(currentUser, '________currentUser AgentGeneralInfo')
    const { Panel } = Collapse
    const userID = currentUser.id

    //  const userCONTACTS = (currentUser as User)?.Type?.[0]
    // const userDOCUMENTS = currentUser?.Documents

    // console.log(userDOCUMENTS, 'userDOCUMENTS')

    // const [agentDocs, setAgentDocs] = Array.isArray(currentUser?.Documents)
    //     ? currentUser?.Documents
    //     : []

    let agentDocs: any = []
    if (Array.isArray(documents)) {
        agentDocs = documents
    }
    const emiratesIdDoc = Array.isArray(agentDocs) && agentDocs.length > 0
        ? agentDocs.reduce((prevDoc, currentDoc) => {
            const prevDocType = prevDoc?.Type?.[0].__component
            const currentDocType = currentDoc?.Type?.[0].__component

            if (currentDocType === 'documents.emirates-id') {
                if (!prevDocType || new Date(currentDoc.updatedAt) > new Date(prevDoc.updatedAt)) {
                    return currentDoc
                }
            }

            return prevDoc
        }, null)
        : null

    const passportDoc = Array.isArray(agentDocs) && agentDocs.length > 0
        ? agentDocs.reduce((prevDoc, currentDoc) => {
            const prevDocType = prevDoc?.Type?.[0].__component
            const currentDocType = currentDoc?.Type?.[0].__component

            if (currentDocType === 'documents.passport') {
                if (!prevDocType || new Date(currentDoc.updatedAt) > new Date(prevDoc.updatedAt)) {
                    return currentDoc
                }
            }

            return prevDoc
        }, null)
        : null

    const reraCardDoc = Array.isArray(agentDocs) && agentDocs.length > 0
        ? agentDocs.reduce((prevDoc, currentDoc) => {
            const prevDocType = prevDoc?.Type?.[0].__component
            const currentDocType = currentDoc?.Type?.[0].__component
            //   console.log(currentDocType, 'currentDocType')
            if (currentDocType === 'documents.rera-card') {
                if (!prevDocType || new Date(currentDoc.updatedAt) > new Date(prevDoc.updatedAt)) {
                    return currentDoc
                }
            }

            return prevDoc
        }, null)
        : null

    const verifiedDocs = []
    if (emiratesIdDoc) {
        verifiedDocs.push(emiratesIdDoc)
    } if (passportDoc) {
        verifiedDocs.push(passportDoc)
    }
    const verifiedDoc = verifiedDocs.find((el) => el.Statuses.includes('Approved')) ?? verifiedDocs.find((el) => el.Statuses.includes('Rejected')) ?? verifiedDocs.find((el) => el.Statuses.includes('In-process'))
    //  console.log(verifiedDoc, 'verifiedDoc')
    useEffect(() => { }, [agentDocs])
    return (
        <div className="card shadow-sm mb-2">
            <Collapse defaultActiveKey={'2'}>
                <Panel
                    style={{ fontFamily: 'Poppins' }}
                    showArrow={false}
                    header={<span className="card-title fs-3 fw-bold fw-bold p-0 m-0">ID's and licenses</span>}
                    key="2"
                >

                    <div className="card-body m-0 p-0">
                        <div className="row g-0 d-flex flex-row">
                            <div className="col-9 fs-5 g-0 d-flex flex-column justify-content-center">
                                <span className='mb-2'>Emirates ID or Passport :</span>
                                <span className='mb-2'>RERA License:</span>
                            </div>
                            <div className="col-3 g-0 d-flex flex-column align-items-end">

                                <div className='mb-2'>   {verifiedDoc ? docStatus(verifiedDoc.Statuses) : <NotAdded/> }</div>
                                <div className='mb-2'>  {reraCardDoc ? docStatus(reraCardDoc.Statuses) : <NotAdded/> }</div>
                                {/* <span
                                    data-id="user_docs_id_front"
                                    className="text-dots-app"
                                >1</span>
                                <span
                                    data-id="user_docs_id_back"
                                    className="text-dots-app"
                                >2</span> */}
                                {/* <span data-id="user_docs_visa" className="text-dots-app"></span>
                                <span data-id="user_docs_rera" className="text-dots-app"></span> */}
                            </div>

                        </div>
                        <div className="row g-0 d-flex flex-row">

                            <AgentMainButton title={'Submit your documents'}>
                                <ActionLoadEmiratesID/>
                                <ActionLoadPassport/>
                                <ActionLoadRera/>
                            </AgentMainButton>

                        </div>
                    </div>
                </Panel>
            </Collapse>

        </div>
    )
}
