import React from 'react'
import LandlordHeader from '../../LandlordHeader'
import ActionCard from '../actionCard'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { useParams } from 'react-router'
import { type RootState } from '../../../../store/store'
import _ from 'lodash'
import { updateOfferByMagic } from '../../../../store/actionsCreators/offerActions/magicOfferActions'
import { swalConfirm, swalSuccess } from '../../../../functions/swalOptions'
import { useNavigate } from 'react-router-dom'
import { setUnitSold } from '../../../../store/actionsCreators/unitsActions'
import confirmAction from '../../../../functions/swals/confirmAction'

const OffersActions = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { unitID, offerID } = useParams()
    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const units = useAppSelector((state: RootState) => state.units.units)
    const offer = _.find(offers, (offer) => offer.id === Number(offerID))
    const unit = _.find(units, (unit) => unit.id === Number(unitID))
    const status = offer?.attributes?.Statuses[offer?.attributes?.Statuses?.length - 1]
    const marketingUse = unit?.attributes?.Marketing?.Use
    const navigate = useNavigate()
    return <>
        <LandlordHeader active={'Offer actions'}/>
        <div className={'d-flex flex-column gap-4 p-4'}>
            { _.includes(['Negotiated by agent', 'Approved'], status) &&
                <ActionCard
                    title={'Confirm'}
                    description={'Confirm the offer\'s price'}
                    onClick={() => {
                        confirmAction({
                            callback: () => {
                                void dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Confirmed'] }, 'Offer price has been confirmed', 'user-offer-confirmed', 'ATTENTION price on offer has been confirmed'))
                                    .then(() => {
                                        void swalSuccess('Offer price has been confirmed')
                                    })
                            }
                        })
                    }}
                    icon={'check_circle'}
                />
            }
            { (status === 'In-process' || status === 'Rejected') &&
                <ActionCard
                    title={'Approve'}
                    description={'Approve offer'}
                    onClick={() => {
                        confirmAction({
                            callback: () => {
                                void dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Approved'] }, 'Offer has been approved', 'user-offer-approved', 'Great, offer has been approved', false, true))
                                    .then(() => {
                                        void swalSuccess('Offer has been approved')
                                    })
                            }
                        })
                    }}
                    icon={'check_circle'}
                />
            }
            { _.includes(['Approved', 'Negotiated by landlord', 'Negotiated by BSO', 'Negotiated by agent'], status) &&
                <ActionCard
                    title={'Negotiate'}
                    description={'Negotiate on offer'}
                    onClick={() => {
                        navigate(`/landlord/modal/negotiate_offer_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                    }}
                    icon={'monetization_on'}
                />
            }
            { status === 'In-process' &&
                <ActionCard
                    title={'Require resubmit'}
                    description={'Require agent to resubmit offer'}
                    onClick={() => {
                        navigate(`/landlord/modal/require_resubmit_offer_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                    }}
                    icon={'cancel'}
                />
            }
            { status === 'Waiting booking form send' &&
                <ActionCard
                    title={'Confirm booking form sending'}
                    description={'Confirm that booking form is sent to client'}
                    onClick={() => {
                        void (async () => {
                            const response = await swalConfirm('Booking form', 'Send a booking form to client')
                            if (response.isConfirmed) {
                                void dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Waiting booking form sign'] }, 'Booking form is send', 'user-offer-booking-form', 'Booking form is sent', false, false, true))
                                    .then(() => {
                                        void swalSuccess('Booking form is send')
                                    })
                            }
                        })()
                    }}
                    icon={'description'}
                />
            }
            { status === 'Waiting booking form sign' &&
                <ActionCard
                    title={'Check booking form sign'}
                    description={'Check booking form sign for offer'}
                    onClick={() => {
                        void (async () => {
                            const response = await swalConfirm('Booking form', 'Has the client confirmed the booking form?')
                            if (response.isConfirmed) {
                                // if (offer?.attributes?.Type?.[0]?.__component === 'offer.sale') {
                                // await dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Completed'] }, 'Booking form is successfully signed', 'admin-offer-completed', 'Offer is completed'))
                                // void dispatch(setUnitSold(offer?.attributes?.Unit?.data?.id ?? -1))
                                // } else {
                                //    await dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Waiting deposit pay slip'] }, 'Booking form is successfully signed', 'user-offer-deposit', 'ATTENTION upload a deposit pay slip for offer'))
                                // }
                                // void swalSuccess('Booking form is successfully signed')
                                navigate(`/landlord/modal/check_booking_form_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                            }
                        })()
                    }}
                    icon={'menu'}
                />
            }
            { status === 'Waiting for slip approval' &&
                <ActionCard
                    title={'Check slip'}
                    description={'Check the deposit pay slip'}
                    onClick={() => {
                        navigate(`/landlord/modal/check_slip_offer_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                    }}
                    icon={'description'}
                />
            }
            { marketingUse === 'For sale'
                ? <>
                    {
                        status === 'Waiting for updates' && (
                            <ActionCard
                                title={'Start MOU process'}
                                description={'Start MOU process for the offer'}
                                onClick={() => {
                                    confirmAction({
                                        callback: () => {
                                            void dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Under MOU'] }, 'Offer is under MOU', 'user-offer-change', '', false, true))
                                                .then(() => {
                                                    void swalSuccess('Offer is under MOU')
                                                })
                                        }
                                    })
                                }}
                                icon={'check_circle'}
                            />
                        )
                    }
                    {
                        status === 'Under MOU' && offer?.attributes?.Type?.[0]?.Type === 'Mortgage Buyer' && (
                            <ActionCard
                                title={'Start mortgage process'}
                                description={'Start mortgage process for the offer'}
                                onClick={() => {
                                    navigate(`/landlord/modal/admin_upload_offer_document_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                                }}
                                icon={'check_circle'}
                            />
                        )
                    }
                    {
                        (status === 'Mortgage process' || (status === 'Under MOU' && offer?.attributes?.Type?.[0]?.Type !== 'Mortgage Buyer')) && (
                            <ActionCard
                                title={'Start obtaining NOC'}
                                description={'Start obtaining NOC for the offer'}
                                onClick={() => {
                                    navigate(`/landlord/modal/admin_upload_offer_document_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                                }}
                                icon={'check_circle'}
                            />
                        )
                    }
                    {
                        status === 'Obtaining NOC' && (
                            <ActionCard
                                title={'Start transfer process'}
                                description={'Start transfer process for the offer'}
                                onClick={() => {
                                    navigate(`/landlord/modal/admin_upload_offer_document_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                                }}
                                icon={'check_circle'}
                            />
                        )
                    }
                    {
                        status === 'Transfer process' && (
                            <ActionCard
                                title={'Commit transaction'}
                                description={'Commit transaction for the offer'}
                                onClick={() => {
                                    navigate(`/landlord/modal/admin_upload_offer_document_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                                }}
                                icon={'check_circle'}
                            />
                        )
                    }
                </>
                : <>
                    {
                        status === 'Slip approved' && (
                            <ActionCard
                                title={'Upload tenancy contract'}
                                description={'Upload tenancy contract for the offer'}
                                onClick={() => {
                                    navigate(`/landlord/modal/admin_upload_offer_document_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                                }}
                                icon={'check_circle'}
                            />
                        )
                    }
                </>
            }
            { status !== 'Completed' && status !== 'Rejected' && status !== 'Canceled' &&
                <ActionCard
                    title={'Reject'}
                    description={'Reject offer'}
                    onClick={() => {
                        navigate(`/landlord/modal/reject_offer_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                    }}
                    icon={'cancel'}
                />
            }
        </div>
    </>
}

export default OffersActions
