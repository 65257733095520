import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import { fetchUser, fetchUsers, updateUserTokenDevice } from '../../store/actionsCreators/userActions'
import { useAppDispatch } from '../../hooks/redux'
import { fetchProperties } from '../../store/actionsCreators/propertyActions'
import { fetchDocuments } from '../../store/actionsCreators/documentActions'
import SearchProvider from '../../app/SearchProvider'
import useOrientation from '../../hooks/UseOrientation'
import LandscapePlaceholder from '../shared/landscape-placeholder'
import { getChatList } from '../../store/actionsCreators/chatListActions'
import { fetchViewings } from '../../store/actionsCreators/viewingsActions'
import { fetchUnits } from '../../store/actionsCreators/unitsActions'
import { fetchOffers } from '../../store/actionsCreators/offerActions'
import { fetchAppConfig } from '../../store/actionsCreators/appActions'
import { fetchAdvertises } from '../../store/actionsCreators/advertiseActions/fetchAdvertiseAction'
import Loader from '../../app/custom/loaders/Loader'
import asyncQueue from '../../functions/utils/asyncQueue'

import { ACCESS_KEY, USER_ID_KEY } from '../../urls'
import _ from 'lodash'
import { landlordSocket } from './landlordSocket'
import { type AppDispatch } from '../../store/store'
import { type NavigateFunction } from 'react-router-dom'

export default function LandlordModule(): JSX.Element {
    const dispatch = useAppDispatch()
    const [dataLoaded, setDataLoaded] = useState(false)
    const { orientation } = useOrientation()

    const navigate = useNavigate()
    let userID = window.localStorage.getItem(USER_ID_KEY)
    let webSocket = {} as any
    const [wb, setWb] = useState<any>({})

    useEffect(() => {
        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        }

        userID = window.localStorage.getItem(USER_ID_KEY)
        const initializeWebSocket = (dispatch: AppDispatch, navigate: NavigateFunction): void => {
            setDataLoaded(true)
            webSocket = new WebSocket('wss://bso.ae/ws')
            setWb(webSocket)
            void landlordSocket(webSocket, dispatch)
        }

        const checkToken = async (dispatch: AppDispatch, navigate: NavigateFunction): Promise<void> => {
            const maxAttempts = 16
            let attemptCount = 0
            let currentToken: string | null = null

            while (attemptCount < maxAttempts && !currentToken) {
                try {

                    currentToken = (window as unknown as Window & { devicetoken: string }).devicetoken || window.localStorage.getItem('devicetoken')
                    if (currentToken) {
                        void dispatch(updateUserTokenDevice(currentToken))
                        initializeWebSocket(dispatch, navigate)
                        break
                    } else {
                        attemptCount++
                        await new Promise(resolve => setTimeout(resolve, 500))
                    }
                } catch (error) {
                    console.error('Error updating token', error)
                    setDataLoaded(true)
                    break
                }
            }

            if (!currentToken) {
                void dispatch(updateUserTokenDevice(`BrowserTokenUnfound${userID}`))
                initializeWebSocket(dispatch, navigate)
            }
        }

        void (async () => {
            const jwt = window.localStorage.getItem(ACCESS_KEY)
            userID = window.localStorage.getItem(USER_ID_KEY)
            window.myController = new AbortController()
            if (jwt && userID) {
                const results = await asyncQueue([
                    dispatch(fetchUser()),
                    dispatch(fetchViewings()),
                    dispatch(fetchOffers(false, true)),
                    dispatch(fetchUnits()),
                    dispatch(fetchDocuments()),
                    dispatch(fetchUsers()),
                    dispatch(fetchProperties()),
                    dispatch(getChatList()),
                    dispatch(fetchAdvertises()),
                    dispatch(fetchAppConfig())
                ])

                const isLoad = results.every((el: any) => {
                    return el?.isSuccessful === true || el?.success === true
                })

                if (isLoad) {
                    const userAgent = navigator.userAgent
                    const isIPhone = _.includes(userAgent.toLowerCase(), 'iphone')
                    const isAndroid = _.includes(userAgent.toLowerCase(), 'android')

                    if (isAndroid) {
                        try {
                            const { initializeApp } = await import('firebase/app')
                            const firebaseApp = initializeApp(firebaseConfig)
                            const { getMessaging, getToken, onMessage } = await import('firebase/messaging')
                            const messaging = getMessaging(firebaseApp)

                            if (Notification.permission === 'default') {
                                const permission = await Notification.requestPermission()
                                if (permission === 'granted') {
                                    const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
                                    if (currentToken != null) {
                                        console.log('FB Token:', currentToken.slice(0, 5) + '...')
                                        await dispatch(updateUserTokenDevice(currentToken))
                                        initializeWebSocket(dispatch, navigate)
                                    } else {
                                        await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                        initializeWebSocket(dispatch, navigate)
                                    }
                                } else if (permission === 'denied') {
                                    await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                    initializeWebSocket(dispatch, navigate)
                                    alert('Notifications have been blocked by the user.')
                                }
                            } else if (Notification.permission === 'granted') {
                                const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
                                if (currentToken != null) {
                                    console.log('FB Token:', currentToken.slice(0, 5) + '...')
                                    await dispatch(updateUserTokenDevice(currentToken))
                                    initializeWebSocket(dispatch, navigate)
                                } else {
                                    await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                    initializeWebSocket(dispatch, navigate)
                                }
                            } else if (Notification.permission === 'denied') {
                                await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                initializeWebSocket(dispatch, navigate)
                                alert('Notifications have been blocked by the user.')
                            }

                            onMessage(messaging, (payload) => {
                                navigator.serviceWorker.ready.then((registration) => {
                                    registration.showNotification(payload.notification?.title as string, {
                                        body: payload.notification?.body
                                    })
                                }).catch((e) => { console.log(e) })
                            })
                        } catch (err) {
                            console.log(err)
                        }
                    } else if (isIPhone) {
                        await checkToken(dispatch, navigate)
                    } else {
                        setDataLoaded(true)
                        await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                        initializeWebSocket(dispatch, navigate)
                    }
                } else {
                    setDataLoaded(false)
                    navigate('/auth/login')
                }
            }
        })()
        const body = document.querySelector('body')
        if (body != null) {
            body.setAttribute('style', 'user-select: text !important;')
        }
    }, [])

    // useEffect(() => {
    //     if (dataLoaded) {
    //         // логика по загрузке нужных роутов в лэндлорде аналог  AgentLoadProcessData
    //     }
    // }, [dataLoaded])

    useEffect(() => {
        const func = () => {
            if (wb.readyState === 3) {
                webSocket = new WebSocket('wss://bso.ae/ws')
                setWb(webSocket)
                void landlordSocket(webSocket, dispatch)
            }
        }
        window.myController.abort()
        window.myController = new AbortController()
        window.addEventListener('open-app', func, { signal: window.myController.signal })
    }, [wb])

    if (!dataLoaded) {
        return <Loader />
    }

    return (
        <SearchProvider>
            {orientation === 'landscape'
                ? <LandscapePlaceholder />
                : <Outlet />
            }
        </SearchProvider>
    )
}
