import { type IViewing } from '../../../store/slices/viewingsSlice'

export const reduceViewingAfterUpdateKey = (entry: Record<any, any>, isMy: IViewing) => {
    //   console.log(isMy, 'isMy')
    const actualKey = {

        data: {
            id: entry?.id,
            attributes: {
                DropOutDate: entry?.DropOutDate,
                PickUpDate: entry?.PickUpDate,
                Status: entry?.Status,
                createdAt: entry?.createdAt,
                updatedAt: entry?.updatedAt
            }
        }
    }

    const reducedViewing = {
        id: isMy?.id,
        attributes: {
            createdAt: isMy?.attributes?.createdAt,
            updatedAt: isMy?.attributes?.updatedAt,
            Complaint: isMy?.attributes?.Complaint,
            Comments: isMy?.attributes?.Comments,
            Notify: isMy?.attributes?.Notify,
            Feedback: isMy?.attributes?.Feedback,
            // order: isMy?.attributes?.order,
            Tasks: isMy?.attributes?.Tasks,
            Name: isMy?.attributes?.Name,
            Datetime: isMy?.attributes?.Datetime,
            Statuses: isMy?.attributes?.Statuses,
            DigitalLockPass: isMy?.attributes?.DigitalLockPass,
            User: {
                data: {
                    id: isMy?.attributes?.User?.data?.id ?? entry?.Holder.id,
                    attributes: {}
                }
            },
            Unit: {
                data: {
                    id: isMy?.attributes?.Unit?.data?.id,
                    attributes: {}
                }
            },
            KeyBooking: actualKey
        }
    }
    //  console.log(reducedViewing, 'reducedViewing')
    return reducedViewing
}
