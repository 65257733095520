import { createSlice } from '@reduxjs/toolkit'
interface ISwalsStates {
    oneCycleShowPopupsViewingReschedule: boolean
    showPopupViewingReschedule: boolean
    showPopupViewingFeedback: boolean
    indexOpenPopupViewingReschedule: number
    newViewingRescheduleSocket: boolean
    stackViewingsReschedule: [] | number[]
    firstLengthSolidStackViewingsReschedule: number
    viewingChanged: number
    newViewingFeedbackSocket: boolean
    alreadyShown: boolean
}

const initialState: ISwalsStates = {
    oneCycleShowPopupsViewingReschedule: false,
    showPopupViewingReschedule: false,
    showPopupViewingFeedback: false,
    indexOpenPopupViewingReschedule: -1,
    newViewingRescheduleSocket: false,
    newViewingFeedbackSocket: false,
    stackViewingsReschedule: [],
    firstLengthSolidStackViewingsReschedule: 0,
    viewingChanged: -1,
    alreadyShown: false
}

export const swalsSlice = createSlice({
    name: 'swalsStates',
    initialState,
    reducers: {

        setShowPopupViewingReschedule (state, action) {
            state.showPopupViewingReschedule = action.payload
        },
        setShowPopupViewingFeedback (state, action) {
            state.showPopupViewingFeedback = action.payload
        },

        setIndexOpenPopupViewingReschedule (state, action) {
            state.indexOpenPopupViewingReschedule = action.payload
        },
        setOneCicleShowPopupsViewingReschedule (state, action) {
            state.oneCycleShowPopupsViewingReschedule = action.payload
        },
        setNewViewingRescheduleSocket (state, action) {
            state.newViewingRescheduleSocket = action.payload
        },
        setNewViewingFeedbackSocket (state, action) {
            state.newViewingFeedbackSocket = action.payload
        },

        setStackViewingsReschedule (state, action) {
            state.stackViewingsReschedule = action.payload
        },
        setFirstLengthSolidStackViewingsReschedule (state, action) {
            state.firstLengthSolidStackViewingsReschedule = action.payload
        },
        setViewingChanged (state, action) {
            state.viewingChanged = action.payload
        },
        setAlreadyShown (state, action) {
            state.alreadyShown = action.payload
        }

    }
})

export const {
    setShowPopupViewingReschedule,
    setIndexOpenPopupViewingReschedule,
    setOneCicleShowPopupsViewingReschedule,
    setNewViewingRescheduleSocket,
    setStackViewingsReschedule,
    setFirstLengthSolidStackViewingsReschedule,
    setViewingChanged,
    setShowPopupViewingFeedback,
    setNewViewingFeedbackSocket,
    setAlreadyShown
} = swalsSlice.actions

export default swalsSlice.reducer
