
import React, { type ReactNode } from 'react'
import { Carousel as AntdCarousel } from 'antd'
import { type CarouselRef } from 'antd/lib/carousel'
interface SlideProps {
    children: ReactNode
    onChange: (index: number) => void
    dotPosition?: any
}

const SlideFirst = React.forwardRef<CarouselRef, SlideProps>(({ children, onChange, dotPosition = 'bottom' }, ref) => (
    <AntdCarousel effect="fade" dots={true} dotPosition={dotPosition || 'top' } beforeChange={(_, newIndex) => { onChange(newIndex) }} ref={ref}>
        {children}
    </AntdCarousel>
))

export default SlideFirst
