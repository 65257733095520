/**
 * Chats
 */

export const MESSAGE_UNPUBLISH_TIME = 1000 * 60 * 60 * 24

/**
 * Notifies
 */

export const NOTIFY_DELAY = 1000 * 5

export const UNIT_SOLD_NOTIFY_DELAY = 1000 * 60 * 60 * 24

/**
 * Hide Emails
 */

export const hideMails = [
    'erter.live@gmail.com', 'projects@bso.ae', 'it@bso.ae', 'it2@bso.ae', 'amemetoff@yandex.ru', 'it3@bso.ae', 'magic.soliman@yahoo.com', 'arsenij.ermolaev2003@gmail.com', 'arsenij.ermolaev2003.1@gmail.com', 'officemac1993@gmail.com', 'jamal_ayash@hotmail.com'
]

/**
 * REGULAR EXPRESSIONS
 */

export const regExps = {
    isYear: '^\\d{4}$',
    forBedrooms: '^\\d+$|^[Ss]tudio$',
    isNumber: '^[0-9]+$',
    isFloatNumber: '^[+-]?(\\d+(\\.\\d*)?|\\.\\d+|\\d+)$',
    isEmiratesIDNumber: '^784-?[0-9]{4}-?[0-9]{7}-?[0-9]{1}$',
    isEmail: '^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$',
    isPhone: '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$',
    //  checkLinks : '/https?:\\|www./',
    checkLinks: /^https?:\/\/(www\.)?([a-zA-Z0-9-]+\.)+(com|net|org|gov|edu|gl|app|map)(\/[^\s]*)?$/,
    isNumberOfBedrooms: '^([+-]?(\\d+(\\.\\d*)?|\\.\\d+)|Studio)$'
}

/**
 * ADMIN VIEW STATUSES
 */

export const offerAdminViewStatuses = ['Confirmed', 'Completed', 'Canceled']

export const days = ['Sun', 'Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat']
