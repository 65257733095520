
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import CustomFormLable from '../../../app/custom/customModalUtils/CustomFormLable'
import MyDataPicker from '../../../app/MyDataPicker'
import _ from 'lodash'
import CustomSelect from '../../../app/custom/selects/CustomSelect'

import { useAppSelector } from '../../../hooks/redux'

import { generateDefaultValueFormField } from '../../../functions/agent/AgentModals'

import { type RootState } from '../../../store/store'

import { Space, Switch } from 'antd'
import CustomSwitch from '../../../app/custom/inputs/CustomSwitch'

export default function StateMarketingTypeUnit({
    setValue,
    errors,
    register,
    control
}: any): JSX.Element {
    const { modalID, unitID, objectID } = useParams()
    const isNegotiateModal = modalID === 'agent_to_agent_negotiate_offer'
    const isEditedModal = modalID === 'agent_edit_unit'

    const units = useAppSelector((state: RootState) => state.units.units)
    const currentUnit = units?.filter((el: any) => el.id === Number(unitID))[0]
    const marketingData = currentUnit?.attributes?.Marketing
    const typeUnit = marketingData?.Use

    let currentOffer = null
    if (isNegotiateModal || isEditedModal) {
        currentOffer = useAppSelector(
            (state: RootState) => state.offers.offers
        )?.filter((offer) => offer.id === Number(objectID))?.[0]

    }

    const [switchChecked, setChecked] = useState(typeUnit === 'For rent')

    const rowTypeContractStartDate = { id: 'Marketing.ContractStartDate', content: ['Contract Start Date', 'Input contract start date'], dateAfter: true, element: 'input-date', ...((isNegotiateModal || isEditedModal) && generateDefaultValueFormField(currentOffer?.attributes?.Type[0]?.ContractStartDate)) }
    const rowTypePayments = { id: 'Marketing.Payments', content: ['Payments', 'Select count'], selectors: ['1', '2', '3', '4', '6', '12'], element: 'select', required: true, ...(generateDefaultValueFormField(isNegotiateModal ? currentOffer?.attributes?.Type[0]?.Payments : (isEditedModal && switchChecked) ? marketingData?.Checks : undefined)) }
    const rowTypeDuration = { id: 'Marketing.Duration', content: ['Contract Duration', 'Select contract duration'], selectors: _.range(6, 25).map(item => ({ label: `${item} Months`, value: item })), element: 'select', required: true, ...((isNegotiateModal) && generateDefaultValueFormField(currentOffer?.attributes?.Type[0]?.Duration)) }
    const rowStatusesMortgage = { id: 'Marketing.StatusesMortgage', content: ['Mortgage is available?', ' Mortgage is available?'], selectors: ['YES', 'NO'], element: 'input-switch', required: true, ...((isNegotiateModal || isEditedModal) && generateDefaultValueFormField(currentUnit?.attributes?.Statuses.includes('Mortgage') && 'YES')) }

    return (
        <>
            <div className="fv-row  mt-8 p-0 w-100">
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Unit's marketing type ?</div>
                    </div>
                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Switch unCheckedChildren="FOR SALE" defaultChecked={switchChecked}
                                checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>FOR RENT</span>}
                                onChange={(checked) => {
                                    setChecked(checked);
                                    if (!checked) {
                                        setValue(rowTypePayments.id, null);
                                    }
                                }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-6"></div>
                {switchChecked
                    ? <>
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={rowTypePayments} />
                            <CustomSelect control={control} register={register} data={rowTypePayments} setValue={setValue} />
                            {
                                _.get(errors, rowTypePayments.id) != null && (
                                    <div className="text-danger my-2">{_.get(errors, rowTypePayments.id).message}</div>
                                )
                            }
                        </div>

                        {isNegotiateModal && <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={rowTypeDuration} />
                            <CustomSelect control={control} register={register} data={rowTypeDuration} setValue={setValue} />
                            {
                                _.get(errors, rowTypeDuration.id) != null && (
                                    <div className="text-danger my-2">{_.get(errors, rowTypeDuration.id).message}</div>
                                )
                            }
                        </div>}
                    </>
                    : <>
                        <CustomSwitch row={rowStatusesMortgage}
                            errors={errors}
                            register={register}
                            control={control}
                        />
                        {(isNegotiateModal) &&
                            <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                <CustomFormLable row={rowTypeContractStartDate} />
                                <MyDataPicker control={control} register={register} row={rowTypeContractStartDate} setValue={setValue} />
                                {_.get(errors, rowTypeContractStartDate.id) != null && (
                                    <div className="text-danger">{_.get(errors, rowTypeContractStartDate.id).message}</div>
                                )}
                            </div>}
                    </>
                }

            </div >

        </>

    )
}
