import React, { useEffect, useState } from 'react'
import Icon from '../../icon/Icon'

interface IProps {
    id: string
    row: any
    register: any
    password?: any
    control?: any
    errors?: any
    isSubmitted?: any
    modal?: any
}

export default function PasswordMeterHook({
    row,
    register,
    password,
    errors,
    isSubmitted,
    modal
}: IProps): JSX.Element {
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
    const [passwordStrength, setPasswordStrength] = useState({
        score: 0

    })


    const handleTogglePasswordVisibility = (): void => {
        setPasswordVisible(!passwordVisible)
    }

    const calculatePasswordStrength = (password: string): number => {
        let strength = 0
        if (/[A-Z]/.test(password)) {
            strength++
        }
        // if (/[a-z]/.test(password)) {
        //     strength++
        // }
        if (/\d/.test(password)) {
            strength++
        }
        if (/[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/.test(password)) {
            strength++
        }
        if (password.length > 8) {
            strength++
        }

        return strength
    }

    useEffect(() => {
        if (password) {
            setPasswordStrength({ score: calculatePasswordStrength(password) })
        }
    }, [password, row.id])

    return (
        <>
            <div className="position-relative mb-2">
                <input
                    {...register(row.id, {
                        required: `${row.content?.[0]} is required`,
                        pattern: /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\d\s]).{8,66})\S$/g
                    })}
                    className="form-control form-control-solid form-control-lg"
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder={row.content?.[1]}
                    name={row.id}
                    autoComplete={row?.autocomplete ? row.passwordType : 'off'}
                />
                <span
                    className={'btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 pt-1  me-n3'}
                    onClick={handleTogglePasswordVisibility}
                >
                    {passwordVisible
                        ? (
                            <Icon name={'visibility_off'} className={'text-muted fs-1'} />
                        )
                        : (
                            <Icon name={'visibility'} className={'text-muted fs-1'} />
                        )}
                </span>
            </div>
            <div className="d-flex align-items-center mb-2">
                <div className={passwordStrength.score >= 1 ? 'flex-grow-1 bg-secondary bg-active-bso active rounded h-5px me-2 !important' : 'flex-grow-1 bg-secondary rounded h-5px me-2'}></div>
                <div className={passwordStrength.score >= 2 ? 'flex-grow-1 bg-secondary bg-active-bso active rounded h-5px me-2 !important' : 'flex-grow-1 bg-secondary rounded h-5px me-2'}></div>
                <div className={passwordStrength.score >= 3 ? 'flex-grow-1 bg-secondary bg-active-bso active rounded h-5px me-2 !important' : 'flex-grow-1 bg-secondary rounded h-5px me-2'}></div>
                <div className={passwordStrength.score >= 4 ? 'flex-grow-1 bg-secondary bg-active-bso active rounded h-5px  !important' : 'flex-grow-1 bg-secondary rounded h-5px me-2'}></div>
            </div>
            <div className="text-muted mb-1" >

                Use mix of letters, numbers &amp; symbols.
            </div>
            {errors[row.id] && (
                <div className="text-danger">{errors[row.id].message}</div>
            )}
            {errors[row.id] && errors[row.id].type === 'pattern' && isSubmitted && (
                <div className="text-danger">{modal[0].id === 'login' ? 'Use 9-character password with a mix of letters, numbers, and symbols.' : 'Create a secure 9-character password with a mix of letters, numbers, and symbols.'} </div>
            )
            }

        </>
    )
}
