import swalTexts from '../../../app/texts/swalTexts'
import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../urls'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'
import { type User } from '../../slices/userSlice'
import { setViewings, viewingsFetchingError } from '../../slices/viewingsSlice'
import { type AppDispatch, type RootState } from '../../store'
import { viewingsQuery } from '../viewingsActions'
import { createNotifyViewing } from './createNotifyViewing'

export const updateViewingByOtherAgent = (bodyClient: any) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            dispatch(dataFetchingStart())
            const unitID = bodyClient.unitID
            const units: any = getState().units.units
            const unit = units?.filter((el: any) => el.id === Number(unitID))

            const currentUser = getState().user.currentUser
            const nameUser = getNameUser(currentUser as User)
            const unitAddress = getUnitAddress(unit?.[0])

            const dateViewing = new Date(`${bodyClient.Datetime}+04:00`)

            const response = await fetch(urlAPI,
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                        'BSO-Hash': '02ns30nsBSO!',
                        Authorization: `Bearer ${jwt}`
                    },
                    body: JSON.stringify({
                        method: 'PUT',
                        collection: 'viewings',
                        query: viewingsQuery,
                        id: Number(bodyClient.viewID),
                        body: {
                            Datetime: dateViewing,
                            Statuses: ['Rescheduled by other agent'],
                            Notify: 'Agent',
                            RejectReason: bodyClient.RejectReason
                        }
                    })
                })
            const dataJSON = await response.json()

            if (dataJSON.success) {
                if (dataJSON.response.error != null) {
                    dispatch(viewingsFetchingError(getErrorss(dataJSON)))
                    dispatch(dataFetchingFinish())
                    return { textNavigate: '', isSuccessful: false, textNotification: '' }
                } else {
                    dispatch(setViewings(
                        dataJSON.response.data
                    ))

                    const currentDubaiDate = getCurrentDubaiDate()
                    const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                    await createNotifyViewing(dataJSON.response.data, unit, true)
                    await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON.response.data.id, 'user-viewing-newtime', `Attention, ${nameUser} has rescheduled ${unitAddress}`)

                    dispatch(dataFetchingFinish())
                    return { ...dataJSON, textNavigate: `/agent/modalView/viewing_modal/${bodyClient.unitID}/${bodyClient.viewID}/my_unit`, isSuccessful: true, textNotification: swalTexts.successViewingsActions.updateViewing.defaultMessage }
                }
            } else {
                dispatch(dataFetchingFinish())
                dispatch(viewingsFetchingError(getErrorss(dataJSON)))
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } catch (e) {
            dispatch(dataFetchingFinish())
            console.log(e)
        }
    }
}
