import React, { useCallback, useEffect, useRef, useState } from 'react'
import VoiceRecorder from './VoiceRecorder'
import FileAttacher from './FileAttacher'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import {
    handleSupportTicket,
    removePendingMessage,
    sendMessage, setMessageDraft,
    setPendingMessage
} from '../../../../store/actionsCreators/chatActions'
import { setCurrentMessage } from '../../../../store/slices/chatSlice'
import { useParams } from 'react-router'
import ChatUtils from '../../../../functions/chats/ChatUtils'
import Icon from '../../../../app/icon/Icon'
import _ from 'lodash'
const ChatInput = ({ scrollRef }: { scrollRef: React.RefObject<HTMLDivElement> }): JSX.Element => {
    const adjustHeight = (e: any): void => {
        e.target.style.height = '1px' // 'auto'; // '1px'
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        e.target.style.height = (Math.min(e.target.scrollHeight, 150)) + 'px'
        // el.style.height = (el.scrollHeight > el.clientHeight) ? `${el.scrollHeight + 10}px` : '30px'
    }
    const dispatch = useAppDispatch()
    const { id: chatID = '0' } = useParams()
    const { chat, currentMessage: message, pendingMessages } = useAppSelector((state: RootState) => state.chat)
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const inputBarRef = useRef<HTMLDivElement>(null)
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const [offsetTop, setOffsetTop] = useState(0)
    const [ticketsActionsLoading, setTicketsActionsLoading] = useState(false)
    const userAgent = navigator.userAgent
    const isIPhone = _.includes(userAgent.toLowerCase(), 'iphone')
    const isAndroid = _.includes(userAgent.toLowerCase(), 'android')
    const Messages = useAppSelector((state: RootState) => state.chat.Messages)
    const currentUser = useAppSelector((state) => state.user.currentUser)

    const openKeyboardHandler = useCallback((offsetTopArg: number) => {
        const offsetTop = offsetTopArg
        const chatWrapper: HTMLDivElement = document.querySelector('#chat-wrapper') as HTMLDivElement
        if (offsetTop != null && inputBarRef.current != null && chatWrapper != null && scrollRef.current != null && (isIPhone || userDevice === 'IPhone')) {
            chatWrapper.style.transition = 'height 400ms'
            chatWrapper.style.height = `calc(100vh - ${offsetTop}px)`

            scrollRef.current.style.transition = 'height 400ms'
            scrollRef.current.style.height = `calc(100vh - ${userDevice === 'IPhone' ? '12rem' : '10rem'} - ${offsetTop}px )`

            inputBarRef.current.style.transition = 'bottom 400ms'
            inputBarRef.current.style.bottom = `${offsetTop}px`

            window.scroll({
                top: 0,
                behavior: 'smooth'
            })
            window.onscroll = function () {
                window.scrollTo(0, 0)
            }
        }
    }, [inputBarRef, scrollRef])


    const scrollToBottom = () => {
        if (scrollRef.current != null) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };


    useEffect(() => {
        function handleKeyboardOpen(): void {
            if (offsetTop === 0) {
                setTimeout(() => {
                    const offsetTopArg = window?.visualViewport?.offsetTop ?? 0
                    console.log(offsetTopArg, "offsetTopArg")
                    openKeyboardHandler(offsetTopArg)
                    setOffsetTop(offsetTopArg)
                }, 1000)
            } else {
                //     for test
                //    openKeyboardHandler(offsetTop)
            }
        }

        function handleKeyboardClose(): void {
            setTimeout(() => {
                const activeElement = document.activeElement
                if (activeElement?.getAttribute('id') !== 'chat-input-textarea') {
                    window.onscroll = function () {
                    }
                    const chatWrapper: HTMLDivElement = document.querySelector('#chat-wrapper') as HTMLDivElement
                    if (chatWrapper != null) {
                        chatWrapper.style.height = '100vh'
                    }
                    if (scrollRef.current != null) {
                        scrollRef.current.style.height = `calc(100vh - ${userDevice === 'IPhone' ? '12rem' : '10rem'})`
                    }
                    if (inputBarRef.current != null) {
                        inputBarRef.current.style.bottom = '0px'
                    }
                }
            }, 100)
        }

        if (inputRef?.current != null) {
            inputRef?.current?.addEventListener('focus', handleKeyboardOpen)
            inputRef?.current?.addEventListener('blur', handleKeyboardClose)
            return () => {
                handleKeyboardClose()
                inputRef?.current?.removeEventListener('focus', handleKeyboardOpen)
                inputRef?.current?.removeEventListener('blur', handleKeyboardClose)
            }
        }
    }, [inputRef, inputBarRef, scrollRef, offsetTop])

    const supportTickets = chat.SupportTickets
    const issueOpen = supportTickets?.[supportTickets?.length - 1]?.TicketOpen === true

    const openSupportIssue = (type: 'viewing' | 'offer' | 'advertise' | 'profile' | 'units' | 'other') => () => {
        if (!ticketsActionsLoading) {
            setTicketsActionsLoading(true)
            dispatch(handleSupportTicket({
                isOpen: true,
                chatID: chat.id ?? -1,
                type
            })).finally(() => {
                setTicketsActionsLoading(false)
                scrollToBottom()
            })
        }
    }

    return (
        ChatUtils.isBSOBroadcast(chat)
            ? <></>
            : issueOpen || !ChatUtils.isBSOSupport(chat) || currentUser?.id === process.env.REACT_APP_BSO_USER ?
                <>
                    <div ref={inputBarRef} className={'w-100 d-flex align-items-end p-4 pb-8 gap-4 bg-white'}
                        style={{ bottom: 0, zIndex: '100' }}
                    >
                        <div className={'h-30px d-flex align-items-center'}>
                            <FileAttacher />
                        </div>
                        <textarea className={'scroll min-h-35px border-0 outline-0 w-100 p-2 bg-secondary rounded'}
                            id={'chat-input-textarea'}
                            ref={inputRef}
                            style={{
                                outline: 'none',
                                wordWrap: 'break-word',
                                resize: 'none',
                                height: '30px'// ,
                                // transition: 'height 100ms'
                            }}
                            value={message}
                            onKeyUp={adjustHeight}
                            onKeyDown={adjustHeight}
                            onChange={(e) => {
                                dispatch(setCurrentMessage(e.target.value))
                                setMessageDraft(e.target.value, +chatID)
                            }}
                        />
                        {
                            message.trim().length > 0
                                ? <div className={'h-30px d-flex align-items-center'}
                                    onClick={() => {
                                        if (inputRef.current != null) {
                                            inputRef.current.focus()
                                            inputRef.current.style.height = '30px'
                                        }
                                        setTimeout(() => {
                                            openKeyboardHandler(offsetTop)
                                        }, 100)

                                        const id = pendingMessages.length
                                        dispatch(setPendingMessage(message, id))
                                        dispatch(sendMessage(+chatID)).finally(() => {
                                            dispatch(removePendingMessage(id))
                                        })
                                        scrollToBottom()
                                    }}
                                >
                                    <Icon
                                        name={'send'}
                                        className={'text-muted fs-3x'}
                                    />
                                </div>
                                : <div className={'h-30px d-flex align-items-center'}>
                                    <VoiceRecorder />
                                </div>
                        }
                    </div>
                </>
                : <div className={'w-100 p-4 pb-8 gap-4 bg-white'}>
                    { !ticketsActionsLoading
                        ? <>
                            <div className={'mb-4 d-flex justify-content-center align-items-center gap-2'}>
                                <Icon name={'support_agent'} className={'fs-3x'}/>
                                <div className={'fw-bold fs-4'}>
                                    Open issue related to
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'px-1 col-4 mb-2'}>
                                    <div className={'btn btn-bso btn-sm w-100'}
                                        onClick={openSupportIssue('viewing')}
                                    >
                                        Viewing
                                    </div>
                                </div>
                                <div className={'px-1 col-4 mb-2'}>
                                    <div className={'btn btn-bso btn-sm w-100'}
                                        onClick={openSupportIssue('offer')}
                                    >
                                        Offer
                                    </div>
                                </div>
                                <div className={'px-1 col-4 mb-2'}>
                                    <div className={'btn btn-bso btn-sm w-100'}
                                        onClick={openSupportIssue('advertise')}
                                    >
                                        Advertise
                                    </div>
                                </div>
                                <div className={'px-1 col-4 mb-2'}>
                                    <div className={'btn btn-bso btn-sm w-100'}
                                        onClick={openSupportIssue('profile')}
                                    >
                                        Profile
                                    </div>
                                </div>
                                <div className={'px-1 col-4 mb-2'}>
                                    <div className={'btn btn-bso btn-sm w-100'}
                                        onClick={openSupportIssue('units')}
                                    >
                                        Units
                                    </div>
                                </div>
                                <div className={'px-1 col-4 mb-2'}>
                                    <div className={'btn btn-bso btn-sm w-100'}
                                        onClick={openSupportIssue('other')}
                                    >
                                        Other issue
                                    </div>
                                </div>
                            </div>
                        </>
                        : <div className={'d-flex justify-content-center'}>
                            <div className={'spinner-border'}/>
                        </div>
                    }
                </div>
    )
}

export default ChatInput
