import { type AppDispatch, type RootState } from '../../store'
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../urls'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'

import { modalSlice } from '../../slices/modalSlice'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { fetchOffers } from '../offerActions'
import _ from 'lodash'
import qs from 'qs'
import dayjs from 'dayjs'
import { rentConfig, saleConfig } from '../../../functions/shared/modals/fillAdminOfferDocumentModal'

import { setUnitSold } from '../unitsActions'
import notifyDelay from '../../../functions/shared/notify-helpers/notifyDelay'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'
import { createDocument } from '../documentsActions/createDocument'

export const updateOfferByMagic = (id: number, data: Record<string, any>, successAlert: string, notifyRule: string, notifyMessage: string, omitNotify: boolean = false, notifyBSO: boolean = false, notifyBoth: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    // const userID = window.localStorage.getItem(USER_ID_KEY)
    // const currentDubaiDate = getCurrentDubaiDate()
    // const inMomentOfferForAdmin = toUTCWithOffset(currentDubaiDate, 0)
    const offer = getState().offers?.offers?.find((offer) => offer.id === id)
    const userID = offer?.attributes?.User?.data?.id
    if (data?.Type != null) {
        if ('ContractStartDate' in data.Type) {
            data.Type.ContractStartDate = dayjs(data.Type.ContractStartDate).add(dayjs(data.Type.ContractStartDate).utcOffset(), 'minutes').toISOString()
        }
        const offer = _.find(getState().offers.offers, offerItem => offerItem.id === id)
        data.Type = [{
            ...(offer?.attributes?.Type[0] ?? {}),
            ...((data.Type as any[])?.[0] ?? {})
        }] as any
    }

    // console.log(data)
    try {
        // const dateViewing = new Date(`${bodyClient.time_viewing}+04:00`)
        dispatch(modalSlice.actions.dataFetchingStart())
        const bodyObject = {
            method: 'PUT',
            collection: 'offers',
            // query: 'populate[User][fields][0]=id&populate[Unit][fields][0]=id&populate[KeyBooking]=*',
            id,
            body: { ...data, Notify: !omitNotify ? notifyBSO ? 'BSO' : notifyBoth ? 'Both' : 'Agent' : undefined },
            query: qs.stringify({
                populate: ['Type', 'User', 'Unit', 'Unit.Marketing']
            }, {
                encodeValuesOnly: true
            })
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${String(jwt)}`
            },
            body: JSON.stringify(bodyObject)
        })
        const dataJSON = await response.json()

        // console.log(dataJSON, 'dataJSON<<<<<<<<<updateViewing<<<<<<<<!!!!!!__________')

        if (dataJSON.success === true) {
            if (dataJSON.response.error != null) {
                // dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                /*  dispatch(viewingsSlice.actions.setViewings(
                    dataJSON.response.data
                )) */

                // await sendNotification(inMomentViewingForAdmin, Number(userID), id, notifyRule, notifyMessage)
                // await dispatch(fetchOffers(true, true))
                await sendNotification(notifyDelay(), Number(userID), id, notifyRule, notifyMessage)
                await dispatch(fetchOffers(true))
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: -1, isSuccessful: true, textNotification: successAlert }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            //  dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (err) {

    }
}

export const uploadOfferDocument = (id: number, data: Record<string, any>, successAlert: string, notifyRule: string, notifyMessage: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const offer = getState().offers.offers.find((offer) => offer.id === id)
    if (offer != null) {
        let nextStatus: string
        let documentCategory: string
        const offerStatus = offer.attributes?.Statuses?.[0]
        const offerType = offer.attributes?.Type?.[0]?.__component
        if (offerType === 'offer.sale' || offerType === 'offer.sale-commercial') {
            nextStatus = saleConfig[offerStatus].nextStatus
            documentCategory = saleConfig[offerStatus].documentCategory
            if (nextStatus === 'Mortgage process' && offer?.attributes?.Type?.[0]?.Type !== 'Mortgage Buyer') {
                nextStatus = 'Obtaining NOC'
            }
        } else {
            nextStatus = rentConfig[offerStatus].nextStatus
            documentCategory = rentConfig[offerStatus].documentCategory
        }
        dispatch(modalSlice.actions.dataFetchingStart())
        const { isSuccessful } = await dispatch(createDocument(data.document, {
            RefID: `REF_DOC<->${documentCategory.toLowerCase().replaceAll(' ', '_')}<->${new Date().getTime()}`,
            Category: documentCategory,
            Offer: id,
            Statuses: ['In-process']
        }, 'offers', String(id)))
        dispatch(modalSlice.actions.dataFetchingFinish())
        if (isSuccessful) {
            if (nextStatus === 'Completed') {
                void dispatch(setUnitSold((offer?.attributes as any).Unit?.data?.id))
                return await dispatch(updateOfferByMagic(id, { Statuses: [nextStatus] }, successAlert, 'user-offer-completed', notifyMessage, true))
            } else {
                return await dispatch(updateOfferByMagic(id, { Statuses: [nextStatus] }, successAlert, notifyRule, notifyMessage, false, true))
            }
        }
    } else {
        return { textNavigate: '', isSuccessful: false, textNotification: '' }
    }
}
