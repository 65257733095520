import QueryString from 'qs'
import { ACCESS_KEY, urlAPI } from '../../../../urls'
import { dataFetchingFinish, modalSlice } from '../../../slices/modalSlice'
import { type Unit } from '../../../slices/unitsSlice'
import { type AppDispatch, type RootState } from '../../../store'
import { offersSlice } from '../../../slices/offersSlice'
import { getErrorss } from '../../../../functions/errors/GetErrors'
import { uploadMultipleForOffers } from '../../documentsActions/upload/uploadMultipleForOffers'

export const makeOfferAgentToAgent = (bodyClient: any) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = bodyClient.objectID // window.localStorage.getItem(USER_ID_KEY)

    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            dispatch(modalSlice.actions.dataFetchingStart())
            const isComertial = 'BusinessName' in bodyClient.Type && Boolean(bodyClient.Type.BusinessName)
            let type
            if (isComertial) {
                type = {
                    __component: 'offer.commercial',
                    BusinessName: bodyClient.Type.BusinessName,
                    BusinessOwnerName: bodyClient.Type.BusinessOwnerName,
                    BusinessActivity: bodyClient.Type.BusinessActivity,
                    ...(bodyClient.Type.EstablishmentDate ? { EstablishmentDate: bodyClient.Type.EstablishmentDate } : {})

                    //   bodyClient.Type.BusinessOwnerName bodyClient.Type.BusinessActivity bodyClient.Type.EstablishmentDate
                }
            } else {
                type = {
                    __component: 'offer.residential',
                    FirstName: bodyClient.Type.FirstName,
                    LastName: bodyClient.Type.LastName,
                    Country: bodyClient.Type.Country
                }
            }

            const loadDocs = await dispatch(uploadMultipleForOffers(bodyClient))

            const units: any = getState().units.units
            const unit = units?.filter((el: any) => el.id === Number(bodyClient.unitID))
            const property = unit[0].attributes?.Property.data
            const name = property.attributes.Name ?? ''
            const location = property.attributes.Area ?? ''
            const unitNumber = unit[0].attributes?.Number ?? ''
            const ownerUserId = (unit[0] as Unit).attributes?.User.data?.id
            const unitStatuses = unit[0].attributes?.Statuses ?? []
            const isAgentUnit = unitStatuses?.includes('Door close')
            const bodyObject = {
                method: 'POST',
                collection: 'offers',
                query: QueryString.stringify({
                    populate: ['Type', 'Documents', 'User', 'Unit']
                }, {
                    encodeValuesOnly: true
                }),

                body: {
                    Type: [type],
                    ProcessStartDate: new Date(),
                    Statuses: ['In-process'],
                    Name: isAgentUnit ? `${name} <-> ${location} <-> Dubai` : `${unitNumber} <-> ${name} <-> ${location}`,
                    //   Notify: isAgentUnit ? 'Other Agent' : 'Both',
                    Unit: `${bodyClient.unitID}`,
                    User: `${userID}`,
                    ...(isAgentUnit && { AgentToAgentID: ownerUserId })
                }
            }
            // console.log(bodyObject, 'bodyObject')
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'BSO-Hash': '02ns30nsBSO!',
                    Authorization: `Bearer ${jwt}`
                },
                body: JSON.stringify(bodyObject)
            })
            const dataJSON = await response.json()

            if (dataJSON.success) {
                if (dataJSON.response.error != null) {
                    dispatch(offersSlice.actions.offersFetchingError(getErrorss(dataJSON)))
                    dispatch(dataFetchingFinish())
                    return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
                } else {
                    dispatch(offersSlice.actions.addOffer(dataJSON.response.data))

                    bodyClient.offerID = dataJSON?.response?.data?.id
                    const uploadedAttachedFilesIDs = await dispatch(uploadMultipleForOffers(bodyClient))
                    //  console.log(uploadedAttachedFilesIDs, 'uploadedAttachedFilesIDs')

                    const responseAddDocs = await fetch(`${urlAPI}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'BSO-Hash': '02ns30nsBSO!',
                            Authorization: `Bearer ${jwt}`
                        },
                        body: JSON.stringify({
                            method: 'PUT',
                            collection: 'offers',
                            id: Number(dataJSON?.response?.data?.id),
                            query: QueryString.stringify({
                                populate: ['Type', 'Documents', 'User', 'Unit']
                            }, {
                                encodeValuesOnly: true
                            }),

                            body: {
                                Documents: uploadedAttachedFilesIDs,
                                ProcessStartDate: new Date()

                            }
                        })
                    })
                    const dataJSONAddDocs = await responseAddDocs.json()

                    if (dataJSONAddDocs.success) {
                        if (dataJSON.response.error != null) { /* empty */ } else {
                            dispatch(dataFetchingFinish())
                            return {
                                textNavigate: `/agent/modal/agent_to_agent_make_offer_last/${bodyClient.unitID}/${dataJSON?.response?.data?.id}`,
                                isSuccessful: true,
                                textNotification: 'Wait change text',
                                title: 'Offer has been created / add additinal information'
                            }
                        }
                    }
                }
                dispatch(dataFetchingFinish())
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            } else {
                dispatch(offersSlice.actions.offersFetchingError(getErrorss(dataJSON)))
                dispatch(dataFetchingFinish())
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } catch (e) {
            dispatch(dataFetchingFinish())
            console.log(e)
        }
    }
}
