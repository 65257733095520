import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { useAppSelector } from '../../../hooks/redux'
import { swalAttentionLeave } from '../../../functions/swalOptions'
import swalConfig from '../../../app/texts/swalTexts'
import { reduceLinks, type StateLink } from '../../../functions/agent/advertises/reduceLinks'

export default function AgentAdvertiseCreateLinks({ form, id, modal, unitID, row, control, objectID, register, errors, clearErrors, setValue, trigger }: any): JSX.Element {
    const advertises = useAppSelector(state => state.advertises.advertises)
    const currentAdvertise = advertises?.filter((adv) => adv.id === Number(objectID))?.[0]
    const mappedLinks: StateLink[] = reduceLinks(currentAdvertise)


    const updateStatusToInProcess = (name: number): void => {
        const currentLinks = form.getFieldValue("links");
        currentLinks[name] = {
            ...currentLinks[name],
            Status: "In-process",
        };
        form.setFieldsValue({ links: currentLinks });
    };



    return (
        <div className='p-0'>
            <Form
                form={form}
                name="advertise"
                style={{ maxWidth: 600 }}
                autoComplete="off"
                initialValues={{
                    links: mappedLinks
                }}
            >
                <Form.List name="links">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }, index) => {
                                const currentLinks = form.getFieldValue("links");
                                const isExistingField = currentLinks?.[name];
                                const isLastItem = index === currentLinks.length - 1
                                return (
                                    <>
                                        <div className='d-flex justify-content-between p-0'>
                                            <span className={`bullet bullet-vertical d-flex align-items-center h-auto  bg-${isExistingField && isExistingField?.Status === 'Approved' ? 'success' : isExistingField && isExistingField?.Status !== 'In-process' ? 'danger' : 'warning'}`}></span>
                                            <div className='w-100 px-1 mx-1'>
                                                <div className='m-0 p-0 d-flex justify-content-between align-items-center'>
                                                    {(isExistingField && isExistingField?.Status === 'Approved')
                                                        ? (
                                                            <span className='mb-2'>
                                                                <span className="badge badge-success">Approved</span>
                                                            </span>
                                                        )
                                                        : (isExistingField && isExistingField?.Status !== 'In-process')
                                                            ? (
                                                                <span className='pt-1'>
                                                                    <span className="badge badge-danger">Rejected</span>
                                                                </span>

                                                            )
                                                            : (
                                                                <span className='mb-2'>
                                                                    <span className="badge badge-warning">In-process</span>
                                                                </span>
                                                            )}

                                                    {isExistingField?.Status !== 'Approved' && (
                                                        <i className="fs-4x ki-duotone ki-cross-square " onClick={async () => {
                                                            await swalAttentionLeave(swalConfig.attentionLeave.messageForClearRowLinkAdvertise).then(async (result: any) => {
                                                                if (result.isConfirmed) {
                                                                    remove(name)
                                                                }
                                                            })
                                                        }}>
                                                            <span className="path1 text-dark" style={{ filter: 'brightness(0)' }}></span>
                                                            <span className="path2 text-white" style={{ filter: 'brightness(1)' }}></span>
                                                        </i>
                                                    )}

                                                </div>

                                                <div className='m-0 p-0'>

                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'Status']}
                                                        initialValue={'In-process'}
                                                        className='h-1px m-0 p-0'
                                                    >
                                                        <Input hidden value="In-process" />

                                                    </Form.Item>
                                                </div>
                                                {/* <div className='m-0 p-0'>
                                                    <span className="text-gray-800 fw-semi-bold d-block mb-1 fs-7 required form-label  fw-bold text-dark text-uppercase">Title</span>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'Title']}
                                                        // rules={[{ required: true, message: 'Missing  Title' }]}
                                                        rules={[
                                                            {
                                                                required: !isExistingField?.Status || isExistingField?.Status !== "Approved",
                                                                message: 'Missing Title'
                                                            }
                                                        ]}
                                                        className='my-1 p-0'
                                                    >

                                                        <Input size="small" className='form-control  form-control-sm m-0 p-2'
                                                            disabled={isExistingField?.Status === "Approved"}
                                                            placeholder="`Dubizzle`,`Bayut`... and so on"
                                                            onChange={(e) => {
                                                                const currentLinks = form.getFieldValue("links");
                                                                currentLinks[name] = {
                                                                    ...currentLinks[name],
                                                                    Title: e.target.value,
                                                                    Status: "In-process",
                                                                };
                                                                form.setFieldsValue({ links: currentLinks });
                                                                updateStatusToInProcess(name);
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div> */}
                                                {/*    <div className='m-0 p-0'>
                                                    <span className="text-gray-800 fw-semi-bold d-block mb-1 fs-7 required form-label  fw-bold text-dark text-uppercase">Link</span>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'Link']}
                                                        rules={[{ required: true, message: 'Missing Link' }]}
                                                        className='  my-1 p-0'
                                                    >
                                                        <Input className='form-control  form-control-sm m-0 p-2'
                                                            disabled={isExistingField?.Status === "Approved"}
                                                            placeholder="https://dubai.dubizzle.com"
                                                            onChange={(e) => {
                                                                const currentLinks = form.getFieldValue("links");
                                                                currentLinks[name] = {
                                                                    ...currentLinks[name],
                                                                    Link: e.target.value,
                                                                    Status: "In-process",
                                                                };
                                                                form.setFieldsValue({ links: currentLinks });
                                                                updateStatusToInProcess(name);
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div> */}
                                                <div className='m-0 pb-2'>
                                                    <span className="text-gray-800 fw-semi-bold d-block mb-1 fs-7 required form-label  fw-bold text-dark text-uppercase">Trakheesi</span>

                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'Trakheesi_id']}
                                                        //  rules={[{ required: !isExistingField, message: 'Missing Trakheesi ID' }]}
                                                        rules={[
                                                            {
                                                                required: !isExistingField?.Status || isExistingField?.Status !== "Approved",
                                                                message: 'Missing Trakheesi ID'
                                                            }
                                                        ]}
                                                        className='  my-1 p-0 mb-1'
                                                    >

                                                        <Input size="middle" className='form-control form-control-sm m-0 p-2'
                                                            disabled={isExistingField?.Status === "Approved"}
                                                            placeholder="253479128002"
                                                            onChange={(e) => {
                                                                const currentLinks = form.getFieldValue("links");
                                                                currentLinks[name] = {
                                                                    ...currentLinks[name],
                                                                    Trakheesi_id: e.target.value,
                                                                    Status: "In-process",
                                                                };
                                                                form.setFieldsValue({ links: currentLinks });
                                                                updateStatusToInProcess(name);
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        {!isLastItem && <div className="separator separator-dashed border-gray-500 my-3"></div>}
                                    </>
                                )
                            })}
                            <Form.Item>
                                <Button type="dashed" className="btn btn-bso mt-2" style={{ padding: '2px' }} onClick={() => { add() }} block icon={<PlusOutlined />}>
                                    Add Trakheesi ID
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>

        </div>

    )
}
