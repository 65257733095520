import { type AppDispatch, type RootState } from '../../../../store'

interface AddOfferTenantTypeData {
    TenantType: string
}

export const AddOfferTenantTypes = ['Potential client is an individual', 'Potential client is a company'] // 'Potential client is 2+ individuals'

export const addOfferSaleTenantType = (unitID: string | undefined, objectID: string | undefined) => (data: AddOfferTenantTypeData) => async (dispatch: AppDispatch, getState: () => RootState) => {
    switch (data.TenantType) {
        case AddOfferTenantTypes[0]:
            return {
                isSuccessful: true,
                textNavigate: `/agent/modal/agent_set_offer_modal_documents_individual_sale/${unitID ?? '-'}/${objectID ?? '-'}`,
                textNotification: ''
            }
        case AddOfferTenantTypes[1]:
            return {
                isSuccessful: true,
                textNavigate: `/agent/modal/agent_set_offer_modal_documents_company_sale/${unitID ?? '-'}/${objectID ?? '-'}`,
                textNotification: ''
            }
        default:
            break
    }
}
