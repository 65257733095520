import { type IOrder } from '../../../store/slices/userSlice'

export const getLatestServiceAgentSubscription = (orders: [] | IOrder[]) => {
    const ServiceAgentSubscription = orders
        ? orders.filter((order: IOrder) =>
            order.Service?.some(
                (service) => service.__component === 'services.broker-subscription'
            )
        )
        : []
    ServiceAgentSubscription?.sort((a, b) => {
        const aCreatedAt = a.createdAt != null ? new Date(a.createdAt) : new Date(0)
        const bCreatedAt = b.createdAt != null ? new Date(b.createdAt) : new Date(0)
        return bCreatedAt.getTime() - aCreatedAt.getTime()
    })
    const latestServiceAgentSubscription = ServiceAgentSubscription?.[0]
    return latestServiceAgentSubscription
}
