import React, { useEffect, useState } from 'react'
import CustomFormLable from '../customModalUtils/CustomFormLable'
import { Controller } from 'react-hook-form'
import _ from 'lodash'
import Icon from '../../icon/Icon'
import { CustomEmiratesIdTitleDeedFields } from './CustomEmiratesIdInput'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { dataFetchingFinish, dataFetchingStart } from '../../../store/slices/modalSlice'
import { ACCESS_KEY } from '../../../urls'
import { uploadFile } from '../../../store/actionsCreators/documentsActions/upload/uploadFile'
import { scanDocument } from '../../../store/actionsCreators/documentsActions/scaning/documentScan'
import { setCreatePropertyFields } from '../../../store/slices/propertySlice'
import { setTemporaryTitleDeed } from '../../../store/slices/documentSlice'

export default function ScanTitleDeedForAgentEditUnit({
    row,
    errors

}: any): JSX.Element {
    const documents = useAppSelector((state: RootState) => state.documents.documents)
    const currentTitleDeed = documents.filter((doc) => doc.id === row?.idTitleDeed)
    const dispatch = useAppDispatch()

    const [selectedFile, setSelectedFile] = useState<any>(null)
    const [loadingLocal, setLoadingLocal] = useState(false)
    const loading = useAppSelector(state => state.auth.loading)
    const temporaryTitleDeed = useAppSelector(state => state.documents.temporaryTitleDeed)

    const isValid =
        temporaryTitleDeed != null
            ? temporaryTitleDeed?.document?.valid
            : currentTitleDeed[0]?.attributes?.Valid

    // dispatch(setTemporaryTitleDeed(null))
    // dispatch(setCreatePropertyFields(null))
    const scanHandler = async (): Promise<any> => {
        if (selectedFile == null) return
        setLoadingLocal(true)
        dispatch(dataFetchingStart())

        try {
            const scanDoc = await dispatch(scanDocument({ agent_edit_unit_title_deed: selectedFile }))
            //   console.log(scanDoc, 'scanDoc')
            setLoadingLocal(true)
            if (scanDoc && scanDoc !== null && scanDoc.document) {
                dispatch(setTemporaryTitleDeed(scanDoc))

                if (scanDoc?.document?.property) {
                    dispatch(setCreatePropertyFields(scanDoc?.document?.property))
                }
                setLoadingLocal(false)
            }
        } catch (error) {
            console.error('Error while scanning title deed:', error)
        } finally {
            dispatch(dataFetchingFinish())
            setLoadingLocal(false)
        }
    }

    return (
        <>

            <div
                className={'card bg-white p-4 my-4'}
                style={{ border: '1px solid #d9d9d9' }}
            >
                <div className={'d-flex flex-column gap-4 mb-4'}>
                    <div>
                        <div
                            className={
                                'w-100 d-flex align-items-center justify-content-between'
                            }
                        >
                            <label
                                className={
                                    'form-label fw-bold mb-2 text-dark text-uppercase'
                                }
                            >
                                Title Deed
                            </label>

                            <div className={'form-label fw-bold text-dark text-uppercase d-flex align-items-center'
                            }>
                                {

                                    isValid
                                        ? <>
                                            <Icon
                                                name={'check_circle'}
                                                className={'text-success fs-1'}
                                            />
                                            <span className='pt-1 ms-1'>Verified</span></>

                                        : <>
                                            <Icon
                                                name={'cancel'}
                                                className={'text-danger fs-1'}
                                            />
                                            <span className='pt-1 ms-1'>Not Verified</span></>
                                }

                            </div>

                        </div>
                        <input
                            onChange={(event) => {
                                const files = event.target.files
                                if ((files != null) && files.length > 0) {
                                    setSelectedFile(files)
                                }
                            }}
                            id={'agent_edit_unit_title_deed'}
                            className="form-control form-control-solid form-control-lg"
                            type="file"
                            placeholder="Choose File"
                        />
                    </div>
                </div>

                {!loading || !loadingLocal
                    ? (
                        <div
                            className={'btn btn-bso'}
                            onClick={scanHandler}
                        >
                            Re-Scan
                        </div>
                    )
                    : (
                        <div className={'btn btn-bso disabled'}>
                            <span className="indicator-progress d-block">
                                Please wait...
                                <span
                                    className={
                                        'spinner-border spinner-border-sm align-middle ms-4'
                                    }
                                ></span>
                            </span>
                        </div>
                    )}

            </div >

            <div className="fv-row my-2 py-2 w-100 d-flex flex-wrap gap-2">
                <div className="badge badge-secondary mb-0">
                    <span className="align-items-center text-dark fs-6 ms-0 me-2">
                        Title Deed Number:
                    </span>
                    <span className="align-items-center text-dark fs-6 fw-bold ms-0">
                        {temporaryTitleDeed?.document?.number ?? (currentTitleDeed[0]?.attributes?.Type as any)?.[0]?.Number ?? 'Not scanned'}
                    </span>
                </div>
                <div className="badge badge-secondary mb-0">
                    <span className="align-items-center text-dark fs-6 ms-0 me-2">
                        Title Deed Type:
                    </span>
                    <span className="align-items-center text-dark fs-6 fw-bold ms-0">
                        {temporaryTitleDeed?.document?.type ?? (currentTitleDeed[0]?.attributes?.Type as any)?.[0]?.Type ?? 'Not scanned'}
                    </span>
                </div>
                <div className="badge badge-secondary mb-0">
                    <span className="align-items-center text-dark fs-6 ms-0 me-2">
                        Title Deed Year:
                    </span>
                    <span className="align-items-center text-dark fs-6 fw-bold ms-0">
                        {temporaryTitleDeed?.document?.year ?? (currentTitleDeed[0]?.attributes?.Type as any)?.[0]?.Year ?? 'Not scanned'}
                    </span>
                </div>
            </div >
            {
                _.get(errors, row.id) != null && (
                    <div className="text-danger">
                        {_.get(errors, row.id).message}
                    </div>
                )
            } {' '}
        </>
    )
}
