
import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../../hooks/redux'
import { generateBgAndBorderColFromOfferMessage } from '../../../../functions/agent/schedule/generateBgAndBorderCol'
import { nanoid } from 'nanoid'
import { type RootState } from '../../../../store/store'
import UseUserType, { type UserType } from '../../../../hooks/UseUserType'
import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../../urls'
import Icon from '../../../../app/icon/Icon'
import { type User } from '../../../../store/slices/userSlice'
import { type Unit } from '../../../../store/slices/unitsSlice'
import { getStatusBroadcast } from '../../../../functions/shared/date/getStatusBroadcast'
import { getDaysUntilHideDate } from '../../../../functions/shared/date/getDaysUntilHideDate'

interface IProps {
    currentBroadcast: any
}
export default function BroadcastCardClient({ currentBroadcast }: IProps): JSX.Element {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const { userType, userTypeLowercase } = UseUserType()
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)

    const storeUnits = useAppSelector((state: RootState) => state.units.units)
    const isUnitsBSOIds = storeUnits
        .filter((__unit) => __unit.attributes.ManagedBy === 'BSO')
        .map((_unit) => _unit.id)

    const agentUnitsIds = (((currentUser as User)?.Units) != null) && Array.isArray(currentUser?.Units) && currentUser?.Units?.length > 0
        ? currentUser?.Units.map((_unit: Unit) => _unit.id)
        : []

    const task = {
        type: 'TYPE UNIT',
        color: 'secondary',
        redirect: 'catalog'
    }

    if (currentBroadcast?.Unit?.attributes?.User?.data?.id === Number(userID)) {
        task.type = 'MY UNIT'
        task.color = 'primary'
        task.redirect = 'my_unit'
    }
    if (currentBroadcast?.Sender === Number(userID) && isUnitsBSOIds.includes(currentBroadcast?.Unit?.attributes?.id as number)) {
        task.type = 'BSO UNIT'
        task.color = 'warning'
        task.redirect = 'catalog'
    } if (currentBroadcast?.Sender === Number(userID) && !agentUnitsIds.includes(currentBroadcast?.Unit?.attributes?.id as number) && !isUnitsBSOIds.includes(currentBroadcast?.Unit?.attributes?.id as number)) { // && currentOffer?.attributes?.AgentToAgentID && currentOffer?.attributes?.AgentToAgentID !== Number(userID)
        task.type = 'OTHER AGENT UNIT'
        task.color = 'info'
        task.redirect = 'other_agent_s_unit'
    }

    const currentUnit = useAppSelector(
        (state: RootState) => state.units.units
    )?.filter(
        (unit) => unit.id === Number(currentBroadcast?.Unit?.attributes?.id)
    )[0]

    const [unitNumber, buildName, location] = [
        currentUnit?.attributes?.Number ?? 'Not number',
        currentUnit?.attributes?.Property?.data?.attributes?.Name ?? 'Not title',
        currentUnit?.attributes?.Property?.data?.attributes?.Area ?? 'Not location'
    ]

    return (
        <div
            key={`${nanoid()}-${currentBroadcast.id}`}
            className={`card shadow-sm my-2 p-1 me-1 position-relative border border-1 border-${generateBgAndBorderColFromOfferMessage(currentBroadcast).borderColor} bg-light-${generateBgAndBorderColFromOfferMessage(currentBroadcast).borderColor}`}
        >
            <div className={'d-flex flex-row row g-0 align-items-start justify-content-between'}>

                <div className="d-flex flex-column col-8 p-2 ">

                    <div className="d-flex mb-2">
                        <div className="col d-flex align-items-center">
                            <div className="symbol symbol-20px symbol-circle me-3">
                                <span className="symbol-label border border-dark">
                                    <Icon name={'location_on'} className={'text-dark fs-2x'} />
                                </span>
                            </div>
                            <div className="m-0 lh-2">
                                <span className="fw-semibold text-gray-500 d-block fs-8">Fit</span>
                                <div className="fw-bold text-gray-800 text-hover-primary fs-7 lh-1">Semi Furnished</div>
                            </div>
                        </div>
                        <div className="col d-flex align-items-center">
                            <div className="symbol symbol-20px symbol-circle me-3">
                                <span className="symbol-label border border-dark ">
                                    <Icon name={'monetization_on'} className={'text-dark fs-2x'} />
                                </span>
                            </div>
                            <div className="m-0 lh-1">
                                <span className="fw-semibold text-gray-500 d-block fs-8">Budget</span>
                                <span className="fw-bold text-gray-800 fs-7 lh-1">$64.800</span>
                            </div>
                        </div>
                    </div>
                    <div className={`d-flex mb-2 p-1 lh-1 fs-8 text-gray-600 rounded max-h-20 border border-${generateBgAndBorderColFromOfferMessage(currentBroadcast).borderColor} bg-light-${generateBgAndBorderColFromOfferMessage(currentBroadcast).borderColor}`}
                        style={{ height: '45px', overflow: 'hidden', whiteSpace: 'normal' }}
                    >
                        Hello!
                        I'm searching for a 2-bedroom apartment in a safe and convenient neighborhood. Here are my requirements:
                        Budget: Up to $1,500 per month
                        Bedrooms: 2
                        Living Room: Spacious living room required
                        Amenities: Preferably with a gym and a swimming pool
                        If you have any listings that meet these criteria, please get in touch. Thank you!
                    </div>

                    <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                        <Icon
                            name={'person'}
                            styles={{ minWidth: '12.9px' }}
                        />
                        <div className="">
                            <span className={'text-dots-app text-dark text-bold'}>
                                Pavel Morozov
                                {/* {currentBroadcast?.User?.FirstName ?? 'No'} */}
                                {/* {currentBroadcast?.User?.FamilyName ?? 'name'} */}
                            </span>
                            <div className="fs-8 text-gray-700 my-n2">Client</div>
                        </div>
                    </span>

                </div>

                <div className="d-flex flex-column col-4 p-1 text-end">
                    <div className="fs-6 d-flex align-items-center justify-content-end">

                        <div className="d-flex flex-column">

                            <div className="fs-1x text-dark lh-1 fw-bold mt-2" key={nanoid()}>
                                <span
                                    className={`me-1 text-end badge h-10px min-w-10px w-10px badge-circle badge-outline badge-${generateBgAndBorderColFromOfferMessage(currentBroadcast).borderColor} bg-light-${generateBgAndBorderColFromOfferMessage(currentBroadcast).borderColor}`}
                                >
                                    {' '}
                                </span>
                                {currentBroadcast.HideDate && getStatusBroadcast(currentBroadcast.HideDate)
                                }
                            </div>

                        </div>
                    </div>
                    <div className="text-dark pe-2 pt-1 d-flex align-items-center justify-content-end gap-1">

                        <div className="">
                            {currentBroadcast?.HideDate && getDaysUntilHideDate(currentBroadcast?.HideDate)}
                        </div>
                        <div className="fs-8 text-gray-700">Last days</div>
                    </div>
                    <div className="text-dark pe-2  d-flex align-items-center justify-content-end gap-1">

                        <div className="">
                            123
                        </div>
                        <div className="fs-8 text-gray-700">Views</div>
                    </div>
                    <Link
                        to={`/${userTypeLowercase}/chats/${currentBroadcast.ChatRoom}`}
                        className="text-decoration-none"
                    >
                        <div
                            className={`fs-7 pt-1 badge border border-${generateBgAndBorderColFromOfferMessage(currentBroadcast).borderColor}`} >
                            GET INFO
                            <Icon
                                name={'expand_more'}
                                className={'text-dark fs-2 ms-1'}
                                useWrapper
                            />
                        </div>
                    </Link>

                </div>
            </div>
        </div >
    )
}
