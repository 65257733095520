import React, { useState } from 'react'

import AgentHeader from '../../../agentModule/AgentHeader'

import ProfileMetrics from './AgentProfileMetrics'

import AgentAreas from './AgentAreas'
import { type RootState } from '../../../../store/store'
import { useAppSelector } from '../../../../hooks/redux'
import AgentProfileDocuments from './AgentProfileDocuments'
import { type ISettings, type User } from '../../../../store/slices/userSlice'

import { generateProgressProfile } from '../../../../functions/agent/profile/generateProgressProfile'
import { getMetrics } from './getMetric'
import MyProfileHead from '../other/viewOtherProfile/MyProfileHead'

// __component:"documents.emirates-id"

export default function LandlordProfile (): JSX.Element {
    const currentUser = useAppSelector(
        (state: RootState) => state.user.currentUser
    ) as User
    const settings = currentUser?.Settings as ISettings[]

    const documents = useAppSelector(
        (state: RootState) => state.documents.documents
    )
    //  console.log(currentUser, 'currentUser')
    // console.log(documents, 'documents')
    // console.log(documents, 'documents')
    const userID = currentUser.id
    const [previewOpen, setPreviewOpen] = useState(false)

    const [progressProfile, setProgressProfile] = useState<number>(generateProgressProfile(currentUser, documents))
    // console.log(currentUser, 'currentUser')
    const profileMetrics = getMetrics(currentUser)
    //  console.log(profileMetrics, 'profileMetrics')
    const progressBarWidth = 300
    const progressInPixels = (progressProfile / 100) * progressBarWidth

    return (
        <>
            <div
                className="page d-flex flex-column flex-row-fluid fs-5"
            >

                <AgentHeader
                    active="Profile"
                />

                <div className="content d-flex flex-column flex-fill p-0 m-4">
                    <div className="w-100 mw-450px mx-auto">
                        <div className="card shadow-sm mb-4 mt-2">
                            <div className="card-body p-4 ">
                                <MyProfileHead previewOpen={previewOpen} setPreviewOpen={setPreviewOpen} currentUser={currentUser}/>

                                <div className="d-flex align-items-center w-300px  flex-column">
                                    <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                        <span className="fw-semibold fs-6 text-gray-400">Profile Completion</span>
                                        <span className="fw-bold fs-6 ">{progressProfile}%</span>
                                    </div>
                                    <div className="h-5px mx-3 w-100 bg-secondary mb-2">
                                        <div className="bg-success rounded h-5px" style={{ width: `${progressInPixels}px` }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card shadow-sm mb-4">
                            <div className="card-body p-4">
                                <ProfileMetrics
                                    metrics={profileMetrics}
                                />
                            </div>
                        </div>

                        <div className="card shadow-sm mb-4">
                            <div className="card-body p-4">
                                <AgentProfileDocuments documents={documents}/>
                            </div>
                        </div>

                        <div className="card shadow-sm mb-4">
                            <div className="card-body p-4">
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <div className={'text-gray-900 fs-4 fw-bold '}>Preferred Areas</div>
                                </div>
                                <AgentAreas user={currentUser}/>
                            </div>
                        </div>

                    </div>
                </div>
                <div style={{ height: '160px' }}></div>
            </div>
            {/* <div className={`oppacity-${!previewOpen ? 100 : 25}`}>
                <LandlordFooter />
            </div> */}
        </>
    )
}
