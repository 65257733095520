import React, { type ReactElement } from 'react'
import useSaveScroll from '../../../hooks/useSaveScroll'
import { setUnitsCatalogScrollPosition } from '../../../store/slices/unitsSlice'
import { type RootState } from '../../../store/store'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
interface UnitsSaverScrollProps {
    scrollRef: React.RefObject<HTMLDivElement>
}
export default function UnitsScrollSaver ({ scrollRef }: UnitsSaverScrollProps): ReactElement {
    const catalogScrollPosition = useAppSelector((state: RootState) => state.units.catalogScrollPosition)
    const dispatch = useAppDispatch()
    useSaveScroll({
        scrollRef,
        position: catalogScrollPosition,
        setPosition: (position: number) => dispatch(setUnitsCatalogScrollPosition(position)),
        getPosition: (state: RootState) => state.units.catalogScrollPosition
    })
    return <></>
}
