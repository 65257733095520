
export function isInInterval(startDateD: string, endDateD: string): boolean {
    const currentDate = new Date();

    //  startDate в начало дня (00:00:00)
    const startDate = new Date(startDateD);
    startDate.setHours(0, 0, 0, 0);

    //  endDate в конец дня (23:59:59)
    const endDate = new Date(endDateD);
    endDate.setHours(23, 59, 59, 999);

    //  находится ли currentDate в интервале
    return currentDate >= startDate && currentDate <= endDate;
}
