import React, { useState } from 'react'

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import {
    setButtonDriverAddOnsParent,
    setIsBricksOpen
} from '../../../../store/slices/modalSlice'
import AddOnServicesInfoDrawer from './AddOnServicesInfoDrawer'
import { marketingCreditsImage } from '../../../../urls'
import AddOnServicesBricksInfo from './AddOnServicesBricksInfo'

import AddOnServicesCardWithoutCarousel from './AddOnsServicesCardWithoutCarousel'

import { type AddOnsServiceSliderCardOptions } from '../../../../types'
import { setNotEnoughMarketingCredits } from '../../../../store/slices/advertisesSlice'
import { swalError } from '../../../../functions/swalOptions'
import { createSubscriptionAgent } from '../../../../store/actionsCreators/subscriptionsActions/createSubscriptionAgent'

export default function AddOnsServiceBricks(): React.ReactElement {

    const isBricksOpen = useAppSelector((state: RootState) => state.modal.isBricksOpen)
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const onBuyPack = async (countPack: number): Promise<void> => {
        const createOrder = await dispatch(
            createSubscriptionAgent(
                `pack_coins_${countPack}`,
                undefined,
                {
                    isTestMode:
                        process.env.REACT_APP_IS_DEV === 'true',
                    selectedPeriod: ''
                }
            )
        )

        if (createOrder?.isSuccessful === true) {
            window.open(createOrder?.url)
            dispatch(setButtonDriverAddOnsParent(false))
            dispatch(setNotEnoughMarketingCredits(0))
        } else {
            void swalError("Sorry, your purchase couldn't be completed. Please try again later.", () => {
                dispatch(setButtonDriverAddOnsParent(false))
                dispatch(setNotEnoughMarketingCredits(0))
            })
        }
    }

    const config: AddOnsServiceSliderCardOptions = {
        title: 'Add bricks',
        description:
            'Think of Bricks as your digital currency within our app, designed specifically to unlock premium marketing features.',
        imageUrl: marketingCreditsImage,
        purchaseText: 'Purchase',
        onPurchase: (amount) => {
            void (async () => {
                setIsLoading(true)
                await onBuyPack(amount)
                setIsLoading(false)
            })()
        },
        onInfo: () => {
            dispatch(setIsBricksOpen(true))
        },
        showTermsAndConditions: true,
        options: [
            {
                product: {
                    amount: 0,
                    unit: 'Bricks'
                },
                price: {
                    amount: 0,
                    unit: 'AED',
                    postfix: ''
                },
                calculator: {
                    use: true,
                    oneUnitPrice: 3,
                    max: 99
                }
            },
            {
                product: {
                    amount: 100,
                    unit: 'Bricks'
                },
                price: {
                    amount: 250,
                    unit: 'AED',
                    postfix: '/ Bundle'
                }
            },
            {
                product: {
                    amount: 200,
                    unit: 'Bricks'
                },
                price: {
                    amount: 400,
                    unit: 'AED',
                    postfix: '/ Bundle'
                }
            }
        ]
    }
    return (
        <>
            <AddOnServicesCardWithoutCarousel
                key={'Bricks services'}
                img={marketingCreditsImage}
                config={config}
                isLoading={isLoading}
            />
            <AddOnServicesInfoDrawer
                isOpen={isBricksOpen}
                onClose={() => {
                    dispatch(setIsBricksOpen(false))
                }}
                key={'Bricks info'}
            >
                <AddOnServicesBricksInfo />
            </AddOnServicesInfoDrawer>
        </>
    )
}
