import _ from 'lodash'

const formatPrice = (price: number | string | undefined | null): string => {
    if (price == null) {
        return '-'
    }
    let numPrice: number
    if (_.isString(price)) {
        numPrice = +price
    } else {
        numPrice = price
    }
    if (numPrice > 99999 && numPrice < 1000 * 1000) {
        return `${Math.floor(numPrice / 1000)}K`
    } else if (numPrice >= 1000 * 1000 && numPrice < 1000 * 1000 * 1000) {
        return `${Math.floor(numPrice / (1000 * 1000))}M`
    } else if (numPrice >= 1000 * 1000 * 1000) {
        return `${Math.floor(numPrice / (1000 * 1000 * 1000))}B`
    } else {
        return `${numPrice}`
    }
}

export default formatPrice
