import React, { type ReactElement } from "react";
import Icon from "../../../../app/icon/Icon";
import {type User} from "../../../../store/slices/userSlice";

interface IProps {
    user: User
}

export default function AgentProfileContactInfo ({user}: IProps): ReactElement {
    return <div className="card shadow-sm mb-4 mx-4">
        <div className="card-body p-4">
            <div className="border border-gray-300 border-dashed rounded py-3 px-4 w-100 g-0">
                <div className="fs-2 fw-bold counted">Contact info</div>
                <div className="row">
                    <div className={'col-9 fw-semibold fs-6 text-gray-600'}>
                        <div>
                            Email :
                        </div>
                        <div>
                            Phone :
                        </div>
                    </div>
                    <div className={'col-3 fw-semibold fs-6 text-gray-600 d-flex flex-column align-items-end text-nowrap'}>
                        <a href={`mailto:${user?.email ?? ''}`}>
                            <Icon name={'mail'}/> {user?.email}
                        </a>
                        <a href={`tel:${user?.phone ?? ''}`}>
                            <Icon name={'call'}/> {user?.phone}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}