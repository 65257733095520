import { createSlice } from '@reduxjs/toolkit'
import { type User } from './userSlice'
import _ from 'lodash'

interface AreasState {
    areas: Array<{
        name: string
        agents: User[]
    }>
    openKeys: string[]
    extendAreas: string[] | []
    isAlreadyOpenLinkExtendAreas: boolean
    whatIsNotThereArea: string | null
}

const initialState: AreasState = {
    areas: [],
    openKeys: [],
    extendAreas: [],
    isAlreadyOpenLinkExtendAreas: false,
    whatIsNotThereArea: null
}

export const areasSlice = createSlice({
    name: 'areas',
    initialState,
    reducers: {
        setAreaUsers: (state, action: { payload: { name: string, agents: User[] } }) => {
            const areasCopy = _.cloneDeep(state.areas)
            const areaIndex = _.findIndex(areasCopy, (area) => area.name === action.payload.name)
            if (areaIndex === -1) {
                areasCopy.push(action.payload)
            } else {
                areasCopy[areaIndex].agents = action.payload.agents
            }
            state.areas = areasCopy
        },
        toggleOpenKey: (state, action) => {
            const openKeysCopy = _.cloneDeep(state.openKeys)
            if (_.includes(openKeysCopy, action.payload)) {
                openKeysCopy.splice(openKeysCopy.indexOf(action.payload), 1)
            } else {
                openKeysCopy.push(action.payload)
            }
            state.openKeys = openKeysCopy
        },
        setExtendAreas: (state, action) => {
            state.extendAreas = action.payload
        },
        setIsAlreadyOpenLinkExtendAreas: (state, action) => {
            state.isAlreadyOpenLinkExtendAreas = action.payload
        },
        setWhatIsNotThereArea: (state, action) => {
            state.whatIsNotThereArea = action.payload
        }

    }
})

export const {
    setAreaUsers,
    toggleOpenKey,
    setExtendAreas,
    setIsAlreadyOpenLinkExtendAreas,
    setWhatIsNotThereArea
} = areasSlice.actions
export default areasSlice.reducer
