import { store } from '../../../store/store'
import dayjs from 'dayjs'
import { type User, type Documents } from '../../../store/slices/userSlice'

/**
 * Function checks if user's passport or emirates id is not archived and is not expired
 * @param userID
 */
export default function checkDocumentsForOtherUser (userID: number): boolean {
    const user: User | undefined = store.getState().user.allUsers.find(user => user.id === userID)
    if (user == null) {
        return false
    }
    const validDocuments: Documents[] | undefined = user.Documents?.filter(document => {
        return document?.Statuses?.includes('Archived') !== true && dayjs().isBefore(dayjs(document.ExpiryDate))
    })
    return Boolean(
        validDocuments?.some((document) => {
            return ['documents.emirates-id', 'documents.passport'].includes(document?.Type?.[0]?.__component)
        })
    )
}
