import React, { useState } from 'react'
import Icon from '../../../app/icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from "../../../hooks/redux";

interface IProps {
    callbackMessage: any
    callbackBlock: any
    callbackFavourite: any
    isBlocked: boolean
    isFavorite: boolean
    userID: number
}
function ActionButtons({ callbackMessage, callbackBlock, callbackFavourite, isBlocked, isFavorite, userID }: IProps): JSX.Element {
    const [isLoading, setIsLoading] = useState({ message: false, block: false, favourite: false })
    const navigate = useNavigate()
    const isDev = process.env.REACT_APP_IS_DEV === 'true'
    const currentUser = useAppSelector((state) => state.user.currentUser)
    return (
        <div className="px-4">
            <div className="d-flex flex-row align-items-stretch fw-semi-bold mb-4 gap-2 justify-content-between">
                <div className="text-center card shadow-sm py-2 px-2 text-gray-700 flex-fill"
                    style={{ minWidth: "80px" }}
                    onClick={!isLoading.message
                        ? () => callbackMessage((state: boolean) => {
                            setIsLoading((prev) => ({ ...prev, message: state }));
                        })
                        : () => null
                    }
                >
                    {!isLoading.message
                        ? <Icon name="send" className="mx-auto fs-4x my-1 text-gray-700" />
                        : <span className="indicator-progress d-block text-gray-700 my-1">
                            <span className="spinner-border spinner-border-md align-middle"></span>
                        </span>
                    }
                    <div className="fs-7 lh-1 px-2">Message</div>
                </div>

                <div className="text-center card shadow-sm py-2 px-2 text-gray-700 flex-fill"
                    style={{ minWidth: "80px" }}
                    onClick={!isLoading.block
                        ? () => callbackBlock((state: boolean) => {
                            setIsLoading((prev) => ({ ...prev, block: state }));
                        })
                        : () => null
                    }
                >
                    {!isLoading.block
                        ? <Icon name={isBlocked ? 'lock' : 'lock_open_right'} className="mx-auto fs-4x my-1 text-gray-700" />
                        : <span className="indicator-progress d-block text-gray-700 my-1">
                            <span className="spinner-border spinner-border-md align-middle"></span>
                        </span>
                    }
                    <div className="fs-7 lh-1 px-2">
                        {!isBlocked ? 'Add to Black List' : 'Remove from Black List'}
                    </div>
                </div>

                <div className={`text-center card shadow-sm py-2 px-2 flex-fill ${!isBlocked ? 'text-gray-700' : 'text-gray-400'}`}
                    style={{ minWidth: "80px" }}
                    onClick={!isBlocked && !isLoading.favourite
                        ? () => callbackFavourite((state: boolean) => {
                            setIsLoading((prev) => ({ ...prev, favourite: state }));
                        })
                        : () => null
                    }
                >
                    {!isLoading.favourite
                        ? <Icon name="bookmark" filled={isFavorite} className={`mx-auto fs-4x my-1 ${!isBlocked ? 'text-gray-700' : 'text-gray-400'}`} />
                        : <span className="indicator-progress d-block text-gray-700 my-1">
                            <span className="spinner-border spinner-border-md align-middle"></span>
                        </span>
                    }
                    <div className="fs-7 lh-1 px-2">
                        {!isFavorite ? 'Add to Favorites' : 'Remove from Favorites'}
                    </div>
                </div>

                {isDev && currentUser?.id !== Number(process.env.REACT_APP_BSO_USER_ID) &&
                    <div className="text-center card shadow-sm py-2 px-2 text-gray-700 flex-fill"
                        style={{ minWidth: "80px" }}
                        onClick={() => { navigate(`/agent/catalog_agent/${userID}`); }}
                    >
                        <Icon name="home" className="mx-auto fs-4x my-1" />
                        <div className="fs-7 lh-1 px-2">Units catalog</div>
                    </div>
                }
            </div>
        </div>




    )
}

export default ActionButtons

