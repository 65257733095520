import { type User } from '../../../store/slices/userSlice'
import _ from 'lodash'
import checkDocumentsForOtherUser from '../../agent/documents/checkDocumentsForOtherUser'
import { hideMails } from '../../../constants'
import { store } from '../../../store/store'
import { REACT_APP_BSO_SUPPORT_USER_ID, REACT_APP_BSO_USER_ID } from '../../../urls'

const checkNotSystemUser = (user: User): boolean => {
    return !_.includes([process.env.REACT_APP_BSO_SUPPORT_USER_ID, process.env.REACT_APP_BSO_USER_ID, String(store.getState().user.currentUser.id)], String(user.id))
}

const filterUserCallback = (user: User): boolean => {
    const documentsValid = checkDocumentsForOtherUser(user.id)
    const userEmail = user.email

    if (Boolean(userEmail) && !hideMails.includes(userEmail)) {
        return (
            documentsValid &&
            checkNotSystemUser(user) &&
            user.id !== Number(REACT_APP_BSO_SUPPORT_USER_ID) &&
            user.id !== Number(REACT_APP_BSO_USER_ID) &&
            user.Category === 'Agent' &&
            user.id !== store.getState().user.currentUser.id &&
            user?.emailVerified
        )
    }

    return false
}

export default filterUserCallback
