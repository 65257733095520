import { useEffect, type RefObject } from 'react'
import _ from 'lodash'
import { type RootState, store } from '../store/store'

export interface UseSaveScrollProps {
    scrollRef?: RefObject<HTMLElement>
    position: number
    setPosition: (offset: number) => void
    getPosition?: (state: RootState) => number
}

const DEBOUNCE_RATE = 40

const UseSaveScroll = ({ scrollRef, position, setPosition, getPosition }: UseSaveScrollProps): void => {
    useEffect(() => {
        const scrollWrapper = scrollRef?.current != null ? scrollRef.current : document.querySelector('#root')
        let localPosition = getPosition != null ? getPosition(store.getState()) : position
        const handleScroll = _.debounce((e: any): void => {
            localPosition = e.target.scrollTop
            setPosition(localPosition)
        }, DEBOUNCE_RATE)

        scrollWrapper?.addEventListener('scroll', handleScroll)
        return () => {
            scrollWrapper?.removeEventListener('scroll', handleScroll)
        }
    }, [setPosition])

    useEffect(() => {
        const scrollWrapper = scrollRef?.current != null ? scrollRef.current : document.querySelector('#root')
        if (scrollWrapper != null) {
            scrollWrapper.scrollTop = getPosition != null ? getPosition(store.getState()) : position
            //      console.log('position', getPosition != null ? getPosition(store.getState()) : position, getPosition)
        }
    }, [getPosition])
}

export default UseSaveScroll
