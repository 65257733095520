import {type AppDispatch, type RootState} from "../../store";
import {getQueryDocuments} from "../../../functions/shared/api/queries/getQueryDocuments";
import {ACCESS_KEY, urlAPI} from "../../../urls";

interface UpdateDocumentParams {
    id: number
    data: Record<string, any>
}

export const updateDocument = ({ id, data }: UpdateDocumentParams) => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ isSuccessful: boolean }> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const document = getState().documents.documents.find((doc) => doc.id === id)
    try {
        const sendObject = {
            method: 'PUT',
            collection: 'documents',
            id: Number(id),
            query: getQueryDocuments(),
            body: {
                ...data,
                Type: [{
                    ...(document?.attributes?.Type?.[0] ?? {}),
                    ...(data?.Type?.[0] ?? {})
                }]
            }

        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        if (responseJSON?.response?.error != null) {
            throw new Error(responseJSON?.response?.error)
        }
        return {
            isSuccessful: true
        }
    } catch (err) {
        console.error('Error while trying to update document', err)
    }
    return {
        isSuccessful: false
    }
}