export default async function downloadBlobByURL (href: string, filename: string): Promise<void> {
    try {
        const response = await fetch(href)
        const responseBlob = await response.blob()
        const url = window.URL.createObjectURL(responseBlob)
        const link = document.createElement('a')
        link.href = url
        link.download = filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
    } catch (err) {
        window.open(href)
        console.error(`Failed to download file with link ${href} `, err)
    }
}
