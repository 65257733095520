import { type Document } from '../../../store/slices/documentSlice'

export const checkIsHaveEmiratesAndExpires = (agentDocs: Document[]): any => {
    let expireFile = ''
    let file = null

    if (agentDocs.length > 0) {
        file = agentDocs.reduce((prevDoc: Document | null, currentDoc: Document) => {
            const prevDocType = prevDoc?.attributes?.Type?.[0]?.__component
            const currentDocType = currentDoc?.attributes?.Type?.[0]?.__component

            if (currentDocType === 'documents.emirates-id') {
                if (!prevDocType || new Date(currentDoc?.attributes?.updatedAt) > new Date(prevDoc?.attributes?.updatedAt)) {
                    expireFile = currentDoc?.attributes?.ExpiryDate || ''
                    return currentDoc
                }
            }
            return prevDoc
        }, null)
    }
    // console.log({ expireFile, file }, 'checkIsHaveEmiratesAndExpires')
    return { expireFile, file }
}

export const checkIsHavePassportAndExpires = (agentDocs: Document[]): any => {
    let expireFile = ''
    let file = null
    // console.log()
    file = Array.isArray(agentDocs) && agentDocs.length > 0
        ? agentDocs.reduce((prevDoc: Document | null, currentDoc: Document) => {
            //    console.log(currentDoc?.ExpiryDate, 'currentDoc = passportDoc')

            const prevDocType = prevDoc?.attributes?.Type?.[0]?.__component
            const currentDocType = currentDoc?.attributes?.Type?.[0]?.__component

            if (currentDocType === 'documents.passport') {
                if (!prevDocType || new Date(currentDoc?.attributes?.updatedAt) > new Date(prevDoc?.attributes?.updatedAt)) {
                    expireFile = currentDoc?.attributes?.ExpiryDate
                    return currentDoc
                }
            }
            return prevDoc
        }, null)
        : null
    // console.log({ expireFile, file }, 'checkIsHavePassportAndExpires')
    return { expireFile, file }
}

export const checkIsHaveReraAndExpires = (agentDocs: Document[]): any => {
    let expireFile = ''
    let file = null

    file = Array.isArray(agentDocs) && agentDocs.length > 0
        ? agentDocs.reduce((prevDoc: Document | null, currentDoc: Document) => {
        //  console.log(currentDoc, 'currentDoc = reraCardDoc')
            const prevDocType = prevDoc?.attributes?.Type?.[0]?.__component
            const currentDocType = currentDoc?.attributes?.Type?.[0]?.__component

            if (currentDocType === 'documents.rera-card') {
                if (!prevDocType || new Date(currentDoc?.attributes?.updatedAt) > new Date(prevDoc?.attributes?.updatedAt)) {
                    expireFile = currentDoc?.attributes?.ExpiryDate
                    return currentDoc
                }
            }

            return prevDoc
        }, null)
        : null

    return { expireFile, file }
}

// export const checkIsHaveTitleDeedAndVerification = (agentDocs: Document[]): any => {
//     let verification: any = []

//     if (agentDocs.length > 0) {
//         agentDocs.reduce((prevDoc: Document | null, currentDoc: Document) => {
//             if (prevDoc?.attributes.RefID.includes('agent_create_unit_title_deed')) {
//                 if (new Date(currentDoc?.attributes?.updatedAt) > new Date(prevDoc?.attributes?.updatedAt)) {
//                     verification = currentDoc.attributes.Statuses
//                     return currentDoc
//                 }
//             }
//             return prevDoc
//         }, null)
//     }
//     // console.log({ expireFile, file }, 'checkIsHaveEmiratesAndExpires')
//     return { verification }
// }
