import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface IPropertyModel {
    id: number
    attributes: {
        createdAt: string
        updatedAt: string
        Developer: string
        Name: string
        Area: string
        PlotNumber: string
        // Images: null,
        // Statuses: null,
        MAKANI: string
        Type: string
        // Community: null,
        GoogleMapsLink: string
        // Address: null,
        RefID: string
        Units: {
            data: any[]
        }
    }
}

interface PropertyDeleteTask {
    property: IPropertyModel
}

interface PropertyState {
    properties: IPropertyModel[]
    propertyDeleteTasks: PropertyDeleteTask[]
    newPropertyFields: {
        number: string | null
        name: string | null
        area: string | null
        type: string | null
        makani: string | null
    } | null

}

const initialState: PropertyState = {
    properties: [],
    propertyDeleteTasks: [],
    newPropertyFields: null
}
export const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        setProperties (state, action) {
            state.properties = action.payload
        },
        setCreatePropertyFields (state, action) {
            state.newPropertyFields = action.payload
        },
        addPropertyToStore: (state, action) => {
            const findDublicate = state.properties.find((property) => property.id === action.payload.id)
            if (findDublicate === undefined) {
                state.properties = [...state.properties, action.payload]
            }
        },
        addPropertyDeleteTask: (state, action: PayloadAction<{ id: number }>) => {
            const property = state.properties.find(propertyItem => propertyItem.id === action.payload.id)
            if (property != null) {
                state.propertyDeleteTasks.push({
                    property
                })
            }
        },
        clearPropertyDeleteTasks: (state) => {
            state.propertyDeleteTasks = []
        }
    }
})

export const {
    setProperties,
    setCreatePropertyFields,
    addPropertyToStore,
    addPropertyDeleteTask,
    clearPropertyDeleteTasks
} = propertySlice.actions

export default propertySlice.reducer
