import { removeDataAttributes } from '../../adapters/universal/removeDataAttributes'
import _ from 'lodash'

type unitOfferStatus = 'Under offer' | 'Rented' | 'Sold' | 'Free'

export default function getUnitOfferStatus (unit: any): unitOfferStatus {
    const unitCopy: any = removeDataAttributes(_.cloneDeep(unit))

    const sold: boolean = (unitCopy?.Statuses ?? [])?.includes('Sold') && unitCopy?.Marketing?.Use === 'For sale'
    if (sold) {
        return 'Sold'
    }
    const rented: boolean = (unitCopy?.Statuses ?? [])?.includes('Sold') && unitCopy?.Marketing?.Use === 'For rent'
    if (rented) {
        return 'Rented'
    }
    const underOffer = _.some(unitCopy?.Offers, (offer: any) => (
        _.includes(['Confirmed', 'Negotiated by BSO', 'Negotiated by agent', 'Negotiated by landlord', 'Waiting booking form sign'], offer?.Statuses?.[0])
    ))
    if (underOffer) {
        return 'Under offer'
    }
    return 'Free'
}

export function areUnitActionButtonsBlocked (unit: any): boolean {
    return !['Free', 'Under offer'].includes(getUnitOfferStatus(unit))
}

export function getUnitOfferUnderOfferType (unit: any): 'Under offer' | 'M.O.U in progress' {
    const unitCopy: any = removeDataAttributes(_.cloneDeep(unit))
    const offer = _.find(unitCopy?.Offers, (offer: any) => (
        _.includes(['Confirmed', 'Negotiated by BSO', 'Negotiated by agent', 'Negotiated by landlord', 'Waiting booking form sign'], offer?.Statuses?.[0])
    ))
    return offer?.Type?.[0]?.__component?.includes('sale') === true ? 'M.O.U in progress' : 'Under offer'
}
