

import { useState } from 'react'
import { Select } from 'antd'
import ButtonsSort from './tableRating/ButtonsSort'
import AgentHeader from '../AgentHeader'
import { store, type RootState } from '../../../store/store'
import { useUsers } from '../../../functions/agent/rating/sortedList'
import { useAppSelector } from '../../../hooks/redux'

import DashboardUserTable from './tableRating/DashboardUserTable'
import { type ISettings, type User } from '../../../store/slices/userSlice'
import { REACT_APP_BSO_SUPPORT_USER_ID, REACT_APP_BSO_USER_ID } from '../../../urls'

interface ICountAreas {
    value: string
    countAgents: number
}
export const countAgentsInArea = (users: any[]): ICountAreas[] => {

    const areas: any = store.getState().app.config?.all.Area
        .filter((area: string) => area !== 'All areas')
        .sort((a: string, b: string) => a.localeCompare(b));


    const result: ICountAreas[] = []

    for (const key of areas) {
        const newObj = { value: key, countAgents: 0 }
        result.push(newObj)
    }

    users.forEach((agent: User) => {
        const agentSettings = agent.Settings

        if ((agentSettings != null) && agentSettings.length > 0) {
            const locations = agentSettings[0].Areas ?? []

            locations.forEach((location: string) => {
                const areaMatch = result.find(area => location.includes(area.value))
                if (areaMatch != null) {
                    areaMatch.countAgents += 1
                }
            })
        }
    })

    return result.sort((a, b) => b.countAgents - a.countAgents)
}

export function UserRatingList(): JSX.Element {
    const { currentUser, allUsers } = useAppSelector(
        (state: RootState) => state.user
    )

    const [areas, setAreas] = useState<any[]>([])
    const [filter, setFilter] = useState('TotalScore')

    const onClickSort = (val: string): any => {
        setFilter(val)
    }

    const allUsersWithoutBso = allUsers.filter((user) => (user.id !== Number(REACT_APP_BSO_SUPPORT_USER_ID) && user.id !== Number(REACT_APP_BSO_USER_ID) && user.Category === 'Agent'))
    // console.log(allUsersWithoutBso, 'allUsersWithoutBso')
    const optionsMapped = (countAgentsInArea(allUsersWithoutBso) || []).map((el: ICountAreas) => {
        // const optionsMapped = (data.selectors || []).map((el: string) => {
        return {
            value: el?.value,
            label: (
                <div className='d-flex justify-content-between'>
                    <span>
                        {el.value}
                    </span>
                    {el.countAgents && el.countAgents !== 0
                        ? (<span className='badge border border-primary badge-light-primary badge-sm fs-6 mx-2' aria-label={el.value}>
                            {el.countAgents} Agents
                        </span>)
                        : null}

                </div>

            )
        }
    })

    const searchId = currentUser.id
    const stableUsersWithAreas = useUsers(allUsersWithoutBso, filter, areas)
    const index = stableUsersWithAreas.findIndex((el: any) => el.id === searchId)

    const handleSelectChange = (selectedValues: any) => {
        setAreas(selectedValues)
    }

    return (

        <>
            <div className="page d-flex flex-column flex-row-fluid">
                <AgentHeader active="Agents Leaderboard" />

                <div className="container rounded py-4 px-2 bg-white shadow " style={{ height: '100%' }}>
                    <div className='container ' data-kt-sticky="true">

                        <ButtonsSort onClick={onClickSort} />
                        <div className="row mt-1">
                            <div className="col">
                                <div className='mt-2'>
                                    <Select
                                        popupClassName="drp-style"
                                        listHeight={325}
                                        placeholder={'Choose area to filter agents list'}
                                        className="d-block"
                                        // {...field}
                                        size={'large'}
                                        options={optionsMapped}
                                        onChange={(value) => { handleSelectChange(value) }}
                                        value={areas}
                                        mode={'multiple'}
                                        showSearch={false}
                                    >

                                    </Select>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="card shadow-sm m-4">
                    <div className="card-body p-2 ">

                        {/* <div className="rounded shadow-sm m-2 h-100 overflow-auto" > */}
                        {(stableUsersWithAreas.length > 0)
                            ? stableUsersWithAreas.slice(0, 10).map((user: User, i: number) => {
                                // if (user.Agent !== null) {
                                const settings = user?.Settings as ISettings[]

                                let countOffers = 0
                                let earnings = 0
                                let rating = 0
                                let currentAgent = false
                                if (user.Offers !== null) {
                                    countOffers = user.Offers.length
                                }
                                // if (user?.Type[0]?.Name) {
                                //     agentName = user?.Type[0]?.Name
                                // }

                                if (settings?.[0]) {
                                    if (settings?.[0].Business !== null) {
                                        earnings = Number(settings?.[0].Business)
                                    } if (settings?.[0].Performance !== null) {
                                        rating = Number(settings?.[0].Performance)
                                    }
                                }

                                if (user.id === currentUser.id) {
                                    currentAgent = true
                                }

                                return <DashboardUserTable currentAgent={currentAgent} key={user.id} num={i + 1} srcImg={'https://cdn-icons-png.flaticon.com/512/3048/3048116.png'} user={user} earnings={earnings} performance={rating} offers={countOffers} userID={user.id} />
                                // }
                            })
                            : 'sorry not users'}

                        {index > 9
                            ? (
                                <DashboardUserTable
                                    currentAgent={true}
                                    num={index + 1}
                                    srcImg={'https://cdn-icons-png.flaticon.com/512/3048/3048116.png'}
                                    user={currentUser as User}
                                    earnings={((currentUser.Settings as ISettings[]).at(0)?.Business || 0)}
                                    performance={((currentUser.Settings as ISettings[]).at(0)?.Performance || 0) as number}
                                    offers={currentUser.Offers?.length || 0}
                                    userID={currentUser.id as number}
                                />
                            )
                            : null}
                    </div>
                </div>
                <div className="m-0" style={{ height: '160px' }} />
            </div>
        </>
    )
}
