import React from 'react'

export default function TutorialReserveTimes (): JSX.Element {
    return (
        <div className="my-2 d-flex flex-column">
            <div className="d-flex align-items-center mb-2">
                {/* <div className={'col-3 btn btn-light-danger active ml-1 mt-1'}>
    Time
                </div> */}
                <span className="col-2 badge badge-danger min-h-20px  fs-7 justify-content-center">Time</span>
                <span className="col-10 d-block  ms-2 lh-1 text-dark">
                    {/* text-gray-500 */}
                    Your bookings
                </span>
            </div>

            <div className="d-flex align-items-center mb-2">
                {/* <div className={'col-3 btn btn-light-success active ml-1 mt-1'}>
  Time
                </div> */}
                <span className="col-2 badge badge-success min-h-20px  fs-7 justify-content-center">Time</span>
                <span className="col-9 ms-2 lh-1  text-dark">
                Available time to book
                </span>
            </div>
            <div className="d-flex align-items-center  mb-2">
                {/* <div className={'col-3 btn btn-active-light-dark active ml-1 mt-1'}>
  Time
                </div> */}
                <span className="col-2 badge badge-light-dark min-h-20px  fs-7 justify-content-center">Time</span>
                <span className="col-9 d-block ms-2 lh-1 text-dark">
                Unavailable time to book
                </span>
            </div>

        </div>
    )
}
