
import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { Controller } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { type ISettings } from '../../../store/slices/userSlice'
import { setDebitMarketingCredits, setNotEnoughMarketingCredits } from '../../../store/slices/advertisesSlice'
import { setShowButtonBuyCoinsOnAdvertise } from '../../../store/slices/modalSlice'

import FormListForMyCustomForView from '../../../app/custom/customModalForView/FormListForMyCustomForView'
import MyCarousel from '../../../app/MyCarousel'
import { AgentModals } from '../../../functions/agent/AgentModals'

import { nanoid } from 'nanoid'
import { USER_AREAS_EXTEND_COUNT } from '../../../urls'
import GeoForMyCustomModalForView from '../../../app/custom/customModalForView/unitsActions/GeoForMyCustomModalForView'

export default function AgentBroadcastSelect({
    unitID,
    row,
    control,
    errors,
    setValue,
    id,
    modal,
    viewingID,
    register,
    clearErrors,
    trigger
}: any): JSX.Element {
    const dispatch = useAppDispatch()
    const units = useAppSelector((state: RootState) => state.units.units)

    const currentUnit = units?.filter((el: any) => el.id === Number(unitID))[0]
    const unitArea = currentUnit?.attributes?.Property?.data?.attributes?.Area
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const unitType = currentUnit?.attributes?.Marketing?.Use ?? ''

    const currentUserMarketingCredits = Number((currentUser?.Settings as ISettings[])?.[0]?.MarketingCredits) ?? 0
    const marketingPriceUnit = Number(currentUnit?.attributes?.AdvertisePrice) ?? 1
    //   const [howCoinsLeft, setHowCoinsLeft] = useState(currentUserMarketingCredits)

    const marketingStore = useAppSelector((state: RootState) => state.advertises.debitMarketingCredits)
    const [countDuration, setCountDuration] = useState(0)
    const [howCoinsLeft, setHowCoinsLeft] = useState(0)

    useEffect(() => {
        let theCalculatedChoosingUnitMarketingPrice = 0
        const validateSelect = (value: any) => {
            if (!value) {
                return `${row.content?.[0]} is required`
            }
            theCalculatedChoosingUnitMarketingPrice = value
            // let coofDays: number
            // if (value === 7) {
            //     coofDays = 0.5
            // } else if (value === 14) {
            //     coofDays = 0.4
            // } else {
            //     coofDays = 0.3
            // }

            // const lowerBound = Math.floor(coofDays * value * marketingPriceUnit / marketingPriceUnit) * marketingPriceUnit
            // const upperBound = Math.ceil(coofDays * value * marketingPriceUnit / marketingPriceUnit) * marketingPriceUnit
            // theCalculatedChoosingUnitMarketingPrice = Math.ceil((lowerBound + upperBound) / 2)

            if (theCalculatedChoosingUnitMarketingPrice > currentUserMarketingCredits) {
                return `To broadcast this unit for a period of ${value} days, you need to replenish up to ${theCalculatedChoosingUnitMarketingPrice - currentUserMarketingCredits} bricks.`
            }
        }

        const errorMessage = validateSelect(countDuration)

        if (errorMessage) {
            errors[row.id] = { message: errorMessage }
        } else {
            clearErrors(row.id)
        }

        setHowCoinsLeft(currentUserMarketingCredits - theCalculatedChoosingUnitMarketingPrice)
        if (theCalculatedChoosingUnitMarketingPrice > currentUserMarketingCredits) {
            dispatch(setShowButtonBuyCoinsOnAdvertise(true))
            dispatch(setNotEnoughMarketingCredits(theCalculatedChoosingUnitMarketingPrice - currentUserMarketingCredits))
        } else {
            dispatch(setShowButtonBuyCoinsOnAdvertise(false))
        }
    }, [countDuration, currentUserMarketingCredits, marketingPriceUnit])

    const validateSelect = (value: any) => {
        //  console.log(value, 'value')
        const theCalculatedChoosingUnitMarketingPrice = value
        if (!value) {
            return `${row.content?.[0]} is required`
        }
        // let coofDays: number
        // if (value === 7) {
        //     coofDays = 0.5
        // } else if (value === 14) {
        //     coofDays = 0.4
        // } else {
        //     coofDays = 0.3
        // }

        // const lowerBound = Math.floor(coofDays * value * marketingPriceUnit / marketingPriceUnit) * marketingPriceUnit
        // const upperBound = Math.ceil(coofDays * value * marketingPriceUnit / marketingPriceUnit) * marketingPriceUnit
        // const theCalculatedChoosingUnitMarketingPrice = Math.ceil((lowerBound + upperBound) / 2)

        //   const theCalculatedChoosingUnitMarketingPrice = value * marketingPriceUnit
        if (theCalculatedChoosingUnitMarketingPrice > currentUserMarketingCredits) {
            return `To advertise this unit for a period of ${value} days, you need to replenish up to ${theCalculatedChoosingUnitMarketingPrice - currentUserMarketingCredits} bricks. `
        }
    }

    let optionsMapped: Array<{ value: string, label: React.ReactNode }>
    const COUNT_DAYS = 7
    const arrayDays = Array.from({ length: COUNT_DAYS }, (_, i) => String(i + 1))

    optionsMapped = arrayDays.map((el: string) => {
        return {
            value: el,
            label: (
                <div className='d-flex text-start justify-align-center'>
                    <span className='fw-bold fs-2x mx-4 ' aria-label={el}>
                        {el}
                    </span>
                    <span >
                        day{el !== '1' ? 's' : ''}
                    </span>
                </div>
            )
        }
    })

    const handleSelectChange = (selectedValues: any) => {
        const theCalculatedChoosingUnitMarketingPrice = selectedValues // 0
        //  let coofDays: number
        // if (selectedValues === 7) {
        //     coofDays = 0.5
        // } else if (selectedValues === 14) {
        //     coofDays = 0.4
        // } else {
        //     coofDays = 0.3
        // }

        // const lowerBound = Math.floor(coofDays * selectedValues * marketingPriceUnit / marketingPriceUnit) * marketingPriceUnit
        // const upperBound = Math.ceil(coofDays * selectedValues * marketingPriceUnit / marketingPriceUnit) * marketingPriceUnit
        // theCalculatedChoosingUnitMarketingPrice = Math.ceil((lowerBound + upperBound) / 2)
        // console.log(selectedValues, 'selectedValues')
        setCountDuration(selectedValues)
        dispatch(setDebitMarketingCredits(selectedValues))

        setHowCoinsLeft(currentUserMarketingCredits - selectedValues)
        //  dispatch(setDebitMarketingCredits(theCalculatedChoosingUnitMarketingPrice))
        //  setHowCoinsLeft(currentUserMarketingCredits - theCalculatedChoosingUnitMarketingPrice)

        if (theCalculatedChoosingUnitMarketingPrice > currentUserMarketingCredits) {
            dispatch(setShowButtonBuyCoinsOnAdvertise(true))
            dispatch(setNotEnoughMarketingCredits(theCalculatedChoosingUnitMarketingPrice - currentUserMarketingCredits))
        } else {
            dispatch(setShowButtonBuyCoinsOnAdvertise(false))
        }
    }

    return (
        <div className="">

            <Controller
                key={`${nanoid()}`}
                name={row.id}
                control={control}
                rules={{
                    validate: validateSelect
                }}
                render={({ field }) => {
                    return (
                        <Select
                            onSelect={(value) => { handleSelectChange(value); field.onChange(value) }}
                            popupClassName="drp-style"
                            listHeight={325}
                            placeholder={row.content && row.content[1]}
                            className="d-block my-2"
                            {...field}
                            size={'large'}
                            options={optionsMapped}
                            optionFilterProp="children"
                        >
                            {field.value === '' && (
                                <Select.Option value="">{row.content[1]}</Select.Option>
                            )}
                            <Select.Option value="">{row.content[1]}</Select.Option>
                        </Select>)
                }}
            />

            {errors[row.id] && (
                <>
                    <div className="text-danger my-2">{errors[row.id].message}</div>
                </>
            )}

            {countDuration > 0
                ? (
                    <div className="d-flex flex-column border border-1  border-dashed border-gray-300 text-center pt-5 pb-7 mb-8 card-rounded my-4">

                        <span className="fw-semibold text-gray-600 fs-7 pb-1 text-start ps-4"> Available bricks</span>
                        <span className="fw-bold text-gray-800 fs-2hx lh-1 pb-1 text-start ps-4">
                            <div className="d-flex justify-content-start align-items-center">
                                <i className="ki-duotone ki-credit-cart fs-5x me-1 text-primary">
                                    <i className="path1"></i>
                                    <i className="path2"></i>
                                </i>
                                <span className="fs-2 fw-bold counted">{currentUserMarketingCredits}</span>
                            </div>
                        </span>
                        <div className="separator separator-dotted border-success my-2 mx-4"></div>
                        <span className="fw-semibold text-gray-600 fs-7 pb-1 text-start ps-4"> The duration of the broadcast <span className="fs-2 fw-bold counted text-gray-800">{countDuration} days</span></span>
                        <span className="fw-semibold text-gray-600 fs-7 pb-1 text-start ps-4"> Total cost = <span className="fs-2 fw-bold counted text-gray-800">{marketingStore} Bricks</span></span>

                        <div className="separator separator-dotted border-success my-2 mx-4"></div>
                        <span className="fw-semibold text-gray-600 fs-7 pb-1 text-start ps-4"> Your balance after the purchase</span>
                        <span className="fw-bold text-gray-800 fs-2hx lh-1 pb-1 text-start ps-4">
                            <div className="d-flex justify-content-start align-items-center">
                                <i className="ki-duotone ki-credit-cart fs-5x me-1 text-primary">
                                    <i className="path1"></i>
                                    <i className="path2"></i>
                                </i>
                                <span className="fs-2 fw-bold counted">{howCoinsLeft}</span>
                            </div>
                        </span>
                    </div>
                )

                : null}

            {modal[0].id === 'agent_set_broadcast' && (
                <div className="card d-flex mh-100 ">
                    <div className="fv-row my-2 m-0 p-0 w-100">
                        <div className="separator border border-bso border-3 mx-n7 p-0 my-4"></div>
                    </div>
                    <GeoForMyCustomModalForView googleMapsLink={null} />
                    <div className="fv-row my-2 m-0 p-0 w-100">
                        <div className="separator border border-bso border-3 mx-n7 p-0 my-4"></div>
                    </div>
                    <FormListForMyCustomForView
                        form={
                            AgentModals().find((el: any) => el.id === 'viewing_modal').form
                        }
                        response={currentUnit}
                    />
                    <div className="fv-row my-2 m-0 p-0 w-100" />
                    <MyCarousel dots={true} autoScrol={true} showTwoColumns={false}>
                        {currentUnit?.attributes.Images
                            ? (
                                currentUnit?.attributes.Images?.map((el: string) => (
                                    <div key={`${el}`} className="text-center">
                                        <div
                                            className="w-100 h-250px border border-gray-500 overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center position-relative"
                                            style={{ backgroundImage: `url(${el})` }}
                                        >
                                            <div className="h-200px w-100"></div>
                                        </div>
                                    </div>
                                ))
                            )
                            : (
                                <div>Not images</div>
                            )}
                    </MyCarousel>
                    <div style={{ height: '70px' }}></div>
                </div>
            )}

        </div>
    )
}
