import React, { useEffect } from 'react'
import { getUserDataForMembership } from '../../../../functions/agent/memberships/getUserDataForMembership'
import { type User } from '../../../../store/slices/userSlice'

interface IProps {
    selectedOption: string
    currentMembership: string
    currentUser: User

    typeMembership: string
}

export default function MembershipCardDescription ({ typeMembership, currentUser, selectedOption, currentMembership }: IProps) {
    // console.log(selectedOption, currentMembership, 'selectedOption, currentMembership ')

    const {
        membershipsRequirements,
        userCurrentData
    } = getUserDataForMembership(currentUser, typeMembership)

    return (
        <div className="pt-1 px-2 d-flex flex-column mt-2 ">
            {selectedOption === 'Benefits'
                ? <>
                    <div className="d-flex flex-stack w-100">
                        <div className="d-flex justify-content-between w-100">
                            <span className="fw-semibold fs-5 text-dark text-uppercase">
                        Broadcast
                            </span>
                            <div className={'row-2 ms-2 d-flex align-items-center gap-2 overflow-x-auto scroll'}>
                                <span className="fw-semibold fs-5 text-dark">
                                    { '+' + membershipsRequirements.Broadcast + '/ mounth' }
                                </span>
                            </div>
                        </div>

                    </div>
                    <div className="separator separator-dashed my-2"></div>
                    <div className="d-flex flex-stack w-100">
                        <div className="d-flex justify-content-between w-100">
                            <span className="fw-semibold fs-5 text-dark text-uppercase">
                            Rental
                            </span>
                            <div className={'row-2 ms-2 d-flex align-items-center gap-2 overflow-x-auto scroll'}>
                                <span className="fw-semibold fs-5 text-dark">
                                    { membershipsRequirements.Rental === 'Unlimited' ? 'Unlimited' : '+' + membershipsRequirements.Rental + '/ mounth' }
                                </span>
                            </div>
                        </div>

                    </div>
                    <div className="separator separator-dashed my-2"></div>
                    <div className="d-flex flex-stack w-100">
                        <div className="d-flex justify-content-between w-100">
                            <span className="fw-semibold fs-5 text-dark text-uppercase">
                        Sales
                            </span>
                            <div className={'row-2 ms-2 d-flex align-items-center gap-2 overflow-x-auto scroll'}>
                                <span className="fw-semibold fs-5 text-dark">
                                    {'+' + membershipsRequirements.Sales + '/ mounth'}
                                </span>
                            </div>
                        </div>

                    </div>

                </>
                : <>
                    <div className="d-flex flex-row  w-100">
                        <div className="row-5 min-w-150px"></div>
                        <div className=" row-7    fs-4 fs-bold text-uppercase d-flex justify-content-between w-100">
                            <span className="d-flex w-50  justify-content-end me-2">Required</span>
                            <span className="d-flex w-50 justify-content-end">Current</span>
                        </div>
                    </div>
                    <div className="separator separator-dashed my-2"></div>
                    <div className="d-flex flex-stack w-100">
                        <div className="row-5 min-w-25px">
                            {true
                                ? <i className="ki-duotone ki-check-circle fs-2x text-success me-2">
                                    <span className="path1 "></span>
                                    <span className="path2 text-white"></span>
                                </i>
                                : <i className="ki-duotone ki-cross-circle fs-2x text-danger me-2">
                                    <span className="path1 text-white"></span>
                                    <span className="path2"></span>
                                </i> }
                        </div>
                        <div className="d-flex flex-row justify-content-between w-75">

                            <div className="row-10 position-relative">
                                <span className="fw-semibold fs-5 text-dark text-uppercase">
                        Referals
                                </span>
                            </div>
                            <div className={'row-2 ms-2 d-flex align-items-center  gap-2 overflow-x-auto scroll me-4'}>
                                <span className="fw-semibold fs-5 text-dark">

                                    {selectedOption === 'Benefits' ? userCurrentData.Referals : membershipsRequirements.Referals }

                                </span>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-end w-25 ps-4">

                            <div className={'row-2 ms-2 d-flex align-items-center gap-2 overflow-x-auto scroll'}>
                                <span className="fw-semibold fs-5 text-dark">
                                    {userCurrentData.Referals}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="separator separator-dashed my-2"></div>

                    <div className="d-flex flex-stack w-100">
                        <div className="row-5 min-w-25px">
                            {true
                                ? <i className="ki-duotone ki-check-circle fs-2x text-success me-2">
                                    <span className="path1 "></span>
                                    <span className="path2 text-white"></span>
                                </i>
                                : <i className="ki-duotone ki-cross-circle fs-2x text-danger me-2">
                                    <span className="path1 text-white"></span>
                                    <span className="path2"></span>
                                </i> }
                        </div>
                        <div className="d-flex flex-row justify-content-between w-75">
                            <div className="row-10 position-relative">
                                <span className="fw-semibold fs-5 text-dark text-uppercase">
                        Perfomance
                                </span>
                            </div>
                            <div className={'row-2 ms-2 d-flex align-items-center  gap-2 overflow-x-auto scroll me-4'}>
                                <span className="fw-semibold fs-5 text-dark">
                        &gt;   {selectedOption === 'Benefits' ? userCurrentData.Perfomance : membershipsRequirements.Perfomance }

                                </span>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-end w-25 ps-4">
                            <div className={'row-2 ms-2 d-flex align-items-center gap-2 overflow-x-auto scroll'}>
                                <span className="fw-semibold fs-5 text-dark">
                                    {userCurrentData.Perfomance }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="separator separator-dashed my-2"></div>
                    <div className="d-flex flex-stack w-100">
                        <div className="row-5 min-w-25px">
                            {false
                                ? <i className="ki-duotone ki-check-circle fs-2x text-success me-2">
                                    <span className="path1 "></span>
                                    <span className="path2 text-white"></span>
                                </i>
                                : <i className="ki-duotone ki-cross-circle fs-2x text-danger me-2">
                                    <span className="path1 text-white"></span>
                                    <span className="path2"></span>
                                </i> }
                        </div>
                        <div className="d-flex flex-row justify-content-between w-75">

                            <div className="row-10 position-relative">

                                <span className="fw-semibold fs-5 text-dark text-uppercase">
                        Buisness
                                </span>
                            </div>
                            <div className={'row-2 ms-2 d-flex align-items-center  gap-2 overflow-x-auto scroll me-4'}>
                                <span className="fw-semibold fs-5 text-dark">
                                    {/* &gt; */}
                                    { membershipsRequirements.Buisness === 1000000 ? '>  1M' : membershipsRequirements.Buisness === 0 ? ' --- ' : membershipsRequirements.Buisness }
                                </span>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-end w-25 ps-4">
                            <div className={'row-2 ms-2 d-flex align-items-center gap-2 overflow-x-auto scroll'}>

                                <span className="fw-semibold fs-5 text-dark">
                                    {userCurrentData.Buisness }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="separator separator-dashed my-2"></div>
                    <div className="d-flex flex-stack  w-100">
                        <div className="row-5 min-w-25px">
                            {false
                                ? <i className="ki-duotone ki-check-circle fs-2x text-success me-2">
                                    <span className="path1 "></span>
                                    <span className="path2 text-white"></span>
                                </i>
                                : <i className="ki-duotone ki-cross-circle fs-2x text-danger me-2">
                                    <span className="path1 text-white"></span>
                                    <span className="path2"></span>
                                </i> }
                        </div>
                        <div className="d-flex flex-row justify-content-between  w-75">

                            <div className="row-10 position-relative ">

                                <span className="fw-semibold fs-5 text-dark text-uppercase pb-2">
                                Broker status
                                </span>
                            </div>
                            <div className={'row-2 ms-2 d-flex align-items-center  gap-2 overflow-x-auto scroll me-4'}>
                                <div

                                    className={'badge badge-light-success  border border-success fs-8'}
                                >
                                    {selectedOption === 'Benefits' ? userCurrentData.BrokerStatus : membershipsRequirements.BrokerStatus }

                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-end w-25 ps-4">
                            <div className={'row-2 ms-2 d-flex align-items-center gap-2 overflow-x-auto scroll'}>

                                <div

                                    className={'badge badge-light-success  border border-success fs-8'}
                                >
                                    {selectedOption === 'Benefits' ? userCurrentData.BrokerStatus : membershipsRequirements.BrokerStatus }

                                </div>
                            </div>
                        </div>
                    </div>

                </> }

        </div>
    )
}
{ /* <div className="pt-1 px-2 d-flex flex-column mt-2 ">
<div className="d-flex flex-stack w-100">
    <div className="d-flex flex-row justify-content-between w-50">
        <div className="row-10 position-relative">
            <span className="fw-semibold fs-5 text-dark text-uppercase">
            Referals
            </span>
        </div>
        <div className={'row-2 ms-2 d-flex align-items-center  gap-2 overflow-x-auto scroll'}>
            <span className="fw-semibold fs-5 text-dark">

                {selectedOption === 'Benefits' ? userCurrentData.Referals : '+' + membershipsRequirements.Referals + '/ mounth'}

            </span>
        </div>
    </div>
    <div className="d-flex flex-row justify-content-between w-50 ps-4">
        <div className="row-10 position-relative">
            <span className="fw-semibold fs-5 text-dark text-uppercase">
            Broadcast
            </span>
        </div>
        <div className={'row-2 ms-2 d-flex align-items-center gap-2 overflow-x-auto scroll'}>
            {selectedOption === 'Benefits' ? userCurrentData.Broadcast : membershipsRequirements.Broadcast }
            <i className="ki-duotone ki-check-circle fs-2x text-dark">
                <span className="path1"></span>
                <span className="path2 "></span>
            </i>
        </div>
    </div>
</div>
<div className="separator separator-dashed my-2"></div>

<div className="d-flex flex-stack w-100">
    <div className="d-flex flex-row justify-content-between w-50">
        <div className="row-10 position-relative">
            <span className="fw-semibold fs-5 text-dark text-uppercase">
            Buisness
            </span>
        </div>
        <div className={'row-2 ms-2 d-flex align-items-center  gap-2 overflow-x-auto scroll'}>

            <span className="fw-semibold fs-5 text-dark">
            &gt;{selectedOption === 'Benefits' ? userCurrentData.Buisness : membershipsRequirements.Buisness }
            </span>
        </div>
    </div>
    <div className="d-flex flex-row justify-content-between w-50 ps-4">
        <div className="row-10 position-relative">
            <span className="fw-semibold fs-5 text-dark text-uppercase">
            Rental
            </span>
        </div>
        <div className={'row-2 ms-2 d-flex align-items-center gap-2 overflow-x-auto scroll'}>
            {selectedOption === 'Benefits' ? userCurrentData.Rental : membershipsRequirements.Rental }

        </div>
    </div>
</div>
<div className="separator separator-dashed my-2"></div>

<div className="d-flex flex-stack w-100">
    <div className="d-flex flex-row justify-content-between w-50">
        <div className="row-10 position-relative">
            <span className="fw-semibold fs-5 text-dark text-uppercase">
            Perfomance
            </span>
        </div>
        <div className={'row-2 ms-2 d-flex align-items-center  gap-2 overflow-x-auto scroll'}>
            <span className="fw-semibold fs-5 text-dark">
            &gt;   {selectedOption === 'Benefits' ? userCurrentData.Perfomance : membershipsRequirements.Perfomance }

            </span>
        </div>
    </div>
    <div className="d-flex flex-row justify-content-between w-50 ps-4">
        <div className="row-10 position-relative">
            <span className="fw-semibold fs-5 text-dark text-uppercase">
            Sales
            </span>
        </div>
        <div className={'row-2 ms-2 d-flex align-items-center gap-2 overflow-x-auto scroll'}>
            {selectedOption === 'Benefits' ? userCurrentData.Sales : membershipsRequirements.Sales }

            <i className="ki-duotone ki-check-circle fs-2x text-dark">
                <span className="path1"></span>
                <span className="path2 "></span>
            </i>
        </div>
    </div>
</div>
<div className="separator separator-dashed my-2"></div>
<div className="d-flex flex-stack w-100">

    <span className="fw-semibold fs-5 text-dark text-uppercase">
        App status
    </span>
    <div

        className={'badge badge-light-success  border border-success fs-6'}
    >
        {selectedOption === 'Benefits' ? userCurrentData.BrokerStatus : membershipsRequirements.BrokerStatus }

    </div>

</div>
</div> */ }
