import React from 'react'
import { type IClient } from '../../../store/slices/userSlice'
import { nanoid } from 'nanoid'
import { useNavigate } from 'react-router-dom'

interface ClientCardProps {
    client: IClient
}

const ClientCard = ({ client }: ClientCardProps): JSX.Element => {
    const navigate = useNavigate()
    return (
        <div className={'w-100 bg-white p-4 rounded shadow-sm d-flex flex-column gap-2'}
            onClick={() => { navigate(`/agent/modalView/client_modal/not/${client.id}/not`) }}
        >
            <div className={'fw-bold fs-2 text-gray-900 text-truncate mb-2'}>
                {client.FirstName} {client.LastName}
            </div>
            <div className={'text-gray-400 text-hover-primary d-flex align-items-center gap-1 flex-wrap'}>
                {/* <i className="bi bi-geo-alt fs-4"></i> */}
                {
                    client.Areas != null && client.Areas.length > 0
                        ? client.Areas.map((area) => (
                            <div className={'btn btn-sm btn-light p-0 px-2 me-1'} key={nanoid()}>
                                {area}
                            </div>
                        ))
                        : 'No areas'
                }
            </div>
            <div className={'d-flex'}>
                <div className={'h-100 d-flex flex-column gap-2 w-100'}>
                    <div className={'text-gray-400 text-hover-primary d-flex align-items-center gap-3'}>
                        {/* <i className="bi bi-envelope-at fs-4"></i> */}
                        {
                            client.Email
                        }
                    </div>
                    <div className={'text-gray-400 text-hover-primary d-flex align-items-center gap-3'}>
                        {/* <i className="bi bi-telephone fs-4"></i> */}
                        {
                            client.Phone
                        }
                    </div>
                </div>
                <div className={'d-flex flex-column align-items-end justify-content-end w-100'}>
                    <a href={`tel:${client.Phone}`}>
                        <div className="btn btn-sm btn-bso">
                            {/* <i className="bi bi-telephone fs-4"></i> */}
                            Call
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ClientCard
