import { type AppDispatch, type RootState } from '../../../../../store'
import { addOffer } from '../../../../offerActions'
import _ from 'lodash'
type AddOfferIndividualOfferDetailsData = Record<string, any>
export const addOfferSaleIndividualOfferDetails = (unitID: string | undefined, objectID: string | undefined) => (data: AddOfferIndividualOfferDetailsData) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const savedData = _.cloneDeep(getState().modal.scannedData.formData)
    const offerData = {
        ...data,
        ...savedData,
        Type: {
            ...data?.Type,
            ...savedData?.Type
        }
    }
    return await dispatch(addOffer(offerData, 'sale', Number(unitID), objectID !== '-' ? Number(objectID) : undefined))
}
