import { createSlice } from '@reduxjs/toolkit'
import { USER_ID_KEY } from '../../urls'
import _ from "lodash";

export interface IOfferType {

    id: number | null
    __component: string | null
    Occupants: string | null
    Payments: number | null
    ContractStartDate: string | null
    Duration: number | null
    FirstName: string | null
    LastName: string | null
    EmiratesID: string | null
    Occupation: string | null
    Employer: string | null
    Smoking: boolean | null
    BusinessName?: string | null
    Pets: boolean | null
    PetType: string | null
    InUAESince: number | null
    UAEBank: string | null
    Offer: number | null
    AdditionalNotes: string | null
    Country: string | null
    DepositSlipLink: string | null
    Type: string | null
    Phone: string | null
    Email: string | null
    BankName: string | null
    PreApprovedAmount: string | null
    BusinessOwnerName: string | null
    BusinessActivity: string | null
    EstablishmentDate: string | null
}

interface IUserVar {
    data: {
        id: number
        attributes: {}
    } | null
}

interface IUnitVar {
    data: {
        id: number
        attributes: {
            User: IUserVar | null
        }
    } | null
}

export interface IOffer {
    id: number
    attributes: {
        createdAt: string
        updatedAt: string
        AgentToAgentID: number | null
        AgentToLandlordID: number | null
        Name : string | null
        Notify : null
        Statuses: string[]
        ProcessStartDate: string | null
        ProcessEndDate: string | null
        RejectReason: string | null
        User: {
            data: null | {
                id: number
                attributes: {}
            }
        }
        Unit: IUnitVar
        Type: [IOfferType] | []
        Documents: {
            data: Array<{
                id: number
                attributes: {}
            }>
        }
        FitsSpecialRequest: boolean | null
    }

}

const initialState: { offers: IOffer[], offersLoading: boolean, error: string, offersScrollPosition: number, areThereMoreThanFiveOffers: boolean } = {
    offers: [],
    offersLoading: false,
    error: '',
    offersScrollPosition: 0,
    areThereMoreThanFiveOffers: false
    // documentsProcessing: false
}
const userID = window.localStorage.getItem(USER_ID_KEY)
export const offersSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        offersFetching (state) {
            state.offersLoading = true
        },
        offersFetchingSuccess (state, action) {
            const userID = window.localStorage.getItem(USER_ID_KEY)
            state.error = ''
            const updatedOffers = action.payload.filter((offer: IOffer) => Boolean(offer.attributes.User.data?.id))
            state.areThereMoreThanFiveOffers = action.payload.filter((offer: IOffer) => offer?.attributes?.User?.data?.id === Number(userID))?.length > 5
            state.offers = updatedOffers
            state.offersLoading = false
        },
        allOffersFetchingSuccess (state, action) {
            const userID = window.localStorage.getItem(USER_ID_KEY)
            state.areThereMoreThanFiveOffers = action.payload.filter((offer: IOffer) => offer?.attributes?.User?.data?.id === Number(userID))?.length > 5
            state.offers = action.payload
            state.offersLoading = false
        },
        offersFetchingError (state, action) {
            state.offersLoading = false
            state.error = action.payload.message
        },
        setOffersScrollPosition (state, action) {
            state.offersScrollPosition = action.payload
        },
        setOffers (state, action) {
            const userID = window.localStorage.getItem(USER_ID_KEY)
            const copyOffers = [...state.offers]
            const updatedOffers = copyOffers.map((offer: any) => {
                if (offer.id === action.payload.id) {
                    return {
                        ...action.payload
                    }
                } else {
                    return offer
                }
            })

            state.areThereMoreThanFiveOffers = action.payload.filter((offer: IOffer) => offer?.attributes?.User?.data?.id === Number(userID))?.length > 5
            state.offers = updatedOffers
            state.offersLoading = false
        },

        addOffer: (state, action) => {
            state.error = ''
            const findDublicate = state.offers.find((off) => off.id === action.payload.id)
            if (findDublicate === undefined) {
                const actualOffers = [...state.offers, action.payload]
                state.offers = actualOffers
                state.areThereMoreThanFiveOffers = actualOffers.filter((offer: IOffer | null) => offer?.attributes?.User?.data?.id === Number(userID))?.length > 5
            }
            state.offersLoading = false
        },
        updateOffer: (state, action) => {
            state.error = ''
            const offersCopy = _.cloneDeep(state.offers)
            const findDublicateIndex = offersCopy.findIndex((off) => off.id === action.payload.id)
            if (findDublicateIndex !== -1) {
                offersCopy[findDublicateIndex] = action.payload
                state.offers = offersCopy
            }
            state.offersLoading = false
        }
        /* setDocumentsProcessing (state, action) {
            state.documentsProcessing = action.payload
        } */
    }
})

export default offersSlice.reducer
