import _ from 'lodash'
import { AgentModals } from '../../../../functions/agent/AgentModals'
import { type AppDispatch, type RootState, store } from '../../../store'
import { type Document } from '../../../slices/documentSlice'
import { modalSlice, setScannedData } from '../../../slices/modalSlice'


import Swal from 'sweetalert2'
import { swalOptions } from '../../../../functions/swalOptions'
import { checkDocForProbabilityAgentName } from '../../../../functions/agent/documents/checkDocForProbabilityAgentName'
import { scanDocuments } from '../../documentsActions/scaning/scanDocuments'
import { scanEmiratesID } from '../../documentsActions/scanEmiratesID'

export const fillSetClientContactInfoModal = (modalID: string, offerID: number) => (dispatch: AppDispatch, getState: () => RootState): { modal: Record<string, unknown> } => {
    const modal = _.cloneDeep(
        AgentModals().find((modal: any) => modal.id === modalID)
    )
    const offer = getState().offers.offers.find(offerItem => offerItem.id === offerID)
    const documents = getState().documents.documents
    const offerDocs = offer?.attributes?.Documents?.data
        ?.map(doc => documents.find(document => document.id === doc.id))
        ?.filter(Boolean) as Document[]
    const passportAdded = offerDocs.some((doc) => doc?.attributes?.Type?.[0]?.__component === 'documents.passport')
    const emiratesIDAdded = offerDocs.some((doc) => doc?.attributes?.Type?.[0]?.__component === 'documents.emirates-id')
    const onScan = (documentType: string) => async (data: any): Promise<boolean> => {
        // console.log(data)
        // const fileList = [uploadedFile]
        dispatch(modalSlice.actions.dataFetchingStart())
        let document: Record<string, any> | null
        if (documentType === 'Emirates ID') {
            document = await dispatch(scanEmiratesID(data['emirates-id-front'], data['emirates-id-back']))
        } else {
            const response = await dispatch(scanDocuments([data.passport], 'Passport', true, `offer-${new Date().getTime()}-passport`, 'offers'))
            document = response[0]?.response?.document
        }
        // if (fileList != null && fileList?.length > 0) {
        /// const response = await dispatch(scanDocuments(fileList, documentType, true))
        if (document?.valid === true) {
            const probability = checkDocForProbabilityAgentName(document.name, {
                FirstName: offer?.attributes?.Type?.[0]?.FirstName ?? '',
                FamilyName: offer?.attributes.Type?.[0]?.LastName ?? ''
            })
            if (probability < 75) {
                await Swal.fire({
                    ...swalOptions('error'),
                    title: 'The documents does not belong to the client'
                })
                document.valid = false
            }
        } else {
            await Swal.fire({
                ...swalOptions('error'),
                title: 'Scanned document is not valid'
            })
        }
        dispatch(modalSlice.actions.dataFetchingFinish())
        return document?.valid === true
    }
    // if (!emiratesIDAdded) {
    //     dispatch(setScannedData({
    //         ...getState().modal.scannedData,
    //         documentType: 'Emirates ID'
    //     }))
    //     modal.form[0].content[0] = 'Scan client\'s emirates id'
    //     modal.form[0].onScan = onScan
    // }
    if (!passportAdded) {
        dispatch(setScannedData({
            ...getState().modal.scannedData,
            documentType: 'Passport'
        }))
        modal.form[0].content[0] = 'Scan client\'s passport'
        modal.form[0].onScan = onScan
    } else {
        modal.form = (modal.form as any[]).filter((row, index) => index !== 0)
    }
    return { modal }
}
