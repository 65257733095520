import React, { useEffect, useState } from 'react'
import { type RootState } from '../../../../store/store'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import AdvertiseListItem from './AdvertiseListItem'
import { advertisesSlice, type IAdvertise } from '../../../../store/slices/advertisesSlice'
import { nanoid } from 'nanoid'
import { getStatusesAdvertisements } from '../../../../functions/agent/schedule/getStatusesAdvertisements'
import useSaveScroll from '../../../../hooks/useSaveScroll'
import { List } from 'antd'
import {
    aprImage,
    cancelledImage, closedImage,
    completedImage, docsReceivedImage, linksAddedImage, linksApprovedImage, linksRejectedImage,
    othrImage,
    placeholders,
    prImage,
    rejImage,
    startedImage,
    USER_ID_KEY
} from '../../../../urls'
interface IProps {
    advertises: IAdvertise[] | []
}

export default function AdvertiseList({ advertises }: IProps): JSX.Element {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const dispatch = useAppDispatch()
    const advertisesScrollPosition = useAppSelector((state: RootState) => state.advertises.advertisesScrollPosition)
    const units = useAppSelector((state: RootState) => state.units.units)
    const queueAds = units.reduce((acc: number[], unit) => {
        unit.attributes.WaitingList?.forEach((reserve) => {
            if (reserve.userID === Number(userID)) {
                acc.push(reserve.adId);
            }
        });
        return acc;
    }, []);


    const statuses = getStatusesAdvertisements(advertises, queueAds) as string[] | []






    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: Number(advertisesScrollPosition), behavior: 'smooth' })
        }, 100)
    }, [])

    useEffect(() => {
        // console.log('useEffect scroll ADVERTISES')
        const scrollWrapper = document.querySelector('#root')

        const handleScroll = (event: Event): void => {
            const target = event?.target as HTMLElement
            if (target.scrollTop > 0) {
                dispatch(advertisesSlice.actions.setAdvertisesScrollPosition(target.scrollTop))
            }
        }
        scrollWrapper?.addEventListener('scroll', handleScroll, { passive: true })

        return () => {
            scrollWrapper?.removeEventListener('scroll', handleScroll)
        }
    }, [])
    const statusImages: any = {
        Approved: aprImage,
        Rejected: rejImage,
        Canceled: cancelledImage,
        Completed: completedImage,
        'In-process': prImage,
        'Advertisement started': startedImage,
        'Docs received': docsReceivedImage,
        'Advertisement closed': closedImage,
        'Links added': linksAddedImage,
        'Links rejected': linksRejectedImage,
        'Links approved': linksApprovedImage,
        "Other": othrImage
    }
    return (
        <div>

            {(advertises && advertises.length > 0 || queueAds.length > 0)
                ? <List
                    dataSource={statuses}
                    renderItem={(item: any, index: number) => {
                        return (
                            <>
                                {item.advertisementsLength > 0
                                    ? <div key={nanoid()} className={'position-relative'}>
                                        <img
                                            className=" w-100 h-100px object-fit-cover mb-1 rounded opacity-100"
                                            src={statusImages[item?.status] || statusImages['In-process']}
                                            alt={item?.status}
                                        />
                                        <span className="d-block fs-2 min-w-30px max-w-30px text-center position-absolute rounded-0 rounded-bottom rounded-end-0 translate-middle badge badge-secondary opacity-75" style={{ top: '12px', right: '-15px' }}>
                                            {item.advertisementsLength}
                                        </span>
                                        <span className="ps-4 fs-5x fw-semi-bold text-white position-absolute lh-1 mt-4" style={{ top: '6px', left: '0px', textShadow: '1px 1px 10px #332d2dd9' }}>
                                            {item?.status}
                                        </span>

                                    </div>
                                    : null}
                                <AdvertiseListItem key={item.id} {...item} />

                            </>
                        )
                    }}
                />
                : <div className={'d-flex position-relative align-items-end'} style={{ height: '55vh' }}>
                    <img
                        className="w-75 object-fit-cover m-auto rounded-0 rounded-bottom opacity-100"
                        src={placeholders.Advertisements}
                        alt={'liable_picture'}
                    />
                    <span className="fs-2x fw-bold text-white text-uppercase position-absolute text-center" style={{ bottom: '16%', left: '50%', transform: 'translateX(-50%)', textShadow: '1px 1px 10px #332d2dd9' }}>
                        You don’t have any active advertisements yet
                    </span>
                </div>

            }
        </div>
    )
}
