import React, { useEffect, useRef } from 'react'

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import _ from 'lodash'
import { nanoid } from 'nanoid'
// import { setPage, setPageCount, setStopPagination } from '../../../../store/slices/paginationSlice'
import { type RootState } from '../../../../store/store'
import { fetchUsers } from '../../../../store/actionsCreators/userActions'
import {accountsSetScrollPosition, type User
} from '../../../../store/slices/userSlice'
// import useFireSearch from '../../../../hooks/useFireSearch'
import { Spin } from 'antd'
import useSaveScroll from '../../../../hooks/useSaveScroll'
import getAgentStatuses from '../../../../functions/agent/documents/checkDocumentsForAproved'
import searchFuse from '../../../../functions/search/searchFuse'
import checkDocumentsForOtherUser from '../../../../functions/agent/documents/checkDocumentsForOtherUser'
import { hideMails } from '../../../../constants'
import filterUserCallback from '../../../../functions/shared/users/filterUserCallback'

import ProfileCardNew from './ProfileCardNew'
import ProfileCard from './profile-card'

const SearchProfiles = (): JSX.Element => {
    const { currentUser, allUsers: profiles, accountsScrollPosition, searchString } = useAppSelector((state) => state.user)
    const scrollRef = useRef<HTMLDivElement>(null)
    const { paginationLoading } = useAppSelector((state: RootState) => state.pagination)
    // const { page, pageCount } = useAppSelector((state: RootState) => state.user)
    const dispatch = useAppDispatch()
    /* useInfinteScroll({
        scrollRef,
        page,
        pageCount,
        setPage: accountsSetPage,
        action: () => fetchSearchedUsers(page + 1),
        direction: 'bottom'
    }) */
    useSaveScroll({
        scrollRef,
        position: accountsScrollPosition,
        setPosition: (position: number) => dispatch(accountsSetScrollPosition(position))
    })

    const checkSearch = (user: User): boolean => {
        const searchStringTrim = searchString.toLowerCase().trim()
        if (searchStringTrim === '') {
            return true
        }
        const searchStringSplit = searchStringTrim.split(' ')
        if (searchStringSplit.length === 1) {
            return Boolean(user.ContactInfo?.FirstName?.toLowerCase()?.includes(searchStringSplit[0])) || Boolean(user.ContactInfo?.FamilyName?.toLowerCase()?.includes(searchStringSplit[0]))
        } else {
            return (
                (
                    user.ContactInfo?.FirstName?.toLowerCase() === searchStringSplit[0] && Boolean(user.ContactInfo?.FamilyName?.toLowerCase()?.includes(searchStringSplit[1]))
                ) || (
                    user.ContactInfo?.FamilyName?.toLowerCase() === searchStringSplit[0] && Boolean(user.ContactInfo?.FirstName?.toLowerCase()?.includes(searchStringSplit[1]))
                )
            )
        }
    }

    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const filterUser = (user: User): boolean => {
        return filterUserCallback(user) && checkSearch(user)
    }
    useEffect(() => {
        dispatch(fetchUsers(true))
    }, [])
    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    return (
        <div className={`gap-4 p-4 ${isDev ? "" : "d-flex flex-column overflow-auto"} `}
            style={{ height: '100%' }}
            ref={scrollRef}
        >
            {profiles.length > 0
                ? _(profiles)
                    .filter(filterUser)
                    .sort((a, b) => {
                        const dateA = new Date(a?.createdAt).getTime()
                        const dateB = new Date(b?.createdAt).getTime()
                        return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
                    })
                    .sort((a, b) => {
                        const areaA = a?.Settings?.[0]?.Areas?.join('')
                        const areaB = b?.Settings?.[0]?.Areas?.join('')
                        if (areaA === areaB || areaA == null || areaB == null) {
                            return 0
                        }
                        return areaA > areaB ? 1 : -1
                    })
                    .map((user, index) => isDev ? <ProfileCardNew user={user} key={user.id} /> : <ProfileCard user={user} key={`profile-${index}`} />
                    )
                    .value()
                : !paginationLoading
                    ? <div className="card shadow-sm my-1">
                        <div className="card-body p-0 m-5 text-center">
                            <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 ">
                                No profiles for you
                            </span>
                        </div>
                    </div>
                    : <></>

            }<div className={"h-50px"} ></div>
            {paginationLoading
                ? <div className={'w-100 d-flex justify-content-center p-4'}>
                    <Spin size={'large'} />
                </div>
                : <></>
            }
        </div>
    )
}

export default SearchProfiles
