export function getCountries (): string[] {
    const countries = [
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia and Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Central African Republic',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Republic of the Congo',
        'Democratic Republic of the Congo',
        'Costa Rica',
        'Cote d\'Ivoire',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'East Timor',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Fiji',
        'Finland',
        'France',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        'North Korea',
        'South Korea',
        'Kosovo',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Federated States of Micronesia',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Morocco',
        'Mozambique',
        'Myanmar',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Qatar',
        'Romania',
        'Russia',
        'Rwanda',
        'Saint Kitts and Nevis',
        'Saint Lucia',
        'Saint Vincent and the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome and Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States of America',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Vatican City (Holy See)',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Zambia',
        'Zimbabwe'
    ]
    return countries
}

export function getAreas (): string[] {
    const areas = [
        'Academic City',
        'Al Awir',
        'Al Badaa',
        'Al Barari',
        'Al Barsha',
        'Al Furjan',
        'Al Garhoud',
        'Al Hudaiba',
        'Al Jaddaf',
        'Al Jafiliya',
        'Al Karama',
        'Al Khawaneej',
        'Al Lisaili',
        'Al Manara',
        'Al Mamzar',
        'Al Mina',
        'Al Mizhar',
        'Al Nahda (Dubai)',
        'Al Quoz',
        'Al Qusais',
        'Al Rashidiya',
        'Al Safa',
        'Al Satwa',
        'Al Sufouh',
        'Al Twar',
        'Al Warqaa',
        'Al Warsan',
        'Al Wasl',
        'All areas',
        'Arabian Ranches',
        'Arabian Ranches 2',
        'Arabian Ranches 3',
        'Arjan',
        'Barsha Heights (Tecom)',
        'Bluewaters Island',
        'Bukadra',
        'Bur Dubai',
        'Business Bay',
        'City of Arabia',
        'Culture Village',
        'DAMAC Hills',
        'DAMAC Hills 2 (Akoya by DAMAC)',
        'Damac Lagoons',
        'Daria Island',
        'Deira',
        'Discovery Gardens',
        'DIFC',
        'Dragon City',
        'Dubai Beach Residence (JBR)',
        'Dubai Festival City',
        'Dubai Harbour',
        'Dubai Hills Estate',
        'Dubai Industrial Park',
        'Dubai Internet City',
        'Dubai Investment Park (DIP)',
        'Dubai International Airport',
        'Dubai Marina',
        'Dubai Maritime City',
        'Dubai Media City',
        'Dubai Outsource Zone',
        'Dubai Pearl',
        'Dubai Production City (IMPZ)',
        'Dubai Residence Complex',
        'Dubai Science Park',
        'Dubai Silicon Oasis',
        'Dubai South',
        'Dubai Sports City',
        'Dubai Studio City',
        'Dubai Waterfront',
        'Dubai World Central',
        'Dubailand',
        'Emirates Golf Club',
        'Emirates Hills',
        'Global Village',
        'Golf City',
        'Green Community',
        'Ibn Battuta Gate',
        'International City',
        'Jebel Ali',
        'Jumeirah',
        'Jumeirah Beach Residence (JBR)',
        'Jumeirah Golf Estates',
        'Jumeirah Heights',
        'Jumeirah Islands',
        'Jumeirah Island 2',
        'Jumeirah Lake Towers (JLT)',
        'Jumeirah Park',
        'Jumeirah Village Circle (JVC)',
        'Jumeirah Village Triangle (JVT)',
        'Knowledge Village',
        'Lehbab',
        'Liwan',
        'Liwan 2',
        'Margham',
        'Meydan City',
        'Mirdif',
        'Mohammed Bin Rashid City',
        'Motor City',
        'Muhaisnah',
        'Mudon',
        'Nad Al Hamar',
        'Nad Al Sheba',
        'Oud Al Muteena',
        'Pacific Village',
        'Palm Jebel Ali',
        'Palm Jumeirah',
        'Pearl Jumeirah',
        'Ras Al Khor',
        'Reem',
        'Remraam',
        'Serena',
        'Sheikh Zayed Road',
        'Technology Park',
        'The Gardens',
        'The Greens',
        'The Hills',
        'The Lagoons',
        'The Lakes',
        'The Meadows',
        'The Springs',
        'The Springs C',
        'The Sustainable City',
        'The Valley',
        'The Villa',
        'Tilal Al Ghaf',
        "Za'abeel",
        'Wadi Almardi',
        'Wadi Al Shabak',
        'Wasl Gate',
        'World Trade Centre',
        'The World Islands',
        'Umm Al Sheif',
        'Umm Ramool',
        'Umm Suqeim'
    ];
    
    return areas
}

export function getFeatures (): string[] {
    const features = [
        'Maids room',
        'Study',
        'Central A/C & Heating',
        'Balcony',
        'Private Garden',
        'Private Pool',
        'Private Gym',
        'Private Jacuzzi',
        'Shared Pool',
        'Shared Spa',
        'Shared Gym',
        'Security',
        'Concierge Service',
        'Maid Service',
        'Covered Parking',
        'Built-in Wardrobes',
        'Walk-in Closet',
        'Built-in Kitchen Appliances',
        'View of Water',
        'View of Landmark',
        'Pets Allowed',
        'Double Glazed Windows',
        'Day Care Center',
        'Electricity Backup',
        'First Aid Medical Center',
        'Service Elevators',
        'Prayer Room',
        'Laundry Room',
        'Broadband Internet',
        'Satellite/Cable TV',
        'Business Center',
        'Intercom',
        'ATM Facility',
        'Kids Play Area',
        'Reception/Waiting Room',
        'Maintenance Staff',
        'CCTV Security',
        'Cafeteria or Canteen',
        'Shared Kitchen',
        'Facilities for Disabled',
        'Storage Areas',
        'Cleaning Services',
        'Barbeque Area',
        'Lobby in Building',
        'Waste Disposal'
    ]
    return features
}

export function getBanks (): string[] {
    const banks = [
        'Abu Dhabi Commercial Bank',
        'Abu Dhabi Islamic Bank',
        'Arab Bank',
        'Zba Bank Dubai',
        'Bank Sedarat Iran',
        'MCB Bank Ltd',
        'Emirates Investment Bank',
        'Bank of Sharjah',
        'Citibank N.A.',
        'Commercial Bank International',
        'Commercial Bank of Dubai',
        'Dubai Islamic Bank',
        'Dubai Finance Bank',
        'Emirates Islamic',
        'First Abu Dhabi Bank',
        'Habib Bank AG Zurich',
        'Habib Bank Limited',
        'HSBC Bank Middle East Limited',
        'Invest Bank',
        'Mashreq',
        'Emirates NBD',
        'National Bank of Fujairah',
        'National Bank of Umm Al-Qaiwain',
        'Noor Bank',
        'National Bank of Oman',
        'National Bank of Ras Al-Khaimah',
        'Sharjah Islamic Bank',
        'Standard Chartered Bank Limited',
        'United Bank Limited',
        'United Arab Bank',
        'Ajman Bank'
    ]
    return banks
}

export function getPropertyType (): string[] {
    const types = ['Residential', 'Commercial'] //, 'Retail', 'Work'
    return types
}

export function padTo2Digits (num: any): string {
    return num.toString().padStart(2, '0')
}

export function getDates (): string[] {
    const dates = []
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth()
    const date = today.getDate()
    for (let i = 0; i < 30; i++) {
        const day = new Date(year, month, date + i)
        const format = [day.getFullYear(), padTo2Digits(day.getMonth() + 1), padTo2Digits(day.getDate())].join('-')
        dates.push(format)
    }
    return dates
}
