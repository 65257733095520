import { type IPropertyModel } from '../../../store/slices/propertySlice'

export default function mapPropertiesForSelect (properties: IPropertyModel[]): Array<{ label: string, value: number }> {
    return properties
        .filter((property, index) => {
            // console.log(property.id, property.attributes?.Units?.data, index, properties.length, `${property.attributes.Name} ${property.attributes.Area ?? ''}`, property.attributes?.Units?.data?.length > 0 || index === properties.length - 1)
            return property.attributes?.Units?.data?.length > 0 || index === properties.length - 1
        })
        .sort((propertyA, propertyB) => {
            if (propertyA.attributes.Name === propertyB.attributes.Name) return 0
            else if (propertyA.attributes.Name > propertyB.attributes.Name) return 1
            else return -1
        })
        .map((property) => ({
            label: `${property.attributes.Name} ${property.attributes.Area ?? ''}`, value: property.id
        }))
}
