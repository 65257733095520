import { type User } from '../../../../store/slices/userSlice'
import { ava_blank } from '../../../../urls'

interface MyComponentProps {
    user: User
    srcImg: string
}

export default function CardUserTable({ srcImg, user }: MyComponentProps): any {
    // console.log(user, 'user')
    const userCONTACTS = (user)?.Type?.[0]
    const userContactsINFO = (user)?.ContactInfo
    const imageUrl = userCONTACTS?.Photo ?? ava_blank

    return (
        <div className="d-flex align-items-center ">
            <div className="symbol symbol-40px me-3">
                <img
                    src={imageUrl}
                    className=""
                    alt=""
                />
            </div>
            <div className="d-flex justify-content-start flex-column ">
                {/* <a href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6 mw-125px text-dots-app " >
                    {name}
                </a> */}

                <span className="text-dark fw-semibold mb-1 d-block fs-6 mw-85px text-dots-app">{userContactsINFO?.FirstName ? userContactsINFO?.FirstName : userCONTACTS?.Name ? userCONTACTS?.Name.split(' ')?.[0] : 'No name'}</span>
                <span className="text-dark fw-semibold d-block fs-7 mw-85px text-dots-app">{userContactsINFO?.FamilyName ? userContactsINFO?.FamilyName : userCONTACTS?.Name ? userCONTACTS?.Name.split(' ')?.[1] : 'No name'}</span>
            </div>
        </div>
    )
}
