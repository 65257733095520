import React from 'react'
import { isShure } from '../../../functions/swals/isShure'
import { Link } from 'react-router-dom'

interface IProps {
    row: any, modal: any, navigate: any
}
function LinkToLogin({ row, modal, navigate }: IProps): JSX.Element {
    return (

        <div className="text-center py-4">
            <div className=" fw-semi-bold">
                {row.content?.[0]}
                <span className="d-inline-block position-relative ms-1">
                    {row.id === 'first_scan_way' && modal[0].id === 'write_yourself_fields'
                        ? <div className="text-decoration-none" onClick={() => { isShure(navigate, row.content?.[2]) }}>
                            <div className="d-inline-block link-dark fw-bold">
                                {row.content?.[1]}
                            </div>
                            <span className="d-inline-block position-absolute h-2px bottom-0 end-0 start-0 bg-bso translate rounded"></span>
                        </div>

                        : <Link to={row.content?.[2]} className="text-decoration-none">
                            <div
                                className="d-inline-block link-dark fw-bold"
                            >
                                {row.content?.[1]}
                            </div>
                            <span className="d-inline-block position-absolute h-2px bottom-0 end-0 start-0 bg-bso translate rounded"></span>
                        </Link>}

                </span>
            </div>
        </div>

    )
}

export default LinkToLogin
