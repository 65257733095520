import React, { useState } from 'react'
import { Modal } from 'antd'

import CloseIconBasic from '../elements/CloseIconBasic'
import { useLocation, useNavigate } from 'react-router-dom'
import { BSO_DEVELOP_MODE_ON_FRONT } from '../../../urls'

export default function BSOAbout({ onCancel }: any): JSX.Element {
    const [modalOpen, setModalOpen] = useState(false)
    const [password, setPassword] = useState('')
    const onSend = () => {
        if (password === process.env.REACT_APP_BSO_ADMIN_PASS) {
            window.localStorage.setItem(BSO_DEVELOP_MODE_ON_FRONT, 'true')
        }
        setPassword('')
        setModalOpen(false)
    }

    const isDevFront = window.localStorage.getItem(BSO_DEVELOP_MODE_ON_FRONT)
    const isLoginPath = useLocation()?.pathname.includes('/auth/login')
    const navigate = useNavigate()

    return (
        <>

            <div className="container position-relative p-lg-20 h-100vh p-md-15 p-sm-10 p-5" style={{ minHeight: '100vh' }}>
                <div
                    className="header fixed-top bg-white h-auto shadow-sm fw-bold mw-450px mx-auto"
                    style={{ paddingTop: '39px' }}
                >
                    {isDevFront === 'true' ? <span className="badge badge-danger position-fixed" style={{ zIndex: '9999999', left: 2, top: 52 }}>DEV MODE FRONT</span> : null}
                    <div className="d-flex flex-row flex-fill justify-content-center m-4 ">
                        <img
                            alt="Logo"
                            src="/assets/media/logo.png"
                            className="h-60px mx-auto"
                        />
                    </div>
                    <CloseIconBasic onCancel={onCancel} />
                </div>

                <div className="h-95px my-4 m-0"></div>
                <img
                    alt="Logo"
                    src="/assets/media/placeholders/About BSO Club Compressed.png"
                    className='my-4 w-100 object-fit-cover'
                />

                <div className="d-flex flex-column flex-row-fluid flex-column-fluid flex-center">
                </div>

                <div className="text-center py-2 my-2">
                    <div className=" fw-semi-bold">
                        {isLoginPath ? ' New here?' : 'Already have an account?'}

                        <span className="d-inline-block position-relative ms-1">
                            {<div
                                onClick={
                                    () => {
                                        onCancel()
                                        setPassword('')
                                        navigate(isLoginPath ? '/auth/register1' : '/auth/login')
                                    }

                                }
                                className="text-decoration-none">
                                <div
                                    className="d-inline-block link-dark fw-bold"
                                >
                                    {isLoginPath ? 'Create an account' : ' Log in'}
                                </div>
                                <span className="d-inline-block position-absolute h-2px bottom-0 end-0 start-0 bg-bso translate rounded"></span>
                            </div>
                            }

                        </span>
                    </div>
                </div>

                <i className="bi bi-pc-display-horizontal fs-7 me-1 opacity-0" onClick={() => { setModalOpen(true) }}></i>
            </div>

            <Modal
                open={modalOpen}
                style={{ height: '100vh', left: 0, top: '250px' }}
                width="90vw"

                zIndex={1504}
                footer={null}
                onCancel={() => {
                    setModalOpen(false)
                    setPassword('')
                }}
                centered

                styles={{
                    body: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '35vh'

                    }
                }}
                closable={false}

            >
                <div className="fv-row mb-2 m-0 p-0 w-90 mx-auto fv-plugins-icon-container">

                    <label className={
                        'required form-label  fw-bold text-dark text-uppercase'
                    }>
                        Password

                    </label>
                    <input
                        className="form-control form-control-solid form-control-lg"
                        type="password"
                        placeholder={'Password'}
                        name={'password_admin'}
                        value={password}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(event.target.value)
                        }}
                    />
                    <button onClick={onSend} className={'btn btb-sm btn-bso w-100 my-2'}>Send</button>
                </div>
            </Modal></>
    )
}
