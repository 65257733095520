import React from 'react'
import { useSearchParams } from 'react-router-dom'

export interface TabButtonProps {
    filter?: string
    value?: string
    label?: string | JSX.Element
    isDefault?: boolean
    additionalParam?: string
    stateControlled?: boolean
    stateControlledActive?: boolean
    stateControlledSetActive?: () => void
}

const TabButton = ({ filter = '', value = '', label, isDefault = false, additionalParam = undefined, stateControlled = false, stateControlledActive = false, stateControlledSetActive = () => {} }: TabButtonProps): JSX.Element => {
    const [searchParams, setSearchParams] = useSearchParams()
    return (
        <span
            onClick={() => {
                if (stateControlled) {
                    stateControlledSetActive()
                } else {
                    setSearchParams(`${filter}=${value}${additionalParam !== undefined ? `&${additionalParam}` : ''}`)
                }
            }}
            className="col d-inline-block position-relative text-center cursor-pointer"
        >
            <div className="d-inline-block link-dark fw-bold my-4">
                {label}
            </div>
            <span
                className={`d-inline-block position-absolute h-4px bottom-0 end-0 start-0 translate rounded-top ${
                    searchParams.get(filter) === value || (isDefault && searchParams.get(filter) === null) || stateControlledActive ? 'bg-dark' : 'bg-light'
                }`}
            />
        </span>
    )
}

export default TabButton
