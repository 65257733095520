import React from 'react'
import _ from 'lodash'
import { nanoid } from 'nanoid'
import { Tooltip } from 'antd'
interface ProfileMetricsProps {
    metrics: IProfileMetric[]
}

export interface IProfileMetric {
    label: string
    value: string
    dynamic?: 'up' | 'down'
    icon?: JSX.Element
    color?: string
    textPromt?: string
}

const ProfileMetrics = ({ metrics }: ProfileMetricsProps): JSX.Element => {
    return (
        <div className={'d-flex flex-column gap-4 mb-4'}>
            {
                _.map(
                    _.chunk(metrics, 2),
                    (pair) => (
                        <div className={'d-flex flex-row gap-4'} key={nanoid()}>
                            {
                                _.map(pair, (metric) => (
                                    <Tooltip title={metric.textPromt}color={metric.color} key={nanoid()}>

                                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 w-100"
                                            style={{ maxWidth: 'calc(50% - 0.5rem)' }}
                                            key={nanoid()}
                                        >
                                            <div className="d-flex align-items-center">
                                                {/* <i className={`ki-duotone ${metric.dynamic === 'up' ? 'ki-arrow-up text-success' : 'ki-arrow-down text-danger'} fs-3 me-2`}>
                                                <i className="path1"></i>
                                                <i className="path2"></i>
                                            </i> */}
                                                <div className="fs-3 me-2">
                                                    {metric.icon}
                                                </div>
                                                <div className="fs-2 fw-bold counted">
                                                    {metric.value}
                                                </div>
                                            </div>
                                            <div className="fw-semibold fs-6 text-gray-400">
                                                {metric.label}
                                            </div>
                                        </div>
                                    </Tooltip>
                                ))
                            }
                        </div>
                    )
                )
            }
        </div>
    )
}

export default ProfileMetrics
