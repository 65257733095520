import React, { useEffect } from 'react'
import AgentFooter from '../AgentFooter'
import AgentHeader from '../AgentHeader'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { getMetricsForPerfomanceOffers, getMetricsForPerfomanceViewings } from '../profile/me/getMetric'
import { type User } from '../../../store/slices/userSlice'
import ProfileMetrics from '../profile/me/AgentProfileMetrics'
import AgentChart from '../dashboard/agentCharts/AgentChart'
import AgentMultipleChart from '../dashboard/agentCharts/AgentMultipleChart'

export default function AgentPerformance (): JSX.Element {
    useEffect(() => {
        document.getElementById('header_agent_performance')?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'

        })
    }, [])

    const currentUser = useAppSelector(
        (state: RootState) => state.user.currentUser
    )
    const viewings = useAppSelector((state: RootState) => state.viewings.viewings)
    const offers = useAppSelector((state: RootState) => state.offers.offers)

    const viewingMetrics = getMetricsForPerfomanceViewings(currentUser as User, viewings)
    const offerMetrics = getMetricsForPerfomanceOffers(currentUser as User, offers)

    return (
        <>
            <div
                className="page d-flex flex-column flex-row-fluid fs-5"
                id='header_agent_performance'
            >
                <AgentHeader active="My Performance" />
                <div
                    className="content d-flex flex-column flex-fill p-0 m-4"
                >
                    <div className="w-100 mw-450px mx-auto">
                        <div className="card shadow-sm mb-4">
                            <div className="card-body p-4">
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <div className={'text-gray-900 fs-4 fw-bold '}>Viewings detailed statistics</div>
                                </div>
                                <ProfileMetrics metrics={viewingMetrics}/>
                            </div>
                        </div>
                        <div className="card shadow-sm mb-4">
                            <div className="card-body p-4">
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <div className={'text-gray-900 fs-4 fw-bold '}>Offers detailed statistics</div>
                                </div>
                                <ProfileMetrics metrics={offerMetrics}/>
                            </div>
                        </div>
                        <div className="card shadow-sm mb-4">
                            <div className="card-body p-4">
                                <AgentMultipleChart />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="m-0" style={{ height: '160px' }} />
            </div>
            <AgentFooter />

        </>

    )
}
