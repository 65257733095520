import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
    type IChat,
    type IChatMessage,
    type IChatUnread,
    type IFile,
    type IRecorder,
    type ITimer,
    type IUnit,
    type IUser
} from '../../types'
import { Root } from 'react-dom/client'
import { USER_ID_KEY } from '../../urls'

export interface ChatState {
    chat: IChat
    Messages: IChatMessage[]
    searchedMessages: IChatMessage[]
    searchedInvites: IChatMessage[]
    searchString: string
    invitesSearchString: string
    sender: IUser
    countMessage: number
    currentMessage: string
    currentFile: IFile | null
    currentUnit: number | null
    pendingMessages: IChatMessage[]
    file: any
    attach: string
    recorder: IRecorder
    timer: ITimer
    unit: IUnit
    page: number
    pageCount: number
    invitePage: number
    invitePageCount: number
    searchMessagesScrollPosition: number
    searchInvitesScrollPosition: number
    userBroadcastMessages: IChatMessage[] | []
}

const initialState: ChatState = {
    chat: {},
    Messages: [],
    searchedMessages: [],
    searchedInvites: [],
    searchString: '',
    invitesSearchString: '',
    sender: {},
    countMessage: 10,
    currentMessage: '',
    currentFile: null,
    currentUnit: null,
    pendingMessages: [],
    file: null,
    attach: 'message',
    recorder: {
        initRecording: false,
        mediaStream: null,
        mediaRecorder: null,
        audio: null,
        seconds: 0
    },
    timer: {
        recordingMinutes: 0,
        recordingSeconds: 0
    },
    unit: {},
    page: 1,
    pageCount: 0,
    invitePage: 1,
    invitePageCount: 0,
    searchMessagesScrollPosition: 0,
    searchInvitesScrollPosition: 0,
    userBroadcastMessages: []
}
const userID = window.localStorage.getItem(USER_ID_KEY)
export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setChat: (state: ChatState, action: PayloadAction<IChat>) => {
            state.chat = action.payload
        },
        setMessages: (state: ChatState, action: PayloadAction<IChatMessage[]>) => {
            state.Messages = action.payload
        


        
        },
        setSearchMessages: (state: ChatState, action: PayloadAction<IChatMessage[]>) => {
            state.searchedMessages = action.payload
        },
        setSearchInvites: (state: ChatState, action: PayloadAction<IChatMessage[]>) => {
            state.searchedInvites = action.payload
        },
        setChatSearchString: (state: ChatState, action: PayloadAction<string>) => {
            state.searchString = action.payload
        },
        setInvitesSearchString: (state: ChatState, action: PayloadAction<string>) => {
            state.invitesSearchString = action.payload
        },
        setUnread: (state: ChatState, action: PayloadAction<IChatUnread>) => {
            state.chat.Unread = action.payload
        },
        setSender: (state: ChatState, action: PayloadAction<IChat>) => {
            state.sender = action.payload
        },
        setCountMessage: (state: ChatState, action: PayloadAction<number>) => {
            state.countMessage = action.payload
        },
        setCurrentFile: (state: ChatState, action: PayloadAction<IFile | null>) => {
            state.currentFile = action.payload
        },
        setCurrentUnit: (state: ChatState, action: PayloadAction<number | null>) => {
            state.currentUnit = action.payload
        },
        setCurrentMessage: (state: ChatState, action: PayloadAction<string>) => {
            state.currentMessage = action.payload
        },
        setPendingMessages: (state: ChatState, action: PayloadAction<IChatMessage[]>) => {
            state.pendingMessages = action.payload
        },
        setFile: (state: ChatState, action: PayloadAction<any>) => {
            state.file = action.payload
        },
        setAttach: (state: ChatState, action: PayloadAction<string>) => {
            state.attach = action.payload
        },
        setRecorder: (state: ChatState, action: PayloadAction<IRecorder>) => {
            state.recorder = action.payload
        },
        setTimer: (state: ChatState, action: PayloadAction<ITimer>) => {
            state.timer = action.payload
        },
        setUnitChat: (state: ChatState, action: PayloadAction<IUnit>) => {
            state.unit = action.payload
        },
        chatSetPage: (state: ChatState, action: PayloadAction<number>) => {
            state.page = action.payload
        },
        chatSetPageCount: (state: ChatState, action: PayloadAction<number>) => {
            state.pageCount = action.payload
        },
        chatSetInvitePage: (state: ChatState, action: PayloadAction<number>) => {
            state.invitePage = action.payload
        },
        chatSetInvitePageCount: (state: ChatState, action: PayloadAction<number>) => {
            state.invitePageCount = action.payload
        },
        setSearchMessagesScrollPosition: (state: ChatState, action: PayloadAction<number>) => {
            state.searchMessagesScrollPosition = action.payload
        },
        setSearchInvitesScrollPosition: (state: ChatState, action: PayloadAction<number>) => {
            state.searchInvitesScrollPosition = action.payload
        },
        setUserBroadcastMessages: (state: ChatState, action: PayloadAction<any>) => {
            state.userBroadcastMessages = action.payload
        }

    }
})

export const {
    setChat,
    setMessages,
    setSearchMessages,
    setSearchInvites,
    setUnread,
    setCurrentFile,
    setCurrentUnit,
    setPendingMessages,
    setSender,
    setCountMessage,
    setCurrentMessage,
    setFile,
    setAttach,
    setRecorder,
    setTimer,
    setChatSearchString,
    setInvitesSearchString,
    setUnitChat,
    chatSetPage,
    chatSetPageCount,
    chatSetInvitePage,
    chatSetInvitePageCount,
    setSearchMessagesScrollPosition,
    setSearchInvitesScrollPosition,
    setUserBroadcastMessages
} = chatSlice.actions

export default chatSlice.reducer
