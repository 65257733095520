import React from 'react'
import ClientCard from './ClientCard'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import _ from 'lodash'
import { nanoid } from 'nanoid'
import { type IClient, type User } from '../../../store/slices/userSlice'
import ClientsHeader from './ClientsHeader'
import searchFuse from '../../../functions/search/searchFuse'
import AgentHeader from '../AgentHeader'
const MyClients = (): JSX.Element => {
    const clients: IClient[] = useAppSelector((state: RootState) => (state.user.currentUser as User)?.Clients)
    const clientsSearchString = useAppSelector((state: RootState) => state.user.clientsSearchString)
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const searchStringSplit = clientsSearchString.trim().split(' ')
    let filteredClients: IClient[]
    if (clientsSearchString === '') {
        filteredClients = clients
    } else if (searchStringSplit.length === 1) {
        filteredClients = _.map(
            searchFuse(clients, clientsSearchString.trim(), 0.1, ['FirstName', 'LastName', 'Areas']),
            (client) => client.item
        )
    } else {
        filteredClients = _.uniqBy(
            _.map([
                ...searchFuse(clients, searchStringSplit[0], 0.1, ['FirstName', 'LastName', 'Areas']),
                ...searchFuse(clients, searchStringSplit[1], 0.1, ['FirstName', 'LastName', 'Areas'])
            ], (client) => client.item), (clientItem) => clientItem.id)
    }
    filteredClients = _.sortBy(filteredClients, (client) => client.FirstName)
    return (
        <>
            <div className="page d-flex flex-column flex-row-fluid" style={{ height: '100vh' }}>
                <AgentHeader
                    active="My Clients"
                />
                <ClientsHeader/>
                <div className="content d-flex flex-column gap-4 flex-fill p-0 m-4 overflow-auto">
                    {
                        _.map(filteredClients, (client) => (
                            <ClientCard
                                key={nanoid()}
                                client={client}
                            />
                        ))
                    }
                </div>
                {userDevice === 'IPhone'
                    ? <div className="m-0" style={{ height: '12.5rem' }}></div>
                    : <div className="m-0" style={{ height: '2.5rem' }}></div>
                }
                <div className="fixed-bottom mw-450px mx-auto px-4 py-2   bg-white" style={{ bottom: userDevice === 'IPhone' ? '9.65rem' : '8.2rem', left: '0px', zIndex: 1 }}>
                    <Link to={'/agent/modal/agent_add_client_modal/-/-'}>
                        <div className="btn btn-bso mb-1 fs-6 p-1 py-4 w-100 fw-bold">
                            Add a client
                        </div >
                    </Link>
                </div>
                <div style={{ height: '17.5rem' }}/>
            </div>
        </>
    )
}

export default MyClients
