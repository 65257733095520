import _ from 'lodash'
import { store } from '../../../store/store'
import { removeDataAttributes } from '../../adapters/universal/removeDataAttributes'

const fillDepositSlipOfferModal = (modal: any, objectID: string, initialState: any, dispatch = (callback: any = () => {}) => callback()): { modal: any, initialState: Record<string, any> } => {
    const state: Record<string, any> = {}
    const rawOffer = _.find(store.getState().offers.offers, (offerItem) => offerItem.id === +(objectID ?? '-1'))
    const offer = removeDataAttributes(_.cloneDeep(rawOffer ?? {}))
    const calculateDeposit = (offer: any): number => {
        const unit = store.getState().units.units.find((unit) => unit.id === offer?.Unit?.id)
        const unitIsFurnished = unit?.attributes?.Fit === 'Furnished'
        const offerPrice = offer?.Type?.[0]?.Offer
        const minimumPrice = 3000
        return Math.floor(Math.max(
            unitIsFurnished ? offerPrice * 0.1 : offerPrice * 0.05,
            minimumPrice
        ))
    }
    const confirmationDocument = _.cloneDeep(store.getState().documents.documents)
        .filter(doc => (doc.attributes as any)?.Offer?.data?.id === Number(objectID) && ['Deposit Cheque', 'Offer Confirmation'].includes((doc.attributes as any)?.Category))
        .sort((a, b) => b?.id - a?.id)?.[0]

    for (const row of modal[0].form) {
        switch (row.id) {
            case 'description':
                row.content[0] = row.content[0].replace('{price}', calculateDeposit(offer).toFixed(2))
                break
            case 'payment_type':
                if ((confirmationDocument?.attributes as any)?.Category === 'Deposit Cheque') {
                    state.payment_type = 'Bank cheque'
                } else {
                    state.payment_type = 'Bank transfer'
                }
                row.onChange = (value: string) => {
                    const fileField = document.querySelector('#file')
                    const fileLabel = fileField?.querySelector('label')
                    if (fileLabel == null) {
                        return
                    }
                    if (value === 'Bank cheque') {
                        fileLabel.innerHTML = 'Bank cheque for booking deposit verification'
                    } else {
                        fileLabel.innerHTML = 'Deposit slip confirmation'
                    }
                }
                break
            case 'file':
                if ((confirmationDocument?.attributes as any)?.Category === 'Deposit Cheque') {
                    row.content[0] = 'Bank cheque for booking deposit verification'
                } else {
                    row.content[0] = 'Deposit slip confirmation'
                }
                break
        }
    }

    return {
        modal,
        initialState: { ...initialState, ...state }
    }
}

export default fillDepositSlipOfferModal
