
import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY, URL_MAIN_API_APP} from '../../../urls'
import { setNotEnoughMarketingCredits } from '../../slices/advertisesSlice'
import { dataFetchingFinish, dataFetchingStart, setIsAreasForBuyBricks, setShowButtonBuyCoinsOnAdvertise, setShowPopupAddOnsBricks } from '../../slices/modalSlice'

import { type AppDispatch, type RootState } from '../../store'

interface IOptions {
    isTestMode: boolean
    selectedPeriod: string
    areas?: string[] | undefined
}


export const createSubscriptionAgent = (chooseSubscription: string, unitID?: number, options?: IOptions) => async (dispatch: AppDispatch, getState: () => RootState) => {
    console.log("CALL createSubscriptionAgent")
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const currentUser = getState().user.currentUser

    const notEnoughMarketingCredits = getState().advertises.notEnoughMarketingCredits || Number(chooseSubscription.split('_')[2])
    const isAreasForBuyBricks = getState().modal.isAreasForBuyBricks
    const unitIDnotEnoughAreaForAdvertise = getState().advertises.unitIDnotEnoughAreaForAdvertise




    let strapiProductID = 0
    const collection = isAreasForBuyBricks ? 'Areas' : unitID ? 'Unit' : undefined
    const collectionID = unitIDnotEnoughAreaForAdvertise ?? unitID ?? undefined
    const testing = options?.isTestMode

    let quantity
    let areasExtendCount: undefined | number

    switch (chooseSubscription) {
        case 'Standard':
            strapiProductID = options?.selectedPeriod === 'weekly' ? 7 : options?.selectedPeriod === 'annual' ? 3 : 1
            break
        case 'Premium':
            strapiProductID = options?.selectedPeriod === 'weekly' ? 8 : options?.selectedPeriod === 'annual' ? 4 : 2
            break

        case 'pack_areas_1':
            strapiProductID = 5
            quantity = 1
            areasExtendCount = 1
            break
        case 'pack_areas_3':
            strapiProductID = 5
            quantity = 3
            areasExtendCount = 3
            break
        case 'pack_areas_5':
            strapiProductID = 5
            quantity = 5
            areasExtendCount = 5
            break
        case 'pack_areas_10':
            strapiProductID = 5
            quantity = 10
            areasExtendCount = 10
            break

        case `pack_coins_${notEnoughMarketingCredits}`:
            strapiProductID = 6
            quantity = notEnoughMarketingCredits
            break

        case 'pack_coins_100':
            strapiProductID = 6
            quantity = 100
            break

        case 'pack_coins_200':
            strapiProductID = 6
            quantity = 200
            break
        default:
            break
    }

    try {
        dispatch(dataFetchingStart())
        const sendObject = { email: currentUser.email, id: Number(strapiProductID), collection, collectionID, quantity, testing , areas : options?.areas}

        const response = await fetch(`${URL_MAIN_API_APP}/payment/createInvoice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(sendObject)
        })
        const dataJSON = await response.json()
        if (getState().advertises.notEnoughMarketingCredits > 0) {
            dispatch(setNotEnoughMarketingCredits(0))
        }
        console.log(dataJSON,"dataJSON")
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(dataFetchingFinish())
                // if (areasExtendCount && areasExtendCount > 0 && Boolean(unitID)) {
                //     window.localStorage.setItem(USER_AREAS_EXTEND_COUNT, `${unitID} - ${areasExtendCount}`)
                //     if (areasExtendCount === 1) {
                //         dispatch(setIsExtend(true))
                //     }
                // }
                dispatch(setShowButtonBuyCoinsOnAdvertise(false))
                dispatch(setShowPopupAddOnsBricks(false))
                dispatch(setNotEnoughMarketingCredits(0))
                dispatch(setIsAreasForBuyBricks(false))
                return { ...dataJSON.response, textNavigate: '', isSuccessful: true, textNotification: 'Please make payment' }
            }
        } else {
            dispatch(dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(dataFetchingFinish())
    }
}
