import React, { useState } from 'react'
import CustomFormLable from '../customModalUtils/CustomFormLable'

import _ from 'lodash'
import { Space, Switch } from 'antd'
import { Controller } from 'react-hook-form'

interface IProps {
    register: any
    errors: any
    row: any
    control: any
}
export default function CustomSwitch({ register, errors, row, control }: IProps): JSX.Element {
    const [switchChecked, setSwitchChecked] = useState(Boolean(row.defaultValue))

    return (
        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">

            <div className="fv-row my-2 m-0 p-0 w-100">
                <div className="d-flex flex-wrap align-items-center">
                    <CustomFormLable row={row} />
                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Controller
                                name={row.id}
                                control={control}
                                defaultValue={switchChecked}
                                render={({ field: { value, onChange } }) => (
                                    <Switch
                                        unCheckedChildren={row.selectors[1]}
                                        checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>{row.selectors[0]}</span>}
                                        checked={value}
                                        onChange={(checked) => {
                                            setSwitchChecked(checked)
                                            onChange(checked)
                                        }}
                                    />
                                )}
                            />
                        </Space>
                    </div>
                </div>
            </div>
            <div className="separator separator-dashed my-4"></div>

            {
                _.get(errors, row.id) != null && (
                    <div className="text-danger">{_.get(errors, row.id).message}</div>
                )
            }

        </div>
    )
}
// <div className="input-group">
//     <input
//         {...register(row.id, row.required === true
//             ? {
//                 required: `Field "${row.content?.[0]}" is required`,
//                 validate: (value: string) => {
//                     let result: boolean = true
//                     if (row.regexp != null) {
//                         result = new RegExp(row.regexp).test(value)
//                     }
//                     if (row.moreThan != null) {
//                         result = result && Number(value) > Number(row.moreThan)
//                     }
//                     if (row.dateBeforeToday === true) {
//                         if (value?.split != null) {
//                             const splitDate = value.split('/')
//                             let date: Date
//                             if (splitDate?.length > 1) {
//                                 date = new Date(+splitDate[0] < 30 ? +splitDate[0] + 2000 : +splitDate[0], +splitDate[1] - 1, +splitDate[2])
//                             } else {
//                                 date = new Date(splitDate[0])
//                             }
//                             result = result && date.getTime() < new Date().getTime()
//                         }
//                     }
//                     if (row.dateAfterToday === true) {
//                         if (value?.split != null) {
//                             const splitDate = value.split('/')
//                             let date: Date
//                             if (splitDate?.length > 1) {
//                                 date = new Date(+splitDate[0] < 30 ? +splitDate[0] + 2000 : +splitDate[0], +splitDate[1] - 1, +splitDate[2])
//                             } else {
//                                 date = new Date(splitDate[0])
//                             }
//                             result = result && date.getTime() >= new Date().getTime()
//                         }
//                     }
//                     return result
//                 }
//             }
//             : {})}
//         name={row.id}
//         className="form-control form-control-solid form-control-lg"
//         type="text"
//         placeholder={row.content?.[2]}
//         defaultValue={row.default || row?.defaultValue}
//         onInput={row.onInput != null ? row.onInput : () => { }}
//     />
//     <span className="input-group-text border-0">{row.content?.[1]}</span>
// </div>
