
import React, { type ReactElement, useEffect, useState } from 'react'
import { type IViewing } from '../../../../store/slices/viewingsSlice'
import { type IUnitKey, type Unit } from '../../../../store/slices/unitsSlice'
import { SchedulerStatus } from '../../../../functions/agent/date/SchedulerStatus'
import { type TimelineItem } from '../ViewingTimeline'
import { Steps } from 'antd'

import Icon from '../../../icon/Icon'
import { getBelongsUnitForViewing } from '../../../../functions/shared/schedule/getBelongsUnit'
import { ReasonBlock } from './timelineKeysInBSO'
interface IProps {
    viewing: IViewing
    unit: Unit
    unitKey: IUnitKey
    userType: string
    isMyUnit: boolean
}

function TimelineAgentToAgent({ viewing, unit, unitKey, userType, isMyUnit }: IProps): JSX.Element {
    let process = 0
    const unitStatuses = unit.attributes.Statuses
    const viewingStatuses = viewing?.attributes?.Statuses

    const texts = {
        stepApprovedOrRejectedAgentToAgent: isMyUnit ? 'Your unit\'s viewing has been approved by you' : 'Your viewing time has been approved',
        descriptionApprovedOrRejectedAgentToAgent: '',
        stepInProcessAgentToAgent: isMyUnit ? 'Approve viewing on your unit' : 'Wait for viewing approval',
        descriptionInProcessAgentToAgent: isMyUnit ? '' : 'Agent will approve your viewing soon',
        stepCompleted: 'Viewing completed successfully',
        descriptionCompleted: 'Congratulations on another completed viewing! Let’s make a new one with the BSO Club!'
    }

    if (unitStatuses.includes('Door close') && viewingStatuses != null) {
        if (viewingStatuses?.includes('Rescheduled by other agent') && !viewingStatuses?.includes('Rescheduled by agent') && !viewingStatuses?.includes('In-process')) {
            process = 0
        }
        if (viewingStatuses?.includes('In-process') || viewingStatuses?.includes('Rescheduled by agent')) {
            process = 0
        } else if (viewingStatuses?.includes('Canceled')) {
            process = 0
            texts.stepInProcessAgentToAgent = isMyUnit ? 'Your unit\'s viewing has been cancelled' : 'Viewing has been cancelled'
            texts.descriptionInProcessAgentToAgent = isMyUnit ? "" : ''
        } else if (viewingStatuses?.includes('Approved')) {
            process = 1
        } else if (viewingStatuses?.includes('Rejected')) {
            process = 1
            texts.stepApprovedOrRejectedAgentToAgent = isMyUnit ? 'Your viewing was rejected this viewing' : 'Your viewing was rejected by representative agent'
            texts.descriptionApprovedOrRejectedAgentToAgent = isMyUnit ? '' : 'Unfortunately, representative agent has decided to reject your viewing because of the valuable reason stated above. Please reschedule the time of this viewing or create a new one'
        } else if (viewingStatuses?.includes('Completed')) {
            process = 2
        }
    }

    const [line, setLine] = useState('middle')

    const iconProcess = (step: number, process: number): ReactElement<any, any> | undefined => {
        if (process + 1 > step || process === 4) {
            return undefined
        }
        return (<div className="ant-steps-item-icon">
            <span className="ant-steps-icon" style={{ color: 'black' }}>
                {step}
            </span>
        </div>
        )
    }

    const task = getBelongsUnitForViewing(viewing)

    const groupAgentToAgent: TimelineItem[] = [
        {
            title: (
                <div>
                    <div className='lh-sm mb-1'>{texts.stepInProcessAgentToAgent}</div>
                    <div className={'d-flex align-items-center'}>

                        {process === 0 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}

                        <div className="mb-1">{process === 0 ? SchedulerStatus(viewingStatuses?.includes('Rescheduled by other agent') ? (task.type === 'MY UNIT' ? ['Rescheduled by youself'] : ['Rescheduled by agent']) : viewingStatuses?.includes('Rescheduled by agent') ? (task.type === 'MY UNIT' ? ['Rescheduled by agent'] : ['Rescheduled by youself']) : viewing?.attributes?.Statuses ?? [], 'fs-6') : undefined}</div>
                    </div>
                    {process === 0 && viewingStatuses != null && (viewingStatuses?.includes('Rescheduled by BSO') || viewingStatuses?.includes('Canceled')) && viewing?.attributes?.RejectReason &&
                        <ReasonBlock statuses={viewingStatuses} reason={viewing?.attributes?.RejectReason} />
                    }

                </div>
            ),
            description: texts.descriptionInProcessAgentToAgent,
            status: process === 0 && viewingStatuses?.includes('Canceled') ? 'finish' : undefined
        } satisfies TimelineItem,

        ...(viewingStatuses?.includes('Canceled')

            ? []
            : [

                {
                    title: (
                        <div>
                            <div className='lh-sm mb-1'> {texts.stepApprovedOrRejectedAgentToAgent} </div>
                            <div className={'d-flex align-items-center'}>
                                {process === 1 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                <div className="subTitle">{process === 1 ? SchedulerStatus(viewing?.attributes?.Statuses ?? [], 'fs-6') : undefined}</div>
                            </div>

                            {process === 1 && viewingStatuses?.includes('Rejected') && viewing?.attributes?.RejectReason &&
                                <ReasonBlock statuses={viewingStatuses} reason={viewing?.attributes?.RejectReason} />

                            }
                        </div>
                    ),
                    description: texts.descriptionApprovedOrRejectedAgentToAgent
                },
                ...(!viewingStatuses?.includes('Rejected')
                    ? [
                        {
                            title: (
                                <div >
                                    <div className='lh-sm mb-1'> {texts.stepCompleted} </div>
                                    <div className={'d-flex align-items-center'}>
                                        {process === 2 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                        <div className="subTitle d-block">{process === 2 ? SchedulerStatus(viewing?.attributes?.Statuses ?? [], 'fs-6 my-0') : undefined}</div>
                                    </div>
                                </div>
                            ),
                            description: texts.descriptionCompleted,
                            status: process === 2 ? 'finish' : undefined
                        } satisfies TimelineItem]
                    : [])
            ]

        )

    ]

    const [sliceSteps, setSliceSteps] = useState([groupAgentToAgent[process]])

    useEffect(() => {
        groupAgentToAgent.map((el, i) => el.icon = iconProcess(i + 1, process))
    }, [sliceSteps, process])
    return (
        <div className='my-8 pt-8'>

            {<Steps
                current={line === 'next' ? 0 : line === 'middle' ? 0 : process}
                percent={60}
                items={sliceSteps}
            />}

            <div className={'d-flex mt-2 pt-2'} style={{ justifyContent: process === 0 || process === 2 ? 'flex-end' : 'space-evenly' }}>
                <div className="d-flex justify-content-between align-items-center mx-4">
                    {process !== 0 && <div className={'text-end'}>
                        <div
                            className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                            onClick={line === 'prew'
                                ? () => { setSliceSteps([groupAgentToAgent[process]]); setLine('middle') }
                                : () => {
                                    setSliceSteps([...groupAgentToAgent].slice(0, process + 1))
                                    setLine('prew')
                                }
                            }
                        >
                            <div className="d-flex justify-content-around align-items-center">
                                <Icon name={line === 'prew' ? 'play_circle' : 'expand_circle_up'} className={'fs-5'} />
                                <span className="ms-1 fs-8">{line === 'prew' ? 'Current step' : 'Previous steps'}</span>
                            </div>
                        </div>
                    </div>}
                    <div className="d-flex justify-content-between align-items-center mx-2">
                        {process !== 2 && !viewing?.attributes?.Statuses?.includes('Canceled') && <div className={'text-end'}>
                            <div
                                className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                                onClick={
                                    line === 'next'
                                        ? () => { setSliceSteps([groupAgentToAgent[process]]); setLine('middle') }
                                        : () => { setSliceSteps([...groupAgentToAgent].slice(process)); setLine('next') }}
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    <Icon name={line === 'next' ? 'play_circle' : 'expand_circle_down'} className={'fs-5'} />
                                    <span className="ms-1 fs-8">   {line === 'next' ? 'Current step' : 'Next steps'}</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

        </div>

    )
}

export default TimelineAgentToAgent
