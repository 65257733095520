import { useMemo } from 'react'
import { compareEarnings, compareOffers, compareRating } from './compareData'
import { type IUser } from '../../../types'
import { type ISettings } from '../../../store/slices/userSlice'

export const useSortedUsers = (users: IUser[], sort: string): IUser[] => {
    const sortedUsers = useMemo(() => {
        if (sort === 'TotalScore' || sort === '') {
            return [...users].sort(compareRating)
        }
        if (sort === 'Business') {
            return [...users].sort(compareEarnings)
        }
        if (sort === 'Offers') {
            return [...users].sort(compareOffers)
        }
        return users
    }, [sort, users])
    return sortedUsers
}

export const useUsers = (users: IUser[], sort: string, areas: string | string[]): any => {
    const sortedUsers = useSortedUsers(users, sort)
    const sortedUsersWithAreas = useMemo(() => {
        if (Array.isArray(areas) && areas.length !== 0) {
            return sortedUsers.filter((user) => {
                const settings = user.Settings as ISettings[]
                return settings?.at(0)?.Areas?.some((el: any) => areas.includes(el))
            })
        }
        return sortedUsers
    }, [areas, sortedUsers])
    return sortedUsersWithAreas
}
