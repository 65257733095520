import _ from 'lodash'

const fillEditPropertyModal = (properties: any[], propertyID: number): { modal: any, initialState: Record<string, any> } => {
    const currentProperty = _.find(properties, (property: any) => property.id === propertyID)

    return {
        modal: {},
        initialState: currentProperty?.attributes ?? {}
    }
}

export default fillEditPropertyModal
