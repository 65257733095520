import React, { useEffect } from 'react'
import AgentHeader from '../../agentModule/AgentHeader'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import _ from 'lodash'
import ReferralRow from './Referral'
import Swal from 'sweetalert2'
import { getReferrals } from '../../../store/actionsCreators/userActions'
const myReferrals = (): JSX.Element => {
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    console.log(currentUser, 'currentUser')
    const referralLink = `${window.location.protocol}//${window.location.host}/auth/register1?referral-id=${String(currentUser.id)}`
    const dispatch = useAppDispatch()
    const referrals = useAppSelector((state: RootState) => state.user.currentUser.Referrals)
    console.log(referrals, 'referrals')
    // useEffect(() => {
    //     void dispatch(getReferrals())
    // }, [])

    return (
        <div className="page d-flex flex-column flex-row-fluid">
            <AgentHeader active={'My referrals'} />
            <div className="card shadow-sm m-4 mb-0">
                <div className="card-body p-2 d-flex align-items-center">
                    <div className={'text-truncate px-2 fs-4'}>{referralLink}</div>
                    <div className={'btn btn-bso text-nowrap'}
                        onClick={() => {
                            void navigator?.clipboard?.writeText(referralLink)
                                .then(() => {
                                    void Swal.fire('Referral link is successfully copied', `<strong>${referralLink}</strong>`, 'success')
                                })
                        }}
                    >
                        Copy referral link
                    </div>
                </div>
            </div>
            {referrals && referrals.length > 0
                ? <div className="card shadow-sm m-4">
                    <div className="card-body p-2 ">
                        {
                            _.map(referrals, (referral, index) =>
                                <ReferralRow user={referral} />
                            )
                        }
                    </div>
                </div>
                : <div className="card shadow-sm m-4">
                    <div className="card-body p-2 text-center">
                        <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 ">
                            You have no confirmed users participating in the referral program
                        </span>
                    </div>
                </div>

            }
        </div>
    )
}

export default myReferrals
