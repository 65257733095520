import { days } from '../../../constants'

export const timeStringInScheduler = (dateString: string): string => {
    const mounthStandart = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ]

    const date = new Date(dateString)
    const dateNum = date.getDate()
    const day = days[date.getDay()]
    const mounth = mounthStandart[date.getMonth()]
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const year = date.getFullYear()

    return `${dateNum + ' ' + mounth} ${day} ${
        hours + ':' + (minutes > 10 ? minutes : '0' + minutes
        )}`
}
