import { getErrorss } from '../../../functions/errors/GetErrors'
import { type ResponseObject, type ApiResponse, type IVerifyPhoneFormValues, type IVerifyPhoneJSON } from '../../../types/userTypes/TAuth'
import { urlAPI, urlAUTH, ACCESS_KEY, USER_ID_KEY, USER_EMAIL_KEY } from '../../../urls'
import { authSlice } from '../../slices/authSlice'

import { modalSlice } from '../../slices/modalSlice'
import { type User } from '../../slices/userSlice'
import { type AppDispatch, type RootState } from '../../store'
import sendMail from '../../../functions/shared/email/sendMail'
import { getQueryCurrentUser } from '../../../functions/shared/api/queries/getQueryCurrentUser'

// change number
export const changeNumberVerifyEmail = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(modalSlice.actions.dataFetchingStart())
        //  console.log(formValues, 'formValues')
        const sendObject = {
            email: formValues.email, renew: true // renew: false
        }
        // console.log(sendObject, 'sendObject')
        // checkMailOTP
        const response = await fetch(`${urlAUTH}/sendMailOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const dataJSON: IVerifyPhoneJSON = await response.json()
        //  console.log(dataJSON, 'dataJSON verifyResetPasswordEmail')
        if (dataJSON.response?.sent) {
            dispatch(authSlice.actions.emailSuccess(formValues?.email))
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/modal/change_phone_for_contacts_my_check_otp/not/not`, isSuccessful: true, textNotification: 'A code was sent to your email address, please enter this code to confirm the phone number change' }
        }

        dispatch(modalSlice.actions.dataFetchingFinish())

        return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
    } catch (error) {
        console.log(error)
    }
}

export const changeNumberVerifyPhoneConfirm = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const userEMAIL = window.localStorage.getItem(USER_EMAIL_KEY)
    try {
        dispatch(modalSlice.actions.dataFetchingStart())
        const email = getState().auth.userEMAIL ?? userEMAIL
        const sendObject = {
            ...formValues, renew: true, email
        }
        //  console.log(sendObject, 'sendObject')

        // sendMailOTP
        const response = await fetch(`${urlAUTH}/checkMailOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const dataJSON = await response.json() as ResponseObject
        //  console.log(dataJSON, ' dataJSON      confirmResetPasswordEmail')
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                if (dataJSON.response.approved) {
                    dispatch(modalSlice.actions.dataFetchingFinish())
                    return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/modal/change_phone_for_contacts_my/not/not`, isSuccessful: true, textNotification: '' }
                } else {
                    dispatch(modalSlice.actions.dataFetchingFinish())
                    return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/modal/change_phone_for_contacts_my_check_otp/not/not`, isSuccessful: false, textNotification: "The code doesn't match" }
                }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())

            return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/modal/change_phone_for_contacts_my_check_otp/not/not`, isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
    }
}

// change_phone_for_contacts_my_confirm /agent/settings

export const changeNumberChangePhoneConfirm = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    //  console.log('ZAAAWELL <<<<<<<<<<<<<<<<< !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! >>>>>>>>>>>>>')
    try {
        dispatch(modalSlice.actions.dataFetchingStart())
        const jwt = window.localStorage.getItem(ACCESS_KEY)
        const userID = window.localStorage.getItem(USER_ID_KEY)
        //  dispatch(authSlice.actions.authFetching(true))
        const number = getState().auth.userNUMBER
        const currentUser = getState().user.currentUser
        const sendObject = {
            ...formValues, phone: number, renew: true // , renew: false
        }
        // console.log(sendObject, 'sendObject')
        const response = await fetch(`${urlAUTH}/checkMobileOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const sendObject2 = {
            method: 'PUT',
            collection: 'users',
            //   query: queryUSER,
            query: getQueryCurrentUser(),
            id: Number(userID),
            body: {
                phoneVerified: true
            }
        }
        //  console.log(sendObject, 'sendObject register')

        const dataJSON = await response.json() as ResponseObject
        //  console.log(dataJSON, 'dataJSON<<<<<<<<<<<<< maklsmcklasmclkAMSclk mAS <<<?????COONNNFFFIIIRRRMMMM  _______________         1')

        if (dataJSON.success && dataJSON.response?.approved) {
            const response2 = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(sendObject2),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })

            const dataJSON2 = await response2.json() as ResponseObject
            const sentMail = await sendMail({
                email: currentUser?.email as string,
                templateId: 'd-1ff4587fed864e7ea2286f14a803bad1',
                dynamicTemplateData: {
                    value: number
                }
            })

            dispatch(modalSlice.actions.dataFetchingFinish())

            return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/settings`, isSuccessful: true, textNotification: 'Your phone has been successfully changed' }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())

            return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
        //  dispatch(authSlice.actions.registerFailed(error))
    }
}

export const changeNumber = (formValues: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const { currentUser } = getState().user
    const userContactInfo = { ...(currentUser as User).ContactInfo, Phone: formValues.email }
    try {
        dispatch(modalSlice.actions.dataFetchingStart())
        //  dispatch(authSlice.actions.authFetching(true))

        const sendObject = {
            method: 'PUT',
            collection: 'users',
            //   query: queryUSER,
            query: getQueryCurrentUser(),
            id: Number(userID),
            body: {
                phone: formValues.phone,
                phoneVerified: false,
                ContactInfo: userContactInfo
            }
        }
        //  console.log(sendObject, 'sendObject register')
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const dataJSON = await response.json() as ApiResponse

        //  console.log(dataJSON, 'dataJSON<<<<<<<<<<<<<<<<?????CHANGE NUMBER')

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                //   dispatch(authSlice.actions.registerFailed(responseRegisterPutStep2JSON.response))
                dispatch(modalSlice.actions.dataFetchingFinish())

                return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/settings`, isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                const response2 = await fetch(`${urlAUTH}/sendMobileOTP`, {
                    method: 'POST',
                    body: JSON.stringify({ ...formValues, renew: true, phone: formValues.phone }), // , renew: false
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                })
                dispatch(modalSlice.actions.dataFetchingFinish())
                dispatch(authSlice.actions.numberSuccess(formValues?.phone))
                return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/modal/change_phone_for_contacts_my_phone_verify/not/not`, isSuccessful: true, textNotification: 'Your phone number has been changed, please confirm the new phone number!' }
            }
        } else {
            // dispatch(authSlice.actions.registerFailed(dataJSON))
            dispatch(modalSlice.actions.dataFetchingFinish())

            return { textNavigate: '', isSuccessful: false, textNotification: 'Some error occurred,please try again' }
        }
    } catch (error) {
        console.log(error)
    }
}
