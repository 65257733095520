import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../urls'

export const deleteDocument = async (id: number): Promise<boolean> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    const sendBody = {
        method: 'DELETE',
        collection: 'documents',
        id
    }

    const response = await fetch(`${urlAPI}`, {
        method: 'POST',
        body: JSON.stringify(sendBody),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`
        }
    })

    if (response.status === 200) {
        const json = await response.json()
        return json.response.deleted
    }
    return false
}
