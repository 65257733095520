import React from 'react'
import GetDescriptions from './GetDescriptions'

interface IProps {
    selectedSubscription: string
    selectedPeriod: string
    setSelectedSubscription: React.Dispatch<React.SetStateAction<string>>
    activeSub: string
    handleSubscriptionChange: (renew?: string) => void
}

export default function StartSub ({ handleSubscriptionChange, activeSub, selectedPeriod, selectedSubscription, setSelectedSubscription }: IProps): JSX.Element {
    return (
        <div className={`card card-dashed p-2  mb-4 bg-light-primary    ${selectedSubscription === 'Free' ? 'bg-primary' : ''}`} onClick={() => { setSelectedSubscription('Free') }}>
            <div className="d-flex justify-content-between align-items-center mb-1 rounded p-2 mb-2">
                <div className="d-flex align-items-center me-2 ">
                    <span className={`badge badge-lg border border-5  badge-circle badge-light-success me-6 ${activeSub === 'Free' ? 'border-success' : 'border-secondary'}`}></span>
                    <div className="flex-grow-1">
                        <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
      Free
                        </div>
                        <div className="fw-semibold opacity-75 lh-sm">
                        Best to explore the app and its features{' '}
                        </div>
                    </div>
                </div>

                <div className="ms-5">
                    <span className="mb-2 me-1">AED</span>
                    <span
                        className="fs-3x fw-bold"
                    >
                        {selectedPeriod === 'annual' ? '0' : '0'}
                    </span>

                    <span className="fs-7 opacity-50">
    /<span>
                            {selectedPeriod === 'annual' ? 'Year' : 'Mon'}

                        </span>
                    </span>
                </div>
            </div>

            {selectedSubscription === 'Free' && (
                activeSub !== 'Free'
                    ? <div className="text-end" onClick={() => { handleSubscriptionChange() }}>
                        <button className="btn btn-sm  border border-white text-white btn-active-light-dark" >Subscribe</button>
                    </div>
                    : <div className="text-end" onClick={() => { handleSubscriptionChange('renew') }}>
                        <button className="btn btn-sm  border border-white text-white btn-active-light-dark" >Renew</button>
                    </div>

            )}

            {selectedSubscription === 'Free' && (
                <GetDescriptions
                    activeSub={activeSub}
                    selectedPeriod={selectedPeriod}
                    selectedSubscription={selectedSubscription}
                    setSelectedSubscription={setSelectedSubscription}
                />
            )}
        </div>

    )
}
