
import React, { type ReactElement } from 'react'
import CloseIconBasic from '../../components/shared/elements/CloseIconBasic'
import { dropModalSlice, setWhereAfterToGo } from '../../store/slices/modalSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useNavigate } from 'react-router'

import useUserType from '../../hooks/UseUserType'
import { type RootState } from '../../store/store'

interface CustomModalHeaderProps {
    modal: any
    onLeave: any
    onCloseForAdvertiseModal: any
}

export default function CustomModalHeader({ modal, onLeave, onCloseForAdvertiseModal }: CustomModalHeaderProps): ReactElement {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const loading = useAppSelector(state => state.modal.loading)
    const previewVisible = useAppSelector((state: RootState) => state.modal.previewVisible)
    const satedRedirect = useAppSelector((state: RootState) => state.modal.whereAfterToGo)
    const btnClose = modal[0].footer.find((footerElement: any) => footerElement.id === 'close' || footerElement.id === 'cross-close' || footerElement.element === 'button-secondary')
    const { userTypeLowercase } = useUserType()


    return (
        <div className="card-title">
            <h3>{modal[0]?.header}</h3>
            {!btnClose || loading || previewVisible
                ? null
                : <CloseIconBasic
                    onCancel={
                        btnClose?.close !== undefined
                            ? btnClose?.close
                            : btnClose?.attention
                                ? () => { onLeave(btnClose?.redirect) }
                                : () => {
                                    if (!['edit_unit_modal', 'set_unit_modal'].includes(modal?.[0]?.id)) {
                                        dispatch(dropModalSlice())
                                    }
                                    if (['edit_unit_modal', 'set_unit_modal'].includes(modal?.[0]?.id)) {
                                        navigate(`/${userTypeLowercase}/my_units`)
                                        return
                                    }
                                    if (btnClose?.redirect) {
                                        navigate(btnClose.redirect);
                                    } else if (modal[0].id === 'agent_advertise_create_duration') {
                                        onCloseForAdvertiseModal();
                                    }
                                    else if (satedRedirect) {
                                        navigate(satedRedirect)
                                        dispatch(setWhereAfterToGo(''))
                                    } else {
                                        navigate(-1);
                                    }
                                }}
                    cls={'15px'}
                />
            }
        </div>
    )
}
