import React from 'react'

const MyUnitButtons = (): JSX.Element => {
    return (
        <div className="d-flex flex-row mb-0 px-4 gap-4">
            <div
                // data-id="units_list_template_get_details"
                className="btn btn-bso m-0 fs-6 p-1 py-3 w-100"
            >
                Order a service
            </div>
            <div
                // data-id="units_list_template_add_viewing"
                className="btn btn-bso  m-0 fs-6 p-1 py-3  w-100"
            >
                Get subscription
            </div>
        </div>
    )
}

export default MyUnitButtons
