import { Slider } from 'antd'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import CustomFormLable from './CustomFormLable'
import customLog from '../../../functions/log'
import Icon from '../../icon/Icon'

interface CustomSliderProps {
    data: any
    control: any
}

const CustomSlider = ({ data, control }: CustomSliderProps): JSX.Element => {
    const [values, setValues] = useState([data.min, data.min])
    const [marks, setMarks] = useState<any>({})
    const [valueDropped, setValueDropped] = useState(false)
    useEffect(() => {
        customLog(values, 'values')
        const newMarks: any = {}
        newMarks[data.min] = <span className={'fs-8 text-nowrap bg-white'} >{data.min}</span>
        newMarks[data.max] = <span className={'fs-8 text-nowrap bg-white'}>{data.max}</span>
        newMarks[values[0]] = <div className={'fs-8 text-nowrap bg-white mt-2'}>{values[0]}</div>
        newMarks[values[1]] = <div className={'fs-8 text-nowrap bg-white mt-2'}>{values[1]}</div>
        setMarks(newMarks)
    }, [values])
    return (
        <>
            <CustomFormLable row={data} />
            <Controller
                name={data.id}
                control={control}
                // defaultValue={data.multiple ? [optionsMapped[0].label] : optionsMapped[0].label}
                rules={data.required === true ? { required: `${String(data.content?.[0])} is required` } : {}}
                render={({ field }) => {
                    customLog(field)
                    return (
                        <div className={'d-flex gap-4'}>
                            <div className={'w-100'}>
                                <Slider
                                    {...field}
                                    value={valueDropped ? null : field.value ?? null}
                                    range
                                    min={data.min}
                                    max={data.max}
                                    marks={marks}
                                    onChange={(value) => {
                                        setValueDropped(false)
                                        field.onChange(value)
                                        setValues(value)
                                    }}
                                    tooltip={{ open: false }}
                                />
                            </div>
                            <Icon name={'close'} className={'fs-4x mt-1 text-muted'} onClick={() => {
                                field.onChange(undefined)
                                setValues([data?.min, data?.min])
                                setValueDropped(true)
                            }} />
                        </div>
                    )
                }}
            />
        </>
    )
}

export default CustomSlider
