// Получаем сегодняшнюю дату
const today = new Date()

// Функция, которая добавляет определенное количество месяцев к дате
function addMonths (date: Date, months: number): Date {
    const newDate = new Date(date)
    newDate.setMonth(newDate.getMonth() + months)
    return newDate
}

function formatDate (date: Date): string {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

const oneMonthLater = addMonths(today, 1)

const todayFormatted = formatDate(today)
const oneMonthLaterFormatted = formatDate(oneMonthLater)

export const getCurrentDateAndNextMonthDate = () => {
    return { todayFormatted, oneMonthLaterFormatted }
}
