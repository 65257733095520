import React, { useEffect, useState } from 'react'

import AgentSchedulerNavigate from './SchedulerNavigate'

import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import AgentHeader from '../../agentModule/AgentHeader'
import SchedulerNavigateNewVersion from './scheduleNavigate/SchedulerNavigateNewVersion'

interface IProps {
    allAttention: any
    countView: number
    countOffers: number
    advertisesCount: number
    broadcastMessagesCount: number
    viewingsNotifies: number
    offersNotifies: number
    advertisesNotifies: number
}

export default function SchedulerHeader({
    allAttention,
    countView, countOffers, advertisesCount, broadcastMessagesCount,
    viewingsNotifies,
    offersNotifies,
    advertisesNotifies
}: IProps): JSX.Element {
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const { isShown } = useAppSelector((state: RootState) => state.voice)
    return (
        <div
            className="header position-fixed w-100 bg-white h-auto fw-bold d-flex flex-column shadow-sm mw-450px mx-auto"
            style={{
                top: isShown ? '31px' : 0,
                left: 0,
                right: 0,
                zIndex: 101
            }}
        >
            <div className="d-flex flex-column p-4 py-0 px-0 w-100 position-relative">

                {/* <AgentHeader active={isMyTasks ? 'My Units Scheduler' : 'Schedule'}
                    allAttention={allAttention}
                    notifyApp={notifyApp}
                    viewingsNotifiesOtherAgents={viewingsNotifiesOtherAgents}
                    isBSOUnits={isBSOUnits} setIsBSOUnits={setIsBSOUnits}
                /> */}
                {userDevice === 'IPhone'
                    ? <div className="h-25px"></div>
                    : <></>
                }
                <SchedulerNavigateNewVersion countView={countView}
                    countOffers={countOffers}
                    countAdvertises={advertisesCount}
                    broadcastMessagesCount={broadcastMessagesCount}
                    viewingsNotifies={viewingsNotifies}
                    offersNotifies={offersNotifies}
                    advertisesNotifies={advertisesNotifies}
                    allAttention={allAttention}
                />
            </div>
        </div>
    )
}
