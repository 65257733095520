import React from 'react'
import { Modal } from 'antd'
import VideoPlayer, { type VideoPlayerProps } from './index'

export type FullScreenPlayerProps = VideoPlayerProps & {
    open: boolean
    onClose: () => void
}
export default function FullScreenPlayer ({ open, onClose, video, name, sentBy, time }: FullScreenPlayerProps): React.ReactElement {
    return <Modal
        open={open}
        style={{ top: 0, bottom: 0, height: '100vh', left: 0 }}
        maskStyle={{ height: '100vh', left: 0 }}
        width={'100vw'}
        zIndex={10000}
        closable={false}
        footer={false}
    >
        <div className={'d-flex justify-content-center align-items-center'} style={{ width: '100vw', height: '100vh' }}>
            <VideoPlayer
                smallSize={false}
                video={video}
                name={name}
                time={time}
                sentBy={sentBy}
                isFullScreen={true}
                closeParentFullScreen={onClose}
            />
        </div>
    </Modal>
}
