
// const january = '/assets/media/calendar_cards/January.png'
// const february = '/assets/media/calendar_cards/February.png'
// const march = '/assets/media/calendar_cards/March.png'
// const april = '/assets/media/calendar_cards/April.png'
// const may = '/assets/media/calendar_cards/May.png'
// const june = '/assets/media/calendar_cards/June.png'
// const july = '/assets/media/calendar_cards/July.png'
// const august = '/assets/media/calendar_cards/August.png'
// const september = '/assets/media/calendar_cards/September.png'
// const october = '/assets/media/calendar_cards/October.png'
// const november = '/assets/media/calendar_cards/November.png'
// const december = '/assets/media/calendar_cards/December.png'
const january = 'https://bso.ae/api/app/downloadStrapiFile?id=1Rw3ceS7cnwrf-KhGxiwVNV51c6iBLFxP&type=image/png'
const february = 'https://bso.ae/api/app/downloadStrapiFile?id=1ZcRAyWG7GLKBSHjK6wch1odn90-Zp2em&type=image/png'
const march = 'https://bso.ae/api/app/downloadStrapiFile?id=1KQu38mjsunm15PU2qAihS4xgXUi2a98Q&type=image/png'
const april = 'https://bso.ae/api/app/downloadStrapiFile?id=17ODR2VV8o_97-jr6VbNUtrp1nEeyWTRC&type=image/png'
const may = 'https://bso.ae/api/app/downloadStrapiFile?id=1Mcn7j9VAJbrLqqm-lItVH1rsCKzYSEjM&type=image/png'
const june = 'https://bso.ae/api/app/downloadStrapiFile?id=1pYdRks3IeZn49N8qnFDjRLMgdVioN_70&type=image/png'
const july = 'https://bso.ae/api/app/downloadStrapiFile?id=1RFvNypb_3KmwEmlQVHCHgMMDil8RyI-W&type=image/png'
const august = 'https://bso.ae/api/app/downloadStrapiFile?id=1mzHvGx0YPks-wbuT2U5LDBIs408Dg8cS&type=image/png'
const september = 'https://bso.ae/api/app/downloadStrapiFile?id=1LmOmn4iSISLFfA66NbMZAEq1B7--xa-S&type=image/png'
const october = 'https://bso.ae/api/app/downloadStrapiFile?id=1EMAlAKLOfsgo7wnjApZ7RCSZmZ7CcgPG&type=image/png'
const november = 'https://bso.ae/api/app/downloadStrapiFile?id=1x6EJkvum4JVI6LXbhzqv9Fs00zNKEh7g&type=image/png'
const december = 'https://bso.ae/api/app/downloadStrapiFile?id=1gS_Z2mTBDgjGTyj4SPvBs6QHEM3oYerW&type=image/png'
export const getMonthImage = (date: any): any => {
    const month = new Date(date).getMonth() + 1

    switch (month) {
        case 1:
            return { mounth: january, str: 'January' }
        case 2:
            return {
                mounth: february,
                str: 'February'
            }
        case 3:
            return {
                mounth: march,
                str: 'March'
            }
        case 4:
            return {
                mounth: april,
                str: 'April'
            }
        case 5:
            return {
                mounth: may,
                str: 'May'
            }
        case 6:
            return {
                mounth: june,
                str: 'June'
            }
        case 7:
            return {
                mounth: july,
                str: 'July'
            }
        case 8:
            return {
                mounth: august,
                str: 'August'
            }
        case 9:
            return { mounth: september, str: 'September' }
        case 10:
            return {
                mounth: october,
                str: 'October'
            }
        case 11:
            return {
                mounth: november,
                str: 'November'
            }
        case 12:
            return {
                mounth: december,
                str: 'December'
            }
        default:
            return null
    }
}
