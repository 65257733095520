import React from 'react'
import TutorialItem from '../../tutorials/TutorialItem'
import { nanoid } from 'nanoid'
import AgentHeader from '../../AgentHeader'
import { tutorials } from '../../../../urls'

export default function TutorialsList(): JSX.Element {
    return (
        <>
            <AgentHeader
                active="Tutorials"
            />
            <div className={'container px-4 mt-4'}>
                <div className="card shadow-lg mb-4">
                    <TutorialItem
                        key={nanoid()}
                        label={'START USING BSO CLUB'}
                        description={'Get started in just few clicks'}
                        route={'/agent/profile/me'}
                        img={tutorials.thumbnails['slide-1']}
                        contentArray={[
                            { img: tutorials['start-using-bso-club']['slide-1'] },
                            { img: tutorials['start-using-bso-club']['slide-2'] },
                            { img: tutorials['start-using-bso-club']['slide-3'] },
                            //        {  img: tutorials['start-using-bso-club']['slide-4'] },
                            { img: tutorials['start-using-bso-club']['slide-5'] }
                        ]}
                    />
                </div>
                <div className="card shadow-lg mb-4">
                    <TutorialItem
                        key={nanoid()}
                        label={'GET STARTED WITH BSO CLUB'}
                        route={'/agent/settings'}
                        description={'Unlock Exclusive Benefits'}
                        img={tutorials.thumbnails['slide-2']}
                        contentArray={[
                            { img: tutorials['benefits-of-verified']['slide-1'] },
                            //   {  img: tutorials['benefits-of-verified']['slide-2']},
                            { img: tutorials['benefits-of-verified']['slide-3'] }]}
                    />

                </div>
                <div className="card shadow-lg mb-4">
                    <TutorialItem
                        key={nanoid()}
                        label={'ADVERTISING BSO UNITS'}
                        route={'/agent/settings'}
                        description={'Everything you need to know'}
                        img={tutorials.thumbnails['slide-3']}
                        contentArray={[
                            { img: tutorials['advertise-bso-units']['slide-1'] },
                            { img: tutorials['advertise-bso-units']['slide-2'] },
                            { img: tutorials['advertise-bso-units']['slide-3'] },
                            { img: tutorials['advertise-bso-units']['slide-4'] }
                            //   {  img: tutorials['advertise-bso-units']['slide-5']  }
                        ]}
                    />
                </div>

                <div className="card shadow-lg mb-4">
                    <TutorialItem
                        key={nanoid()}
                        label={'BOOK YOUR FIRST VIEWING'}
                        route={'/agent/settings'}
                        description={'Efficent way to book viewings'}
                        img={tutorials.thumbnails['slide-4']}
                        contentArray={[
                            { img: tutorials['book-your-first-viewing']['slide-1'] },
                            { img: tutorials['book-your-first-viewing']['slide-2'] },
                            { img: tutorials['book-your-first-viewing']['slide-3'] },
                            { img: tutorials['book-your-first-viewing']['slide-4'] },
                            { img: tutorials['book-your-first-viewing']['slide-5'] }
                            // {  img: tutorials['book-your-first-viewing']['slide-6'] }

                        ]}
                    />
                </div>
                <div className="card shadow-lg mb-4">
                    <TutorialItem
                        key={nanoid()}
                        label={'BUSINESS SCHEDULER & TASKS'}
                        route={'/agent/settings'}
                        description={'Your companian to stay organized'}
                        img={tutorials.thumbnails['slide-5']}
                        contentArray={[
                            { img: tutorials['business-scheduler-tasks']['slide-1'] },
                            { img: tutorials['business-scheduler-tasks']['slide-2'] },
                            { img: tutorials['business-scheduler-tasks']['slide-3'] }

                        ]}
                    />
                </div>
                <div className="card shadow-lg mb-4">

                    <TutorialItem
                        key={nanoid()}
                        label={'SUBMIT YOUR FIRST OFFER'}
                        route={'/agent/settings'}
                        description={'Effortless offer submission'}
                        img={tutorials.thumbnails['slide-6']}
                        contentArray={[
                            { img: tutorials['submit-your-first-offer']['slide-1'] },
                            { img: tutorials['submit-your-first-offer']['slide-2'] },
                            { img: tutorials['submit-your-first-offer']['slide-3'] },
                            { img: tutorials['submit-your-first-offer']['slide-4'] },
                            { img: tutorials['submit-your-first-offer']['slide-5'] }
                            //  { img: tutorials['submit-your-first-offer']['slide-6'] }
                        ]}
                    />
                </div>
                {/* <div className="card shadow-lg mb-4"> */}
                {/*    <TutorialItem */}
                {/*        key={nanoid()} */}
                {/*        label={'MANAGE YOUR DIRECT UNITS AND CLIENTS !!! - -- - - - - -   - - - - - -- - - - !!!'} */}
                {/*        route={'/agent/settings'} */}
                {/*        description={'Seamlessly manage units and clients'} */}
                {/*        img={'/assets/media/tutorials/thumbnail/7.png'} */}
                {/*        contentArray={[ */}
                {/*            {  img: defaultImage }, */}
                {/*            {  img: defaultImage } */}
                {/*        ]} */}
                {/*    /> */}
                {/* </div> */}
                <div className="card shadow-lg mb-4">
                    <TutorialItem
                        key={nanoid()}
                        label={'GET CONNECTED THROUGH BSO CHAT'}
                        route={'/agent/settings'}
                        description={'Explore broadcast chat and other features'}
                        img={tutorials.thumbnails['slide-8']}
                        contentArray={[
                            { img: tutorials['bso-chat-magic']['slide-1'] },
                            { img: tutorials['bso-chat-magic']['slide-2'] },
                            { img: tutorials['bso-chat-magic']['slide-3'] },
                            { img: tutorials['bso-chat-magic']['slide-4'] },
                            { img: tutorials['bso-chat-magic']['slide-5'] },
                            { img: tutorials['bso-chat-magic']['slide-6'] },
                            { img: tutorials['bso-chat-magic']['slide-7'] }
                            // {  img: tutorials['bso-chat-magic']['slide-8'] }
                        ]}
                    />
                </div>

                <div className="card shadow-lg mb-4">
                    <TutorialItem
                        key={nanoid()}
                        label={'BSO CLUB ADD-ONS'}
                        route={'/agent/settings'}
                        description={'Supercharge Your BSO Club Experience'}
                        img={tutorials.thumbnails['slide-9']}
                        contentArray={[
                            { img: tutorials['bso-club-add-ons']['slide-1'] },
                            { img: tutorials['bso-club-add-ons']['slide-2'] },
                            { img: tutorials['bso-club-add-ons']['slide-3'] },
                            { img: tutorials['bso-club-add-ons']['slide-4'] }
                            //  {  img: tutorials['bso-club-add-ons']['slide-5'] }
                        ]}
                    />
                </div>

                {/* <div className="card shadow-lg mb-4"> */}
                {/*    <TutorialItem */}
                {/*        key={nanoid()} */}
                {/*        label={'EARN EXTRA MONEY WITH BSO CLUB !!! - -- - - - - -   - - - - - -- - - - !!!'} */}
                {/*        route={'/agent/settings'} */}
                {/*        description={'Unlock extra earnings effortlessly with referrals'} */}
                {/*        img={'/assets/media/tutorials/thumbnail/10.png'} */}
                {/*        contentArray={[ */}
                {/*            {  img: defaultImage }, */}
                {/*            { img: defaultImage } */}
                {/*        ]} */}
                {/*    /> */}
                {/* </div> */}
                {/* <div className="card shadow-lg mb-4"> */}
                {/*    <TutorialItem */}
                {/*        key={nanoid()} */}
                {/*        label={'BSO BROKER RATING'} */}
                {/*        route={'/agent/settings'} */}
                {/*        description={'Discover top-notch agents with BSO'} */}
                {/*        img={'/assets/media/tutorials/thumbnail/11.png'} */}
                {/*        contentArray={[ */}
                {/*            {  img: '/assets/media/tutorials/BSO_BROKER_RATING/1.png' }, */}
                {/*            {  img: '/assets/media/tutorials/BSO_BROKER_RATING/2.png' }, */}
                {/*            {  img: '/assets/media/tutorials/BSO_BROKER_RATING/3.png' }, */}
                {/*            {  img: '/assets/media/tutorials/BSO_BROKER_RATING/4.png' } */}
                {/*        ]} */}
                {/*    /> */}
                {/* </div> */}
                {/* <div className="card shadow-lg mb-4"> */}
                {/*    <TutorialItem */}
                {/*        key={nanoid()} */}
                {/*        label={'BSO CREDITS !!! - -- - - - - -   - - - - - -- - - - !!!'} */}
                {/*        route={'/agent/settings'} */}
                {/*        description={'Everything you need to know'} */}
                {/*        img={'/assets/media/tutorials/thumbnail/12.png'} */}
                {/*        contentArray={[ */}
                {/*            {  img: defaultImage }, */}
                {/*            { img: defaultImage } */}
                {/*        ]} */}
                {/*    /> */}
                {/* </div> */}
            </div>
            <div className='h-60px'> </div>
        </>
    )
}
