import React, { FormEvent, type ReactElement, useState } from 'react'
import {
    type Control,
    Controller,
    type FieldErrors,
    type FieldValues,
    type UseFormRegister,
    type UseFormSetValue
} from 'react-hook-form'
import { Space, Switch } from 'antd'
import { getCountries, getCountryCodes } from '../../../../functions/getDataForSelects/getCountries'

import CustomSelect from '../../../../app/custom/selects/CustomSelect'
import _ from 'lodash'
import { regExps } from '../../../../constants'
import DefaultInput from '../../../../app/custom/inputs/DefaultInput'
import GroupInput from '../../../../app/custom/inputs/GroupInput'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import CustomSingleFileInput from '../../../../app/custom/inputs/CustomSingleFileInput'
import { scanDocument } from '../../../../store/actionsCreators/documentsActions/scaning/documentScan'
import { dataFetchingFinish, dataFetchingStart } from '../../../../store/slices/modalSlice'
import { type IFields } from '../../../../types/userTypes/documentTypes/TScan'
import CustomFormLable from '../../../../app/custom/customModalUtils/CustomFormLable'

interface ScanningValidatorProps {
    errors: FieldErrors<any>
    register: UseFormRegister<any>
    id: string
    show: boolean
    validationMessage: string
}

function ScanningValidator ({ errors, register, id, show, validationMessage }: ScanningValidatorProps): ReactElement {
    const name = `${id}-validation`
    return (
        show
            ? <div>
                <input
                    {...register(name, {
                        required: validationMessage
                    })}
                    name={name}
                    type="text"
                    hidden={true}
                />
                {
                    _.get(errors, name) != null && (
                        <div className="text-danger my-2">{String(_.get(errors, name)?.message)}</div>
                    )
                }
            </div>
            : <></>
    )
}

interface AgentToBSOOfferClientInfoProps {
    setValue: UseFormSetValue<FieldValues>
    errors: FieldErrors<any>
    register: UseFormRegister<any>
    control: Control<any>
    offerID: number
    unitID: number
}

export default function AgentToBSOOfferClientInfo ({ setValue, errors, register, control, offerID, unitID }: AgentToBSOOfferClientInfoProps): ReactElement {
    const dispatch = useAppDispatch()
    const units = useAppSelector((state: RootState) => state.units.units)
    const unit = units.find((unitItem) => unitItem.id === unitID)
    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const offer = offers.find((offerItem) => offerItem.id === offerID)
    const documents = useAppSelector((state: RootState) => state.documents.documents)
    const offerDocs = documents.filter((docItem) => (
        offer?.attributes?.Documents?.data?.some((offerDocItem) => (
            docItem.id === offerDocItem.id
        ))
    ))
    const tradeLicenceDoc = offerDocs?.find((docItem) => docItem?.attributes?.Type?.[0]?.__component === 'documents.trade-license')
    const passportDoc = offerDocs?.find((docItem) => docItem?.attributes?.Type?.[0]?.__component === 'documents.passport')
    const emiratesIDDoc = offerDocs?.find((docItem) => docItem?.attributes?.Type?.[0]?.__component === 'documents.emirates-id')

    const handleSwitchChangeOfferType = (checked: boolean): void => {
        setCheckedOfferType(checked)
    }
    const [switchCheckedOfferType, setCheckedOfferType] = useState(offer?.attributes?.Type[0]?.BusinessName != null)
    const [isHaveID, setIsHaveID] = useState(emiratesIDDoc != null)
    const [isHavePassport, setIsHavePassport] = useState(passportDoc != null)

    const [scanningErrors, setScanningErrors] = useState<string[]>([])
    const [uploadedFiles, setUploadedFiles] = useState<string[]>([])
    const [scannedFormData, setScannedFormData] = useState<Record<string, any> | null>(null)
    // console.log(scannedFormData?.document?.name?.split(' ')?.[0] ?? offer?.attributes?.Type[0]?.FirstName)
    const captureFileUpload = (rowID: string) => (e: any): void => {
        // if (['visa', 'id_front', 'id_back'].includes(rowID)) {
        //     dispatch(dataFetchingStart())
        //     const file = e.target.files
        //     const name = e.target.name
        //     const formValues: IFields = { [name]: file }
        //     void dispatch(scanDocument(formValues))
        //         .then((scannedData: any) => {
        //             if (scannedData?.document?.valid !== true) {
        //                 const newScanningErrors = Array.from(new Set([...scanningErrors, rowID]))
        //                 setScanningErrors(newScanningErrors)
        //             } else {
        //                 setScannedFormData(scannedData)
        //                 setValue('Type.FirstName', scannedData?.document?.name?.split(' ')?.[0])
        //                 setValue('Type.LastName', scannedData?.document?.name.split(' ').slice(1).join(' '))
        //                 setValue('Type.Country', getCountryCodes(scannedData?.document?.nationality))
        //                 setScanningErrors(scanningErrors.filter(err => err !== rowID))
        //             }
        //         })
        //         .finally(() => {
        //             dispatch(dataFetchingFinish())
        //         })
        // }
        const newUploadedFiles = Array.from(new Set([...uploadedFiles, rowID]))
        setUploadedFiles(newUploadedFiles)
    }

    const rowFirstName = {
        id: 'Type.FirstName',
        content: ['Client First Name', 'Thomas'],
        element: 'input',
        required: true,
        validateEquals: ['Scanned.FirstName', 'Must be equal to first name in the scanned document', 2],
        defaultValue: scannedFormData?.document?.name?.split(' ')?.[0] ?? offer?.attributes?.Type[0]?.FirstName ?? undefined
    }
    const rowLastName = {
        id: 'Type.LastName',
        content: ['Client Last Name', 'A. Anderson'],
        element: 'input',
        required: true,
        validateEquals: ['Scanned.LastName', 'Must be equal to last name in the scanned document', 2],
        defaultValue: scannedFormData?.document?.name.split(' ').slice(1).join(' ') ?? offer?.attributes?.Type[0]?.LastName ?? undefined
    }

    const rowCountry = {
        id: 'Type.Country',
        content: ['Client Nationality', 'Select country'],
        selectors: getCountries(),
        element: 'select',
        required: true,
        defaultValue: scannedFormData?.document?.nationality != null ? getCountryCodes(scannedFormData?.document?.nationality) : offer?.attributes?.Type[0]?.Country ?? undefined
    }
    // const rowClientBirthday = { id: 'client_birthday', content: ['Client Birth Day', 'Choose client\'s date of birth'], element: 'input-date', yearsBeforeToday: 18, required: true, ...(generateDefaultValueFormField(dataScan != null && dataScan?.dob ? getCorrectExpDate(dataScan?.dob) : undefined)) }
    //   const rowClientBirthday = { id: 'dob', content: ['Client Birth Day', 'Choose client\'s date of birth'], element: 'input-date', required: true, ...(generateDefaultValueFormField(dataScan != null && dataScan?.dob ? getCorrectExpDate(dataScan?.dob) : undefined)) }
    const rowTradeLicence = {
        id: 'trade_licence',
        content: ['Upload client\'s company trade licence', 'Trade licence'],
        element: 'input-file',
        required: false,
        onInput: captureFileUpload('traded_licence'),
        uploadedBase64: !uploadedFiles.includes('trade_licence') ? tradeLicenceDoc?.attributes?.Link : undefined
    }
    const rowIdFront = {
        id: 'id_front',
        content: ['Emirates ID Front Side'],
        element: 'input-file',
        required: emiratesIDDoc == null,
        onInput: captureFileUpload('id_front'),
        uploadedBase64: !uploadedFiles.includes('id_front') ? emiratesIDDoc?.attributes?.LinkFront : undefined
    }
    const rowIdBack = {
        id: 'id_back',
        content: ['Emirates ID back side'],
        element: 'input-file',
        required: emiratesIDDoc == null,
        onInput: captureFileUpload('id_back'),
        uploadedBase64: !uploadedFiles.includes('id_back') ? emiratesIDDoc?.attributes?.LinkBack : undefined
    }
    const rowVisa = {
        id: 'visa',
        content: ['Passport'],
        element: 'input-file',
        required: passportDoc == null,
        onInput: captureFileUpload('visa'),
        uploadedBase64: !uploadedFiles.includes('visa') ? passportDoc?.attributes?.Link : undefined
    }
    const rowBusinessName = {
        id: 'Type.BusinessName',
        content: ['Business Name', 'Input client business name'],
        element: 'input',
        required: true,
        defaultValue: offer?.attributes?.Type[0]?.BusinessName ?? undefined
    }
    const rowBusinessOwnerName = {
        id: 'Type.BusinessOwnerName',
        content: ['Business Owner Name', 'Input business owner name'],
        element: 'input',
        required: true,
        defaultValue: offer?.attributes?.Type[0]?.BusinessOwnerName ?? undefined
    }
    const rowBusinessActivity = {
        id: 'Type.BusinessActivity',
        content: ['Business Activity', 'Input business activity'],
        element: 'input',
        required: true,
        defaultValue: offer?.attributes?.Type[0]?.BusinessActivity ?? undefined
    }
    const rowEstablishmentDate = {
        id: 'Type.EstablishmentDate',
        content: ['Establishment Date', 'Year', 'Input establishment year'],
        element: 'input-group',
        regexp: regExps.isYear,
        required: true,
        dateBeforeToday: true,
        defaultValue: offer?.attributes?.Type?.[0]?.EstablishmentDate ?? undefined
    }

    return (
        <>

            <div className="fv-row my-2 m-0 p-0 w-100">
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Is the client a company ?</div>
                    </div>

                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Controller
                                control={control}
                                name={'offer_is_commercial'}
                                defaultValue={switchCheckedOfferType}
                                render={({ field }) => (
                                    <Switch unCheckedChildren="NO"
                                        {...field}
                                        // defaultChecked={switchCheckedOfferType}
                                        checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                        onChange={(e) => {
                                            handleSwitchChangeOfferType(e)
                                            field.onChange(e)
                                        }}
                                    />
                                )}
                            />
                        </Space>
                    </div>
                </div>

                {
                    switchCheckedOfferType
                        ? <>
                            <div className={'d-flex flex-column gap-4'}>
                                <div className="separator separator-dashed my-4 mb-0"></div>
                                <CustomSingleFileInput row={rowTradeLicence} register={register} errors={errors} getValues={() => { }} />
                            </div>
                        </>
                        : null

                }
                <div className="separator separator-dashed my-4"></div>
            </div>

            <div className="fv-row  mt-8 p-0 w-100">
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase"> Do you have a {switchCheckedOfferType ? "company representative's" : "client's"} Passport copy ?</div>
                    </div>
                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Switch unCheckedChildren="NO" defaultChecked={isHavePassport}
                                checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                onChange={(ch) => { setIsHavePassport(ch) }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-4"></div>
                {isHavePassport &&
                    <>
                        <CustomSingleFileInput row={rowVisa} register={register} errors={errors} getValues={() => { }} />
                        <ScanningValidator id={rowVisa.id} register={register} errors={errors} validationMessage={'The passport is not valid'} show={scanningErrors.includes(rowVisa.id)} />
                        <div className="separator separator-dashed my-4"></div>
                    </>
                }
            </div>
            <div className="fv-row  mt-8 p-0 w-100">
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase"> Do you have a {switchCheckedOfferType ? "company representative's" : "client's"} Emirates ID copy ?</div>
                    </div>
                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Switch unCheckedChildren="NO"
                                defaultChecked={isHaveID}
                                checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                onChange={(ch) => { setIsHaveID(ch) }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-4"></div>
                {isHaveID &&

                    <>
                        <CustomSingleFileInput row={rowIdFront} register={register} errors={errors} getValues={() => { }} />
                        <CustomSingleFileInput row={rowIdBack} register={register} errors={errors} getValues={() => { }} />
                        <ScanningValidator id={rowIdFront.id} register={register} errors={errors} validationMessage={'The emirates id is not valid'} show={scanningErrors.includes(rowIdFront.id) && scanningErrors.includes(rowIdBack.id)} />
                        <div className="separator separator-dashed my-4"></div></>
                }

                {
                    !switchCheckedOfferType
                        ? <>
                            <DefaultInput row={rowFirstName} register={register} errors={errors} />
                            <DefaultInput row={rowLastName} register={register} errors={errors} />
                            <CustomFormLable row={rowCountry} />
                            <CustomSelect control={control} register={register} data={rowCountry} setValue={setValue} />
                            {
                                _.get(errors, rowCountry.id) != null && (
                                    <div className="text-danger my-2">{String(_.get(errors, rowCountry.id)?.message)}</div>
                                )
                            }
                        </>
                        : <>
                            <DefaultInput row={rowBusinessName} register={register} errors={errors} />
                            <DefaultInput row={rowBusinessActivity} register={register} errors={errors} />
                            <DefaultInput row={rowBusinessOwnerName} register={register} errors={errors} />
                            <GroupInput register={register} errors={errors} row={rowEstablishmentDate} />
                        </>
                }

            </div>
        </>
    )
}
