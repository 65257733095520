import _ from 'lodash'
import { customSendNotify } from './customSendNotify'

interface RemoveNotifyByDeviceTokenDTO {
    userID: number
    deviceToken: string
    badges? : number
}

export const removeNotifyByDeviceToken = async ({ userID, deviceToken , badges }: RemoveNotifyByDeviceTokenDTO): Promise<undefined> => {
    try {
        const userAgent = navigator.userAgent
        const isAndroid = _.includes(userAgent.toLowerCase(), 'android')
        if (isAndroid) return

        const body: {
            id: number
            device: string
            silent: boolean
            badges?: number
        } = {
            id: userID,
            device: deviceToken,
            silent: true
        }
        if (badges === 0) {
            body.badges = 0
        }

        const response = await customSendNotify(body)
    } catch (err) {
        console.error('Error while removing notify by device token: ', err)
    }
}
