import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { timeStringInScheduler } from '../../../../functions/agent/date/timeStringInScheduler'
import { generateBgAndBorderCol } from '../../../../functions/agent/schedule/generateBgAndBorderCol'
import { nanoid } from 'nanoid'
import { type IViewing } from '../../../../store/slices/viewingsSlice'

import { type RootState } from '../../../../store/store'
import UseUserType, { type UserType } from '../../../../hooks/UseUserType'
import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../../urls'
import Icon from '../../../../app/icon/Icon'
import { renderViewingStatus } from '../../../../functions/agent/schedule/renderViewingStatus'
import { getBelongsUnitForViewing } from '../../../../functions/shared/schedule/getBelongsUnit'
import { type IContactInfo } from '../../../../store/slices/userSlice'

interface IProps {
    viewing: IViewing

}

export default function CardViewing({
    viewing

}: IProps): JSX.Element {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const { userType, userTypeLowercase } = UseUserType()
    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    let notifyApp: UserType | 'BSO' = userType // | 'Both' | 'Other Agent'
    if (userID === REACT_APP_BSO_USER_ID) {
        notifyApp = 'BSO'
    }

    let isThereNotKey = false

    const currentViewing = useAppSelector(
        (state: RootState) => state.viewings.viewings
    )?.filter((view) => view.id === Number(viewing.id))[0]

    let task = getBelongsUnitForViewing(currentViewing)

    if (currentViewing.attributes.KeyBooking.data != null) {
        const keySts = currentViewing.attributes.KeyBooking.data.attributes.Status
        if (keySts != null && keySts.includes('In-process') || keySts?.includes('Waiting for agent')) { isThereNotKey = true }
    }

    const currentUnit = useAppSelector((state: RootState) => state.units.units)?.filter(
        (unit) => unit.id === Number(viewing?.attributes?.Unit?.data?.id)
    )[0]

    const currentAgent = useAppSelector(
        (state) => state.user.allUsers
    )?.filter(
        (user) => {
            return user.id === (task.type === 'MY UNIT' ? Number(viewing?.attributes?.User?.data?.id) : Number(viewing?.attributes?.Unit.data?.attributes?.User?.data?.id))
        }
    )[0]

    const [statusNotify, setStatusNotify] = useState(
        viewing.attributes?.Notify
    )
    const [showBadge, setShowBadge] = useState(false)

    const [dateDubai, timeDubai] = timeStringInScheduler(
        currentViewing?.attributes?.Datetime ?? ''
    ).split(',')

    let unitNumber, buildName, location

    if (currentViewing) {
        if (viewing?.attributes?.Name) {
            [unitNumber, buildName, location] = viewing?.attributes?.Name?.split(viewing?.attributes?.Name.includes(' <-> ') ? ' <-> ' : '-')
        } else {
            [unitNumber, buildName, location] = [
                'Not number',
                'Not title',
                'Not location'
            ]
        }
    }

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (notifyApp === 'Agent') {
            if (task.type === 'MY UNIT' && viewing?.attributes.User?.data?.id !== Number(userID) && statusNotify === 'Other Agent') {
                setShowBadge(true)
            } else if (task.type === 'BSO UNIT' && viewing?.attributes.User?.data?.id === Number(userID) && (statusNotify === 'Agent' || statusNotify === 'Both')) {
                setShowBadge(true)
            } else if (task.type === 'OTHER AGENT UNIT' && viewing?.attributes.User?.data?.id === Number(userID) && statusNotify === 'Agent') {
                setShowBadge(true)
            }
        } else if (notifyApp === 'BSO' && (statusNotify === 'BSO' || statusNotify === 'Both')) {
            setShowBadge(true)
        }
        task = getBelongsUnitForViewing(currentViewing)
        setStatusNotify(viewing?.attributes?.Notify)
    }, [viewing, dispatch])

    return (

        <div
            key={`${nanoid()}-${viewing.id}`}
            className={`card shadow-sm my-2 d-flex flex-row row g-0 p-1 me-1  align-items-start justify-content-between position-relative border border-1 border-${generateBgAndBorderCol(viewing, true).borderColor} bg-light-${generateBgAndBorderCol(viewing, true).borderColor}`}
        >

            {

                showBadge
                    ? (
                        <span className="position-absolute top-1 start-100 translate-middle badge badge-circle badge-danger">

                            !
                        </span>
                    )
                    : null
            }

            <div className="d-flex flex-column col-8 p-2 ">
                {isDev
                    ? notifyApp !== 'BSO' && <span className={`position-absolute badge  badge-light-${generateBgAndBorderCol(viewing, true).textColor} border border-${generateBgAndBorderCol(viewing, true).borderColor} text-${generateBgAndBorderCol(viewing, true).textColor} p-1`} style={{ top: '-7px', left: '-5px' }}>
                        {task.type}
                    </span>
                    : null}

                <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                    <Icon
                        name={'door_open'}
                        className={''}
                        styles={{ minWidth: '12.9px' }}
                    // iconToFontRatio={1.5}
                    // useWrapper
                    />
                    <span className={'text-dots-app'}> {unitNumber}, {buildName}</span>
                </span>
                <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                    <Icon
                        name={'location_on'}
                        className={''}
                        styles={{ minWidth: '12.9px' }}
                    />
                    <span className={'text-dots-app'}>{location}{' '}</span>
                </span>

                {

                    userType === 'Landlord' || (userType === 'Agent' && currentUnit?.attributes?.Statuses.includes('Door close'))
                        ? <> <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                            <Icon
                                name={'person'}
                                className={''}
                                styles={{ minWidth: '12.9px' }}
                            /><div className="">
                                <span className={'text-dots-app'}>
                                    {currentAgent?.ContactInfo?.FirstName != null
                                        ? `${String(currentAgent?.ContactInfo?.FirstName)} ${String(currentAgent?.ContactInfo?.FamilyName)}`
                                        : 'No name'
                                    }
                                </span>
                                <div className="fs-8 text-gray-700 my-n2">{task.type === 'OTHER AGENT UNIT' ? 'Owner\'s unit' : 'Agent'}</div></div>
                        </span>
                        </>
                        : <span className="fs-6 text-gray-800 d-flex align-items-center gap-2 ">
                            <Icon
                                name={'info'}
                                className={''}
                                styles={{ minWidth: '12.9px' }}
                            />
                            <span className={'text-dots-app '}>
                                {renderViewingStatus(currentUnit?.attributes?.Statuses)}
                            </span>
                        </span>
                }
            </div>
            <div className="d-flex flex-column col-4 p-1 mt-1 text-end">
                <div className="fs-6 d-flex align-items-center justify-content-end">
                    {viewing.attributes?.Statuses?.includes('Rescheduled by BSO') ||
                        viewing.attributes?.Statuses?.includes('Rescheduled by agent') ||
                        viewing.attributes?.Statuses?.includes('Rescheduled by other agent')
                        ? <span
                            className={`me-1 text-end badge h-10px min-w-10px w-10px badge-circle badge-outline badge-${generateBgAndBorderCol(viewing, true).borderColor} bg-light-${generateBgAndBorderCol(viewing, true).borderColor}`}
                        >
                            {' '}
                        </span>
                        : null
                    }

                    <div className="d-flex flex-column">
                        {viewing?.attributes?.Statuses?.map((el: any) => (

                            <div key={nanoid()} className={`fs-1x text-dark lh-1 ${generateBgAndBorderCol(viewing, true).thin}`}>
                                {el !== 'Rescheduled by BSO' && el !== 'Rescheduled by agent' && el !== 'Rescheduled by other agent'
                                    ? <span
                                        className={`me-1 text-end badge h-10px min-w-10px w-10px badge-circle badge-outline badge-${generateBgAndBorderCol(viewing, true).borderColor} bg-light-${generateBgAndBorderCol(viewing, true).borderColor}`}
                                    >
                                        {' '}
                                    </span>
                                    : null}
                                {timeDubai == null && el === 'Rescheduled by BSO'
                                    ? 'Reschedule required'
                                    : el === 'Waiting for complain' ? isThereNotKey ? 'Waiting for keys return' : 'Completed' : el === 'Rescheduled by other agent' ? task.type === 'MY UNIT' ? 'Rescheduled by youself' : 'Rescheduled by agent' : el

                                }
                            </div>
                        ))}
                    </div>
                </div>
                <div className="fs-1x pe-2 text-dark pt-1">
                    {timeDubai
                        ? <div className={'d-flex align-items-center gap-1 justify-content-end'}>
                            <Icon
                                name={'schedule'}
                            />
                            <div>{timeDubai}</div>
                        </div>
                        : <>
                            <div className="fs-8 lh-1 fw-bold border-light-danger text-bold text-danger my-1">Waiting time expired</div>
                        </>
                    }
                </div>
                {/* magic_viewing_modal */}
                <Link

                    to={userTypeLowercase === 'agent'
                        ? currentViewing?.attributes?.Unit?.data?.id && currentViewing?.id ? `/${userTypeLowercase}/modalView/viewing_modal/${currentViewing?.attributes?.Unit?.data?.id}/${currentViewing?.id}/${task.type === 'MY UNIT' ? 'my_unit' : currentUnit?.attributes?.Statuses.includes('Door close') ? 'other_agent_s_unit' : 'calendar'}` : "#"
                        : `/${userTypeLowercase}/modalView/${notifyApp === 'BSO' ? 'magic_' : ''}viewing_modal/${currentViewing?.attributes?.Unit?.data?.id}/${currentViewing?.id}/calendar`
                    }
                    className="text-decoration-none"
                >
                    <div
                        className={`fs-7 pt-1 badge border border-${generateBgAndBorderCol(currentViewing, true).borderColor}`}
                    >
                        GET INFO
                        <Icon
                            name={'expand_more'}
                            className={'text-dark fs-2 ms-1'}
                            useWrapper
                        />
                    </div>
                </Link>
            </div>
        </div>
    )
}
