import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

const fillModalLink = (): string => {
    const { modalID, objectID } = useParams()
    const [searchParams] = useSearchParams()
    let link: string = ''
    switch (modalID) {
        case 'offer_modal':
            link = `/agent/modal/agent_set_offer_modal_${searchParams.get('type') ?? ''}/-/${objectID ?? -1}`
            break
        default:
            break
    }

    return link
}

export default fillModalLink
