import React from 'react'
import { nanoid } from 'nanoid'

interface IProps {
    stepTitle: string
    stepDescription: string
    step: number
    countSteps?: number | undefined
    isShowHint?: boolean | undefined
}
export default function AuthStepper ({ stepTitle, stepDescription, step, countSteps, isShowHint }: IProps) {
    const colorRow = 'bg-active-bso'
    const countLines = countSteps || 5
    return (
        <div className={' my-4 mb-6'} key={nanoid()} >
            <div key={nanoid()} className={'fs-6  mb-2 ps-2'}> <strong>{stepTitle} :</strong> {stepDescription} </div>
            <div className="d-flex align-items-center mb-2 px-2">
                {[...Array(countLines)].map((_, index) => (
                    < >
                        <div className={`${index < step ? 'bg-bso' : 'bg-secondary'}  border rounded-4 w-10px h-10px`}></div>
                        <div
                            className={`flex-grow-1  ${index < step ? `${colorRow}` : 'bg-secondary'} active mx-1 rounded h-4px ${index < countLines ? 'me-1' : ''} !important`}
                        >
                        </div>
                    </>
                ))}
            </div>
            {/* isShowHint && */}
            { <div className={'text-end'}>
                <span className="badge badge-light-secondary text-dark fw-normal fs-8 py-2"> Takes <strong className={'ms-1'}>2 minutes </strong> <i className="bi bi-clock ms-1 text-dark"></i></span>
            </div> }

        </div>

    )
}
