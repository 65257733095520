import { useEffect, useState } from 'react'
import { useAppSelector } from '../redux'
import { type RootState, store } from '../../store/store'
import _ from 'lodash'

const UseEditFilters = (modal: any): { initialState: Record<string, any>, modal: Record<string, any> } => {
    const [filledModal, setFilledModal] = useState(modal)
    const [initialState, setInitialState] = useState<Record<string, any>>({})
    const { filters } = useAppSelector((state: RootState) => state.units)
    const state: Record<string, any> = {}

    useEffect(() => {
        _.forEach(modal[0].form ?? [], (row) => {
            for (const key in filters) {
                if (row.id === key) {
                    state[key] = filters[key]
                    row.default = filters[key]
                }
            }
            /* const selectors = []
            switch (row.id) {
                case 'Bathrooms':
                    for (const unit of store.getState().units.units) {
                        if (!_.includes(selectors, unit.attributes.Bathrooms)) {
                            selectors.push(unit.attributes.Bathrooms)
                        }
                    }
                    break
                case 'Bedrooms':
                    for (const unit of store.getState().units.units) {
                        if (!_.includes(selectors, unit.attributes.Bedrooms)) {
                            selectors.push(unit.attributes.Bedrooms)
                        }
                    }
                    break
                default:
                    break
            }
            if (selectors.length > 0) {
                row.selectors = _.map(
                    _.sortBy(
                        _.filter(selectors, (selector) => selector != null),
                        []
                    ),
                    selector => ({ label: selector, value: selector }))
            } */
        })
        setInitialState(state)
        setFilledModal(modal)
    }, [])

    return ({
        initialState,
        modal: filledModal
    })
}

export default UseEditFilters
