import React, { useEffect, useRef } from 'react'
import { getMonthImage } from '../../../../functions/agent/schedule/getMonthImage'
import { useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { type Unit } from '../../../../store/slices/unitsSlice'
import { nanoid } from 'nanoid'
import { Link, useNavigate } from 'react-router-dom'
import Icon from '../../../../app/icon/Icon'

function RecentlyUploadUnits(): JSX.Element {
    const units = useAppSelector((state: RootState) => state.units.units)
    const navigate = useNavigate()
    const unitsByBSO = units
        .filter((unit) => unit.attributes?.User?.data?.id === Number(process.env.REACT_APP_BSO_USER_ID ?? '57') && unit.attributes?.ManagedBy === 'BSO' && !unit?.attributes?.Statuses?.includes('Sold') && !unit?.attributes?.Statuses?.includes('Drafted') && !unit?.attributes?.Statuses?.includes('Archived') && Boolean(unit?.attributes?.Marketing))
        .sort((a, b) => {
            const dateA = new Date(a.attributes?.createdAt).getTime()
            const dateB = new Date(b.attributes?.createdAt).getTime()
            return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
        })
        .slice(0, 7)
    const scrollContainerRef = useRef<HTMLDivElement>(null)

    const saveScrollPosition = () => {
        if (scrollContainerRef.current != null) {
            localStorage.setItem('scrollPositionHorizontal', scrollContainerRef.current.scrollLeft.toString())
        }
    }

    const restoreScrollPosition = () => {
        const savedPosition = localStorage.getItem('scrollPositionHorizontal')
        if ((scrollContainerRef.current != null) && savedPosition) {
            scrollContainerRef.current.scrollLeft = parseInt(savedPosition, 10)
        }
    }

    const handleScroll = () => {
        saveScrollPosition()
    }

    useEffect(() => {
        restoreScrollPosition()
        if (scrollContainerRef.current != null) {
            scrollContainerRef.current.addEventListener('scroll', handleScroll)
        }

        return () => {
            saveScrollPosition()

            if (scrollContainerRef.current != null) {
                scrollContainerRef.current.removeEventListener('scroll', handleScroll)
            }
        }
    }, [])
    const defaultImage = '/assets/media/istockphoto.jpg'

    return (
        <>
            <div className={'d-flex align-items-center mt-4 mb-1 fs-3 '}>
                {/* text-gray-600 */}
                <span className="fw-bold">Recently uploaded units</span>
                {/* <span className="fw-bold border border-1  separator border-dark ms-3  w-75px">

                </span> */}
            </div>
            {/* scroll */}
            <div
                ref={scrollContainerRef}
                style={{ overflowX: 'auto' }}
                className=" slides-manual d-flex flex-row gap-4 w-100 scrollbar-width overflow-auto pe-4 mb-4">
                {unitsByBSO && unitsByBSO.length > 0 && unitsByBSO.map((unit: Unit, i: number) => {
                    return (
                        <div key={nanoid()} className='position-relative'
                        >
                            <Link
                                to={`/agent/modalView/unit_modal/${unit.id}/not/catalog`}
                                className="text-decoration-none"
                            >

                                <div className={'w-100 h-100 position-absolute z-index-2 rounded rounded-2 '} style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', top: 0 }}>
                                    <span className="ps-4 fw-semi-bold me-2 fs-3x lh-1 text-white position-absolute" style={{ bottom: '70px', left: '5px' }}>
                                        {unit?.attributes?.Marketing?.Headline}
                                    </span>
                                    <div className={'text-white  ps-4'} >
                                        <span className="d-block position-absolute fs-2x fw-semi-bold ms-4" style={{ bottom: '32px', left: '5px' }} >
                                            AED {unit?.attributes?.Marketing?.Price}
                                        </span>
                                        <span className="text-end position-absolute d-block pe-4 fs-5 fw-semi-bold " style={{ bottom: '18px', right: '5px' }} >
                                            <Icon name={'location_on'} filled className={'fs-5 text-white'} />
                                            {unit?.attributes?.Property?.data?.attributes?.Area}
                                        </span>
                                    </div>
                                </div>
                                {unit?.attributes?.Images && unit?.attributes?.Images?.[0]
                                    ? <img
                                        className='w-300px h-175px object-fit-cover rounded rounded-2  opacity-100'
                                        src={unit?.attributes?.Images?.[0]}
                                        alt={'img_unit'}
                                    />
                                    : <img
                                        className='w-300px h-175px object-fit-cover rounded rounded-2  opacity-100'
                                        src={defaultImage}
                                        alt={'img_unit'}
                                    />

                                }

                            </Link>
                        </div>)
                })}
                {unitsByBSO.length < units.length && (
                    <div onClick={() => { navigate('/agent/units/nav'); localStorage.setItem('scrollPositionHorizontal', '0') }} className="w-100 h-175px px-4 py-auto opacity-100 d-flex justify-content-center align-items-center border border-1 border-gray-600 rounded fs-4 fw-semi-bold lh-1 bg-light-secondary"><div >SHOW MORE <i className="bi bi-arrow-return-right fs-2x d-block text-center text-dark"></i></div></div>
                )}

            </div>

        </>
    )
}

export default RecentlyUploadUnits
