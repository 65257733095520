type FetchOptions = RequestInit & {
    headers?: HeadersInit
}
const defaultFetch = window.fetch
export default async function customFetch (url: RequestInfo | URL, options: FetchOptions = {}): Promise<Response> {
    const defaultHeaders: HeadersInit = {}
    const isDevMode = process.env.REACT_APP_IS_DEV === 'true'

    if (typeof url === 'string' && url.includes('bso.ae/api')) {
        if (isDevMode) {
            defaultHeaders['BSO-Dev'] = 'true'
        }
        defaultHeaders['BSO-Hash'] = window.bsotoken()

        options.headers = {
            ...options.headers,
            ...defaultHeaders
        }
    }

    return await defaultFetch(url, options)
}

