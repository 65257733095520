import React from 'react'
import { Link } from 'react-router-dom'
import { type RootState } from '../../../../store/store'
import { useAppSelector } from '../../../../hooks/redux'

export default function ActionLoadEmiratesID (): JSX.Element {
    const { currentUser, loading } = useAppSelector(
        (state: RootState) => state.user
    )
    const userID = currentUser.id

    return (
        <Link
            to={`/agent/modal/update_emirates_front/not/${userID}`}
            className="text-decoration-none"
        >
            <div className="card shadow-sm mb-4 h-80px ">
                <div className="card-body d-flex justify-content-between align-items-center p-1 ">
                    <div className="px-4 py-1 mb-1">
                        <span className="fs-3 text-gray-800 fw-bold d-block text-hover-primary">
                        Upload Emirates ID</span>
                        <span className="fs-6 fw-semibold text-gray-500 lh-1"> Get verify status your account with Emirates ID</span>
                    </div >
                    <div className='align-items-center'>
                        <span className="pe-4">
                            {/* <i className="fs-6x text-dark las la-eye"></i> */}
                            <i className="fs-5x ki-duotone ki-calendar-edit">
                                <i className="path1 "></i>
                                <i className="path2"></i>
                                <i className="path3 "></i>
                            </i>
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    )
}
