import { ACCESS_KEY, urlAPI } from '../../../urls'
import { dataFetchingFinish, dataFetchingStart, setWhereAfterToGo } from '../../slices/modalSlice'
import { type AppDispatch, type RootState } from '../../store'
import { getErrorss } from '../../../functions/errors/GetErrors'
import nanToNull from '../../../functions/adapters/universal/nanToNull'
import dayjs from 'dayjs'
import _ from 'lodash'
import { uploadStrapiFile } from '../fileActions'
import { type IFile } from '../../../types'
import { nanoid } from 'nanoid'

import { getQueryUnits } from '../../../functions/shared/api/queries/getQueryUnits'
import { fetchProperties } from '../propertyActions'
import { setMyDraftUnitsOpen } from '../../slices/unitsSlice'

export const editAgentDraftUnit = (bodyClient: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {

    try {
        dispatch(dataFetchingStart())
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const images: Array<IFile | null> = getState().modal.images
        const storedImages = _.filter(images, (image) => !_.includes(image?.data, 'data:'))
        const notUploadedImages = _.filter(images, (image) => _.includes(image?.data, 'data:'))

        const uploadedImages = await Promise.all(_.map(notUploadedImages, async (image) => {
            const res: Response = await fetch(image?.data ?? '')
            const blob: Blob = await res.blob()
            const file = new File([blob], `unit-image-${nanoid()}`, { type: blob.type })
            return await uploadStrapiFile(file, 'properties', null, `unit-image-${nanoid()}`)
        }))

        const unit = _.cloneDeep(getState().units.units?.find((unit) => unit.id === Number(bodyClient.unitID)))

        const unitBody: any = {
            Marketing: {}
        }
        unitBody.Images = _.map([...storedImages, ...uploadedImages], (image) => image?.data)

        // unit options
        unitBody.Bedrooms = nanToNull(bodyClient.Bedrooms)
        unitBody.Bathrooms = nanToNull(bodyClient.Bathrooms)
        unitBody.SizeArea = nanToNull(bodyClient.AreaSqFt)
        unitBody.Number = bodyClient.Number
        unitBody.GuestBathroom = bodyClient.GuestBathroom
        unitBody.MaidsRoom = bodyClient.MaidsRoom
        unitBody.Features = bodyClient.Features
        unitBody.Amenities = bodyClient.Amenities
        unitBody.Appliances = bodyClient.Appliances
        unitBody.Fit = bodyClient.Fit
        unitBody.View = bodyClient.View
        unitBody.Statuses = unit?.attributes.Statuses ?? []
        if (bodyClient.StatusesMortgage && !unitBody.Statuses.includes('Mortgage')) {
            unitBody.Statuses.push('Mortgage')
        }
        unitBody.OccupiedUntil = dayjs(bodyClient.occupied_until_edit).add(4, 'hours')

        // marketing options

        unitBody.Marketing.Headline = bodyClient?.Marketing?.Headline
        unitBody.Marketing.Description = bodyClient?.Marketing?.Description
        unitBody.Marketing.Price = nanToNull(bodyClient?.Marketing?.Price)


        unitBody.Marketing.Use = bodyClient?.Marketing?.Payments && bodyClient?.Marketing?.Payments > 0 ? "For rent" : "For sale" // bodyClient?.Marketing?.Use
        unitBody.Marketing.Checks = bodyClient?.Marketing?.Payments


        const bodyObject = {
            method: 'PUT',
            collection: 'units',
            id: Number(bodyClient.unitID),
            body: unitBody,
            query: getQueryUnits()
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response?.json() as unknown as any

        if (responseJSON.success) {
            if (responseJSON.response.error != null) {
                dispatch(dataFetchingFinish())
                return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
            } else {
                const response = await fetch(`${urlAPI}`, {
                    method: 'POST',
                    body: JSON.stringify({
                        method: 'PUT',
                        collection: 'properties',
                        id: Number(bodyClient.objectID),
                        body: {
                            Name: (bodyClient)?.Name ?? '',
                            Area: bodyClient?.Area,
                            Type: bodyClient?.Type,
                            GoogleMapsLink: bodyClient?.GoogleMapsLink,
                            MAKANI: bodyClient?.MAKANI,
                            Developer: bodyClient?.Developer,
                            PlotNumber: bodyClient?.PlotNumber
                        }
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                })
                const dataPropertyJSON = await response.json()

                if (dataPropertyJSON.success) {
                    if (dataPropertyJSON.response.error != null) {
                        dispatch(dataFetchingFinish())
                        return { ...dataPropertyJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataPropertyJSON) }
                    } else {
                      

                        await dispatch(fetchProperties())
                        dispatch(dataFetchingFinish())
                        dispatch(setMyDraftUnitsOpen(true))
                        dispatch(setWhereAfterToGo('/agent/my_units'))

                        return {
                            textNavigate: `/agent/modalView/unit_modal/${responseJSON?.response?.data?.id}/${dataPropertyJSON.response?.data?.id}/agent_s_unit`,
                            isSuccessful: true,
                            textNotification: 'Unit was successfully created',
                            title: 'Success'
                        }
                    }
                } else {
                    dispatch(dataFetchingFinish())
                    return { ...dataPropertyJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataPropertyJSON) }
                }
            }
        } else {
            dispatch(dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
        }
    } catch (error) {
        dispatch(dataFetchingFinish())
        console.log(error)
        return {
            isSuccessful: false,
            textNotification: 'Something error'
        }
    }
}
