import { useEffect, useState } from 'react'
import { useAppSelector } from './redux'
import { type RootState } from '../store/store'

interface IOrientation {
    orientation: 'portrait' | 'landscape'
}

const UseOrientation = (): IOrientation => {
    const [orientation, setOrientation] = useState<'portrait' | 'landscape'>('portrait')
    const { fullscreenVideo } = useAppSelector((state: RootState) => state.app)
    useEffect(() => {
        const detect = (): void => {
            const { innerWidth, innerHeight } = window
            if (innerWidth > innerHeight && !fullscreenVideo) {
                setOrientation('landscape')
            } else {
                setOrientation('portrait')
            }
        }
        detect()
        window.addEventListener('resize', detect)
        window.addEventListener('orientationchange', detect)
        return () => {
            window.removeEventListener('resize', detect)
            window.removeEventListener('orientationchange', detect)
        }
    }, [fullscreenVideo])

    return ({
        orientation
    })
}

export default UseOrientation
