import { getQueryCurrentUser } from '../../../functions/shared/api/queries/getQueryCurrentUser'
import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../../urls'
import { userSlice, type User } from '../../slices/userSlice'
import { type AppDispatch, type RootState } from '../../store'


export const userUpdateCountLeaving = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const currentUser = getState().user.currentUser as User
    try {
        const today = new Date().toISOString().split('T')[0]; 
        const loginCounts = ((currentUser?.Stats?.LoginCounts) != null) ? [...currentUser.Stats.LoginCounts] : []; 

        const todayEntryIndex = loginCounts.findIndex((entry: any) => entry.date === today);

        if (todayEntryIndex >= 0) {
            const updatedEntry = { ...loginCounts[todayEntryIndex], count: loginCounts[todayEntryIndex].count + 1 };
            loginCounts[todayEntryIndex] = updatedEntry;
        } else {
            loginCounts.push({ date: today, count: 1 });
        }

        const updatedLoginCounts = loginCounts;
      
        const sendObject = {
            method: 'PUT',
            collection: 'users',
            query: getQueryCurrentUser(),
            id: Number(userID),
            body: {
                Stats: {
                    ...currentUser.Stats,
                    LoginCounts: updatedLoginCounts,
                },
            },
        };
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()

        if (dataJSON.success) {
            if ((!dataJSON.response.error != null)) {
                dispatch(userSlice.actions.userUpdate(dataJSON.response.data))
            }
        }
    } catch (error) {
        console.log(error)
    }
}
