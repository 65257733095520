import { nanoid } from 'nanoid'
import React from 'react'
import { type ISettings } from '../../../../store/slices/userSlice'
import { Tooltip } from 'antd'

interface IProps {
    settings: ISettings[]
}

export default function AgentCreditsBSO ({ settings }: IProps): JSX.Element {
    // console.log(settings, 'settings')

    return (
        <>
            <div className={'d-flex flex-row gap-3'} key={nanoid()}>
                <Tooltip title={'Your total amount of Bricks for Units Advertising in the app'} color={'blue'} key={nanoid()}>
                    <div className="border border-gray-300 border-dashed rounded min-w-80px min-w-150px py-3 px-4"
                        style={{ maxWidth: 'calc(50% - 0.5rem)' }}
                    >
                        <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-credit-cart fs-4x me-1 text-primary">
                                <i className="path1"></i>
                                <i className="path2"></i>
                            </i>
                            <div className="fs-2 fw-bold counted">
                                {settings?.[0]?.MarketingCredits ?? '0'}
                            </div>
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">
                            Bricks
                        </div>
                    </div>
                </Tooltip>
                {/* <Tooltip title={'Your total amount of credits for Broadcasting your own units in the app'} color={'green'} key={nanoid()}>
                    <div className="border border-gray-300 border-dashed rounded min-w-80px min-w-100px py-3 px-4"
                        style={{ maxWidth: 'calc(33% - 0.5rem)' }}
                    >
                        <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-two-credit-cart fs-4x me-1 text-success">
                                <i className="path1"></i>
                                <i className="path2"></i>
                                <i className="path3"></i>
                                <i className="path4"></i>
                                <i className="path5"></i>
                            </i>

                            <div className="fs-2 fw-bold counted">
                                {settings?.[0]?.BroadcastCredits ?? '0'}
                            </div>
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">
                            Broadcast credits
                        </div>
                    </div>
                </Tooltip> */}
                <Tooltip title={'Your total amount of virtual in-app money earned from inviting new referrals in the app'} color={'purple'} key={nanoid()}>
                    <div className="border border-gray-300 border-dashed rounded min-w-80px min-w-150px py-3 px-4"
                        style={{ maxWidth: 'calc(50% - 0.5rem)' }}>
                        <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-bill fs-4x text-info">
                                <i className="path1"></i>
                                <i className="path2"></i>
                                <i className="path3"></i>
                                <i className="path4"></i>
                                <i className="path5"></i>
                                <i className="path6"></i>
                            </i>

                            <div className="fs-2 fw-bold counted">
                                {settings?.[0]?.BSOCredits ?? '0'}
                            </div>
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">
                            BSO credits
                        </div>
                    </div>
                </Tooltip>
            </div>

        </>
    )
}
