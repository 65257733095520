import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../urls'
import { dataFetchingStart, modalSlice } from '../../slices/modalSlice'
import { type AppDispatch, type RootState } from '../../store'

import { addPropertyToStore } from '../../slices/propertySlice'
import nanToNull from '../../../functions/adapters/universal/nanToNull'
import { getQueryUnits } from '../../../functions/shared/api/queries/getQueryUnits'

export const createPropertyForUnit = (bodyClient: any) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    console.log(bodyClient, 'bodyClient')
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            let buildingType = ''
            let isVilla = false
            if ('VillaName' in bodyClient && Boolean(bodyClient.VillaName)) {
                isVilla = true
            } else {
                'ApartmentName' in bodyClient && Boolean(bodyClient?.ApartmentName) ? buildingType = bodyClient?.ApartmentName : buildingType = bodyClient?.CommercialName
            }
            dispatch(dataFetchingStart())
            const bodyObject = {
                method: 'POST',
                collection: 'properties',
                body: {
                    RefID: [isVilla ? bodyClient.VillaName : buildingType, bodyClient.Area, new Date().getTime()]?.join(' <-> '),
                    Name: isVilla ? bodyClient.VillaName : buildingType ?? '',
                    Area: bodyClient?.Area,
                    Type: isVilla ? 'Community' : 'Building',
                    GoogleMapsLink: bodyClient?.GoogleMapsLink,
                    MAKANI: bodyClient?.MAKANI,
                    Developer: bodyClient?.Developer,
                    PlotNumber: bodyClient?.PlotNumber,
                    ManagedBy: Number(userID),
                    ...(buildingType === 'Commercial' ? { AreaType: 'Commercial Office' } : {}),
                    ...(isVilla ? { Community: bodyClient.VillaName } : {})
                }
            }
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(bodyObject),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const dataPropertyJSON = await response.json()

            if (dataPropertyJSON.success) {
                if (dataPropertyJSON.response.error != null) {
                    return { ...dataPropertyJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataPropertyJSON) }
                } else {
                    const bodyObject = {
                        method: 'POST',
                        collection: 'units',
                        body: {
                            RefID: [isVilla ? bodyClient.VillaName : bodyClient.ApartmentName, bodyClient.Area, new Date().getTime()]?.join(' <-> '),
                            User: Number(userID),
                            Property: { id: dataPropertyJSON?.response?.data?.id },
                            Documents: { id: Number(bodyClient?.objectID) },
                            SizeArea: nanToNull(bodyClient?.AreaSqFt),
                            Number: String(getState().property.newPropertyFields?.number ?? ''),
                            Statuses: ['Door close']
                        },
                        query: getQueryUnits()
                    }
                    //  = data?.Marketing?.Payments ? 'For rent' : 'For sale'
                    const response = await fetch(`${urlAPI}`, {
                        method: 'POST',
                        body: JSON.stringify(bodyObject),
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwt}`
                        }
                    })

                    const dataUnitJSON = await response?.json() as unknown as any

                    if (dataUnitJSON.success) {
                        if (dataUnitJSON.response.error != null) {
                            dispatch(modalSlice.actions.dataFetchingFinish())
                            return { ...dataUnitJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataUnitJSON) }
                        } else {
                            dispatch(addPropertyToStore(dataPropertyJSON.response.data))
                            dispatch(modalSlice.actions.dataFetchingFinish())
                            return {
                                ...dataUnitJSON,
                                textNavigate: `/agent/modal/agent_create_unit_options/${dataUnitJSON?.response?.data?.id}/${dataPropertyJSON?.response?.data?.id}`, // ${bodyClient?.objectID}
                                isSuccessful: true,
                                textNotification: 'Property was added successfully next go to additional options',
                                title: 'Success'
                            }
                        }
                    } else {
                        dispatch(modalSlice.actions.dataFetchingFinish())
                        return { textNavigate: '', isSuccessful: false, textNotification: 'Please , try again' }
                    }
                }
            } else {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } catch (e) {
            dispatch(modalSlice.actions.dataFetchingFinish())
            console.log(e)
        }
    }
}
