
import React, { useState } from 'react'
import { Select } from 'antd'
import { Controller } from 'react-hook-form'
import { store } from '../../../store/store'
import { type Unit } from '../../../store/slices/unitsSlice'

export interface ICountUnits {
    value: string
    countUnits: number
}

export default function CustomSelectAreas({ maxAreas, register, control, data, currentAgent }: any): JSX.Element {
    let units = []
    const countUnitsInArea = (): ICountUnits[] => {
        units = store.getState().units.units
        units = [...units]
            .filter((unit) => unit.attributes?.User?.data?.id === Number(process.env.REACT_APP_BSO_USER_ID ?? '57') && unit.attributes?.ManagedBy === 'BSO' && !unit?.attributes?.Statuses?.includes('Sold') && !unit?.attributes?.Statuses?.includes('Drafted') && !unit?.attributes?.Statuses?.includes('Archived') && Boolean(unit?.attributes?.Marketing))

        const areas: any = store.getState().app.config?.all.Area
            .filter((area: string) => area !== 'All areas')
            .sort((a: string, b: string) => a.localeCompare(b));

        const result: ICountUnits[] = []
        for (const key of areas) {
            const newObj = { value: key, countUnits: 0 }
            result.push(newObj)
        }
        units?.reduce((result: ICountUnits[], unit: Unit) => {
            for (const iterator of result) {
                if (unit?.attributes?.Property?.data?.attributes?.Area === iterator.value) { iterator.countUnits += 1 }
            }
            return result
        }, result)
        return result.sort((a, b) => b.countUnits - a.countUnits)
    }



    let optionsMapped = (countUnitsInArea() || []).map((el: ICountUnits) => {
        // const optionsMapped = (data.selectors || []).map((el: string) => {

        return {
            value: el?.value,
            label: (
                <div className='d-flex justify-content-between'>
                    <span>
                        {el.value}
                    </span>
                    {el.countUnits && el.countUnits !== 0
                        ? (
                            <span className='badge border border-primary badge-light-primary  fs-7 mx-2' aria-label={el.value}>
                                <span className='fs-6 me-1'>{el.countUnits} </span>  Unit(s)
                            </span>
                        )
                        : null}

                </div>

            )
        }
    })

    const initValues = data.defaultValue
    if (maxAreas === 'All' as string) {
        //    initValues = ['All areas']

        optionsMapped = [{
            value: 'All areas',
            label: (
                <div className='d-flex justify-content-between'>
                    <span>
                        All areas
                    </span>


                    <span className='badge border border-primary badge-light-primary  fs-7 mx-2' aria-label={'All areas'}>
                        <span className='fs-6 me-1'>{units.length} </span>  Unit(s)
                    </span>

                </div>

            )
        }]
        maxAreas = data.selectors.length
    }

    const [areas, setAreas] = useState<any[]>(initValues)

    const handleSelectChange = (selectedValues: any) => {
        if (selectedValues.length > maxAreas) {
            selectedValues.splice(-1)
        }
        setAreas(selectedValues)
    }

    return (
        (
            <Controller
                name={data.id}
                control={control}
                rules={data.required
                    ? {
                        required: 'Please select your preferred areas to proceed', //  `${data.content?.[0]} is required`
                        validate: (value) => {
                            const selectedAreasCount = Array.isArray(value) ? value.length : 0
                            if (maxAreas !== 'All' && value.length !== Number(maxAreas)) {
                                return `Please select exactly ${maxAreas} area(s)`
                            }
                            return true
                        }
                    }
                    : {}}
                render={({ field }) => (
                    <Select
                        popupClassName="drp-style"
                        listHeight={325}
                        placeholder={data.content && data.content[1]}
                        className="d-block"
                        {...field}
                        size={'large'}
                        options={optionsMapped}
                        onChange={(value) => { handleSelectChange(value); field.onChange(value) }}
                        value={areas}
                        mode={'multiple'}
                        showSearch={false}

                    >
                        {field.value === '' && (
                            <Select.Option value="">{data.content[1]}</Select.Option>
                        )}
                    </Select>
                )}
            />

        )

    )
}
