import { type AppDispatch, type RootState } from '../../store'
import _ from 'lodash'
import { modalSlice } from '../../slices/modalSlice'
import { ACCESS_KEY, urlAPI } from '../../../urls'
import { getErrorss } from '../../../functions/errors/GetErrors'

import { setAdvertises } from '../../slices/advertisesSlice'
import notifyDelay from '../../../functions/shared/notify-helpers/notifyDelay'
import dayjs from 'dayjs'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'
import { getQueryAdvertises } from '../../../functions/shared/api/queries/getQueryAdvertises'

export const checkAForm = (data: Record<string, unknown>, id: number, isApproved: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const advertise = _.find(getState().advertises.advertises, (advertise: any) => advertise.id === id)
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

    const StartDate = isApproved && data.StartDate != null ? dayjs(data.StartDate as any).add(4, 'hours') : null
    const EndDate = isApproved && data.EndDate != null ? dayjs(data.EndDate as any).add(4, 'hours') : null
    const RejectReason = !isApproved ? data?.RejectReason : null

    try {
        dispatch(modalSlice.actions.dataFetchingStart())
        const sendObject = {
            method: 'PUT',
            collection: 'unit-advertises',
            id,
            query: getQueryAdvertises(), // 'populate[User][fields][0]=id&populate[Unit][fields][0]=id',
            body: {
                ...advertise?.attributes,
                Statuses: [isApproved ? 'Confirmed' : 'A Form rejected'],
                StartDate,
                EndDate,
                RejectReason,
                Notify: 'Agent'
            }
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()
        // console.log(dataJSON, 'dataJSON addAgentSettings')
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                let notifyRule: string, notifyMessage: string
                if (isApproved) {
                    notifyRule = 'user-advertise-confirmed'
                    notifyMessage = 'The advertise a form is successfully approved'
                    if (data.StartDate != null && data.EndDate != null) {
                        await sendNotification(notifyDelay(dayjs(data.StartDate as Date).diff(dayjs(), 'millisecond')), Number(advertise?.attributes?.User?.data?.id), id, 'user-advertise-start', 'Advertise started')
                        await sendNotification(notifyDelay(dayjs(data.EndDate as Date).diff(dayjs(), 'millisecond')), Number(advertise?.attributes?.User?.data?.id), id, 'user-advertise-end', 'Advertise started')
                    }
                } else {
                    notifyRule = 'user-advertise-a-form-rejected'
                    notifyMessage = 'The advertise a form is rejected'
                }
                await sendNotification(notifyDelay(), Number(advertise?.attributes?.User?.data?.id), id, notifyRule, notifyMessage)
                dispatch(modalSlice.actions.dataFetchingFinish())
                dispatch(setAdvertises(
                    dataJSON.response.data
                ))
                return { ...dataJSON, textNavigate: -2, isSuccessful: true, textNotification: 'You have successfully reviewed the a form' }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
        dispatch(modalSlice.actions.dataFetchingFinish())
        return { textNavigate: '', isSuccessful: false, textNotification: 'Something went wrong, try again.' }
        //  dispatch(userSlice.actions.userFetchingError(error))
    }
}
