import React, { useState, type Dispatch, type SetStateAction } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { type RootState } from '../../../../../store/store'

import { Drawer, Modal } from 'antd'
import { type ISettings, type User } from '../../../../../store/slices/userSlice'
import Icon from '../../../../../app/icon/Icon'
import CloseIconBasic from '../../../../shared/elements/CloseIconBasic'
import { mainSwalDeal } from '../../../../../functions/swalOptions'
import { ava_blank } from '../../../../../urls'
import Avatar from '../../../../shared/elements/Avatar'
import { updateWallpapers } from '../../../../../store/actionsCreators/userActions/updateWallpapers'
import Markdown from 'react-markdown'
import { setWhereAfterToGo } from '../../../../../store/slices/modalSlice'

interface IProps {
    previewOpen?: boolean
    setPreviewOpen: Dispatch<SetStateAction<boolean>>
    currentUser: User
}

export default function MyProfileNEW({
    previewOpen,
    setPreviewOpen,
    currentUser
}: IProps): JSX.Element {
    const userCONTACTS = (currentUser)?.Type?.[0]
    const userContactsINFO = (currentUser)?.ContactInfo
    const userSettings = currentUser?.Settings as ISettings[]
    const userID = currentUser.id
    const dispatch = useAppDispatch()
    const isLoading = useAppSelector(
        (state: RootState) => state.modal.loading
    )

    const navigate = useNavigate()

    const [imageUrl, setImageUrl] = useState<string>(
        userCONTACTS?.Photo ?? ava_blank
    )
    const licenseApproved = useAppSelector(
        (state: RootState) => state.agentStates.statusesApproved.licenseApproved
    )
    const [wallpaper, setWallpaper] = useState<string>(userSettings?.[0].WallpaperLink ?? "/assets/media/stock/s_default.jpg");
    const [gifStatus, setGifStatus] = useState<string>(userSettings?.[0].GifLink ?? '');
    const [wallpaperModalOpen, setWallpaperModalOpen] = useState<boolean>(false);


    const wallpapers = [
        '/assets/media/stock/s_w1.jpg',
        '/assets/media/stock/s_w2.jpg',
        '/assets/media/stock/s_w3.jpg',
        '/assets/media/stock/s_w4.jpg',
        '/assets/media/stock/s_w5.jpg',
        '/assets/media/stock/s_w6.jpg',
    ];
    const gifs = [
        '/assets/media/gifs/file_1629.160.gif',
        '/assets/media/gifs/file_1630.160.gif',
        '/assets/media/gifs/file_1631.160.gif',
        '/assets/media/gifs/file_1633.160.gif',
        '/assets/media/gifs/file_1635.160.gif',
        '/assets/media/gifs/file_1637.160.gif',
        '/assets/media/gifs/file_1638.160.gif',
        '/assets/media/gifs/file_1640.160.gif',
        // '/assets/media/gifs/file_1644.160.gif',
        '/assets/media/gifs/file_1645.160.gif',
        '/assets/media/gifs/file_1650.160.gif',
        '/assets/media/gifs/file_1651.160.gif',
        '/assets/media/gifs/file_1657.160.gif',
        '/assets/media/gifs/file_1676.160.gif',
        '/assets/media/gifs/file_1679.160.gif',
        '/assets/media/gifs/file_1692.160.gif',
    ];



    const upgradeAvatar = (): void => {
        void mainSwalDeal('Want to change your avatar?', undefined, 'Yes, I do', 'Not now').then(async (result: any) => {
            if (result.isConfirmed === true) {
                dispatch(setWhereAfterToGo('/agent/profile/me'))
                navigate(`/agent/modal/agent_avatar/${userID}/${userID}`)
            }
        })
    }

    let fullName = (userContactsINFO?.FirstName ? userContactsINFO.FirstName : userCONTACTS?.Name) + ' ' +
        (userContactsINFO?.FamilyName ? userContactsINFO.FamilyName : '')
    fullName === 'undefined ' ? fullName = 'No name' : null


    const selectWallpaper = (selectedWallpaper: string) => {
        setWallpaper(selectedWallpaper);
    };


    const languages = userSettings?.[0]?.Languages



    return (
        <>
            <div className="d-flex flex-column bg-white rounded rounded-4 shadow-sm overflow-hidden position-relative">
                <div className="position-relative"
                    style={{
                        backgroundImage: `url(${wallpaper})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',

                    }}>

                    <div className="overlay rounded rounded-4 text-end shadow-sm"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            zIndex: 0,
                            pointerEvents: 'none'
                        }} />

                    <div style={{
                        width: '35px',
                        height: '35px',
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '2px',
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                        zIndex: 2
                    }} onClick={() => { setWallpaperModalOpen(true); }}>
                        <span>
                            <Icon name={'wallpaper_slideshow'} className={'fs-3x text-gray-700 '} />
                        </span>
                    </div>


                    <div className="position-relative d-flex justify-content-start align-items-center"
                        style={{
                            height: '140px',
                            zIndex: 1,
                            padding: '1rem'
                        }}>
                        <div
                            style={{
                                width: '130px',
                                height: '130px',
                                borderRadius: '50%',
                                overflow: 'hidden',
                                border: '4px solid white',
                                position: 'absolute',
                                bottom: '-70px',
                                left: '20px'
                            }}
                            onClick={() => { setPreviewOpen(true); }}
                        >
                            <Avatar image={imageUrl ?? undefined} size={'100%'} />
                        </div>
                    </div>
                    <div className='bg-gray-200' style={{
                        width: '35px',
                        height: '35px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '140px',
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                        zIndex: 2
                    }} onClick={() => { navigate(`/agent/modal/profile_modal/${currentUser.id}/${currentUser.id}`); }}>
                        <span>
                            <Icon name={'edit_square'} className={'fs-3x text-gray-700'} />
                        </span>
                    </div>
                </div>

                <div className="p-4 pt-0" style={{ marginTop: '80px' }}>
                    <div className="d-flex align-items-center text-gray-800 text-hover-primary fs-3 fw-bold" style={{ wordBreak: 'break-word' }}>
                        {fullName}   <span className={'d-flex align-items-center ps-1'}>
                            {gifStatus && <img src={gifStatus} alt="" className="h-25px w-25px ms-2 rounded" />}

                            {/* {licenseApproved
                                ? <Icon name={'verified'} className={'fs-1 text-warning'} />
                                : <Icon name={'verified'} className={'fs-1 text-primary'} />
                            } */}

                        </span>
                    </div>
                    <span className="text-gray-600 fw-semibold d-block fs-5">{userSettings?.[0].Company ?? ""}</span>

                    {/* {(languages != null) && languages.length > 0 ?
                        <div className={'d-flex flex-wrap align-items-center gap-2 w-100 my-1 text-gray-200 '}>
                            <i className="las la-globe-europe fs-2x"></i>
                            {languages.map((lang) => (
                                <div key={lang} className={'d-flex gap-1 align-items-center '}>
                                    <div className={'badge p-1 fs-8 badge-light border border-light-dark'}>
                                        {lang}
                                    </div>
                                </div>
                            ))
                            }
                        </div> : null} */}


                    {Boolean(currentUser.About) &&

                        <div className={'rounded rounded-sm bg-gray-200 p-2 pt-4 ps-4 mt-4 lh-1'}>
                            <div className='fw-semi fs-6 text-gray-700 markdown-container mb-1 fw-semibold ls-1' style={{ textIndent: "1em" }}>
                                <Markdown >
                                    {currentUser.About ?? "Hey there! I am using BSO Club!"}
                                </Markdown>
                            </div>
                        </div>
                    }

                </div>
            </div>
            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'auto'}
                styles={{
                    body: {
                        maxHeight: '88vh',
                        marginBottom: '3px',
                        paddingTop: '3px',
                        marginTop: '6px',
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }
                }}
                placement={'bottom'}
                closable={false}
                onClose={() => { setWallpaperModalOpen(false); }}
                open={wallpaperModalOpen}
                rootStyle={{ zIndex: '1100' }}
                key={'select-wallpaper-driver'}
                maskClosable={true}
            >
                <div key={'wallpaper-driver'}
                    className="content d-flex flex-column flex-fill p-0 m-1 px-2"
                >
                    <div className="w-100 mw-450px  mx-auto">

                        <div className={'text-gray-900 fs-7 fw-bold text-end'}>Wallpapers</div>
                        <div className="d-flex flex-wrap">
                            {wallpapers.map((wp, index) => (
                                <div
                                    key={index}
                                    className="my-3 mx-auto"
                                    style={{
                                        cursor: 'pointer',
                                        border: wallpaper === wp ? '4px solid #fff100B3' : 'none',
                                        borderRadius: '1rem'
                                    }}
                                    onClick={() => { selectWallpaper(wp); }}
                                >
                                    <img
                                        src={wp}
                                        alt={`wallpaper - ${index}`}
                                        style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '0.5rem' }}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className={'text-gray-900 fs-7 fw-bold text-end mb-2 pb-2'}>Gifs animations statuses</div>
                        <div className="d-flex flex-wrap gap-8" >
                            {gifs.map((gf, index) => (
                                <div
                                    key={index}
                                    className="gif-item  mx-auto px-auto"
                                    style={{
                                        cursor: 'pointer',
                                        border: gifStatus === gf ? '4px solid #fff100B3' : '2px solid transparent', // Сделал рамку прозрачной по умолчанию
                                        borderRadius: '1rem',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        boxSizing: 'border-box',
                                        overflow: 'hidden'

                                    }}
                                    onClick={() => { setGifStatus(gf); }}
                                >
                                    <img
                                        src={gf}
                                        alt={`gif - ${index}`}
                                        style={{ width: '40px', height: '40px', objectFit: 'cover', borderRadius: '0.5rem' }}

                                    />
                                </div>
                            ))}
                        </div>


                        <div
                            className={'btn btn-bso my-4 py-2 border-bso2px w-100 d-flex justify-content-center align-items-center'}
                            onClick={async () => {
                                const isLoaded = await dispatch(updateWallpapers({ wallpaper, gifStatus }));
                                if (isLoaded.isConfirmed === true) {
                                    setWallpaperModalOpen(false)
                                }
                            }}
                        >
                            {isLoading
                                ? <span className="indicator-progress d-block">
                                    Please wait...
                                    <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                </span>
                                : <span className="indicator-label">
                                    Update
                                </span>
                            }
                        </div>
                    </div>
                </div >
            </Drawer >
            <Modal
                open={previewOpen}
                footer={null}
                onCancel={() => {
                    setPreviewOpen(false)
                }}

                closeIcon={
                    <CloseIconBasic onCancel={() => {
                        setPreviewOpen(false)
                    }} cls={''} style={{ top: '-5px', right: '-5px' }} />
                }
                centered
                styles={{
                    body: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '50vh'
                    }
                }}
            >
                <img
                    alt="example"
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    src={imageUrl}
                    className='position-relative object-fit-cover w-100'
                />
                <div style={{ top: '11px', left: '12px' }} className='position-absolute' onClick={upgradeAvatar}>
                    <div className={'p-2'} style={{ borderRadius: '1rem', background: '#00000066' }}>
                        <i className="ki-duotone ki-pencil fs-2x p-1">
                            <span className="path1 text-white"></span><span className="path2 text-white"></span>
                        </i>

                    </div>

                </div>
            </Modal>



        </>
    )
}
