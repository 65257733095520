import React, { useRef } from 'react'
import {
    type IOrder,
    type ISettings
} from '../../../../store/slices/userSlice'
import { mounthes } from '../../../../functions/agent/date/timeString'
import Icon from '../../../../app/icon/Icon'
import { nanoid } from 'nanoid'

interface IProps {
    orders: IOrder[]
}

export default function HistoryList ({ orders }: IProps): JSX.Element {
    // const ServiceAgentSubscription = (currentUser.Orders as IOrder[])?.filter(
    //     (order: IOrder) => order.Service.some((service) => service.__component === 'services.broker-subscription')
    // )
    // ServiceAgentSubscription?.sort((a, b) => new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime())

    // const latestServiceAgentSubscription = ServiceAgentSubscription?.[0]
    // console.log(latestServiceAgentSubscription, 'latestServiceAgentSubscription')
    // const packageTitle = latestServiceAgentSubscription?.Service?.[0]?.Package
    // console.log(packageTitle, 'packageTitle')
    // const activeSub = packageTitle?.includes('Premium') ? 'Premium' : packageTitle?.includes('Standard') ? 'Standard' : packageTitle?.includes('Free') ? 'Free' : '' // 'free'
    // const activeSubPeriod = latestServiceAgentSubscription?.Name?.includes('(Yearly)') ? 'annual' : latestServiceAgentSubscription?.Name?.includes('(Weekly)') ? 'weekly' : 'month'

    return (
        <div className="table px-2" >
            <table className="table align-middle table-row-dashed fs-6 gy-4 mb-0 gx-2">
                <thead>
                    <tr className="border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                        <th className="min-w-100px max-w-100px">Name</th>
                        <th className="min-w-50px max-w-50px">Date</th>
                        <th className="min-w-70px max-w-70px">Amount</th>
                        <th className="text-end min-w-30px max-w-30px">
                            Actions
                        </th>
                    </tr>
                </thead>

                <tbody className="fw-semi-bold text-gray-800" key={nanoid()}>
                    {orders &&
                        orders.map((ord) => {
                            const orderName = ord?.Name ?? ''
                            let name = ord?.Name
                            let desc = ''
                            if (orderName.split('(').length > 0) {
                                name = orderName.split('(')[0]
                                desc = orderName.split('(')[1]
                                    ? orderName
                                        .split('(')[1]
                                        ?.slice(
                                            0,
                                            orderName.split('(')[1]?.length -
                                                  1
                                        )
                                    : 'Basic bundle'
                            }

                            if (name?.includes('_') && (name?.includes('Free') || name?.includes('Standard') || name?.includes('Premium'))) {
                                name = name.split('_')?.[2] ?? name
                                desc = 'Subscription'
                            }

                            const createdAtDate = ord?.createdAt
                                ? new Date(ord?.createdAt)
                                : null

                            return (
                                <tr key={ord?.id}>
                                    <td>
                                        <label className="min-w-100px mw-125px  lh-1">
                                            {/* {ord?.Service?.[0]?.Package ?? ord?.Name} */}
                                            {name}
                                        </label>
                                        <div className="fw-normal text-gray-600">
                                            {desc}
                                        </div>
                                    </td>

                                    {createdAtDate != null && (
                                        <>
                                            <td className="text-start">
                                                <label className="min-w-40px">
                                                    {mounthes[
                                                        createdAtDate.getMonth()
                                                    ].slice(0, 3)}{' '}
                                                    {createdAtDate
                                                        .getDate()
                                                        .toString()
                                                        .padStart(2, '0')}
                                                </label>
                                                <div className="fw-normal text-gray-600">
                                                    {' '}
                                                    {createdAtDate
                                                        .getFullYear()
                                                        .toString()}
                                                </div>
                                            </td>
                                        </>
                                    )}
                                    <td className="text-start">
                                        <label>{ord?.Amount}</label>
                                        <div className="fw-normal text-gray-600">
                                            AED
                                        </div>
                                    </td>
                                    <td className="text-end">
                                        <div className="d-flex flex-column">
                                            {/* window.open(createOrder?.url, '_self') */}
                                            <span
                                                onClick={
                                                    ord?.InvoiceURL !== null
                                                        ? () =>
                                                            window.open(
                                                                ord?.InvoiceURL as string,
                                                                '_self'
                                                            )
                                                        : undefined
                                                }
                                                className={`${
                                                    ord?.InvoiceURL !== null
                                                        ? ' btn-color-primary btn-active-color-primary'
                                                        : 'btn-color-muted'
                                                } btn btn-icon btn-sm me-3 d-flex align-items-center`}
                                            >
                                                <span>Invoice{' '}</span>
                                                <Icon
                                                    name={'print'}
                                                    className={
                                                        'fs-3 text-muted ms-1 d-flex justify-content-center align-items-center'
                                                    }
                                                    useWrapper
                                                />{' '}
                                            </span>

                                            <span
                                                onClick={
                                                    ord?.ReceiptURL !== null
                                                        ? () =>
                                                            window.open(
                                                                ord?.ReceiptURL as string,
                                                                '_self'
                                                            )
                                                        : undefined
                                                }
                                                className={`${
                                                    ord?.ReceiptURL !== null
                                                        ? 'btn-color-primary  btn-active-color-primary'
                                                        : 'btn-color-muted'
                                                } btn btn-icon  btn-sm me-3 d-flex align-items-center`}
                                            >
                                                <span>Receipt{' '}</span>
                                                <Icon
                                                    name={'mail'}
                                                    className={
                                                        'fs-3 text-muted ms-1 d-flex justify-content-center align-items-center'
                                                    }
                                                    useWrapper
                                                />{' '}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                </tbody>
            </table>
        </div>
    )
}
