import { createSlice } from '@reduxjs/toolkit'
interface UnitsState {
    filters: Array<{
        id: string
        value: string
    }>
    filterNavigate: 'viewings' | 'offers' | 'attention' | 'referrals' | 'adverts' | 'broadcasts'
}

const initialState: UnitsState = {
    filters: [],
    filterNavigate: 'attention'
}
export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        scheduleSetFilters (state, action) {
            state.filters = action.payload
        },
        setFilterNavigate (state, action) {
            state.filterNavigate = action.payload
        }
    }
})

export const { scheduleSetFilters, setFilterNavigate } = scheduleSlice.actions

export default scheduleSlice.reducer
