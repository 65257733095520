import { getCurrentDubaiDate } from '../../agent/date/getCurrentDateStrFormat'

export const checkDubaiDateTime = (checkDate: string): string => {
    const currentDubaiDate = getCurrentDubaiDate()
    const dateObj = new Date(checkDate)
    const checkDubaiUtcTime = dateObj.getTime() + (dateObj.getTimezoneOffset() * 60000) // Время из параметра функции в UTC+0
    const checkDubaiDate = new Date(checkDubaiUtcTime)

    if (checkDubaiDate.toDateString() === currentDubaiDate.toDateString()) {
        return 'Today'
    } else if (checkDubaiDate < currentDubaiDate) {
        return 'Past'
    } else {
        return 'Future'
    }
}