import { type IViewing } from '../../../store/slices/viewingsSlice'

export const isCheckFeedbackTakePlace = (viewing: IViewing): boolean => {
    return Boolean((viewing?.attributes?.Statuses?.includes('Waiting for complain') && viewing?.attributes?.Feedback?.includes('Viewing take place')) || (viewing?.attributes?.Statuses?.includes('Completed') && viewing?.attributes?.Feedback && viewing?.attributes?.Feedback?.includes('Viewing take place')))
}

export const isCheckFeedbackTakeNotPlace = (viewing: IViewing): boolean => {
    return Boolean((viewing?.attributes?.Statuses?.includes('Waiting for complain') && viewing?.attributes?.Feedback?.includes('Viewing did not take place')) || (viewing?.attributes?.Statuses?.includes('Completed') && viewing?.attributes?.Feedback && viewing?.attributes?.Feedback?.includes('Viewing did not take place')))
}

export const isCheckFeedbackWasOkay = (viewing: IViewing): boolean => {
    return Boolean((viewing?.attributes?.Statuses?.includes('Waiting for complain') && viewing?.attributes?.Feedback?.includes('Viewing was okay')) || (viewing?.attributes?.Statuses?.includes('Completed') && viewing?.attributes?.Feedback && viewing?.attributes?.Feedback?.includes('Viewing was okay')))
}

export const isCheckFeedbackWasNotOkay = (viewing: IViewing): boolean => {
    return Boolean((viewing?.attributes?.Statuses?.includes('Waiting for complain') && viewing?.attributes?.Feedback?.includes('Viewing was not okay')) || (viewing?.attributes?.Statuses?.includes('Completed') && viewing?.attributes?.Feedback && viewing?.attributes?.Feedback?.includes('Viewing was not okay')))
}
