export const getCorrectName = (str: string): string => {
    switch (str) {
        case 'documents.emirates-id':
            return 'Emirates ID'
        case 'documents.rera-card':
            return 'RERA Card'
        case 'documents.passport':
            return 'Passport'

        default:
            return ''
    }
}
