import React from 'react'
import { useSearchParams } from 'react-router-dom'
import MyUnitButtons from './MyUnitButtons'
import BSOUnitButtons from './BSOUnitButtons'
import useUserType from '../../../../hooks/UseUserType'

const UnitButtons = ({ unit, sentBy }: { unit: any, sentBy: string }): JSX.Element => {
    const { userType } = useUserType()
    const [searchParams] = useSearchParams()

    return (
        userType === 'Landlord'
            ? searchParams.get('source') === 'my-units'
                ? <MyUnitButtons />
                : <BSOUnitButtons unit={unit} />
            : <BSOUnitButtons unit={unit} sentBy={sentBy} />
    )
}

export default UnitButtons
