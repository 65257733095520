import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../hooks/redux'
import useUserType from '../../../hooks/UseUserType'
import AgentInfoForChildren from './contactInfo/AgentInfoForChildren'
import { removeOfferNotify } from '../../../store/actionsCreators/offerActions'
import OfferTimeline from './offerTimelines/OfferTimeline'
import { type Unit } from '../../../store/slices/unitsSlice'
import { type IOffer } from '../../../store/slices/offersSlice'
import OfferClientInfoForChildren from './contactInfo/OfferClientInfoForChildren'
import CopyClientInfo from './contactInfo/CopyClientInfo'
import { offerAdminViewStatuses } from '../../../constants'
import renderOfferStatus from "../../../functions/agent/offers/renderOfferStatus";
import Icon from "../../icon/Icon";

interface IProps {
    currentUnit: Unit
    offer: any
    currentOffer: IOffer
}

export default function ChildOffer({ offer, currentUnit, currentOffer }: IProps): JSX.Element {

    const { userType } = useUserType()
    const dispatch = useAppDispatch()

    useEffect(() => {
        const agentViewStatuses = ['Completed', 'Approved', 'Rejected', 'Canceled', 'Canceled by other agent', 'Confirmed', 'Signing F form', 'Signing other documents', 'Obtaining NOC', 'Downpayment', 'Transfer process', 'Waiting booking form sign']
        const otherAgentViewStatuses = ['Canceled', 'Canceled by other agent', 'Completed', 'Approved', 'Rejected']

        const landlordViewStatuses = offerAdminViewStatuses
        const agentNotifyBothNotViewStatuses = ['Negotiated by landlord']
        if (userType === 'Agent' && offer?.Notify === 'Agent' && agentViewStatuses.includes(offer?.Statuses?.[0])) {
            dispatch(removeOfferNotify(offer.id))
        } else if (userType === 'Agent' && (offer?.Notify === 'Other Agent') && otherAgentViewStatuses.includes(offer?.Statuses?.[0])) {
            dispatch(removeOfferNotify(offer.id, 'Agent'))
        }

        if (offer.Notify === 'Both' && !agentNotifyBothNotViewStatuses.includes(offer?.Statuses?.[0])) {
            if (userType === 'Agent') {
                dispatch(removeOfferNotify(offer.id, 'BSO'))
            } else if (landlordViewStatuses?.includes(offer?.Statuses?.[0])) {
                dispatch(removeOfferNotify(offer.id, 'Agent'))
            }
        } if (userType === 'Landlord' && offer?.Notify === 'BSO' && landlordViewStatuses?.includes(offer?.Statuses?.[0])) {
            dispatch(removeOfferNotify(offer?.id))
        }
    }, []) // offer, userType, dispatch


    const [offerKey, setOfferKey] = useState(0)

    useEffect(() => {
        setOfferKey((prevKey) => prevKey + 1)
    }, [currentOffer])





    return (
        <>
            <div className="fv-row mb-2 m-0 p-0 w-100">
                <div className="d-flex align-items-center">
                    <div className="fs-3 fw-bolder text-dark mb-4 lh-1">Offer Information</div>
                    <div className="fv-row mb-2 m-0 p-0 w-100 text-end">
                        <label className="form-label fs-5 fw-bold text-dark m-0">
                            Offer status
                        </label>
                        <div className="fw-normal text-dark mb-2">
                            <div className="badge badge-light-primary border border-primary fs-6 my-1">
                                {
                                    renderOfferStatus(offer.Statuses?.[0] ?? '', offer.id)
                                }
                            </div>
                        </div>

                        {offer.RejectReason && (offer.Statuses[0] === 'Rejected' || offer.Statuses[0] === 'Canceled by other agent')
                            ? <div className="fv-row mb-2 m-0 p-0 w-100">
                                <label className="form-label fs-5 fw-bold text-dark m-0">
                                    Reject reason
                                </label>
                                <div className="fw-normal text-dark mb-2">
                                    <div className="badge badge-light-primary border border-primary fs-6 my-1">
                                        {offer.RejectReason}
                                    </div>
                                </div>
                            </div>
                            : <></>
                        }
                        {offer?.Type?.[0]?.DepositSlipLink != null
                            ? <div className="fv-row mb-2 m-0 p-0 w-100">
                                <label className="form-label fs-5 fw-bold text-dark m-0">
                                    Deposit confirmation
                                </label>
                                <div className="fw-normal text-dark mb-2">
                                    <a href={offer?.Type?.[0]?.DepositSlipLink} target={'_blank'} rel="noreferrer">
                                        <div className="badge badge-light-primary border border-primary fs-6 my-1">
                                            Link
                                        </div>
                                    </a>
                                </div>
                            </div>
                            : <></>
                        }
                    </div>
                </div>
                <OfferTimeline key={offerKey} offer={currentOffer} unit={currentUnit} />
                <div className="fv-row my-2  p-2 w-100">
                    <div className="separator border border-bso border-3 px-2 my-2"></div>
                </div>
                { currentOffer?.attributes?.FitsSpecialRequest != null
                    ? <>
                        <div className={'d-flex gap-4 align-items-center'}>
                            {
                                currentOffer?.attributes?.FitsSpecialRequest
                                    ? <>
                                        <Icon name={'check_circle'} className={'fs-4x text-success'}/>
                                        <div className={'fs-5'}>Offer fits unit requirements</div>
                                    </>
                                    : <>
                                        <Icon name={'cancel'} className={'fs-4x text-danger'}/>
                                        <div className={'fs-5'}>Offer doesn&apos;t fit unit requirements</div>
                                    </>
                            }
                        </div>
                        <div className="fv-row my-2  p-2 w-100">
                            <div className="separator border border-bso border-3 px-2 my-2"></div>
                        </div>
                    </>
                    : <></>
                }
            </div>

            <OfferClientInfoForChildren offerID={offer?.id} />
            <CopyClientInfo offerID={offer?.id} />
            {userType === 'Landlord' // || isMyUnit
                ? <>
                    <AgentInfoForChildren userID={offer?.User?.data?.id ?? -1} />
                    <div className="fv-row my-2  p-2 w-100">
                        <div className="separator border border-bso border-3 px-2 my-2"></div>
                    </div>

                    {/* <ContactAgent userID={offer?.User?.data?.id ?? -1} /> */}
                </>
                : <></>
            }
        </>
    )
}
