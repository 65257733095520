import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../../../../app/icon/Icon'

export default function ActionMakeOffer (): JSX.Element {
    return (
        <Link
            to={'/agent/units/makeOffer'}
            className="text-decoration-none"
        >
            <div className="card shadow-sm mb-4 h-80px">
                <div className="card-body d-flex justify-content-between align-items-center p-1 ">
                    <div className="px-4 py-1 mb-1">
                        {/* Make offer */}
                        <span className="fs-3 text-gray-800 fw-bold d-block text-hover-primary">
                        MAKE OFFER
                        </span>
                        <span className="fs-5 fw-semi-bold text-gray-500"> Make a deal, close the deal, and get your commission</span>

                    </div >
                    <div className='align-items-center'>
                        <span className="pe-4">
                            <Icon name={'wallet'} filled={true} className={'fs-6x mx-auto text-gray-700'}/>
                        </span>

                    </div>
                </div>
            </div>

        </Link>
    )
}
