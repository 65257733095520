

import { error } from 'console'
import { getErrorss, getMessageForError } from '../../../functions/errors/GetErrors'
import { getQueryCurrentUser } from '../../../functions/shared/api/queries/getQueryCurrentUser'
import { swalError, swalSuccess } from '../../../functions/swalOptions'
import { urlAPI, ACCESS_KEY, USER_ID_KEY } from '../../../urls'

import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'
import { type ISettings,  userSlice } from '../../slices/userSlice'
import { type RootState, type AppDispatch } from '../../store'
import { uploadStrapiFile } from '../fileActions'

export const updateWallpapers = (formValues: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)

    let linkWallpaper = ""
    let linkGif = ""  
    try {
        dispatch(dataFetchingStart())
        if(formValues?.wallpaper?.includes('/assets/media/stock/s_')) {
            linkWallpaper = formValues?.wallpaper
            if (Boolean(formValues?.gifStatus) && formValues?.gifStatus?.includes('.160.gif')) {
                console.log(formValues?.gifStatus,"formValues?.gifStatus")
                linkGif = formValues?.gifStatus
            }
        } else {
            const file = formValues?.wallpaper?.[0]  
            const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];

            if (!validImageTypes.includes(file?.type)) {
                dispatch(dataFetchingFinish())
                return await swalError(getErrorss(getMessageForError("It's wrong image type")));
            }
            const uploadedFile = await uploadStrapiFile(formValues?.wallpaper?.[0])
            if (uploadedFile?.data) {
                linkWallpaper = uploadedFile?.data
            }
        }
       
        const currentUser = getState().user.currentUser
        const userSettings = (currentUser.Settings as ISettings[] | null)?.at(0)
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify({
                method: 'PUT',
                collection: 'users',
                id: Number(userID),
                query: getQueryCurrentUser(),
                body: {
                    ...currentUser,
                    Settings:   [{
                        ...userSettings,
                        __component: 'settings.agent',
                        WallpaperLink: linkWallpaper,
                        GifLink : linkGif
                    }]  
                }
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
       
        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(userSlice.actions.userFetchingSuccess(dataJSON.response))
                dispatch(dataFetchingFinish())
                return await swalSuccess("The wallpapers has been successfully changed")

            }
        } else {
            dispatch(dataFetchingFinish())
            await swalError(getErrorss(dataJSON))               
        }
        
    } catch (error) {
        console.log(error)
        dispatch(dataFetchingFinish())
        await swalError(getErrorss('Unknown error occurred, try again'))  
    }
}

// export const deleteWallpapers  = () => async (dispatch: AppDispatch, getState: () => RootState) => {
//     const jwt = window.localStorage.getItem(ACCESS_KEY)
//     const userID = window.localStorage.getItem(USER_ID_KEY)
//     const { currentUser } = getState().user

//     try {
//         dispatch(deletingSomething(true))
//         const userType = { ...(currentUser as User).Type, Photo: null, __component: 'contacts.individual' }
//         const sendObject = {
//             method: 'PUT',
//             collection: 'users',
//             id: Number(userID),
//             query: getQueryCurrentUser(),
//             // query: queryUSER,
//             body: {
//                 Type:
//                  [userType]
//             }
//         }

//         const response = await fetch(`${urlAPI}`, {
//             method: 'POST',
//             body: JSON.stringify(sendObject),
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `Bearer ${jwt}`
//             }
//         })
//         const dataJSON = await response.json()
//         //  console.log(dataJSON, 'dataJSON')
//         if (dataJSON.success) {
//             if (dataJSON.response.error != null) {
//                 dispatch(deletingSomething(false))
//                 return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
//             } else {
//                 dispatch(userSlice.actions.userFetchingSuccess(dataJSON.response))
//                 dispatch(deletingSomething(false))
//                 return { ...dataJSON, textNavigate: '/agent/settings', isSuccessful: true, textNotification: 'The avatar was deleted' }
//             }
//         } else {
//             dispatch(deletingSomething(false))
//             return { textNavigate: '', isSuccessful: false, textNotification: '' }
//         }
//     } catch (error) {
//         console.log(error)
//         dispatch(deletingSomething(false))
//     }
// }
