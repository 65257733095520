export function getCountries (): string[] {
    const countries = [
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia and Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Central African Republic',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Republic of the Congo',
        'Democratic Republic of the Congo',
        'Costa Rica',
        'Cote d`Ivoire',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'East Timor',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Fiji',
        'Finland',
        'France',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        'North Korea',
        'South Korea',
        'Kosovo',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Federated States of Micronesia',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Morocco',
        'Mozambique',
        'Myanmar',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Qatar',
        'Romania',
        'Russia',
        'Rwanda',
        'Saint Kitts and Nevis',
        'Saint Lucia',
        'Saint Vincent and the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome and Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States of America',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Vatican City (Holy See)',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Zambia',
        'Zimbabwe'
    ]
    return countries
}

export const getCountryCodes = (shortCode: string): string => {
    const countryCodes =
{
    AFG: 'Afghanistan',
    ALB: 'Albania',
    DZA: 'Algeria',
    AND: 'Andorra',
    AGO: 'Angola',
    ATG: 'Antigua and Barbuda',
    ARG: 'Argentina',
    ARM: 'Armenia',
    AUS: 'Australia',
    AUT: 'Austria',
    AZE: 'Azerbaijan',
    BHS: 'Bahamas',
    BHR: 'Bahrain',
    BGD: 'Bangladesh',
    BRB: 'Barbados',
    BLR: 'Belarus',
    BEL: 'Belgium',
    BLZ: 'Belize',
    BEN: 'Benin',
    BTN: 'Bhutan',
    BOL: 'Bolivia',
    BIH: 'Bosnia and Herzegovina',
    BWA: 'Botswana',
    BRA: 'Brazil',
    BRN: 'Brunei',
    BGR: 'Bulgaria',
    BFA: 'Burkina Faso',
    BDI: 'Burundi',
    KHM: 'Cambodia',
    CMR: 'Cameroon',
    CAN: 'Canada',
    CPV: 'Cape Verde',
    CAF: 'Central African Republic',
    TCD: 'Chad',
    CHL: 'Chile',
    CHN: 'China',
    COL: 'Colombia',
    COM: 'Comoros',
    COG: 'Republic of the Congo',
    COD: 'Democratic Republic of the Congo',
    CRI: 'Costa Rica',
    CIV: 'Cote d`Ivoire',
    HRV: 'Croatia',
    CUB: 'Cuba',
    CYP: 'Cyprus',
    CZE: 'Czech Republic',
    DNK: 'Denmark',
    DJI: 'Djibouti',
    DMA: 'Dominica',
    DOM: 'Dominican Republic',
    TLS: 'East Timor',
    ECU: 'Ecuador',
    EGY: 'Egypt',
    SLV: 'El Salvador',
    GNQ: 'Equatorial Guinea',
    ERI: 'Eritrea',
    EST: 'Estonia',
    ETH: 'Ethiopia',
    FJI: 'Fiji',
    FIN: 'Finland',
    FRA: 'France',
    GAB: 'Gabon',
    GMB: 'Gambia',
    GEO: 'Georgia',
    DEU: 'Germany',
    GHA: 'Ghana',
    GRC: 'Greece',
    GRD: 'Grenada',
    GTM: 'Guatemala',
    GIN: 'Guinea',
    GNB: 'Guinea-Bissau',
    GUY: 'Guyana',
    HTI: 'Haiti',
    HND: 'Honduras',
    HUN: 'Hungary',
    ISL: 'Iceland',
    IND: 'India',
    IDN: 'Indonesia',
    IRN: 'Iran',
    IRQ: 'Iraq',
    IRL: 'Ireland',
    ISR: 'Israel',
    ITA: 'Italy',
    JAM: 'Jamaica',
    JPN: 'Japan',
    JOR: 'Jordan',
    KAZ: 'Kazakhstan',
    KEN: 'Kenya',
    KIR: 'Kiribati',
    PRK: 'North Korea',
    KOR: 'South Korea',
    XKX: 'Kosovo',
    KWT: 'Kuwait',
    KGZ: 'Kyrgyzstan',
    LAO: 'Laos',
    LVA: 'Latvia',
    LBN: 'Lebanon',
    LSO: 'Lesotho',
    LBR: 'Liberia',
    LBY: 'Libya',
    LIE: 'Liechtenstein',
    LTU: 'Lithuania',
    LUX: 'Luxembourg',
    MKD: 'Macedonia',
    MDG: 'Madagascar',
    MWI: 'Malawi',
    MYS: 'Malaysia',
    MDV: 'Maldives',
    MLI: 'Mali',
    MLT: 'Malta',
    MHL: 'Marshall Islands',
    MRT: 'Mauritania',
    MUS: 'Mauritius',
    MEX: 'Mexico',
    FSM: 'Federated States of Micronesia',
    MDA: 'Moldova',
    MCO: 'Monaco',
    MNG: 'Mongolia',
    MNE: 'Montenegro',
    MAR: 'Morocco',
    MOZ: 'Mozambique',
    MMR: 'Myanmar',
    NAM: 'Namibia',
    NRU: 'Nauru',
    NPL: 'Nepal',
    NLD: 'Netherlands',
    NZL: 'New Zealand',
    NIC: 'Nicaragua',
    NER: 'Niger',
    NGA: 'Nigeria',
    NOR: 'Norway',
    OMN: 'Oman',
    PAK: 'Pakistan',
    PLW: 'Palau',
    PAN: 'Panama',
    PNG: 'Papua New Guinea',
    PRY: 'Paraguay',
    PER: 'Peru',
    PHL: 'Philippines',
    POL: 'Poland',
    PRT: 'Portugal',
    QAT: 'Qatar',
    ROU: 'Romania',
    RUS: 'Russia',
    RWA: 'Rwanda',
    KNA: 'Saint Kitts and Nevis',
    LCA: 'Saint Lucia',
    VCT: 'Saint Vincent and the Grenadines',
    WSM: 'Samoa',
    SMR: 'San Marino',
    STP: 'Sao Tome and Principe',
    SAU: 'Saudi Arabia',
    SEN: 'Senegal',
    SRB: 'Serbia',
    SYC: 'Seychelles',
    SLE: 'Sierra Leone',
    SGP: 'Singapore',
    SVK: 'Slovakia',
    SVN: 'Slovenia',
    SLB: 'Solomon Islands',
    SOM: 'Somalia',
    ZAF: 'South Africa',
    SSD: 'South Sudan',
    ESP: 'Spain',
    LKA: 'Sri Lanka',
    SDN: 'Sudan',
    SUR: 'Suriname',
    SWZ: 'Swaziland',
    SWE: 'Sweden',
    CHE: 'Switzerland',
    SYR: 'Syria',
    TWN: 'Taiwan',
    TJK: 'Tajikistan',
    TZA: 'Tanzania',
    THA: 'Thailand',
    TGO: 'Togo',
    TON: 'Tonga',
    TTO: 'Trinidad and Tobago',
    TUN: 'Tunisia',
    TUR: 'Turkey',
    TKM: 'Turkmenistan',
    TUV: 'Tuvalu',
    UGA: 'Uganda',
    UKR: 'Ukraine',
    ARE: 'United Arab Emirates',
    GBR: 'United Kingdom',
    USA: 'United States of America',
    URY: 'Uruguay',
    UZB: 'Uzbekistan',
    VUT: 'Vanuatu',
    VAT: 'Vatican City (Holy See)',
    VEN: 'Venezuela',
    VNM: 'Vietnam',
    YEM: 'Yemen',
    ZMB: 'Zambia',
    ZWE: 'Zimbabwe'

}

    if (countryCodes[shortCode as keyof typeof countryCodes]) {
        return countryCodes[shortCode as keyof typeof countryCodes]
    }
    return ''
}

export function getCorrectGender (genderShort: string): string {
    const gender = {
        M: 'Male',
        F: 'Female'
    }
    if (gender[genderShort as keyof typeof gender]) {
        return gender[genderShort as keyof typeof gender]
    }
    return ''
}
export function getCountriesDocs (countryIn: string): string | null {
    const countries = [
        { id: 'test_id', value: 'Afghanistan' },
        { id: 'test_id', value: 'Albania' },
        { id: 'test_id', value: 'Algeria' },
        { id: 'test_id', value: 'Andorra' },
        { id: 'test_id', value: 'Angola' },
        { id: 'test_id', value: 'Antigua and Barbuda' },
        { id: 'test_id', value: 'Argentina' },
        { id: 'test_id', value: 'Armenia' },
        { id: 'test_id', value: 'Australia' },
        { id: 'test_id', value: 'Austria' },
        { id: 'test_id', value: 'Azerbaijan' },
        { id: 'test_id', value: 'Bahamas' },
        { id: 'test_id', value: 'Bahrain' },
        { id: 'test_id', value: 'Bangladesh' },
        { id: 'test_id', value: 'Barbados' },
        { id: 'test_id', value: 'Belarus' },
        { id: 'test_id', value: 'Belgium' },
        { id: 'test_id', value: 'Belize' },
        { id: 'test_id', value: 'Benin' },
        { id: 'test_id', value: 'Bhutan' },
        { id: 'test_id', value: 'Bolivia' },
        { id: 'test_id', value: 'Bosnia and Herzegovina' },
        { id: 'test_id', value: 'Botswana' },
        { id: 'test_id', value: 'Brazil' },
        { id: 'test_id', value: 'Brunei' },
        { id: 'test_id', value: 'Bulgaria' },
        { id: 'test_id', value: 'Burkina Faso' },
        { id: 'test_id', value: 'Burundi' },
        { id: 'test_id', value: 'Cambodia' },
        { id: 'test_id', value: 'Cameroon' },
        { id: 'test_id', value: 'Canada' },
        { id: 'test_id', value: 'Cape Verde' },
        { id: 'test_id', value: 'Central African Republic' },
        { id: 'test_id', value: 'Chad' },
        { id: 'test_id', value: 'Chile' },
        { id: 'test_id', value: 'China' },
        { id: 'test_id', value: 'Colombia' },
        { id: 'test_id', value: 'Comoros' },
        { id: 'test_id', value: 'Republic of the Congo' },
        { id: 'test_id', value: 'Democratic Republic of the Congo' },
        { id: 'test_id', value: 'Costa Rica' },
        { id: 'test_id', value: 'Cote d`Ivoire' },
        { id: 'test_id', value: 'Croatia' },
        { id: 'test_id', value: 'Cuba' },
        { id: 'test_id', value: 'Cyprus' },
        { id: 'test_id', value: 'Czech Republic' },
        { id: 'test_id', value: 'Denmark' },
        { id: 'test_id', value: 'Djibouti' },
        { id: 'test_id', value: 'Dominica' },
        { id: 'test_id', value: 'Dominican Republic' },
        { id: 'test_id', value: 'East Timor' },
        { id: 'test_id', value: 'Ecuador' },
        { id: 'test_id', value: 'Egypt' },
        { id: 'test_id', value: 'El Salvador' },
        { id: 'test_id', value: 'Equatorial Guinea' },
        { id: 'test_id', value: 'Eritrea' },
        { id: 'test_id', value: 'Estonia' },
        { id: 'test_id', value: 'Ethiopia' },
        { id: 'test_id', value: 'Fiji' },
        { id: 'test_id', value: 'Finland' },
        { id: 'test_id', value: 'France' },
        { id: 'test_id', value: 'Gabon' },
        { id: 'test_id', value: 'Gambia' },
        { id: 'test_id', value: 'Georgia' },
        { id: 'test_id', value: 'Germany' },
        { id: 'test_id', value: 'Ghana' },
        { id: 'test_id', value: 'Greece' },
        { id: 'test_id', value: 'Grenada' },
        { id: 'test_id', value: 'Guatemala' },
        { id: 'test_id', value: 'Guinea' },
        { id: 'test_id', value: 'Guinea-Bissau' },
        { id: 'test_id', value: 'Guyana' },
        { id: 'test_id', value: 'Haiti' },
        { id: 'test_id', value: 'Honduras' },
        { id: 'test_id', value: 'Hungary' },
        { id: 'test_id', value: 'Iceland' },
        { id: 'test_id', value: 'India' },
        { id: 'test_id', value: 'Indonesia' },
        { id: 'test_id', value: 'Iran' },
        { id: 'test_id', value: 'Iraq' },
        { id: 'test_id', value: 'Ireland' },
        { id: 'test_id', value: 'Israel' },
        { id: 'test_id', value: 'Italy' },
        { id: 'test_id', value: 'Jamaica' },
        { id: 'test_id', value: 'Japan' },
        { id: 'test_id', value: 'Jordan' },
        { id: 'test_id', value: 'Kazakhstan' },
        { id: 'test_id', value: 'Kenya' },
        { id: 'test_id', value: 'Kiribati' },
        { id: 'test_id', value: 'North Korea' },
        { id: 'test_id', value: 'South Korea' },
        { id: 'test_id', value: 'Kosovo' },
        { id: 'test_id', value: 'Kuwait' },
        { id: 'test_id', value: 'Kyrgyzstan' },
        { id: 'test_id', value: 'Laos' },
        { id: 'test_id', value: 'Latvia' },
        { id: 'test_id', value: 'Lebanon' },
        { id: 'test_id', value: 'Lesotho' },
        { id: 'test_id', value: 'Liberia' },
        { id: 'test_id', value: 'Libya' },
        { id: 'test_id', value: 'Liechtenstein' },
        { id: 'test_id', value: 'Lithuania' },
        { id: 'test_id', value: 'Luxembourg' },
        { id: 'test_id', value: 'Macedonia' },
        { id: 'test_id', value: 'Madagascar' },
        { id: 'test_id', value: 'Malawi' },
        { id: 'test_id', value: 'Malaysia' },
        { id: 'test_id', value: 'Maldives' },
        { id: 'test_id', value: 'Mali' },
        { id: 'test_id', value: 'Malta' },
        { id: 'test_id', value: 'Marshall Islands' },
        { id: 'test_id', value: 'Mauritania' },
        { id: 'test_id', value: 'Mauritius' },
        { id: 'test_id', value: 'Mexico' },
        { id: 'test_id', value: 'Federated States of Micronesia' },
        { id: 'test_id', value: 'Moldova' },
        { id: 'test_id', value: 'Monaco' },
        { id: 'test_id', value: 'Mongolia' },
        { id: 'test_id', value: 'Montenegro' },
        { id: 'test_id', value: 'Morocco' },
        { id: 'test_id', value: 'Mozambique' },
        { id: 'test_id', value: 'Myanmar' },
        { id: 'test_id', value: 'Namibia' },
        { id: 'test_id', value: 'Nauru' },
        { id: 'test_id', value: 'Nepal' },
        { id: 'test_id', value: 'Netherlands' },
        { id: 'test_id', value: 'New Zealand' },
        { id: 'test_id', value: 'Nicaragua' },
        { id: 'test_id', value: 'Niger' },
        { id: 'test_id', value: 'Nigeria' },
        { id: 'test_id', value: 'Norway' },
        { id: 'test_id', value: 'Oman' },
        { id: 'test_id', value: 'Pakistan' },
        { id: 'test_id', value: 'Palau' },
        { id: 'test_id', value: 'Panama' },
        { id: 'test_id', value: 'Papua New Guinea' },
        { id: 'test_id', value: 'Paraguay' },
        { id: 'test_id', value: 'Peru' },
        { id: 'test_id', value: 'Philippines' },
        { id: 'test_id', value: 'Poland' },
        { id: 'test_id', value: 'Portugal' },
        { id: 'test_id', value: 'Qatar' },
        { id: 'test_id', value: 'Romania' },
        { id: 'RUS', value: 'Russia' },
        { id: 'test_id', value: 'Rwanda' },
        { id: 'test_id', value: 'Saint Kitts and Nevis' },
        { id: 'test_id', value: 'Saint Lucia' },
        { id: 'test_id', value: 'Saint Vincent and the Grenadines' },
        { id: 'test_id', value: 'Samoa' },
        { id: 'test_id', value: 'San Marino' },
        { id: 'test_id', value: 'Sao Tome and Principe' },
        { id: 'test_id', value: 'Saudi Arabia' },
        { id: 'test_id', value: 'Senegal' },
        { id: 'test_id', value: 'Serbia' },
        { id: 'test_id', value: 'Seychelles' },
        { id: 'test_id', value: 'Sierra Leone' },
        { id: 'test_id', value: 'Singapore' },
        { id: 'test_id', value: 'Slovakia' },
        { id: 'test_id', value: 'Slovenia' },
        { id: 'test_id', value: 'Solomon Islands' },
        { id: 'test_id', value: 'Somalia' },
        { id: 'test_id', value: 'South Africa' },
        { id: 'test_id', value: 'South Sudan' },
        { id: 'test_id', value: 'Spain' },
        { id: 'test_id', value: 'Sri Lanka' },
        { id: 'test_id', value: 'Sudan' },
        { id: 'test_id', value: 'Suriname' },
        { id: 'test_id', value: 'Swaziland' },
        { id: 'test_id', value: 'Sweden' },
        { id: 'test_id', value: 'Switzerland' },
        { id: 'test_id', value: 'Syria' },
        { id: 'test_id', value: 'Taiwan' },
        { id: 'test_id', value: 'Tajikistan' },
        { id: 'test_id', value: 'Tanzania' },
        { id: 'test_id', value: 'Thailand' },
        { id: 'test_id', value: 'Togo' },
        { id: 'test_id', value: 'Tonga' },
        { id: 'test_id', value: 'Trinidad and Tobago' },
        { id: 'test_id', value: 'Tunisia' },
        { id: 'test_id', value: 'Turkey' },
        { id: 'test_id', value: 'Turkmenistan' },
        { id: 'test_id', value: 'Tuvalu' },
        { id: 'test_id', value: 'Uganda' },
        { id: 'test_id', value: 'Ukraine' },
        { id: 'AED', value: 'United Arab Emirates' },
        { id: 'test_id', value: 'United Kingdom' },
        { id: 'test_id', value: 'United States of America' },
        { id: 'test_id', value: 'Uruguay' },
        { id: 'test_id', value: 'Uzbekistan' },
        { id: 'test_id', value: 'Vanuatu' },
        { id: 'test_id', value: 'Vatican City (Holy See)' },
        { id: 'test_id', value: 'Venezuela' },
        { id: 'test_id', value: 'Vietnam' },
        { id: 'test_id', value: 'Yemen' },
        { id: 'test_id', value: 'Zambia' },
        { id: 'test_id', value: 'Zimbabwe' }
    ]
    const countryOut = countries.find(el => el.id === countryIn)
    return countryOut !== undefined ? countryOut.value : null
}
