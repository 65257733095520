export const getCorrectExpDate = (dateStr: string) => {
    if (dateStr) {
        const year = (dateStr.slice(0, 2) > '35' ? '19' : '20') + dateStr.slice(0, 2)
        const month = dateStr.slice(2, 4)
        const day = dateStr.slice(4, 6)
        const formattedDate = `${year}-${month}-${day}`
        return formattedDate
    }
    return ''
}
