
export const getSubsDetails = (subChoose: string) => {
    const resultDetails = { areas: '3', advertiseCoins: '50', broadcastCoins: '10', amount: '0' }

    if (subChoose === 'Test-Mode_Broker_Subscription_Standard' || subChoose.includes('Standard')) { // 'Standard'
        resultDetails.areas = '5'
        resultDetails.advertiseCoins = '100'
        resultDetails.broadcastCoins = '20'
        resultDetails.amount = '10'
    } if (subChoose === 'Test-Mode_Broker_Subscription_Premium' || subChoose.includes('Premium')) { // 'Premium'
        resultDetails.areas = 'All'
        resultDetails.advertiseCoins = '150'
        resultDetails.broadcastCoins = '30'
        resultDetails.amount = '20'
    }
    return resultDetails
}
