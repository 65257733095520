import React from 'react'
import { useAppSelector } from '../../../../hooks/redux'
import { type IconName } from '../../../../app/icon/iconsConfig'
import Icon from '../../../../app/icon/Icon'

interface ActionCardProps {
    title: string
    description: string
    onClick: () => void
    icon: IconName
    styles?: string
}

const ActionCard = ({ title, description, onClick, icon, styles = '' }: ActionCardProps): JSX.Element => {
    const loading = useAppSelector((state) => state.modal.loading)
    return (
        <div className={`card shadow-sm h-80px ${loading ? 'bg-secondary' : ''} ${styles}`}
            onClick={loading ? undefined : onClick}
        >
            <div className="card-body d-flex justify-content-between align-items-center p-1 ">
                <div className="px-4 py-1 mb-1">
                    <span className="fs-3 text-gray-800 fw-bold d-block text-hover-primary">
                        {title}
                    </span>
                    <span className="fs-6 fw-semibold text-gray-500">{description}</span>
                </div >
                <div className='align-items-center'>
                    <span className="pe-4 d-flex align-items-center justify-content-center">
                        <Icon name={icon} className={'fs-6x mx-auto text-muted d-flex align-items-center justify-content-center'} imageSize={48} useWrapper />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ActionCard
