import { type IChat } from '../../../types'
import _ from 'lodash'

const getChatAdapter = (data: any, userID: number): IChat => {
    try {
        const response = data.response?.data

        const users = response?.attributes?.Users?.data ?? []
        let blockedBy: number | null | undefined

        //   console.log(users[0]?.attributes?.BlackList.data)
        //   console.log(users[1]?.attributes?.BlackList.data)
        //    console.log(users)
        if (users[0]?.attributes?.BlackList?.data?.length > 0 && _.some(users[0]?.attributes?.BlackList?.data, user => user.id === users[1]?.id)) {
            blockedBy = users[0]?.id
        } else if (users[1]?.attributes?.BlackList?.data?.length > 0 && _.some(users[1]?.attributes?.BlackList?.data, user => user.id === users[0]?.id)) {
            blockedBy = users[1]?.id
        }

        //     console.log(blockedBy)

        _.remove(users, (user: any) => user?.id === userID)

        const chatData: IChat = {
            id: response.id,
            User: {
                Name: `${String(users[0]?.attributes?.ContactInfo?.FirstName ?? '')} ${String(users[0]?.attributes?.ContactInfo?.FamilyName ?? '')}`,
                Photo: users[0]?.attributes?.Type?.[0]?.Photo ?? null,
                id: users[0]?.id
            },
            UserIDs: (response?.attributes?.Users?.data?.map((user: any) => Number(user.id)) ?? []),
            Unread: response?.attributes?.Unread,
            LastMessage: response?.attributes?.LastMessage?.data?.id,
            BlockedBy: blockedBy,
            Type: response?.attributes?.Type,
            SupportTickets: response?.attributes?.SupportTickets ?? [],
            UnitID: response?.attributes?.UnitID,
            Status: response?.attributes?.Status,
            createdAt: response?.attributes?.createdAt
        }
        return (chatData)
    } catch (error) {
        console.error(error)
        return {}
    }
}

export default getChatAdapter
