import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface HashState {
    chatRooms: number | null
    users: number | null
    units: number | null
    viewings: number | null
    offers: number | null
    unitAdvertises: number | null
    unitKeys: number | null
    documents: number | null

    // referrals: number
    // broadcasts: number
}

const initialState: HashState = {
    chatRooms: null,
    users: null,
    units: null,
    viewings: null,
    offers: null,
    unitAdvertises: null,
    unitKeys: null,
    documents: null
}

export const hashSlice = createSlice({
    name: 'hash',
    initialState,
    reducers: {
        setHash (state, action: PayloadAction<{ action: keyof HashState, timestamp: number }>) {
            state[action.payload.action] = action.payload.timestamp
        }
    }
})

export const { setHash } = hashSlice.actions
export default hashSlice.reducer
