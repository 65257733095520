const getAgentStatuses = (arr: any) => {
    const acc = {
        emiratesApproved: false,
        licenseApproved: false,
        passportApproved: false,
        emiratesLoaded: false,
        licenseLoaded: false,
        passportLoaded: false,
        passportReject: false,
        emiratesReject: false,
        licenseReject: false,
        emiratesExpire: false,
        passportExpire: false,
        licenseExpire: false,
        emiratesInProgress: false,
        passportInProgress: false,
        licenseInProgress: false
    }

    const resultObj = arr.reduce((acc: any, el: any) => {
        let checkDocumentType = true
        let documentType, refNumber
        let isThereLinks = false
        if (el) {
            if (el.hasOwnProperty('attributes')) {
                documentType = el?.attributes?.Type?.[0]?.__component
                refNumber = el?.attributes?.RefID
                isThereLinks = (el?.attributes?.LinkFront !== null && el?.attributes?.LinkBack !== null)
            } else {
                documentType = el?.Type?.[0]?.__component
                refNumber = el?.RefID
                isThereLinks = (el?.LinkFront !== null && el?.LinkBack !== null)
            }
            if (!documentType) {
                checkDocumentType = false
            }
        }
        const dateNow = new Date().getTime()
        const expDate = el && el?.hasOwnProperty('attributes') ? new Date(el?.attributes?.ExpiryDate).getTime() : new Date(el?.ExpiryDate).getTime()
        const statuses = el && el?.hasOwnProperty('attributes') ? el?.attributes?.Statuses : el?.Statuses

        // RERA LICENSE STATUSES
        if ((checkDocumentType ? documentType === 'documents.rera-card' : refNumber.includes('rera')) && statuses?.includes('Approved') && dateNow < expDate) {
            return { ...acc, licenseApproved: true, licenseLoaded: true }
        } else if ((checkDocumentType ? documentType === 'documents.rera-card' : refNumber.includes('rera')) && statuses?.includes('Archived') && dateNow < expDate) {
            return { ...acc, licenseApproved: true, licenseLoaded: true }
        } else if ((checkDocumentType ? documentType === 'documents.rera-card' : refNumber.includes('rera')) && statuses?.includes('Approved') && dateNow > expDate) {
            return { ...acc, licenseExpire: true, licenseLoaded: true }
        } else if ((checkDocumentType ? documentType === 'documents.rera-card' : refNumber.includes('rera')) && statuses?.includes('In-process')) {
            return { ...acc, licenseInProgress: true, licenseLoaded: true }
        } else if ((checkDocumentType ? documentType === 'documents.rera-card' : refNumber.includes('rera')) && statuses?.includes('Rejected')) {
            return { ...acc, licenseReject: true, licenseLoaded: true }

            // EMIRATES ID STATUSES
        } else if ((checkDocumentType ? documentType === 'documents.emirates-id' : refNumber.includes('emirates_id')) && statuses?.includes('Approved') && dateNow < expDate) {
            return { ...acc, emiratesApproved: true, emiratesLoaded: true }
        } else if ((checkDocumentType ? documentType === 'documents.emirates-id' : refNumber.includes('emirates_id')) && statuses?.includes('Archived') && dateNow < expDate) {
            return { ...acc, emiratesApproved: true, emiratesLoaded: true }
        } else if ((checkDocumentType ? documentType === 'documents.emirates-id' : refNumber.includes('emirates_id')) && statuses?.includes('Approved') && dateNow > expDate && isThereLinks) {
            return { ...acc, emiratesExpire: true, emiratesLoaded: true }
        } else if ((checkDocumentType ? documentType === 'documents.emirates-id' : refNumber.includes('emirates_id')) && statuses?.includes('In-process')) {
            return { ...acc, emiratesInProgress: true, emiratesLoaded: true }
        } else if ((checkDocumentType ? documentType === 'documents.emirates-id' : refNumber.includes('emirates_id')) && statuses?.includes('Rejected')) {
            return { ...acc, emiratesReject: true, emiratesLoaded: true }

            // PASSPORT STATUSES
        } else if ((checkDocumentType ? documentType === 'documents.passport' : refNumber.includes('visa')) && statuses?.includes('Approved') && dateNow < expDate) {
            return { ...acc, passportApproved: true, passportLoaded: true }
        } else if ((checkDocumentType ? documentType === 'documents.passport' : refNumber.includes('visa')) && statuses?.includes('Archived') && dateNow < expDate) {
            return { ...acc, passportApproved: true, passportLoaded: true }
        } else if ((checkDocumentType ? documentType === 'documents.passport' : refNumber.includes('visa')) && statuses?.includes('Approved') && dateNow > expDate) {
            return { ...acc, passportExpire: true, passportLoaded: true }
        } else if ((checkDocumentType ? documentType === 'documents.passport' : refNumber.includes('visa')) && statuses?.includes('In-process')) {
            return { ...acc, passportInProgress: true, passportLoaded: true }
        } else if ((checkDocumentType ? documentType === 'documents.passport' : refNumber.includes('visa')) && statuses?.includes('Rejected')) {
            return { ...acc, passportReject: true, passportLoaded: true }
        } else {
            return acc
        }
    }, acc)

    return resultObj
}

export default getAgentStatuses
