import React from 'react'

export interface UnitCardParamsProps {
    unit: any // Replace with IUnit
}
const UnitCardParams = ({ unit }: UnitCardParamsProps): JSX.Element => {
    const Marketing = unit.attributes.Marketing ?? {}
    const Property = unit.attributes.Property ?? {}
    return (
        <>
            <div className="d-flex align-items-start mb-0 px-4">
                <span
                    data-id="units_list_template_marketing_headline"
                    className="text-gray-900 fs-2x me-0 mt-1"
                >
                    {
                        Marketing.Headline ?? Property.Name ?? 'Not added'
                    }
                </span>
            </div>
            <div className="d-flex align-items-end mb-0 px-4">
                <span
                    className="text-gray-900 fs-4  me-2 mb-2"
                >
                    {'AED '}
                    {
                        Marketing.Price ?? 'Not added'
                    }
                </span>
            </div>

            <div className="d-flex flex-wrap fw-bold fs-6 mb-2 px-4">
                <div className="btn btn-sm btn-light p-0 px-2 me-2 mb-2">
                    <i className="ki-duotone ki-map">
                        <i className="path1"></i>
                        <i className="path2"></i>
                        <i className="path3"></i>
                    </i>
                    <span data-id="units_list_template_location">
                        Dubai {' '}
                        { Property.Area ?? Property.Community ?? Property.Name ?? null }
                    </span>
                </div>
                <div className="btn btn-sm btn-light p-0 px-2 me-2 mb-2">
                    <i className="ki-duotone ki-safe-home">
                        <i className="path1"></i>
                        <i className="path2"></i>
                    </i>
                    <span data-id="units_list_template_property_type">
                        { unit.attributes?.Type ?? 'Not added' }
                    </span>
                </div>
                {
                    Marketing.Use !== undefined
                        ? (
                            <div className="btn btn-sm btn-light p-0 px-2 me-2 mb-2">
                                <i className="ki-duotone ki-watch">
                                    <i className="path1"></i>
                                    <i className="path2"></i>
                                </i>
                                <span data-id="units_list_template_marketing_type">
                                    { Marketing.Use }
                                </span>
                            </div>
                        )
                        : <></>
                }
                {unit.attributes.Bedrooms !== undefined
                    ? (
                        <div className="btn btn-sm btn-light p-0 px-2 me-2 mb-2">
                            <i className="ki-duotone ki-price-tag">
                                <i className="path1"></i>
                                <i className="path2"></i>
                                <i className="path3"></i>
                            </i>
                            <span data-id="units_list_template_bedrooms">
                                {unit.attributes?.Bedrooms} Bed
                            </span>
                        </div>
                    )
                    : null}

                {unit.attributes.Bathrooms !== undefined
                    ? (
                        <div className="btn btn-sm btn-light p-0 px-2 me-2 mb-2">
                            <i className="ki-duotone ki-price-tag">
                                <i className="path1"></i>
                                <i className="path2"></i>
                                <i className="path3"></i>
                            </i>
                            <span data-id="units_list_template_bathrooms">
                                {unit.attributes.Bathrooms ?? 'Not added'} Bath
                            </span>
                        </div>
                    )
                    : null}

            </div>
        </>
    )
}

export default UnitCardParams
