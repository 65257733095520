import React, {Fragment, useMemo} from 'react'
import { useAppSelector } from '../../../../hooks/redux'
import { type User } from '../../../../store/slices/userSlice'
import {type IAdvertise} from "../../../../store/slices/advertisesSlice";
import {nanoid} from "nanoid";
import dayjs from "dayjs";
import {type Unit} from "../../../../store/slices/unitsSlice";
import {Link} from "react-router-dom";

interface IProps {
    user: User
}

export default function AgentProfileActiveUnits ({ user }: IProps): JSX.Element {
    const userID = user.id
    const units = useAppSelector((state) => state.units.units)
    const advertises = useAppSelector((state) => state.advertises.advertises)

    const filterAdvertises = (advertise: IAdvertise): boolean => [
        dayjs().isAfter(dayjs(advertise?.attributes?.StartDate)),
        dayjs().isBefore(dayjs(advertise?.attributes?.EndDate)),
        // !['Completed', 'Canceled'].includes(advertise?.attributes?.Statuses?.[0])
    ].every(Boolean)

    const showAdvertiseSpaces = (unit: Unit): string | number => {
        if (unit?.attributes?.Marketing?.Use !== 'Rent' && unit?.attributes?.AdvertisementSpaces != null) {
            return unit.attributes.AdvertisementSpaces
        }
        return '∞'
    }
    
    const relatedUnits = useMemo(() => {
        const unitsIds = new Set<number>()
        advertises.forEach((advertise) => {
            if (advertise?.attributes?.User?.data?.id === userID && filterAdvertises(advertise)) {
                unitsIds.add(Number(advertise?.attributes?.Unit?.data?.id))
            }
        })
        return units?.filter(unit => (
            unitsIds.has(unit?.id) && (unit?.attributes?.Advertises?.data?.filter(filterAdvertises)?.length ?? 0) > 0
        ))
    }, [userID, units, advertises])

    return (
        <div className="card shadow-sm mx-4 mb-4">
            <div className="card-body p-4">
                <div className={'text-gray-900 fs-2 fw-bold mb-2'}>Advertised units</div>
                { relatedUnits?.length > 0
                    ? <div className="row">
                        <div className="col-8 fw-bold">Unit</div>
                        <div className="col-4 text-end fw-bold">Advertises</div>
                        {
                            relatedUnits.map((unit) => (
                                <Fragment key={nanoid()}>
                                    <div className="col-8 mb-2">
                                        <Link to={`/landlord/modalView/unit_modal/${unit?.id}/-/-`}>
                                            <div className={'badge p-2 fs-7 badge-light border border-dark text-gray-600 mw-100'}>
                                                <span>#{unit?.attributes?.Number}</span> <span className={'text-truncate ms-1'}>{unit?.attributes?.Property?.data?.attributes?.Name}</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-4 text-end mb-2">
                                        {
                                            unit?.attributes?.Advertises?.data?.filter(filterAdvertises)?.length ?? 0
                                        } / {
                                            showAdvertiseSpaces(unit)
                                        }
                                    </div>
                                </Fragment>
                            ))
                        }
                    </div>
                    : <div className={'py-2 fs-4 text-center text-gray-600'}>No advertised units yet</div>
                }
            </div>
        </div>
    )
}