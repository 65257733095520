import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { type IChatMessage } from '../../../../types'

interface SharedFilesListProps {
    messages: IChatMessage[]
}

const SharedFilesList = ({ messages }: SharedFilesListProps): JSX.Element => {
    return (
        <div className={'d-flex flex-column w-100 gap-4'}>
            {messages.length > 0
                ? _.map(messages, (file) => (
                    <div className={'d-flex flex-row gap-2 w-100'} onClick={() => {
                        window.open(file?.Attachments?.data)
                    }}>
                        <i className="ki-duotone ki-file-down fs-4x pt-1">
                            <i className="path1"></i>
                            <i className="path2"></i>
                        </i>
                        <div className={'d-flex flex-column justify-content-between w-100'}>
                            <div>{file?.Attachments?.name}</div>
                            <div className={'d-flex flex-row gap-2 text-gray-500 fs-8'}>
                                <div>
                                    {file?.Attachments?.size},
                                </div>
                                <div>
                                    {
                                        moment(file.updatedAt).format('DD.MM.YY HH:mm')
                                    }
                                </div>
                            </div>
                            <div className="separator"/>
                        </div>
                    </div>
                ))
                : <div className="card shadow-sm my-1 bg-secondary">
                    <div className="card-body p-0 m-5 text-center">
                        <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 ">
                                        No files
                        </span>
                    </div>
                </div>
            }
        </div>
    )
}

export default SharedFilesList
