import React, { useRef, useState } from 'react'
import ChatUtils from '../../../../../functions/chats/ChatUtils'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { setFullscreenVideo } from '../../../../../store/slices/appSlice'
import { Modal, Image } from 'antd'

interface ImageMessageProps {
    sentBy: 'me' | 'other'
    image: string
    time: Date
    senderName?: string
}

const ImageMessage = ({ sentBy, image, time, senderName }: ImageMessageProps): JSX.Element => {
    // const fullRef = useRef<HTMLDivElement>(null)
    // const userDevice = useAppSelector((state) => state.app.userDevice)
    const [fullScreen, setFullScreen] = useState(false)
    const dispatch = useAppDispatch()
    return (
        <div className={`w-100 d-flex justify-content-${sentBy === 'me' ? 'end' : 'start'}`}>
            <div className={'card pt-4 px-4 pb-2 shadow-sm position-relative z-index-0'}
                onClick={() => {
                    /* void fullRef?.current?.requestFullscreen()
                        .then(() => {
                            dispatch(setFullscreenVideo(true))
                        })
                        .catch((err) => {
                            console.log(err)
                        }) */
                    setFullScreen(true)
                    dispatch(setFullscreenVideo(true))
                }}
                style={{
                    width: '70vw',
                    height: '200px',
                    border: `solid 1px ${sentBy === 'me' ? '#fff200' : '#FFFFFF'}`,
                    background: `url(${image}) no-repeat center`,
                    backgroundSize: 'cover'
                }}
            >
                { sentBy === 'other'
                    ? <div className={'position-absolute top-0 start-0 rounded p-1 px-2 ms-1 mt-1 text-gray-600'}
                        style={{
                            background: '#FFFFFF'
                        }}
                    >
                        {senderName}
                    </div>
                    : <></>
                }
                <div className={'position-absolute bottom-0 end-0 rounded p-1 px-2 me-1 mb-1 fs-6 text-gray-600'}
                    style={{
                        background: sentBy === 'me' ? '#fff200' : '#FFFFFF'
                    }}
                >
                    {ChatUtils.formatTime(time)}
                </div>
            </div>
            <Image
                style={{ display: 'none' }}
                src={image}
                preview={{
                    visible: fullScreen,
                    src: image,
                    onVisibleChange: (value) => {
                        setFullScreen(false)
                        dispatch(setFullscreenVideo(false))
                    }
                }}
            />
        </div>
    )
}

export default ImageMessage
