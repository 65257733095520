import { Link } from 'react-router-dom'
import { type IUser } from '../../../../types'
import CardUserTable from './CardUserTable'
import { type User } from '../../../../store/slices/userSlice'
// import { setRecipient } from '../../../stores/store'
// import type { IUser } from '../../../types'

interface MyComponentProps {
    agentName?: string
    srcImg: string
    performance?: number
    offers?: number
    num?: number
    user?: User
    earnings?: number
    userID?: number
    currentAgent: boolean
}

export default function DashboardUserTable ({ currentAgent, num = 1, srcImg, user, performance, offers, earnings, userID }: MyComponentProps): any {
    return (
        <Link to={!currentAgent ? `/agent/profile/${userID}` : '#'} className="text-decoration-none">
            <div className={`row-cols-5 d-flex align-items-center rounded  justify-content-between border-bottom-dashed border-gray-100 my-1 py-3 px-1 ${currentAgent ? 'bg-light-warning' : ''}`}>
                <div className='w-15px col d-flex align-items-center justify-content-end'>
                    <span className="text-dark fw-bold  d-block mb-1 fs-4">{num}</span>
                </div>
                <div className='w-125px col'><CardUserTable srcImg={srcImg} user={user as User} /></div>

                <div className='w-40px  col text-start'>
                    <span className="text-gray-800 fw-bold d-block mb-1 fs-6">{offers}</span>
                    <span className="text-muted fw-semibold d-block fs-7">Offers</span>
                </div>
                <div className='w-70px col text-start'>
                    <span className="text-dark fw-bold  d-block mb-1 fs-6"> {earnings}</span>
                    <span className="text-muted fw-semibold d-block fs-7">Earnings</span>
                </div>
                <div className='w-40px col text-start'>
                    <span className="text-dark fw-bold  d-block mb-1 fs-6">{performance}</span>
                    <span className="text-muted fw-semibold d-block fs-7">Rating</span>
                </div>
                {/* <div className='w-40px col d-flex align-items-center justify-content-center text-center' id="kt_drawer_example_basic_button">
                    <div className={` p-0 ${currentAgent ? 'btn btn-light-warning p-1' : 'btn-warning p-2'}  opacity-75`} onClick={handleClick}>
                        {currentAgent
                            ? <i className="fs-4x ki-duotone ki-user-square">
                                <i className="path1"></i>
                                <i className="path2"></i>
                                <i className="path3"></i>
                            </i>
                            :
                        //  <i className="fs-3x bi bi-send"></i>
                            <i className="fs-4x ki-duotone ki-messages">
                                <i className="path1"></i>
                                <i className="path2"></i>
                                <i className="path3"></i>
                                <i className="path4"></i>
                                <i className="path5"></i>
                            </i>
                        }

                    </div>
                </div> */}
            </div>
        </Link>
    )
}

// text-hover-primary
