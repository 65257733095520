import { useEffect, useState } from 'react'
import { type AuthState } from '../store/slices/authSlice'
import { useAppSelector } from './redux'

export type UserType = 'Landlord' | 'Agent' | 'Unknown'

interface IUserType {
    userType: UserType
    userTypeLowercase: string
}

const UseUserType = (): IUserType => {
    const { userCATEGORY }: AuthState = useAppSelector((state) => state.auth)
    const [type, setType] = useState<UserType>(userCATEGORY === 'Agent' ? 'Agent' : 'Landlord')
    useEffect(() => {
        if (userCATEGORY === 'Landlord') {
            setType('Landlord')
        } else {
            setType('Agent')
        }
    }, [userCATEGORY])
    return {
        userType: type,
        userTypeLowercase: type.toLowerCase()
    }
}

export default UseUserType
