export const isInLastWeek = (date: string): boolean => {
    const dateView = new Date(date)
    const today = new Date()
    const oneWeekAgo = new Date(today)
    oneWeekAgo.setDate(today.getDate() - 7)

    return (
        dateView >= oneWeekAgo &&
        dateView < today
    )
}
