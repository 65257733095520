import { type User } from '../../../store/slices/userSlice'

export const getNameUser = (currentUser: User): string => {
    const userCONTACTS = (currentUser)?.Type?.[0]
    const userContactsINFO = (currentUser)?.ContactInfo
    const nameUser = `${
        userContactsINFO?.FirstName
            ? userContactsINFO?.FirstName
            : userCONTACTS?.Name
    } ${
        userContactsINFO?.FamilyName
            ? userContactsINFO?.FamilyName
            : ''
    }`
    return nameUser
}
