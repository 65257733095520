import React from 'react'
import Lottie from 'react-lottie'

import animationData from '../../../animations/loading_screen_animation/Icon.json'
interface IProps {
    title: string
}

export default function MainLoader({ title }: IProps) {
    return (
        <div className="d-flex flex-column flex-row-fluid flex-column-fluid flex-center vh-100 my-n8">
            <Lottie
                options={{
                    animationData,
                    loop: true,
                    autoplay: true
                }}
                // height={100}
                // width={100}
                height={220}
                width={220}
            />
            <h1 className="pt-n12 mt-n12">{title}</h1>
        </div>
    )
}
