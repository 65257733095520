import { useState } from 'react'

interface MyComponentProps {
    onClick: any
}
export default function ButtonsSort ({ onClick }: MyComponentProps): any {
    const [value, setValue] = useState('TotalScore')
    const handleClick = (sort: string): void => {
        setValue(sort)
        onClick(sort)
    }
    return (
        <div className="m-0">
            <div className="d-flex justify-content-between gap-4 gap-xxl-4 px-0 mb-2">
                {/* btn-color-gray-600 btn-active-text-gray-800 border-active-bso btn-active-bso border border-3 border-gray-400 */}
                <label

                    className={'btn bg-light shadow-sm text-gray-400   w-50 min-h-65px px-2 py-1'}
                    onClick={() => {
                        handleClick('Offers')
                    }}
                >
                    <div className={`${
                        value === 'Offers'
                            ? 'text-dark'
                            : ''
                    }`}>
                        <span className="fa-regular fa-handshake fs-3x p-2 "></span>
                        {/* <i className="las la-handshake fs-1"></i> */}
                        {/* <div className="btn fs-1 w-50 py-4" ></div> */}
                        <span className={`${
                            value === 'Offers'
                                ? 'fw-bold'
                                : 'fw-normal'
                        } fs-5 d-block`} >Offers</span>
                        {/* <span className="fs-6  fw-bold d-block">
                        {'  '}Offers{'  '}{' '}
                    </span> */}
                    </div>

                </label>
                {/* 'text-dark' : 'text-gray-400' */}

                {/* <div className="d-flex flex-column align-items-center">
                            <span className="bi bi-grid fs-4x m-0 p-0" />
                            <span className="fw-bold fs-4">Board</span>
                        </div> */}
                <label
                    className={'btn bg-light shadow-sm text-gray-400 border border-3  w-50 min-h-65px px-2 py-1 '}
                    onClick={() => {
                        handleClick('Business')
                    }}
                >
                    <div className={`${
                        value === 'Business'
                            ? 'text-dark'
                            : ''
                    }`}>
                        <span className="fa-solid fa-sack-dollar fs-3x p-2"></span>
                        {/* <i className="las la-hand-holding-usd fs-1"></i> */}
                        <span className={`${
                            value === 'Business'
                                ? 'fw-bold'
                                : 'fw-normal'
                        } fs-5 d-block`}>Earnings</span>
                    </div>
                </label>

                <label
                    className={'btn bg-light shadow-sm  text-gray-400 border border-3  w-50 min-h-65px px-2 py-1'}
                    onClick={() => {
                        handleClick('TotalScore')
                    }}
                >
                    {/* <div className="btn fs-1 w-50 py-4" > </div> */}
                    <div className={`${
                        value === 'TotalScore'
                            ? 'text-dark'
                            : ''
                    }`}>
                        <span className="fa-solid fa-medal fs-3x p-2 "></span>
                        {/* <i className="las la-medal fs-1"></i> */}
                        <span className={`${
                            value === 'TotalScore'
                                ? 'fw-bold'
                                : 'fw-normal'
                        } fs-5 d-block`}>Rating</span>
                    </div>
                </label>
            </div>
        </div>
    )
}
// <i className="fonticon-mobile-payment fs-2hx mb-2 pe-0"></i>  <i className="fa-solid fa-dollar-sign"></i>
// <i className="fa-regular fa-credit-card"></i> <i className="fa-solid fa-coins"></i> <i className="fa-solid fa-sack-dollar"></i>
// <i className="fa-solid fa-trophy"></i>
// <i className="fa-solid fa-medal"></i>
// className="btn bg-light btn-color-gray-600 btn-active-text-gray-800 border border-3 border-gray-100 border-active-primary btn-active-light-primary w-100 px-4"
