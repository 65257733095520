import _ from 'lodash'
import qs from 'qs'
import { ACCESS_KEY, urlAPI } from '../../../../urls'
import { store } from '../../../store'
import { removeDataAttributes } from '../../../../functions/adapters/universal/removeDataAttributes'
import { sendNotification } from '../../offerActions'
import notifyDelay from '../../../../functions/shared/notify-helpers/notifyDelay'

export const setClientContactInfo = async (offerID: number, email: string, phone: string, documentID: number): Promise<{ isSuccessful: boolean, textNavigate?: number }> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    try {
        const rawOffer = store.getState().offers.offers.find((offerItem) => offerItem.id === offerID)
        const valid = /\S+@\S+\.\S+/.test(email.toLowerCase().trim()) && ((phone?.toLowerCase()?.trim()?.match(/\d/g)?.length ?? 0) >= 9)
        if (rawOffer != null && email != null && email !== '' && phone != null && phone !== '' && valid) {
            const offer = removeDataAttributes(
                _.cloneDeep(
                    rawOffer
                )
            )
            offer.Type[0].Email = email
            offer.Type[0].Phone = phone
            offer.Statuses = ['offer.residential', 'offer.commercial'].includes(offer?.Type?.[0]?.__component) ? ['Waiting booking form send'] : ['Waiting for updates']
            offer.Notify = 'BSO'
            offer.Documents = [...offer.Documents.map((doc: any) => doc.id), documentID].filter(Boolean)
            const bodyObject = {
                method: 'PUT',
                collection: 'offers',
                id: offerID,
                body: offer,
                query: qs.stringify({
                    populate: ['Type', 'Documents', 'User', 'Unit']
                }, {
                    encodeValuesOnly: true
                })
            }

            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(bodyObject),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const responseJSON = await response.json()

            if (responseJSON.success === true && responseJSON?.response?.data?.id != null) {
                await sendNotification(notifyDelay(), offer?.User?.id, offer, 'admin-offer-client-info', 'Offer - Waiting booking form sign')
                return {
                    isSuccessful: true,
                    textNavigate: -1
                }
            }
        }
        return {
            isSuccessful: false
        }
    } catch (err) {
        return {
            isSuccessful: false
        }
    }
}
