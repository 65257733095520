import React from 'react'

export default function TermsContent (): JSX.Element {
    return (
        <div className="row g-0 ">
            <div className="row g-0 text-end fs-8">
                <p className="mb-0">
BSO REAL ESTATE MANAGEMENT</p>
                <p className="mb-0">BSO Mobile Application</p>
                <p className="mb-0">BSO CLUB</p>
            </div>

            <div className="p-5 col-lg-12 col-md-12 col-sm-12 col-12 blog text-justify" >

                <h1 className="text-center mb-0">TERMS & CONDITIONS</h1>
                <p className="text-center fs-8"> Latest updates published on <span className="text-danger fw-bold">01.04.2024</span></p>

                <div className="text-center fst-italic mb-2">
                THIS IS A BINDING LEGAL AGREEMENT BETWEEN YOU AND BSO REAL ESTATE MANAGEMENT.
YOU MUST READ AND ACCEPT THESE TERMS AND CONDITIONS IN THEIR ENTIRETY BEFORE USING THIS MOBILE APPLICATION.
IF YOU DO NOT AGREE TO OR CANNOT COMPLY WITH THE TERMS CONTAINED IN THIS AGREEMENT IN THEIR ENTIRETY,
YOU ARE NOT AUTHORIZED TO USE THIS MOBILE APPLICATION.
                </div>
                <h4>1. AGREEMENT TO THE TERMS</h4>
                <p>
          1.1 These are the terms and conditions (“Terms”) on which this mobile application (“BSO Mobile App”), is made available by BSO Real Estate Management, (“BSO”, or “we”, or “us”, or “our”) and our payment gateway service providers (“Payment Provider”): Emirates Telecommunication Group Company P. J. S. C. (“Etisalat Group”) and/or Network International Payment Solutions (“Network International”) and/or Stripe Inc. (“Stripe”) to our registered landlord(s) (“BSO Landlord”), and/or to our registered tenant(s) (“BSO Tenant”), and/or our registered vendor(s) (“BSO Vendor”), and/or our registered real estate broker(s) (“BSO Broker”, or “you”, or “your”), any other user type(s) registered with BSO, and/or any other third party service provider(s) associated or engaged with BSO;
                </p>
                <p>
          1.2 These Terms, together with the Privacy Policy and the terms against any services and/or additional services (“Add-on”) offered in BSO Mobile App (“Services Terms”, “T&C”, “Particulars”, “Schedule I”, “Schedule II”, “Undertaking Terms” and/or any other set terms linked to the functionality of BSO Mobile App), govern your access to and use of BSO Mobile App, including any content, functionality and services offered to you. These Terms supersede any previous agreements you have previously
signed and agreed to with BSO at any earlier stage;
                </p>
                <p>
          1.3 Please read these Terms before using BSO Mobile App. Your access to and use of BSO Mobile App is conditioned on your acceptance and compliance with these Terms. By accessing and/or using BSO Mobile App, you agree to be bound by these Terms. If you disagree with any part(s) of the Terms, then you may not register any account on BSO Mobile App or access the services provided.
                </p>
                <h4>2. ACCESS & USE OF BSO MOBILE APP </h4>
                <p>
          2.1 You, as a registered BSO Broker of BSO Mobile App, agree to access and use BSO Mobile App only for lawful purposes. You are solely responsible for the knowledge of and adherence to any, and all laws, statutes, rules and regulations pertaining to your use of BSO Mobile App;
                </p>
                <p>
          2.2 In order to utilize any of our services, you will be asked to register for an account and provide us with certain personal information, and a required set of documents and information (i.e., advertising and/or marketing permit number(s), valid practicing license copy, passport copy and/or country ID copy, residency copy, email, mobile number, debit and/or credit card details, etc.). You agree to provide accurate and current information and to update any submitted documents as necessary to maintain its validity and accuracy. You agree not to mask your identity by providing false information about BSO, or by providing another person’s information that you are not authorised to provide:
                </p>
                <p>
          2.2.1 Failure to provide any document, information and/or other matter(s) to BSO after ninety (90) days of initial registration and/or providing any false information in relation to any submitted documents may result in the suspension and/or termination of your account;
                </p>
                <p>
          2.2.2 You are only authorised to present yourself as a BSO Broker to the public and may not alter and/or change the status assigned to you by BSO under these Terms. If we discover that you have masked your identity and/or the true nature of your relationship to BSO, BSO Mobile App and/or to any of our affiliates, we may suspend and/or terminate your use of BSO Mobile App.
                </p>
                <p>
          2.3 BSO shall maintain and use your information in accordance with the Privacy Policy and may request from you to update and/or add any document(s), license(s), and/or contact information at any stage through and/or after your registration period on BSO Mobile App. Failure to do so may result in the suspension or termination of your account;
                </p>
                <p>
          2.4 BSO may ask you to provide a clearance certificate and/or background check document(s) from an authorizing UAE institute (i.e., Abu Dhabi City Municipality, Al Ain Municipality, Dubai Land Department, Sharjah Real Estate Registration Department, Ajman Department of Land and Real Estate Regulation, Ras Al Khaimah Land Department, Umm Al Quwain Land Department, Fujairah Municipality, DMA, RERA, Trakheesi, Al Etihad Credit Bureau, etc.). You accept to provide such document(s) to BSO at any time you are requested to fulfill this requirement by BSO. Failure to provide a requested clearance certificate document and/or any other background check document may result in the suspension and/or termination of your account;
                </p>
                <p>2.5 You are required to maintain and store your documents and information submitted (i.e., power of attorney(s), certificate(s), authorization letter(s), etc.) and information received from BSO (i.e., advertising permit(s), marketing form(s), contract(s), authorization letter(s), etc.) via BSO Mobile App, email and/or via any other platform(s) in a confidential manner and not disclose or make accessible such information;</p>
                <p>2.6 Upon registration, you are required to provide your personal debit and/or credit card (“Card”) details on BSO Mobile App. You are responsible to store these details in a confidential and sensible manner and not make accessible such information to anyone else. BSO will only charge you through your Card details as per your authorization and/or agreement on the BSO Mobile App;</p>
                <p>2.7 You agree that you are responsible for maintaining security of any passwords and/or other registration information. You agree to notify us of any unauthorized use of your password and/or other registration information immediately after you become aware of such use. BSO will not be responsible for any losses arising out of the unauthorized use of your registration information and/or your account. We may require your fingerprint and/or face ID recognition for the access and use of BSO Mobile App. You may choose to opt-out of providing the above methods for providing your credentials and only select to use a standard password;</p>
                <p>2.8 By providing your email address to BSO, you consent for the use of it to send you service-related notices, including among other things notices required by law. We may also send you commercial emails on behalf of ourselves, our affiliates, and certain unaffiliated third party. You may opt-out of receiving such emails at any time by following the un-subscription instructions included in each commercial email;</p>
                <p>2.9 You may only have one BSO Broker account on BSO Mobile App. If we discover multiple BSO Broker accounts under your name at the same time, we reserve the right to cancel them and even to ban your access to BSO Mobile App and all other platforms of BSO;</p>
                <p>2.10 BSO Mobile App features a blacklist option that stores the records of all accounts terminated and/or banned. You agree that if you violate any laws and/or performed and/or become involved in any unethical activity that jeopardize the safety and/or security of all registered users of BSO Mobile App, your account and related activity details may be reported to the related authorities and/or circulated to other registered users of BSO Mobile App;</p>
                <p>2.11 BSO Mobile App contains a distinct profile page for each of its registered users, in which they may be required to share personal information such as name, description, areas of operation and other applicable details for service exchange and online communication services. You agree that you acknowledge that the information you may be requested to share on your profile will be accessible and shared to other registered users of BSO Mobile App at all times;</p>
                <p>2.12 By using BSO Mobile App and its services, you agree to act towards BSO, conscientiously and in good faith and not allow any personal and/or external interests to conflict with your duties and obligations towards BSO. You may not act in any way that will or may potentially incur any liabilities on behalf of BSO;</p>
                <p>2.13 BSO uses third-party providers and/or other affiliates for publishing property listings, advertised properties, contact details of all registered users on BSO Mobile App and/or otherwise on third party platforms and/or via BSO Mobile App. By using BSO Mobile App, you agree to the use and access of your contact details by any third party(s) that BSO may work with and agree that any properties assigned to you may be published via any other third-party platform other than BSO Mobile App;</p>
                <p>2.14 BSO uses third party providers and/or other affiliates for real estate brokerage services, maintenance services, moving and packing services, cleaning services, legal services and any other services related to properties and real estate industry in general. By using BSO Mobile App, you agree to the use and access of your contact details by any third party that BSO may work with;</p>
                <p>2.15 You acknowledge and agree to be contacted by other registered users of BSO Mobile App. If you wish to restrict other registered users from contacting you through BSO Mobile App, you should adjust accordingly your chat settings, which you can find in profile settings of BSO Mobile App.</p>
                <h4> 3. SUBSCRIPTION PLAN</h4>
                <p>
          3.1 BSO Mobile App offers its services through a paid subscription-based scheme. You can access the service(s) offered on BSO Mobile App upon first payment and submission of any required documents and will be able to continue using the service(s) of BSO Mobile App until a selected subscription plan is cancelled;
                </p>
                <p>
          3.2 BSO may at any time change and/or alternate between Payment Providers throughout your subscription cycle without any prior notification. This will not affect your subscription plan's rate nor incur any extra charges on you:
                </p>
                <p>
          3.2.1 In case BSO terminates its agreement and the use of the services of any of the Payment Provider(s) and/or adds a new a payment gateway service provider to BSO Mobile App, BSO will notify you with such change and may request that you re-enter your payment details on BSO Mobile App;
                </p>
                <p>
          3.2.2 All fees and charges are quoted and billed in Arab Emirate Dirhams “AED”. A Value Added Tax “VAT” of five (5) percent (%) will be added to purchased service(s) on BSO Mobile App.
                </p>
                <p>
          3.3 Upon registration and selection of a certain subscription, you will be requested to enter your Card details and will be automatically charged at the start of each subscription cycle with the predefined fees and an additional VAT of five (5) percent (%). All subscription cycles begin as soon as initial payment is processed and will automatically renew at the start of each cycle (i.e., weekly, monthly, semi-annually or annually), depending on the plan and payment method requested, without notice until cancellation:
                </p>
                <p>
          3.3.1 BSO, at its’ sole discretion, may offer a complimentary subscription period ("Free Trial Period") on any of BSO Mobile App paid subscriptions to any BSO Broker after selecting and registering to any of BSO Mobile App paid subscription plans. All services offered under the Free Trial Period are subject to the Terms and are provided to you without any charge until the end of the Free Trial Period. The duration of Free Trial Period is set by BSO and at sole discretion of BSO. During the Free Trial Period, all Card details will be required as per the normal procedure. You may be charged for a small fee upon signing up and will not be charged for any subscription services during the Free Trial Period. Upon signing up for the Free Trial Period, you hereby consent that at the end of your trial, you will be charged for the subscription plan selected, except if the selected plan is cancelled during the Free Trial Period;
                </p>
                <p>
          3.3.2 Subscription plan description, pricing, terms of its’ use and delivery will be provided to you during each time of selected subscription plan is activated and/or renewed via BSO Mobile App. You acknowledge and agree that by making successful payment for the selected subscription plan, you accept the entire subscription plan description, pricing, terms of its’ use and delivery. BSO offers four (4) following subscription plans to BSO Broker:
                </p>
                <p>
          3.3.2.1 <span className='fw-bold'>Free Plan</span> provides BSO Broker with a limited profile setting and limited access to the main features of BSO Mobile App, and unlimited access to the menu of Add-On features of BSO Mobile App, excluding access to all features under the Accelerator Plan;
                </p>
                <p>
          3.3.2.2 <span className='fw-bold'>Standard Plan</span> provides BSO Broker with a limited profile setting, limited discount to any Add-on(s), and unlimited access to the main and the menu of Add-On features of BSO Mobile App, excluding access to all features under the Accelerator Plan;
                </p>
                <p>
          3.3.2.3 <span className='fw-bold'>Premium Plan</span> provides BSO Broker with unlimited profile setting, limited discount to any Add-on(s), and full access to the main and the menu of Add-On features of BSO Mobile App, excluding access to all features under the Accelerator Plan;
                </p>
                <p>
          3.3.2.4 <span className='fw-bold'>Accelerator Plan</span> provides BSO Broker with a number of features (i.e., statistical and/or analytical support, educational tips, insights and practical knowledge related to the industry, useful tools and information, and etc.) from BSO in addition to any of already active subscription plan.
                </p>
                <p>
          3.4 Upon renewal of your subscription cycle, we will automatically try your primary payment method for the predefined subscription fees. If your primary payment method fails, you authorize us to charge any other payment method(s) in your account, until any outstanding amounts are settled. If you have not provided a backup payment method(s) and you fail to provide payment, or if all payment methods in your account fail, the BSO will suspend your account until you update your payment method and settle any outstanding amounts successfully;
                </p>
                <p>
          3.5 BSO Mobile App uses three (3) independent automated Payment Providers: the Etisalat Group and/or the Network International and/or Stripe, simultaneously or otherwise, to exclusively store, collect and process all payments done through BSO Mobile App. BSO has no access to the payment data you store under Payment Provider(s)' portal(s), but reserves the right to instruct Payment Provider(s) to charge your Card for outstanding payments and track all settled, outstanding and due payments to BSO;
                </p>
                <p>
          3.6 We may change our subscriptions’ rates at any time, and we will notify you of any rate change with the option to cancel. In case of any change of your subscriptions’ rate, you will not be charged any extra fees for the prepaid cycle and will only be charged upon the renewal of the cycle or the beginning of the new one;
                </p>
                <p>
          3.7 You may cancel your subscription plan at any time during your cycle. You will not be refunded any amounts paid to BSO, nor receive or withdraw the remaining bonus credits given to you by BSO for your referrals to BSO (“Referral”) and/or for outstanding performance as a BSO Broker (“BSO Credits”) or bonus credits (“Brick”) for unused services (i.e., bonus credits for Add-on service of BSO granting you advertisement permits to make advertisement of any property from BSO Mobile App with any third party marketing portals associated and actively used by suppliers and/or clients in your preferred business areas and city and/or Emirate, and/or bonus credits for Add-on service of BSO online chat (“BSO Chat”) offering a BSO Chat marketing feature (“BSO Chat Broadcast”) between you and all BSO Broker(s) against any of your registered properties in BSO Mobile App and not managed by BSO (“My Unit”) and/or your registered clients in BSO Mobile App (“My Client”) for the purposes of sharing a one-time promotional message in BSO Chat, and etc.) if you decide to terminate or suspend your account before the end of your subscription cycle.
                </p>
                <h4>4. ADD-ON FEATURES </h4>
                <p>
          4.1 BSO Mobile App features an option that allows you to purchase any Add-On service(s) (i.e., upgrades, and/or purchase new service(s)) to your existing subscription on BSO Mobile App at any time through your subscription cycle. You may only purchase an Add-On if you have already subscribed to a service on BSO Mobile App and may not purchase an Add-On without an existing and valid subscription;
                </p>
                <p>
          4.2 BSO may offer an Add-On as a one-time service, as a bundle service(s) and/or package of multiple services. You may not refund, exchange, and/or solicit any Add-On(s) you purchase on BSO Mobile App;
                </p>
                <p>
          4.3 Add-On description, pricing, terms of its’ use and delivery will be provided to you during each time of selected Add-On is activated and/or renewed via BSO Mobile App. You acknowledge and agree that by making successful payment for the selected Add-On, you accept the entire Add-On description, pricing, terms of its’ use and delivery. BSO offers Add-On features to BSO Broker as a supervisor between BSO Broker, BSO, BSO Landlord(s), BSO Tenant(s), BSO Vendor(s) and other third-party service provider(s).
                </p>
                <h4> 5. RESPONSIBILITIES & DUTIES OF A BSO BROKER</h4>
                <p>
                    {' '}
          5.1 You must comply with all the terms, guidelines and regulations set out by BSO, in relation to your account registration with BSO Mobile App, verify your mobile number and email before the use of BSO Mobile App. If you wish to change your password and/or mobile number and/or email, you may do it using your settings section in BSO Mobile App and you will have to verify your new mobile number and new email before the next use of BSO Mobile App;
                </p>
                <p>
          5.2 You must comply with all the terms, guidelines and regulations set out by BSO, in relation to your profile status in BSO Mobile App, complete submission of your profile information and documentation, set up your preferred business areas and city and/or Emirate in accordance with your subscription plan. If you wish to get the status of “Verified BSO Broker” and to receive more benefits from BSO, deeper accessibility to BSO Mobile App, you may submit your valid ID for verification through your settings section in BSO Mobile App. If you wish to get the status of “Licensed BSO Broker” and receive better recognition from other BSO Broker(s), BSO, BSO Landlord(s), BSO Tenant(s), BSO Vendor(s), you may submit your valid practicing license for verification through your settings section in BSO Mobile App;
                </p>
                <p>
          5.3 You must comply with all the terms, guidelines and regulations set out by BSO, in relation to marketing, advertisement (“Property Advertisement” or “PA”), booking a viewing (“Property Viewing” or “PV” i.e., client communication, booking a property visit, procedure of a property viewing and related processes, etc.) of any of the properties managed by BSO (“BSO Unit”) and/or any of the properties registered in BSO Mobile App directly by any BSO Landlord (“Listed Unit”) and/or any other of your properties registered in BSO Mobile App as My Unit(s), and not managed by BSO, with your client and/or BSO Tenant, registering a rental or sale offer(s) on any of BSO Unit(s) and/or Listed Unit(s) (“Potential Offer” or “PO”) with a client, obtaining and/or progressing request(s) on any of the BSO Vendor’s services (“Work Order” or “WO”) with your client and/or on behalf of your client, progressing the Work Order and/or rental or sale transactions on any of BSO Unit(s) and/or Listed Unit(s) and/or My Unit(s) (“User Transaction” or “UT”) with your client and/or any other matters that BSO may require you to comply with through any stage during your agreement with BSO:
                </p>
                <p>
          5.3.1 <span className='fw-bold'>Property Advertisement: </span>prior to a Property Advertisement, you must select BSO Unit or Listed Unit via BSO Mobile App as per the following steps (any of My Unit(s) will not be supported with this functionality of BSO Mobile App):
                </p>
                <p>
          5.3.1.1 Click the button “ADVERTISE” in BSO Mobile App to submit a new Property Advertisement request;
                </p>
                <p>
          5.3.1.2 Make sure your balance of available Brick(s) is equal to or higher than required amount by BSO for making such Property Advertisement request. Property Advertisement request on each different property, whether it’s a BSO Unit and/or Listed Unit, may require a different amount of Brick(s) spent by you against any such Property Advertisement request, and may be set for a different advertisement duration, subject to the property location, property type, property lease or sale advertisement type, property demand classification, your profile rating in BSO Mobile App (“BSO Broker Rating” i.e., performance score), and/or Terms set activating Add-On(s) of BSO Mobile App;
                </p>
                <p>
          5.3.1.3 Make sure your BSO Broker Rating is equal to or higher than required by BSO in order to receive Property Advertisement permit successfully, and you don’t have any restrictions related to the use of BSO Mobile App;
                </p>
                <p>
          5.3.1.4 Select a BSO Unit or Listed Unit for your Property Advertisement. Select Property Advertisement duration (if applicable). Make sure your selected property is matching to your preferred business areas and city and/or Emirate;
                </p>
                <p>5.3.1.5 Review your request;</p>
                <p>
          5.3.1.6 Once Property Advertisement request is made, your Brick(s) against it will be spent and will not be refunded to you for any reason;
                </p>
                <p>5.3.1.7 Submit Property Advertisement request, where:</p>
                <p>
          a. Property Advertisement requests related to BSO Unit(s) will be reviewed by BSO and approved instantly with respective Property Advertisement permit if your submitted Property Advertisement request is made in accordance with these Terms, if your balance of available Brick(s) is equal to or higher than required amount by BSO for making such Property Advertisement request, if your BSO Broker Rating is equal to or higher than required by BSO, if the subject property is not already advertised by three (3) other BSO Brokers and/or three (3) other real estate brokers as per the set regulations of the relevant authorities, and/ or if your subscription plan is active;
                </p>
                <p>
          b. Property Advertisement requests related to Listed Unit(s) will be reviewed by BSO Landlord and approved instantly with respective Property Advertisement permit if your submitted Property Advertisement request is made in accordance with these Terms, if your balance of available Brick(s) is equal to or higher than required amount by BSO for making such Property Advertisement request, if the subject property is not already advertised by three (3) other BSO Brokers and/or three (3) other real estate brokers as per the set regulations of the relevant authorities, if your BSO Broker Rating is equal to or higher than required by BSO, and/ or if your subscription plan is active;
                </p>
                <p>
          c. As per the regulations set by the relevant authorities, you must know, that obtaining respective permits against any real estate advertisement activities is restricted to a maximum of three (3) real estate brokers at a time. Therefore, after receiving the Property Advertisement permit, you must use it to obtain the respective permits from the relevant authorities, and provide BSO via BSO Mobile App with the active QR code permit and/or the link of your Property Advertisement on any marketing portals associated and actively used by suppliers and/or clients in your preferred business areas and city and/or Emirate within three (3) calendar days from the date you requested your Property Advertising permit from BSO and/or BSO Landlord;
                </p>
                <p>
          d. Failure to obtain the respective permits from the relevant authorities and to provide BSO with the active QR code permit and/or the link of your Property Advertisement on any marketing portals associated and actively used by suppliers and/or clients in your preferred business areas and city and/or Emirate on time as set in these Terms will result on cancellation of your Property Advertisement request by BSO and/or BSO Landlord. BSO and/or BSO Landlord may also allow other BSO Brokers to receive Property Advertisement permits to the subject property before you decide to try it again. In such event all Brick(s) spent by you for this Property Advertisement request will not be refunded to you for any reason;
                </p>
                <p>
          e. Should you obtain the respective permits from the relevant authorities and provide BSO with the active QR code permit and/or the link of your Property Advertisement on any marketing portals associated and actively used by suppliers and/or clients in your preferred business areas and city and/or Emirate on time as set in these Terms, your Property Advertisement permit will remain active so you can use it until the date of your Property Advertisement permit expiry, which is always mentioned to you at the time of your Property Advertisement request is submitted, and/or until a BSO Unit and/or Listed Unit is leased or sold, and/or until your Property Advertisement permit is cancelled by BSO and/or BSO Landlord due to any breach to these Terms made by you, and/or until relevant instructions are given to you by BSO, whichever comes first;
                </p>
                <p>
          5.3.1.8 BSO may provide you with information and/or documentation (i.e., marketing and/or advertisement material, property documentation and/or documentation related to the property ownership, etc.) related to BSO Unit(s) through BSO Mobile App and/or by email. You shall display and portray BSO Unit(s) as provided by BSO and not make any amendments to the price of BSO Unit(s), add and/or change written statements, alter any logos or images changes (i.e., Photoshop, etc.) and/or other changes on the content provided to you by BSO;
                </p>
                <p>
          5.3.1.9 BSO Landlord(s) may provide you with information and/or documentation (i.e., marketing and/or advertisement material, property documentation and/or documentation related to the property ownership, etc.) related to Listed Unit(s) through BSO Mobile App and/or by email. You shall display and portray Listed Unit(s) as provided by BSO Landlord(s) and not make any amendments to the price of Listed Unit(s), add and/or change written statements, alter any logos or images changes (i.e., Photoshop, etc.) and/or other changes on the content provided to you by BSO Landlord(s);
                </p>
                <p>
          5.3.1.10 You are not allowed to share any of the provided information and/or documentation (i.e., marketing and/or advertisement material, property documentation and/or documentation related to the property ownership, etc.) by BSO and/or via BSO Mobile App by BSO Landlord(s), BSO Tenant(s), BSO Vendor(s) or any other user type(s) registered with BSO, and/or any other third party service provider(s) associated or engaged with BSO, with any third parties, except when required by law;
                </p>
                <p>
          5.3.1.11 You are only allowed to use the provided information and/or documentation (i.e., marketing and/or advertisement material, property documentation and/or documentation related to the property ownership, etc.) until the date of expiry of your Property Advertisement permit, and/or until a BSO Unit and/or Listed Unit is leased or sold, and/or until the Property Advertisement is cancelled by BSO and/or BSO Landlord, and/or until relevant instructions are given to you by BSO, whichever comes first;
                </p>
                <p>
          5.3.1.12 You are not allowed to use the provided information and/or documentation (i.e., marketing and/or advertisement material, property documentation and/or documentation related to the property ownership, etc.) for your personal or any other benefit other that described under these Terms.
                </p>
                <p>
          5.3.2 <span className='fw-bold'>Property Viewing: </span>  prior to a Property Viewing, you must book the exact Property Viewing of your choice via BSO Mobile App as per the following steps:
                </p>
                <p>
          5.3.2.1 Click the button “VIEW” in BSO Mobile App to submit a new Property Viewing;
                </p>
                <p>
          5.3.2.2 Select a BSO Unit, or Listed Unit, or My Unit for your Property Viewing;
                </p>
                <p>
          5.3.2.3 Invite other BSO Broker if a Property Viewing is to be made against any of your registered properties as My Unit with the client of another BSO Broker, or if you have a client and a Property Viewing is to be made against My Unit, that belongs to another BSO Broker (if applicable);
                </p>
                <p>
          5.3.2.4 Select the date and time of your Property Viewing matching with set availability and accessibility of the selected property;
                </p>
                <p>5.3.2.5 Set the reminder (if applicable);</p>
                <p>5.3.2.6 Review your request;</p>
                <p>5.3.2.7 Submit Property Viewing request, where:</p>
                <p>
          a. Property Viewing for a Listed Unit will be reviewed by BSO Landlord directly for further approvals, rejections and/or suggestions to reschedule it if a Property Viewing can’t be organized at the requested date and time or for some other reasons. If a Property Viewing is approved you will be notified via BSO Mobile App to get the necessary viewing pass and/or non-objective certificate and/or letter if required by the building or community security offices of a Listed Unit and proceed accordingly. If a Property Viewing needs to be rescheduled, you will be notified via BSO Mobile App to amend your Property Viewing booking request accordingly. If a Listed Unit is locked before Property Viewing is organized, you may be requested to pick up the necessary key(s), access card(s) and/or remote control(s) from the BSO Landlord directly. BSO is not responsible for Property Viewing(s) on Listed Unit(s), as we may be limited or not permitted to manage these properties and the functionality of booking a Property Viewing on Listed Unit(s) is available to you for convenience purposes only;
                </p>
                <p>
          b. Property Viewing for a BSO Unit will be reviewed by BSO for further approvals, rejections and/or suggestions to reschedule it if a Property Viewing can’t be organized at the requested date and time or for some other reasons. If a Property Viewing is approved you will be notified via BSO Mobile App to get the necessary viewing pass or non-objective certificate and/or letter if required by the building or community security offices of BSO Unit and proceed accordingly. If a Property Viewing needs to be rescheduled, you will be notified via BSO Mobile App to amend your Property Viewing booking request accordingly. If BSO Unit has an electronic smart lock, you will be provided with an expiring pass code (“Code”) to be able to access BSO Unit. You are not allowed to share Code(s) with any third party at any time without a written consent of BSO. If a BSO Unit is manually locked and doesn’t have an electronic smart lock before Property Viewing is organized, you will be requested to pick up the necessary key(s), access card(s) and/or remote control(s) from the office of BSO;
                </p>
                <p>
          c. Property Viewing related to My Unit will be booked immediately or after another BSO Broker approves your invitation so you can proceed accordingly. BSO is not responsible for Property Viewing(s) on My Unit(s), as we do not manage these properties and the functionality of booking a Property Viewing on My Unit(s) is available to you for convenience purposes only;
                </p>
                <p>
          5.3.2.8 You must equally return any collected key(s), access card(s) and/or remote control(s) of BSO Unit(s) and/or Listed Unit(s) on the same date when collected from the office of BSO and/or from BSO Landlord directly. Your failure to return any collected keys, and/or access cards, and/or remote controls of the property will lead you to pay a penalty to BSO equal to thirty-five (35) AED for each additional day when the return is not made on time. Loss of any collected items will fall under your complete responsibility and will require a financial compensation, at the sole discretion of BSO and/or BSO Landlord. The cost of the replacement starts from three hundred fifty (350) AED per one (1) item and may increase without limitation depending on the item type and replacement procedure;
                </p>
                <p>
          5.3.2.9 You must be present during all of your Property Viewing(s) with your clients and/or clients of other invited to Property Viewing BSO Broker(s) and should ensure complete responsibility and care towards any of BSO Unit(s), and/or Listed Unit(s), and/or My Unit(s) and all its asset's and services (i.e., prohibited use of bathrooms, kitchens, air conditioning equipment, electrical equipment, gas equipment of a property, switches of electric equipment and water, closing of doors, locking of property(s), etc.) and/or any other duties of care and responsibility required towards BSO Unit(s), and/or Listed Unit(s), and/or My Unit(s), its assets and services available during the time of a Property Viewing:
                </p>
                <p>
          a. You must ensure that all your clients are equally responsible to carry on all duties towards BSO Unit(s), and/or Listed Unit(s), and/or My Unit(s). Any damages inflicted by you and/or a client must be immediately reported by you to BSO, through contacting BSO immediately during a Property Viewing and following all guidelines requested by BSO;
                </p>
                <p>
          b. Failure to provide due diligent control of all mentioned above and below responsibilities will lead to BSO implementing financial penalties on you against caused damages by you and/or your client and further suspension and/or termination of your account in BSO Mobile App. Any damages, service exploitation, irresponsible behavior or otherwise caused during any of your onsite Property Viewing(s), will be your sole responsibility.
                </p>
                <p>
          5.3.2.10 After your Property Viewing on a BSO Unit and/or Listed Unit is completed, you will be requested to submit your feedback and/or complaint, where you are responsible to provide true information about entire process of your Property Viewing to BSO and/or to BSO Landlord. You may further register a Potential Offer on the same BSO Unit and/or Listed Unit (if applicable);
                </p>
                <p>
          5.3.2.11 If your Property Viewing was requested, then approved by BSO or BSO Landlord, but was made later that the agreed date and/or time or wasn’t completed by you due to any reason, you are responsible to inform BSO or BSO Landlord about it. Continuous failure to complete your Property Viewing on time and/or failure to be personally present during your Property Viewing will lead to BSO implementing adjustments to your BSO Broker Rating and further suspension and/or termination of your account in BSO Mobile App. You may see the history of your Property Viewing(s) in your tasks section in BSO Mobile App.
                </p>
                <p>
          5.3.3 <span className='fw-bold'>Potential Offer: </span> you must comply with all the procedural forms required by BSO to be submitted for any of your Potential Offer(s) and must equally submit the requested documentation to BSO or BSO Landlord for the finalization of a BSO Unit's and/or Listed Unit’s rental or sale agreements (any of My Unit(s) will not be supported with this functionality of BSO Mobile App). You must register the Potential Offer via BSO Mobile App following below steps:
                </p>
                <p>
          5.3.3.1 Click the button “OFFER” in BSO Mobile App to submit a new
          Potential Offer;
                </p>
                <p>5.3.3.2 Select the type of your Potential Offer (if applicable);</p>
                <p>
          5.3.3.3 Select a BSO Unit or a Listed Unit for your Potential Offer;
                </p>
                <p>
          5.3.3.4 Mention the full name of your client and provide all required
          information about your client;
                </p>
                <p>
          5.3.3.5 Provide all required information about your Potential Offer;
                </p>
                <p>
          5.3.3.6 Upload all necessary documents of your client to support your
          Potential Offer;
                </p>
                <p>5.3.3.7 Review your request;</p>
                <p>
          5.3.3.8 Submit your Potential Offer request, where it will be reviewed by BSO and/or by BSO Landlord directly for further approvals, negotiations, rejections and/or suggestions to provide additional information and/or documentation of your client if Potential Offer can’t be approved at the initial format or for some other reasons. If a Potential Offer is approved, you will be notified via BSO Mobile App to complete the necessary steps accordingly. On the event of any negotiation on your Potential Offer and/or if your Potential Offer needs to be supported with additional information and/or documentation, you will be notified via BSO Mobile App to negotiate and/or support your Potential Offer request accordingly. You may see the history of your Potential Offer(s) in your tasks section in BSO Mobile App.
                </p>
                <p>
          5.3.4 <span className='fw-bold'>Work Order: </span> prior to obtaining a Work Order, you must submit your request via BSO Mobile App as a one-time Referral, providing complete and accurate information related to a desired Work Order, where you will be offered to appoint a BSO Vendor(s) on a number of services as per the following steps:
                </p>
                <p>
          5.3.4.1 Click the button “REFER” in BSO Mobile App to obtain a new Work Order;
                </p>
                <p>
          5.3.4.2 Select the service to refer which matches to a desired Work Order you wish to obtain;
                </p>
                <p>
          5.3.4.3 Provide all required information and fill up the form with complete and accurate detailed of a desired Work Order you wish to obtain;
                </p>
                <p>
          5.3.4.4 Upload all necessary documents and/or pictures of a desired Work Order you wish to obtain;
                </p>
                <p>5.3.4.5 Review your request;</p>
                <p>
          5.3.4.6 Submit Work Order request, where it will be reviewed by BSO and/or by BSO Vendor directly for further acceptances, negotiations, progressions, rejections and/or suggestions to provide additional information and/or documentation related to the referred Work Order if it can’t be progressed for any reasons;
                </p>
                <p>
          5.3.4.7 If a Work Order is accepted by a BSO Vendor, you will be notified via BSO Mobile App and supported with the relevant updates on the completion of the submitted Work Order. You must comply with all the procedural forms required by BSO to be followed for any of your submitted Work Order(s) and must equally respond to instructions given by BSO and/or BSO Vendor for successful completion of your submitted Work Order(s). Status of all your pending, and/or ongoing, and/or completed referred Work Order(s) is available through your referrals section in BSO Mobile App.
                </p>
                <p>
          5.3.5 <span className='fw-bold'>User Transaction: </span>you must comply with all the procedural forms required by BSO and/or BSO Landlord and/or BSO Tenant to be followed for any of your User Transaction(s) and must equally respond to instructions given by BSO and/or BSO Landlord and/or BSO Tenant, submit the requested documentation to BSO and/or to BSO Landlord and/or BSO Tenant for successful completion of a BSO Unit's and/or Listed Unit’s rental or sale transaction(s) (any of My Unit(s) will not be supported with this functionality of BSO Mobile App). You may see the history of your User Transaction(s) in your tasks section in BSO Mobile App.
                </p>
                <p>
          5.4 You shall comply with all instructions provided by BSO in relation to performing any requested or agreed-upon services, and generally carry on any of BSO related businesses in such a manner that best promotes the interests of BSO;
                </p>
                <p>
          5.5 You may not delegate and/or transfer any of your duties, services, obligation and/or any requests given to you by BSO to any other BSO Broker(s) and/or other third party, unless otherwise instructed by BSO or after obtaining their necessary approvals on writing or via BSO Mobile app;
                </p>
                <p>
          5.6 You may not offer, sale and/or solicit any of your products and services and/or products and services of any third party you represent to any subscribers of BSO, suppliers of BSO and/or customers of BSO (i.e., BSO Landlords, BSO Tenants, BSO Brokers, BSO Vendors, suppliers, service providers, etc.) directly or indirectly without a written approval of BSO. You acknowledge and agree that failure to follow this clause will give BSO the right to terminate your use of BSO Mobile App;
                </p>
                <p>
          5.7 You are required to follow the professional targets and limits provided to you by BSO. You acknowledge and agree that failure to follow these targets and limits will give BSO the right to terminate your use of BSO Mobile App;
                </p>
                <p>
          5.8 You are responsible to distribute, share and/or divide any revenue, profit and/or other financial splits between you and your employer, colleagues, and/or any other third party you conduct business with. BSO will not be responsible in the decision-making and/or any profit distribution between a BSO Broker and any other third party. BSO is not liable and holds no responsibility for any disputes arising of such matters;
                </p>
                <p>
          5.9 You acknowledge and agree that failure to follow these guidelines and/or violate any duties and obligations given to you by BSO directly and/or through BSO Mobile App, will give BSO the right to terminate your account and stop any pending, ongoing services between you and BSO, including any third party you work or have worked with, through BSO Mobile App and/or any other BSO platform(s). If BSO suspects that you have violated these terms, BSO has the right to take any legal actions necessary to ensure your compliance with your end of service obligations.
                </p>
                <h4> 6. CREDIT COLLECTION</h4>
                <p>
                    {' '}
          6.1 As a BSO Broker, you are eligible to collect, earn, and receive BSO Credit(s) through BSO Mobile App;
                </p>
                <p>6.2 Each collected BSO Credit will be equivalent to one (1) AED;</p>
                <p>6.3 You may earn BSO Credit(s) through:</p>
                <p>6.3.1 Referrals, as per clause 7;</p>
                <p>
          6.3.2 Participating in any limited edition, marketed package and/or plan introduced on BSO Mobile App;
                </p>
                <p>
          6.3.3 Becoming eligible through the membership bonus schemes (as per clause 8) and/or complimentary BSO Credit(s) imbursement at the sole discretion of BSO.
                </p>
                <p>
          6.4 BSO Credit(s) may not be refunded nor transferred from one account to any other account. All collected BSO Credit(s) can only be withdrawn as set out in clause 6.5 or used as set out in clause 6.6;
                </p>
                <p>
          6.5 You are only allowed to withdraw BSO Credit(s) once all your collected BSO Credit(s) reach to one thousand (1,000) BSO Credit(s), which is equivalent to one thousand (1,000) AED and may only continue to withdraw BSO Credit(s) through units of one thousand (1,000) BSO Credit(s) at a time. Withdrawal of BSO Credit(s) may occur through a bank transfer or a bank cheque. BSO Credit(s) withdrawal is available through your referrals section in BSO Mobile App;
                </p>
                <p>
          6.6 You may use your available BSO Credit(s) as a payment method against a number of Add-on(s) during your purchase, if your available balance of BSO Credit(s) is equal or more than required to activate respective Add-on.
                </p>
                <h4>7. REFERRAL BONUS SCHEME</h4>
                <p>
                    {' '}
          7.1 BSO Mobile App offers BSO Credits to BSO Broker(s) for performing any of the following referral activities:
                </p>
                <p>7.1.1 One-time referral of any Work Order services;</p>
                <p>
          7.1.2 Referring UAE real estate brokers to register on BSO Mobile App;
                </p>
                <p>
          7.1.3 Referring UAE landlords and/or UAE tenants to register on BSO
          Mobile App.
                </p>
                <p>
          7.2 <span className='fw-bold'>One-time Referral of any Work Order services: </span>if you refer a certain third party to BSO for any services listed under Work Order category as described below, you shall receive BSO Credit(s) equivalent to a one-time ten (10) percent (%) of the total payments made against the first service requested for such referral to BSO. You are only entitled to earn BSO Credit(s) for the first conducted service by BSO to the referred party and shall not be imbursed for any businesses conducted afterwards between BSO and the referred party. Such services are and are not restricted to:
                </p>
                <p>7.2.1 Property sale or property gifting supervision services;</p>
                <p>7.2.2 Rental dispute supervision services;</p>
                <p>7.2.3 Real estate powers appointment supervision services;</p>
                <p>7.2.4 Property inspections and/or hand over supervision services;</p>
                <p>7.2.5 Moving and packing supervision services;</p>
                <p>7.2.6 Property maintenance supervision services;</p>
                <p>7.2.7 Property cleaning supervision services;</p>
                <p>
          7.2.8 Any other services, which may be offered by BSO against your
          Referral(s) submission.
                </p>
                <p>
          7.3 <span className='fw-bold'>Referral of UAE real estate brokers to register on BSO Mobile App:</span>
          you will be credited with twenty (20) BSO Credit(s) upon referring any new broker to register on BSO Mobile App as a BSO Broker and subscribing to any subscription plan under BSO Mobile App;
                </p>
                <p>
          7.4 <span className='fw-bold'>Referral of UAE landlords and/or UAE tenants to register on BSO
          Mobile App: </span> you will be enrolled in the landlord and/or tenants referral scheme(s) and begin earning BSO Credit(s) after successfully referring any new landlord and/or any new tenant to register as a BSO Landlord and/or BSO Tenant on BSO Mobile App and make a first-time payment against any of BSO supervision subscription services (“Supervision Fee”) to BSO:
                </p>
                <p>
          7.4.1 BSO Credit(s) will be credited to you every time your referred BSO Landlord(s) or BSO Tenant(s) make a Supervision Fee payment to BSO under the condition that both you and your referred BSO Landlord(s) and/or BSO Tenant(s) remain registered and subscribed to a service(s) on BSO Mobile App;
                </p>
                <p>
          7.4.2 BSO Credits will be calculated to be a specific predetermined percentage (%) of all of your referred BSO Landlord(s)’ and/or BSO Tenant(s)’ Supervision Fee payment (if applicable). Terms and conditions of such predetermined percentage (%) along with Referral agreement will be provided to you prior to completing your referral application submission in BSO Mobile App.
                </p>
                <p>
          7.5 All BSO Credit(s) relating to any referral scheme(s) as per clause 7 will only be credited to you on the first (1) day of the following and applicable month after BSO receives the total due payment(s) from any third party referred by you (i.e., BSO Landlord(s), BSO Broker(s), etc.). Failure for a third party to settle any payments due to BSO, will result in the failure for you from earning BSO Credit(s);
                </p>
                <p>
          7.6 BSO will periodically revise the referral bonuses scheme terms and may at any time amend the content of the terms to reflect any changes.
                </p>
                <h4>8. MEMBERSHIPS </h4>
                <p>
                    {' '}
          8.1 BSO will offer three (3) membership options (the “Membership Tier”) on BSO Mobile App to you, as per the following categories:
                </p>
                <p>8.1.1 Bronze Membership Tier;</p>
                <p>8.1.2 Silver Membership Tier;</p>
                <p>8.1.3 Gold Membership Tier.</p>
                <p>
          8.2 You may automatically be eligible to a change of your Membership Tier (i.e., upgrade, downgrade) every thirty (30) calendar days from the date of registering your account with BSO Mobile App and as per the requirements set out in clauses 8.2 and 8.3. Each Membership Tier offers various benefits and conditions, as per the following specifics:
                </p>
                <p>
          8.2.1 <span className='fw-bold'>Bronze Membership Tier: </span>Upon registration, you will be automatically enrolled under a thirty (30) calendar days Bronze Membership Tier as the first primary Membership Tier level. Upon an automatic renewal of a thirty (30) calendar days enrollment to Bronze Membership Tier, and/or automatic downgrade from a thirty (30) calendar days enrollment to Silver Membership Tier to a thirty (30) calendar days enrollment to Bronze Membership Tier, and/or automatic downgrade from a thirty (30) calendar days enrollment to Gold Membership Tier to a thirty (30) calendar days enrollment to Bronze Membership Tier, you will be imbursed with a complimentary thirty (30) Brick(s) every period of thirty (30) calendar days of enrollment to Bronze Membership Tier, from the date above mentioned automatic renewal and/or automatic downgrade has happened;
                </p>
                <p>
          8.2.2 <span className='fw-bold'>Silver Membership Tier: </span>Upon an automatic renewal of a thirty (30) calendar days enrollment to Silver Membership Tier, and/or automatic upgrade from a thirty (30) calendar days enrollment to Bronze Membership Tier to a thirty (30) calendar days enrollment to Silver Membership Tier, and/or automatic downgrade from a thirty (30) calendar days enrollment to Gold Membership Tier to a thirty (30) calendar days enrollment to Silver Membership Tier, you will be imbursed with a complimentary fifty (50) Brick(s), every period of thirty (30) calendar days of enrollment to Silver Membership Tier, from the date above mentioned automatic renewal, and/or automatic upgrade, and/or automatic downgrade has happened. In the event, when you are already enrolled to a thirty (30) calendar days Silver Membership Tier and are not eligible to maintain Silver Membership Tier further, an automatic downgrade to a thirty (30) calendar days Bronze Membership Tier will happen by the date of expiry of your enrollment to a thirty (30) calendar days Silver Membership Tier, where you will stop receiving above described benefits under Silver Membership Tier such as complimentary Brick(s) until another time you are eligible to a thirty (30) calendar days enrollment to Silver Membership Tier. In order to get upgrade to and/or maintain Silver Membership Tier, the conditions that are required to be satisfied, from the last thirty (30) calendar days to the date of automatic renewal, or automatic upgrade, or automatic downgrade, are as follows:
                </p>
                <p>
          8.2.2.1 Completion of one (1) Referral of a UAE landlord through BSO
          Mobile App;
                </p>
                <p>
          8.2.2.2 Maintaining your BSO Broker Rating at the score equal to or
          higher than the score of five point nine out of ten (5.9/10).
                </p>
                <p>
          8.2.3 <span className='fw-bold'>Gold Membership Tier: </span>Upon an automatic renewal of a thirty (30) calendar days enrollment to Gold Membership Tier, and/or automatic upgrade from a thirty (30) calendar days enrollment to Bronze Membership Tier to a thirty (30) calendar days enrollment to Gold Membership Tier, and/or automatic upgrade from a thirty (30) calendar days enrollment to Silver Membership Tier to a thirty (30) calendar days enrollment to Gold Membership Tier, you will be imbursed with a complimentary one hundred (100) Brick(s), every period of thirty (30) calendar days of enrollment to Gold Membership Tier, from the date above mentioned automatic renewal, and/or automatic upgrade has happened. In the event, when you are already enrolled to a thirty (30) calendar days Gold Membership Tier and are not eligible to maintain Gold Membership Tier further, an automatic downgrade to a thirty (30) calendar days Silver Membership Tier or an automatic downgrade to a thirty (30) calendar days Bronze Membership Tier (whichever you are eligible to by that time) will happen by the date of expiry of your enrollment to a thirty (30) calendar days Gold Membership Tier, where you will stop receiving above described benefits under Gold Membership Tier such as complimentary Brick(s) until another time you are eligible to a thirty (30) calendar days enrollment to Gold Membership Tier. In order to get upgrade to and/or maintain Gold Membership Tier you must bring your completed User Transaction(s) to the level equal to or higher than AED 1,000,000.00 (one million) through BSO Mobile App from the date of registering your account with BSO Mobile App to the date of automatic renewal or automatic upgrade, the conditions that are required to be satisfied, from the last thirty (30) calendar days to the date of automatic renewal or automatic upgrade, are as follows:
                </p>
                <p>
          8.2.3.1 Completion of three (3) Referral(s) of a UAE landlord through BSO Mobile App;
                </p>
                <p>
          8.2.3.2 Maintaining your BSO Broker Rating at the score equal to or higher than the score of six point nine out of ten (6.9/10);
                </p>
                <p>
          8.2.3.3 Holding a Licensed BSO Broker status of your account in BSO Mobile App;
                </p>
                <p>
                8.2.3.4 Holding zero (0) active complaints against your BSO Broker account.
                </p>
                <p>
          8.3 Failure to maintain your Membership Tier (i.e., failure to maintain the requirements and conditions as set out in clause 8.2) will result in the downgrade of your Membership Tier to the Membership Tier preceding it or to the first primary Membership Tier level accordingly. In case of a downgrade to a lower-level Membership Tier(s), you will become eligible to the benefits and requirements set out for the related Membership Tier, as per clause 8.2, excluding the benefit of any initial and one-time imbursements for Brick(s) if any.
                </p>
                <h4>9. RATING</h4>
                <p>
                    {' '}
          9.1 BSO will provide a BSO Broker Rating for every BSO Broker including you, BSO Landlord profile rating in BSO Mobile App (“BSO Landlord Rating”) for every BSO Landlord, BSO Tenant profile rating in BSO Mobile App (“BSO Tenant Rating”) for every BSO Tenant, BSO Vendor profile rating in BSO Mobile App (“BSO Vendor Rating”) for every BSO Vendor, in order to evaluate their and your performance, and such rating will be publicly displayed to you, all other BSO Broker(s) and any other registered user accounts on BSO Mobile App, including but not limited to BSO Landlord(s), BSO Tenant(s), BSO Vendor(s) any other user type(s) registered in BSO Mobile App;
                </p>
                <p>
                    {' '}
          9.2 The BSO Broker Rating scheme and allocated grading of BSO Broker(s) will be decided by BSO as per the following list of activities, services performed, and advancements reached:
                </p>
                <p> 9.2.1 BSO Broker’s account registration completion progress;</p>
                <p>
                    {' '}
          9.2.2 BSO Broker’s subscription plan status, activity status, profile status, and user dynamics on BSO Mobile App;
                </p>
                <p> 9.2.3 BSO Broker’s Membership Tiers;</p>
                <p>
                    {' '}
          9.2.4 Number of successful Property Advertisement(s) completed by a
          BSO Broker
                </p>
                <p>
                    {' '}
          9.2.5 Number of successful Property Viewing(s) completed by a BSO
          Broker;
                </p>
                <p>
                    {' '}
          9.2.6 Number of successful Potential Offer(s) completed by a BSO
          Broker;
                </p>
                <p>
                    {' '}
          9.2.7 Number of successful User Transaction(s) completed by a BSO
          Broker
                </p>
                <p>
                    {' '}
          9.2.8 Number of successful Referral(s) completed by a BSO Broker;
                </p>
                <p>
                    {' '}
          9.2.9 Number and nature of complaints received against a BSO Broker;
                </p>
                <p>
                    {' '}
          9.2.10 Any other factors that BSO may find necessary to add to the BSO
          Broker Rating scheme grading.
                </p>
                <p>
          9.3 BSO will continuously monitor your activities (as per clause 9.2) and may at any time change your BSO Broker Rating based on the above. BSO reserves full and sole discretion to amend the BSO Broker Rating scheme and allocated grading algorithm without any prior notification;
                </p>
                <p>
          9.4 Every BSO Broker Rating given to a BSO Broker, BSO Landlord Rating given to BSO Landlord, BSO Tenant Rating given to BSO Tenant, BSO Vendor Rating given to BSO Vendor will be out of a total scale of ten (10).
                </p>
                <h4> 10. BSO ONLINE CHAT</h4>
                <p>
                    {' '}
          10.1 BSO Chat is an online chat service that is available on BSO Mobile App in order to connect BSO Broker(s) together and with BSO Landlord(s), BSO Tenant(s), BSO Vendor(s) any other user type(s) registered in BSO Mobile App to exchange contact details, relevant contributions, knowledge and expertise and to offer or delegate services (if approved by BSO);
                </p>
                <p>
          10.2 Any communication occurring between you and other BSO Broker(s), BSO Landlord(s), BSO Tenant(s), BSO Vendor(s) any other user type(s) registered in BSO Mobile App and/or with BSO regarding any subjects/materials falling under BSO must run through BSO Chat on BSO Mobile App;
                </p>
                <p>
          10.3 All your communications with other BSO Broker(s), BSO Landlord(s), BSO Tenant(s), BSO Vendor(s) any other user type(s) registered in BSO Mobile App and/or with BSO must run through BSO Chat. You are prohibited from communicating with other BSO Broker(s), BSO Landlord(s), BSO Tenant(s), BSO Vendor(s) any other user type(s) registered in BSO Mobile App and/or with BSO externally and without the use of BSO Chat;
                </p>
                <p>
          10.4 You may not use BSO Chat for any purpose that is unlawful, unethical, criminal and/or prohibited by these Terms. If you receive any content from any other BSO Broker(s), BSO Landlord(s), BSO Tenant(s), BSO Vendor(s) any other user type(s) registered in BSO Mobile App that is unethical and/or unlawful, you are expected to report the account you had received the chat from to our Help Center via BSO Mobile App and/or by email <a href="mailto:helpcenter@bso.ae">helpcenter@bso.ae</a> and/or by phone +971 4 454 7337;
                </p>
                <p>
          10.5 If BSO suspects that you and/or any other registered account(s) are engaging in any unlawful or unethical activities, BSO will control and attain full access to your account or any other registered account(s) without your consent and may conduct any investigation and/or take any legal action necessary at its sole discretion;
                </p>
                <p>
          10.6 You acknowledge and agree that any conversation in BSO Chat is an official conversation and can be used as official evidence in disputes and/or complaints related to any illegal activities done by you or against you in BSO Mobile App, should this ever be required.
                </p>
                <h4>11. PRIVACY POLICY</h4>
                <p>
                The Privacy Policy is considered part of these Terms. You must review the Privacy Policy provided in BSO Mobile App or by visiting the Privacy Policy page.
                </p>
                <h4>12. COOKIES</h4>
                <p>
                We employ the use of cookies and/or similar technologies: web beacons, browser analysis tools, server logs, pixels, mobile identifiers, and etc. By accessing BSO Mobile App, you agree to use cookies and/or similar technologies in agreement with these Terns and our Privacy Policy. Most interactive mobile applications use cookies to retrieve their users’ details for each visit. Cookies and similar technologies are used by BSO Mobile App to enable the functionality of certain areas to make it easier for you and any other registered accounts of BSO Mobile App. Some of our affiliate/advertising partners may also use cookies and/or similar technologies.
                </p>
                <h4>13. INTELLECTUAL PROPERTY RIGHTS</h4>
                <p>
                    {' '}
          13.1 The coding and content of BSO Mobile App must not be copied, reproduced, reverse engineered, used or otherwise dealt with other as expressly permitted under these terms to use BSO Mobile App or by law. You may not compete or engage in any manner with any other service provider that competes with BSO's services through BSO Mobile App or any other platform(s);
                </p>
                <p>
          13.2 BSO Mobile App may contain our service marks or trademarks as well as those of our affiliates or other companies, in the form of words, quotes, graphics, and logos. Your use of BSO Mobile App does not constitute any right or license for you to use our service marks or trademarks, without our prior written permission;
                </p>
                <p>
          13.3 Our content, as found within BSO Mobile App, is protected under United Arab Emirates and foreign copyrights. The copying, redistribution, use or publication by you of any such content, is strictly prohibited. Your use of BSO Mobile App does not grant you any ownership rights to our content.
                </p>
                <h4> 14. VARIATION</h4>
                <p>
                BSO will periodically revise the information, services and resources contained in BSO Mobile App and reserves the right to make such changes without any obligation to notify past, current or prospective visitor including you.
                </p>
                <h4> 15. MOBILE APP & SOFTWARE UPDATES</h4>
                <p>
                We may automatically update BSO Mobile App and change its functionality to improve performance, enhance functionality and reflect changes to the operating system or address security issues. Alternatively, we may ask you to update BSO Mobile App in your devices for these reasons. If you choose not to install such updates or if you opt out of automatic updates you may not be able to continue using or accessing BSO Mobile App. BSO has sole discretion in terms of deciding which functionalities, releases, updates and/or upgrades are allocated to which packages and/or subscription plans of BSO Mobile App, if whether they form part of free updates or paid releases, independent of past or proposed trends and tendencies.
                </p>
                <h4> 16. FORCE MAJEURE </h4>
                <p>
                In the event that BSO claims Force Majeure (defined below) and is unable to perform its duties and provide its services as set out in these Terms, BSO shall be excused from the performance of all its’ obligations and of any payments, repayment, refunds or transfer of funds to you or any other registered account of BSO Mobile App, to the extent that such failure of performance of BSO is prevented by Force Majeure. For the purpose of these Terms, Force Majeure shall include conditions beyond the control of BSO, including an act of God, pandemics, bankruptcy, acts of terrorism, voluntary or involuntary compliance with any regulation, law or order of any government, war, acts of, labor strike or lock-out, civil commotion, epidemic, failure or default of public utilities or common carriers, destruction of production facilities or materials by fire, earthquake, pandemic diseases, storm or other catastrophe.
                </p>
                <h4>17. CHANGES TO OUR TERMS</h4>
                <p>
                We reserve the right to change these Terms at any time by notifying you of any changes via BSO Mobile App and/or email. Your continued use of BSO Mobile App, services, and products after any change to these Terms, and your receipt of these changes, will constitute your acceptance. If you do not agree with the changes to these Terms, you can choose to discontinue the use of BSO Mobile App, services, and products.
                </p>
                <h4>18. DISCLAIMER OF WARRANTY</h4>
                <p>
                You expressly understand and agree that your use of BSO Mobile App and/or any material available through BSO Mobile App is at your own risk. Neither BSO nor its’ employees warrant that BSO Mobile App will be uninterrupted, problem-free, free of omissions, or error-free; nor do they make any warranty as to the results that may be obtained from the use of BSO Mobile App. The content and function of BSO Mobile App are provided to you "as is", without warranties of any kind, either express or implied, including, but not limited to, warranties of title, merchantability, fitness for a particular purpose or use, or currency.
                </p>
                <h4>19. LIMITATION OF LIABILITY</h4>
                <p>
                In no event will BSO or its’ employees be liable for any incidental, indirect, special, punitive, exemplary on consequential damages, arising out of your use of or inability to use BSO Mobile App, including without limitation, loss of revenue or anticipated profits, loss of goodwill, loss of business, loss of data, computer failure or malfunction or any other damages.
                </p>
                <h4>20. CONFIDENTIALITY</h4>
                <p>
                    <span className=''>20.1 </span>You must not directly or indirectly disclose or make available any and all confidential or proprietary information (“Confidential Information”) in whole or in part to any third party, except as expressly permitted by BSO and in accordance with these Terms relating to:
                </p>
                <p>
          20.1.1 The business, affairs, customers, clients, suppliers, or plans, intentions, or market opportunities of BSO or of any of BSO’s group companies;
                </p>
                <p>
          20.1.2 The operations, processes, product information, know-how, technical information, designs, trade secrets or software of BSO, or of any of the BSO’s group companies;
                </p>
                <p>
          20.1.3 Any information, findings, data or analysis derived from Confidential Information;
                </p>
                <p>
          20.1.4 Any other information that is identified as being of a confidential or proprietary nature.
                </p>
                <p>
          20.2 Information is not confidential if it is, or becomes, generally available to the public through any means other than as a direct or indirect result of the information being disclosed in breach of these Terms;
                </p>
                <p>
          20.3 You shall establish and maintain adequate security measures (including any reasonable security measures that may be proposed by BSO from time to time) to safeguard any Confidential Information from unauthorized access and/or use;
                </p>
                <p>
          20.4 By using BSO Mobile App, you agree to keep the Confidential Information secret and confidential and not use and/or exploit the Confidential Information except if instructed by BSO and these Terms;
                </p>
                <p>
          20.5 You agree not to share, disclose and/or publish any Confidential Information shared to you by BSO through BSO Mobile App, email and/or any other BSO platform(s) (i.e. in person and/or online) to any other third party (i.e. service provider, competitor, broker, landlord, and/or otherwise) unless authorized by BSO. You understand that any reputational damage and/or caused by you to BSO, its’ employees, services and other third-party member(s) may result in an investigation and termination of your use and access to BSO Mobile App;
                </p>
                <p>
          20.6 Without prejudice to any other rights or remedies that BSO may have, you acknowledge and agree that damages alone would not be an adequate remedy for any breach of these Terms. Accordingly, BSO shall be entitled to the remedies of interim measures for any threatened or actual breach of these Terms by you;
                </p>
                <p>
          20.7 You shall be liable for the actions or omissions of any of your representatives, attorneys and/or their representatives in relation to the Confidential Information as if they were the actions or omissions of yourself;
                </p>
                <p>
          20.8 You may only disclose Confidential Information to the minimum extent required by an order of any court of competent jurisdiction or any regulatory, judicial, governmental, similar body and/pr or any taxation authority of competent jurisdiction.
                </p>
                <h4>21. PROHIBITED USE</h4>
                <p>
                By using BSO Mobile App, you agree on behalf of yourself, not to disrupt, violate, modify, prepare derivative works of, or reverse engineer our services, knowingly or negligently use our services in a way that abuses or disrupts our reputation, networks, BSO Brokers’ accounts or any other registered accounts and/or services. You understand that any damage(s) caused by you to BSO, BSO Brokers(s), BSO Landlord(s), BSO Tenant(s), BSO Vendor(s) and/or other registered users on BSO Mobile App and any third party(s) affiliated and/or engaged with BSO, may result in your immediate suspension and/or termination of your use of BSO Mobile App and cause additional potential investigations to be conducted by and through BSO. You also agree never to make any brokerage transactions directly (i.e., property sale transactions, property purchase transactions, property leasing transactions, property gifting transactions etc. directly with other BSO Brokers(s), BSO Landlord(s), BSO Tenant(s), BSO Vendor(s) and/or any other third-party service provider(s) associated or not with BSO) and without involving BSO. In the event, where you are found involved in any of the above listed transactions, and the result of your such actions caused financial and/or reputational damages to BSO, you agree to pay a compensation to BSO equal to but not limited to the full amount of such damages.
                </p>
                <h4>22. TERMINATION OF USE</h4>
                <p>
                    {' '}
          22.1 BSO may, in its’ sole discretion, terminate or suspend your access to and use of BSO Mobile App without notice and for any reason, including for violation of these Terms or for other conduct which BSO, in its’ sole discretion, believes is unlawful or harmful to others. In the event of termination, you will no longer be authorized to access BSO Mobile App, and BSO will use any means possible to enforce this termination;
                </p>
                <p>
          22.2 In case of suspension and/or termination of your account, all payments previously settled to BSO and/or any remaining BSO Credit(s), and/or Brick(s) will not be refunded nor transferred.
                </p>
                <h4>23. LANGUAGE</h4>
                <p>
                    {' '}
                    The language of these Terms is English. All documents, notices, waivers, variations and other written communications relating to this agreement will be in English and in the event of any document being translated into another language, the English version shall prevail in case of any dispute.
                </p>
                <h4>24. ENTIRE AGREEMENT</h4>
                <p>
                    {' '}
                    You acknowledge and agree that these Terms together with the Privacy Policy and the terms against any services and/or additional services (“Add-on”) offered in BSO Mobile App (“Services Terms”, “T&C”, “Particulars”, “Schedule I”, “Schedule II”, “Undertaking Terms” and/or any other set terms linked to the functionality of BSO Mobile App) constitute the complete agreement between BSO and you in relation to your use of BSO Mobile App, and supersede all prior agreements, communications and understandings.
                </p>
                <h4>25. GOVERNING LAW & JURISDICTION </h4>
                <p>
                    {' '}
                    These Terms and any dispute or claims arising out of, or in connection with it, its subject matter or formation (including non-contractual disputes or claims) shall be governed by, and construed in accordance with the laws of the Dubai International Financial Centre (“DIFC”), specifically the Small Claims Tribunal, and the DIFC Courts shall have exclusive jurisdiction in relation to it.
                </p>
            </div>
        </div>
    )
}
