import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { useDispatch } from 'react-redux'
import {
    setAudioSrc, setCurrentTime, setDuration,
    setIsPlaying,
    setIsShown,
    setMessageAuthor,
    setMessageDate
} from '../../../store/slices/voiceSlice'
import moment from 'moment'
import Icon from '../../../app/icon/Icon'
const VoicePlayer = (): JSX.Element => {
    const {
        activeId,
        isShown,
        isPlaying,
        audioSrc,
        duration,
        currentTime,
        messageDate,
        messageAuthor,
        timeJump
    } = useAppSelector((state: RootState) => state.voice)
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const dispatch = useDispatch()
    const audioRef = useRef<HTMLAudioElement>(null)

    const onTimeUpdate = (): void => {
        if (audioRef !== null) {
            dispatch(setCurrentTime(audioRef.current?.currentTime ?? 0))
        }
    }

    const play = (): void => {
        dispatch(setIsPlaying(true))
    }

    const pause = (): void => {
        dispatch(setIsPlaying(false))
    }

    useEffect(() => {
        if (audioRef !== null) {
            /* dispatch(setSetCurrentTimeManually((time: number) => {
                if (audioRef.current !== null) {
                    audioRef.current.currentTime = time
                }
            })) */
            audioRef.current?.addEventListener('timeupdate', onTimeUpdate)
        }
        return () => {
            audioRef.current?.removeEventListener('timeupdate', onTimeUpdate)
        }
    }, [isShown])

    useEffect(() => {
        if (audioRef.current !== null) {
            audioRef.current.currentTime = timeJump ?? 0
        }
    }, [timeJump])

    useEffect(() => {
        if (isPlaying) {
            void audioRef.current?.play()
                .catch((err) => {
                    console.log(err)
                })
        } else {
            void audioRef.current?.pause()
        }
    }, [isPlaying])

    return (
        isShown
            ? <>
                { audioSrc !== '' ? <audio src={audioSrc ?? ''} ref={audioRef}/> : <></>}
                <div className={'d-flex position-fixed shadow-sm justify-content-between align-items-center bg-white w-100 px-2'}
                    style={{
                        zIndex: 2000,
                        paddingTop: userDevice === 'IPhone' ? '2.5rem' : 0
                    }}
                >
                    <div className={'d-flex gap-2 align-items-center'}>
                        {
                            isPlaying
                                ? <Icon name={'pause'} className={'fs-4x text-muted'} onClick={pause}/>
                                : <Icon name={'play_arrow'} className={'fs-4x text-muted'} onClick={play}/>
                        }
                        <div className={''}>
                            <span className={'fw-bold'}>
                                {
                                    messageAuthor
                                }
                            </span> <span>
                                {
                                    moment(messageDate).fromNow()
                                }
                            </span>
                        </div>
                    </div>
                    <Icon name={'close'} className={'text-muted fs-4x'} onClick={() => {
                        pause()
                        dispatch(setCurrentTime(0))
                        dispatch(setIsShown(false))
                    }}/>
                </div>
            </>
            : <></>
    )
}

export default VoicePlayer
