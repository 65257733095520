import { type ISettings, type User } from '../../../store/slices/userSlice'

export const getUserDataForMembership = (user: User, currentMembership: string) => {
    const userSETTINGS = (user.Settings as [ISettings])?.[0]
    //  console.log(userSETTINGS)
    const membershipsRequirements = {
        Referals: 0,
        Buisness: 0,
        Perfomance: 0,
        Broadcast: 0,
        Rental: '0',
        Sales: 0,
        BrokerStatus: 'Verified'
    }
    const userCurrentData = {
        Referals: user.Referrals.length,
        Buisness: userSETTINGS.Business ?? 0,
        Perfomance: userSETTINGS.Performance ?? 0,
        Broadcast: userSETTINGS.BroadcastCredits ?? 0,
        Rental: '2',
        Sales: 4,
        BrokerStatus: 'Licensed'
    }

    switch (currentMembership) {
        case 'Bronze':
            // currentBenefits.Broadcast = 3,
            // currentBenefits.BrokerStatus = 'Licensed',
            // currentBenefits.Buisness = 1000000,
            // currentBenefits.Perfomance = 6.9,
            // currentBenefits.Referals = 3,
            // currentBenefits.Rental = 'Unlimited',
            // currentBenefits.Sales = 10
            break
        case 'Silver':
            membershipsRequirements.Broadcast = 1,
            membershipsRequirements.Perfomance = 5.9,
            membershipsRequirements.Referals = 1,
            membershipsRequirements.Rental = '3',
            membershipsRequirements.Sales = 2

            break
        case 'Gold':
            membershipsRequirements.Broadcast = 3,
            membershipsRequirements.BrokerStatus = 'Licensed',
            membershipsRequirements.Buisness = 1000000,
            membershipsRequirements.Perfomance = 6.9,
            membershipsRequirements.Referals = 3,
            membershipsRequirements.Rental = 'Unlimited',
            membershipsRequirements.Sales = 10

            break
        default:
            break
    }

    return {
        membershipsRequirements,
        userCurrentData
    }
}
