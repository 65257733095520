import { type IChatListItem } from '../../../types'
import _ from 'lodash'

export const singleChatAdapterForList = (userID: number) => (chat: any): IChatListItem => {
    const users = chat?.Users ?? []
    _.remove(users, (user: any) => user?.id === userID)
    return {
        id: chat?.id ?? -1,
        userID: users[0]?.id,
        Name: `${String(users[0]?.ContactInfo?.FirstName ?? '')} ${String(users[0]?.ContactInfo?.FamilyName ?? '')}`,
        Photo: users[0]?.Type?.[0]?.Photo ?? null,
        Unread: chat.Unread,
        LastMessage: {
            updatedAt: chat?.LastMessage?.updatedAt ?? '',
            Text: chat?.LastMessage?.Text ?? ''
        },
        Type: chat.Type ?? '',
        UnitID: chat.UnitID ?? '',
        Status: chat?.Status ?? null,
        createdAt: chat.createdAt
    }
}

export const sortChatsForChatList = (chats: IChatListItem[]): IChatListItem[] => {
    return _.orderBy(chats, (chatItem: IChatListItem) => chatItem.LastMessage?.updatedAt, 'desc')
}

const getChatListAdapter = (data: any, userID: number, entry: string = 'Chats'): IChatListItem[] => {
    try {
        const mapFunction = singleChatAdapterForList(userID)
        const chats: IChatListItem[] = _.map(data.response[entry], mapFunction)
        const sortedChats = sortChatsForChatList(chats)
        return (sortedChats)
    } catch (error) {
        console.error(error)
        return []
    }
}

export default getChatListAdapter
