import { getErrorss } from '../../../functions/errors/GetErrors'
import { getQueryAdvertises } from '../../../functions/shared/api/queries/getQueryAdvertises'
import { removeNotifyByDeviceToken } from '../../../functions/shared/notify-helpers/removeNotify'
import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../../urls'
import { advertisesSlice } from '../../slices/advertisesSlice'

import { type AppDispatch, type RootState } from '../../store'

export const viewAdvertise = (bodyClient: any) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
        try {
            const response = await fetch(`${urlAPI}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    body: JSON.stringify({

                        method: 'PUT',
                        collection: 'unit-advertises',
                        id: Number(bodyClient.advID),
                        query: getQueryAdvertises() , // `filters[User][id][$eq]=${userID}&populate[User][fields][0]=id&populate[Unit][fields][0]=id`, // [id]
                        body: {
                            Notify: bodyClient.notify
                        }
                    })
                })
            const responseJSON = await response.json()
            if (responseJSON.success) {
                if (responseJSON.response.error != null) {
                    dispatch(advertisesSlice.actions.advertisesFetchingError(getErrorss(responseJSON)))
                    return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
                } else {
                    await removeNotifyByDeviceToken({
                        userID: getState().user.currentUser.id as number,
                        deviceToken: getState().user.currentUser.DeviceToken as string
                    })
                    dispatch(advertisesSlice.actions.setAdvertises(
                        responseJSON.response.data
                    ))

                    return { ...responseJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
                }
            } else {
                dispatch(advertisesSlice.actions.advertisesFetchingError(getErrorss(responseJSON)))
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } catch (e) {
            console.log(e)
        }
    }
}
