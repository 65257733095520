import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'


import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { type RootState } from '../../../../../store/store'
import AgentAreas from '../../me/AgentAreas'
import ProfileSharedMedia from '../../../../shared/profile/sharedMedia'

import { type User } from '../../../../../store/slices/userSlice'
import AgentHeader from '../../../AgentHeader'
import OtherProfileHead from './OtherProfileHead'

import ActionButtons from '../../ActionButtons'
import { openChatByUserID } from '../../../../../store/actionsCreators/chatActions'
import { addUserToFavorites, blockUser } from '../../../../../store/actionsCreators/userActions'
import _ from 'lodash'

import useUserType from '../../../../../hooks/UseUserType'
import AgentProfileDocuments from '../../me/AgentProfileDocuments'
import { type Document } from '../../../../../store/slices/documentSlice'
import AgentProfileContactInfo from "../../me/AgentProfileContactInfo";
import AgentProfileStatistics from "../../me/AgentProfileStatistics";
import AgentProfileActiveUnits from "../../me/AgentProfileActiveUnits";
import OtherProfilesNEW from './OtherProfilesNEW'
import ProfileMetrics from '../../me/AgentProfileMetrics'
import { getMetrics } from '../../me/getMetric'
export default function OtherAgentsProfile(): JSX.Element {
    const { id, from } = useParams()
    const previewUser = useAppSelector((state: RootState) => state.user.allUsers).filter((user: User) => user.id === Number(id))[0]
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser) as User
    const documents = previewUser.Documents?.map(doc => ({
        id: doc.id as Document['id'],
        attributes: doc as any
    })) ?? []

    const [previewOpen, setPreviewOpen] = useState(false)


    const isFavorite = _.some((currentUser as any).FavoriteList, user => user.id === Number(id))
    const isBlocked = _.some((currentUser as any).BlackList, user => user.id === Number(id))
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { userTypeLowercase } = useUserType()
    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    const otherAgentProfileMetrics = getMetrics(previewUser)
    return (
        <>

            <div className="page d-flex flex-column flex-row-fluid fs-5">
                <AgentHeader active="Profile" />
                <div className="card shadow-sm mx-4 my-4">
                    <div className={`card-body ${isDev ? "p-0" : "p-4"}  bg-white rounded`}>
                        {isDev ?
                            <OtherProfilesNEW previewOpen={previewOpen} setPreviewOpen={setPreviewOpen} previewUser={previewUser} /> :
                            <OtherProfileHead previewOpen={previewOpen} id={id} setPreviewOpen={setPreviewOpen} currentUser={currentUser} previewUser={previewUser} />}
                    </div>
                </div>

                <ActionButtons callbackMessage={(setLoading: (state: boolean) => void) => {
                    setLoading(true)
                    dispatch(openChatByUserID(previewUser.id))
                        .then(({ id }: { id: any }) => {
                            setLoading(false)
                            navigate(`/${userTypeLowercase}/chats/${String(id)}`)
                        })
                        .catch(() => {
                            setLoading(false)
                        })
                }} callbackBlock={(setLoading: (state: boolean) => void) => {
                    setLoading(true)
                    void dispatch(blockUser(Number(id), isBlocked))
                        .finally(() => {
                            setLoading(false)
                        })
                }} callbackFavourite={(setLoading: (state: boolean) => void) => {
                    setLoading(true)
                    void dispatch(addUserToFavorites(Number(id), isFavorite))
                        .finally(() => {
                            setLoading(false)
                        })
                }} isBlocked={isBlocked} isFavorite={isFavorite} userID={previewUser.id}
                />

                {
                    currentUser.id === Number(process.env.REACT_APP_BSO_USER_ID)
                        ? <>
                            <div className="card shadow-sm mb-4 mx-4">
                                <div className="card-body p-4">
                                    <AgentProfileDocuments
                                        documents={documents}
                                    />
                                </div>
                            </div>
                            <AgentProfileContactInfo user={previewUser} />
                            <AgentProfileStatistics user={previewUser} />
                            <AgentProfileActiveUnits user={previewUser} />
                        </>
                        : <></>
                }



                {previewUser?.Settings?.[0]?.ShowStats && <div className="card shadow-sm mx-4 mb-4">
                    <div className="card-body p-4">
                        <div className={'text-gray-900 fs-2 fw-bold mb-2'}> Stat </div>
                        <ProfileMetrics metrics={otherAgentProfileMetrics} />
                    </div>
                </div>}

                <div className="card shadow-sm mx-4 mb-4">
                    <div className="card-body p-4">
                        <div className={'text-gray-900 fs-2 fw-bold mb-2'}>Areas</div>
                        <AgentAreas user={previewUser}
                        />
                    </div>
                </div>

                {
                    from === 'chat'
                        ? <ProfileSharedMedia id={Number(id)} />
                        : <></>
                }
                <div className="h-200px" />
            </div>
        </>
    )
}
