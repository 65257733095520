import React from 'react'
import CardUserTable from '../../agentModule/rating/tableRating/CardUserTable'
import { useNavigate } from 'react-router'
import { openChatByUserID } from '../../../store/actionsCreators/chatActions'
import { useAppDispatch } from '../../../hooks/redux'
import _ from 'lodash'
import checkDocumentsForAproved from '../../../functions/agent/documents/checkDocumentsForAproved'
import { ava_blank } from '../../../urls'
import { capitalizeWords } from '../../../functions/shared/format/capitalizeWords'
import { timeStringInScheduler } from '../../../functions/shared/schedule/timeStringInScheduler'

interface ReferralRowProps {
    user: any

}

export default function Referral({ user }: ReferralRowProps): JSX.Element {
    const [dateRefer, timeRefer] = timeStringInScheduler(
        user.createdAt ?? ''
    ).split(',')

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const userCONTACTS = (user)?.Type?.[0]
    const userContactsINFO = (user)?.ContactInfo
    const imageUrl = userCONTACTS?.Photo ?? ava_blank

    const income: number = _.reduce(
        user.Orders,
        (sum, order) => sum + Number(order.Amount ?? 0),
        0
    )
    const documentsStatuses = checkDocumentsForAproved(user.Documents)

    const firstName = capitalizeWords(userContactsINFO?.FirstName ? userContactsINFO?.FirstName : userCONTACTS?.Name ? userCONTACTS?.Name.split(' ')?.[0] : 'No name')
    const lastName = capitalizeWords(userContactsINFO?.FamilyName ? userContactsINFO?.FamilyName : userCONTACTS?.Name ? userCONTACTS?.Name.split(' ')?.[1] : 'No name')
    return (
        <>

            <div className={'overflow-x-visible position-relative shadow my-2 rounded '} >
                <div className={'w-200px h-200px d-flex flex-column position-relative overflow-hidden rounded  bg-light-secondary'}>
                    <div className='col'>
                        <div className="d-flex align-items-center  p-2">
                            <div className="symbol symbol-60px m-2">
                                <img
                                    src={imageUrl}
                                    className=""
                                    alt=""
                                />
                            </div>
                            <div className="d-flex justify-content-start flex-column ">
                                <span className="text-dark fw-bold  d-block fs-6 mw-85px text-dots-app">{firstName}</span>
                                <span className="text-dark fw-semibold mb-1 d-block fs-7 mw-85px text-dots-app">{lastName}</span>
                                <span className="text-dark fw-bold mb-1 d-block fs-8 mw-85px text-dots-app ">
                                    {dateRefer}
                                </span>
                            </div>
                        </div>

                    </div>

                    <div className={'d-flex flex-column col p-2'}>
                        <div className={'d-flex gap-2 text-black align-items-center'}>
                            {
                                user.emailVerified === true
                                    ? <i className="ki-duotone ki-check-square fs-2x text-success">
                                        <i className="path1"></i><i className="path2"></i>
                                    </i>
                                    : <i className="ki-duotone ki-cross-square fs-2x text-danger">
                                        <i className="path1"></i><i className="path2"></i>
                                    </i>
                            }
                            <div className={'text-truncate w-100'}>{user.email}</div>
                        </div>
                        <div className={'d-flex gap-2 text-black align-items-center'}>
                            {
                                user.phoneVerified === true
                                    ? <i className="ki-duotone ki-check-square fs-2x text-success">
                                        <i className="path1"></i><i className="path2"></i>
                                    </i>
                                    : <i className="ki-duotone ki-cross-square fs-2x text-danger">
                                        <i className="path1"></i><i className="path2"></i>
                                    </i>
                            }
                            <div className={'text-truncate w-100'}>{user.phone}</div>
                        </div>
                        <div className={'d-flex gap-2 text-black  align-items-center'}>
                            {
                                documentsStatuses.emiratesApproved === true
                                    ? <i className="ki-duotone ki-check-square fs-2x text-success">
                                        <i className="path1"></i><i className="path2"></i>
                                    </i>
                                    : <i className="ki-duotone ki-cross-square fs-2x text-danger">
                                        <i className="path1"></i><i className="path2"></i>
                                    </i>
                            }
                            <div className={'text-truncate w-100'}>Verified</div>
                        </div>

                        <div className={'d-flex gap-2 text-black align-items-center'}>
                            <i className="ki-duotone ki-percentage fs-2x text-warning">
                                <i className="path1"></i>
                                <i className="path2"></i>
                            </i>
                            <div>{income}AED</div>
                        </div>

                        <div className='text-center fs-2hx fw-bold '>
                            +30 BC
                        </div>
                    </div>

                    <div className="position-relative w-100 h-100 ">

                        {/* <img className="z-index-0" src={imgPath} alt="" style={{ height: 'calc(100% - 10px)', width: '100%' }} /> */}
                    </div>

                </div>
            </div >
        </>

    )
}
