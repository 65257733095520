import React, { useEffect, useState } from 'react'
import { Image, Carousel as AntdCarousel, Space } from 'antd'

import { nanoid } from 'nanoid'
import { type Unit } from '../../../../store/slices/unitsSlice'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { setPreviewVisible } from '../../../../store/slices/modalSlice'
import { type RootState } from '../../../../store/store'
import CloseIconBasic from '../../../../components/shared/elements/CloseIconBasic'
import Icon from '../../../icon/Icon'

interface IProps {
    currentUnit: Unit
    heightImage?: string | undefined
}

function ImagesPreviewGroup({ currentUnit, heightImage = '275' }: IProps): JSX.Element {
    const defaultImage = '/assets/media/placeholders/image_unavailable.png'
    const dispatch = useAppDispatch()
    const previewVisible = useAppSelector((state: RootState) => state.modal.previewVisible)

    return (
        <div >
            <Image.PreviewGroup preview={{
                closeIcon: (<CloseIconBasic onCancel={console.log} cls={'13px'} rightPosition={'-5.5'} />),
                onVisibleChange: (): void => {
                    if (!previewVisible) {
                        dispatch(setPreviewVisible(true))
                    } else {
                        setTimeout(() => dispatch(setPreviewVisible(false)), 300)

                    }
                },
                toolbarRender: (
                    _,
                    {
                        transform: { scale },
                        actions: { onRotateLeft, onRotateRight }
                    }
                ) => (
                    <Space size={12} className="toolbar-wrapper " >
                        <Icon name={'rotate_90_degrees_cw'} onClick={onRotateRight} className={'fs-3x'} />
                        <Icon name={'rotate_90_degrees_ccw'} onClick={onRotateLeft} className={'fs-3x'} />
                    </Space>
                )
            }}>
                <AntdCarousel dots={true} autoplay={true} infinite={false}>
                    {currentUnit?.attributes?.Images != null && currentUnit?.attributes?.Images?.length > 0
                        ? (
                            currentUnit?.attributes?.Images?.map((el: string, i) => (
                                <div key={`${el}-${nanoid()}`} >
                                    <Image
                                        width={'100vw'}
                                        height={`${heightImage}px`}
                                        src={el}
                                        preview={{
                                            mask: null
                                        }}
                                        style={{ objectFit: 'cover' }}
                                    />
                                </div>
                            ))
                        )
                        : (
                            <div key={nanoid()} className="text-center">
                                <div
                                    className={`w-100 h-${heightImage}px overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center position-relative`}
                                    style={{ backgroundImage: `url(${defaultImage})` }}>
                                    <div className="h-200px w-100"></div>
                                </div>
                            </div>
                        )}

                </AntdCarousel>
            </Image.PreviewGroup>
        </div>

    )
}

export default ImagesPreviewGroup
