import React, { useState } from 'react'
import { Segmented } from 'antd'
import { useNavigate } from 'react-router'
export const DocumentSegmented = ({ type, redirect }: { type: string, redirect: string }) => {
    const [value, setValue] = useState<string | number>(type)
    const navigate = useNavigate()
    return <Segmented options={['Emirates ID', 'Passport']} value={value} onChange={(value) => {
        setValue(value)
        navigate(redirect)
    }} />
}
