import _ from 'lodash'
import React from 'react'
import { type IChatMessage } from '../../../../types'
import VideoPlayer from '../../videoPlayer'

interface SharedMediaListProps {
    messages: IChatMessage[]
}

const SharedMediaList = ({ messages }: SharedMediaListProps): JSX.Element => {
    // console.log(messages)
    return (
        messages.length > 0
            ? <div className={'row g-0'}>
                {
                    _.map(messages, (message) => (
                        message.Text === 'type:video'
                            ? <div className={'col-4'}>
                                <VideoPlayer
                                    smallSize
                                    video={String(message?.Attachments?.data)}
                                    name={String(message?.Attachments?.name)}
                                />
                            </div>
                            : <div className={'col-4 p-0'}>
                                <div
                                    className={'m-1'}
                                    style={{
                                        height: 'calc((100vw - 0.5rem) / 3)',
                                        background: `url(${String(message?.Attachments?.data)}) center no-repeat`,
                                        backgroundSize: 'cover'
                                    }}
                                />
                            </div>
                    ))
                }
            </div>
            : <div className={'py-1'}>
                <div className="card shadow-sm bg-secondary">
                    <div className="card-body p-0 m-5 text-center">
                        <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 ">
                            No media
                        </span>
                    </div>
                </div>
            </div>
    )
}

export default SharedMediaList
