import React from 'react'

export default function Rejected ({ fontsize = '' }: { fontsize?: string | undefined }): JSX.Element {
    return (
        <div

            className={`badge badge-light-danger border border-danger ${fontsize}`}
        >
      Rejected
        </div>
    )
}
