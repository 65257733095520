import { getQueryCurrentUser } from "../../../functions/shared/api/queries/getQueryCurrentUser";
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from "../../../urls";
import { dataFetchingFinish, dataFetchingStart } from "../../slices/modalSlice";
import { type User, userSlice } from "../../slices/userSlice";
import { type AppDispatch, type RootState } from "../../store";

export const addUnitToFavorites = (favoriteUnitID: number, isFavorite: boolean = false) => 
 
    async (dispatch: AppDispatch, getState: () => RootState) => {
        try {   
            dispatch(dataFetchingStart())
            const jwt = window.localStorage.getItem(ACCESS_KEY) as string;
            const userID = window.localStorage.getItem(USER_ID_KEY);
            const favoriteUnits = (getState().user.currentUser as User).FavoriteUnits ?? []
          
            const sendObject = {
                method: 'PUT',
                collection: 'users',
                id: Number(userID),
                query: getQueryCurrentUser(),
                body: {
                    FavoriteUnits: isFavorite
                        ? favoriteUnits.filter((id: number) => id !== favoriteUnitID)
                        : [...favoriteUnits, favoriteUnitID]
                }
            };

            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(sendObject),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            });
        
            const dataJSON = await response.json();
            if (dataJSON.success === true) {
                if (dataJSON.response.error != null) {
                    dispatch(dataFetchingFinish())
                    return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: "" }
                } else {
                    dispatch(dataFetchingFinish())
                    return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: "" }
                }
    
            } else {
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: "" }
            }
        } catch (err) {
            console.error(err);
            dispatch(dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: "" }
        }
    };
