import React from 'react'
import { Link } from 'react-router-dom'
import { type RootState } from '../../../../store/store'
import { useAppSelector } from '../../../../hooks/redux'
import Icon from '../../../../app/icon/Icon'

export default function ActionLoadPassport (): JSX.Element {
    const { currentUser, loading } = useAppSelector(
        (state: RootState) => state.user
    )
    const userID = currentUser.id
    return (
        <Link
            to={`/agent/modal/update_passport/not/${userID}`}
            className="text-decoration-none"
        >
            <div className="card shadow-sm mb-4 h-80px ">
                <div className="card-body d-flex justify-content-between align-items-center p-1 ">
                    <div className="px-4 py-1 mb-1">
                        <span className="fs-3 text-gray-800 fw-bold d-block text-hover-primary">
                        Upload Passport</span>
                        <span className="fs-5 fw-semi-bold text-gray-500 lh-1"> Get verify status your account with Passport</span>
                    </div >
                    <div className='align-items-center'>
                        <span className="pe-4">
                            <Icon name={'id_card'} className={'fs-6x mx-auto text-gray-700'} imageSize={48}/>
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    )
}
