import { getQueryDocuments } from "../../../functions/shared/api/queries/getQueryDocuments"
import { ACCESS_KEY, urlAPI } from "../../../urls"
import { type AppDispatch, type RootState } from "../../store"
import { fetchDocuments } from "../documentActions"
import { uploadSingleFile } from "./upload/uploadSingleFile"

export const createDocument = (documents: FileList | File[] | null, data: any, collection?: string, entityID?: string, url?: string, refID?: string) => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ isSuccessful: boolean, response?: any }> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    try {
        if (documents?.[0] != null) {
            const url = await uploadSingleFile(documents[0], refID, collection, entityID)
            const sendBody = {
                method: 'POST',
                collection: 'documents',
                query: getQueryDocuments(),
                body: {
                    ...data,
                    Link: url
                }
            }
            const documentResponse = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(sendBody),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const documentResponseJSON = await documentResponse.json()
            if (documentResponseJSON.success === true) {
                await dispatch(fetchDocuments(true))
            }
            documentResponseJSON.isSuccessful = documentResponseJSON.success
            return documentResponseJSON
        } else if (url != null) {
            const sendBody = {
                method: 'POST',
                collection: 'documents',
                query: getQueryDocuments(),
                body: {
                    ...data,
                    Link: url
                }
            }
            const documentResponse = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(sendBody),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const documentResponseJSON = await documentResponse.json()
            if (documentResponseJSON.success === true) {
                await dispatch(fetchDocuments(true))
            }
            documentResponseJSON.isSuccessful = documentResponseJSON.success
            return documentResponseJSON
        } else {
            return {
                isSuccessful: false
            }
        }
    } catch (err) {
        return {
            isSuccessful: false
        }
    }
}