import React from 'react'
import GetDescriptions from './GetDescriptions'
import { useAppSelector } from '../../../../hooks/redux'

interface IProps {
    selectedSubscription: string
    selectedPeriod: string
    activeSubPeriod: string
    setSelectedSubscription: React.Dispatch<React.SetStateAction<string>>
    activeSub: string
    handleSubscriptionChange: (renew?: string) => void
}

export default function EndSub({ activeSubPeriod, handleSubscriptionChange, activeSub, selectedPeriod, selectedSubscription, setSelectedSubscription }: IProps): JSX.Element {
    const loading = useAppSelector(state => state.modal.loading)
    return (
        <div className={`card card-dashed p-2 mb-4 bg-light-primary ${selectedSubscription === 'Premium' ? 'border border-primary' : ''}`} onClick={() => { setSelectedSubscription('Premium') }}>
            <div className="d-flex justify-content-between align-items-center mb-1 rounded p-2 mb-2">
                <div className="d-flex align-items-center me-2 ">
                    <span className={`badge badge-lg border border-5  badge-circle badge-light-success me-6 ${activeSub === 'Premium' && activeSubPeriod === selectedPeriod ? 'border-success' : 'border-secondary'}`}></span>
                    <div className="flex-grow-1">
                        <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                            Premium
                        </div>
                        <div className="fw-semi-bold opacity-75 lh-sm">
                            Enjoy unlimited access to property advertising
                        </div>
                    </div>
                </div>

                <div className="ms-5">
                    <span className="mb-2 me-1">AED</span>

                    <span
                        className="fs-3x fw-bold"
                    >
                        {selectedPeriod === 'annual' ? '200' : selectedPeriod === 'weekly' ? '10' : '20'}
                    </span>

                    <span className="fs-7 opacity-50">
                        /<span>
                            {selectedPeriod === 'annual' ? 'Year' : selectedPeriod === 'weekly' ? 'week' : 'Mon'}

                        </span>
                    </span>
                    {activeSub === 'Premium' && activeSubPeriod === selectedPeriod
                        ? <div className="text-end">
                            <div className="badge badge-primary text-end fs-7" >Active</div>
                        </div>
                        : null

                    }
                </div>

            </div>

            {selectedSubscription === 'Premium' && (
                <GetDescriptions
                    activeSub={activeSub}
                    activeSubPeriod={activeSubPeriod}
                    selectedPeriod={selectedPeriod}
                    selectedSubscription={selectedSubscription}
                    setSelectedSubscription={setSelectedSubscription}

                />
            )}
            {selectedSubscription === 'Premium' && (
                activeSub !== 'Premium'
                    ? <div className="w-100 mx-auto py-1" >
                        <button className="btn btn-bso py-3 w-100 fs-5" onClick={() => { handleSubscriptionChange() }}>
                            {loading
                                ? <span className="indicator-progress d-block">
                                    Please wait...
                                    <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                </span>
                                : <span className="indicator-label">
                                    Subscribe
                                </span>
                            }

                        </button>
                    </div>
                    : activeSubPeriod === selectedPeriod
                        ? null
                        : <div className="w-100 mx-auto py-1" >
                            <button className="btn btn-bso py-3 w-100 fs-5" onClick={() => { handleSubscriptionChange() }}>
                                {loading
                                    ? <span className="indicator-progress d-block">
                                        Please wait...
                                        <span className={'spinner-border spinner-border-sm align-middle ms-4'}></span>
                                    </span>
                                    : <span className="indicator-label">
                                        Subscribe
                                    </span>
                                }
                            </button>
                        </div>

            )}
        </div>
    )
}
