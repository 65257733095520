export const mounthes = ['January', 'February', 'March', 'April', 'May', 'June', 'Jule', 'August', 'September', 'October', 'November', 'December']

export const timeString = (dateString: string): string => {
    const mounthStandart = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const days = ['Sun', 'Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat']

    const date = new Date(dateString)
    const dateNum = date.getDate()
    const day = days[date.getDay()]
    const mounth = mounthStandart[date.getMonth()]
    const hours = date.getHours()
    const minutes = date.getMinutes()
    return `${hours}:${minutes > 10 ? minutes : '0' + minutes}`
    // return `${mounth}-${dateNum} / ${day} / ${hours} : ${minutes > 10 ? minutes : "0" +  minutes}`
}
