import React, { useEffect, useRef, useState } from 'react'
import ChatUtils from '../../../../../functions/chats/ChatUtils'
import { useAppSelector } from '../../../../../hooks/redux'
import { type RootState } from '../../../../../store/store'
// import {setCurrentTime} from "../../../../../store/slices/voiceSlice";
import { useDispatch } from 'react-redux'
import {
    setActiveId,
    setAudioSrc,
    setIsPlaying,
    setIsShown, setMessageAuthor,
    setMessageDate, setTimeJump
} from '../../../../../store/slices/voiceSlice'
import Icon from '../../../../../app/icon/Icon'

interface VoiceMessageProps {
    sentBy: 'me' | 'other'
    audio: string
    duration: number
    time: Date
    id: string
    senderName?: string
    name?: string
    type: string
    isPending?: boolean
    // voicePlaying: string | null
    // setVoicePlaying: (id: string | null) => void
}

const VoiceMessage = ({ sentBy, audio, time, duration, id, senderName, name, type, isPending = false }: VoiceMessageProps): JSX.Element => {
    // const [isPlaying, setIsPlaying] = useState(false)
    // const [currentTime, setCurrentTime] = useState(0)
    // const [duration, setDuration] = useState(0)
    const {
        activeId,
        isShown,
        isPlaying,
        currentTime,
        timeJump
    } = useAppSelector((state: RootState) => state.voice)

    const dispatch = useDispatch()

    // const audioRef = useRef<HTMLAudioElement>(null)
    const progressRef = useRef<HTMLDivElement>(null)
    const play = (): void => {
        dispatch(setIsShown(true))
        if (activeId !== id) {
            dispatch(setMessageDate(time.toString()))
            dispatch(setMessageAuthor(type === 'type:audio' ? name : senderName))
            dispatch(setIsPlaying(false))
            // setCurrentTimeManually(0)
            dispatch(setTimeJump(0))
            dispatch(setAudioSrc(audio))
            setTimeout(() => {
                dispatch(setActiveId(id))
                dispatch(setIsPlaying(true))
            }, 10)
        } else {
            dispatch(setIsPlaying(true))
        }
    }
    const pause = (): void => {
        if (activeId === id) {
            dispatch(setIsPlaying(false))
        }
    }

    const setTime = (e: any): void => {
        if (progressRef?.current?.offsetLeft !== undefined && activeId === id) {
            const offset = e.pageX - progressRef.current.getBoundingClientRect().x
            const width = progressRef.current.offsetWidth
            dispatch(setTimeJump((offset / width) * duration))
            // setCurrentTimeManually((offset / width) * duration)
        }
    }

    /* useEffect(() => {
        if (audioRef !== null) {
            setTimeout(() => {
                const seconds = audioRef.current?.duration ?? 0
                setDuration(seconds)
            }, 1000)
        }
    }, []) */

    return (
        <div className={`w-100 d-flex justify-content-${sentBy === 'me' ? 'end' : 'start'}`}>
            <div className={'card px-2 py-2 shadow-sm'}
                style={sentBy === 'me' ? { width: '70vw', background: '#fff200' } : { width: '70vw' }}
            >
                { sentBy === 'other'
                    ? <div className={'text-gray-600 mb-1'}>{senderName}</div>
                    : <></>
                }
                <div className={'d-flex flex-row gap-2'}>
                    { <div className={'d-flex align-items-center'}>
                        {
                            isPlaying && activeId === id
                                ? <Icon
                                    key={'pause'}
                                    name={'pause_circle'}
                                    className={'text-muted fs-5x'}
                                    onClick={pause}
                                />
                                : <Icon
                                    key={'play'}
                                    name={'play_circle'}
                                    className={'text-muted fs-5x'}
                                    onClick={play}
                                />
                        }
                    </div>
                    }
                    <div className={'d-flex flex-column w-100'}
                        ref={progressRef}
                        onMouseUp={setTime}
                    >
                        <div>{type === 'type:audio' ? name : 'Voice message'}</div>
                        <div className={'w-100 rounded h-5px border border-gray-500'}>
                            <div className={'bg-gray-500 h-100'}
                                style={{
                                    width: activeId === id ? `${duration > 0 ? Math.min(currentTime / duration * 100, 100) : 0}%` : `${0}%`
                                }}
                            />
                        </div>
                        <div className={'text-gray-500 fs-6'}>{
                            ChatUtils.formatDuration(
                                activeId === id
                                    ? /* (audioRef.current?.duration ?? 0) */ duration - currentTime
                                    : (/* audioRef.current?.duration */duration)
                            )
                        }</div>
                    </div>
                    <div className={'h-100 d-flex align-items-end text-gray-500 fs-6'}>
                        <div>{ChatUtils.formatTime(time)}</div>
                    </div>
                </div>
                { /* <div className={'hidden'}>
                <audio src={audio} ref={audioRef}/>
            </div> */}
            </div>
        </div>
    )
}

export default VoiceMessage
