import Swal, { type SweetAlertResult } from 'sweetalert2'
import { type User } from '../../../store/slices/userSlice'

import { animationOptions, swalNegatiateOffer, swalOptions, swalSuccess } from '../../swalOptions'
import { removeOfferNotify, setOfferStatus } from '../../../store/actionsCreators/offerActions'
import { type AppDispatch, store } from '../../../store/store'
import { type NavigateFunction } from 'react-router-dom'
import _ from 'lodash'
import { removeDataAttributes } from '../../adapters/universal/removeDataAttributes'
import swalConfig from '../../../app/texts/swalTexts'
import {
    agentToBSOOfferCommunicationCheckBrokenPipeline
} from '../../../store/actionsCreators/offerActions/agentToBSOOfferCommunication/agentToBSOOfferCommunicationStorage'
import { type IOffer } from '../../../store/slices/offersSlice'
import { USER_ID_KEY } from '../../../urls'
import { type Unit } from '../../../store/slices/unitsSlice'

export const showNegotiateSwals = async (currentUser: User, dispatch: AppDispatch, navigate: NavigateFunction): Promise<void> => {
    
    const userID = currentUser.id ?? window.localStorage.getItem(USER_ID_KEY) as string
    const offers =  store.getState().offers.offers.filter((el) => el?.attributes?.User?.data?.id === Number(userID))
  
  
    try {
    
        for (const offer of offers) {
            await processOfferSwal(offer, dispatch, navigate)
        }
        dispatch(agentToBSOOfferCommunicationCheckBrokenPipeline(navigate))
    } catch (err) {
        console.log(err)
    }
}

export const offerReactRequireStatuses = ['Resubmit required', 'Negotiated by landlord', 'Negotiated by BSO', 'Confirmed', 'Waiting deposit pay slip', 'Slip rejected']
export const offerReactRequireActions = {
    'Resubmit required': 'Resubmit',
    'Negotiated by landlord': 'Negotiate',
    'Negotiated by BSO': 'Negotiate',
    Confirmed: 'Send client info',
    'Waiting deposit pay slip': 'Upload pay slip',
    'Slip rejected': 'Upload pay slip'
}

export const processOfferSwal = async (offer: IOffer, dispatch: AppDispatch, navigate: NavigateFunction): Promise<void> => {
    let units = store.getState().units.units
    units = [...units]?.filter((el: Unit) => el.id === Number(offer?.attributes?.Unit?.data?.id))
    const processEndDate = offer?.attributes?.ProcessEndDate ? new Date(offer.attributes.ProcessEndDate).getTime() : null;
    const unit = units[0] 

    if (
        processEndDate !== null && 
        new Date().getTime() > processEndDate && offer?.attributes?.ProcessEndDate != null && _.includes(['Negotiated by landlord', 'Negotiated by BSO', 'Confirmed', 'Waiting deposit pay slip', 'Slip approved', 'Slip rejected'], offer?.attributes?.Statuses[0])) {
        await Swal.fire({
            ...swalOptions('error'),
            ...{
                html: `${getOfferInfo(offer)} ${swalConfig.offerExpiredSwal.message(unit?.attributes?.Marketing?.Headline ?? " - ")}`,
                showConfirmButton: true,
                confirmButtonText: swalConfig.offerExpiredSwal.confirmButtonText
            }
        })
    } else {
        const status = offer?.attributes?.Statuses?.[0]
        const storeOffer = store.getState().offers.offers.find((offerItem) => offerItem.id === offer.id)
        switch (status) {
            case 'Resubmit required':
                await Swal.fire({
                    ...swalOptions('info'),
                    ...{
                        html: getOfferInfo(offer, 'Our specialist requires action from you: Please resubmit this offer', 'resubmit'),
                        showConfirmButton: true,
                        confirmButtonText: 'Resubmit offer',
                        showDenyButton: true,
                        denyButtonText: 'Cancel offer',
                        showCancelButton: true,
                        cancelButtonText: 'Decide later',
                        allowOutsideClick: true
                    }
                }).then((promptResult) => {
                    if (promptResult.isConfirmed) {
                        navigate(`/agent/modal/agent_to_bso_resubmit_offer_documents/${String(offer.attributes?.Unit?.data?.id ?? storeOffer?.attributes?.Unit?.data?.id ?? '-')}/${String(offer.id)}`)
                        // switch (offer.Type[0].__component) {
                        //     case 'offer.residential':
                        //         navigate(`/agent/modal/agent_set_offer_modal_documents_individual/${String(offer.Unit.id ?? storeOffer?.attributes?.Unit?.data?.id ?? '-')}/${String(offer.id)}`)
                        //         break
                        //     case 'offer.commercial':
                        //         navigate(`/agent/modal/agent_set_offer_modal_documents_company/${String(offer.Unit.id ?? storeOffer?.attributes?.Unit?.data?.id ?? '-')}/${String(offer.id)}`)
                        //         break
                        //     case 'offer.sale':
                        //         navigate(`/agent/modal/agent_set_offer_modal_documents_individual_sale/${String(offer.Unit.id ?? storeOffer?.attributes?.Unit?.data?.id ?? '-')}/${String(offer.id)}`)
                        //         break
                        //     case 'offer.sale-commercial':
                        //         navigate(`/agent/modal/agent_set_offer_modal_documents_company_sale/${String(offer.Unit.id ?? storeOffer?.attributes?.Unit?.data?.id ?? '-')}/${String(offer.id)}`)
                        //         break
                        //     default:
                        //         break
                        // }
                    } else if (promptResult.isDenied) {
                        void dispatch(setOfferStatus(offer.id, 'Canceled', undefined, {
                            receiver: 'BSO',
                            rule: 'admin-offer-canceled'
                        }))
                    }
                })
                break
            case 'Negotiated by landlord':
            case 'Negotiated by BSO':
                await swalNegatiateOffer(
                    status === 'Negotiated by landlord'
                        ? `${getOfferInfo(offer, swalConfig.negotiateOfferSwal.title.byLandlord, 'negotiate')}`
                        : `${getOfferInfo(offer, swalConfig.negotiateOfferSwal.title.byBSO, 'negotiate')}`,
                    async (promptResult: SweetAlertResult<any>) => {
                        if (promptResult.isDismissed) {
                            // just close swal
                        } else if (!promptResult.isConfirmed) {
                            navigate(`/agent/modal/negotiate_offer_modal/${String(offer.attributes?.Unit?.data?.id)}/${String(offer?.id)}`)
                        } else {
                            void dispatch(setOfferStatus(offer.id, 'Confirmed', undefined, {
                                receiver: 'Agent',
                                rule: 'admin-offer-confirmed'
                            }))
                            await swalSuccess(swalConfig.negotiateOfferSwal.negotiateConfirmed.message)
                        }
                    },
                    new Date().getTime() - new Date(offer?.attributes.updatedAt).getTime() < 24 * 60 * 60 * 1000
                )
                break
            case 'Confirmed': {
                if (offer?.attributes.AgentToAgentID == null && offer?.attributes.AgentToLandlordID == null && !unit?.attributes?.Statuses?.includes('Door close')) {
                    await Swal.fire({
                        ...swalOptions('info'),
                        ...{
                            html: `${getOfferInfo(offer, swalConfig.confirmedOfferSwal.title)} ${swalConfig.confirmedOfferSwal.message(removeDataAttributes(_.cloneDeep(offer))?.Type?.[0]?.Offer * 0.05)}`,
                            showConfirmButton: true,
                            showCancelButton: true,
                            confirmButtonText: swalConfig.confirmedOfferSwal.confirmButtonText,
                            cancelButtonText: swalConfig.confirmedOfferSwal.cancelButtonText,
                            showLoaderOnConfirm: true,
                            allowOutsideClick: true,
                            icon : "info",
                            iconHtml: '<div  id="lottie-icon-info"></div>',
                            didOpen: () => {
                                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                                if (lottieContainer != null) {
                                    lottie.loadAnimation({
                                        container: lottieContainer,
                                        renderer: 'svg',
                                        ...{ ...animationOptions, animationData: require(`../../../animations/Info.json`) }
                                    })
                                }
                            },
                            preConfirm: async () => {
                                navigate(`/agent/modal/agent_offer_submit_client_info_modal/${String(offer.attributes?.Unit?.data?.id)}/${String(offer?.id)}`)
                                return true
                                // const cancelButton = Swal.getCancelButton()
                                // if (cancelButton != null) {
                                //     cancelButton.style.display = 'none'
                                // }
                                //
                                // const form: HTMLFormElement | null = document.querySelector('#swal-form')
                                // if (form != null) {
                                //     const email = form.Email.value
                                //     const phone = form.Phone.value
                                //     const { isSuccessful } = await setClientContactInfo(offer.id, email, phone)
                                //     if (isSuccessful) {
                                //         return true
                                //     }
                                // }
                                // if (cancelButton != null) {
                                //     cancelButton.style.display = 'block'
                                // }
                                // Swal.showValidationMessage('Client\'s email or phone is not valid')
                                //
                                // return false
                            }
                        }
                    })
                }
                break
            }
            case 'Completed':
                if (offer.attributes?.Notify === 'Agent' && !unit?.attributes?.Statuses?.includes('Door close')) {
                    await Swal.fire({
                        ...swalOptions('success'),
                        icon : "success",
                        iconHtml: '<div  id="lottie-icon-success"></div>',
                        didOpen: () => {
                            const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                            if (lottieContainer != null) {
                                lottie.loadAnimation({
                                    container: lottieContainer,
                                    renderer: 'svg',
                                    ...{ ...animationOptions, animationData: require(`../../../animations/Success.json`) }
                                })
                            }
                        },
                        html: getOfferInfo(offer, swalConfig.offerCompletedSwal.title)
                    })
                    await dispatch(removeOfferNotify(offer.id))
                }
                break
            case 'Waiting deposit pay slip':
                await swalWaitingForDepositPaySlip(dispatch, offer, false, navigate)
                break
            case 'Slip rejected':
                await swalWaitingForDepositPaySlip(dispatch, offer, true, navigate)
                break
            default:
                break
        }
    }
}

export const swalWaitingForDepositPaySlip = async (dispatch: AppDispatch, offer: any, wasRejected: boolean, navigate: NavigateFunction): Promise<SweetAlertResult<any>> => {
    return await Swal.fire({
        ...swalOptions(wasRejected ? 'error' : 'info'),
        ...{
            html: `${getOfferInfo(offer, wasRejected ? 'Offer confirmation document has been denied: Resubmit offer confirmation' : 'Upload offer confirmation document')}`, // !wasRejected ? 'Proceed to uploading' : 'Resubmit offer confirmation',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Upload later',
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: true
        },
        preConfirm: async () => {
            navigate(`/agent/modal/submit_deposit_slip_offer_modal/-/${String(offer?.id)}`)
        }
    })
    // return await Swal.fire({
    //     ...swalOptions(wasRejected ? 'error' : 'info'),
    //     ...{
    //         html: `${getOfferInfo(offer, wasRejected ? swalConfig.waitingPaySlipOfferSwal.title.wasRejected : swalConfig.waitingPaySlipOfferSwal.title.firstTime)}<div>
    //                  ${swalConfig.waitingPaySlipOfferSwal.message(calculateDeposit(offer), wasRejected)}
    //               </div>
    //               <input id="swal-input" type="file" class="form-control form-control-solid form-control-lg mt-8">
    //         `,
    //         showConfirmButton: true,
    //         confirmButtonText: swalConfig.waitingPaySlipOfferSwal.confirmButtonText,
    //         showCancelButton: true,
    //         cancelButtonText: swalConfig.waitingPaySlipOfferSwal.cancelButtonText,
    //         showLoaderOnConfirm: true,
    //         inputLabel: swalConfig.waitingPaySlipOfferSwal.inputLabel,
    //         inputValue: '',
    //         allowOutsideClick: true
    //     },
    //     preConfirm: async () => {
    //         const cancelButton = Swal.getCancelButton()
    //         if (cancelButton != null) {
    //             cancelButton.style.display = 'none'
    //         }
    //         const fileList = (document.getElementById('swal-input') as HTMLInputElement)?.files
    //         if (fileList != null && fileList?.length > 0) {
    //             const { isSuccessful, response } = await dispatch(createDocument(fileList, {
    //                 RefID: `REF_DOC_deposit_cheque_${new Date().getTime()}_${0}`,
    //                 Category: 'Deposit Cheque',
    //                 Statuses: ['In-process'],
    //                 Offer: offer.id
    //             }, 'offers', String(offer.id)))
    //             if (isSuccessful && response?.data?.id != null) {
    //                 await dispatch(setOfferStatus(offer.id, 'Waiting for slip approval', { Documents: [response?.data?.id] }, { receiver: 'BSO', rule: 'admin-offer-deposit' }))
    //                 await swalSuccess(swalConfig.waitingPaySlipOfferSwal.slipCorrect.message)
    //                 return true
    //             }
    //         }
    //         if (cancelButton != null) {
    //             cancelButton.style.display = 'block'
    //         }
    //         Swal.showValidationMessage(swalConfig.waitingPaySlipOfferSwal.slipIncorrentValidationMessage)
    //         return false
    //     }
    // })
}

export const getOfferInfo = (offer: IOffer, title?: string, mode: 'negotiate' | 'resubmit' | null = null): string => {
    const offerInfo = removeDataAttributes(_.cloneDeep(offer))
    const units = store.getState().units.units
    const unit = units?.filter((el: any) => el.id === Number(offer?.attributes.Unit?.data?.id))[0]

    return `<div class="w-100 d-flex flex-column">
                <div class="d-flex justify-content-center mb-2"><h3>${title !== null ? String(title) : 'Notification for offer'}</h3></div>
                <div class="d-flex justify-content-between"><div>Offer Unit:</div> <strong>${String(unit?.attributes?.Marketing?.Headline)}</strong></div>
                <div class="d-flex justify-content-between"><div>Offer Client:</div> <strong>${String(offerInfo?.Type?.[0]?.FirstName ?? offerInfo?.Type?.[0]?.BusinessName)} ${String(offerInfo?.Type?.[0]?.LastName ?? '')}</strong></div>
                <div class="d-flex justify-content-between"><div>Offer Price:</div> <strong>${String(offerInfo?.Type?.[0]?.Offer)} AED</strong></div>
                ${(mode === 'negotiate')
        ? `
                           ${offerInfo?.Type?.[0]?.Payments != null ? `<div class="d-flex justify-content-between"><div>Offer Payments:</div> <strong>${String(offerInfo?.Type?.[0]?.Payments)} AED</strong></div>` : ''}
                           ${offerInfo?.Type?.[0]?.Duration != null ? `<div class="d-flex justify-content-between"><div>Contract Duration:</div> <strong>${String(offerInfo?.Type?.[0]?.Duration)} months</strong></div>` : ''}
                           ${offerInfo?.Type?.[0]?.ContractStartDate != null ? `<div class="d-flex justify-content-between"><div>Contract Start Date:</div> <strong>${String(offerInfo?.Type?.[0]?.ContractStartDate)}</strong></div>` : ''}        
                        `
        : ''}
                ${(mode === 'resubmit')
        ? `
                           ${offerInfo?.RejectReason != null ? `<div class="d-flex justify-content-between"><div>Reject Reason:</div> <strong>${String(offerInfo?.RejectReason)}</strong></div>` : ''}
                        `
        : ''}
            </div><br>
            `
}
