import React from 'react'
import { Area, G2 } from '@ant-design/plots'

import { useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { sortDaysForChart } from '../../../../functions/agent/charts/daysViewed'

export default function AgentMultipleChart (): JSX.Element {
    const { registerTheme } = G2

    const currentUser = useAppSelector(
        (state: RootState) => state.user.currentUser
    )
    const userID = currentUser.id
    const viewings = useAppSelector((state: RootState) => state.viewings.viewings)
    const offers = useAppSelector((state: RootState) => state.offers.offers)

    const agentViewings = viewings.filter((el) => el?.attributes?.User?.data?.id === userID && (el?.attributes?.Statuses?.includes('Completed') || el?.attributes?.Statuses?.includes('Waiting for complain')))
    const agentOffers = offers.filter((el) => el?.attributes?.User?.data?.id === userID && (el?.attributes?.Statuses?.includes('Completed')))
    const othersViewings = viewings.filter((el) => el?.attributes?.User?.data?.id !== userID && (el?.attributes?.Statuses?.includes('Completed') || el?.attributes?.Statuses?.includes('Waiting for complain')))
    const othersOffers = offers.filter((el) => el?.attributes?.User?.data?.id !== userID && (el?.attributes?.Statuses?.includes('Completed')))

    registerTheme('custom-theme', {
        colors10: [
            '#ffc700',
            '#f1416c',
            '#7239ea',
            '#50cd89'
        ],
        colors20: [

        ],

        styleSheet: {
            fontFamily: 'Poppins, Helvetica, sans-serif'

        }

    })

    const viewingsD = sortDaysForChart(agentViewings, 'Viewings')
    const offersD = sortDaysForChart(agentOffers, 'Offers')

    const offersAverage = sortDaysForChart(othersViewings, 'Average Viewings')
    const viewingsAverage = sortDaysForChart(othersOffers, 'Average Offers')

    const data = [
        ...offersD,
        ...offersAverage,
        ...viewingsD,
        ...viewingsAverage
    ]

    // const [data, setData] = useState([])

    // useEffect(() => {
    //     asyncFetch()
    // }, [])

    // const asyncFetch = () => {
    //     fetch('https://gw.alipayobjects.com/os/bmw-prod/b21e7336-0b3e-486c-9070-612ede49284e.json')
    //         .then(async (response) => await response.json())
    //         .then((json) => { setData(json) })
    //         .catch((error) => {
    //             console.log('fetch data failed', error)
    //         })
    // }

    function findMaxValueObject (data: any) {
        let maxObject = null
        let maxDate = null

        for (const item of data) {
            if (!maxObject || item.value > maxObject.value) {
                maxObject = item
                maxDate = item.date
            }
        }

        if (!maxObject && data.length > 0) {
            const today = new Date().toISOString().split('T')[0]
            maxDate = today
        }

        return { date: maxDate, value: maxObject ? maxObject.value : 0 }
    }

    const maxObjectViews = findMaxValueObject(viewingsD)
    const maxObjectOffs = findMaxValueObject(offersD)

    interface ILegentPos {
        marker: {
            symbol: 'circle'
            style: {
                r: number
            }
        }
        layout: 'vertical' | 'horizontal' | undefined
        position?: 'top-left'
        flipPage?: boolean
        itemWidth?: number
        maxRow?: any
    }
    const legentPos: ILegentPos = {
        marker: {
            symbol: 'circle',
            style: {
                r: 8
            }
        },
        layout: 'vertical',
        position: 'top-left',
        flipPage: false,
        maxRow: 2,
        itemWidth: 200
    }

    // console.log(data, 'viewings')
    const config = {
        data,
        xField: 'date',
        yField: 'value',
        isGroup: true,
        isStack: true,
        seriesField: 'category',
        // style: {
        //     // fill: 'red',
        //     fillOpacity: 0.5,
        //     // stroke: 'black',
        //     lineWidth: 1
        //     // lineDash: [4, 5],
        //     // strokeOpacity: 0.7,
        //     // shadowColor: 'black',
        //     // shadowBlur: 10,
        //     // shadowOffsetX: 5,
        //     // shadowOffsetY: 5,
        //     // cursor: 'pointer'
        // },

        smooth: true,
        // slider: {
        //     start: 0.1,
        //     end: 0.9,
        //   },
        groupField: 'subtype',
        legend: legentPos,
        interactions: [
            {
                type: 'element-highlight'
            },
            {
                type: 'active-region'
            }
        ],
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000
            }
        },
        // animation: false,
        theme: 'custom-theme'
    }

    return (
        <>
            <div className='position-relative'>
                <div className='position-absolute top-0 end-0'>
                    {maxObjectViews.value && maxObjectViews.value > 0
                        ? <div className="card shadow-sm mb-2">
                            <div className="card-body badge badge-light-info p-2 ps-4 h-45px">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex flex-column mt-2">
                                        <div
                                            className="d-flex text-dark mb-1"
                                        >
                                            <div className='d-flex flex-start'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16">
                                                    <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                                                    <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                </svg>
                                            </div>

                                            <div className='d-flex flex-center'>
                                                <span className='fs-6 mx-1'>{maxObjectViews.date} 2023</span>
                                            </div>

                                        </div>
                                        <span
                                            className="fs-7 text-muted"
                                        >
                                    The best day for viewings
                                        </span>
                                    </div>
                                    <div className='d-flex flex-end'>
                                        <span className='d-block justify-content-end fs-5 mx-1 badge badge-info'>{maxObjectViews.value}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                    {maxObjectOffs.value && maxObjectOffs.value > 0
                        ? <div className="card shadow-sm  mb-2">
                            <div className="card-body badge badge-light-warning p-2 ps-4  h-45px">

                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex flex-column mt-2">
                                        <div
                                            className="d-flex text-dark mb-1"
                                        >
                                            <div className='d-flex flex-start'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16">
                                                    <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                                                    <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                </svg>
                                            </div>

                                            <div className='d-flex flex-center'>
                                                <span className='fs-6 mx-1'> {maxObjectOffs.date} 2023</span>
                                            </div>

                                        </div>
                                        <span
                                            className="fs-7 text-muted"
                                        >
                                    The best day for offers
                                        </span>
                                    </div>
                                    <div className='d-flex flex-end ms-6'>
                                        <span className='d-block justify-content-end fs-5 mx-1 badge badge-warning'> {maxObjectOffs.value}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        : null
                    }
                </div>
                <Area {...config} />
            </div>

        </>
    )
}
