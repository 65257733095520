import React from 'react'
import { type User, type ISettings } from '../../../../store/slices/userSlice'

interface IProps {
    user: User
}

export default function AgentAreas ({ user }: IProps): JSX.Element {
    const settings = user?.Settings as ISettings[] | undefined

    return (
        <>

            <div className={'d-flex flex-wrap gap-2 w-100 mb-2'}>
                {settings?.[0]?.Areas != null && settings[0].Areas.length > 0
                    ? (
                        settings[0].Areas.map((area) => (
                            <div
                                key={area}
                                className={'d-flex gap-1 align-items-center text-gray-600'}
                            >
                                <div className={'badge p-2 fs-7  badge-light border border-dark'}>
                                    {area}
                                </div>
                            </div>
                        ))
                    )
                    : (
                        <span className={'p-2 fs-4 align-items-center text-gray-600'}>Not add areas</span>
                    )}
            </div>
        </>
    )
}
