import React from 'react'
import { type ISettings } from '../../../../store/slices/userSlice'

import { Drawer } from 'antd'

import AddOnsServiceBricks from '../../addOnsServices/AddOnsServicesCards/AddOnsServiceBricks'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { setButtonDriverAddOnsParent } from '../../../../store/slices/modalSlice'
import { setNotEnoughMarketingCredits } from '../../../../store/slices/advertisesSlice'

interface IProps {
    settings: ISettings[]
}
function CreditBalance({ settings }: IProps): JSX.Element {
    const bricksIcon = <svg xmlns="http://www.w3.org/2000/svg" className={'text-dark fs-3x'} width={'1em'} height={'1em'} enableBackground="new 0 0 25 25" viewBox="0 0 25 25" id="SecurityWall"><path d="M1.3 15.7h13.4c.5 0 .8-.4.8-.8v-4.7c0-.5-.4-.8-.8-.8H1.3c-.5 0-.8.4-.8.8v4.7C.5 15.3.9 15.7 1.3 15.7zM23 15.7c.5 0 .8-.4.8-.8v-4.7c0-.5-.4-.8-.8-.8h-4.5c-.5 0-.8.4-.8.8v4.7c0 .5.4.8.8.8H23zM23.7 17.8H10.3c-.5 0-.8.4-.8.8v4.7c0 .5.4.8.8.8h13.4c.5 0 .8-.4.8-.8v-4.7C24.5 18.1 24.1 17.8 23.7 17.8zM1.3 24.1h4.5c.5 0 .8-.4.8-.8v-4.7c0-.5-.4-.8-.8-.8H1.3c-.5 0-.8.4-.8.8v4.7C.5 23.8.9 24.1 1.3 24.1zM23.7.9H10.3c-.5 0-.8.4-.8.8v4.7c0 .5.4.8.8.8h13.4c.5 0 .8-.4.8-.8V1.7C24.5 1.2 24.1.9 23.7.9zM1.3 7.2h4.5c.5 0 .8-.4.8-.8V1.7c0-.5-.4-.8-.8-.8H1.3c-.5 0-.8.4-.8.8v4.7C.5 6.9.9 7.2 1.3 7.2z" fill="#191d33" className="color000000 svgShape"></path></svg>
    const dispatch = useAppDispatch()
    const buttonDriverAddOnsParent = useAppSelector((state: RootState) => state.modal.buttonDriverAddOnsParent)

    return (
        <>
            <div className={'fw-bold px-2 pt-2 '}>

                <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className={'fs-2hx mt-2'}>Balance</div>

                    <span className="mt-2">

                        {bricksIcon}
                    </span>
                </div>

                <div className="d-flex  fs-4 justify-content-between my-1">
                    <span className="">Bricks</span>
                    <span className="">  {settings?.[0]?.MarketingCredits ?? '0'} </span>
                </div>
                {/* <div className="d-flex fs-4 justify-content-between my-1 ">
                    <span className="">Broadcast credit</span>
                    <span className="">
                        {settings?.[0]?.BroadcastCredits ?? '0'}
                    </span>
                </div> */}
                <div className="text-end">
                    <div className=" mt-1 btn btn-sm w-75 btn-dark fs-5 fw-bold  px-auto"
                        onClick={() => { dispatch(setButtonDriverAddOnsParent(true)) }}>Top up</div>
                </div>
            </div>

            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'auto'}
                styles={{
                    body: {
                        maxHeight: '88vh',
                        marginBottom: '3px',
                        paddingTop: '3px',
                        marginTop: '6px',
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }
                }}
                placement={'bottom'}
                closable={false}
                onClose={() => { dispatch(setButtonDriverAddOnsParent(false)); dispatch(setNotEnoughMarketingCredits(0)) }}
                open={buttonDriverAddOnsParent}
                rootStyle={{ zIndex: '1100' }}
                key={'dashboard-parent-driver-bricks'}
                maskClosable={true}
            >
                <div key={'dashboard-child-driver-bricks'}
                    className="content d-flex flex-column flex-fill p-0 m-1 px-2"
                >
                    <div className="w-100 mw-450px  mx-auto">
                        <AddOnsServiceBricks />
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default CreditBalance
