import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import PaymentMy from './PaymentMy'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { type User } from '../../../store/slices/userSlice'

import { swalError, swalSuccess } from '../../../functions/swalOptions'
import { authSlice } from '../../../store/slices/authSlice'
import { changeEmailVerifyPhone } from '../../../store/actionsCreators/userActions/changeEmailActions'
import { changeNumberVerifyEmail } from '../../../store/actionsCreators/userActions/changePhoneActions'
import { type IResponseObjectJSON } from '../../../types/userTypes/TAuth'

export default function ContactsMy (): JSX.Element {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const { currentUser } = useAppSelector(
        (state: RootState) => state.user
    )
    const { loading } = useAppSelector(
        (state: RootState) => state.modal
    )

    const [loadType, setLoadType] = useState({ emailLoad: false, phoneLoad: false, passLoad: false })

    const phone = (currentUser as User)?.phone
    const email = (currentUser as User)?.email
    const userID = (currentUser as User).id
    const phoneHide = phone.slice(0, 9)
    // console.log({ phoneHide })

    function hideEmailCharacters (email: string) {
        const atIndex = email.indexOf('@')
        const username = email.slice(2, atIndex)
        const hiddenUsername = username.replace(/./g, '*')
        return email.slice(0, 2) + hiddenUsername + email.slice(atIndex)
    }

    const onClickChange = async (callback: any) => {
        const json: IResponseObjectJSON = await dispatch(callback)
        //  console.log(json, 'json')
        if (json.isSuccessful) {
            //   console.log(json.textNotification, 'json.textNotification', json.textNavigate, 'json.textNavigate')
            void swalSuccess(json.textNotification, () => {
                navigate(json.textNavigate)
                // dispatch(authSlice.actions.authFetching(false))
            })
        } else {
            void swalError(json.textNotification, () => {
                // dispatch(authSlice.actions.authFetching(false))
                navigate(json.textNavigate)
            })
        }
        setLoadType({ emailLoad: false, phoneLoad: false, passLoad: false })
    }

    return (
        <div
            className="page d-flex flex-column flex-row-fluid fs-5"
        >

            {/* <div className="content d-flex flex-column flex-fill p-0 m-4"> */}

            <div className="w-100 mw-450px mx-auto">

                <div className="btn btn-sm fs-6 btn-light-secondary border border-dark text-dark me-2">Change Email

                </div>

                <div className="card shadow-sm  mb-5 mb-xl-10">

                    <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
                        <div className="card-title m-0">
                            <h3 className="fw-bold m-0">Your general contact</h3>
                        </div>
                    </div>

                    <div className="card-body border-top p-9">

                        <div className="d-flex flex-wrap align-items-center">
                            <div >
                                <div className="fs-6 fw-bold mb-1 text-uppercase">Email Address</div>
                                <div className="fw-semibold text-gray-600">{hideEmailCharacters(email)}</div>
                            </div>

                            { loadType.emailLoad
                                ? <span className="indicator-progress ms-2">1
                                    <span className={'spinner-border spinner-border-sm align-middle ms-2'}>2</span>
                                </span>
                                : null
                            }
                            {/* <div id="kt_signin_email_edit" className="flex-row-fluid d-none">
                                    <form id="kt_signin_change_email" className="form fv-plugins-bootstrap5 fv-plugins-framework">
                                        <div className="row mb-6">
                                            <div className="col-lg-6 mb-4 mb-lg-0">
                                                <div className="fv-row mb-0 fv-plugins-icon-container">
                                                    <label htmlFor="emailaddress" className="form-label fs-6 fw-bold mb-3">Enter New Email Address</label>
                                                    <input type="email" className="form-control form-control-lg form-control-solid" id="emailaddress" placeholder="Email Address" name="emailaddress" value="support@keenthemes.com"/>
                                                    <div className="fv-plugins-message-container invalid-feedback"></div></div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="fv-row mb-0 fv-plugins-icon-container">
                                                    <label htmlFor="confirmemailpassword" className="form-label fs-6 fw-bold mb-3">Confirm Password</label>
                                                    <input type="password" className="form-control form-control-lg form-control-solid" name="confirmemailpassword" id="confirmemailpassword"/>
                                                    <div className="fv-plugins-message-container invalid-feedback"></div></div>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <button id="kt_signin_submit" type="button" className="btn btn-primary  me-2 px-6">Update Email</button>
                                            <button id="kt_signin_cancel" type="button" className="btn btn-color-gray-400 btn-active-light-primary px-6">Cancel</button>
                                        </div>
                                    </form>
                                </div> */}

                            <div className="ms-auto">
                                {/* <Link to={`/agent/modal/phone_verify_contacts_my/${userID}/${userID}`} className="text-decoration-none"> */}
                                <div onClick={async () => {
                                    onClickChange(changeEmailVerifyPhone({ phone }))
                                    setLoadType({ ...loadType, emailLoad: true })
                                }
                                } className="btn btn-sm fs-6 btn-light-secondary border border-dark text-dark me-2">Change Email
                                 1

                                    {loading
                                    // && loadType.emailLoad
                                        ? <span className="indicator-progress ms-2">
                                            <span className={'spinner-border spinner-border-sm align-middle ms-2'}></span>
                                        </span>
                                        : <span className="indicator-label">
                                        </span>
                                    }
                                </div>
                                {/* </Link> */}
                                {/* <button className="btn btn-light btn-active-light-primary">Change Email</button> */}
                            </div>

                        </div>

                        <div className="separator separator-dashed my-6"></div>

                        <div className="d-flex flex-wrap align-items-center">
                            <div >
                                <div className="fs-6 fw-bold mb-1 text-uppercase">Phone number</div>
                                <div className="fw-semibold text-gray-600">{phoneHide}****</div>
                            </div>
                            <div className="ms-auto">
                                {/* <Link to={`/agent/modal/email_verify_contacts_my/${userID}/${userID}`} className="text-decoration-none"> */}
                                <div onClick={async () => {
                                    onClickChange(changeNumberVerifyEmail({ email }))
                                    setLoadType({ ...loadType, phoneLoad: true })
                                }} className="btn btn-sm fs-6 btn-light-secondary border border-dark text-dark me-2">Change Phone
                                    {loading
                                    // && loadType.phoneLoad
                                        ? <span className="indicator-progress ms-2">
                                            <span className={'spinner-border spinner-border-sm align-middle ms-2'}></span>
                                        </span>
                                        : <span className="indicator-label">

                                        </span>
                                    }
                                </div>
                                {/* </Link> */}

                            </div>

                        </div>
                        <div className="separator separator-dashed my-6"></div>
                        <div className="d-flex flex-wrap align-items-center mb-10">

                            <div >
                                <div className="fs-6 fw-bold mb-1 text-uppercase">Password</div>
                                <div className="fw-semibold text-gray-600">************</div>
                            </div>

                            <div className="ms-auto">
                                <div onClick={() => { navigate('/agent/modal/my_profile_reset_password_way_email/not/not') }} className="btn btn-sm fs-6 btn-light-secondary border border-dark text-dark me-2">Reset password</div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

            <PaymentMy/>

            {/* </div> */}
            <div

                className="footer fixed-bottom bg-white shadow-sm mw-450px mx-auto"
            >
                <div className="d-flex flex-row w-100 h-100 p-4 justify-content-end ">
                    <div
                        className="btn btn-light mt-2 mb-6 me-2"
                        onClick={() => { navigate('/agent/settings') }}
                    >
                                          Close
                    </div>
                </div>
            </div>

        </div>
    )
}
