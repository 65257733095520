import { useDispatch } from 'react-redux'
import {
    setActiveId,
    setAudioSrc,
    setIsPlaying,
    setIsShown,
    setMessageAuthor,
    setMessageDate, setTimeJump
} from '../store/slices/voiceSlice'
import { useAppSelector } from './redux'
import { type RootState } from '../store/store'

interface IUseVoiceMessage {
    pause: () => void
    play: () => void
    isPlaying: boolean
}

interface UseVoiceMessageProps {
    date: Date
    author: string
    audioSrc: string
    id: string
}

const UseVoiceMessage = ({ date, author, audioSrc, id }: UseVoiceMessageProps): IUseVoiceMessage => {
    const dispatch = useDispatch()
    const {
        activeId,
        isPlaying
    } = useAppSelector((state: RootState) => state.voice)
    const play = (): void => {
        dispatch(setIsShown(true))
        if (activeId !== id) {
            dispatch(setMessageDate(date.toString()))
            dispatch(setMessageAuthor(author))
            dispatch(setIsPlaying(false))
            dispatch(setTimeJump(0))
            dispatch(setAudioSrc(audioSrc))
            dispatch(setActiveId(id))
            setTimeout(() => {
                dispatch(setIsPlaying(true))
            }, 10)
        } else {
            dispatch(setIsPlaying(true))
        }
    }

    const pause = (): void => {
        if (activeId === id) {
            dispatch(setIsPlaying(false))
        }
    }

    return ({
        play,
        pause,
        isPlaying: isPlaying && activeId === id
    })
}

export default UseVoiceMessage
