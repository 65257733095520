import { Controller } from 'react-hook-form'
import { Upload } from 'antd'
import type { RcFile } from 'antd/es/upload'

import React from 'react'

import Icon from '../../icon/Icon'
import _ from 'lodash'
import CustomFormLable from '../customModalUtils/CustomFormLable'

interface CustomMultipleImagesInputProps {
    row: any
    control: any
    errors: any
    getValues: any
}
const CustomMultipleFilesInput = ({ row, control, errors, getValues }: CustomMultipleImagesInputProps): JSX.Element => {
    return (
        <>
            <CustomFormLable row={row} />
            <div className={'custom-multiple-images-input'}>
                <Controller
                    control={control}
                    name={row.id}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Upload
                            multiple
                            listType="picture-card"
                            itemRender={(el, file, fileList) => (
                                <div className="ant-upload-list-item overflow-hidden d-flex align-items-center justify-content-center">
                                    {
                                        _.isString(file)
                                            ? <Icon name={'download_for_offline_24dp'}
                                                className={'fs-6x position-absolute text-muted'}
                                                styles={{
                                                    zIndex: '10'
                                                }}
                                                onClick={() => {
                                                    window.open(file)
                                                }}
                                            />
                                            : <>
                                                {
                                                    file.name
                                                }
                                            </>
                                    }
                                    <Icon name={'delete_24dp'}
                                        className={'fs-3x position-absolute text-muted'}
                                        styles={{
                                            zIndex: '10',
                                            top: '1rem',
                                            right: '1rem'
                                        }}
                                        onClick={() => {
                                            if (_.isString(file)) {
                                                onChange(value.filter((fileItem: RcFile | string) => fileItem !== file))
                                            } else {
                                                onChange(value.filter((fileItem: RcFile) => fileItem?.uid !== file?.uid))
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            fileList={value?.filter(Boolean) ?? []}
                            // onRemove={(file) => {
                            //     onChange(value.filter((fileItem: RcFile) => fileItem.uid !== file.uid))
                            // }}
                            customRequest={(options) => {
                                const oldValues = getValues()?.[row.id as string] ?? []
                                console.log(options, oldValues)
                                onChange([...oldValues, options.file])
                            }}
                        >
                            <div className={'btn btn-bso'}>Upload</div>
                        </Upload>
                    )}
                />
            </div>
        </>
    )
}

export default CustomMultipleFilesInput
