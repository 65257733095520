import { type AppDispatch, type RootState } from '../../store'
import { setDateChooseViewing } from '../../slices/modalSlice'

export const setModalDateChooseViewing = (viewingID: number) => (dispatch: AppDispatch, getState: () => RootState): void => {
    const viewings = getState().viewings.viewings ?? []
    const currentViewing = viewings.find((viewing) => viewing.id === viewingID)
    if (currentViewing != null) {
        const dateChooseViewing = currentViewing.attributes.Datetime
        dispatch(setDateChooseViewing(dateChooseViewing))
    } else {
        console.error(`Viewing with id ${viewingID} does not exist in the state`)
    }
}
