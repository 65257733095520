import React from 'react'
import _ from 'lodash'
import SharedVoice from './SharedVoice'
import { type IChatMessage } from '../../../../types'
import { nanoid } from 'nanoid'

interface SharedVoiceListProps {
    messages: IChatMessage[]
}

const SharedVoiceList = ({ messages }: SharedVoiceListProps): JSX.Element => {
    return (
        <div className={'d-flex flex-column gap-2 w-100'}>
            {messages.length > 0
                ? _.map(messages, (message) => (
                    <SharedVoice
                        time={new Date(message.updatedAt)}
                        sentBy={String(message.User?.FirstName) + ' ' + String(message.User?.FamilyName)}
                        id={nanoid()}
                        audioSrc={String(message.Attachments?.data)}
                    />
                ))
                : <div className="card shadow-sm my-1 bg-secondary">
                    <div className="card-body p-0 m-5 text-center">
                        <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 ">
                            No voice
                        </span>
                    </div>
                </div>
            }
        </div>
    )
}

export default SharedVoiceList
