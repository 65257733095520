interface SoftlyIncludesProps {
    target: string
    value: string
    accuracy: number
}

/**
 * A function that checks that a string (value) is included in another string (target) with maximum of n (accuracy) errors
 * @param target
 * @param value
 * @param accuracy
 */
export default function softlyIncludes ({ target, value, accuracy }: SoftlyIncludesProps): boolean {
    const targetSplit = target.split('')
    const valueSplit = value.split('')
    if (targetSplit.length < valueSplit.length) {
        return false
    }
    const inclusionErrors: number[] = []
    for (let i = 0; i < targetSplit.length - valueSplit.length + 1; i++) {
        let errorCounter = 0
        for (let j = 0; j < valueSplit.length; j++) {
            // console.log(`Step ${i + j}: ${targetSplit[i + j]}, ${valueSplit[j]}`)
            if (targetSplit[i + j] !== valueSplit[j]) {
                errorCounter++
            }
        }
        inclusionErrors.push(errorCounter)
    }
    const inclusionAccuracy = Math.min(...inclusionErrors)
    return inclusionAccuracy <= accuracy
}
