import { type NavigateFunction } from 'react-router-dom'
import { ACCESS_KEY, urlAPI } from '../../../urls'
import { type AppDispatch, type RootState } from '../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'
import { deleteDocument } from '../documentsActions/deleteDocument'
import { deleteProperty } from '../propertyActions'

export const deleteAgentUnit = (unitID: number, navigate?: NavigateFunction) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const unit = getState().units.units.find(unitItem => unitItem.id === unitID)
    try {
        if (!unit?.attributes?.Statuses.includes('Archived')) {
            return
        }
        dispatch(dataFetchingStart())

        const unitDocuments = unit?.attributes?.Documents
        if (unitDocuments && unitDocuments.data.length > 0) {
            for (const doc of unitDocuments.data) {
                await deleteDocument(doc.id)
            }
        }
        const deletedProperty = await dispatch(deleteProperty(unit?.attributes?.Property?.data.id as number))
        if (deletedProperty.isSuccessful) {
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify({
                    method: 'DELETE',
                    collection: 'units',
                    id: Number(unitID)
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })

            const responseJSON = await response.json() as unknown as any
            dispatch(dataFetchingFinish())
            return responseJSON
        }
        dispatch(dataFetchingFinish())
    } catch (err) {
        dispatch(dataFetchingFinish())
        console.error(err)
    }
}
