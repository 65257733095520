
export function getCurrentDubaiDate (): Date {
    const dubaiTimezoneOffset = 4 * 60 // Временная зона Дубая в минутах (UTC+4)
    const now = new Date()
    const currentUtcTime = now.getTime() + (now.getTimezoneOffset() * 60000) // Текущее UTC время
    const dubaiCurrentUtcTime = currentUtcTime + (dubaiTimezoneOffset * 60000) // Текущее UTC время в Дубае
    const dubaiDate = new Date(dubaiCurrentUtcTime)
    return dubaiDate
}

export function getCurrentDubaiDateString (): string {
    const dubaiDate = getCurrentDubaiDate()
    const year = dubaiDate.getFullYear()
    const month = dubaiDate.getMonth() + 1
    const day = dubaiDate.getDate()
    return String(year) + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0')
}
