import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { type IChatListItem } from '../../types'

export interface ChatListState {
    chatList: IChatListItem[]
}

const initialState: ChatListState = {
    chatList: []
}

export const chatListSlice = createSlice({
    name: 'chatList',
    initialState,
    reducers: {
        setChatList: (state: ChatListState, action: PayloadAction<IChatListItem[]>) => {
            state.chatList = action.payload
        }
    }
})

export const { setChatList } = chatListSlice.actions

export default chatListSlice.reducer
