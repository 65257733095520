
import React from 'react'

import Icon from '../../../icon/Icon'
import Markdown from 'react-markdown'


export default function DescriptionCreateAdLinks(): JSX.Element {
    return (

        <div className="mb-4">
            <div className={'rounded rounded-sm bg-gray-300 p-4'}>
                <label className={'form-label fw-bold fs-5 text-dark m-0 d-flex align-items-center text-uppercase '}>
                    <Icon name={'help'} className={'fs-2x text-info me-1'} />
                    Instruction
                </label>
                <div className='fw-semi fs-5  lh-1 mt-2'>
                    <Markdown>
                        1. After you receive unit documents you can create advertisement posts with this unit on 3-party platforms like Dubizzle, Bayut, Property Finder, etc.
                    </Markdown>
                    <Markdown>
                        2. After you published all needed advertisements please get Trakheesi ID Number of advertisement publication (you can get info about how you can get Trakheesi Number in tech support of the platform where you publish your ads)
                    </Markdown>

                </div>
            </div>
        </div>
    )
}
