import { ACCESS_KEY, urlAPI } from '../../../urls'
import { dataFetchingFinish, dataFetchingStart, modalSlice } from '../../slices/modalSlice'
import { store, type AppDispatch, type RootState } from '../../store'
import qs from 'qs'

import { getErrorss } from '../../../functions/errors/GetErrors'
import nanToNull from '../../../functions/adapters/universal/nanToNull'
import dayjs from 'dayjs'
import { setCreatePropertyFields } from '../../slices/propertySlice'
import { getQueryUnits } from '../../../functions/shared/api/queries/getQueryUnits'

export const createAdditionalOptionsForUnit = (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    try {
        console.log(data, 'data')
        dispatch(dataFetchingStart())
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        // const properties = getState().property.properties
        // const property = properties?.find(property => property.id === Number(data.objectID))
        //  data.RefID = `${String(data.Number)}-${String(property?.attributes?.Name)}-${String(property?.attributes?.Area)}-${new Date().getTime()}`
        data.Bedrooms = nanToNull(data.Bedrooms)
        data.Bathrooms = nanToNull(data.Bathrooms)
        data.GuestBathroom = data.GuestBathroom //= == 'Yes'
        data.MaidsRoom = data.MaidsRoom //= == 'Yes'
        data.Statuses = ['Door close']

        if (data.StatusesAvailability) {
            data.Statuses.push(data.StatusesAvailability)
        } if (data.StatusesDoor) {
            data.Statuses.push(data.StatusesDoor)
        } if (data.StatusesMortgage && data.StatusesMortgage === 'Mortgage') {
            data.Statuses.push(data.StatusesMortgage)
        }
        data.OccupiedUntil = data.OccupiedUntil ? dayjs(data.OccupiedUntil).add(4, 'hours') : null

        const bodyObject = {
            method: 'PUT',
            collection: 'units',
            id: Number(data.unitID),
            body: data,
            query: getQueryUnits()
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response?.json() as unknown as any

        if (responseJSON.success) {
            if (responseJSON.response.error != null) {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
            } else {
                dispatch(setCreatePropertyFields(null))
                dispatch(dataFetchingFinish())
                return {
                    textNavigate: `/agent/modal/agent_create_unit_marketing/${responseJSON?.response?.data?.id}/-/`,
                    isSuccessful: true,
                    textNotification: 'Additional options was added successfully next go to add marketing',
                    title: 'Success'
                }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        dispatch(dataFetchingFinish())
        console.log(error)
        return {
            isSuccessful: false
        }
    }
}
