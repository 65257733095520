import { type IChatMessage } from '../../../types'
import moment from 'moment/moment'
import dayjs from 'dayjs'

type IMessageOrDate = IChatMessage | { date: string }

const messageDatesAdapter = (Messages: IChatMessage[], direction: 'asc' | 'desc' | undefined = 'asc'): IMessageOrDate[] => {
    const messages: IMessageOrDate[] = []
    //  console.log(Messages)
    if (direction === 'desc') {
        if (Messages[0] !== undefined) {
            if (direction === 'desc') {
                messages.push({
                    date: moment(Messages[0].updatedAt).format('LL')
                })
            }
            messages.push(Messages[0])
        }
        for (let i = 1; i < Messages.length; i++) {
            // if (direction === 'asc') {
            //     messages.push(Messages[i])
            // }
            if (
                moment(Messages[i].updatedAt).day() !== moment(Messages[i - 1].updatedAt).day() ||
                moment(Messages[i].updatedAt).week() !== moment(Messages[i - 1].updatedAt).week() ||
                moment(Messages[i].updatedAt).month() !== moment(Messages[i - 1].updatedAt).month() ||
                moment(Messages[i].updatedAt).year() !== moment(Messages[i - 1].updatedAt).year()
            ) {
                messages.push({
                    date: moment(Messages[i].updatedAt).format('LL')
                })
            }
            if (direction === 'desc') {
                messages.push(Messages[i])
            }
        }
        //  if (direction === 'asc' && messages?.length > 0 && (messages[messages.length - 1] as {
        //      date: string
        //       })?.date === undefined) {
        //      messages.push({
        //          date: moment((messages[messages.length - 1] as IChatMessage).updatedAt).format('LL')
        //      })
        // }
    } else {
        if (Messages[0] !== undefined) {
            messages.push(Messages[0])
        }
        for (let i = 1; i < Messages.length; i++) {
            if (!moment(Messages[i].updatedAt).isSame(moment(Messages[i - 1].updatedAt), 'day')) {
                messages.push({
                    date: moment(Messages[i - 1].updatedAt).format('LL')
                })
            }
            messages.push(Messages[i])
        }
        if (direction === 'asc' && messages?.length > 0 && (messages[messages.length - 1] as {
            date: string
        })?.date === undefined) {
            messages.push({
                date: moment((messages[messages.length - 1] as IChatMessage).updatedAt).format('LL')
            })
        }
    }
    return messages
}

export default messageDatesAdapter
