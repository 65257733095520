
import { type AppDispatch, type RootState } from '../../../store'
import { setOfferStatus } from '../../offerActions'
import swalConfig from '../../../../app/texts/swalTexts'
import { dataFetchingFinish, dataFetchingStart } from '../../../slices/modalSlice'
import { createDocument } from '../../documentsActions/createDocument'

export const addOfferPaymentConfirmation = (offerID: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ isSuccessful: boolean, textNotification?: string, textNavigate?: number }> => {
    dispatch(dataFetchingStart())
    try {
        const offer = getState().offers.offers.find((offerItem) => offerItem.id === offerID)
        const fileList = data.file
        if (fileList != null && fileList?.length > 0) {
            const { isSuccessful, response } = await dispatch(createDocument(fileList, {
                RefID: `REF_DOC_deposit_cheque_${new Date().getTime()}_${0}`,
                Category: data.payment_type === 'Bank cheque' ? 'Deposit Cheque' : 'Offer Confirmation',
                Statuses: ['In-process'],
                Offer: offer?.id
            }, 'offers', String(offer?.id)))
            if (isSuccessful && response?.data?.id != null) {
                await dispatch(setOfferStatus(Number(offer?.id), 'Waiting for slip approval', { Documents: [...(offer?.attributes?.Documents?.data?.map(doc => ({ id: doc.id })) ?? []), response.data.id] }, {
                    receiver: 'BSO',
                    rule: 'admin-offer-deposit'
                }))
                return {
                    textNotification: swalConfig.waitingPaySlipOfferSwal.slipCorrect.message,
                    textNavigate: -1,
                    isSuccessful: true
                }
            }
        }
    } catch (err) {
        console.error(err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}
