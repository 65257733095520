import Swal from 'sweetalert2'
import { swalOptions } from '../swalOptions'

interface ConfirmActionProps {
    title?: string
    description?: string
    confirmText?: string
    cancelText?: string
    callback: () => void
}
export default function confirmAction (
    {
        title = 'Are you sure',
        description = undefined,
        confirmText = 'OK',
        cancelText = 'Cancel',
        callback
    }: ConfirmActionProps
): void {
    void Swal.fire({
        ...swalOptions('warning'),
        title,
        html: description,
        confirmButtonText: confirmText,
        denyButtonText: cancelText,
        showDenyButton: true
    }).then((result) => {
        if (result.isConfirmed) {
            callback()
        }
    })
}
