import React from 'react'
import { Controller } from 'react-hook-form'
import CustomFormLable from '../customModalUtils/CustomFormLable'
import _ from 'lodash'
const CustomRangeInput = ({ row, control, register, errors }: any): JSX.Element => {
    return (
        <>
            <Controller
                name={row.id}
                control={control}
                // defaultValue={data.defaultValue}
                defaultValue={row.default || row.default === 'undefined' ? row.default : undefined}
                rules={row.required ? { required: `${row.content?.[0]} is required` } : {}}
                render={({ field }) => (
                    <div className="fv-row mb-2 m-0 p-0 w-100 fv-plugins-icon-container">
                        <CustomFormLable row={row} />
                        {/* <MyInput /> */}
                        <div className={'d-flex gap-4'}>
                            <input
                                {...register(row.id, {
                                    required: row.required === true ? `${row.content?.[0]} is required` : undefined,
                                    minLength: row.minLength != null ? row.minLength : 2,
                                    maxLength: 60
                                })}
                                value={field.value?.[0] ?? ''}
                                className="form-control form-control-solid form-control-lg"
                                placeholder={'From'}
                                defaultValue={row.defaultValue?.[0]}
                                onInput={(e) => {
                                    field.onChange([(e.target as HTMLInputElement).value, field.value?.[1]])
                                    // field.onChange([100, 1000])
                                }}
                                type="text"
                            />
                            <input
                                {...register(row.id, {
                                    required: row.required === true ? `${row.content?.[0]} is required` : undefined,
                                    minLength: row.minLength != null ? row.minLength : 2,
                                    maxLength: 60
                                })}
                                value={field.value?.[1] ?? ''}
                                className="form-control form-control-solid form-control-lg"
                                placeholder={'To'}
                                defaultValue={row.defaultValue?.[1]}
                                onChange={(e) => {
                                    field.onChange([field.value?.[0], e.target.value])
                                }}
                                type="text"
                            />
                        </div>
                        {
                            _.get(errors, row.id) != null && (
                                _.get(errors, row.id).type === 'minLength'
                                    ? <div
                                        className="text-danger">{`${row.content?.[0]} can not be less than 2 characters `} </div>
                                    : _.get(errors, row.id).type === 'maxLength'
                                        ? <div
                                            className="text-danger">{`${row.content?.[0]} cannot exceed  60 characters more `} </div>
                                        : _.get(errors, row.id).type === 'validate'
                                            ? <div className="text-danger">{`${row.content?.[0]} must be valid `} </div>
                                            : <div className="text-danger">{_.get(errors, row.id).message}</div>
                            )
                            /* errors[row.id] && (
                                <div className="text-danger">{errors[row.id].message}</div>
                            ) */
                        }
                        {
                            /* errors[row.id] && errors[row.id].type === 'minLength' && (
                                <div className="text-danger">{`${row.content?.[0]} can not be less than 3 characters `} </div>
                            ) */
                        }
                        { /*
                                errors[row.id] && errors[row.id].type === 'maxLength' && (
                                    <div className="text-danger">{`${row.content?.[0]} cannot exceed  60 characters more `} </div>
                                ) */
                        }

                    </div>
                )}
            />
        </>
    )
}

export default CustomRangeInput
