import React from 'react'
import AgentHeader from '../AgentHeader'
import MenuItem from './MenuItem'

function PerformanceMenuSection(): JSX.Element {
    return (
        <>
            <div
                className="page d-flex flex-column flex-row-fluid fs-5"
                id='header_performance_section'
            >

                <AgentHeader
                    active="Performance"

                />

                <div className="content d-flex flex-column flex-fill p-0 m-4">

                    <div className="w-100 mw-450px mx-auto">
                        <div className="card shadow-sm mb-4 ">
                            <div className="card-body p-4">
                                <MenuItem
                                    description={'Agents Leaderboard, your current rating'}
                                    label={'Agents Leaderboard'}
                                    route={'/agent/rating'}
                                    bgColor={'success'}
                                    icon={
                                        <i className="bi bi-person-up fs-3x mx-auto text-white"></i>
                                    }
                                />
                                <MenuItem
                                    description={'Your performance overview'}
                                    label={'My Performance'}
                                    route={'/agent/performance'}
                                    bgColor={'info'}
                                    icon={

                                        <i className="bi bi-activity fs-3x mx-auto text-white"></i>

                                    }
                                />
                                <MenuItem
                                    description={'Membership overview. view experience bar'}
                                    label={'My Membership'}
                                    route={'/agent/settings/memberships'}
                                    bgColor={'info'}
                                    icon={
                                        <i className="bi bi-check2-circle fs-3x mx-auto text-white"></i>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PerformanceMenuSection
