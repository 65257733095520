import { createSlice } from '@reduxjs/toolkit'
import { USER_ID_KEY } from '../../urls'
interface IUserVar {
    data: {
        id: number
        attributes: {}
    } | null
}

interface IUnitVar {
    data: {
        id: number
        attributes: {
            User: IUserVar | null
        }
    } | null
}

export interface IAdvertise {
    id: number
    attributes: {
        Name: string | null
        createdAt: string
        updatedAt: string
        CreditsAmount: number | null
        Notify: string | null
        Status?: string
        Statuses: string[]
        Links: string | null
        StartDate: string | null
        EndDate: string | null
        Duration: number | null
        RejectReason: string | null
        User: {
            data: null | {
                id: number
                attributes: {}
            }
        }
        Unit: IUnitVar
        ImagesOnly : boolean | null
    }

}

interface IAdvertiseState {
    advertises: IAdvertise[]
    advertisesLoading: boolean
    error: string
    debitMarketingCredits: number
    notEnoughMarketingCredits: number
    unitIDnotEnoughAreaForAdvertise: number | undefined
    isExtend: boolean
    advertisesScrollPosition: number
    isAlreadyOpenLink: boolean
    areThereMoreThanFiveAvertises: boolean
}

const initialState: IAdvertiseState = {
    advertises: [],
    advertisesLoading: false,
    error: '',
    debitMarketingCredits: 0,
    notEnoughMarketingCredits: 0,
    unitIDnotEnoughAreaForAdvertise: undefined,
    advertisesScrollPosition: 0,
    isExtend: false,
    isAlreadyOpenLink: false,
    areThereMoreThanFiveAvertises: false
}
const userID = window.localStorage.getItem(USER_ID_KEY)

export const advertisesSlice = createSlice({
    name: 'advertises',
    initialState,
    reducers: {
        advertisesFetching (state) {
            state.advertisesLoading = true
        },
        advertisesFetchingSuccess (state, action) {
            state.error = ''
            state.advertises = action.payload
            state.advertisesLoading = false
            state.debitMarketingCredits = 0
            state.notEnoughMarketingCredits = 0
            state.unitIDnotEnoughAreaForAdvertise = undefined
            state.isAlreadyOpenLink = false
            state.areThereMoreThanFiveAvertises = action.payload.filter((ad: IAdvertise | null) => ad?.attributes?.User?.data?.id === Number(userID))?.length > 5
        },
        addAdvertise: (state, action) => {
            state.error = ''
            const actualAdvertises = [...state.advertises, action.payload]
            state.areThereMoreThanFiveAvertises = actualAdvertises.filter((ad: IAdvertise | null) => ad?.attributes?.User?.data?.id === Number(userID))?.length > 5
            state.advertises = actualAdvertises
            state.advertisesLoading = false
            state.debitMarketingCredits = 0
            state.notEnoughMarketingCredits = 0
            state.unitIDnotEnoughAreaForAdvertise = undefined
        },
        setAdvertises: (state, action) => {
            state.error = ''
            const copyAdvertises = [...state.advertises]
            const updatedAdvertises = copyAdvertises.map((adv: any) => {
                if (adv.id === action.payload.id) {
                    return {
                        ...action.payload
                    }
                } else {
                    return adv
                }
            })

            state.areThereMoreThanFiveAvertises = updatedAdvertises.filter((ad: IAdvertise | null) => ad?.attributes?.User?.data?.id === Number(userID))?.length > 5
            state.advertises = updatedAdvertises
            state.advertisesLoading = false
        },
        advertisesFetchingError (state, action) {
            state.advertisesLoading = false
            state.error = action.payload.message
            state.debitMarketingCredits = 0
            state.notEnoughMarketingCredits = 0
            state.unitIDnotEnoughAreaForAdvertise = undefined
            state.isAlreadyOpenLink = false
        },
        setDebitMarketingCredits (state, action) {
            state.debitMarketingCredits = action.payload
        },
        setNotEnoughMarketingCredits (state, action) {
            state.notEnoughMarketingCredits = action.payload
        },
        setUnitIDnotEnoughAreaForAdvertise (state, action) {
            state.unitIDnotEnoughAreaForAdvertise = action.payload
        },
        setIsExtend (state, action) {
            state.isExtend = action.payload
        },
        setIsAlreadyOpenLink (state, action) {
            state.isAlreadyOpenLink = action.payload
        },
        setAdvertisesScrollPosition (state, action) {
            state.advertisesScrollPosition = action.payload
        }
    }
})
export const { addAdvertise, setDebitMarketingCredits, setAdvertises, setNotEnoughMarketingCredits, setUnitIDnotEnoughAreaForAdvertise, setIsExtend, setIsAlreadyOpenLink } = advertisesSlice.actions
export default advertisesSlice.reducer
