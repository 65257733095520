

import {  getErrorss } from '../../functions/errors/GetErrors'
import { ACCESS_KEY , urlAPI } from '../../urls'
import { documentsSlice } from '../slices/documentSlice'
import { type AppDispatch} from '../store'
import { getQueryDocuments } from '../../functions/shared/api/queries/getQueryDocuments'

export const fetchDocuments = (noLading?: boolean) => async (dispatch: AppDispatch) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)

    if (noLading !== true) {
        dispatch(documentsSlice.actions.documentsFetching())
    }
    try {
        const bodyObject = {
            method: 'GET',
            collection: 'documents',
            query: getQueryDocuments()
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt as string}`
            },
            body: JSON.stringify(bodyObject)
        })

        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(documentsSlice.actions.documentsFetchingError(getErrorss(dataJSON)))
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(documentsSlice.actions.documentsFetchingSuccess(dataJSON?.response?.data))
                return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(documentsSlice.actions.documentsFetchingError(getErrorss(dataJSON)))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(documentsSlice.actions.documentsFetchingError(JSON.stringify(error)))
        return { textNavigate: '', isSuccessful: false, textNotification: '' }
    }
}





