import React from 'react'
import { useAppSelector } from '../../../hooks/redux'
import { useDispatch } from 'react-redux'
import { setSwitch } from '../../../store/slices/serviceSlice'

const SetSwitch = (): JSX.Element => {
    const service = useAppSelector((state) => state.service)
    const dispatch = useDispatch()
    return (
        <div className="fv-row mb-2 m-0 p-0 mb-2 w-100 fv-plugins-icon-container"
            onClick={() => {
                dispatch(setSwitch(!service.switchUnit))
            }}
        >
            <div className="ms-20 d-flex bg-light-dark w-225px h-35px position-relative rounded-4">
                <div className={
                    service.switchUnit
                        ? 'position-absolute d-flex on start-0 bg-dark rounded-4 mt-1 ms-2'
                        : 'position-absolute d-flex off start-0 rounded-4 mt-1 ms-2'}>
                    <span className={
                        service.switchUnit
                            ? 'align-items-center text-center text-white  fw-bold fs-6 w-100px h-25px mt-1'
                            : 'align-items-center text-center text-dark fw-bold fs-6 w-100px h-25px mt-1'}>UNIT</span>
                </div>
                <div className={
                    !service.switchUnit
                        ? 'position-absolute d-flex on start-50 bg-dark rounded-4 mt-1 ms-2'
                        : 'position-absolute d-flex off start-50 rounded-4 mt-1 ms-2'}>
                    <span className={
                        !service.switchUnit
                            ? 'align-items-center text-center text-white  fw-bold fs-6 w-100px h-25px mt-1'
                            : 'align-items-center text-center text-dark fw-bold fs-6 w-100px h-25px mt-1'}>OTHER</span>
                </div>
            </div>
        </div>
    )
}

export default SetSwitch
