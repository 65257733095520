import swalTexts from '../../../app/texts/swalTexts'
import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { timeStringInScheduler } from '../../../functions/agent/date/timeStringInScheduler'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { getErrorss } from '../../../functions/errors/GetErrors'
import sendMail from '../../../functions/shared/email/sendMail'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'
import { removeNotifyByDeviceToken } from '../../../functions/shared/notify-helpers/removeNotify'

import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../urls'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'

import { setShowPopupViewingReschedule } from '../../slices/swalsSlice'
import { type User } from '../../slices/userSlice'
import { setViewings, viewingsFetchingError } from '../../slices/viewingsSlice'
import { type AppDispatch, type RootState } from '../../store'
import { viewingsQuery } from '../viewingsActions'

export const updateStatusViewing = (bodyClient: any) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            dispatch(dataFetchingStart())
            const unitID = bodyClient.unitID
            const units: any = getState().units.units
            const unit = units?.filter((el: any) => el.id === Number(unitID))
            const unitStatuses = unit[0].attributes?.Statuses ?? []

            const currentUnit = unit?.[0]
            const currentUser = getState().user.currentUser
            const nameUser = getNameUser(currentUser as User)
            const unitAddress = getUnitAddress(currentUnit)

            const createDigitalLock = unitStatuses?.includes('Digital lock')
            const createKeysInBSO = unitStatuses?.includes('Keys in BSO office')
            const createBuildingManagement = unitStatuses?.includes('Building Management')
            const createOccupied = unitStatuses?.includes('Occupied')
            const isDoorOpen = unitStatuses?.includes('Door open')

            const isOtherAgent = bodyClient.isOtherAgent
            const response = await fetch(urlAPI,
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    body: JSON.stringify({
                        method: 'PUT',
                        collection: 'viewings',
                        query: viewingsQuery,
                        id: Number(bodyClient.viewID),
                        body: {
                            Statuses: [bodyClient.status],
                            Notify: isOtherAgent || 'BSO'
                        }
                    })
                })

            const dataJSON = await response.json()

            if (dataJSON.success) {
                if (dataJSON.response.error != null) {
                    dispatch(viewingsFetchingError(getErrorss(dataJSON)))
                    dispatch(dataFetchingFinish())
                    return { textNavigate: '', isSuccessful: false, textNotification: '' }
                } else {
                    await removeNotifyByDeviceToken({
                        userID: getState().user.currentUser.id as number,
                        deviceToken: getState().user.currentUser.DeviceToken as string
                    })
                    dispatch(setViewings(
                        dataJSON.response.data
                    ))
                    dispatch(setShowPopupViewingReschedule(false))

                    const currentDubaiDate = getCurrentDubaiDate()
                    const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)

                    if (bodyClient.status === 'Rejected' || bodyClient.status === 'Canceled') {
                        if (!isDoorOpen) {
                            //    isOtherAgent
                            await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-rejected', `Attention admin, ${bodyClient?.nameUser} CANCELED viewing by ${bodyClient?.unitAdress}.`)
                        }
                    } if (bodyClient.status === 'Approved') {
                        await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-approved', `Attention admin, ${bodyClient?.nameUser} APPROVED viewing by ${bodyClient?.unitAdress}.`)

                        if (createOccupied) {
                            const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                            await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-in-process', `Attention admin, ${nameUser} has selected ${unitAddress} which is in Occupied , please respond.`)
                        }
                        if (createKeysInBSO) {
                            const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                            await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-key', `Attention admin, ${nameUser} has selected ${unitAddress} which contains an Keys in BSO , please respond.`)
                        }
                        if (createDigitalLock) {
                            const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                            await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-digital-lock', `Attention admin, ${nameUser} has selected ${unitAddress} which contains an digital lock , please respond.`
                            )
                        }
                        if (createBuildingManagement) {
                            const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                            await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-building-management', `Attention admin, ${nameUser} has selected ${unitAddress} which contains an Building Management , please respond.`
                            )
                        }
                        if (isDoorOpen) {
                            const [dateDubai, timeDubai] = timeStringInScheduler(
                                dataJSON?.response?.data?.attributes?.Datetime
                            ).split(',')

                            const sentMail = await sendMail({
                                email: currentUser?.email as string,
                                templateId: 'd-c319ebc8b1f242eb82936867b49e5667',
                                dynamicTemplateData: { date: dateDubai, time: timeDubai, unit: unitAddress }

                            })
                        }
                    }
                    dispatch(dataFetchingFinish())
                    return { ...dataJSON, textNavigate: -1, isSuccessful: true, textNotification: swalTexts.successViewingsActions.updateViewing.defaultMessage }
                }
            } else {
                dispatch(dataFetchingFinish())
                dispatch(viewingsFetchingError(getErrorss(dataJSON)))
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } catch (e) {
            dispatch(dataFetchingFinish())
            console.log(e)
        }
    }
}
