import nanToNull from "../../../functions/adapters/universal/nanToNull"
import { getErrorss, getMessageForError } from "../../../functions/errors/GetErrors"
import { getQueryDocuments } from "../../../functions/shared/api/queries/getQueryDocuments"
import { getQueryUnits } from "../../../functions/shared/api/queries/getQueryUnits"
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from "../../../urls"
import { documentsSlice } from "../../slices/documentSlice"
import { dataFetchingFinish, dataFetchingStart } from "../../slices/modalSlice"

import { type AppDispatch, type RootState } from "../../store"
import { uploadSingleFile } from "../documentsActions/upload/uploadSingleFile"
import { deleteData } from "../userActions/deleteUserAction"




export const createDraftUnit = (bodyClient: any) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''

    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const temporaryTitleDeed = getState().documents.temporaryTitleDeed
            const propertyTitleDeed = temporaryTitleDeed?.document?.property
            dispatch(dataFetchingStart())
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify({
                    method: 'POST',
                    collection: 'properties',
                    body: {
                        RefID: [propertyTitleDeed?.name, propertyTitleDeed?.area, new Date().getTime()]?.join(' <-> '),
                        Name: propertyTitleDeed?.name ,
                        Type: propertyTitleDeed?.type === "Villa" || propertyTitleDeed?.type === "Land" ? 'Community' : 'Building',
                        MAKANI: propertyTitleDeed?.makani,
                        ManagedBy: Number(userID),
                        ...(propertyTitleDeed?.type === "Villa" ? { Community: propertyTitleDeed?.name } : {}),
                        ...(getState().app.config?.all.Area.includes(temporaryTitleDeed?.document?.property?.area) ? {Area:  temporaryTitleDeed?.document?.property?.area } : {}),
                        //  GoogleMapsLink: bodyClient?.GoogleMapsLink,
                        //  Developer: bodyClient?.Developer,
                        //   PlotNumber: bodyClient?.PlotNumber,
                        //   ...(buildingType === 'Commercial' ? { AreaType: 'Commercial Office' } : {}),
                    }
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            const dataPropertyJSON = await response.json()
            console.log(dataPropertyJSON,"dataPropertyJSON")
            if (dataPropertyJSON.success) {
                if (dataPropertyJSON.response.error != null) {
                    return { ...dataPropertyJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataPropertyJSON) }
                } else {
                    let docTitleDeed
                    if (temporaryTitleDeed != null) {
                        const owners = Array.isArray(temporaryTitleDeed?.document?.owners) && temporaryTitleDeed.document.owners.length > 0
                            ? temporaryTitleDeed.document.owners.map((owner: any) => {
                                return {
                                    Name: owner?.name,
                                    Valid: owner?.valid
                                }
                            }) : undefined
                        const file = bodyClient.agent_create_unit_title_deed
                        // console.log(file,"file")
                        if (file) {
                            const url = await uploadSingleFile(file)
                            console.log(url,"url")
                            const bodyCreateTitleDeed = {
                                RefID: `REF_DOC<->${'agent_create_unit_title_deed'}<->${temporaryTitleDeed?.document?.number}<->${new Date().getTime()}`,
                                Type: [{
                                    __component: 'documents.title-deed',
                                    Number: temporaryTitleDeed?.document?.number,
                                    Year: temporaryTitleDeed?.document?.year,
                                    Type: temporaryTitleDeed?.document?.type,
                                    Property: {
                                        Number: temporaryTitleDeed?.document?.property?.number,
                                        Name: temporaryTitleDeed?.document?.property?.name,
                                        MAKANI: temporaryTitleDeed?.document?.property?.makani,
                                        Type: temporaryTitleDeed?.document?.property?.type ? temporaryTitleDeed?.document?.property?.type : null,
                                        Area:  temporaryTitleDeed?.document?.property?.area  ? temporaryTitleDeed?.document?.property?.area: null 
                                    },
                                    Owners: owners
                                }],
                                Link: url,
                                Valid: Boolean(temporaryTitleDeed?.document?.valid),
                                Statuses: [temporaryTitleDeed?.document?.valid ? 'Approved' : 'In-process']
                            }
                       
                            const responseTD = await fetch(`${urlAPI}`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    method: 'POST',
                                    collection: 'documents',
                                    query: getQueryDocuments(),
                                    body: bodyCreateTitleDeed
                                }),
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${jwt}`,
                                    'BSO-Hash': '02ns30nsBSO!'
                                }
                            })
                            const dataTitleDeedCreateJSON = await responseTD.json()
                            //  console.log(dataTitleDeedCreateJSON,"dataTitleDeedCreateJSON")
                            if (dataTitleDeedCreateJSON.success && dataTitleDeedCreateJSON?.response?.data) {
                                docTitleDeed = dataTitleDeedCreateJSON?.response?.data
                                dispatch(documentsSlice.actions.documentAddSuccess(dataTitleDeedCreateJSON.response?.data))
                            } else {
                                await deleteData('properties',dataPropertyJSON?.response?.data?.id)

                                return { ...dataTitleDeedCreateJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataTitleDeedCreateJSON) }
                            }
                        }
                    }
                    // data.OccupiedUntil = data.OccupiedUntil ? dayjs(data.OccupiedUntil).add(4, 'hours') : null
                    //  console.log(docTitleDeed,"docTitleDeed")
           
                    const response = await fetch(`${urlAPI}`, {
                        method: 'POST',
                        body: JSON.stringify({
                            method: 'POST',
                            collection: 'units',
                            body: {
                                RefID: [propertyTitleDeed?.name , propertyTitleDeed?.area, new Date().getTime()]?.join(' <-> '),
                                User: Number(userID),
                                Property: { id: dataPropertyJSON?.response?.data?.id },
                                Documents: { id: Number(docTitleDeed.id) },
                                Number: String(propertyTitleDeed?.number),
                                Statuses: ['Drafted' , "Door close"],
                                //  SizeArea: nanToNull(bodyClient?.AreaSqFt),
                                // Bedrooms = nanToNull(data.Bedrooms)
                                // Bathrooms = nanToNull(data.Bathrooms)
                                // GuestBathroom = data.GuestBathroom //= == 'Yes'
                                // MaidsRoom = data.MaidsRoom //= == 'Yes'
                            },
                            query: getQueryUnits()
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwt}`
                        }
                    })

                    const dataUnitJSON = await response?.json() as unknown as any
                    //     console.log(dataUnitJSON,"dataUnitJSON")
                    if (dataUnitJSON.success) {
                        if (dataUnitJSON.response.error != null) {
                            await deleteData('properties',dataPropertyJSON?.response?.data?.id)
                            await deleteData('documents', docTitleDeed.id)
                            dispatch(dataFetchingFinish())
                            return { ...dataUnitJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataUnitJSON) }
                        } else {
                            dispatch(dataFetchingFinish())
                            return {
                                ...dataUnitJSON,
                                textNavigate: `/agent/modal/save_after_draft_agent_unit/${dataUnitJSON?.response?.data?.id}/${dataPropertyJSON?.response?.data?.id}`, // '/agent/my_units', 
                                isSuccessful: true,
                                textNotification: 'Unit was added successfully in draft list, you can check and add this unit in catalog',
                                title: 'Success'
                            }                           
                        }
                    } else {
                        dispatch(dataFetchingFinish())
                        return { textNavigate: '', isSuccessful: false, textNotification: getErrorss(getMessageForError('Some error! Please , try again'))}
                    }
                }
            } else {
                dispatch(dataFetchingFinish())
                return { textNavigate: '', isSuccessful: false, textNotification:  getErrorss(getMessageForError('Some error! Please , try again')) }
            }
        } catch (e) {
            dispatch(dataFetchingFinish())
            console.log(e)
        }
    }
}
