import React, {type ReactElement, useMemo} from "react";
import {type User} from "../../../../store/slices/userSlice";
import {useAppSelector} from "../../../../hooks/redux";
import {type IOffer} from "../../../../store/slices/offersSlice";
import dayjs from "dayjs";
import {type IAdvertise} from "../../../../store/slices/advertisesSlice";
import {type IViewing} from "../../../../types";
import formatPrice from "../../../../functions/shared/format/formatPrice";

interface IProps {
    user: User
}

export default function AgentProfileStatistics ({user}: IProps): ReactElement {
    const offers = useAppSelector((state) => state.offers.offers)
    const viewings = useAppSelector((state) => state.viewings.viewings)
    const advertises = useAppSelector((state) => state.advertises.advertises)

    const statistics = useMemo(() => {
        const statistics = {
            Offers: {
                TotalCompleted: 0,
                TotalPrice: {
                    Sale: 0,
                    Rent: 0
                },
                Total: 0,
                Month: 0,
                Week: 0,
                Today: 0
            },
            Viewings: {
                Total: 0,
                Month: 0,
                Week: 0,
                Today: 0
            },
            Advertises: {
                Total: 0,
                Month: 0,
                Week: 0,
                Today: 0
            }
        }
        function processScheduleItem (item: IOffer | IViewing | IAdvertise, statisticsKey: 'Offers' | 'Viewings' | 'Advertises', currentDate: dayjs.Dayjs): boolean {
            if (item?.attributes?.User?.data?.id === user.id) {
                const creationDate = dayjs(item?.attributes?.createdAt)
                if (currentDate.diff(creationDate, 'month', true) > 1) {
                    statistics[statisticsKey].Total++
                } else if (currentDate.diff(creationDate, 'week', true) > 1) {
                    statistics[statisticsKey].Total++
                    statistics[statisticsKey].Month++
                }  else if (currentDate.diff(creationDate, 'day', true) > 1) {
                    statistics[statisticsKey].Total++
                    statistics[statisticsKey].Month++
                    statistics[statisticsKey].Week++
                } else {
                    statistics[statisticsKey].Total++
                    statistics[statisticsKey].Month++
                    statistics[statisticsKey].Week++
                    statistics[statisticsKey].Today++
                }
                return true
            }
            return false
        }
        const currentDate = dayjs()
        offers.forEach((offer: IOffer) => {
            if (processScheduleItem(offer, 'Offers', currentDate)) {
                if (offer?.attributes?.Statuses?.includes('Completed')) {
                    statistics.Offers.TotalCompleted++
                    if (offer?.attributes?.Type?.[0]?.__component?.includes('sale') === true) {
                        statistics.Offers.TotalPrice.Sale += (offer?.attributes?.Type?.[0]?.Offer ?? 0)
                    } else {
                        statistics.Offers.TotalPrice.Rent += (offer?.attributes?.Type?.[0]?.Offer ?? 0) * (offer?.attributes?.Type[0]?.Duration ?? 0)
                    }
                }
            }
        })
        viewings.forEach((viewing: IViewing) => processScheduleItem(viewing, 'Viewings', currentDate))
        advertises.forEach((advertise: IAdvertise) => processScheduleItem(advertise, 'Advertises', currentDate))
        return statistics
    }, [user, offers, viewings, advertises])

    return <div className="card shadow-sm mb-4 mx-4">
        <div className="card-body p-4">
            <div className="row">
                <div className={'col-6 ps-4 pe-2 pb-4'}>
                    <div className="border border-gray-300 border-dashed rounded p-4">
                        <div className="fs-2 fw-bold counted">New viewings</div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                Total :
                            </span>
                            <span>
                                {
                                    statistics.Viewings.Total
                                }
                            </span>
                        </div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                This month :
                            </span>
                            <span>
                                {
                                    statistics.Viewings.Month
                                }
                            </span>
                        </div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                This week :
                            </span>
                            <span>
                                {
                                    statistics.Viewings.Week
                                }
                            </span>
                        </div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                Today :
                            </span>
                            <span>
                                {
                                    statistics.Viewings.Today
                                }
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'col-6 ps-2 pe-4 pb-4'}>
                    <div className="border border-gray-300 border-dashed rounded p-4">
                        <div className="fs-2 fw-bold counted">New offers</div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                Total :
                            </span>
                            <span>
                                {
                                    statistics.Offers.Total
                                }
                            </span>
                        </div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                This month :
                            </span>
                            <span>
                                {
                                    statistics.Offers.Month
                                }
                            </span>
                        </div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                This week :
                            </span>
                            <span>
                                {
                                    statistics.Offers.Week
                                }
                            </span>
                        </div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                Today :
                            </span>
                            <span>
                                {
                                    statistics.Offers.Today
                                }
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'col-6 ps-4 pe-2'}>
                    <div className="border border-gray-300 border-dashed rounded p-4">
                        <div className="fs-2 fw-bold counted">New advertises</div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                Total :
                            </span>
                            <span>
                                {
                                    statistics.Advertises.Total
                                }
                            </span>
                        </div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                This month :
                            </span>
                            <span>
                                {
                                    statistics.Advertises.Month
                                }
                            </span>
                        </div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                This week :
                            </span>
                            <span>
                                {
                                    statistics.Advertises.Week
                                }
                            </span>
                        </div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                Today :
                            </span>
                            <span>
                                {
                                    statistics.Advertises.Week
                                }
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'col-6 ps-2 pe-4'}>
                    <div className="border border-gray-300 border-dashed rounde p-4">
                        <div className="fs-2 fw-bold counted">Completed offers</div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                Total :
                            </span>
                            <span>
                                {
                                    statistics.Offers.TotalCompleted
                                }
                            </span>
                        </div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                Completed :
                            </span>
                            <span>
                                { statistics.Offers.Total > 0
                                    ? Math.floor(statistics.Offers.TotalCompleted / statistics.Offers.Total * 100)
                                    : 0
                                }%
                            </span>
                        </div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                Total sale :
                            </span>
                            <span>
                                AED {formatPrice(statistics.Offers.TotalPrice.Sale)}
                            </span>
                        </div>
                        <div className={'d-flex justify-content-between fs-6 fw-semibold text-gray-600'}>
                            <span>
                                Total rent :
                            </span>
                            <span>
                                AED {formatPrice(statistics.Offers.TotalPrice.Rent)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}