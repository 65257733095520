import React from 'react'

import { AgentModals } from '../../../functions/agent/AgentModals'
import MyCarousel from '../../../app/MyCarousel'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { scanningInstructions } from '../../../urls'
import Icon from '../../../app/icon/Icon'
import Markdown from 'react-markdown'
import GeoForMyCustomModalForView from '../../../app/custom/customModalForView/unitsActions/GeoForMyCustomModalForView'
import FormListForMyCustomForView from '../../../app/custom/customModalForView/FormListForMyCustomForView'

export default function AgentCreateBroadcastInstruction({
    unitID,
    modal
}: any): JSX.Element {
    const units = useAppSelector((state: RootState) => state.units.units)
    const currentUnit = units?.filter((el: any) => el.id === Number(unitID))[0]

    return (

        <>
            <div className={'px-2 mt-2   mb-8'}>
                <h1 className={'text-center my-2'}>Broadcast Your Unit for Increased Visibility and Viewings</h1>
                {/* <img
                    className="w-100 h-275px object-fit-contain mb-2 rounded-0 rounded-bottom "
                    src={scanningInstructions.QR_Code}
                    alt="cover_scan_qr"
                /> */}
                <div className="mb-4 ">
                    <div className={'rounded rounded-sm bg-gray-300 p-4'}>
                        <label className={'form-label fw-bold fs-5 text-dark m-0 d-flex align-items-center text-uppercase '}>
                            <Icon name={'help'} className={'fs-2x text-info me-1'} />
                            Instruction
                        </label>
                        <div className='fw-semi fs-5  lh-1 mt-2'>
                            <Markdown>
                                1.   When you broadcast your unit, it becomes visible to a broader audience of agents for 24 hours. This increased exposure allows other agents to see your unit and potentially respond with interest. As a result, more agents will be aware of your listing, which can lead to additional viewings and a higher chance of finding the right match for your property.
                            </Markdown>
                            <Markdown>
                                2. By broadcasting, you maximize the visibility of your unit, making it easier to attract attention and generate more engagement. The more agents who know about your unit, the better the chances of finding a suitable tenant or buyer quickly.
                            </Markdown>
                            <Markdown>
                                3. Broadcasting is an effective way to create momentum and encourage more viewings, ultimately leading to faster and more successful transactions.
                            </Markdown>
                        </div>
                    </div>
                </div>
            </div>

            {(modal[0].id === 'agent_set_broadcast') && (
                <div className="card d-flex mh-100 ">
                    <div className="fv-row my-2 m-0 p-0 w-100">
                        <div className="separator border border-bso border-3 mx-n7 p-0 my-4"></div>
                    </div>
                    <GeoForMyCustomModalForView googleMapsLink={null} />
                    <div className="fv-row my-2 m-0 p-0 w-100">
                        <div className="separator border border-bso border-3 mx-n7 p-0 my-4"></div>
                    </div>
                    <FormListForMyCustomForView
                        form={
                            AgentModals().find((el: any) => el.id === 'viewing_modal').form
                        }
                        response={currentUnit}
                    />
                    <div className="fv-row my-2 m-0 p-0 w-100" />
                    <MyCarousel dots={true} autoScrol={true} showTwoColumns={false}>
                        {currentUnit?.attributes.Images
                            ? (
                                currentUnit?.attributes.Images?.map((el: string) => (
                                    <div key={`${el}`} className="text-center">
                                        <div
                                            className="w-100 h-250px border border-gray-500 overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center position-relative"
                                            style={{ backgroundImage: `url(${el})` }}
                                        >
                                            <div className="h-200px w-100"></div>
                                        </div>
                                    </div>
                                ))
                            )
                            : (
                                <div>Not images</div>
                            )}
                    </MyCarousel>
                    <div style={{ height: '70px' }}></div>
                </div>
            )}</>
    )
}
