
import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY, URL_MAIN_API_APP, USER_ID_KEY } from '../../../urls'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'

import { type AppDispatch, type RootState } from '../../store'

export interface IOptions {
    isTestMode: boolean
    selectedPeriod: string
    orderID: number
    areas?:string[]
}

export const upgradeDowngradeSubscriptionAgent = (chooseSubscription: string, options?: IOptions) => async (dispatch: AppDispatch, getState: () => RootState) => {
 
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const testing = options?.isTestMode
    const orderID = options?.orderID
    let action = ''

    switch (chooseSubscription) {
        case 'Standard':
            action = options?.selectedPeriod === 'weekly' ? 'WeeklyStandard' : options?.selectedPeriod === 'annual' ? 'AnnualStandard' : 'MonthlyStandard'
            break
        case 'Premium':
            action = options?.selectedPeriod === 'weekly' ? 'WeeklyPremium' : options?.selectedPeriod === 'annual' ? 'AnnualPremium' : 'MonthlyPremium'
            break
        default:
            break
    }

    try {
        dispatch(dataFetchingStart())
        const sendObject = { id: String(orderID), action, testing }
        const response = await fetch(`${URL_MAIN_API_APP}/payment/updateSubscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(sendObject)
        })
        const dataJSON = await response.json()
     
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(dataFetchingFinish())
                return { ...dataJSON.response, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(dataFetchingFinish())
    }
}
