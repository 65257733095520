import React from 'react'
import Lottie from 'react-lottie'
// import animationData from '../animations/loading_screen_animation/LOADING SCREEN 2.json'
import animationData from '../../../animations/loading_screen_animation/Icon.json'
export default function Loader(): JSX.Element {
    return (
        <div className="d-flex flex-column flex-row-fluid flex-column-fluid flex-center vh-100 my-n8">
            <Lottie
                options={{
                    animationData,
                    loop: true,
                    autoplay: true
                }}
                height={220}
                width={220}
            />
            <h1 className="pt-n12 mt-n12">{'App is loading'}</h1>
        </div>
    )
}
