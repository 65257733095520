import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface PaginationState {
    pageCount: number
    page: number
    stopPagination: boolean
    paginationLoading: boolean
}

const initialState: PaginationState = {
    page: 1,
    pageCount: 1,
    stopPagination: false,
    paginationLoading: false
}

export const paginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        setPage: (state: PaginationState, action: PayloadAction<number>) => {
            state.page = action.payload
        },
        setPageCount: (state: PaginationState, action: PayloadAction<number>) => {
            state.pageCount = action.payload
        },
        setStopPagination: (state: PaginationState, action: PayloadAction<boolean>) => {
            state.stopPagination = action.payload
        },
        setPaginationLoading: (state: PaginationState, action: PayloadAction<boolean>) => {
            state.paginationLoading = action.payload
        }
    }
})

export const {
    setPage,
    setPageCount,
    setStopPagination,
    setPaginationLoading
} = paginationSlice.actions

export default paginationSlice.reducer
