import React from 'react'
import { Drawer } from 'antd'
import CustomModal from '../CustomModal'

interface CustomModalDrawerProps {
    open: boolean
    closeDrawer: () => void
    modalID: string
    unitID?: string
    objectID?: string
}

const CustomModalDrawer = ({ open, closeDrawer, modalID, unitID, objectID }: CustomModalDrawerProps): JSX.Element => {
    return (
        <Drawer
            open={open}
            onClose={closeDrawer}
            zIndex={10000}
            width={'100vw'}
            className={'bg-gray-200'}
            styles={{
                body: { padding: 0 }
            }}
            closable={false}
        >
            {
                modalID !== ''
                    ? <CustomModal modalID={modalID} close={closeDrawer} unitID={unitID} objectID={objectID} />
                    : <></>
            }
        </Drawer>
    )
}

export default CustomModalDrawer
