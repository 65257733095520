import React, { useEffect } from 'react'
import AgentHeader from '../../AgentHeader'
import TermsContent from '../../../shared/auth/TermsContent'
export default function TermsSection (): JSX.Element {
    useEffect(() => {
        document.getElementById('header_terms_section')?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'

        })
    }, [])
    return (
        <>
            <div
                className="page d-flex flex-column flex-row-fluid fs-5"
                id='header_terms_section'
            >

                <AgentHeader
                    active="Terms and Conditions"
                />

                <div className="content d-flex flex-column flex-fill p-0 m-4">

                    <div className="w-100 mw-450px mx-auto">

                        <div className="card shadow-sm mb-4">
                            <div className="card-body p-4">
                                <TermsContent/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
