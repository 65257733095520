export function getFeatures(): string[] {
    let features = [
        'Maids room',
        'Study',
        'Central A/C & Heating',
        'Balcony',
        'Private Garden',
        'Private Pool',
        'Private Gym',
        'Private Jacuzzi',
        'Shared Pool',
        'Shared Spa',
        'Shared Gym',
        'Security',
        'Concierge Service',
        'Maid Service',
        'Covered Parking',
        'Built-in Wardrobes',
        'Walk-in Closet',
        'Built-in Kitchen Appliances',
        'View of Water',
        'View of Landmark',
        'Pets Allowed',
        'Double Glazed Windows',
        'Day Care Center',
        'Electricity Backup',
        'First Aid Medical Center',
        'Service Elevators',
        'Prayer Room',
        'Laundry Room',
        'Broadband Internet',
        'Satellite/Cable TV',
        'Business Center',
        'Intercom',
        'ATM Facility',
        'Kids Play Area',
        'Reception/Waiting Room',
        'Maintenance Staff',
        'CCTV Security',
        'Cafeteria or Canteen',
        'Shared Kitchen',
        'Facilities for Disabled',
        'Storage Areas',
        'Cleaning Services',
        'Barbeque Area',
        'Lobby in Building',
        'Waste Disposal'
    ]
    return features
}
