import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { Switch, Space } from 'antd'
import { type IOrder, type ISettings, type User } from '../../../../store/slices/userSlice'

import UpdateSubscriptions from './UpdateSubscriptions'
import SubscriptionsList from './SubscriptionsList'
import { mounthes } from '../../../../functions/agent/date/timeString'

interface IProps {
    latestServiceAgentSubscription: IOrder | null
}

export default function CurrentSub({ latestServiceAgentSubscription }: IProps): JSX.Element {

    const isDev = window.localStorage.getItem('BSO_DEVELOP_MODE')
    const service = latestServiceAgentSubscription?.Service?.[0]

    const startedAtDate = service?.StartDate ? new Date(service?.StartDate) : null
    const finishedAtDate = service?.ExpiryDate ? new Date(service?.ExpiryDate) : null

    return (
        <div className="card card-dashed p-2 bg-light-primary">
            <div className="d-flex justify-content-between align-items-center mb-1 rounded p-2 mb-2">
                <div className="d-flex align-items-center me-2 ">
                    <span className="badge badge-lg border border-5 border-success badge-circle badge-light-success me-6"></span>
                    <div className="flex-grow-1">
                        <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                            {service?.Package}
                        </div>
                        <div className="fw-semibold opacity-75 lh-sm">
                            The best for independent sailing{' '}
                        </div>
                    </div>
                </div>

                <div className="ms-5">
                    <span className="mb-2">AED</span>

                    <span
                        className="fs-3x fw-bold"
                    >
                        {service?.Package === 'Premium' ? "20" : service?.Package === 'Standard' ? '10' : "0"}
                    </span>

                    <span className="fs-7 opacity-50">
                        /<span>Mon</span>
                    </span>
                </div>
            </div>

            <div className="pt-1 px-2 d-flex flex-column justify-content-between">

                <div className="separator separator-dashed my-2"></div>
                <h5 className="my-4">Subscription Details</h5>

                <div className="d-flex align-items-center fs-6 my-1">
                    <span className="fw-semi-bold  text-gray-700 flex-grow-1">
                        Started{' '}
                    </span>

                    {startedAtDate != null
                        ? (<span className="fw-semi-bold text-text-gray-700 flex-grow-2">
                            {startedAtDate.getDate().toString().padStart(2, '0')}{' '}{mounthes[startedAtDate.getMonth()].slice(0, 3)}{' '}{startedAtDate.getFullYear().toString()}
                        </span>)
                        : null
                    }

                </div>
                <div className="separator separator-dashed my-2"></div>
                <div className="d-flex align-items-center fs-6 my-1">
                    <span className="fw-semi-bold  text-gray-700 flex-grow-1">
                        Status{' '}
                    </span>

                    <span className="flex-grow-2 badge fs-6 badge-light-success">
                        Active
                    </span>

                </div>

                {isDev === 'true'
                    ? <>
                        <div className="d-flex align-items-center fs-6 my-1">
                            <span className="fw-semi-bold  text-gray-700 flex-grow-1">
                                Next Invoice{' '}
                            </span>
                            {finishedAtDate != null
                                ? (<span className="fw-semi-bold text-text-gray-700 flex-grow-2">
                                    {finishedAtDate.getDate().toString().padStart(2, '0')}{' '}{mounthes[finishedAtDate.getMonth()].slice(0, 3)}{' '}{finishedAtDate.getFullYear().toString()}
                                </span>)
                                : null
                            }
                        </div>
                        <div className="separator separator-dashed my-2"></div>

                        <div className="d-flex flex-wrap align-items-center my-1">
                            <div >
                                <div className="fs-6 fw-bold mb-1 text-uppercase">Auto renew</div>
                            </div>
                            <div className="ms-auto">

                                <Space direction="vertical">
                                    <Switch checkedChildren="ON" unCheckedChildren="OFF" defaultChecked />
                                </Space>

                            </div>
                        </div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="d-flex align-items-center justify-content-end py-2">
                            <div className="ms-2">
                                <UpdateSubscriptions title={'Compare plans'}>
                                    <SubscriptionsList />
                                </UpdateSubscriptions>
                            </div>
                        </div>
                    </>
                    : null}

            </div>

        </div>
    )
}
